import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { setSnackbar, setLoading, setGlobalDialog } from '../../store/general/actions';
import { deleteUser, activateUser, deactivateUser } from '../../services/UserService';
import { CanUserFunc } from '../CanUser';
import { access, roleNames } from '../../constants';
import capitalize from '../../utils/capitalize';
import { defaultColumnSettings } from '../UserSetting/DisplayColumns';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        maxWidth: '100vw'
    },
    paper: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        verticalAlign: 'middle !important',
        padding: '12px 12px',
    },
    tableCell: {
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
    actionColumn: {
        width: 75,
        padding: '2px 12px',
    },
    iconButton: {
        marginLeft: 5,
        padding: 4,
    },
    activeFilter: {
        color: '#0FB99E',
    },
    tableHead: {
        position: 'relative',
        zIndex: 0
    }
}));

const headCells = [
    { id: 'firstName', label: 'First Name', sort: true },
    { id: 'lastName', label: 'Last Name', sort: true },
    { id: 'username', label: 'Username', sort: true },
    { id: 'title', label: 'Title', sort: true },
    { id: 'email', label: 'Email', sort: true },
    { id: 'mobilePhone', label: 'Phone', sort: true },
    { id: 'roles', label: 'Roles', filter: true },
    { id: 'sites', label: 'Sites', filter: true },
    { id: 'status', label: 'Status', filter: true },
    { id: 'accountType', label: 'Account Type', filter: true },
];

const UserTable = (props) => {
    const classes = useStyles();
    const {
        user, users, loading, setSort, onEdit, fetchUser, isFilterActive,
        setSnackbar, setLoading, setGlobalDialog, openFilter, sort
    } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedUser, setSelectedUser] = React.useState(null);
    const [columnsSetting, setColumnsSetting] = React.useState({});

    useEffect(() => {
        if (sort) {
            setOrder(sort.sortAs || 'asc');
            setOrderBy(sort.sortBy || '');
        }
    }, [sort])

    useEffect(() => {
        if (users.length <= page * rowsPerPage) {
            setPage(page - 1 >= 0 ? page - 1 : 0);
        }
    }, [users])

    useEffect(() => {
        const usersColumnsSetting = user.settings?.columnsSetting?.users;
        const formattedSetting = {};
        if (usersColumnsSetting) {
            Object.entries(usersColumnsSetting).forEach(([key, value]) => {
                formattedSetting[key] = value;
            });
        } else {
            defaultColumnSettings.users.forEach((item) => {
                formattedSetting[item.key] = item.checked;
            });
        }
        setColumnsSetting(formattedSetting);
    }, [user])

    const handleRequestSort = (property) => event => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort({ sortAs: isAsc ? 'desc' : 'asc', sortBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openUserAction = (user) => (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedUser(user);
    }

    const handleEditUser = () => {
        setAnchorEl(null);
        onEdit(selectedUser);
    }

    /** When the entire row is clicked, set the selected user to clicked user and open edit form */
    const handleRowClick = (user) => (event) => {
        setSelectedUser(user);
        onEdit(user);
    }

    const handleActivateUser = () => {
        setAnchorEl(null);
        setLoading('editUser');
        activateUser(selectedUser.id).then(() => {
            setLoading('editUser', false);
            setSnackbar('success', 'User activated!');
            fetchUser();
        })
    }

    const handleDeactivateUser = () => {
        setAnchorEl(null);
        setLoading('editUser');
        deactivateUser(selectedUser.id).then(() => {
            setLoading('editUser', false);
            setSnackbar('success', 'User deactivated!');
            fetchUser();
        })
    }

    const handleDeleteUser = () => {
        setAnchorEl(null);
        setGlobalDialog(
            `Delete user?`,
            `Deleted user cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    setLoading('deleteUser');
                    deleteUser(selectedUser.id).then(() => {
                        setLoading('deleteUser', false);
                        setSnackbar('success', 'User deleted!');
                        fetchUser();
                    });
                }
            },
        )
    }

    const showActions = CanUserFunc(access.users.edit) || CanUserFunc(access.users.remove);
    const isAdmin = selectedUser?.roles.some(e => e.name === roleNames.ORG_ADMIN);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table stickyHeader className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tblHeadRow}>
                                {headCells.map(headCell => 
                                    (columnsSetting[headCell.id] || headCell.id === 'username') && <TableCell
                                        key={headCell.id}
                                        className={classes.headCell}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.sort ?
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={handleRequestSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                            :
                                            headCell.label
                                        }
                                        {headCell.filter &&
                                            <IconButton id={`user-sort-${headCell.id}`} className={classes.iconButton} onClick={(e) => openFilter(headCell.id, e.currentTarget)}>
                                                <KeyboardArrowDownIcon className={isFilterActive(headCell.id) ? classes.activeFilter : ''} />
                                            </IconButton>
                                        }
                                    </TableCell>
                                )}
                                {showActions &&
                                    <TableCell className={`${classes.headCell} ${classes.actionColumn}`}>Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        {loading.getUsers ?
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={showActions ? 10 : 9}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        const isSuperAdmin = row.roles.some(e => e.name === roleNames.SUPER_ADMIN);
                                        const isCompanyAdmin = row.roles.some(e => e.name === roleNames.ORG_ADMIN);
                                        const canEdit = !isSuperAdmin && CanUserFunc(access.users.edit) && (!isCompanyAdmin || row.id === user.id);
                                        const accountType = row.accountType === 'employee' ? 'user' : row.accountType;

                                        return (
                                            <TableRow
                                                hover
                                                onClick={canEdit ? handleRowClick(row) : null}
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                {columnsSetting.firstName && <TableCell className={classes.tableCell} component="th" scope="row">
                                                    {row.firstName}
                                                </TableCell>}
                                                {columnsSetting.lastName && <TableCell className={classes.tableCell}>{row.lastName}</TableCell>}
                                                <TableCell className={classes.tableCell}>{row.username}</TableCell>
                                                {columnsSetting.title && <TableCell className={classes.tableCell}>{row.title && row.title.name}</TableCell>}
                                                {columnsSetting.email && <TableCell className={classes.tableCell}>{row.email}</TableCell>}
                                                {columnsSetting.mobilePhone && <TableCell className={classes.tableCell}>{row.mobilePhone}</TableCell>}
                                                {columnsSetting.roles && <TableCell className={classes.tableCell}>
                                                    {row.roles && row.roles.map(role => (
                                                        <p key={`${row.id}-${role.id}`} style={{ margin: 0 }}>•&nbsp;{role.name}</p>
                                                    ))}
                                                </TableCell>}
                                                {columnsSetting.sites && <TableCell className={classes.tableCell}>
                                                    {isCompanyAdmin ? '• All sites' :
                                                    row.branches && row.branches.map(branch => (
                                                        <p key={`${row.id}-${branch.id}`} style={{ margin: 0 }}>•&nbsp;{branch.name}</p>
                                                    ))}
                                                </TableCell>}
                                                {columnsSetting.status && <TableCell className={classes.tableCell}>{row.isActive ? 'Active' : 'Inactive'}</TableCell>}
                                                {columnsSetting.accountType && <TableCell className={classes.tableCell}>{capitalize(accountType)}</TableCell>}
                                                {showActions && canEdit &&
                                                    <TableCell className={`${classes.tableCell} ${classes.actionColumn}`}>
                                                        <IconButton id={`user-row-edit-${row.id}`} onClick={openUserAction(row)}>
                                                            <img alt="Edit" src={require("../../resources/EditIcon.svg")} />
                                                        </IconButton>
                                                        {selectedUser &&
                                                            <LoadingCheck show={(loading.deleteUser || loading.editUser) && selectedUser.id === row.id} size={24} />
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        );
                                    })}
                                {!users.length &&
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell id="user-row-no-user-found" className={classes.noData} colSpan={showActions ? 10 : 9}>No users found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={users.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.users.edit) ?
                    <MenuItem id="user-row-edit-option" onClick={handleEditUser}>Edit</MenuItem>
                    : null
                }
                {!isAdmin && CanUserFunc(access.users.edit) ?
                    selectedUser && selectedUser.isActive ?
                        <MenuItem id="user-row-inactivate-option" onClick={handleDeactivateUser}>Inactivate</MenuItem>
                        :
                        <MenuItem id="user-row-activate-option" onClick={handleActivateUser}>Activate</MenuItem>
                    : null
                }
                {!isAdmin && CanUserFunc(access.users.remove) ?
                    <MenuItem id="user-row-delete-option" onClick={handleDeleteUser}>Delete</MenuItem>
                    : null
                }
            </Menu>
        </div>
    );
}

UserTable.propTypes = {
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setSkip: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
    users: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    openFilter: PropTypes.func.isRequired,
    isFilterActive: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading, setSnackbar, setGlobalDialog })(UserTable);