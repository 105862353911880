import React, { useCallback, useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import Timer from "./Timer";
import { getUnixTime } from "date-fns";
import PhaseColoredButton from "./PhaseColoredButton";

const useStyles = makeStyles((theme) => ({
    intervalButton: {
        borderRadius: 0,
        marginRight: 10,
        width: "100%",
    },
    intervalButtons: {
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    ongoingSessionHolder: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    codeHolder: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    controlersHolder: {
        display: "flex",
        flexDirection: "row",
        marginTop: 10,
        alignItems: "center",
        width: "100%",
    },
}));

const IntervalEndAudio = "/audio/ding.mp3";
const DurationEndAudio = "/audio/ting.wav";

const Interval = (props) => {
    const classes = useStyles();
    const [totalConsumedTime, setTotalConsumedtime] = useState(0);
    const [isOngoing, setIsOngoing] = useState(true);
    const [isAllowedToResponse, setIsAllowedToResponse] = useState(false);

    const calculateConsumedTime = useCallback(() => {
        if (!props.sessionData?.length) {
            return 0;
        }
        let resumeTime = null;
        let total = 0;
        props.sessionData.forEach((item) => {
            if (item.data?.type === "start") {
                resumeTime = item.data?.time_ms;
            } else if (item.data?.type === "resume") {
                resumeTime = item.data?.time_ms;
            } else if (item.data?.type === "pause") {
                total += item.data?.time_ms - resumeTime;
                resumeTime = 0;
            }
        });
        const consumedTime = resumeTime
            ? total + (new Date().getTime() - resumeTime)
            : total;
        setIsOngoing(consumedTime / 1000 < props.target.customData.duration);

        const responses = props.sessionData.filter((e) => e.data?.type === "add");
        if (responses.length > 0) {
            const latestCollectionTime =
                parseInt(consumedTime / (1000 * props.target.customData.schedule)) *
                1000 *
                props.target.customData.schedule;
            setIsAllowedToResponse(
                latestCollectionTime >
                responses[responses.length - 1].data?.consumedTime
            );
        } else {
            setIsAllowedToResponse(true);
        }
        setTotalConsumedtime(consumedTime);
        return consumedTime;
    }, [props.sessionData?.length, props.target.id]);

    const isTimeRunning = () => {
        if (!props.sessionData?.length) {
            return false;
        }
        return props.sessionData.reduce((status, item) => {
            if (item.data?.type === "start" || item.data?.type === "resume") {
                return true;
            } else if (item.data?.type === "pause") {
                return false;
            }
            return status;
        }, false);
    };

    const handleStartPress = () => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {
                type: "start",
                time_ms: new Date().getTime(),
                time: getUnixTime(new Date()),
            },
            'Start interval',
        );
        setIsOngoing(true);
    };

    const handlePauseResumePress = () => {
        if (isTimeRunning()) {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                {
                    type: "pause",
                    time_ms: new Date().getTime(),
                    time: getUnixTime(new Date()),
                },
                'Pause interval',
            );
        } else {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                {
                    type: "resume",
                    time_ms: new Date().getTime(),
                    time: getUnixTime(new Date()),
                },
                'Resume interval',
            );
        }
    };

    const onDataEntryButtonPress = (code) => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {
                type: "add",
                time_ms: new Date().getTime(),
                time: getUnixTime(new Date()),
                code: code,
                consumedTime: totalConsumedTime,
            },
            code.code,
        );
    };

    const responseCounter = (sessionData) => {
        return sessionData.filter((e) => e.data.type === "add").length;
    };

    const isResponseAllowed = useMemo(() => {
        const maxTrialNo = props.target.maximumNumberTrials;
        if (!!maxTrialNo && !isNaN(maxTrialNo)) {
            return responseCounter(props.sessionData) < maxTrialNo;
        }
        return true;
    }, [props.sessionData?.length]);

    useEffect(() => {
        const nextCollectionTime = (parseInt(totalConsumedTime / (1000 * props.target.customData.schedule))) *
            1000 *
            props.target.customData.schedule;
        if (parseInt(props.target.customData.duration * 1000) === nextCollectionTime) {
            (new Audio(DurationEndAudio)).play();
        } else if (nextCollectionTime && parseInt(nextCollectionTime / 1000) === parseInt(totalConsumedTime / 1000)) {
            (new Audio(IntervalEndAudio)).play();
        }
    }, [totalConsumedTime, isOngoing]);

    return (
        <Wrapper {...props} responseCounter={responseCounter}>
            <div className={classes.intervalButtons}>
                {props.sessionData?.length && isOngoing ? (
                    <div className={classes.ongoingSessionHolder}>
                        <div className={classes.codeHolder}>
                            {props.target.customData.codes.filter(e => !e.isDeleted).map((entry, i) => (
                                <PhaseColoredButton
                                    className={classes.intervalButton}
                                    onClick={() => onDataEntryButtonPress(entry)}
                                    disabled={!isAllowedToResponse || !isResponseAllowed}
                                    phase={props.target?.phase}
                                >
                                    {entry.code}
                                </PhaseColoredButton>
                            ))}
                        </div>
                        <div className={classes.controlersHolder}>
                            <PhaseColoredButton
                                onClick={handlePauseResumePress}
                                className={classes.intervalButton}
                                phase={props.target?.phase}
                            >
                                {isTimeRunning() ? "Pause" : "Resume"}
                            </PhaseColoredButton>
                            <Timer
                                consumedTimeFn={calculateConsumedTime}
                                isPaused={props.sessionData?.pauseTimestamp}
                            />
                        </div>
                    </div>
                ) : (
                    <PhaseColoredButton
                        onClick={handleStartPress}
                        className={classes.intervalButton}
                        disabled={!isOngoing && props.sessionData?.length}
                        phase={props.target?.phase}
                    >
                        Start
                    </PhaseColoredButton>
                )}
            </div>
        </Wrapper>
    );
};

Interval.propTypes = {
    sessionData: PropTypes.array.isRequired,
    sessionNotes: PropTypes.array.isRequired,
    sessionId: PropTypes.number.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
};

export default Interval;
