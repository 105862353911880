import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    TextField, Grid, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    input: {
        width: '100%',
    },
    saveBtn: {
        padding: '6px 40px',
        marginTop: 18,
        marginBottom: 18,
    },
}));

const EditTitle = (props) => {
    const classes = useStyles();
    const { onClose, open, title, updateTitle } = props;
    const [name, setName] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!!title) {
            setName(title.name);
        }
        setError(false);
    }, [open, title]);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        const titleName = name.trim();
        if (!titleName) {
            setError(true);
            return;
        }
        let data = {
            name: titleName,
            id: title.id,
        }
        updateTitle(data);
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        Edit Title
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} container justify="center">
                        <TextField
                            variant="outlined"
                            multiline
                            label="Title"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={error && !name.trim()}
                            helperText={error && !name.trim() ? "Title can't be empty" : ' '}
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        <Button className={classes.saveBtn} onClick={handleOk}>Update</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

EditTitle.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.object,
    updateTitle: PropTypes.func.isRequired,
}

export default EditTitle;
