import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    List, ListItem, ListItemText, makeStyles,
    Divider, IconButton, Collapse,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { connect } from 'react-redux';
import { Droppable, Draggable } from "react-beautiful-dnd";

import ProgramItem from './ProgramItem';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';
import { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
    listDivider: {
        marginLeft: 29,
        marginTop: 10,
        marginBottom: 10,
        marginRight: 29,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    domain: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 16,
    },
    program: {
        fontWeight: 300,
        fontSize: 14,
        paddingLeft: 16,
    },
    listItem: {
        paddingLeft: 24,
        paddingRight: 32,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
    },
    domainWrapper: {
        position: 'relative',
    },
    collapseDomain: {
        position: 'absolute',
        right: 5,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': { backgroundColor: '#00826d' },
    },
    dot: {
        position: 'absolute',
        top: 7,
        right: 7,
        width: 10,
        height: 10,
        backgroundColor: '#0FB99E',
        borderRadius: 50,
    },
    editBtn: {
        position: 'absolute',
        left: 2,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': {
            backgroundColor: '#00826d',
            opacity: 1,
        },
        opacity: 0.4,
    },
    programList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const DomainItem = (props) => {
    const classes = useStyles();
    const { selection, filter, handleOpenAction, domain, collapseAll, ordering } = props;
    const [collapseDomain, setCollapseDomain] = useState(false);
    const [hoverDomain, setHoverDomain] = useState(null);
    const [programs, setPrograms] = useState([]);

    useEffect(() => {
        // notify parent that we are collapsing the domain
        props.collapseDomain(collapseDomain);
    }, [collapseDomain])

    useEffect(() => {
        setCollapseDomain(collapseAll);
    }, [collapseAll])

    useEffect(() => {
        setPrograms(domain.programs);
    }, [domain])

    const showActionsDomain = CanUserFunc(access.domains.edit) || CanUserFunc(access.domains.remove);
    const isDomainSelected = selection.domain && selection.domain === domain.id;
    const isDomainArchived = domain.status === 'archived';
    const showDot = collapseDomain && isDomainSelected && selection.program && selection.program !== 'all';
    return (
        <React.Fragment key={domain.id}>
            <div
                className={classes.domainWrapper}
                onMouseEnter={() => setHoverDomain(true)}
                onMouseLeave={() => setHoverDomain(false)}
            >
                <ListItem button
                    onClick={() => filter({ domain: domain.id, all: false, program: 'all' })}
                    className={`${classes.listItem} ${selection.program === 'all' && isDomainSelected ? classes.listItemSelected : ''}`}
                >
                    <ListItemText
                        primaryTypographyProps={{ className: classes.domain }}
                        primary={domain.name}
                        style={isDomainArchived ? { color: 'red' } : {}}
                    />
                </ListItem>
                <IconButton onClick={() => setCollapseDomain(!collapseDomain)} className={classes.collapseDomain}>
                    {!collapseDomain ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    {showDot && <div className={classes.dot} />}
                </IconButton>
                {
                    showActionsDomain && hoverDomain ?
                        <IconButton id={`sidebar-domain-action-button-${domain.id}`} className={classes.editBtn} onClick={handleOpenAction('domain', domain)}>
                            <MoreVertIcon />
                        </IconButton>
                        : null
                }
                <Collapse
                    in={!collapseDomain}
                    onMouseEnter={() => setHoverDomain(false)}
                    onMouseLeave={() => setHoverDomain(true)}
                >
                    <Droppable droppableId={`droppableProgram${domain.id}`} type={`domain-${domain.id}`}>
                        {(provided, snapshot) => (
                            <List
                                className={classes.programList}
                                component="div"
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {!!programs.length && ordering && ordering.map((programId, index) => {
                                    const program = programs.find(e => e.id === programId)
                                    if (!program) return null;
                                    return (
                                        <Draggable key={program.id} draggableId={`program${program.id}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    className={snapshot.isDragging ? classes.listItemSelected : ''}
                                                >
                                                    <ProgramItem
                                                        isSelected={selection.program && selection.program === program.id}
                                                        program={program}
                                                        filter={() => filter({ program: program.id, domain: domain.id, all: false })}
                                                        handleOpenAction={handleOpenAction}
                                                    />
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                                {provided.placeholder}
                            </List>
                        )}
                    </Droppable>

                </Collapse>
            </div>
            <Divider classes={{ middle: classes.listDivider }} variant="middle" />
        </React.Fragment>

    )
}

DomainItem.propTypes = {
    collapseAll: PropTypes.bool.isRequired,
    domain: PropTypes.object.isRequired,
    filter: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    handleOpenAction: PropTypes.func.isRequired,
    collapseDomain: PropTypes.func.isRequired,
    ordering: PropTypes.array,
}

const mapStateToProps = state => ({
    selection: state.general.selection,
})

export default connect(mapStateToProps)(DomainItem);
