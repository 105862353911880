import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ListIcon from '@material-ui/icons/List';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
		height: '100%',
	},
	chart: {
		marginTop: 100,
		fontSize: 400,
		display: 'block',
		padding: 10,
		border: '5px solid #0FB99E',
		borderRadius: 10,
	},
	chartMobile: {
		marginTop: 20,
		fontSize: 40,
		display: 'block',
		padding: 10,
		border: '2px solid #0FB99E',
		borderRadius: 10,
	},
	title: {
		fontWeight: 'bold',
	},
	subTitle: {
		margin: 0,
		color: '#5A5A5A',
	},
}));

const NoProgram = ({isMobile = false}) => {
	const classes = useStyles();

	if(isMobile) 
		return (
			<Grid className={classes.root}>
				<Grid item xs={12} container justify="center">
					<ListIcon id="data_empty_data_icon" className={classes.chartMobile} />
				</Grid>
				<Grid item xs={12} container justify="center">
					<p id="data_no_item_selected_message" className={classes.title}>NO DOMAIN OR PROGRAM SELECTED</p>
				</Grid>
				<Grid item xs={12} container justify="center">
					<p id="data_please_select_message" className={classes.subTitle}>Please select a domain or program</p>
				</Grid>
			</Grid>
		)

	return (
		<Grid className={classes.root}>
			<Grid item xs={12} container justify="center">
				<ListIcon id="data_empty_data_icon" className={classes.chart} />
			</Grid>
			<Grid item xs={12} container justify="center">
				<p id="data_no_item_selected_message" className={classes.title}>NO DOMAIN OR PROGRAM SELECTED</p>
			</Grid>
			<Grid item xs={12} container justify="center">
				<p id="data_please_select_message" className={classes.subTitle}>Please select a domain or program from the sidebar</p>
			</Grid>
		</Grid>
	)
}

export default NoProgram;
