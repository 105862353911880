import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import format from 'date-fns/format';
import isWithinInterval from 'date-fns/isWithinInterval';
import isSameDay from 'date-fns/isSameDay';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import { connect } from 'react-redux';

import { setSnackbar } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
    datePicker: {
        width: 260,
    },
    dateInput: {
        color: '#53545E',
        padding: 10.5,
    },
    dateAdornment: {
        margin: 0,
        padding: 0,
    },
    clearIcon: {
        color: '#000000',
    },
    dialogPaper: {
        width: 800,
        backgroundColor: 'transparent',
        boxShadow: 'none',
    },
    buttonWrapper: {
        backgroundColor: 'white',
        borderRadius: '0px 0px 10px 10px',
        padding: 20,
    },
    dayWrapper: {
        margin: '1px 0px'
    },
    dayWrapperSingle: {
        margin: '1px 2px',
    },
    day: {
        width: 36,
        height: 36,
        fontSize: theme.typography.caption.fontSize,
        margin: '0px 2px',
        color: 'inherit',
        borderRadius: 0,
    },
    daySingle: {
        margin: 0,
    },
    startDay: {
        extend: 'day',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    endDay: {  
        extend: 'day',
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    },
    nonCurrentMonthDay: {
        visibility: 'hidden'
    },
    highlight: {
        backgroundColor: '#0FB99E',
        color: 'white',
        borderRadius: 0,
        '&:hover': {
            backgroundColor: '#00826d',
        }
    },
    startHighlight: {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    },
    endHighlight: {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
        backgroundColor: '#4a8fff',
        '&:hover': {
            backgroundColor: '#3b72cc',
        }
    },
    singleHighlight: {
        borderRadius: '50%',
    },
    disabledDay: {
        color: theme.palette.text.disabled,
        cursor: 'default',
        '&:hover': {
            backgroundColor: 'white',
        }
    },
}));

const DateRangePicker = (props) => {
    const classes = useStyles();
    const [startDateInternal, setStartDateInternal] = useState(new Date());
    const [endDateInternal, setEndDateInternal] = useState(new Date());
    const { startDate, endDate, setStartDate, setEndDate, open, onClose, setSnackbar } = props;

    useEffect(() => {
        // don't convert null to date
        setStartDateInternal(!!startDate ? new Date(startDate) : null);
    }, [startDate])

    useEffect(() => {
        setEndDateInternal(!!endDate ? new Date(endDate) : null);
    }, [endDate])

    const handleCancel = () => {
        setStartDateInternal(!!startDate ? new Date(startDate) : null);
        setEndDateInternal(!!endDate ? new Date(endDate) : null);
        onClose();
    }

    const handleApply = () => {
        if (isAfter(startDateInternal, endDateInternal)) {
            setSnackbar('error', 'Start date must be before end date!');
            return;
        }

        setStartDate(startDateInternal);
        setEndDate(endDateInternal);
        onClose();
    }

    const renderCustomDate = isStartPicker => (date, selectedDate, dayInCurrentMonth) => {
        let dateClone = new Date(date);

        let dayIsBetween = false;
        if (startDateInternal && endDateInternal) {
            dayIsBetween = dayInCurrentMonth && isWithinInterval(dateClone, { start: startDateInternal, end: endDateInternal });
        }

        const isStart = dayInCurrentMonth && startDateInternal && isSameDay(dateClone, startDateInternal);
        const isEnd = dayInCurrentMonth && endDateInternal && isSameDay(dateClone, endDateInternal);
        const isSingleSelect = dayInCurrentMonth && ((startDate || endDate) && !(startDateInternal && endDateInternal));

        let isDisabled;
        if (isStartPicker) {
            isDisabled = endDateInternal ? isAfter(dateClone, endDateInternal) : false;
        } else {
            isDisabled = startDateInternal ? isBefore(dateClone, startDateInternal) : false;
        }

        return (
            <div className={`
                ${classes.dayWrapper}
                ${isSingleSelect ? classes.dayWrapperSingle : ''}
                ${dayIsBetween ? classes.highlight : ''}
                ${isStart ? `${classes.highlight} ${classes.startHighlight}` : ''}
                ${isEnd ? `${classes.highlight} ${classes.endHighlight}` : ''}
                ${isSingleSelect ? classes.singleHighlight : ''}
            `}>
                <IconButton
                    disabled={isDisabled}
                    className={`
                        ${classes.day}
                        ${isSingleSelect ? classes.daySingle : ''}
                        ${isStartPicker ? classes.startDay : classes.endDay}
                        ${!dayInCurrentMonth ? classes.nonCurrentMonthDay : ''}
                        ${isDisabled ? classes.disabledDay : ''}
                    `}
                >
                    <span> {format(dateClone, "d")} </span>
                </IconButton>
            </div>
        );
    }

    return (
        <Dialog
            maxWidth="lg"
            open={open}
            onClose={onClose}
            classes={{
                paper: classes.dialogPaper
            }}
        >
            <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={5} style={{ marginRight: 1 }}>
                    <DatePicker
                        variant="static"
                        openTo="date"
                        value={startDateInternal}
                        onChange={(date) => setStartDateInternal(date)}
                        maxDate={endDateInternal || undefined}
                        renderDay={renderCustomDate(true)}
                    />
                </Grid>
                <Grid item xs={5}>
                    <DatePicker
                        variant="static"
                        openTo="date"
                        value={endDateInternal}
                        onChange={(date) => setEndDateInternal(date)}
                        minDate={startDateInternal || undefined}
                        renderDay={renderCustomDate(false)}
                    />
                </Grid>

                <Grid item xs={1}></Grid>
                <Grid item xs={5} container justify="flex-start" className={classes.buttonWrapper} style={{ marginRight: 1 }}>
                    <Button id="daterangepicker-clear-button_1" className={classes.dateClear} onClick={() => setStartDateInternal(null)}>Clear</Button>
                </Grid>
                <Grid item xs={5} container justify="flex-start" className={classes.buttonWrapper}>
                    <Grid item xs>
                        <Button id="daterangepicker-clear-button_2" className={classes.dateClear} onClick={() => setEndDateInternal(null)}>Clear</Button>
                    </Grid>
                    <Grid item xs container justify="flex-end">
                        <Button id="daterangepicker-cancel-button" className={classes.dateClear} onClick={handleCancel}>Cancel</Button>
                        <Button id="daterangepicker-apply-button" style={{ marginLeft: 10 }} className={classes.dateClear} onClick={handleApply}>Apply</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}

DateRangePicker.propTypes = {
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    setStartDate: PropTypes.func.isRequired,
    setEndDate: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
}

export default connect(null, { setSnackbar })(DateRangePicker);
