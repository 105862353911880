import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles, Grid, Badge, Menu, MenuItem, Typography } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import { connect } from 'react-redux';

import PatientSelect from './PatientSelect';
import UserAvatar from './UserAvatar';
import { access, settingTypesEnum } from '../constants';
import CanUser, { CanUserFunc } from './CanUser';
import ZendeskPopover from './ZendeskPopover';
import { Menu as MenuIcon } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
	header: {
		backgroundColor: '#F4F4F4',
		width: '100%',
		paddingLeft: 20,
		position: 'sticky',
		top: 0,
		zIndex: 5,
	},
	mobileHeader: {
		backgroundColor: '#0D3250',
		width: '100%',
		position: 'sticky',
		top: 0,
		zIndex: 2,
		padding: "8px 10px",
		marginBottom: 10,
	},
	title: {
		fontSize: 20,
		color: '#fff',
		marginLeft: 16,
		whiteSpace: 'nowrap',
	},
	routeTab: {
		display: 'inline-block',
		padding: '20px 15px',
		cursor: 'pointer',
		fontSize: 18,
		'@media (max-width: 1135px)': {
            padding: '20px 10px',
        },

		'@media (max-width: 1046px)': {
            padding: '20px 5px',
        }
	},
	avaterPart: {
		flexWrap: 'nowrap',
		maxHeight: '70px',
	},
	tabActive: {
		borderBottom: '4px solid #0FB99E',
	},
	tabUnderline: {
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		textDecoration: 'none',
		fontSize: 17
	},
	link: {
		color: 'black',
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
	},
	sublink: {
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
		textAlign: 'center',
		color: '#0D3250',
	},
	popoverPaper: {
		backgroundColor: '#ffffff',
	},
	sublinkUnderline: {
		textDecoration: 'none',
	},
	sublinkActive: {
		borderBottom: '4px solid #0FB99E',
		'&:hover': { color: '#0FB99E' },
	},
	arrowIcon: {
		verticalAlign: 'middle',
	},
	helpOutline: { margin: '0 5px'}
}));

 const routes = [
	{
		label: 'Program',
		route: '/',
		permission: access.targets.view,
	},
	{
		label: 'Data',
		route: '/data',
		permission: access.data.view,
	},
	{
		label: 'Analysis',
		route: '/analysis',
		permission: access.analysis.view,
	},
	{
		label: 'Notes',
		route: '/notes',
		permission: access.notes.view,
	},
	// Just for site title on Mobile
	{
		label: 'Program Lib.',
		route: '/user-setting/my-library',
	},
	{
		label: "User Info",
		route: "/user-setting/user-info",
	},
	{
		label: 'User Settings',
		route: '/user-setting',
	},
	{
		label: 'Roles',
		route: '/admin/user/role',
	},
	{
		label: 'Devices',
		route: '/admin/user/devices',
	},
	{
		label: 'Users',
		route: '/admin/user',
	},
	{
		label: 'Clients',
		route: '/admin/client',
	},
	{
		label: 'Sites',
		route: '/admin/subscription/site',
	},
	{
		label: 'Account Info.',
		route: '/admin/subscription/account-info',
	}
]

const CustomLink = ({ route, unreadMemos, openPopover }) => {
	const classes = useStyles();

	let hasSubroute = false;

	if (route.subroute) {
		route.subroute.forEach((sub) => {
			if (!sub.isParent) {
				if (CanUserFunc(sub.permission)) {
					hasSubroute = true;
				}
			}
		})
	}

	const match = useRouteMatch({
		path: route.route,
		exact: !hasSubroute,
	});

	return (
		<div id={'route-' + route.label} className={classes.routeTab} onClick={hasSubroute ? openPopover(route.subroute) : () => { }}>
			<Link id={`route-link-${route.route}`} to={hasSubroute ? undefined : { pathname: route.route }} className={classes.link}>
				{route.label === 'Notes' ?
					<Badge badgeContent={unreadMemos.length} max={99} color="error">
						<u className={`${classes.tabUnderline} ${match ? classes.tabActive : ''}`}>
							{route.label}
							{route.special && <ArrowIcon className={classes.arrowIcon} />}
						</u>
					</Badge>
					:
					<u className={`${classes.tabUnderline} ${match ? classes.tabActive : ''}`}>
						{route.label}
						{route.special && <ArrowIcon className={classes.arrowIcon} />}
					</u>
				}
			</Link>
		</div>
	)
};

const CustomSubLink = ({ route, onClick }) => {
	const classes = useStyles();

	const match = useRouteMatch({
		path: route.route,
		exact: true,
	});


	return (
		<Link id={`route-link-${route.route}`} to={{ pathname: route.route }} className={classes.sublink} onClick={onClick}>
			<MenuItem>
				<u className={`${classes.sublinkUnderline} ${match ? classes.sublinkActive : ''}`} >{route.label}</u>
			</MenuItem>
		</Link>
	)
};

const Header = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [subroute, setSubroute] = useState([]);
	const [routeList, setRouteList] = useState([]);
	const [screenTitle, setScreenTitle] = useState('Program');
	
	useEffect(() => {
		const newList = routes.slice();
		
		newList.push({
			label: 'Settings',
			route: '/settings',
			subroute: [
				{ label: settingTypesEnum['reminder'], route: '/settings/reminder', isParent: true, permission: access.clients.view },
				{ label: settingTypesEnum['datasheet'], route: '/settings/data-sheet', permission: access.clients.view }
			],
			permission: access.clients.view,
		})
				
		if (CanUserFunc(access.users.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/user',
				permission: access.users.view,
				special: true,
			})
		} else if (CanUserFunc(access.roles.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/user/role',
				permission: access.roles.view,
				special: true,
			})
		} else if (CanUserFunc(access.sites.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/subscription/site',
				permission: access.sites.view,
				special: true,
			})
		} else if (CanUserFunc(access.clients.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/client',
				permission: access.clients.view,
				special: true,
			})
		}

		setRouteList(newList);
	
	}, [])

	useEffect(() => {
		const currentRoute = routes.find((e) => e.route === window.location.pathname || (e.route !== '/' && window.location.pathname.startsWith(e.route)));
		const currentSubroute = routeList.reduce((acc, curr) => {
			if (curr.subroute) {
				const sub = curr.subroute.find(sub => sub.route === window.location.pathname);
				if (sub) {
					return sub;
				}
			}
			return acc;
		}, null);
		const isAdminRoute = window.location.pathname.includes('/admin');
		setScreenTitle(currentSubroute?.label || currentRoute?.label || (isAdminRoute && "Admin") || 'Program');
	}, [window.location.pathname, routeList])

	const openPopover = (subroute) => (event) => {
		setAnchorEl(event.currentTarget);
		setSubroute(subroute);
	}

	if(props.isMobile)  
		return 	<Grid container className={classes.mobileHeader} alignItems="center">
			<Grid item xs={1} id="toggle-mobile-sidebar">
				<MenuIcon onClick={props.toggleSidebar} style={{ color: 'white', marginTop: 2 }} fontSize='large'  />
			</Grid>
			<Grid item xs={2} >
				<Typography className={classes.title}>{screenTitle}</Typography>
			</Grid>
			<Grid style={{ flexWrap: 'nowrap' }} item xs={9} container justify="flex-end" alignItems="center">
				<PatientSelect />
				{/* <UserAvatar /> */}
			</Grid>
		</Grid>

	return (
		<Grid container className={classes.header}>
			<Grid item md={8} id="clinical-header">
				{routeList.map((e, i) => (
					<CanUser
						key={i}
						perform={e.permission}
						yes={() => (
							<CustomLink route={e} unreadMemos={props.unreadMemos} openPopover={openPopover} />
						)}
					/>
				))}
			</Grid>
			<Grid className={classes.avaterPart} style={{ flexWrap: 'nowrap' }} item md={4} container justify="flex-end" alignItems="center">
				<PatientSelect />
				<ZendeskPopover />
				<UserAvatar />
			</Grid>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: classes.popoverPaper,
				}}
				getContentAnchorEl={null}
			>
				{subroute.map((sub, i) => (
					<CanUser
						key={`sub-${i}`}
						perform={sub.permission}
						yes={() => (
							<CustomSubLink route={sub} onClick={() => setAnchorEl(null)} />
						)}
					/>
				))}
			</Menu>
			{/* <Onboarding steps={headerSteps} updateTrigger={routeList} /> */}
		</Grid>
	)
}

Header.propTypes = {
	unreadMemos: PropTypes.array.isRequired,
	isMobile: PropTypes.bool.isRequired,
	toggleSidebar: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
	unreadMemos: state.data.unreadMemos,
	isMobile: state.general.isMobile,
});

export default connect(mapStateToProps)(Header);
