import React, {useState }  from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button, InputLabel, TextField, Grid, IconButton, Select, MenuItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import cloneDeep from 'lodash.clonedeep';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

export const defaultRatingScale = [
    {
        id: 0,
        question: '',
        ratings: [
            { value: 1, desc: '' },
        ]
    },
];

const RatingScaleData = (props) => {
    const classes = useTargetDataStyles();
    const { ratingScales, setRatingScales, itemError, disabled, isMobile } = props;
    const [newRatingScaleList, setNewRatingScaleList] = useState([]);

    const handleChangeRatingScale = (id, field) => event => {
        const newRatingScales = ratingScales.slice();
        if (newRatingScales[id].id && !newRatingScales[id].preModifiedVersion) {
            newRatingScales[id].preModifiedVersion = { ...newRatingScales[id] };
        }
        newRatingScales[id][field] = event.target.value;
        setRatingScales(newRatingScales);
    }

    const handleChangeDescription = (id, ratingId) => event => {
        const newRatingScales = ratingScales.slice();
        newRatingScales[id].ratings[ratingId].desc = event.target.value;
        setRatingScales(newRatingScales);
    }

    const addRatingScale = () => {
        let newRatingScales = ratingScales.slice();
        newRatingScales.push(cloneDeep(defaultRatingScale[0]));
        newRatingScales = newRatingScales.map((scale, _i) => ({
            ...scale
        }))
        setRatingScales(newRatingScales);
        setNewRatingScaleList([...newRatingScaleList, newRatingScales.length - 1]);
    }

    const handleDeleteRatingScale = (id) => () => {
        let newRatingScales = ratingScales.slice();
        newRatingScales[id] = { ...newRatingScales[id], isDeleted: true };
        newRatingScales = newRatingScales.map((scale, _i) => ({
            ...scale
        }))
        setRatingScales(newRatingScales);
        setNewRatingScaleList(newRatingScaleList.filter(rs => rs !== id));
    }

    const handleDeleteRatingScaleRating = (scaleId, ratingId) => () => {
        const newRatingScales = ratingScales.slice();
        newRatingScales[scaleId].ratings[ratingId] = { ...newRatingScales[scaleId].ratings[ratingId], isDeleted: true };
        setRatingScales(newRatingScales);
    }

    const handleAddRatingScaleRating = (scaleId) => () => {
        const newRatingScales = ratingScales.slice();
        newRatingScales[scaleId].ratings.push(cloneDeep(defaultRatingScale[0].ratings[0]));
        setRatingScales(newRatingScales);
    }

    const handleChangeScale = (id, ratingId) => (e) => {
        const newRatingScales = ratingScales.slice();
        const newRatings = newRatingScales[id].ratings;
        if (newRatings[ratingId].id && !newRatings[ratingId].preModifiedVersion) {
            newRatings[ratingId].preModifiedVersion = { ...newRatings[ratingId] };
        }
        newRatings[ratingId].value = parseInt(e.target.value, 10);
        newRatingScales[id].ratings.slice(ratingId, 1, newRatings[ratingId]);
        setRatingScales(newRatingScales);
    }

    const trimmedScalings = (ratingScale) => {
        return ratingScale.ratings.map(e => e.value.toString());
    }

    const checkScalingError = (id, rating) => {
        const error = {};
        if (checkDuplicates(trimmedScalings(ratingScales[id]), rating.value.toString())) {
            error.scaling = "Scaling must be unique";
        }
        return error;
    }

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <p id="target-form-rating-scale-section-heading" className={classes.wrapperTitle}>Rating Scale Data</p>
            </Grid>
            {ratingScales.map((ratingScale, id) => (
                <Grid item xs={isMobile? 12 : 6} container key={`ratingScale${id}`} className={classes.dataRowWrapper}>
                    <Grid item xs={12}>
                        <InputLabel id={`target-form-rating-scale-question-field-label-${id}`} required>Question {id+1}</InputLabel>
                        <TextField
                            id={`target-form-rating-scale-question-field-${id}`}
                            variant="outlined"
                            value={ratingScale.question}
                            onChange={handleChangeRatingScale(id, 'question')}
                            style={{ width: '85%' }}
                            error={itemError[`ratingScale ${id}`] && !ratingScale.question.trim()}
                            helperText={itemError[`ratingScale ${id}`] && !ratingScale.question.trim() ? "Rating scale question can't be empty" : null}
                            disabled={disabled && !newRatingScaleList.includes(id)}
                        />
                        {ratingScales.length > 1 ?
                            <IconButton 
                                id={`target-form-rating-scale-question-delete-${id}`}
                                disabled={disabled && !newRatingScaleList.includes(id)}
                                className={classes.deleteBtn}
                                onClick={handleDeleteRatingScale(id)}
                                tabIndex={-1}
                            >
                                <CloseIcon />
                            </IconButton>
                            : null
                        }
                    </Grid>
                    {
                        ratingScale.ratings.map((rating, ratingId) => {
                            const scalingError = itemError[`ratingScale ${id} ${ratingId}`] && checkScalingError(id, rating) || {};
                            return (
                                <React.Fragment key={ratingId}>
                                    <Grid item xs={2} className={classes.ratingDescription}>
                                        <InputLabel id={`target-form-rating-scale-rating-field-label-${id}-${ratingId}`}>Rating {ratingId + 1}</InputLabel>                      
                                        <TextField
                                            id={`target-form-rating-scale-rating-field-${id}-${ratingId}`}
                                            select
                                            value={rating.value}
                                            onChange={handleChangeScale(id, ratingId)}
                                            SelectProps={{
                                                native: true,
                                            }}
                                            error={!!scalingError.scaling && scalingError.scaling !== " "}
                                            helperText={scalingError.scaling || ''}
                                        >

                                            {
                                                [...new Array(10)].map((d, _i) => (
                                                    <option key={`scale ${_i}`} value={_i+1}>{_i+1}</option>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={10} className={classes.ratingDescription}>
                                        <InputLabel id={`target-form-rating-scale-description-field-label-${id}-${ratingId}`}>Rating Description {ratingId + 1}</InputLabel>
                                        <TextField
                                            id={`target-form-rating-scale-description-field-${id}-${ratingId}`}
                                            variant="outlined"
                                            value={rating.desc}
                                            onChange={handleChangeDescription(id, ratingId)}
                                            style={{ width: '82%' }}
                                            disabled={disabled && !newRatingScaleList.includes(id)}
                                            error={!!scalingError.desc && scalingError.desc !== " "}
                                            helperText={scalingError.desc || ''}
                                        />
                                        {
                                            ratingScale.ratings.length > 1 ?
                                                <IconButton
                                                    id={`target-form-rating-scale-rating-delete-${id}-${ratingId}`}
                                                    disabled={disabled && !newRatingScaleList.includes(id)}
                                                    className={classes.deleteBtn}
                                                    onClick={handleDeleteRatingScaleRating(id, ratingId)}
                                                    tabIndex={-1}
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                                : null
                                        }
                                        {
                                            ratingId === ratingScale.ratings.length - 1 && ratingScale.ratings.length < 10 ?
                                                <IconButton
                                                    id={`target-form-rating-scale-rating-add-${id}`}
                                                    disabled={disabled && !newRatingScaleList.includes(id)}
                                                    className={classes.addBtn}
                                                    onClick={handleAddRatingScaleRating(id)}
                                                    tabIndex={-1}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                : null
                                        }
                                    </Grid>
                                </React.Fragment>
                            )
                        })
                    }
                </Grid>
            ))}
            {!isMobile && ratingScales.length % 2 === 0 &&
                <Grid item xs={6} />
            }
            {ratingScales.length < 8 &&
                <Grid item xs={isMobile ? 12 : 6} className={classes.addMoreWrapper} container alignItems="center" justify="center">
                    <Button id="target-form-rating-scale-add-more" className={classes.saveBtn} onClick={addRatingScale}>Add More</Button>
                </Grid>
            }
        </Grid>
    )
}

RatingScaleData.propTypes = {
    ratingScales: PropTypes.array.isRequired,
    setRatingScales: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isMobile: state.general.isMobile,
})

export default connect(mapStateToProps, { setSnackbar })(RatingScaleData)
