import agent from './agent';

export const createDomain = data => {
    return agent.post('/domain/create', data);
}

export const updateDomain = (domainId, data) => {
    return agent.put(`/domain/update`, { id: domainId, ...data });
}

export const deleteDomain = (domainId) => {
    return agent.delete('/domain/delete', {
        params: {
            id: domainId,
        },
    });
}

export const reorderDomain = (id, newPos) => {
    return agent.post('/domain/reorder', { id, newPos });
}

export const archiveDomain = (domainId, unarchive = false) => {
    if (unarchive) {
        return agent.put(`/domain/un-archive`, { id: domainId });
    } else {
        return agent.put(`/domain/update`, { id: domainId, status: 'archived' });
    }
}
