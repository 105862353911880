import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';

import withDataTableHoc from './DataTableHoc';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: 'collapse',
    },
    cell: {
        border: '1px solid black !important',
        padding: 2,
        minWidth: 15,
        height: 20,
        minHeight: 20,
    },
    topSeparator: {
        padding: 2,
        height: 25,
        minHeight: 25,
        minWidth: 15,
    },
    text: {
        margin: 0,
    },
    separatorCell: {
        width: 10,
        minWidth: 10,
    },
    value: {
        display: 'inline-block',
        width: 40,
        maxWidth: 40,
    },
    textField: {
        width: 40,
        maxWidth: 40,
    },
    input: {
        padding: '0px !important',
        textAlign: 'center',
    },
    deleteBtn: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#d32f2f' },
    },
    deleteActive: {
        opacity: 1,
        color: '#d32f2f',
    },
    menuItem: {
        justifyContent: 'center',
    },
    addButton: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#0FB99E' },
    },
    hidden: {
        display: 'none',
    }
}));

const createCustomData = (data) => {
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    for (let index = 0; index < data.value.length; index++) {
        const value = Object.entries(data.value[index])
            .filter((data) => !!data[1])
            .map((data) => ({
                set: data[0],
                id: data[1],
                time: dateNow + index,
            }));
        value.length > 0 && customData.push([value, dateNow]);
    }

    return customData;
};

const Probe = (props) => {
    const classes = useStyles();
    const { isEdit, dataToUse, setEditingData, customData, handleNewDelete, handleAdd, errors, setErrors } = props;
    const { sets, codes } = customData;

    const formattedData = useMemo(() => {
        const newProbeData = [];
        dataToUse &&
            dataToUse.forEach((data) => {
                const newProbe = {
                    value: {},
                    date: data.date,
                    isDelete: data.isDelete,
                };

                sets.forEach((set) => {
                    newProbe.value[set.id] = [];
                });
                data.value.forEach((value) => {
                    sets.forEach((set) => {
                        const code = value[set.id] ? codes.find(e => e.id === value[set.id]) : null;
                        newProbe.value[set.id].push(code);
                    });
                });
                newProbeData.push(newProbe);
            });
        return newProbeData;
    }, [dataToUse]);

    const requiredSetIds = (customData?.sets ?? []).filter(e => e.isRequired && !e.isDeleted).map(e => e.id);

    useEffect(() => {
        if (customData && dataToUse && requiredSetIds?.length) {
            validateData();
        }
    }, [customData, requiredSetIds, dataToUse]);

    const validateData = () => {
        const hasError = requiredSetIds?.length && !!dataToUse.find(item => {
            return (item.value ?? []).find(value => {
                return requiredSetIds.find(e => !value[e]);
            });
        });
        setErrors(hasError);
    };

    const handleChange = (index, index2, key) => (event) => {
        const newData = dataToUse;
        newData[index].value[index2][key] = event.target.value;
        validateData();
        setEditingData(newData.slice());
    };

    const dataKeys = sets.map(e => e.id) || [];
    return (
        <Grid item xs={12} container>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        {formattedData.map((data, index) => (
                            <React.Fragment key={`probe-${index}`}>
                                <td
                                    align='center'
                                    className={classes.topSeparator}
                                    colSpan={data.value[dataKeys[0]].length}
                                ></td>
                                {formattedData.length > 1 && index !== formattedData.length - 1 && (
                                    <td className={classes.separatorCell}></td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    {dataKeys.map((key, keyIndex) => (
                        <tr key={key}>
                            {formattedData.map((data, index) => (
                                <React.Fragment key={`probe-${index}`}>
                                    {data.value[key].map((value, index2) => (
                                        <td key={index2} align='center' className={classes.cell}>
                                            {isEdit ? (
                                                <TextField
                                                    value={value?.id}
                                                    className={classes.textField}
                                                    InputProps={{
                                                        classes: { input: classes.input },
                                                    }}
                                                    error={errors && !value?.id && requiredSetIds.includes(key)}
                                                    onChange={handleChange(index, index2, key)}
                                                    select
                                                    SelectProps={{ IconComponent: () => null }}
                                                    disabled={data.isDelete || sets[keyIndex]?.isDeleted || dataToUse[index]?.isOngoing}
                                                >
                                                    {codes.map((entry) => (
                                                        <MenuItem
                                                            key={entry.id}
                                                            className={`${classes.menuItem} ${entry.isDeleted ? classes.hidden : ''}`}
                                                            value={entry.id}
                                                        >
                                                            {entry.code}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        value=''
                                                    >
                                                        -
                                                    </MenuItem>
                                                </TextField>
                                            ) : (
                                                <span className={classes.value}>{value?.code}</span>
                                            )}
                                        </td>
                                    ))}
                                    {isEdit && keyIndex === 0 && (
                                        <td className={classes.cell} rowSpan={dataKeys.length}>
                                            <Grid
                                                container
                                                style={{ height: '100%' }}
                                                className={classes.textField}
                                                alignContent='center'
                                                justify='center'
                                            >
                                                <AddIcon
                                                    color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                    className={classes.addButton}
                                                    onClick={!dataToUse[index]?.isOngoing && handleAdd(index)}
                                                />
                                            </Grid>
                                        </td>
                                    )}
                                    {formattedData.length > 1 &&
                                        index !== formattedData.length - 1 && (
                                            <td className={classes.separatorCell}></td>
                                        )}
                                </React.Fragment>
                            ))}
                        </tr>
                    ))}
                    {isEdit && (
                        <tr>
                            {formattedData.map((data, index) => (
                                <React.Fragment key={index}>
                                    {data.value[dataKeys[0]].map((value, index2) => (
                                        <td key={index2} align='center'>
                                            <DeleteIcon
                                                color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                className={classes.deleteBtn}
                                                onClick={!dataToUse[index]?.isOngoing && handleNewDelete(index, index2)}
                                            />
                                        </td>
                                    ))}
                                    <td align='center' valign='middle'>
                                        {data.value[dataKeys[0]].length === 0 && (
                                            <Grid
                                                container
                                                style={{ height: '100%' }}
                                                alignContent='center'
                                                justify='center'
                                            >
                                                <Tooltip title='This session data will be deleted'>
                                                    <DeleteIcon
                                                        color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                        className={clsx(
                                                            classes.deleteBtn,
                                                            classes.deleteActive
                                                        )}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </td>
                                    {formattedData.length > 1 &&
                                        index !== formattedData.length - 1 && (
                                            <td className={classes.separatorCell}></td>
                                        )}
                                </React.Fragment>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        </Grid>
    );
};

Probe.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    customData: PropTypes.object,
    setErrors: PropTypes.func,
    errors: PropTypes.object
};

export default withDataTableHoc(Probe, 'probe', createCustomData);
