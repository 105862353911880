import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    InputLabel,
    TextField,
    Grid,
    IconButton,
    Tooltip,
    Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

// TODO:: Delete old format
// export const defaultPrompt = [
//     { acronym: '', name: '', isNegative: false }
// ];

export const defaultPrompt = {
    codes: [{ correctness: true, code: '', codeDesc: '' }], // TODO:: Format { "correctness": true, "code": "ABC", "codeDesc": "Alpha Beta Correction" }
};

const defaultCode = { correctness: true, code: '', codeDesc: '' };

const PromptData = (props) => {
    const classes = useTargetDataStyles();
    const { prompts, setPrompts, setSnackbar, itemError, disabled, isMobile } = props;
    const [newPromptList, setNewPromptList] = useState([]);

    const handleChangeCodes = (field, id) => (event) => {
        const newPrompts = { ...prompts };
        if (field === 'code' && event.target.value.length > 4) {
            return;
        }
        if (field === 'code' && newPrompts.codes[id].id && !newPrompts.codes[id].preModifiedVersion) {
            newPrompts.codes[id].preModifiedVersion = { ...newPrompts.codes[id] };
        }
        newPrompts.codes[id][field] =
            field === 'correctness' ? event.target.checked : event.target.value;
        setPrompts(newPrompts);
    };

    const addCode = () => {
        const newPrompts = { ...prompts };
        newPrompts.codes.push({ ...defaultCode });
        if (newPrompts.codes.length === 8) {
            setSnackbar('warning', 'Maximum 8 codes allowed!');
        }
        setPrompts(newPrompts);
        setNewPromptList([...newPromptList, newPrompts.codes.length - 1]);
    };

    const deleteCodeById = (id) => () => {
        const newCodes = [...prompts.codes];
        newCodes[id] = { ...newCodes[id], isDeleted: true };

        setPrompts({
            ...prompts,
            codes: newCodes,
        });
        setNewPromptList(newPromptList.filter(c => c !== id));
    };

    const trimmedCodes = useMemo(() => {
        return prompts.codes.map((e) => e.code.trim());
    }, [prompts.codes]);

    const checkCodesError = (value) => {
        if (!value.code.trim()) {
            return "Code can't be empty";
        }
        if (checkDuplicates(trimmedCodes, value.code.trim())) {
            return 'Code must be unique';
        }
        return ' ';
    };

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <p id="target-form-prompt-section-heading" className={classes.wrapperTitle}>Prompt Data</p>
            </Grid>
            {prompts.codes.map((code, id) => {
                const entryError = itemError[`prompt ${id}`] && checkCodesError(code);
                return (
                    <Grid
                        item
                        xs={isMobile ? 12 : 6}
                        container
                        key={`prompt${id}`}
                        className={classes.dataRowWrapper}
                    >
                        <Grid item xs={3} style={{ paddingLeft: 10, position: 'relative' }}>
                            <InputLabel id={`target-form-prompt-plus-minus-field-label-${id}`} className={classes.plusMinusLabel}>
                                <span>+/-</span>
                                <Tooltip title='This column defines whether a response is considered a correct or incorrect response.'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </InputLabel>
                            <Switch
                                id={`target-form-prompt-plus-minus-field-${id}`}
                                onChange={handleChangeCodes('correctness', id)}
                                checked={prompts.codes[id]?.correctness}
                                classes={{
                                    track: prompts.codes[id]?.correctness
                                        ? classes.plusTrack
                                        : classes.minusTrack,
                                    switchBase: prompts.codes[id]?.correctness
                                        ? classes.plusThumb
                                        : classes.minusThumb,
                                }}
                                icon={<RemoveCircleIcon className={classes.switchIcon} />}
                                checkedIcon={<AddCircleIcon className={classes.switchIcon} />}
                                disabled={disabled && !newPromptList.includes(id)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id={`target-form-prompt-code-field-label-${id}`} required>Code {id + 1}</InputLabel>
                            <TextField
                                id={`target-form-prompt-code-field-${id}`}
                                variant='outlined'
                                value={prompts.codes[id].code}
                                onChange={handleChangeCodes('code', id)}
                                style={{ width: '80%' }}
                                error={!!entryError && entryError !== ' '}
                                helperText={entryError}
                                disabled={disabled && !newPromptList.includes(id)}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: 'nowrap' }}>
                            <InputLabel id={`target-form-prompt-description-field-label-${id}`} required>Code Description {id + 1}</InputLabel>
                            <TextField
                                id={`target-form-prompt-description-field-${id}`}
                                variant='outlined'
                                value={prompts.codes[id].codeDesc}
                                onChange={handleChangeCodes('codeDesc', id)}
                                style={{ width: '75%' }}
                                error={
                                    itemError[`prompt ${id}`] && !prompts.codes[id].codeDesc.trim()
                                }
                                helperText={
                                    itemError[`prompt ${id}`] && !prompts.codes[id].codeDesc.trim()
                                        ? "Description can't be empty"
                                        : ' '
                                }
                                disabled={disabled && !newPromptList.includes(id)}
                            />
                            {prompts.codes.length > 1 ? (
                                <IconButton
                                    id={`target-form-prompt-section-delete-${id}`}
                                    disabled={disabled && !newPromptList.includes(id)}
                                    className={classes.deleteBtn}
                                    onClick={deleteCodeById(id)}
                                    tabIndex={-1}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </Grid>
                    </Grid>
                );
            })}
            {!isMobile && prompts.codes.length % 2 === 0 && <Grid item xs={6} />}
            {prompts.codes.length < 8 && (
                <Grid
                    item
                    xs={isMobile ? 12 : 6}
                    className={classes.addMoreWrapper}
                    container
                    alignItems='center'
                    justify='center'
                >
                    <Button id="target-form-prompt-add-more" className={classes.saveBtn} onClick={addCode}>
                        Add More
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

PromptData.propTypes = {
    prompts: PropTypes.object.isRequired,
    setPrompts: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, { setSnackbar })(PromptData);
