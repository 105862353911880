import React, { useState } from 'react';
import PropTypes from 'prop-types';
import randomcolor from 'randomcolor';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import Highlighter from 'react-highlight-words';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import { deleteMemo, updateMemo } from '../../../services/MemoService';
import { setGlobalDialog, setSnackbar } from '../../../store/general/actions';

const useStyles = makeStyles((theme) => ({
    itemWrapper: {
        borderBottom: '1px solid #a8a8a8',
    },
    avatarCell: {
        border: 'none',
        width: 40,
        padding: 12,
    },
    date: {
        margin: 0,
        color: '#a8a8a8',
        fontSize: 12,
        paddingLeft: 5,
    },
    memo: {
        margin: 0,
        padding: 5,
        lineHeight: 1.5,
    },
    memoCell: {
        border: 'none',
        padding: 12,
    },
    memoMessage: {
        fontSize: 15,
        whiteSpace: 'break-spaces',
    },
    actionCell: {
        border: 'none',
        padding: 12,
        width: 80,
    },
    iconButton: {
        padding: 4,
        marginLeft: 4,
    },
    actionText: {
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: 5,
        '&:hover': { color: '#0FB99E' },
    },
    actionTextActive: {
        textDecoration: 'underline',
        cursor: 'pointer',
        display: 'inline-block',
        marginLeft: 5,
        color: '#0FB99E',
    },
    input: {
        padding: '5px',
        lineHeight: '16px',
    },
}));

const MemoItem = (props) => {
    const classes = useStyles();
    const { memo, search, user, setGlobalDialog, fetchMemo, setSnackbar } = props;
    const [isEdit, setIsEdit] = useState(false);
    const [editMemo, setEditMemo] = useState(memo.message);
    const [isError, setIsError] = useState(false);

    const generateInitial = (user) => {
        const firstInitial = user.firstName?.substring(0, 1) || '';
        const lastInitial = user.lastName?.substring(0, 1) || '';
        return firstInitial + lastInitial;
    };

    const generateSeed = (user) => {
        return generateInitial(user) + user.id;
    };

    const handleDelete = () => {
        setGlobalDialog('Delete memo?', 'Deleted memo cannot be restored, please proceed with caution.', (answer) => proceedDelete(answer));
    };

    const proceedDelete = (answer) => {
        if (answer) {
            deleteMemo(memo.id).then(() => {
                setSnackbar('success', 'Memo deleted!');
                fetchMemo();
            });
        }
    };

    const handleSave = () => {
        if (!editMemo.trim()) {
            setIsError(true);
            return;
        }
        updateMemo(memo.id, editMemo).then(() => {
            setSnackbar('success', 'Memo updated!');
            fetchMemo();
            setIsEdit(false);
        });
    };

    const startEdit = () => {
        setEditMemo(memo.message);
        setIsEdit(true);
    };

    const canEditDelete = user.isCompanyAdmin || user.id === memo.user.id;
    return (
        <TableRow key={memo.id} className={classes.itemWrapper}>
            <TableCell className={classes.avatarCell}>
                <Avatar style={{ backgroundColor: randomcolor({ seed: generateSeed(memo.user), luminosity: 'dark' }) }}>{generateInitial(memo.user)}</Avatar>
            </TableCell>
            <TableCell className={classes.memoCell}>
                <Badge variant='dot' invisible={memo.isRead} color='error'>
                    <p className={classes.date}>
                        <b>{`${memo.user.firstName} ${memo.user.lastName} `}</b>
                        {format(new Date(memo.createdAt), 'yyyy-MM-dd HH:mm')}
                        {!isEdit && canEditDelete && CanUserFunc(access.notes.edit) && (
                            <span id={`notes-memo-item-edit-${memo.id}`} className={classes.actionText} onClick={startEdit}>
                                Edit
                            </span>
                        )}
                        {!isEdit && canEditDelete && CanUserFunc(access.notes.remove) && (
                            <span id={`notes-memo-item-delete-${memo.id}`} className={classes.actionText} onClick={handleDelete}>
                                Delete
                            </span>
                        )}
                        {isEdit && (
                            <span id={`notes-memo-item-cancel-${memo.id}`} className={classes.actionText} onClick={() => setIsEdit(false)}>
                                Cancel
                            </span>
                        )}
                        {isEdit && (
                            <span id={`notes-memo-item-save-${memo.id}`} className={classes.actionTextActive} onClick={handleSave}>
                                Save
                            </span>
                        )}
                    </p>
                </Badge>
                {isEdit ? (
                    <TextField
                        variant='outlined'
                        value={editMemo}
                        onChange={(event) => setEditMemo(event.target.value)}
                        fullWidth
                        multiline
                        InputProps={{ classes: { root: classes.input } }}
                        error={isError && !editMemo.trim()}
                        helperText={isError && !editMemo.trim() && 'Cannot be empty'}
                    />
                ) : (
                    <p className={classes.memo}>
                        <Highlighter className={classes.memoMessage} searchWords={[search]} autoEscape={true} textToHighlight={memo.message} />
                    </p>
                )}
            </TableCell>
        </TableRow>
    );
};

MemoItem.propTypes = {
    memo: PropTypes.object.isRequired,
    search: PropTypes.string,
    fetchMemo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setGlobalDialog, setSnackbar })(MemoItem);
