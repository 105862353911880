import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import TableHead from '@material-ui/core/TableHead';
import format from 'date-fns/format';
import { connect } from 'react-redux';

import { updateTargetData, deleteTargetData } from '../../../services/TargetService';
import { setLoading, setGlobalDialog } from '../../../store/general/actions';
import LoadingCheck from '../../../assets/progress/LoadingCheck';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';

const useStyles = makeStyles(theme => ({
	notesTblHeadRow: {
		backgroundColor: '#F4F4F4',
	},
	headCell: {
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		verticalAlign: 'middle !important',
		padding: '12px 16px',
	},
	closeIconBtn: {
		padding: '0px 4px',
		color: '#0FB99E',
	},
	dialogPaper: {
		minWidth: 900,
		minHeight: 400,
	},
	dateCell: {
		width: '15%',
		verticalAlign: 'top',
		color: 'black',
		fontSize: 14,
		border: 'none',
	},
	textCell: {
		whiteSpace: 'pre-line',
		border: 'none',
		width: '20%'
	},
	lastTextCell: {
		whiteSpace: 'pre-line',
		border: 'none',
		width: '100%'
	},
	actionCell: {
		padding: '13px 8px',
		width: 100,
		maxWidth: 100,
		border: '1px solid #ffffff',
	},
	iconButton: {
		padding: '4px',
		marginLeft: 4,
	},
	submitButton: {
		color: '#0FB99E',
	},
	cancelButton: {
		color: '#d32f2f',
	},
	input: {
		padding: '5px',
		lineHeight: '16px',
	},
	notes: {
		padding: '5px',
		whiteSpace: 'pre-line',
		display: 'inline-block',
		lineHeight: '16px',
	},
	tableRow: {
		borderBottom: '1px solid rgba(224, 224, 224, 1)',
		'&:last-child': {
			borderBottom: '1px solid #0FB99E',
		}
	}
}));

const TextDialog = (props) => {
	const classes = useStyles();
	const { open, handleClose, name, loading, setLoading, setGlobalDialog, fetchData, customData } = props;
	const [textData, setTextData] = useState(null);
	const [isEditId, setIsEditId] = useState(0);
	const [updatedTextData, setUpdatedTextData] = useState(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		setTextData(props.textData);
	}, [props.textData])

	const getValue = (rawValue) => {
		return typeof rawValue === 'string' ? rawValue.replace(/\s/g,' ') : rawValue?.join(', ').replace(/\s/g,' ');
	}

	const handleChangeText = (name) => (event) => {
		setUpdatedTextData({ ...updatedTextData, [name]: [event.target.value] });
	}

	const submitUpdate = () => {
		if (!!updatedTextData && Object.values(updatedTextData).some(e => !getValue(e))) {
			let hasError = false;
			for (let key in updatedTextData) {
				if (requiredFields.includes(+key) 
					&& typeof updatedTextData[key] !== 'undefined' 
					&& !getValue(updatedTextData[key])) {
						hasError = true;
				}
			}
			if (hasError) {
				setError(true);
				return;
			}
		}

		if (!!updatedTextData) {
			setLoading(`updateTextData`);
			updateLocalText(isEditId, updatedTextData);
			const date = Math.floor(Date.now() / 1000);
			const customData = [[updatedTextData, date]];
			updateTargetData(isEditId, customData, null).then(() => {
				setLoading(`updateTextData`, false);
				fetchData(false);
				handleCancelEdit();
			});
		} else {
			handleCancelEdit();
		}
	}

	const updateLocalText = (id, updatedText) => {
		const newTextData = textData;
		const updatedTextObj = newTextData.find(e => e.id === id);
		if (updatedTextObj) {
			updatedTextObj.value = updatedText;
		}
		setTextData(newTextData.slice());
	}

	const deleteLocalText = (id) => {
		const newTextData = textData;
		const textIndex = newTextData.findIndex(e => e.id === id);
		newTextData.splice(textIndex, 1);
		setTextData(newTextData.slice());
	}

	const handleAskDelete = (id) => () => {
		setIsEditId(id);
		setLoading('updateTextData');
		setGlobalDialog(
			'Delete text data?',
			'Deleted text data and session note cannot be restored, please proceed with caution.',
			(answer) => handleDelete(answer, id)
		)
	}

	const handleDelete = (answer, id) => {
		if (answer) {
			deleteLocalText(id);
			deleteTargetData(id).then(() => {
				setLoading('updateTextData', false);
				fetchData();
				handleCancelEdit();
			});
		} else {
			setLoading('updateTextData', false);
			handleCancelEdit();
		}
	}

	const handleStartEdit = (targetDataId) => () => {
		setIsEditId(targetDataId);
		const text = textData.find(e => e.id === targetDataId);
		const initialTextData = {};
		textFieldIds.forEach(e => {
			initialTextData[e] = [''];
		});
		setUpdatedTextData({ ...initialTextData, ...text.value });
	}

	const handleCancelEdit = () => {
		setIsEditId(0);
	}

	const renderEditActions = () => {
		return (
			<React.Fragment>
				<IconButton className={`${classes.iconButton} ${classes.cancelButton}`} onClick={handleCancelEdit}>
					<CloseIcon />
				</IconButton>
				<IconButton className={`${classes.iconButton} ${classes.submitButton}`} onClick={submitUpdate}>
					<DoneIcon />
				</IconButton>
			</React.Fragment>
		)
	}

	const renderActions = (text) => {
		if (isEditId === text.id) {

			if (loading.updateTextData || loading.getTargetData) {
				return (
					<LoadingCheck show size={24} />
				)
			} else {
				return renderEditActions();
			}
		}

		return (
			<React.Fragment>
				{CanUserFunc(access.data.edit) && 
					<IconButton disabled={text.isOngoing} className={classes.iconButton} onClick={handleStartEdit(text.id)}>
						<EditIcon />
					</IconButton>
				}
				{CanUserFunc(access.data.remove) &&
					<IconButton disabled={text.isOngoing} className={classes.iconButton} onClick={handleAskDelete(text.id)}>
						<DeleteIcon />
					</IconButton>
				}
			</React.Fragment>
		)
	}
	
	const textFieldNames = customData?.map(e => e.value) ?? [];
	const textFieldIds = customData?.map(e => e.id) ?? [];
	const requiredFields = customData?.filter(e => e.isRequired)?.map(e => e.id) ?? [];

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			classes={{
				paper: classes.dialogPaper
			}}
		>
			<DialogTitle className={classes.dialogTitle}>
				<Grid container>
					<Grid item xs={6}>
						{name}
					</Grid>
					<Grid item xs={6} container justify="flex-end">
						<IconButton className={classes.closeIconBtn} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Table>
					<TableHead>
						<TableRow className={classes.notesTblHeadRow}>
							<TableCell className={`${classes.dateCell} ${classes.headCell}`}>Date/Time</TableCell>
							{textData && textFieldNames?.map((value, index, arr) => (
								<TableCell key={`head-${index}`} style={{ width: `${55 / textFieldNames.length}%` }} className={`${classes.noteCell}${classes.headCell}`}>{value}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{textData && textData.map((text) => (
							<TableRow key={`${text.id}`} className={classes.tableRow}>
								<TableCell align="left" className={classes.dateCell}>
									<p className={classes.date}>{format(new Date(text.date), "MM/d/yyyy")}</p>
									<p className={classes.date}>{format(new Date(text.date), "HH:mm")}</p>
								</TableCell>
								{textFieldNames.map((e, index) => (
									<TableCell  
										key={`${text.id}-${index}`}    
										align="left" 
										className={index === textFieldNames.length - 1 ? classes.lastTextCell : classes.textCell } 
									>
										{[text?.value[textFieldIds[index]]]?.map((t, index3) => {
											let currentValue = getValue(t);

											return isEditId === text.id ?
												<TextField
													variant="outlined"
													defaultValue={currentValue}
													onChange={handleChangeText(textFieldIds[index])}
													fullWidth
													multiline
													InputProps={{ classes: { root: classes.input } }}
													error={error && !getValue(updatedTextData[textFieldIds[index]]) && requiredFields.includes(textFieldIds[index])}
													placeholder={requiredFields.includes(textFieldIds[index]) ? "Must be filled" : ''}
												/>
												:
												<span className={classes.notes}>{currentValue}</span>
										})}
									</TableCell>
								))}
								<TableCell align="right" className={classes.actionCell}>
									{renderActions(text)}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</DialogContent>
		</Dialog>
	)
}

TextDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	textData: PropTypes.array,
	customData: PropTypes.array,
	name: PropTypes.string,
	fetchData: PropTypes.func.isRequired,
	loading: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(TextDialog);
