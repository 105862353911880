import {
    SET_LIBRARIES,
    SET_LIB_FILTER,
    SET_LIB_TABLE_FILTER,
    SET_REMINDERS,
} from './action-types';

export const setReminders = (data) => (dispatch) => {
    return dispatch({
        type: SET_REMINDERS,
        payload: data,
    });
};

export const setLibFilter = (data) => (dispatch) => {
    return dispatch({
        type: SET_LIB_FILTER,
        payload: data,
    });
};

export const setLibTableFilter = (data) => (dispatch) => {
    return dispatch({
        type: SET_LIB_TABLE_FILTER,
        payload: data,
    });
};

export const setLibraries = (data) => (dispatch) => {
    return dispatch({
        type: SET_LIBRARIES,
        payload: data,
    });
};
