import React, { useState, useEffect } from 'react';
import { Button, DialogTitle, DialogContent, Dialog, Grid, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { logout, showUpdateSubsctiptionDialog, tokenLogin } from '../../store/auth/actions';
import { subscriptionStatuses } from '../../constants';
import dateToDays from '../../utils/dateToDays';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    saveBtn: {
        padding: '6px 20px',
        margin: '18px 5px'
    },
}));

const UpdateSubscription = (props) => {
    const classes = useStyles();
    const { showUpdateSubsctiption, showUpdateSubsctiptionDialog, logout, tokenLogin, user } = props;
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [expired, setExpired] = useState(false);
    const [updateClicked, setUpdateClicked] = useState(false);
    const [updating, setUpdating] = useState(false);
    const subscription = user.subscription;
    const history = useHistory();
    const location = useLocation();
    const accountInfoPath = '/admin/subscription/account-info';

    useEffect(() => {
        if(!subscription) return;

        const status = subscription.status;
        const endDate = subscription.current_term_end || subscription.trial_end;
        const termEnd = new Date(endDate * 1000);
        const now = new Date();
        const diffDays = dateToDays(termEnd) - dateToDays(now);

        if(!endDate || termEnd < now || diffDays === 0)  setExpired(true);

        switch(status) {
            case(subscriptionStatuses.cancelled):
            case(subscriptionStatuses.non_renewing):
            case(subscriptionStatuses.paused):
                if(!termEnd || termEnd < now || diffDays === 0) {
                    setTitle('Your subscription is not valid');
                    setContent('Please renew your subscription to continue');
                    break;
                }

                if(diffDays <= 7) {
                    diffDays === 1 ? 
                        setTitle(`Your subscription expires in 1 day`)
                        : setTitle(`Your subscription expires in ${diffDays} days`);
                    setContent('Do you want to update you subscription?');
                    break;
                }
            case(subscriptionStatuses.in_trial):
                if(termEnd < now || diffDays === 0) {
                    setTitle('Your free trial has expired');
                    setContent('Please upgrade your subscription to continue');
                    break;
                }

                if(diffDays <= 7) {
                    diffDays === 1 ? setTitle(`Your free trial expires in 1 day`)
                    : setTitle(`Your free trial expires in ${diffDays} days`);
                    setContent('Do you want to upgrade you subscription?');
                    break;
                }
            default:
                showUpdateSubsctiptionDialog(false);
        }
    }, [subscription]);

    const handleCancel = () => {
        showUpdateSubsctiptionDialog(false);

        if(expired) logout();   
        else window.location.reload();     
    };

    const handleUpdate = () => {
        const link = document.querySelector('#chargebee-tag');
        link.click();
        setUpdateClicked(true);
    };

    const handleCheck = () => {
        setUpdating(true);
        tokenLogin(false).then(() => {
            history.push(accountInfoPath);
        });
    };

    return (
        <Dialog disableBackdropClick disableEnforceFocus disableEscapeKeyDown maxWidth="sm" open={showUpdateSubsctiption && user.isCompanyAdmin}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={11}> {title} </Grid>
                    <Grid item xs={1} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} container >
                        {updateClicked ? 'Please complete the subscription update on Chargebee' : content}
                    </Grid>
                    {updateClicked ?
                        <Grid item xs={12} container  justify="flex-end">
                           {location.pathname !== accountInfoPath ?
                                <Button className={classes.saveBtn} onClick={handleCheck}>
                                    {updating ? <CircularProgress color={'#FFF'} size={15}/> : 'Check Account Info'}
                                </Button>
                                :
                                <Button className={classes.saveBtn} onClick={() => window.location.reload()}>Refresh Substription</Button>
                            }
                            <Button className={classes.saveBtn} onClick={handleUpdate}>Upgrade</Button>
                        </Grid>
                    :
                    <Grid item xs={12} container justify="flex-end">
                        <Button className={classes.saveBtn} onClick={handleUpdate}>Upgrade</Button>
                    </Grid>
                    }
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

const mapStateToProps = (state) => ({
    loading: state.general.loading,
    user: state.auth.user,
	showUpdateSubsctiption: state.auth.showUpdateSubsctiption,
});

export default connect(mapStateToProps, { showUpdateSubsctiptionDialog, logout, tokenLogin })(UpdateSubscription);
