import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    seeMore: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

const Category = (props) => {
    const classes = useStyles();

    const handleOpen = () => {
        props.setCategoryState({ tableData: props.tableData, customData: props.customData, name: props.name, targetId: props.targetId });
    }

    return (
        <Grid container>
            <Grid item xs={12} container>
                <span onClick={handleOpen} className={classes.seeMore}>View</span>
            </Grid>
        </Grid>
    )
}

Category.propTypes = {
    setCategoryState: PropTypes.func.isRequired,
};

export default Category;