import { useState, useRef, useEffect } from 'react';

// To 100% show dots on recharts while keeping the animation on
// We have to avoid state changes or rerenders on the graph component
// when the animation is playing
// I added a timeout to disable animation after 2 seconds
// to force the dots to showup
// this is a bug from recharts itself

// This assumes that animation will end in 2s after graph is mounted.
// Animation is disabled afterwards.

const useAnimationTimeout = (props) => {
	const [enableAnimation, setEnableAnimation] = useState(true);
	const [enableTooltip, setEnableTooltip] = useState(false);
	const enableAnimationTimeout = useRef(null);

	useEffect(() => {
		if (enableAnimationTimeout.current) {
			clearTimeout(enableAnimationTimeout.current);
		}
		enableAnimationTimeout.current = setTimeout(() => {
			setEnableAnimation(false);
			setEnableTooltip(true);
		}, 2000);

		return () => {
			if (enableAnimationTimeout.current) {
				clearTimeout(enableAnimationTimeout.current);
			}
		}
	}, [])

    return [enableAnimation, enableTooltip];
}

export default useAnimationTimeout;
