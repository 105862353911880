import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Chip, InputLabel, TextField, Grid, CircularProgress, Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { access } from '../../constants';
import ClientInfoPopover from './ClientInfoPopover';
import { getTitles } from '../../services/UserService';
import { getRolesDropdown } from '../../services/RoleService';
import { getSitesDropdown } from '../../services/SiteService';
import { setLoading, setGlobalDialog } from '../../store/general/actions';
import { CanUserFunc } from '../CanUser';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    multilineInput: {
        minHeight: 90,
    },
    multilineInputLarge: {
        minHeight: 225,
    },
    saveBtn: {
        padding: '6px 40px',
    },
    targetBtn: {
        padding: '6px 50px',
    },
    addNewMenu: {
        backgroundColor: '#0FB99E',
        color: '#ffffff',
        '&:hover': { backgroundColor: '#00826d' },
        fontSize: 14,
    },
    closeAdornment: {
        padding: 8,
    },
    chipBox: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
    },
    popoverPaper: {
        padding: 20,
        width: '40vw',
    },
    buttonChip: {
        border: '0 !important',
        color: '#ffffff',
        backgroundColor: '#0FB99E !important',
        '&:hover': { backgroundColor: '#00826d !important' },
    },
    iconBtn: {
        padding: 2,
    },
    listItemText: {
        fontSize: 14,
    },
    listItem: {
        padding: '0px 16px',
    },
    titleSelect: {
        padding: '8px 32px 8px 14px',
    },
    resetPassword: {
        fontSize: '0.75rem',
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.54)',
    }
}));

const defaultUser = {
    firstName: '',
    lastName: '',
    username: '',
    title: 0,
    email: '',
    phone: '',
    roles: [],
    branches: [],
    patients: [],
    note: '',
};

const NewUser = (props) => {
    const classes = useStyles();
    const { selectedUser, loading, setLoading, user, setGlobalDialog } = props;
    const [userData, setUserData] = useState(cloneDeep(defaultUser));
    const [titles, setTitles] = useState([]);

    const [sites, setSites] = useState([]);
    const [roles, setRoles] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);

    useEffect(() => {
        if (selectedUser) {
            setLoading('loadingUserViewer');
            Promise.all([
                getRolesDropdown(),
                getTitles(),
                CanUserFunc(access.sites.view) ? getSitesDropdown() : { data: { data: user.branchNames } },
            ]).then((values) => {
                setRoles(values[0].data.data.roles);
                setTitles(values[1].data.data.titles);
                setSites(values[2].data.data);

                if (!!selectedUser) {
                    let user = selectedUser;
                    user = {
                        ...user,
                        roles: user.roles.map(e => e.id),
                        branches: user.branches.map(e => e.id),
                        patients: user.patients,
                        title: user.title ? user.title.id : 0,
                        phone: user.mobilePhone,
                    }
                    setUserData(user)
                    setLoading('loadingUserViewer', false);
                }
            })
        }

    }, [selectedUser])

    const openClientInfo = id => event => {
        setSelectedClientId(id);
        setAnchorEl(event.currentTarget);
    }

    const isCompanyAdmin = userData.roles.some(e => e === 1);
    return (
        <div>
            {loading.loadingUserViewer ?
                <Grid container justify="center" alignItems="center" style={{ width: 400, height: 400 }}>
                    <CircularProgress size={250} />
                </Grid>
                :
                <Grid container>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                            variant="outlined"
                            value={userData.firstName}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.rightWrapper}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                            variant="outlined"
                            value={userData.lastName}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>Username</InputLabel>
                        <TextField
                            variant="outlined"
                            value={userData.username}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.rightWrapper}>
                        <InputLabel>Title</InputLabel>
                        <TextField
                            variant="outlined"
                            value={titles.find(title => title.id === userData.title)?.name || 'No Title'}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>Email</InputLabel>
                        <TextField
                            variant="outlined"
                            value={userData.email}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.rightWrapper}>
                        <InputLabel>Phone</InputLabel>
                        <TextField
                            variant="outlined"
                            value={userData.phone}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>Roles</InputLabel>
                        <Grid container className={classes.chipBox}>
                            {userData.roles.map(role => (
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label={roles.find(e => e.id === role)?.name}
                                    key={role}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    {!isCompanyAdmin &&
                        <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel>Sites</InputLabel>
                            <Grid container className={classes.chipBox}>
                                {userData.branches.map(site => {
                                    const selectedSite = sites.find(e => e.id === site);
                                    if (selectedSite) {
                                        return (
                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                label={selectedSite.name}
                                                key={site}
                                            />
                                        )
                                    } else return null;
                                })}
                                {!CanUserFunc(access.sites.view) && userData.branches.length > sites.length ?
                                    <Tooltip arrow title="User belongs to other sites that you don't have access to.">
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label="Other Sites"
                                        />
                                    </Tooltip>
                                    :
                                    null
                                }
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel>Clients</InputLabel>
                        <Grid container className={classes.chipBox}>
                            {userData.patients.map(patient => {
                                const isLinked = patient.id === userData.patientId;
                                return (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        label={`${patient.firstName} ${patient.lastName}${isLinked ? ' (Linked)' : ''}`}
                                        key={patient.id}
                                        onClick={openClientInfo(patient.id)}
                                    />
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel>Note</InputLabel>
                        <TextField
                            variant="outlined"
                            multiline
                            value={userData.note}
                            className={classes.input}
                            InputProps={{
                                classes: {
                                    root: classes.multilineInputLarge,
                                },
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }

            <ClientInfoPopover
                anchorEl={anchorEl}
                onClose={() => setAnchorEl()}
                clientId={selectedClientId}
            />
        </div>
    )
}

NewUser.propTypes = {
    selectedUser: PropTypes.object,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(NewUser)
