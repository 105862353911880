import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { InputLabel, TextField, Grid, Divider, FormHelperText, Button, Typography, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setSnackbar } from '../../store/general/actions';
import * as SettingService from '../../services/SettingService';
import { setUser } from '../../store/auth/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    input: {
        width: '100%',
    },
    additionalDataWrapperOuter: {
        padding: '0px 0px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    },
}));

const DefaultPrompt = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, setUser } = props;

    const [trialNumber, setTrialNumber] = useState({
        maximumNumberTrials: '',
        numberTrials: '',
    });
    const [isUpdating, setIsUpating] = useState(false);
    const [error, setError] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'trialNumber')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setTrialNumber(res.data?.data?.data);
                    } else {
                        setTrialNumber({
                            maximumNumberTrials: '',
                            numberTrials: '',
                        });
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    }, []);

    const handleChange = (field) => (e) => {
        setIsUpating(true);
        let value = (!!e.target.value && e.target.type === 'number') ? Math.floor(Number(e.target.value)) : e.target.value;
        value = (value !== "" && value <= 0) ? trialNumber[field] : value;
        setTrialNumber({ ...trialNumber, [field]: value });
    };

    const handleSave = () => {
        let error = false;
        if ((trialNumber.numberTrials !== "" && trialNumber.numberTrials < 1) || (trialNumber.maximumNumberTrials !== "" && trialNumber.maximumNumberTrials < 1)) {
            error = true;
        }

        if (trialNumber.numberTrials > trialNumber.maximumNumberTrials) {
            error = true;
        }

        if (error) {
            setError(true);
            return;
        }

        setSettingSynced(false);

        if (newSetting) {
            SettingService.createSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'trialNumber',
                data: { ...trialNumber },
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'trialNumber',
                data: { ...trialNumber },
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        }
    };

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'trialNumber')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setTrialNumber(res.data?.data?.data);

                        const userSettings = user.settings;
                        userSettings.trialNumber = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Target and Maximum Number of Trials Setting!');
                    } else {
                        setTrialNumber({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    return (
        <Grid item xs={7} container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Typography id="trial-number-setting-section-heading" className={classes.title}>Target and Maximum Number of Trials</Typography>
            </Grid>

            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                        <InputLabel id="trial-number-setting-target-number-field-label">Target Number of Trials</InputLabel>
                        <TextField id="trial-number-setting-target-number-field" placeholder='Please enter...' variant='outlined' type='number' value={trialNumber.numberTrials.toString()} onChange={handleChange('numberTrials')} className={classes.input} />
                        <FormHelperText id="trial-number-setting-target-number-field-helper-text" error={(error && trialNumber.numberTrials > trialNumber.maximumNumberTrials) || (error && trialNumber.numberTrials !== "" && trialNumber.numberTrials < 1)}>
                            {error && trialNumber.numberTrials > trialNumber.maximumNumberTrials ? 'Please choose a value lower than maximum number of trials' : ' '}
                            {error && trialNumber.numberTrials !== "" && trialNumber.numberTrials < 1 ? 'Should be a positive value' : ' '}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="trial-number-setting-max-number-field-label" required>Maximum Number of Trials</InputLabel>
                        <TextField id="trial-number-setting-max-number-field" placeholder='Please enter...' variant='outlined' type='number' value={trialNumber.maximumNumberTrials.toString()} onChange={handleChange('maximumNumberTrials')} className={classes.input} />
                        <FormHelperText id="trial-number-setting-max-number-field-helper-text" error={error && trialNumber.maximumNumberTrials !== "" && trialNumber.maximumNumberTrials < 1}>{error && trialNumber.maximumNumberTrials !== "" && trialNumber.maximumNumberTrials < 1 ? 'Should be a positive value' : ' '}</FormHelperText>
                    </Grid>

                    <Grid item container xs={12} justify='space-between' direction='row-reverse'>
                        <Button id="trial-number-setting-save"  onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </>
            )}

            <Divider className={classes.divider} />
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return { user: state.auth.user };
};

export default connect(mapStateToProps, { setSnackbar, setUser })(DefaultPrompt);
