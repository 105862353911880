import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    List, ListItem, ListItemText, Drawer, makeStyles,
    Divider, Menu, MenuItem, LinearProgress
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Resizable } from 're-resizable';
import SimpleBar from 'simplebar-react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DomainItem from './LibraryDomainItem';
import { setGlobalDialog, setLoading, setSnackbar } from '../../store/general/actions';
import { reorderDomain } from '../../services/DomainService';
import { reorderProgram } from '../../services/ProgramService';
import { getLibraryDomains, reorderLibrary } from '../../services/LibraryService'
import { setLibFilter } from '../../store/setting/actions';
import clsx from 'clsx';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: '#0D3250',
        border: 'none',
        overflowX: 'hidden',
    },
    list: {
        backgroundColor: '#0D3250',
        color: '#fff',
        position: 'relative',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 11,
    },
    divider: {
        marginLeft: 29,
        marginRight: 29,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    domain: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 16,
    },
    accelLogo: {
        alignSelf: 'flex-start',
        marginTop: 10,
        marginLeft: 35,
        marginBottom: 18,
    },
    allDomain: {
        marginTop: 6,
        paddingLeft: 24,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItem: {
        paddingLeft: 24,
        paddingRight: 32,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
    },
    collapseButton: {
        position: 'absolute',
        right: 16,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': { backgroundColor: '#00826d' },
    },
    dot: {
        position: 'absolute',
        top: 7,
        right: 7,
        width: 10,
        height: 10,
        backgroundColor: '#0FB99E',
        borderRadius: 50,
    },
    version: {
        margin: 0,
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.2)',
        marginLeft: 35,
        marginRight: 30,
        position: 'relative',
    },
    progress: {
		width: '100%',
    },
    visibilityIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        bottom: -4,
        '&:hover': {
            color: 'white',
        }
    },
    searchField: {
        width: 'calc(100% - 30px)',
        margin: '10px',
        '& .MuiInputBase-root': {
            color: 'white',
        },
        '& .MuiInputAdornment-root': {
            color: 'white',
        }
    },
    disableSidebar: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(244, 244, 244, 0.2)'
    }
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const LibrarySidebar = (props) => {
    const classes = useStyles();
    const {
        editDomain, libFilter, libTableFilter, setLibFilter, handleResize, handleResizeStop,
        width, open, setGlobalDialog, patient, loading, setLoading, setSnackbar,
    } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorType, setAnchorType] = useState('domain');
    const [typeData, setTypeData] = useState(null);
	const [domains, setDomains] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const [ordering, setOrdering] = useState({ domainOrdering: [] });

	useEffect(() => {
        fetchDomains();
	}, [props.domains])

    useEffect(() => {
        handleSelectAll();
    }, [])

    useEffect(() => {
        const newOrdering = {};
        newOrdering.domainOrdering = [];
        domains.forEach((domain) => {
            newOrdering.domainOrdering.push(domain.id);
        });
        setOrdering(newOrdering);
    }, [domains])

    const handleSelectAll = () => {
        setLibFilter({
            groupBy: 'all',
            resourceId: 0,
            resourceName: ''
        })
    }

    const handleSelect = (resourceId) => () => {
        setLibFilter({
            groupBy: 'domain',
            resourceId,
            resourceName: domainList.find(e => e.id === resourceId)?.name
        })
    }

    const fetchDomains = () => {
        setLoading('getLibraryDomains')
		getLibraryDomains('all').then((res) => {
			setDomains(res.data.data.map(e => e.domain))
			setDomainList(res.data.data.map(e => e.domain))
            setLoading('getLibraryDomains', false)
		})
    }

    const handleOpenAction = (type, data) => event => {
        setAnchorType(type);
        setAnchorEl(event.currentTarget);
        setTypeData(data);
    }
    
    const handleEditDomain = () => {
        editDomain(typeData);
        setAnchorEl(null);
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.type === 'domains') {
            const domainId = ordering.domainOrdering[result.source.index];
            const newPos = domains.find(e => e.id === ordering.domainOrdering[result.destination.index]).order;
            reorderLibrary(domainId, newPos).then(() => {
                fetchDomains();
            });

            const reorderedDomains = reorder(
                ordering.domainOrdering,
                result.source.index,
                result.destination.index
            );

    
            setOrdering({ ...ordering, domainOrdering: reorderedDomains });
        } else {
            const domainId = result.type.split('-')[1];
            const programId = ordering[domainId][result.source.index];
            const newPos = domains.find(e => e.id === parseInt(domainId, 10)).programs.find(e => e.id === ordering[domainId][result.destination.index]).order;
            reorderProgram(programId, newPos).then(() => {
                fetchDomains();
            });

            const reorderedPrograms = reorder(
                ordering[domainId],
                result.source.index,
                result.destination.index,
            );
            
            setOrdering({ ...ordering, [domainId]: reorderedPrograms });
        }
    }

    const isLoading = loading.getLibraryDomains && patient?.id;
    return (
        <Drawer className={classes.drawer} open={open} variant="persistent"
            classes={{ paper: classes.drawerPaper }} anchor="left">
            <Resizable
                minWidth={180}
                maxWidth={540}
                size={{ width: width }}
                enable={{ top: false, right: true, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                onResize={handleResize}
                onResizeStop={handleResizeStop}
            >
                <SimpleBar autoHide={false} style={{ maxHeight: '100vh' }} id="sidebar">
                    <img alt="Accel Logo" src={require("../../resources/AccelLogo.svg")} className={classes.accelLogo} />
                    <p className={classes.version}>
                        <span id="sidebar-app-version">{`v${process.env.REACT_APP_VERSION}`}</span>
                    </p>
                    <Divider classes={{ middle: classes.divider }} variant="middle" />
                    <div id="sidebar-rearrange">
                        <List component="nav" className={classes.list}>
                            {!!domains.length && !isLoading ?
                                <React.Fragment>
                                    <ListItem
                                        id="sidebar-all-domains"
                                        onClick={handleSelectAll}
                                        className={clsx(classes.allDomain, libFilter.groupBy === 'all' && classes.listItemSelected)}
                                        button
                                    >
                                        <ListItemText primaryTypographyProps={{ className: classes.domain }} primary="All Domains" />
                                    </ListItem>
                                </React.Fragment>
                                :
                                <ListItem className={clsx(classes.allDomain, libFilter.groupBy === 'all' && classes.listItemSelected)}>
                                    <ListItemText primaryTypographyProps={{ className: classes.domain }} primary={isLoading ? 'Loading...' : 'No library'} />
                                </ListItem>
                            }
				            {isLoading && <LinearProgress className={classes.progress} />}
                        </List>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppableDomain" type="domains">
                                {(provided, snapshot) => (
                                    <List
                                        component="nav"
                                        className={classes.list}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {!loading.getDomains && ordering.domainOrdering.map((domainId, index) => {
                                            const domain = domainList.find(e => e.id === domainId);
                                            if (!domain) return null;
                                            if (libTableFilter?.domains && !libTableFilter?.domains.includes(domain.id)) return null;
                                            return (
                                                <Draggable key={domain.id} draggableId={`domain${domain.id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={snapshot.isDragging ? classes.listItemSelected : ''}
                                                        >
                                                            <DomainItem
                                                                domain={domain}
                                                                handleOpenAction={handleOpenAction}
                                                                selected={domain.id === libFilter.resourceId}
                                                                onSelect={handleSelect}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </List>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </SimpleBar>
            </Resizable>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.domains.edit) && <MenuItem onClick={handleEditDomain}>Edit Name</MenuItem>}
            </Menu>
        </Drawer>
    )
}

LibrarySidebar.propTypes = {
    handleResize: PropTypes.func.isRequired,
    handleResizeStop: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    libFilter: PropTypes.object.isRequired,
    libTableFilter: PropTypes.object.isRequired,
    setLibFilter: PropTypes.func.isRequired,
    editDomain: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    libFilter: state.setting.libFilter,
    domains: state.data.domains,
    libTableFilter: state.setting.libTableFilter,
})

export default connect(mapStateToProps, { setGlobalDialog, setLoading, setSnackbar, setLibFilter })(LibrarySidebar);
