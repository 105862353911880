import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
    Grid,
    TextField,
    FormHelperText,
    Select,
    MenuItem,
    Typography,
    Divider,
    Box,
    InputLabel,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { setUser } from "../../store/auth/actions";
import { setSnackbar } from "../../store/general/actions";
import { masteryLineUnitNames } from '../../constants';

//Services
import * as SettingService from "../../services/SettingService";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "20px 100px",
        minWidth: 850,
    },
    btnWrapper: {},
    row: {
        display: "flex",
        flexDirection: "row",
    },
    input: {
        width: "100%",
    },
    buttonContainer: {
        marginTop: "-20px",
    },
    inputLabel: {
        margin: 0,
        marginBottom: "16px",
    },
    title: {
        fontSize: "18px",
        fontWeight: "bold",
        marginBottom: 30,
    },
    divider: {
        width: "100%",
        marginTop: "15px",
    },
}));

const MasteryLine = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, setUser } = props;
    const [masteryLineSetting, setMasteryLineSetting] = useState({});
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting("user", user.id, "masteryLineSetting")
            .then((res) => {
                if (res.status === 200) {
                    setMasteryLineSetting(res.data?.data?.data ?? {});
                    setNewSetting(res.data?.data?.data ? false : true);
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (field) => (event) => {
        setIsUpdating(true);
        setMasteryLineSetting(
            { ...masteryLineSetting, [field]: event.target.value },
            () => {
                setIsUpdating(false);
            }
        );
    };

    const handleSave = () => {
        let error = false;
        if (getMasteryLineYValueError() || (masteryLineSetting?.yValue && !masteryLineSetting?.unit)) {
            error = true;
        }
        if (error) {
            setError(true);
            return;
        }
        setSettingSynced(false);

        let data = { ...masteryLineSetting };
        if (data.yValue === '') {
            data = {};
        }
        data.unit = data.unit === '0' ? null : data.unit

        if (newSetting) {
            SettingService.createSetting({
                type: "masteryLineSetting",
                data,
                resourceType: "user",
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                type: "masteryLineSetting",
                data,
                resourceType: "user",
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        }
    };

    const getUserSettings = () => {
        SettingService.getSetting("user", user.id, "masteryLineSetting")
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setMasteryLineSetting(res.data?.data?.data);

                        const userSettings = user.settings;
                        userSettings.masteryLineSetting = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar("success", "Updated Mastery Line Setting!");
                    } else {
                        setMasteryLineSetting({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    const getMasteryLineYValueError = () => {
        if (masteryLineSetting.unit === masteryLineUnitNames.percentage 
            && (isNaN(masteryLineSetting.yValue) || masteryLineSetting.yValue < 0 || masteryLineSetting.yValue > 100)) {
                return 'Should be in between 0 and 100 when the unit is "Percentage"';
        }
        return '';
    }

    return (
        <Grid item container xs={7} className={classes.root}>
            <Grid item xs={12}>
                <Typography id="mastery-line-setting-section-heading" className={classes.title}>
                    Baseline and Mastery Lines
                </Typography>
            </Grid>
            {!settingSynced ? (
                <Grid item container xs={12} direction="row" justify="center">
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12} spacing={2} className={classes.btnWrapper} container>
                    <Grid item xs={6}>
                        <InputLabel id="mastery-line-setting-y-value-field-label">Y-Value</InputLabel>
                        <TextField
                            id="mastery-line-setting-y-value-field"
                            variant="outlined"
                            type="number"
                            value={masteryLineSetting?.yValue}
                            placeholder="Y-Value"
                            onChange={handleChange("yValue")}
                            className={classes.input}
                            error={error && getMasteryLineYValueError()}
                            helperText={
                                error && (getMasteryLineYValueError() || ' ')
                            }
                            InputProps={{
                                inputProps: { min: 1, style: { textAlign: "right" } },
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <InputLabel id="mastery-line-setting-unit-field-label">Unit</InputLabel>
                        <Select
                            id="mastery-line-setting-unit-field"
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            className={classes.input}
                            error={error && masteryLineSetting?.yValue && !masteryLineSetting?.unit}
                            value={masteryLineSetting?.unit || "0"}
                            onChange={handleChange("unit")}
                        >
                            <MenuItem value="0">Unit Unselected</MenuItem>
                            <MenuItem value="Number">Number</MenuItem>
                            <MenuItem value="Percentage">Percentage</MenuItem>
                        </Select>
                        <FormHelperText
                            id="mastery-line-setting-unit-field-helper-text"
                            error={
                                error && masteryLineSetting?.yValue && !masteryLineSetting?.unit
                            }
                        >
                            {error && masteryLineSetting?.yValue && !masteryLineSetting?.unit
                                ? "Please select a Unit"
                                : " "}
                        </FormHelperText>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="space-between"
                        direction="row-reverse"
                        className={classes.buttonContainer}
                    >
                        <Button
                            id="mastery-line-setting-save"
                            onClick={handleSave}
                            variant="contained"
                            disabled={!isUpdating}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}

            <Divider className={classes.divider} />
        </Grid>
    );
};

MasteryLine.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar, setUser })(MasteryLine);
