import React from 'react';

const ErrorScreen = () => {
	return (
		<div style={{ width: '100%' }}>
			<p style={{ textAlign: 'center' }}>An unknown error has occured. Please refresh the page or contact our support.</p>
		</div>
	)
}

export default ErrorScreen;
