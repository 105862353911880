import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Checkbox, Typography, Divider, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { setUser } from '../../store/auth/actions';
import { setSnackbar } from '../../store/general/actions';

//Services
import * as SettingService from '../../services/SettingService';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    formControlLabelText: {
        color: '#000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    },
    buttonContainer: {
        marginTop: '20px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    },
}));

const ZeroOutDataSetting = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, setUser } = props;
    const [isUpdating, setIsUpdating] = useState(false);
    const [checked, setChecked] = useState(false)
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'zeroOutDataSetting')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setChecked(res.data?.data?.data);
                    } else {
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    }, []);

    const handleChange = (event) => {
        setIsUpdating(true);
        setChecked(!checked);
        return;
    };

    const handleSave = () => {
        if (newSetting) {
            SettingService.createSetting({
                type: 'zeroOutDataSetting',
                data: checked,
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                type: 'zeroOutDataSetting',
                data: checked,
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        }
    };

    const getUserSettings = () => {
        
        SettingService.getSetting('user', user.id, 'zeroOutDataSetting')
        .then((res) => {
            if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setChecked(res.data?.data?.data);

                        const userSettings = user.settings;
                        userSettings.zeroOutDataSetting = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated zero out data Setting!');
                    } else {
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    return (
        <Grid item container xs={7} className={classes.root}>
            <Grid item xs={12}>
                <Typography className={classes.title}>Zero Out Data</Typography>
            </Grid>
            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12} container>
                    <Grid item xs={12} >
                        <FormControlLabel
                            classes={{ label: classes.formControlLabelText }}
                            control={<Checkbox checked={checked} onChange={handleChange} color='primary' />}
                            label='Record "0" instance of behavior for Frequency, Duration, Rate, Quantity data, if no data was collected during the session'
                        />
                    </Grid>
                    <Grid item container xs={12} justify='space-between' direction='row-reverse' className={classes.buttonContainer}>
                        <Button onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}

            <Divider className={classes.divider} />
        </Grid>
    );
};

ZeroOutDataSetting.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar, setUser })(ZeroOutDataSetting);
