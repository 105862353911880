import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import isSameDay from 'date-fns/isSameDay';

import CorrectIncorrect from './TargetData/CorrectIncorrect';
import Prompt from './TargetData/Prompt';
import Duration from './TargetData/Duration';
import Quantity from './TargetData/Quantity';
import Interval from './TargetData/Interval';
import Frequency from './TargetData/Frequency';
import Probe from './TargetData/Probe';
import Text from './TargetData/Text';
import ImageVideo from './TargetData/ImageVideo';
import RatingScale from './TargetData/Rating';
import Rate from './TargetData/Rate';
import { dataTypeDict } from '../../constants';
import Category from './TargetData/Category';
import {
    removeDeletedEmptyCategoryData,
    removeDeletedEmptyProbeData,
    removeDeletedEmptyRatingScaleData,
    removeDeletedEmptyTextData
} from '../../utils/deletedEmptyTargetDataRemover';
import { isTargetDataAvailable } from '../../utils/targetDataAnalyser';

const useStyles = makeStyles(theme => ({
    expandIcon: {
        fontSize: 32,
    },
    btnExpand: {
        color: '#0FB99E',
        padding: 0,
        marginLeft: 5,
    },
    programTitle: {
        fontSize: 16,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
    },
    card: {
        overflow: 'auto',
        maxHeight: 650,
    },
    table: {
        borderCollapse: 'separate',
        backgroundColor: 'white',
    },
    tableRow: {
        height: 1, // height 1 is a hack so we can have full size div inside a td
    },
    tableCell: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: 5,
        height: 'auto',
    },
    tableHead: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: 5,
        verticalAlign: 'middle',
    },
    stickyHeader: {
        '& th': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',

            border: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    dateCell: {
        textAlign: 'center',
        minWidth: 0,
    },
    targetName: {
        position: 'sticky',
        left: 0,
        width: 200,
        minWidth: 200,
        backgroundColor: '#fff',
        zIndex: 3,
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    targetNameMobile: {
        textAlign: 'center',
        width: 200,
        minWidth: 200,
        backgroundColor: '#fff',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    notesCell: {
        position: 'sticky',
        left: 212,
        width: 15,
        minWidth: 15,
        backgroundColor: '#fff',
        zIndex: 3,
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    notesCellMobile: {
        textAlign: 'center',
        width: 15,
        minWidth: 15,
        backgroundColor: '#fff',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    notes: {
        width: '10%',
    },
    greyedOutCell: {
        backgroundColor: 'rgba(242,242,242,255)',
    },
    noteBtn: {
        cursor: 'pointer',
        textDecoration: 'underline'
    },
    probeSets: {
        margin: '2px 0px 3px 20px',
    },
    ratingSets: {
        margin: '2px 0px 3px 20px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    targetInfo: {
        margin: 2,
    },
    infoTitle: {
        width: 47,
        display: 'inline-block'
    },
    noPadding: {
        padding: 0,
    }
}));

const ProgramWrapper = (props) => {
    const classes = useStyles();
    const { isDownloading, collapseTarget, toggleCollapse, setNotesState, setTextState, setImageData, setCategoryState } = props;

    const getTargetDataByDate = (target, date) => {
        if (!target.targetData || !target.targetData.length) {
            return { dataType: target.dataType, targetData: null }
        }

        const selectedData = target.targetData.filter(data => {
            const dataDate = new Date(data.date);
            if (!dataDate) return false;
            if (data.value === undefined || data.value === null) return false;
            if (Array.isArray(data.value) && !data.value.length) return false;

            return isSameDay(dataDate, date);
        });

        return { id: target.id, dataType: target.dataType, targetData: selectedData, customData: target.customData, name: target.name }
    }

    const renderItem = (target) => {
        if (!target.targetData || !target.targetData.length) return null;
        if (target.dataType === 'prompt') {
            return (
                <Prompt
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    customData={target.customData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'correctIncorrect') {
            return (
                <CorrectIncorrect
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'duration') {
            return (
                <Duration
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'interval') {
            return (
                <Interval
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    customData={target.customData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'frequency') {
            return (
                <Frequency
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'probe') {
            return (
                <Probe
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    customData={target.customData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'text') {
            const textTarget = removeDeletedEmptyTextData(target);
            return (
                <Text
                    key={`${textTarget.dataType}-${textTarget.targetData[0].date}`}
                    textData={textTarget.targetData}
                    customData={textTarget.customData}
                    name={textTarget.name}
                    setTextState={setTextState}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'imageVideo') {
            return (
                <ImageVideo
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    mediaData={target.targetData}
                    name={target.name}
                    targetId={target.id}
                    setImageData={setImageData}
                    isSilentLoading={props.isSilentLoading}
                    getTargetDataByDate={getTargetDataByDate}
                />
            )
        }
        if (target.dataType === 'rating') {
            return (
                <RatingScale
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    customData={target.customData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'rate') {
            return (
                <Rate
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'quantity') {
            return (
                <Quantity
                    key={`${target.dataType}-${target.targetData[0].date}`}
                    tableData={target.targetData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                />
            )
        }
        if (target.dataType === 'category') {
            const categoryTarget = removeDeletedEmptyCategoryData(target);
            return (
                <Category
                    key={`${categoryTarget.dataType}-${categoryTarget.targetData[0].date}`}
                    tableData={categoryTarget.targetData}
                    customData={categoryTarget.customData}
                    fetchData={props.fetchData}
                    isSilentLoading={props.isSilentLoading}
                    setCategoryState={setCategoryState}
                    targetId={categoryTarget.id}
                    name={categoryTarget.name}
                />
            )
        }
    }

    const notes = useMemo(() => {
        const notes = {};
        props.targets.forEach((target) => {
            notes[target.id] = [];
            if (target.notes) {
                target.notes.forEach((data) => {
                    if (data.note) {
                        notes[target.id].push({ date: data.date, note: data.note, targetDataId: data.id });
                    }
                })
            }
        })
        return notes;
    }, [props.targets]);

    const openNotes = (id) => {
        const target = props.targets.find(target => target.id === id);
        setNotesState({ target, notes: notes[id] });
    }

    return (
        <TableBody>
            {props.targets.map(t => {
                let target;
                if (t.dataType === 'probe') {
                    target = removeDeletedEmptyProbeData(t);
                } else if (t.dataType === 'rating') {
                    target = removeDeletedEmptyRatingScaleData(t);
                } else {
                    target = t;
                }
                if (!isTargetDataAvailable(target.targetData)) return null;
                return (
                    <TableRow key={target.id} className={classes.tableRow}>
                        <TableCell className={`${props.isMobile ? classes.targetNameMobile : classes.targetName} ${classes.tableCell} ${!isDownloading && collapseTarget[target.id] ? classes.greyedOutCell : ''}`}>
                            <IconButton style={{ visibility: isDownloading ? 'hidden' : 'visible' }} className={classes.btnExpand} onClick={() => toggleCollapse(target.id)}>
                                {collapseTarget[target.id] ?
                                    <KeyboardArrowDownIcon className={classes.expandIcon} />
                                    :
                                    <KeyboardArrowUpIcon className={classes.expandIcon} />
                                }
                            </IconButton>
                            <Tooltip
                                title={(
                                    <React.Fragment>
                                        <p className={classes.targetInfo}>
                                            <span className={classes.infoTitle}>Domain</span>: {props.domainName}
                                        </p>
                                        <p className={classes.targetInfo}>
                                            <span className={classes.infoTitle}>Program</span>: {props.programName}
                                        </p>
                                        <p className={classes.targetInfo}>
                                            <span className={classes.infoTitle}>Data Type</span>: {dataTypeDict[target.dataType]}
                                        </p>
                                    </React.Fragment>
                                )}
                            >
                                <span>{target.name}</span>
                            </Tooltip>
                            {(!collapseTarget[target.id] && target.dataType === 'probe' && target.customData?.sets?.length) &&
                                target.customData.sets.map((set, index) => (
                                    <p key={`${target.id}-probe-${index}`} className={classes.probeSets}>{`${index + 1}. \xA0${set.value}`}</p>
                                ))
                            }
                            {(!collapseTarget[target.id] && target.dataType === 'rating' && target.customData?.length) &&
                                target.customData.map((question, index) => (
                                    <Tooltip
                                        key={question.id}
                                        title={(
                                            <React.Fragment>
                                                <p className={classes.targetInfo}>{question.question}</p>
                                                {question.ratings.map(rating => (
                                                    <p key={rating.value} className={classes.targetInfo}>{`${rating.value} - ${rating.desc}`}</p>
                                                ))}
                                            </React.Fragment>
                                        )}
                                    >
                                        <p key={`${target.id}-rating-${index}`} className={classes.ratingSets}>{index + 1}. {question.question}</p>
                                    </Tooltip>
                                ))
                            }
                        </TableCell>
                        <TableCell className={`${props.isMobile ? classes.notesCellMobile : classes.notesCell} ${classes.tableCell}`}>
                            {!!notes[target.id]?.length &&
                                <span className={classes.noteBtn} onClick={() => openNotes(target.id)}>View</span>
                            }
                        </TableCell>
                        {props.dates.map(date =>
                            <TableCell key={date} className={`${classes.tableCell} ${classes.noPadding} ${!isDownloading && collapseTarget[target.id] ? classes.greyedOutCell : ''}`}>
                                {isDownloading || !collapseTarget[target.id] ? renderItem(getTargetDataByDate(target, date)) : null}
                            </TableCell>
                        )}
                    </TableRow>
                )}
            )}
        </TableBody>

    )
}

ProgramWrapper.propTypes = {
    targets: PropTypes.array.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    dates: PropTypes.array.isRequired,
    domainName: PropTypes.string.isRequired,
    programName: PropTypes.string.isRequired,
    collapseTarget: PropTypes.object.isRequired,
    toggleCollapse: PropTypes.func.isRequired,
    setNotesState: PropTypes.func.isRequired,
    setImageData: PropTypes.func.isRequired,
    setTextState: PropTypes.func.isRequired,
    setCategoryState: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    isSilentLoading: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

export default ProgramWrapper;
