import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 200
    }
}));

const RichEditor = ({ value, onChange, className, id, disabled }) => {
    const classes = useStyles();

    const editorRef = useRef(null);
    const [initialized, setInitialized] = useState(false);

    return (
        <div className={className}>
            {!initialized && <div className={classes.circularProgress}>
                <CircularProgress size={50} />
            </div>}
            <div style={{display: initialized ? 'block' : 'none'}}>
                <Editor
                    id={id}
                    disabled={disabled}
                    tinymceScriptSrc="/js/tinymce/tinymce.min.js"
                    onInit={(evt, editor) => editorRef.current = editor}
                    value={value}
                    onEditorChange={onChange}
                    init={{
                        setup: (editor) => {
                            editor.on('init', () => setInitialized(true));
                        },
                        branding: false,
                        elementpath: false,
                        plugins: 'importcss searchreplace autolink directionality visualblocks visualchars fullscreen image link media table charmap pagebreak nonbreaking insertdatetime advlist lists wordcount charmap emoticons',
                        menubar: 'edit insert format',
                        menu: {
                            insert: { title: 'Insert', items: 'image link inserttable | charmap emoticons hr | pagebreak nonbreaking | insertdatetime' },
                        },
                        toolbar: 'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen print | insertfile image link',
                        toolbar_sticky: true,
                        image_advtab: true,
                        importcss_append: true,
                        height: 450,
                        image_caption: true,
                        file_picker_callback: (cb, value, meta) => {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                            input.onchange = function () {
                                var file = this.files[0];

                                var reader = new FileReader();
                                reader.onload = function () {
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache = editorRef.current.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                    blobCache.add(blobInfo);
                                    cb(blobInfo.blobUri(), { title: file.name });
                                };
                                reader.readAsDataURL(file);
                            };

                            input.click();
                        },
                        noneditable_noneditable_class: 'mceNonEditable',
                        toolbar_mode: 'sliding',
                        contextmenu: 'link image imagetools table',
                        skin: 'oxide',
                        content_css: 'default',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                    }}
                />
            </div>
        </div>
    )
}

RichEditor.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    id: PropTypes.string,
    disabled: PropTypes.bool
}

export default RichEditor;