import React from "react";

const parseCategoryRecord = (data) => {
  return (
    <div>
      {data.map((item) => (
        <div>{item}</div>
      ))}
    </div>
  );
};

const parseManualDurationRecord = (data) => {
  return (
    <div>
      <span>Add manual duration Duration</span>
      <br />
      <span>{`Duration: ${data.hours} hour, ${data.minutes} min, ${data.seconds} sec`}</span>
    </div>
  );
};

const parseImageRecord = (data) => {
  return (
    <div>
      Save Images/Videos
      <br />
      {`${data.imageCount} images, ${data.videoCount} videos`}
    </div>
  );
};

const parseProbeSaveRecord = (data) => {
  return (
    <div>
      {Object.keys(data).map(function (key) {
        return (
          data[key] && (
            <>
              <span>{`${data[key].set}: ${data[key].id}`}</span>
              <br />
            </>
          )
        );
      })}
    </div>
  );
};

export const KEY_CATEGORY = "category";
export const KEY_DURATION_MANUAL = "duration_manual";
export const KEY_IMAGE = "image";
export const KEY_PROBE_SAVE = "probe_save";

export const parseSessionHistoryRecord = (record) => {
  if (typeof record === "string") {
    return record;
  }
  const { key, data } = record;
  switch (key) {
    case KEY_CATEGORY:
      return parseCategoryRecord(data);
    case KEY_DURATION_MANUAL:
      return parseManualDurationRecord(data);
    case KEY_IMAGE:
      return parseImageRecord(data);
    case KEY_PROBE_SAVE:
      return parseProbeSaveRecord(data);
    default:
      return "";
  }
};
