import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    labels: {
        textTransform: 'capitalize',
    },
    filter: {
        paddingRight: 5,
        color: '#000000',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    infoIcon: {
        color: '#0FB99E',
        position: 'absolute',
        right: 10,
    },
    withTooltip: {
        paddingRight: 40,
    },
}));

/**
 * 
 * @param options options value must be a number
 * otherwise use event.target.getAttribute('value') when handling change 
 */
const DropdownButton = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [tooltipOpen, setTooltipOpen] = useState(null);
    const classes = useStyles();

    const handleChange = (event) => {
        setAnchorEl(null);
        props.onValueChange(event);
    }

    const generateLabel = () => {
        if (props.customLabel) {
            return props.customLabel;
        }

        const selectedOption = props.options.find(e => e.value === props.selectedValue);

        if (props.label) {
            return `${props.label}: ${selectedOption?.label || props.emptySelection}`;
        }

        return selectedOption?.label || props.emptySelection;
    }

    return (
        <div className={props.wrapperStyle} id={props.id}>
            <Button id={props.id ? props.id + '_button' : undefined} className={classes.filter} variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)} disabled={props.disabled}>
                {generateLabel()}
                <ArrowDownIcon style={{ color: '#0FB99E' }} />
            </Button>
            <Menu
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {props.options.map(option => (
                    <Tooltip
                        key={option.value}
                        title={option.tooltip || ''}
                        placement="right"
                        open={!!option.tooltip && tooltipOpen === option.value}
                    >
                        <MenuItem
                            id={props.id ? `${props.id}_dropdown_item_${option.value}` : undefined}
                            selected={option.value === props.selectedValue}
                            onClick={handleChange}
                            value={option.value}
                            className={!!option.tooltip ? classes.withTooltip : ''}
                        >
                            {option.label}
                            {!!option.tooltip &&
                                <InfoIcon
                                    onMouseEnter={() => setTooltipOpen(option.value)}
                                    onMouseLeave={() => setTooltipOpen(null)}
                                    className={classes.infoIcon}
                                />
                            }
                        </MenuItem>
                    </Tooltip>
                ))}
            </Menu>
        </div>
    );
}

DropdownButton.defaultProps = {
    wrapperStyle: null,
    emptySelection: 'Loading...',
}

DropdownButton.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    options: PropTypes.array.isRequired,
    onValueChange: PropTypes.func.isRequired,
    selectedValue: PropTypes.any.isRequired,
    wrapperStyle: PropTypes.any,
    emptySelection: PropTypes.string,
    customLabel: PropTypes.string,
    disabled: PropTypes.bool
};

export default DropdownButton;