import agent from './agent';

export const createMemo = (message, patientId, branchId) => {
    return agent.post('/memo/create', { message, patientId, branchId });
}

export const updateMemo = (id, message) => {
    return agent.post('/memo/update', { id, message });
}

export const deleteMemo = (id) => {
    return agent.delete('/memo/delete', { params: { id } });
}

export const listMemo = (patientId) => {
    return agent.get('/memo/list', {
        params: {
            patientId,
            skip: 0,
            limit: 0,
            projection: 'createdAt,id,message,user,isRead,readId'
        }
    })
}

export const readMemo = (ids) => {
    return agent.post('/memo/read', { ids });
}