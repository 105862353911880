import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	loading: {
		marginLeft: 5,
	},
	icon: {
		marginLeft: 5,
		color: theme.palette.primary.main,
	},
}));

const LoadingCheck = (props) => {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		if (!loading && props.show) setLoading(true);
		if (loading && !props.show) {
			setLoading(false);
			setLoaded(true);
			setTimeout(() => setLoaded(false), 750);
		}
	}, [props.show])

	if (props.show) return <CircularProgress className={classes.loading} size={props.size} />;
	else if (loaded) return <CheckCircleIcon className={classes.icon} style={{ fontSize: props.size }} />;
	else return null;
}


LoadingCheck.propTypes = {
    show: PropTypes.bool,
    size: PropTypes.number.isRequired,
};

export default LoadingCheck;
