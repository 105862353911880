import { makeStyles } from '@material-ui/core/styles';

const useTargetDataStyles = makeStyles(theme => ({
    additionalDataWrapper: {
        padding: 19,
        margin: '20px 0px',
        paddingBottom: 20,
        border: '1px solid #0FB99E',
        borderRadius: 5,

        '@media (max-width: 768px)': {
            padding: 15,
            paddingBottom: 20,
        }
    },
    additionalDataWrapperOuter: {
        padding: 0,
    },
    wrapperTitle: {
        textAlign: 'center',
        padding: 0,
        margin: 0,
        fontWeight: 'bold',
    },
    deleteBtn: {
        padding: '4px',
        color: 'rgba(255, 0, 0, 30%)',
        marginLeft: 5,
        marginTop: 5.5,
        '&:hover': { color: 'red' }
    },
    addBtn: {
        padding: '4px',
        color: 'rgba(15, 185, 158, 30%)',
        marginLeft: 5,
        marginTop: 5.5,
        '&:hover': { color: 'rgba(15, 185, 158, 100%)' }
    },
    dataRowWrapper: {
        marginTop: 35,
        alignContent: 'flex-start',
    },
    addMoreWrapper: {
        marginTop: 35,
    },
    saveBtn: {
        padding: '6px 20px',
        '&:hover': {
            backgroundColor: '#00826D'
        }
    },
    leftDataWrapper: {
        paddingRight: 19,
        marginTop: 28,
        '@media (max-width: 768px)': {
            paddingLeft: 19
        }
    },
    rightDataWrapper: {
        paddingLeft: 19,
        marginTop: 28,
        '@media (max-width: 768px)': {
            paddingRight: 19
        }
    },
    input: {
        width: '100%',
    },
    wrapperDisabled: {
        textAlign: 'center',
        padding: 0,
        margin: 0,
    },
    plusThumb: {
        color: '#0FB99E !important',
        '&:hover': {
            backgroundColor: 'rgba(15, 185, 158, 0.04) !important',
        }
    },
    minusThumb: {
        color: '#d32f2f !important',
        '&:hover': {
            backgroundColor: 'rgba(245, 0, 86, 0.04) !important',
        }
    },
    plusTrack: {
        backgroundColor: '#0FB99E !important'
    },
    minusTrack: {
        backgroundColor: '#d32f2f !important',
    },
    infoIcon: {
        color: '#0FB99E',
        fontSize: 21,
        marginLeft: 5,
        marginTop: -3,
    },
    plusMinusLabel: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'flex-start',
    },
    switchIcon: {
        width: 24,
        height: 24,
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '50%',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: -2,
    },
    ratingNumber: {
        marginTop: '40px',
    },
    ratingDescription: {
        marginTop: '10px',
    },
    responseWrapper: {
        display: 'flex',
        marginTop: '20px',
        justifyContent: 'flex-end',
        placeSelf: 'flex-end',
        whiteSpace: 'nowrap',
    },
    addCategory: {
        width: 'fit-content',
        position: 'absolute',
        right: 0,
        top: 0,
    },
    requiredCheckboxWrapper: {
        textAlign: 'center',
        marginBottom: '-2px'
    },
    probSwitchWrapper: {
        marginLeft: '-8px'
    },
    requiredTextDataCheckboxWrapper: {
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        verticalAlign: 'top',
        marginRight: '5px'
    },
    requiredTextLabel: {
        fontSize: '12px'
    }
}));

export default useTargetDataStyles;