import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog,
    InputLabel, TextField, Grid, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    multilineInput: {
        minHeight: 90,
    },
}));


const defaultSite = {
    name: '',
    address: '',
}

const NewSite = (props) => {
    const classes = useStyles();
    const { onClose, open, isEdit, site, onSave, loading } = props;
    const [newSite, setNewSite] = useState(cloneDeep(defaultSite));
    const [error, setError] = useState(false);

    useEffect(() => {
        if (open) {
            if (isEdit) {
                setNewSite(site);
            } else {
                setNewSite(cloneDeep(defaultSite));
            }
            setError(false);
        }
    }, [open])

    const handleCancel = () => {
        onClose();
    };

    const handleChange = field => event => {
        setNewSite({ ...newSite, [field]: event.target.value });
    }

    const handleSave = () => {
        if (!newSite.name.trim() || !newSite.address.trim()) {
            setError(true);
            return;
        }
        onSave({
            ...newSite,
            name: newSite.name.trim(),
            address: newSite.address.trim(),
        });
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xl" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        {!!site ? 'Edit Site' : 'New Site'}
					</Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton id="sites-new-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel id="sites-new-form-field-name-label" required>Site Name</InputLabel>
                        <TextField
                            id="sites-new-form-field-name"
                            variant="outlined"
                            value={newSite.name}
                            onChange={handleChange('name')}
                            error={error && !newSite.name.trim()}
                            helperText={error && !newSite.name.trim() ? "Site name can't be empty" : ' '}
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel id="sites-new-form-field-address-label" required>Address</InputLabel>
                        <TextField
                            id="sites-new-form-field-name-address"
                            variant="outlined"
                            multiline
                            value={newSite.address}
                            onChange={handleChange('address')}
                            className={classes.input}
                            error={error && !newSite.address.trim()}
                            helperText={error && !newSite.address.trim() ? "Site address can't be empty" : ' '}
                            InputProps={{
                                classes: {
                                    root: classes.multilineInput,
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button id="sites-new-form-cancel" className={classes.saveBtn} onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Button id="sites-new-form-save" className={classes.saveBtn} onClick={handleSave} disabled={loading.newSite || loading.editSite}>Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

NewSite.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    isEdit: PropTypes.bool,
    site: PropTypes.object,
    loading: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps)(NewSite)
