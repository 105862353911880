import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    InputLabel,
    TextField,
    Grid,
    IconButton,
    Tooltip,
    Switch,
    Checkbox
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

export const defaultProbe = {
    sets: [{ value: '', isRequired: false }],
    codes: [{ correctness: true, code: '', codeDesc: '' }], // TODO: Format { "correctness": true, "code": "ABC", "codeDesc": "Alpha Beta Correction" }
};

const defaultCode = { correctness: true, code: '', codeDesc: '' };

const ProbeData = (props) => {
    const classes = useTargetDataStyles();
    const { probe, setProbe, setSnackbar, itemError, disabled, isMobile } = props;
    const [newCodeList, setNewCodeList] = useState([]);
    const [newSetList, setNewSetList] = useState([]);

    const handleChangeCodes = (field, id) => (event) => {
        const newProbe = { ...probe };
        if (field === 'code' && event.target.value.length > 4) {
            return;
        }
        if (field === 'code' && newProbe.codes[id].id && !newProbe.codes[id].preModifiedVersion) {
            newProbe.codes[id].preModifiedVersion = { ...newProbe.codes[id] };
        }
        newProbe.codes[id][field] =
            (field === 'correctness') ? event.target.checked : event.target.value;
        setProbe(newProbe);
    };

    const addCode = () => {
        const newProbe = { ...probe };
        newProbe.codes.push({ ...defaultCode });
        if (newProbe.codes.length === 8) {
            setSnackbar('warning', 'Maximum 8 codes allowed!');
        }
        setProbe(newProbe);
        setNewCodeList([...newCodeList, newProbe.codes.length - 1]);
    };

    const deleteCodeById = (id) => () => {
        const newCodes = [...probe.codes];
        newCodes[id] = { ...newCodes[id], isDeleted: true };

        setProbe({
            ...probe,
            codes: newCodes,
        });
        setNewCodeList(newCodeList.filter(c => c !== id));
    };

    const handleChangeSet = (field, id) => (event) => {
        const newProbe = { ...probe };
        if (newProbe.sets[id].id && !newProbe.sets[id].preModifiedVersion) {
            newProbe.sets[id].preModifiedVersion = { ...newProbe.sets[id] };
        }
        newProbe.sets[id][field] =
            (field === 'isRequired') ? event.target.checked : event.target.value;
        setProbe(newProbe);
    };

    const addSet = () => {
        const newSet = probe.sets.slice();
        newSet.push({ ...defaultProbe.sets[0] });
        setProbe({ ...probe, sets: newSet });

        if (newSet.length === 16) {
            setSnackbar('warning', 'Maximum 16 sets allowed!');
        }
        setNewSetList([...newSetList, newSet.length - 1]);
    };

    const deleteSet = (id) => () => {
        const newSet = probe.sets.slice();
        newSet[id] = { ...newSet[id], isDeleted: true };
        setProbe({ ...probe, sets: newSet });
        setNewSetList(newSetList.filter(s => s !== id));
    };

    const trimmedCodes = useMemo(() => {
        return probe.codes.map((e) => e.code.trim());
    }, [probe.codes]);

    const checkCodesError = (value) => {
        if (!value.code.trim()) {
            return "Code can't be empty";
        }
        if (checkDuplicates(trimmedCodes, value.code.trim())) {
            return 'Code must be unique';
        }
        return ' ';
    };

    const trimmedSets = useMemo(() => {
        if (probe.sets)
            return probe.sets.map((e) => e.value.trim());
    }, [probe.sets]);

    const checkSetsError = (value) => {
        if (!value.trim()) {
            return "Set can't be empty";
        }
        if (checkDuplicates(trimmedSets, value.trim())) {
            return 'Set must be unique';
        }
        return ' ';
    };

    return (
        <div>
            <Grid container className={classes.additionalDataWrapper}>
                <Grid
                    id="target-form-probe-code-section-heading" 
                    item
                    xs={12}
                    container
                    alignItems='center'
                    justify='center'
                    className={classes.wrapperTitle}
                >
                    Data Entry Code
                    <Tooltip
                        title={
                            'Codes that appear on the mobile app (e.g., "IND" for independent, "a")'
                        }
                    >
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                </Grid>
                {probe.codes.map((code, id) => {
                    const entryError = itemError[`probe code ${id}`] && checkCodesError(code);
                    return (
                        <Grid
                            container
                            item
                            xs={isMobile ? 12 : 6}
                            className={classes.dataRowWrapper}
                            key={`dataEntry${id}`}
                        >
                            <Grid item xs={3} style={{ paddingLeft: 10, position: 'relative' }}>
                                <InputLabel id={`target-form-probe-plus-minus-field-label-${id}`} className={classes.plusMinusLabel}>
                                    <span>+/-</span>
                                    <Tooltip title='This column defines whether a response is considered a correct or incorrect response.'>
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </InputLabel>
                                <Switch
                                    id={`target-form-probe-plus-minus-field-${id}`}
                                    onChange={handleChangeCodes('correctness', id)}
                                    checked={probe.codes[id]?.correctness}
                                    classes={{
                                        track: probe.codes[id]?.correctness
                                            ? classes.plusTrack
                                            : classes.minusTrack,
                                        switchBase: probe.codes[id]?.correctness
                                            ? classes.plusThumb
                                            : classes.minusThumb,
                                    }}
                                    icon={<RemoveCircleIcon className={classes.switchIcon} />}
                                    checkedIcon={<AddCircleIcon className={classes.switchIcon} />}
                                    disabled={disabled && !newCodeList.includes(id)}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <InputLabel id={`target-form-probe-code-field-label-${id}`} required>Code {id + 1}</InputLabel>
                                <TextField
                                    id={`target-form-probe-code-field-${id}`}
                                    variant='outlined'
                                    value={probe.codes[id].code}
                                    onChange={handleChangeCodes('code', id)}
                                    style={{ width: '70%' }}
                                    error={!!entryError && entryError !== ' '}
                                    helperText={entryError}
                                    disabled={disabled && !newCodeList.includes(id)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel id={`target-form-probe-description-field-label-${id}`} required>Code Description {id + 1}</InputLabel>
                                <TextField
                                    id={`target-form-probe-description-field-${id}`}
                                    variant='outlined'
                                    value={probe.codes[id].codeDesc}
                                    onChange={handleChangeCodes('codeDesc', id)}
                                    style={{ width: '75%' }}
                                    error={
                                        itemError[`probe code ${id}`] &&
                                        !probe.codes[id].codeDesc.trim()
                                    }
                                    helperText={
                                        itemError[`probe code ${id}`] &&
                                        !probe.codes[id].codeDesc.trim()
                                            ? "Description can't be empty"
                                            : ' '
                                    }
                                    disabled={disabled && !newCodeList.includes(id)}
                                />
                                {probe.codes.length > 1 ? (
                                    <IconButton
                                        id={`target-form-probe-code-section-delete-${id}`}
                                        disabled={disabled && !newCodeList.includes(id)}
                                        className={classes.deleteBtn}
                                        onClick={deleteCodeById(id)}
                                        tabIndex={-1}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : null}
                            </Grid>
                        </Grid>
                    );
                })}
                {probe.codes.length < 8 && (
                    <Grid
                        item
                        xs={isMobile ? 12 : 3}
                        className={classes.addMoreWrapper}
                        container
                        alignItems='center'
                        justify='center'
                    >
                        <Button id="target-form-probe-code-add-more" className={classes.saveBtn} onClick={addCode}>
                            Add More
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Grid container className={classes.additionalDataWrapper}>
                <Grid
                    id="target-form-probe-set-section-heading" 
                    item
                    xs={12}
                    container
                    alignItems='center'
                    justify='center'
                    className={classes.wrapperTitle}
                >
                    Steps/Sets
                    <Tooltip
                        title={
                            'Each set may represent a step in task analysis (e.g., "wash hands") or a stimulus when probing (e.g., "a")'
                        }
                    >
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip>
                </Grid>
                {probe.sets && probe.sets.map((set, id) => {
                    const setError = itemError[`probe set ${id}`] && checkSetsError(set.value);
                    return (
                        <Grid
                            container
                            item
                            xs={isMobile? 12 : 6}
                            className={classes.dataRowWrapper}
                            key={`set${id}`}
                        >
                            <Grid item xs={isMobile ? 4 : 3}>
                                <InputLabel id={`target-form-probe-required-field-label-${id}`} className={classes.plusMinusLabel}>
                                    <span>Required</span>
                                    <Tooltip title='The user will be required to enter data, if checked'>
                                        <InfoIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </InputLabel>
                                <div className={classes.requiredCheckboxWrapper} >
                                    <Checkbox
                                        id={`target-form-probe-required-field-${id}`}
                                        color="primary"
                                        checked={set?.isRequired}
                                        onChange={handleChangeSet('isRequired', id)}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={isMobile ? 8 : 9}  style={{ paddingLeft: 5, position: 'relative' }}>
                                <InputLabel id={`target-form-probe-set-field-label-${id}`} required>Set {id + 1}</InputLabel>
                                <TextField
                                    id={`target-form-probe-set-field-${id}`}
                                    variant='outlined'
                                    value={set.value}
                                    onChange={handleChangeSet('value', id)}
                                    style={{ width: '85%' }}
                                    error={!!setError && setError !== ' '}
                                    helperText={setError}
                                    disabled={disabled && !newSetList.includes(id)}
                                />
                                {probe.sets.length > 1 ? (
                                    <IconButton
                                        id={`target-form-probe-set-section-delete-${id}`}
                                        disabled={disabled && !newSetList.includes(id)}
                                        className={classes.deleteBtn}
                                        onClick={deleteSet(id)}
                                        tabIndex={-1}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                ) : null}
                            </Grid>
                        </Grid>
                    );
                })}

                {!isMobile && probe.sets && probe.sets.length % 2 === 0 && <Grid item xs={6} />}
                {probe.sets && probe.sets.length < 16 && (
                    <Grid
                        item
                        xs={isMobile? 12 : 6}
                        className={classes.addMoreWrapper}
                        container
                        alignItems='center'
                        justify='center'
                    >
                        <Button id="target-form-probe-set-add-more" className={classes.saveBtn} onClick={addSet}>
                            Add More
                        </Button>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

ProbeData.propTypes = {
    probe: PropTypes.object.isRequired,
    setProbe: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, { setSnackbar })(ProbeData);
