import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, LabelList, Tooltip } from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
    dateAxisSetting,
    defaultAxisSetting,
    getColor,
    graphHeight,
    defaultLineSetting,
    defaultLineChartSetting,
    toDateFull,
    renderCustomizedValue,
    defaultTooltipSetting,
    getMinMaxDate,
    generateDomainTicks,
    splitGraphDataByPhases,
    generateTargetName,
    percentAxisSetting,
    getTargetColor,
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand
const totalTrial = [{ id: 'Total Trial', codeDesc: 'Total Trial', code: '' }];

const PromptGraph = (props) => {
    const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
    const [disabledLines, setDisabledLines] = useState({});
    const countTotalData = props.graphData
        .map((target) => target.targetData.length > 0 && target.customData.codes.length)
        .reduce((a, b) => a + b);
    const height = graphHeight + Math.floor(countTotalData / 7) * 25;
    const valueToUse = props.usePercent ? 'value' : props.useCumulative ? 'cumulative' : 'value';
    
    const formattedPayload = useMemo(() => {
        const payload = [];
        props.graphData.forEach((target, targetIndex) => {
            if (props.usePercent) {
                if (disabledLines[target.name]) {
                    return;
                }
                target.targetData.length > 0 &&
                    target.targetData
                        .filter((e) => dateToDays(e.date) === hoveredDate)
                        .forEach((targetData) => {
                            payload.push({
                                date: hoveredDate,
                                color: getColor(targetIndex, 'light'),
                                name: target.name,
                                value:
                                    typeof targetData.value.percentage.correct === 'number'
                                        ? targetData.value.percentage.correct
                                        : null,
                            });
                        });
            } else {

                target.targetData.length > 0 &&
                    totalTrial
                        .concat(target.customData.codes)
                        .forEach((prompt, index) => {
                            if (disabledLines[prompt.id]) return;

                            target.targetData
                                .filter((e) => dateToDays(e.date) === hoveredDate)
                                .forEach((targetData) => {
                                    if (targetData.value?.count[prompt.id]) {
                                        payload.push({
                                            date: hoveredDate,
                                            color: getColor(index, 'light'),
                                            name: prompt.codeDesc,
                                            value: targetData.value.count[prompt.id],
                                        });
                                    }
                                });
                        });
            }
        });
        return payload;
    }, [hoveredDate, props.graphData]);

    const { graphDomain, graphTicks } = useMemo(() => {
        let { graphDomain, graphTicks } = props;
        if (!graphDomain.length || !graphTicks.length) {
            const { minDate, maxDate } = getMinMaxDate(props.graphData);
            const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
            graphDomain = domain;
            graphTicks = ticks;
        }
        return { graphDomain, graphTicks };
    }, [props.graphDomain, props.graphTicks, props.graphData]);

    const formattedGraphData = useMemo(() => {
        const formattedData = [];
        const dateValueMap = {};
        let maxValue = 9;

        props.graphData.forEach((target) => {
            const newTarget = clonedeep(target);
            newTarget.targetData.length > 0 &&
                newTarget.targetData.forEach((targetData) => {
                    const date = dateToDays(targetData.date);
                    if (targetData[valueToUse]?.count) {
                        Object.entries(targetData[valueToUse].count).forEach(
                            ([promptId, value]) => {
                                if (value > maxValue) {
                                    maxValue = value;
                                }

                                const dateValue = `${date}-${value}`;
                                dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

                                targetData.value.count[promptId] = {
                                    value,
                                    sameDot: dateValueMap[dateValue],
                                };
                            }
                        );
                    }
                });
            formattedData.push(newTarget);
        });

        const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
        formattedData.forEach((target) => {
            target.targetData.length > 0 &&
                target.targetData.forEach((targetData) => {
                    if (targetData.value?.count) {
                        Object.entries(targetData.value.count).forEach(([promptId, countObj]) => {
                            targetData.value.count[promptId] =
                                countObj.value + countObj.sameDot * dotMultiplier;
                        });
                    }
                });
        });

        return formattedData;
    }, [props.graphData, props.useCumulative]);

    const graphData = useMemo(() => {
        if (!props.showPhase) {
            return formattedGraphData;
        } else {
            return splitGraphDataByPhases(formattedGraphData);
        }
    }, [formattedGraphData, props.showPhase]);

    return props.usePercent ? (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis(
                    'date',
                    dateAxisSetting,
                    graphDomain,
                    graphTicks,
                    'Date',
                    !!props.graphElements.axisLabelsX
                )}
                {yAxis(
                    'percent',
                    percentAxisSetting,
                    'Percentage',
                    !!props.graphElements.axisLabelsY
                )}
                {enableTooltip && !props.isDownloading && (
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                valueFormatter={(value) => `${value}%`}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                )}
                {graphData.map((target, index) => (
                    <Line
                        {...defaultLineSetting}
                        stroke={getTargetColor(target, index, 'light')}
                        dataKey='percent'
                        name={generateTargetName(target, index)}
                        key={generateTargetName(target, index)}
                        isAnimationActive={enableAnimation}
                        data={
                            !disabledLines[target.name]
                                ? target.targetData.map((e) => ({
                                      date: dateToDays(e.date),
                                      percent:
                                          typeof e.value.percentage.correct === 'number'
                                              ? e.value.percentage.correct
                                              : null,
                                  }))
                                : []
                        }
                    >
                        {props.graphElements.lineTitles && (
                            <LabelList
                                dataKey='percent'
                                content={(props) =>
                                    renderCustomizedValue(props, 0, -15, () => target.name)
                                }
                            />
                        )}
                        {props.graphElements.dataValues && (
                            <LabelList
                                dataKey='percent'
                                content={(props) =>
                                    renderCustomizedValue(props, 3, 15, (e) =>
                                        typeof e === 'number' ? `${e}%` : null
                                    )
                                }
                            />
                        )}
                    </Line>
                ))}
            </LineChart>
        </ResponsiveContainer>
    ) : (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis(
                    'date',
                    dateAxisSetting,
                    graphDomain,
                    graphTicks,
                    'Date',
                    !!props.graphElements.axisLabelsX
                )}
                {yAxis('count', defaultAxisSetting, 'Count', !!props.graphElements.axisLabelsY)}
                {enableTooltip && !props.isDownloading && (
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                )}

                {graphData.map(
                    (target, targetIndex) =>
                        target.targetData.length > 0 &&
                        totalTrial
                            .concat(target.customData.codes)
                            .map((prompt, index) => (
                                <Line
                                    {...defaultLineSetting}
                                    stroke={getColor(
                                        (target.isCloned ? target.colorIndex : targetIndex) * 100 +
                                            index,
                                        'light'
                                    )}
                                    dataKey='count'
                                    name={generateTargetName(
                                        { ...target, name: '' },
                                        targetIndex,
                                        prompt.codeDesc
                                    )}
                                    dataId={generateTargetName(
                                        { ...target, name: '' },
                                        targetIndex,
                                        prompt.id
                                    )}
                                    key={generateTargetName(
                                        { ...target, name: '' },
                                        targetIndex,
                                        prompt.id
                                    )}
                                    isAnimationActive={enableAnimation}
                                    data={
                                        !disabledLines[prompt.id]
                                            ? target.targetData.map((val) => ({
                                                  date: dateToDays(val.date),
                                                  count: val.value.count[prompt.id],
                                              }))
                                            : []
                                    }
                                >
                                    {props.graphElements.lineTitles && (
                                        <LabelList
                                            dataKey='count'
                                            content={(props) =>
                                                renderCustomizedValue(
                                                    props,
                                                    0,
                                                    -15,
                                                    () => prompt.id
                                                )
                                            }
                                        />
                                    )}
                                    {props.graphElements.dataValues && (
                                        <LabelList
                                            dataKey='count'
                                            content={(props) =>
                                                renderCustomizedValue(props, 0, 15, (value) =>
                                                    value ? Math.round(value) : ''
                                                )
                                            }
                                        />
                                    )}
                                </Line>
                            ))
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

PromptGraph.propTypes = {
    usePercent: PropTypes.bool,
    graphData: PropTypes.array.isRequired,
    graphElements: PropTypes.object.isRequired,
    graphDomain: PropTypes.array.isRequired,
    graphTicks: PropTypes.array.isRequired,
    showPhase: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool.isRequired,
};

export default PromptGraph;
