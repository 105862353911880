import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog, Chip, CircularProgress,
    Select, MenuItem, InputLabel, TextField, Grid, IconButton, FormHelperText,
    FormControlLabel, Checkbox, Collapse, Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { access } from '../../constants';
import emailIsValid from '../../utils/validateEmail';
import ClientUserConnection from './ClientUserConnection';
import UserInfoPopover from './UserInfoPopover';
import { getPatient } from '../../services/PatientService';
import { setLoading } from '../../store/general/actions';
import { getSitesDropdown } from '../../services/SiteService';
import { CanUserFunc } from '../../components/CanUser';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    multilineInput: {
        minHeight: 90,
    },
    multilineInputLarge: {
        minHeight: 225,
    },
    saveBtn: {
        padding: '6px 40px',
    },
    targetBtn: {
        padding: '6px 50px',
    },
    addNewMenu: {
        backgroundColor: '#0FB99E',
        color: '#ffffff',
        '&:hover': { backgroundColor: '#00826d' },
    },
    chipBox: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
        minHeight: 52,
    },
    buttonChip: {
        border: '0 !important',
        color: '#ffffff',
        backgroundColor: '#0FB99E !important',
        '&:hover': { backgroundColor: '#00826d !important' },
    },
    infoIcon: {
        color: '#0FB99E'
    },
}));

const maxLength = {
    firstName: 100,
    lastName: 100,
    clientId: 50,
    email: 100,
    phone: 100,
    note: 1024,
}

const NewClient = (props) => {
    const classes = useStyles();
    const { onClose, open, onSave, selectedClient, setLoading, loading, user } = props;
    const defaultClient = {
        firstName: '',
        lastName: '',
        clientId: '',
        email: '',
        phone: '',
        branchId: null,
        users: [user],
        note: '',
    };
    const [newClient, setNewClient] = useState(cloneDeep(defaultClient));
    const [error, setError] = useState(false);
    const [sites, setSites] = useState([]);
    const [createAsUser, setCreateAsUser] = useState(false);
    const [disableCreateAsUser, setDisableCreateAsUser] = useState(false);
    const [anchorEl, setAnchorEl] = useState({
        users: null,
        userInfo: null,
    });
    const [selectedUserId, setSelectedUserId] = useState(null);
    const canAddOtherUser = CanUserFunc(access.users.view);

    useEffect(() => {
        setLoading('loadingClient');
        if (open) {
            Promise.all([
                CanUserFunc(access.sites.view) ? getSitesDropdown() : { data: { data: user.branchNames } },
            ]).then((values) => {
                setSites(values[0].data.data);
                if (!!selectedClient) {
                    getPatient(selectedClient.id).then((res) => {
                        let client = res.data.data;
                        client = {
                            ...client,
                            firstName: client.firstName || '',
                            lastName: client.lastName || '',
                            clientId: client.clientId || '',
                            email: client.email || '',
                            phone: client.phone || '',
                            branchId: client.branch ? client.branch.id : '',
                            users: client.users || [],
                            note: client.note || '',
                        }
                        setNewClient(client);

                        // userId indicates that client has an user account
                        setCreateAsUser(!!client.userId);
                        setDisableCreateAsUser(!!client.userId);

                        setLoading('loadingClient', false);
                    });
                } else {
                    setNewClient(cloneDeep(defaultClient));
                    setCreateAsUser(false);
                    setDisableCreateAsUser(false);
                    setLoading('loadingClient', false);
                }
            })

            setError(false);
        }

    }, [open])

    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = type => () => {
        setAnchorEl({ ...anchorEl, [type]: null });
    }

    const handleCancel = () => {
        onClose();
    };

    const handleDeleteUser = (user) => () => {
        const array = newClient.users;
        array.splice(array.indexOf(user), 1);
        setNewClient({ ...newClient, users: array });
    }

    const handleClickUser = user => {
        const array = newClient.users;
        const findUser = array.find(e => e.id === user.id);
        if (findUser) {
            array.splice(array.indexOf(findUser), 1);
        } else {
            array.push(user);
        }
        setNewClient({ ...newClient, users: array });
    }

    const handleChange = field => event => {
        let value = event.target.value
        if (maxLength[field]) {
            if (value.length > maxLength[field]) {
                value = value.substring(0, maxLength[field]);
            }
        }
        setNewClient({ ...newClient, [field]: value });
    }


    const handleChangePhone = event => {
        let filteredInput = event.target.value.replace(/([^0-9+]+)/g, '');
        const firstChar = filteredInput.substring(0, 1);
        const subbedInput = filteredInput.substring(1);
        filteredInput = firstChar + subbedInput.replace('+', '');
        setNewClient({ ...newClient, phone: filteredInput });
    }

    const handleChangeSite = event => {
        const branchId = event.target.value;
        const clientUsers = newClient.users.filter(e => e.isCompanyAdmin || e.branches.find(branch => branch === branchId));
        setNewClient({ ...newClient, branchId, users: clientUsers });
    }

    const handleSave = () => {
        if (!newClient.firstName.trim()
            || !newClient.lastName.trim()
            || !newClient.branchId
        ) {
            setError(true);
            return;
        }

        if (createAsUser) {
            if (!newClient.clientId.trim()
                || !newClient.email.trim()
                || !emailIsValid(newClient.email)
            ) {
                setError(true);
                return;
            }
        }

        let data = {
            ...newClient,
            users: undefined,
            branch: undefined,
            firstName: newClient.firstName.trim(),
            lastName: newClient.lastName.trim(),
            userIds: newClient.users.map(e => e.id),
        }

        if (createAsUser) {
            data = {
                ...data,
                clientId: newClient.clientId.trim(),
                email: newClient.email.trim(),
                phone: newClient.phone.trim(),
            }

            if (!disableCreateAsUser) {
                data.createAsUser = true;
            }
        }

        onSave(data);
    }

    const openUserInfo = id => event => {
        setSelectedUserId(id)
        openAnchorEl('userInfo', event.currentTarget);
    }

    const getUserName = (userObj) => {
        return userObj.fullName || `${userObj.firstName} ${userObj.lastName}`;
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        {!!selectedClient ? 'Edit Client' : 'New Client'}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {loading.loadingClient ?
                    <Grid container justify="center" alignItems="center" style={{ width: 400, height: 400 }}>
                        <CircularProgress size={250} />
                    </Grid>
                    :
                    <Grid container>
                        {!disableCreateAsUser &&
                            <Grid item xs={12} className={classes.largeWrapper} container alignItems="center">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id="client-form-create-as-user"
                                            color="primary"
                                            checked={createAsUser}
                                            onChange={() => setCreateAsUser(!createAsUser)}
                                            value="createAsUser"
                                        />}
                                    label="Create as User"
                                />
                                <Tooltip title="This will create a user account for the client and will invite them to access the system">
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <InputLabel required>First Name</InputLabel>
                            <TextField
                                id="client-form-firstname"
                                variant="outlined"
                                value={newClient.firstName}
                                onChange={handleChange('firstName')}
                                error={error && !newClient.firstName.trim()}
                                helperText={error && !newClient.firstName.trim() ? "First name can't be empty" : ' '}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel required>Last Name</InputLabel>
                            <TextField
                                id="client-form-lastname"
                                variant="outlined"
                                value={newClient.lastName}
                                onChange={handleChange('lastName')}
                                error={error && !newClient.lastName.trim()}
                                helperText={error && !newClient.lastName.trim() ? "Last name can't be empty" : ' '}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <InputLabel required>Site</InputLabel>
                            <Select
                                id="client-form-site"
                                variant="outlined"
                                IconComponent={KeyboardArrowDownIcon}
                                className={classes.input}
                                value={newClient.branchId}
                                onChange={handleChangeSite}
                                error={error && !newClient.branchId}
                            >
                                {sites.map(site => (
                                    <MenuItem id={`clients-new-form-field-site-${site.id}`} key={site.id} value={site.id}>{site.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText id="clients-new-form-site-required-error" error={error && !newClient.branchId}>
                                {error && !newClient.branchId ? 'Please choose a site' : ' '}
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={6} className={createAsUser ? classes.rightWrapper : ''}>
                            <Collapse in={createAsUser}>
                                <InputLabel required>Client ID</InputLabel>
                                <TextField
                                    id="client-form-client-id"
                                    variant="outlined"
                                    value={newClient.clientId}
                                    onChange={handleChange('clientId')}
                                    error={error && !newClient.clientId.trim()}
                                    helperText={error && !newClient.clientId.trim() ? "Client ID can't be empty" : ' '}
                                    className={classes.input}
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={6} className={createAsUser ? classes.leftWrapper : ''}>
                            <Collapse in={createAsUser}>
                                <InputLabel required>Email</InputLabel>
                                <TextField
                                    id="client-form-email"
                                    variant="outlined"
                                    value={newClient.email}
                                    onChange={handleChange('email')}
                                    error={error && (!newClient.email.trim() || !emailIsValid(newClient.email))}
                                    helperText={error ?
                                        ((!newClient.email.trim() && "Email can't be empty")
                                            || (!emailIsValid(newClient.email) ? 'Email is invalid' : ' '))
                                        : ' '
                                    }
                                    className={classes.input}
                                />
                            </Collapse>
                        </Grid>
                        <Grid item xs={6} className={createAsUser ? classes.rightWrapper : ''}>
                            <Collapse in={createAsUser}>
                                <InputLabel>Phone</InputLabel>
                                <TextField
                                    id="client-form-phone"
                                    variant="outlined"
                                    value={newClient.phone}
                                    onChange={handleChangePhone}
                                    className={classes.input}
                                />
                            </Collapse>
                        </Grid>

                        <Grid item xs={12} className={classes.largeWrapper}>
                            <InputLabel>Users</InputLabel>
                            <Grid container className={classes.chipBox}>
                                {newClient.users.map(userObj => {
                                    const isLinked = userObj.id === newClient.userId;
                                    const isUser = userObj.id === user.id;
                                    if (!canAddOtherUser && !isUser) {
                                        return null;
                                    }
                                    return (
                                        <Chip
                                            id={`client-form-user-chip-${userObj.id}`}
                                            variant="outlined"
                                            size="small"
                                            label={`${getUserName(userObj)}${isLinked ? ' (Linked)' : ''}`}
                                            key={userObj.id}
                                            onClick={canAddOtherUser ? openUserInfo(userObj.id) : undefined}
                                            onDelete={isLinked ? undefined : handleDeleteUser(userObj)}
                                        />
                                    )
                                })}
                                {canAddOtherUser &&
                                    <Chip
                                        id={"client-form-user-chip-add-users"}
                                        variant="outlined"
                                        size="small"
                                        label="Add Users"
                                        onClick={(e) => openAnchorEl('users', e.currentTarget)}
                                        className={classes.buttonChip}
                                    />
                                }
                                {(
                                    !canAddOtherUser &&
                                    !newClient.users.find(e => e.id === user.id) &&
                                    user.branches.includes(newClient.branchId)
                                ) &&
                                    <Chip
                                        id="client-form-user-chip-add-me"
                                        variant="outlined"
                                        size="small"
                                        label="Add me"
                                        onClick={(e) => handleClickUser(user)}
                                        className={classes.buttonChip}
                                    />
                                }
                                {(!canAddOtherUser && !!newClient.users.filter(e => e.id !== user.id).length) &&
                                    <Tooltip arrow title="Client is assigned to other users that you don't have access to.">
                                        <Chip
                                            id="client-form-user-chip-other-users"
                                            variant="outlined"
                                            size="small"
                                            label="Other Users"
                                        />
                                    </Tooltip>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.largeWrapper}>
                            <InputLabel>Note</InputLabel>
                            <TextField
                                id="client-form-note"
                                variant="outlined"
                                multiline
                                value={newClient.note}
                                onChange={handleChange('note')}
                                className={classes.input}
                                InputProps={{
                                    classes: {
                                        root: classes.multilineInputLarge,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button id="client-form-cancel-button" className={classes.saveBtn} onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Button id="client-form-save-button" className={classes.saveBtn} onClick={handleSave} disabled={loading.loadingClient || loading.newClient || loading.editClient}>Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>

            {canAddOtherUser &&
                <ClientUserConnection
                    anchorEl={anchorEl.users}
                    onClose={closeAnchorEl('users')}
                    site={sites.find(e => e.id === newClient.branchId) || {}}
                    onClick={handleClickUser}
                    selectedUsers={newClient.users}
                />
            }

            {canAddOtherUser &&
                <UserInfoPopover
                    anchorEl={anchorEl.userInfo}
                    onClose={closeAnchorEl('userInfo')}
                    userId={selectedUserId}
                />
            }
        </Dialog>
    )
}

NewClient.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedClient: PropTypes.object,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading })(NewClient)
