import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';

import TargetPopup from './TargetPopup';
import Wrapper from './Wrapper';
import { useStyles } from '../style';
import PhaseColoredButton from './PhaseColoredButton';
import { KEY_CATEGORY } from '../../../../utils/sessionHistoryRecordParser';
const Category = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);

  const sendCategory = () => {
    let undoMessage = props.target?.customData?.map((item) => {
      const pickedVariant = data?.find((el) => el.id === item.id);
      return `${item.category}: ${
        item.responses[parseInt(pickedVariant?.value)]?.label
      }`;
    });
    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      data,
      { key: KEY_CATEGORY, data: undoMessage }
    );
    setData([]);
    setModal(false);
  };
  const cancelModal = () => {
    setData([]);
    setModal(false);
  };
  const getValue = (id) => {
    const questionObj = data?.find((item) => item.id === id);
    return questionObj ? parseInt(questionObj.value) : null;
  };
  const handleData = (id, event) => {
    setData([
      ...data.filter((item) => item.id !== id),
      { id: id, value: event.target.value, date: (new Date()).toJSON() },
    ]);
  };
  return (
    <Wrapper {...props}>
      <PhaseColoredButton 
        className={classes.editButton} 
        onClick={() => setModal(true)}
        phase={props.target?.phase}
      >
        <Edit />
      </PhaseColoredButton>
      <TargetPopup
        open={modal}
        onSave={() => sendCategory()}
        onClose={() => setModal(false)}
        onCancel={() => cancelModal()}
        title={props.target.name}
        saveDisabled={!data.length}
        isLarge={true}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="100%"
          padding={4}
        >
          {props.target?.customData?.filter(e => !e.isDeleted)?.map((item) => (
            <FormControl key={item.id} className={classes.formWrapper}>
              <FormLabel>{item.category}</FormLabel>
              <RadioGroup
                value={getValue(item.id)}
                onChange={(event) => handleData(item.id, event)}
              >
                {item.responses?.filter(e => !e.isDeleted)?.map((el) => (
                  <FormControlLabel
                    key={el.value}
                    label={el.label}
                    value={el.value}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ))}
        </Box>
      </TargetPopup>
    </Wrapper>
  );
};
Category.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
};
export default Category;
