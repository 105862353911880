import agent from './agent';

export const loginUser = (username, password) => {
    return agent.post('/auth/login', { username, password });
}

export const loginToken = () => {
    return agent.get('/auth/update-token');
}

export const setPassword = (token, password) => {
    return agent.post('/user/set-password', { token, password });
}

export const forgetPassword = (username) => {
    return agent.post('/auth/forgot-password', { username });
}

export const resetPassword = (token, password) => {
    return agent.post('/auth/reset-password', { token, password });
}

export const getCurrentUser = (id) => {
    return agent.get('/auth/get-user', {
        params: {
            id,
            projection: 'id,name,firstName,lastName,email,username,mobilePhone,branches,roles,isActive,title,patients,patients.branch,note,patientId'
        },
    });
}

export const updateCurrentUser = (data) => {
    return agent.put('/auth/update-user', data);
}