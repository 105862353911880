import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';

import withDataTableHoc from './DataTableHoc';
import sharedStyles from './styles';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => sharedStyles({ cellWidth: 24 }));

const createCustomData = (data) => {  
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    for (let index = 0; index < data.value; index++) {
        customData.push(dateNow + index);
    }
    return customData;
}

const Frequency = (props) => {
    const classes = useStyles();
    const { isEdit, dataToUse, setEditingData, handleDelete } = props;

    const handleChange = (index) => (event) => {
        let { value } = event.target;
        if (isNaN(value)) return;
        
        const parsedValue = parseInt(value || 0, 10);
        value = `${parsedValue}`
        if (parsedValue > 999) {
            value = '999';
        }
        if (parsedValue < 0) {
            value = '';
        }
        const newData = dataToUse;
        newData[index].value = value;
        setEditingData(newData.slice());
    }

    return (
        <Grid item xs={12} container>
            <table className={classes.tableCollapse}>
                <tbody>
                    <tr>
                        {dataToUse && dataToUse.map((frequencyData, index) => (
                            <td align="center" key={index} className={classes.tableCellFullBorder}>
                                {isEdit ?
                                    <TextField
                                        value={frequencyData.value}
                                        className={classes.textField}
                                        InputProps={{ classes: { input: classes.input } }}
                                        placeholder={frequencyData.isDelete ? '' : '0'}
                                        onChange={handleChange(index)}
                                        disabled={frequencyData.isDelete || frequencyData.isOngoing}
                                    />
                                    :
                                    <span className={classes.value}>{frequencyData.value || 0}</span>
                                }
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {(isEdit && dataToUse && CanUserFunc(access.data.remove)) && dataToUse.map((frequencyData, index) => (
                            <td align="center" key={`freq-delete-${index}`}>
                                {frequencyData.isDelete &&
                                    <Tooltip title="This session data will be deleted">
                                        <DeleteIcon
                                            color={frequencyData.isOngoing ? 'disabled' : 'action'}
                                            className={clsx(classes.deleteBtn, classes.deleteActive)}
                                            onClick={!frequencyData.isOngoing && handleDelete(index)}
                                        />
                                    </Tooltip>
                                }
                                {!frequencyData.isDelete &&
                                    <DeleteIcon
                                        color={frequencyData.isOngoing ? 'disabled' : 'action'}
                                        className={classes.deleteBtn}
                                        onClick={!frequencyData.isOngoing && handleDelete(index)}
                                    />
                                }
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </Grid>
    )
}

Frequency.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default withDataTableHoc(Frequency, 'frequency', createCustomData);
