import { subscriptionStatuses } from "../constants";
import { logout, showUpdateSubsctiptionDialog } from '../store/auth/actions';
import store from "../store/store";
import dateToDays from "./dateToDays";

export default function checkSubscription(user, tokenLogin = false) {
    if(user.isSuperAdmin) return;
    
    const subscription = user.subscription;
    const status = subscription.status;
    const endDate = subscription.current_term_end || subscription.trial_end;
    const termEnd = new Date(endDate * 1000);
    const now = new Date();
    const diffDays = dateToDays(termEnd) - dateToDays(now);

    if(status === subscriptionStatuses.active || user.isValidPaymentStatus) return;

    if(!subscription) {
        return setTimeout(() => store.dispatch(logout(false, 'You do not have an active subscription')), 100);
    }

    if(status === subscriptionStatuses.future) {
        return setTimeout(() => store.dispatch(logout(false, 'Your subscription period has not started yet')), 100);
    }

    if(diffDays === 0 || termEnd < now || status === subscriptionStatuses.cancelled) {
        if(!user.isCompanyAdmin) {
            return setTimeout(() => store.dispatch(logout(false, 'Your subscription is invalid. Please contact your organization\'s admin')), 100);
        }
        return store.dispatch(showUpdateSubsctiptionDialog(true));
    }

    if(diffDays <= 7 && user.isCompanyAdmin && !tokenLogin) {
        store.dispatch(showUpdateSubsctiptionDialog(true));
    }
}