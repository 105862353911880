import React, { useState, useRef, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Webcam from "react-webcam";
import { Button, makeStyles } from "@material-ui/core";
import { format } from "date-fns";
import Timer from "./Timer";
import { maxVideoTimeLimitInSessions } from "../../../../constants";

const VIDEO_TIME_LIMIT = maxVideoTimeLimitInSessions * 1000; //30 seconds, in ms

const useStyles = makeStyles((theme) => ({
    mainControllers: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: 20,
        marginTop: 10,
    },
    controlButton: {
        borderRadius: 0,
        width: "40%",
    },
    error: {
        textAlign: "center",
        marginTop: "auto",
        marginBottom: "auto",
        color: "red",
    },
    timer: {
        fontWeight: "bold",
    },
}));

const Camera = (props) => {
    const classes = useStyles();

    const [photo, setPhoto] = useState(null);
    const [video, setVideo] = useState(null);
    const [error, setError] = useState("");
    const [videoRecording, setVideoRecording] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);
    const [cameraReady, setCameraReady] = useState(false);
    const [videoRecordStartTime, setVideoRecordStartTime] = useState(null);

    const webCamRef = useRef(null);
    const mediaRecorderRef = useRef(null);

    useEffect(() => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm",
            });
            blob.name = format(new Date(), "yyyyddmm_HHmmss") + ".webm";
            setVideo(blob);
            setRecordedChunks([]);
            setVideoRecording(false);
        }
    }, [recordedChunks.length]);

    const capturePhoto = () => {
        const imageSrc = webCamRef.current.getScreenshot();
        setPhoto(imageSrc);
    };

    const photoSelectHandler = async () => {
        const blob = await (await fetch(photo)).blob();
        props.onPhoto(new File([blob], format(new Date(), "yyyyddmm_HHmmss") + ".png", { type: "image/png" }));
        setPhoto(null);
    };

    const videoSelectHandler = () => {
        props.onVideo(new File([video], format(new Date(), "yyyyddmm_HHmmss") + ".webm", { type: "video/webm" }));
        setVideo(null);
    };

    const startRecordVideo = () => {
        setVideoRecording(true);
        setVideoRecordStartTime(new Date().getTime());
        mediaRecorderRef.current = new MediaRecorder(webCamRef.current.stream, {
            mimeType: "video/webm",
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    };

    const stopRecordVideo = () => {
        mediaRecorderRef.current.stop();
    };

    const handleDataAvailable = ({ data }) => {
        if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    };

    const videoTimeLimitExceedHandler = () => {
        stopRecordVideo();
        props.onVideoTimeLimitReached && props.onVideoTimeLimitReached();
    };

    return (
        <>
            {error && <div className={classes.error}>{error}</div>}
            {!photo && !video && !error && (
                <Webcam
                    audio
                    muted
                    screenshotFormat="image/png"
                    width={"100%"}
                    onUserMedia={() => setCameraReady(true)}
                    ref={webCamRef}
                    onUserMediaError={(error) => {
                        if (error.name === "NotReadableError" || error.name === "TrackStartError") {
                            setError("The webcam is already in use by another application");
                        } else if (!error.message) {
                            setError("Failed to start the webcam");
                        } else {
                            setError(error.message);
                        }
                    }}
                />
            )}
            {photo && !error && <img src={photo} alt="Preview" />}
            {video && !error && <video controls src={URL.createObjectURL(video)} />}
            {!photo && !video && !error && !videoRecording && (
                <div className={classes.mainControllers}>
                    <Button
                        onClick={capturePhoto}
                        className={classes.controlButton}
                        disabled={!cameraReady}
                    >
                        Take Photo
                    </Button>
                    <Button
                        className={classes.controlButton}
                        onClick={startRecordVideo}
                        disabled={!cameraReady}
                    >
                        Record Video
                    </Button>
                </div>
            )}
            {photo && !error && (
                <div className={classes.mainControllers}>
                    <Button
                        onClick={() => {
                            setPhoto(null);
                            setCameraReady(false);
                        }}
                        className={classes.controlButton}
                    >
                        Discard
                    </Button>
                    <Button
                        className={classes.controlButton}
                        onClick={photoSelectHandler}
                    >
                        Use
                    </Button>
                </div>
            )}
            {videoRecording && !error && (
                <div className={classes.mainControllers}>
                    <div className={classes.timer}>
                        <Timer
                            startTime={videoRecordStartTime}
                            stopDuration={VIDEO_TIME_LIMIT}
                            onStopDuration={videoTimeLimitExceedHandler}
                        />
                    </div>
                    <Button className={classes.controlButton} onClick={stopRecordVideo}>
                        Stop
                    </Button>
                </div>
            )}
            {video && !error && (
                <div className={classes.mainControllers}>
                    <Button
                        onClick={() => {
                            setVideo(null);
                        }}
                        className={classes.controlButton}
                    >
                        Discard
                    </Button>
                    <Button
                        className={classes.controlButton}
                        onClick={videoSelectHandler}
                    >
                        Use
                    </Button>
                </div>
            )}
        </>
    );
};

Image.propTypes = {
    onPhoto: PropTypes.func.isRequired,
    onVideo: PropTypes.func.isRequired,
    onVideoTimeLimitReached: PropTypes.func,
};

export default Camera;
