
const sharedStyles = ({ cellWidth }) => ({
    rowSeparator: {
        border: 'none !important',
        minHeight: 10,
        height: 10,
    },
    table: {
        borderSpacing: 0,
    },
    tableCollapse: {
        borderCollapse: 'collapse'
    },
    tableCell: {
        borderTop: '1px solid black',
        borderRight: '1px solid black',
        padding: 2,
        minWidth: 15,
        position: 'relative',
    },
    tableCellFullBorder: {
        border: '1px solid black',
        padding: 2,
        minWidth: 15,
        position: 'relative',
    },
    leftBorder: {
        borderLeft: '1px solid black',
    },
    bottomBorder: {
        borderBottom: '1px solid black',
    },
    topBorder: {
        borderTop: '1px solid black',
    },
    borderlessCell: {
        padding: '0',
        border: 'none',
    },
    value: {
        display: 'inline-block',
        minWidth: cellWidth,
    },
    textField: {
        width: cellWidth,
        maxWidth: cellWidth,
    },
    input: {
        padding: '0px !important',
        textAlign: 'center',
        '&::placeholder': { color: 'black', opacity: 1 }
    },
    deleteBtn: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#d32f2f' },
    },
    deleteBtnTooltip: {
        width: 20,
        height: 20,
        opacity: 0.4,
        color: '#d32f2f'
    },
    deleteActive: {
        opacity: 1,
        color: '#d32f2f',
    },
    addButton: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#0FB99E' },
    },
    menuItem: {
        justifyContent: 'center',
    },
    hidden: {
        display: 'none',
    }
});

export default sharedStyles;