import {
    SET_PATIENT,
    SET_PATIENTS,
} from './action-types';

import { setLoading } from '../general/actions';
import { getPatientListByUser } from '../../services/PatientService';

export const setPatient = (data) => (dispatch) => {
    return dispatch({
        type: SET_PATIENT,
        payload: data,
    })
}

export const setPatients = (data) => (dispatch) => {
    return dispatch({
        type: SET_PATIENTS,
        payload: data,
    })
}

export const getPatients = () => (dispatch, getState) => {
    dispatch(setLoading('getPatients'));
    return getPatientListByUser()
        .then(res => {
            dispatch(setLoading('getPatients', false));
            dispatch(setPatients(res.data.data));
            const defaultPatientId = getState().auth.user?.settings?.defaults?.client;
            const selectedPatientId = getState().general.selection.patient ?? defaultPatientId;
            let selectedPatient = res.data.data[0];
            if (selectedPatientId) {
                if (res.data.data) {
                    selectedPatient = res.data.data.find(e => e.id === selectedPatientId);
                    if (!selectedPatient) {
                        selectedPatient = res.data.data[0];
                    }
                }
            }
            dispatch(setPatient(selectedPatient));
        })
        .catch(error => {
            dispatch(setLoading('getPatients', false));
        });
}
