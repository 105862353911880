import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ListItem, ListItemText, makeStyles, IconButton
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';

const useStyles = makeStyles(theme => ({
    program: {
        fontWeight: 300,
        fontSize: 14,
        paddingLeft: 16,
    },
    programWrapper: {
        position: 'relative',
    },
    listItem: {
        paddingLeft: 24,
        paddingRight: 32,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
    },
    editBtn: {
        position: 'absolute',
        left: 2,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': {
            backgroundColor: '#00826d',
            opacity: 1,
        },
        opacity: 0.4,
    },
}));


const ProgramItem = (props) => {
    const classes = useStyles();
    const { isSelected, program, filter, handleOpenAction } = props;
    const [hoverProgram, setHoverProgram] = useState(false);

    const showActionsProgram = CanUserFunc(access.programs.edit) || CanUserFunc(access.programs.remove); 
    const isProgramArchived = program.status === 'archived';

    return (
        <div key={program.id} className={classes.programWrapper} onMouseEnter={() => setHoverProgram(true)} onMouseLeave={() => setHoverProgram(false)}>
            <ListItem button key={program.id}
                className={`${classes.listItem} ${isSelected ? classes.listItemSelected : ''}`}
                onClick={() => filter()}
            >
                <ListItemText
                    primaryTypographyProps={{ className: classes.program }}
                    primary={program.name}
                    style={isProgramArchived ? { color: 'red' } : {}}
                />
            </ListItem>

            {showActionsProgram && hoverProgram ?
                <IconButton id={`sidebar-program-action-button-${program.id}`}  className={classes.editBtn} onClick={handleOpenAction('program', program)}>
                    <MoreVertIcon />
                </IconButton>
                : null
            }
        </div>
    )
}

ProgramItem.propTypes = {
    isSelected: PropTypes.bool.isRequired,
    program: PropTypes.object.isRequired,
    filter: PropTypes.func.isRequired,
    handleOpenAction: PropTypes.func.isRequired,
}

export default ProgramItem;
