import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    FormControlLabel, Checkbox
} from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import useLazyLoadLibraryTargets from './useLazyLoadLibraryTargets';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';

const LibraryTreeView = (props) => {
    const { programLibraries, setSelected } = props;

    const [internalLoading, setInternalLoading] = useState({})
    const [expandedPrograms, setExpandedPrograms, programsTargetLibraries] =
        useLazyLoadLibraryTargets(programLibraries, internalLoading, setInternalLoading);

    const [selectedTargetIds, setSelectedTargetIds] = useState({})
    const [selectedProgramIds, setSelectedProgramIds] = useState({})

    useEffect(() => {
        if (programLibraries?.length === 1) {
            setExpandedPrograms({ [programLibraries[0].id]: true })
            setSelectedProgramIds({ ...selectedProgramIds, [programLibraries[0].id]: true })
        }
    }, [programLibraries])

    // default target to checked
    useEffect(() => {
        const ids = {};
        Object.values(programsTargetLibraries).forEach(programLibTargets => {
            programLibTargets.forEach(lib => {
                ids[lib.id] = true;
            })
        })
        setSelectedTargetIds({ ...selectedTargetIds, ...ids });
    }, [programsTargetLibraries])

    useEffect(() => {
        const programIds = Object.keys(selectedProgramIds).filter(e => !!selectedProgramIds[e]).map(e => +e);
        const targetIds = Object.keys(selectedTargetIds).filter(e => !!selectedTargetIds[e]).map(e => +e);
        
        const selectedProgamLibraries = programLibraries.filter(e => programIds.includes(e.id));
        const selectedTargetLibraries = {};

        programIds.forEach(progLibId => {
            const targetLibs = programsTargetLibraries[progLibId]?.filter(e => targetIds.includes(e.id)) || [];
            selectedTargetLibraries[progLibId] = targetLibs;
        })

        setSelected(selectedProgamLibraries, selectedTargetLibraries);
    }, [selectedProgramIds, selectedTargetIds])

    const handleSelect = (libId, isProgram = true) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isProgram) {
            selectedProgramIds[libId] = !selectedProgramIds[libId]
            setSelectedProgramIds({ ...selectedProgramIds });

            if (!expandedPrograms[libId]) {
                setExpandedPrograms({ ...expandedPrograms, [libId]: !expandedPrograms[libId] })
            }
        } else {
            selectedTargetIds[libId] = !selectedTargetIds[libId]
            setSelectedTargetIds({ ...selectedTargetIds });
        }
    }

    const onToggle = (id) => (e) => {
        e.preventDefault();
        e.stopPropagation();

        setExpandedPrograms({ ...expandedPrograms, [id]: !expandedPrograms[id] })
    }

    const expandedTrees = useMemo(() => {
        return Object.keys(expandedPrograms).filter(e => !!expandedPrograms[e]);
    }, [expandedPrograms])

    return (
        <TreeView
            defaultCollapseIcon={<KeyboardArrowDown id="library-tree-view-arrow-down" />}
            defaultExpandIcon={<KeyboardArrowRight id="library-tree-view-arrow-right" />}
            expanded={expandedTrees}
        >
            {programLibraries?.map(programLib => (
                <TreeItem
                    key={programLib.id}
                    nodeId={`${programLib.id}`}
                    label={
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`library-tree-view-checkbox-program-${programLib.id}`}
                                    color="primary"
                                    checked={!!selectedProgramIds[programLib.id]}
                                    onClick={handleSelect(programLib.id)}
                                />
                            }
                            label={`(Program) - ${programLib.program.name}`}
                        />
                    }
                    onClick={onToggle(programLib.id)}
                >
                    {programsTargetLibraries[programLib.id]?.map(targetLib => (
                        <TreeItem
                            nodeId={`${targetLib.id}`}
                            key={targetLib.id}
                            label={
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            id={`library-tree-view-checkbox-target-${targetLib.id}`}
                                            color="primary"
                                            checked={!!selectedProgramIds[programLib.id] && !!selectedTargetIds[targetLib.id]}
                                            onClick={handleSelect(targetLib.id, false)}
                                            disabled={!selectedProgramIds[programLib.id]}
                                        />
                                    }
                                    label={`(Target) - ${targetLib.target.name}`}
                                />
                            }
                            onClick={handleSelect(targetLib.id)}
                        />
                    ))}
                    {internalLoading[programLib.id] &&
                        <TreeItem
                            nodeId={programLib.id + 'loading'}
                            label="Loading..."
                        />
                    }
                    {(!internalLoading[programLib.id] && !programsTargetLibraries[programLib.id]?.length) &&
                        <TreeItem
                            nodeId={programLib.id + 'no-targets'}
                            label="No Targets"
                        />
                    }
                </TreeItem>
            ))}
        </TreeView>
    )
}

LibraryTreeView.propTypes = {
    programLibraries: PropTypes.array.isRequired,
    setSelected: PropTypes.func.isRequired,
    id: PropTypes.string
}

export default LibraryTreeView;
