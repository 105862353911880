import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { newDataTypes, targetTrialLimits } from "../../../../constants";
import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotesIcon from "../../../../assets/icons/NotesIcon";
import TargetPopup from "./TargetPopup";
import TextField from "@material-ui/core/TextField";
import StarBorder from '@material-ui/icons/StarBorder';
import Star from '@material-ui/icons/Star';

const useStyles = makeStyles((theme) => ({
    starContainer: {
        padding: 3,
        marginLeft: '-6px',
        '& > .star': {
            color: '#F1B84B'
        },
        '& > svg': {
            width: 20,
        }
    },
    programName: {
        fontWeight: 600,
        fontSize: 16,
    },
    targetDetails: {
        fontSize: 14,
    },
    container: {
        display: "flex",
        justifyContent: "space-between",
    },
    subContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        justifyContent: "space-between",
    },
    indicator: {
        fontSize: 12,
        backgroundColor: "#0FB99E",
        padding: 4,
        borderRadius: 4,
        fontWeight: 500,
        color: "#fff",
    },
    dataContainer: {
        width: "100%",
        overflowX: "auto",
    },
    input: {
        width: "480px",
        "& textarea": {
            minHeight: "100px !important",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            "& textarea": {
                minHeight: "300px !important",
                minWidth: "220px !important",
            },
        },
    },
    note: {
        marginBottom: -5,
        marginLeft: 10,
        maxWidth: 53,
        maxHeight: 53,
    },
    noteHolder: {
        display: "flex",
        flexDirection: "column",
    },
    targetName: {
        marginBottom: "10px",
    }
}));

const Wrapper = (props) => {
    const classes = useStyles();
    const [responseCount, setResponseCount] = useState(0);
    const [note, setNote] = useState("");
    const [notePopupOpen, setNotePopupOpen] = useState(false);

    const saveNoteHandler = () => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {
                type: "note",
                note: note,
            },
            `Set note: ${note}`
        );
        setNotePopupOpen(false);
    };

    const openNoteDialogHandler = () => {
        setLatestNote();
        setNotePopupOpen(false);
    };

    const setLatestNote = () => {
        if (props.sessionNotes?.length) {
            setNote(props.sessionNotes[props.sessionNotes.length - 1]?.data?.note);
        } else {
            setNote("");
        }
    };

    const getTargetDataPointsStyle = () => {
        if (props.target.numberTrials) {
            const percent = (responseCount / props.target.numberTrials) * 100;
            const trialLimit =
                targetTrialLimits.find((value) => percent < value.limit) ||
                targetTrialLimits.reduce((prevValue, nextValue) =>
                    prevValue.limit <= nextValue.limit ? nextValue : prevValue
                );
            if (trialLimit) {
                return { backgroundColor: trialLimit.color };
            }
        }
        return {};
    };

    useEffect(() => {
        if (!props.sessionData?.length) {
            setResponseCount(0);
        } else if (props.responseCounter) {
            setResponseCount(props.responseCounter(props.sessionData));
        } else {
            setResponseCount(props.sessionData.length);
        }
        setLatestNote();
    }, [props.sessionData]);

    return (
        <>
            <div className={classes.container}>
                <div className={classes.starContainer}>{props.target.isStarred ? <Star className="star"/> : <StarBorder />}</div>
                <div className={classes.dataContainer}>
                    <div className={classes.programName}>{props.target.program.name}</div>
                    <div className={classes.targetDetails}>{props.target.name}</div>
                    <div className={classes.targetDetails}>{`Phase: ${props.target.phase
                        }, Data: ${newDataTypes[props.target.dataType]}`}</div>
                    {props.children}
                </div>
                <div className={classes.subContainer}>
                    <span
                        className={classes.indicator}
                        style={getTargetDataPointsStyle()}
                    >
                        {responseCount}
                        {props.target.numberTrials ? `/${props.target.numberTrials}` : ""}
                    </span>
                    <IconButton
                        onClick={() => setNotePopupOpen(true)}
                        className={classes.note}
                    >
                        <NotesIcon />
                    </IconButton>
                </div>
            </div>
            <TargetPopup
                open={notePopupOpen}
                onSave={saveNoteHandler}
                onCancel={openNoteDialogHandler}
                title="Target Note"
            >
                <div className={classes.noteHolder}>
                    <span className={classes.targetName}>T: {props.target.name}</span>
                    <TextField
                        variant="outlined"
                        className={classes.input}
                        multiline
                        InputProps={{
                            onChange: (e) => setNote(e.target.value),
                            value: note,
                        }}
                    />
                </div>
            </TargetPopup>
        </>
    );
};

Wrapper.propTypes = {
    sessionData: PropTypes.array.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
    responseCounter: PropTypes.func,
};

export default Wrapper;
