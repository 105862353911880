import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SearchableSelect from '../SearchableSelect';
import { roleNames } from '../../constants';
import RolePicker from '../../assets/input/RolePicker';

const useStyles = makeStyles(theme => ({
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    changeOwner: {
        fontSize: '0.75rem',
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline',
    },
}));

const OrganizationForm = (props) => {
    const classes = useStyles();
    const {
        updatedUser, users, newOrganization, children,
        handleChange, error, handleSubscriptionChange,
        changeOwner, setChangeOwner
    } = props;

    const companyUsers = users.filter(u => !u.roles.find(r => r.name === roleNames.SUPER_ADMIN));
    const subscription = newOrganization.subscription?.subscription?.subscription_items[0].item_price_id;

    return (
        <Grid container>
            <Grid item xs={6} className={classes.leftWrapper}>
                <InputLabel>Organization Id</InputLabel>
                <TextField
                    variant="outlined"
                    value={newOrganization.organizationId}
                    className={classes.input}
                    disabled
                />
            </Grid>
            <Grid item xs={6} className={classes.largeWrapper}>
                <InputLabel>Account Owner Name</InputLabel>
                <TextField
                    variant="outlined"
                    disabled
                    value={`${newOrganization.owner?.firstName} ${newOrganization.owner?.lastName}`}
                    className={classes.input}
                />

                <span className={classes.changeOwner} onClick={() => setChangeOwner(!changeOwner)}>
                    Change Account Owner
                </span>
            </Grid>
            {changeOwner && 
                <RolePicker 
                    style={{ padding: 6 }}
                    user={updatedUser} 
                    onChange={handleChange('roles')} 
                    title="Role(s) for the previous account owner"
                    error={error}
                    tooltipTitle='Choose the role(s) the previous account owner should be assigned to'
                />
            }
            {changeOwner && 
                <Grid item xs={6} className={classes.largeWrapper}>
                    <InputLabel required>New Account Owner</InputLabel>
                    <SearchableSelect 
                        entities={companyUsers} 
                        handleChange={handleChange('ownerId')} 
                        selectedEntity={newOrganization.owner}
                        displayEntity={user => `${user?.firstName} ${user?.lastName}`}
                        emptyMessage="Loading..."
                    />
                </Grid>
            }
            <Grid item xs={6} className={classes.leftWrapper}>
                <InputLabel required>Organization Name</InputLabel>
                <TextField
                    variant="outlined"
                    value={newOrganization.name}
                    onChange={handleChange('name')}
                    error={error && !newOrganization.name.trim()}
                    helperText={error && !newOrganization.name.trim() ? "Organization name can't be empty" : ' '}
                    className={classes.input}
                />
            </Grid>
            <Grid item xs={6} className={classes.largeWrapper}>
                <InputLabel>Account Owner Email</InputLabel>
                <TextField
                    variant="outlined"
                    disabled
                    value={newOrganization.owner?.email}
                    className={classes.input}
                />
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
                <InputLabel>Subscription</InputLabel>
                    <TextField
                        variant="outlined"
                        disabled
                        value={subscription}
                        className={classes.input}
                    />
                    {!!handleSubscriptionChange && <span className={classes.changeOwner} onClick={handleSubscriptionChange}>
                        Change Subscription
                    </span>}
            </Grid>
            <Grid item xs={6} className={classes.largeWrapper}>
                {!!newOrganization.owner?.mobilePhone && (
                <>
                    <InputLabel>Account Owner Phone</InputLabel>
                    <TextField
                        variant="outlined"
                        disabled
                        value={newOrganization.owner?.mobilePhone}
                        className={classes.input}
                    />
                </>)}
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
                <InputLabel># of Active users</InputLabel>
                <TextField
                    variant="outlined"
                    value={newOrganization.totalUsers}
                    disabled
                    className={classes.input}
                />
            </Grid>
            {children}
        </Grid>

    )
}

OrganizationForm.propTypes = {
    newOrganization: PropTypes.object.isRequired,
    users: PropTypes.array.isRequired,
    handleChange:  PropTypes.func.isRequired,
}

export default OrganizationForm;
