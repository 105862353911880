import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  TextField,
  InputLabel
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import { getUnixTime } from 'date-fns';
import TargetPopup from './TargetPopup';

import Wrapper from './Wrapper';
import PhaseColoredButton from './PhaseColoredButton';

const useStyles = makeStyles((theme) => ({
  editButton: {
    borderRadius: 0,
    width: '100%',
  },
}));

function Text(props) {
  const classes = useStyles();

  const [enterModal, setEnterModal] = useState(false);
  const [data, setData] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleTextInput = () => {
    if (!saving) {
      setSaving(true);
      props.onAddSessionData(
        props.sessionId,
        props.target.id,
        props.target.dataType,
        { data, time: getUnixTime(new Date()) }
      );
      setData([])
      setEnterModal(false);
    }
  };
  const setTextData = (text, type) => {
    var trText = text.replace(/\s+/g, ' ').trim()
    setData({ ...data, [type]: trText });
  };

  const dataValidator = () => {
    let validFields = 0
    const validation = props?.target?.customData.filter(e => !e.isDeleted).forEach(item => {
      if (!item.isRequired || (typeof data[`${item.id}`] !== 'undefined' && data[`${item.id}`] !=='')) {
        validFields+=1
      }
    })
    validFields === props?.target?.customData.filter(e => !e.isDeleted).length ? setSaveDisabled(false) : setSaveDisabled(true)
  }

  const isResponseAllowed = useMemo(() => {
    const maxTrialNo = props.target.maximumNumberTrials;
    if (!!maxTrialNo && !isNaN(maxTrialNo)) {
        return props.sessionData.length < maxTrialNo;
    }
    return true;
  }, [props.sessionData?.length]);


  useEffect(() => {
    dataValidator()
  }, [data])

  useEffect(() => {
      enterModal && setSaving(false);
  }, [enterModal])

  return (
    <Wrapper {...props}>
      <PhaseColoredButton
        className={classes.editButton}
        onClick={() => setEnterModal(true)}
        phase={props.target?.phase}
        disabled={!isResponseAllowed}
      >
        <Edit />
      </PhaseColoredButton>
      <TargetPopup
        open={enterModal}
        onSave={() => handleTextInput()}
        onCancel={() => {setData([]); setEnterModal(false)}}
        title={props.target.name}
        saveDisabled={saveDisabled}
        isLarge={true}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="100%"
        >
          {props?.target?.customData.filter(e => !e.isDeleted).map((item, index) => (
            <Box key={index}>
              <InputLabel required={item.isRequired} style={{'marginBottom': 0}}>{item.value}</InputLabel>
              <TextField
                multiline
                maxRows={6}
                variant="outlined"
                className={classes.textArea}
                onChange={(e) => setTextData(e.target.value, item.id)}
                fullWidth
                style={{'marginBottom': '8px'}}
              />
            </Box>
          ))}
        </Box>
      </TargetPopup>
    </Wrapper>
  );
}

Text.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
  sessionNotes: PropTypes.array.isRequired,
};

export default Text;
