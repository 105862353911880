import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import Timer from "./Timer";
import { getUnixTime, addHours, addSeconds, addMinutes } from "date-fns";
import Edit from "@material-ui/icons/Edit";
import TargetPopup from "./TargetPopup";

import MaskedInput from "react-text-mask";
import PhaseColoredButton from "./PhaseColoredButton";
import { KEY_DURATION_MANUAL } from "../../../../utils/sessionHistoryRecordParser";

const TextMaskDuration = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[0-24]/,
                /\d/,
                " ",
                "h",
                "o",
                "u",
                "r",
                "s",
                " ",
                /[0-5]/,
                /\d/,
                " ",
                "m",
                "i",
                "n",
                "u",
                "t",
                "e",
                "s",
                " ",
                /[0-5]/,
                /\d/,
                " ",
                "s",
                "e",
                "c",
                "o",
                "n",
                "d",
                "s",
            ]}
            placeholderChar={"_"}
            showMask
        />
    );
};

const useStyles = makeStyles((theme) => ({
    durationButton: {
        borderRadius: 0,
        marginRight: 10,
        width: "100%",
    },
    startStopButton: {
        width: "200%",
    },
    durationButtons: {
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    ongoingSessionHolder: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
    },
    codeHolder: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    controlersHolder: {
        display: "flex",
        flexDirection: "row",
        marginTop: 10,
        alignItems: "center",
        width: "100%",
    },
    input: {
        width: "100%",
    },
}));

const Duration = (props) => {
    const classes = useStyles();

    const [timePickerOpen, setTimePickerOpen] = useState(false);
    const [durationError, setDurationError] = useState(false);
    const [customValue, setCustomValue] = useState("");

    const isTimeRunning = () => {
        if (!props.sessionData?.length) {
            return false;
        }
        return props.sessionData.reduce((status, item) => {
            if (item.data?.type === "start") {
                return true;
            } else if (item.data?.type === "stop") {
                return false;
            }
            return status;
        }, false);
    };

    const getTimeFromLastStart = () => {
        const lastStart = props.sessionData
            .slice()
            .reverse()
            .find((e) => e.data?.type === "start");
        return lastStart ? lastStart.data?.time : 0;
    };

    const handleStartStopPress = () => {
        if (isTimeRunning()) {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                {
                    type: "stop",
                    time_ms: new Date().getTime(),
                    time: getUnixTime(new Date()),
                },
                "Finish duration"
            );
        } else {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                {
                    type: "start",
                    time_ms: new Date().getTime(),
                    time: getUnixTime(new Date()),
                },
                "Start duration"
            );
        }
    };

    const handleEmptyPress = () => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {
                type: "empty",
                time_ms: new Date().getTime(),
                time: getUnixTime(new Date()),
            },
            "0"
        );
    };

    const handleCustomDurationAdd = () => {
        const numbers = customValue.split(" ");
        let hours, minutes, seconds;
        if (numbers?.length && numbers.length === 6) {
            hours = numbers[0]?.length === 2 ? parseInt(numbers[0]) : NaN;
            minutes = numbers[2]?.length === 2 ? parseInt(numbers[2]) : NaN;
            seconds = numbers[4]?.length === 2 ? parseInt(numbers[4]) : NaN;
        }
        if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
            setDurationError(true);
        } else {
            setDurationError(false);
            const currentDate = new Date();
            let dateAfterAddingDuration = addHours(currentDate, hours);
            dateAfterAddingDuration = addMinutes(dateAfterAddingDuration, minutes);
            dateAfterAddingDuration = addSeconds(dateAfterAddingDuration, seconds);
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                {
                    type: "custom",
                    start_time_ms: currentDate.getTime(),
                    start_time: getUnixTime(currentDate),
                    end_time_ms: dateAfterAddingDuration.getTime(),
                    end_time: getUnixTime(dateAfterAddingDuration),
                },
                { key: KEY_DURATION_MANUAL, data: { hours, minutes, seconds }}
            );
            setTimePickerOpen(false);
        }
    };

    const responseCounter = (sessionData) => {
        return sessionData.filter(
            (e) => e.data?.type === "stop" || e.data?.type === "custom"
        ).length;
    };

    const isResponseAllowed = useMemo(() => {
        const maxTrialNo = props.target.maximumNumberTrials;
        if (
            props.sessionData?.length &&
            !!maxTrialNo &&
            !isNaN(maxTrialNo)
        ) {
            return responseCounter(props.sessionData) < maxTrialNo;
        }
        return true;
    }, [props.sessionData?.length]);

    return (
        <Wrapper {...props} responseCounter={responseCounter}>
            <div className={classes.durationButtons}>
                <PhaseColoredButton
                    onClick={handleStartStopPress}
                    className={`${classes.durationButton} ${classes.startStopButton}`}
                    disabled={!isResponseAllowed}
                    phase={props.target?.phase}
                >
                    {isTimeRunning() ? "Stop" : "Start"}
                </PhaseColoredButton>
                {isTimeRunning() && <Timer startTime={getTimeFromLastStart() * 1000} />}
                {!isTimeRunning() && (
                    <>
                        <PhaseColoredButton
                            onClick={() => setTimePickerOpen(true)}
                            className={classes.durationButton}
                            disabled={!isResponseAllowed}
                            phase={props.target?.phase}
                        >
                            <Edit />
                        </PhaseColoredButton>
                        <PhaseColoredButton
                            onClick={handleEmptyPress}
                            className={classes.durationButton}
                            disabled={props.sessionData?.length || !isResponseAllowed}
                            phase={props.target?.phase}
                        >
                            0
                        </PhaseColoredButton>
                    </>
                )}
            </div>
            <TargetPopup
                open={timePickerOpen}
                onSave={() => handleCustomDurationAdd()}
                onCancel={() => setTimePickerOpen(false)}
                title="Add Duration"
            >
                <TextField
                    variant="outlined"
                    className={classes.input}
                    error={durationError}
                    helperText={durationError ? "Incorrect duration format" : " "}
                    InputProps={{
                        onChange: (e) => setCustomValue(e.target.value),
                        value: customValue,
                        inputComponent: TextMaskDuration,
                    }}
                />
            </TargetPopup>
        </Wrapper>
    );
};

Duration.propTypes = {
    sessionData: PropTypes.array.isRequired,
    sessionNotes: PropTypes.array.isRequired,
    sessionId: PropTypes.number.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
};

export default Duration;
