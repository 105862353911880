import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    ListItem, ListItemText, makeStyles,
    Divider, IconButton,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    listDivider: {
        marginLeft: 29,
        marginTop: 10,
        marginBottom: 10,
        marginRight: 29,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    domain: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 16,
    },
    listItem: {
        paddingLeft: 24,
        paddingRight: 32,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
    },
    domainWrapper: {
        position: 'relative',
    },
    editBtn: {
        position: 'absolute',
        left: 2,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': {
            backgroundColor: '#00826d',
            opacity: 1,
        },
        opacity: 0.4,
    },
}));

const LibraryDomainItem = (props) => {
    const classes = useStyles();
    const { handleOpenAction, domain, selected, onSelect } = props;
    const [hoverDomain, setHoverDomain] = useState(null);

    return (
        <React.Fragment key={domain.id}>
            <Divider classes={{ middle: classes.listDivider }} variant="middle" />
            <div
                className={classes.domainWrapper}
                onMouseEnter={() => setHoverDomain(true)}
                onMouseLeave={() => setHoverDomain(false)}
            >
                <ListItem button
                    onClick={onSelect(domain.id)}
                    className={clsx(classes.listItem, selected && classes.listItemSelected)}
                >
                    <ListItemText
                        primaryTypographyProps={{ className: classes.domain }}
                        primary={domain.name}
                    />
                </ListItem>
                {hoverDomain &&
                    <IconButton className={classes.editBtn} onClick={handleOpenAction('domain', domain)}>
                        <MoreVertIcon />
                    </IconButton>
                }
            </div>
        </React.Fragment>

    )
}

LibraryDomainItem.propTypes = {
    domain: PropTypes.object.isRequired,
    handleOpenAction: PropTypes.func.isRequired,
}

export default LibraryDomainItem;
