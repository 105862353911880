import React, { Fragment } from 'react'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { IconButton, Tooltip } from '@material-ui/core';
import { Tune } from '@material-ui/icons';

const TargetActionMenu = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };
    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    return (
        <Fragment>
            <IconButton
                id="session-modal-refresh"
                className={props.buttonClassName}
                onClick={handleToggle}
                ref={anchorRef}
            >
                <Tooltip title="Dropdown menu actions"><Tune /></Tooltip>
            </IconButton>
            <Menu
                anchorEl={anchorRef.current}
                keepMounted
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {props.buttons.map((button, index) => (
                    <MenuItem
                        id={props.id ? `${props.id}-manu-item-${button.name}` : ''}
                        key={button.name}
                        disabled={button.disabled}
                        onClick={button.onClick}
                    >
                        {button.name}
                    </MenuItem>
                ))}
            </Menu>
        </Fragment>
    )
}

export default TargetActionMenu