import axios from 'axios';
import store from '../store/store';
import { logout } from '../store/auth/actions';
import { setSnackbar, clearLoading } from '../store/general/actions';

const cancelTokens = {};

export const cancelPreviousRequest = (id) => {
  cancelTokens[id] && cancelTokens[id].cancel();
  cancelTokens[id] = axios.CancelToken.source();
  return cancelTokens[id].token;
}

store.subscribe(listener)

let token = '';

function select(state) {
  return state.auth.token;
}

function listener() {
  token = select(store.getState())
}

const agent = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	// baseURL: 'http://localhost:8002/api/v1',
});

export const httpErrorHandling = (error) => {
  store.dispatch(clearLoading())
  if (error && error.response) {
    if (error.response.status === 401) {
      //store.dispatch(logout());
      store.dispatch(setSnackbar('error', 'Unauthorized action.'));
    } else if (error.response.status === 422) {
      if (error.response.data) {
        store.dispatch(setSnackbar('warning', error.response.data.message || error.response.data.error.message));
      }
    } else {
      store.dispatch(setSnackbar('error', error.response.data.message || error.response.data.error.message || error.response.data.error));
    }
  }
}

agent.interceptors.request.use(function (config) {
    config.headers.Authorization = 'Bearer ' + token;
    config.headers.Timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;

    return config;
});

agent.interceptors.response.use(response => {
  return response;
}, error => {
  httpErrorHandling(error)
  return Promise.reject(error);
})

export default agent;
