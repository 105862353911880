import React from "react";
import { Button } from "@material-ui/core";
import { sessionPhaseColor } from '../../../../theme';

const PhaseColoredButton = (props) => {
    const { phase, ...rest } = props;
    const backgroundColor = !props.disabled ? sessionPhaseColor[phase] : 'lightgray';
    return <Button {...rest} style={{ backgroundColor, color: '#000000' }}>
        {props.children}
    </Button>
}

export default PhaseColoredButton;