import React, { useState } from 'react';
import {
    CartesianGrid, Legend, ReferenceLine, XAxis, Label, YAxis, Surface, Symbols,
} from 'recharts';
import {
    defaultGridSetting
} from './GraphSettings';

import dateToDays from '../../../utils/dateToDays';
import capitalize from '../../../utils/capitalize';
import { phaseColor } from '../../../theme';
import CustomLegend from './CustomLegend';

import { emptyAnnotationPhaseString } from './../../../constants';
import { masteryLineUnitNames } from './../../../constants';
import { masteryLineUnits } from './../../../constants';

export default (props) => ([
    <CartesianGrid {...defaultGridSetting} key="cartesian" />,
    <Legend
        key="legend"
        iconSize={24}
        wrapperStyle={{
            fontSize: 14,
            bottom: 10,
            visibility: props.graphElements.legend ? 'visible' : 'hidden',
        }}
        payload={props.legendPayload}
        content={<CustomLegend setDisabledLines={props.setDisabledLines} />}
    />,

    props.showPhase && props.graphData.map((target, index) => {

        const getReferenceLine = (label, annotation, key, stroke, strokeDasharray) => {
            if (label.value?.length > 30) {
                let breakpoint = label.value.indexOf(' ', 30);
                if (!breakpoint || breakpoint === -1) {
                    breakpoint = label.value.substring(0, 30).lastIndexOf(' ');
                }
                if (!breakpoint || breakpoint === -1) {
                    breakpoint = parseInt(label.value.length/2);
                }
                const label1 = {...label, value: label.value.substring(0, breakpoint)};
                const label2 = {...label, value: label.value.substring(breakpoint, label.value.length)};
                return (
                    <ReferenceLine
                        key={key}
                        x={dateToDays(annotation.showAt)}
                        stroke={stroke}
                        strokeDasharray={strokeDasharray}
                    >
                        <Label {...label1} dx={22} angle={90} fontSize={14} />
                        <Label {...label2} dx={8} angle={90} fontSize={14} />
                    </ReferenceLine>
                );
            }
            return (
                <ReferenceLine
                    key={key}
                    x={dateToDays(annotation.showAt)}
                    stroke={stroke}
                    strokeDasharray={strokeDasharray}
                >
                    <Label {...label} dx={8} angle={90} fontSize={14} />
                </ReferenceLine>
            );
        }

        return target.annotations ? target.annotations
            .filter(e => e.type !== 'masteryLine')
            .sort((a, b) => new Date(a.showAt) - new Date(b.showAt))
            .map((annotation, index2) => {
                let stroke;
                if (props.graphElements?.phaseConditionLines === false) {
                    stroke = 'white';
                } else {
                    stroke = annotation.lineType === 'no line' ? 'white' : (phaseColor[annotation.phase] || 'black');
                }
                const strokeDasharray = annotation.lineType === 'dashed' ? '2 2' : '';
                let labelText;
                if (props.graphElements?.annotations === false) {
                    labelText = "";
                } else if (annotation.phase && annotation.phase !== emptyAnnotationPhaseString && annotation.comment) {
                    labelText = capitalize(annotation.phase) + '; ' + annotation.comment;
                } else if (annotation.phase && annotation.phase !== emptyAnnotationPhaseString ) {
                    labelText = capitalize(annotation.phase);
                } else if (annotation.comment) {
                    labelText = annotation.comment;
                } else {
                    labelText = "";
                }
                const label = { value: labelText, position: 'center', fontSize: 12, style: { textAnchor: 'middle' } };

                const { graphTicks } = props;
                const tick = dateToDays(annotation.showAt);
                if (!(graphTicks[0] <= tick && tick <= graphTicks[graphTicks.length - 1])) {
                    return null;
                }
                return getReferenceLine(label, annotation, `${annotation.phase}-${index}-${index2}-left`, stroke, strokeDasharray);
            }) : null;
        }),

    props.showPhase && props.graphData.map((target, index) => {
        const isMasteryLineApplicable = (dataType, masteryLine) => {
            const isNumberPercentageSupported = masteryLineUnits[dataType].filter(e => e === masteryLineUnitNames.percentage || e === masteryLineUnitNames.number).length === 2;
            let applicable = false;
            if (dataType === 'rate' && props.rateUnit === 'hour' && masteryLine?.data?.unit === masteryLineUnitNames.numberPerHour) {
                applicable = true;
            } else if (dataType === 'rate' && props.rateUnit === 'minute' && masteryLine?.data?.unit === masteryLineUnitNames.numberPerMinute) {
                applicable = true;
            } else if (isNumberPercentageSupported && props.usePercent && masteryLine?.data?.unit === masteryLineUnitNames.percentage) {
                applicable = true;
            } else if (isNumberPercentageSupported && !props.usePercent && masteryLine?.data?.unit === masteryLineUnitNames.number) {
                applicable = true;
            } else if (dataType === 'frequency' || dataType === 'rating' || dataType === 'duration' || dataType === 'quantity' || dataType === 'probe') {
                applicable = true;
            }
            return applicable;
        }

        const getReferenceLine = (label, masteryLine, key, stroke, strokeDasharray) => {
            let yValue = masteryLine.data?.yValue;
            if (!isNaN(yValue) && target.dataType === 'duration' && masteryLine.data?.unit === masteryLineUnitNames.minutes) {
                yValue = yValue * 60;
            }
            return (
                <ReferenceLine
                    key={key}
                    y={yValue}
                    stroke={stroke}
                    strokeDasharray={strokeDasharray}
                >
                    <Label {...label} dy={-12} angle={0} fontSize={14} />
                </ReferenceLine>
            );
        }

        return target.annotations ? target.annotations
            .filter(e => e.type === 'masteryLine' && !isNaN(e.data?.yValue))
            .sort((a, b) => new Date(a.showAt) - new Date(b.showAt))
            .map((masteryLine, index2) => {
                const stroke = (props.graphElements?.phaseConditionLines === false || masteryLine.lineType === 'no line') ? 'white' : 'black';
                const strokeDasharray = masteryLine.lineType === 'dashed' ? '2 2' : '';
                let labelText = (props.graphElements?.annotations !== false && masteryLine.comment) ? masteryLine.comment : "";
                const label = { value: labelText, position: 'right', fontSize: 12, style: { textAnchor: 'end' } };

                return isMasteryLineApplicable(target.dataType, masteryLine) 
                    ? getReferenceLine(label, masteryLine, `masteryline-${index}-${index2}`, stroke, strokeDasharray) : null;
            }) : null;
        }),
])

export const xAxis = (dataKey, axisSettings, domain, ticks, label, showLabel, offset = -5) => (
    <XAxis dataKey={dataKey} {...axisSettings} domain={domain} ticks={ticks}>
        {showLabel && <Label value={label} position="insideBottom" style={{ fontSize: 14 }} offset={offset} />}
    </XAxis>
)

export const yAxis = (dataKey, axisSettings, label, showLabel, offset = -5) => (
    <YAxis dataKey={dataKey} {...axisSettings}>
        {showLabel && <Label value={label} position="insideLeft" angle={-90} style={{ fontSize: 14 }} offset={offset} />}
    </YAxis>
)