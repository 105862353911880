import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
import {
    Button,
    Grid, InputAdornment, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import debouncedPromise from 'awesome-debounce-promise';

//Services
import * as TargetService from '../../services/TargetService';

//Components
import { localStorageEnum } from '../../constants';
import { setGlobalDialog, setLoading, setSelection, setSnackbar, setTargetLoading } from '../../store/general/actions';
import TargetGrid from './TargetGrid';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import DropdownButton from '../../assets/button/DropdownButton';
import { addTargetToDataSheet, createDataSheet, deleteDataSheet, getDataSheet, listDataSheet, printDataSheet, removeTargetFromDataSheet, updateDataSheet } from '../../services/DataSheetService';
import GroupedButton from '../../assets/button/GroupedDropdownButton';
import urlDownload from '../../utils/urlDownload';
import MobileSearchInput from '../../assets/input/MobileSearchInput';

const useStyles = makeStyles(theme => ({
    newBtnWrapper: {
        padding: '5px 30px',
        backgroundColor: '#FFFFFF',
        '@media (max-width: 768px)': {
            maxWidth: 'calc(100vw - 40px)',
            padding: '5px 0',
            margin: 'auto',
        }
    },
    domainStatusWrapper: {
        backgroundColor: '#F4F4F4',
        padding: '12px 0px 12px 30px',
        height: 60,
    },
    nameWrapper: {
        color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
        display: 'inline-block',
        lineHeight: '32px',
        whiteSpace: 'nowrap',
    },
    downloadButton: {
        marginRight: 20,
    },
    searchField: {
        width: '100%'
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    sheetSelect: {
        marginLeft: 10,
    },
    sheetTextField: {
        width: 200,
        marginLeft: 10,
    },
    textFieldInput: {
        color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: '26px',
    },
    saveBtn: {
        padding: '6px 20px',
        marginRight: 20,
    },
    btnContainer: {
        display: 'flex'
    }
}));

const debouncedAddTargets = debouncedPromise(addTargetToDataSheet, 750);
const debouncedRemoveTargets = debouncedPromise(removeTargetFromDataSheet, 750);


const searchTarget = (targets, search) => {

    const searchInput = search.toLowerCase();
    if (!searchInput) {
        return targets;
    }
    const filteredTargets = targets.filter((target) => {
        return target.program.name.toLowerCase().includes(searchInput) || target.name.toLowerCase().includes(searchInput)
    });
    return filteredTargets;
}

const DataSheet = (props) => {
    const classes = useStyles();
    const {
        selection, patient, loading, setSnackbar, isMobile,
        setLoading, setTargetLoading, setGlobalDialog
    } = props;

    const [targets, setTargets] = useState([]);
    const [gridTargets, setGridTargets] = useState([]);
    const [search, setSearch] = useState('');
    const [dataSheets, setDataSheets] = useState([]);
    const [selectedDataSheetId, setSelectedDataSheetId] = useState(0);
    const [dataSheet, setDataSheet] = useState(null);
    const [selectedTargets, setSelectedTargets] = useState({});
    const [editSelectedTargets, setEditSelectedTargets] = useState({});
    const [sheetName, setSheetName] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [isNew, setIsNew] = useState(false);

    useEffect(() => {
        setEditMode(false);
        fetchTargets();
        fetchDataSheets();
    }, [selection])

    useEffect(() => {
        const delayedSearch = setTimeout(() => {
            setGridTargets(searchTarget(targets, search));
        }, 750);

        return () => clearTimeout(delayedSearch);
    }, [targets, search])

    useEffect(() => {
        fetchDataSheet(selectedDataSheetId);
    }, [selectedDataSheetId])

    const fetchTargets = () => {
        setTargetLoading(true);
        TargetService.getFilteredTargets({ ...selection, domain: 'all', program: 'all', status: 'active' }).then(res => {
            setTargets(res.data.data);
            setTargetLoading(false);
        })
    }

    const fetchDataSheet = (selectedId) => {
        if (selectedId !== 0) {
            setTargetLoading(true);
            localStorage.setItem(localStorageEnum.selectedDataSheetId, selectedId);
            getDataSheet(selectedId).then((res) => {
                const selectedTargets = {};

                if (res.data.data) {
                    const dataSheet = res.data.data;
                    setDataSheet(dataSheet);
                    setSheetName(dataSheet.name);
                    dataSheet.targets.forEach(target => { selectedTargets[target.id] = true });
                } else {
                    setDataSheet(null);
                    setSheetName(null);
                }

                setSelectedTargets({ ...selectedTargets });
                setEditSelectedTargets({ ...selectedTargets });
                setTargetLoading(false);
            })
        } else {
            setDataSheet(null)
            setSheetName('')
        }
    }

    const handleSelectionUpdate = (selectedId) => {
        const dataSheetTargets = isNew ? [] : dataSheet.targets.map(target => target.id);
        const addPayload = [];
        const removePayload = [];
        Object.entries(editSelectedTargets).forEach(([field, value]) => {
            const targetId = parseInt(field, 10);
            if (value) {
                if (!dataSheetTargets.includes(targetId)) {
                    addPayload.push(targetId);
                }
            } else {
                if (dataSheetTargets.includes(targetId)) {
                    removePayload.push(targetId);
                }
            }
        })

        if (!addPayload.length && !removePayload.length) {
            return;
        }

        const addFunc = addPayload.length ? debouncedAddTargets(selectedId, addPayload) : () => { };
        const removeFunc = removePayload.length ? debouncedRemoveTargets(selectedId, removePayload) : () => { };

        Promise.all([addFunc, removeFunc]).then(() => {
            fetchDataSheet(selectedId);
        })
    }

    const fetchDataSheets = (selectId, callback) => {
        if (patient?.id) {
            listDataSheet(patient.id).then((res) => {
                setDataSheets(res.data.data);
                let savedSelection = localStorage.getItem(localStorageEnum.selectedDataSheetId);
                savedSelection = savedSelection ? parseInt(savedSelection, 10) : 0;
                if (selectId) {
                    setSelectedDataSheetId(selectId);
                } else {
                    if (res.data.data.find(e => e.id === savedSelection)) {
                        setSelectedDataSheetId(savedSelection);
                    } else {
                        setSelectedDataSheetId(res.data.data[0]?.id || 0);
                    }
                }
                callback && callback();
            })
        }
    }

    const saveDataSheet = () => {
        setTargetLoading(true);
        setLoading('saveDataSheet');
        const saveFunc = isNew ? createDataSheet : updateDataSheet;

        const data = { name: sheetName };
        if (isNew) {
            data.patientId = patient.id;
            data.branchId = patient.branch.id;
        } else {
            data.dataSheetId = dataSheet.id;
        }

        saveFunc(data).then((res) => {
            setSnackbar('success', `Data sheet ${isNew ? 'added!' : 'updated!'}`)
            setLoading('saveDataSheet', false);
            setEditMode(false);
            fetchDataSheets(res.data.data.dataSheetId);
            handleSelectionUpdate(res.data.data.dataSheetId);
        })
    }

    const askDelete = () => {
        setGlobalDialog(
            'Delete data sheet?',
            'Deleted data sheet cannot be restored, please proceed with caution.',
            (answer) => handleDelete(answer)
        )
    }

    const handleDelete = (answer) => {
        if (answer) {
            setLoading('saveDataSheet');
            deleteDataSheet(selectedDataSheetId).then(() => {
                setSnackbar('success', 'Data sheet deleted!')
                setLoading('saveDataSheet', false);
                fetchDataSheets();
            })
        }
    }

    const handleDownload = () => {
        printDataSheet(selectedDataSheetId).then((res) => urlDownload(res.data.data.path));
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    }

    const handleSheetChange = (event) => {
        const { value } = event.target;
        setSelectedDataSheetId(value);
    }

    const addNewDataSheet = () => {
        setEditMode(true);
        setIsNew(true);
        setSheetName('');
        setEditSelectedTargets({});
    }

    const editDataSheet = () => {
        setEditMode(true);
        setIsNew(false);
        setSheetName(dataSheet.name);
        setEditSelectedTargets({ ...selectedTargets });
    }

    const dataSheetOptions = useMemo(() => {
        return dataSheets.map((sheet) => ({ label: sheet.name, value: sheet.id }));
    }, [dataSheets])

    const groupedButtonOptions = useMemo(() => {
        const options = [
            { name: 'New Sheet', onClick: addNewDataSheet }
        ]
        if (dataSheetOptions.length) {
            options.push({ name: 'Edit Sheet', onClick: editDataSheet });
            options.push({ name: 'Delete Sheet', onClick: askDelete })
        } else {
            options.push({ name: 'Edit Sheet', onClick: editDataSheet, disabled: true });
            options.push({ name: 'Delete Sheet', onClick: askDelete, disabled: true })
        }
        return options;
    }, [dataSheetOptions, selectedDataSheetId, editDataSheet])

    const handleSelect = (targetId) => (event) => {
        const { checked } = event.target;

        if (targetId === 'all') {
            gridTargets.forEach((target) => {
                editSelectedTargets[target.id] = checked;
                setEditSelectedTargets({ ...editSelectedTargets });
            })
            return;
        }
        editSelectedTargets[targetId] = checked;
        setEditSelectedTargets({ ...editSelectedTargets });
    }

    const selectedTargetsList = useMemo(() => {
        if (dataSheet?.targets) {
            const newGridTargets = [];

            dataSheet.targets.forEach(target => {
                const gridTarget = gridTargets.find(e => target.id === e.id);
                if (gridTarget) {
                    newGridTargets.push(gridTarget);
                }
            })

            return newGridTargets;
        }
        return [];
    }, [dataSheet, gridTargets])

    const allTargets = useMemo(() => {
        const filteredTargets = gridTargets.filter(e => !selectedTargets[e.id]);
        return selectedTargetsList.concat(filteredTargets);
    }, [selectedTargetsList, gridTargets, selectedTargets]);

    return (
        <div style={{ maxWidth: "100%" }}>
            <Grid container>
                <Grid item xs={12} className={classes.newBtnWrapper} container alignItems="center">
                    <Grid item xs={isMobile ? 8 : 5} container alignItems="center">
                        <GroupedButton
                            id="datasheet-page-actions"
                            buttons={groupedButtonOptions}
                            disabled={!patient?.id || editMode}
                            saveSelectedOption="datasheet-action"
                        />
                        <DropdownButton
                            id="datasheet-page-select"
                            selectedValue={selectedDataSheetId}
                            onValueChange={handleSheetChange}
                            wrapperStyle={classes.sheetSelect}
                            options={dataSheetOptions}
                            disabled={!dataSheetOptions.length}
                            emptySelection="No data sheet available"
                            customLabel={isMobile ? 'Sheet' : undefined}
                        />
                    </Grid>
                    {!isMobile && <Grid item xs={6} id="program-page-search">
                        <TextField
                            id="datasheet-page-search"
                            variant="outlined"
                            className={classes.searchField}
                            placeholder="Program Name, Target Name..."
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>}
                    <Grid className={classes.btnContainer} item xs={isMobile ? 4 : 1} container justify="flex-end">
                        <TooltipedIconButton
                            icon={<GetAppIcon />}
                            onClick={handleDownload}
                            disabled={!targets?.length || loading.downloadClinical}
                            tooltip="Download Selected Datasheet"
                            className={classes.downloadButton}
                            id="datasheet-page-download"
                        />
                        {isMobile && <MobileSearchInput onSearch={handleSearch} />}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.domainStatusWrapper}>
                <Grid item xs={6} className={classes.nameWrapper}>
                    <span id="datasheet_data_sheet_title">Data Sheet:</span>
                    {editMode ?
                        <TextField
                            id="datasheet_name_input"
                            placeholder="Sheet Name*"
                            className={classes.sheetTextField}
                            onChange={(e) => setSheetName(e.target.value)}
                            defaultValue={sheetName}
                            InputProps={{
                                classes: {
                                    root: classes.textFieldInput
                                }
                            }}
                            autoFocus
                        />
                        :
                        <span id="datasheet_name_text" style={{ marginLeft: 10 }}>{sheetName}</span>
                    }
                </Grid>
                {editMode &&
                    <Grid item xs={6} container justify="flex-end">
                        <Button id="datasheet_cancel_button" disabled={loading.saveDataSheet} className={classes.saveBtn} onClick={() => setEditMode(false)}>Cancel</Button>
                        <Button id="datasheet_save_button" disabled={loading.saveDataSheet || !sheetName.trim()} className={classes.saveBtn} onClick={saveDataSheet}>Save</Button>
                    </Grid>
                }
            </Grid>
            <TargetGrid
                targets={editMode ? (isNew ? gridTargets : allTargets) : selectedTargetsList}
                onSelect={editMode ? handleSelect : undefined}
                selectedTargets={editMode ? editSelectedTargets : selectedTargets}
            />

        </div>
    );
}

DataSheet.propTypes = {
    selection: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setSelection: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setTargetLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    domains: state.data.domains,
    programs: state.data.programs,
    selection: state.general.selection,
    patient: state.patient.patient,
    loading: state.general.loading,
    isMobile: state.general.isMobile
})

export default connect(
    mapStateToProps,
    { setSelection, setSnackbar, setLoading, setTargetLoading, setGlobalDialog }
)(DataSheet)
