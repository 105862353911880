import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import withDataTableHoc from './DataTableHoc';
import sharedStyles from './styles';
import BasicDataLayout from './BasicDataLayout';

const useStyles = makeStyles((theme) => sharedStyles({ cellWidth: 40 }));

const createCustomData = (data) => {
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    for (let index = 0; index < data.value.length; index++) {
        customData.push([data.value[index], dateNow + index]);
    }

    return customData;
};

const Prompt = (props) => {
    const classes = useStyles();
    const { dataToUse, setEditingData, customData } = props;

    const customDataDict = useMemo(() => {
        const dict = {};
        customData.codes.forEach((data) => {
            dict[data.id] = data.code;
        });
        return dict;
    }, [customData]);

    const handleChange = (index, index2) => (event) => {
        const newData = dataToUse;
        newData[index].value[index2] = event.target.value;
        setEditingData(newData.slice());
    };

    const editComponent = (value, index, index2, DATA_PER_ROW, index3, isDisabled) => (
        <TextField
            value={value}
            disabled={isDisabled}
            className={classes.textField}
            InputProps={{ classes: { input: classes.input } }}
            onChange={handleChange(index, index2 * DATA_PER_ROW + index3)}
            select
            SelectProps={{ IconComponent: () => null }}
        >
            {props.customData?.codes?.map((item) => (
                <MenuItem key={item.id} className={`${classes.menuItem} ${item.isDeleted ? classes.hidden : ''}`} value={item.id}>
                    {item.code}
                </MenuItem>
            ))}
        </TextField>
    );

    return (
        <BasicDataLayout
            editComponent={editComponent}
            valueFormatter={(value) => customDataDict[value] || '-'}
            classes={classes}
            {...props}
        />
    );
};

Prompt.propTypes = {
    customData: PropTypes.object.isRequired,
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default withDataTableHoc(Prompt, 'prompt', createCustomData);
