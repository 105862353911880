import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import { closeGlobalDialog } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
  closeIconBtn: {
    padding: '0px 4px',
    color: '#0FB99E',
  },
}));

const GlobalDialog = (props) => {
	const classes = useStyles();
  const { title, message, open, handleAnswer, content, showActions } = props.globalDialog;
  const { closeGlobalDialog } = props;

  const handleClose = (isOk) => () => {
    if (!!handleAnswer) {
      handleAnswer(isOk);
    }
    closeGlobalDialog();
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container>
          <Grid id="alert-dialog-title" item xs={11}>
            {title}
          </Grid>
          <Grid item xs={1} container justify="flex-end">
            <IconButton id="alert-dialog-button-close"  className={classes.closeIconBtn} onClick={handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
        {content}
      </DialogContent>
      {showActions &&
        <DialogActions>
          <Button id="alert-dialog-button-cancel" onClick={handleClose(false)}>
            Cancel
          </Button>
          <Button id="alert-dialog-button-ok" onClick={handleClose(true)} autoFocus>
            OK
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

GlobalDialog.propTypes = {
  globalDialog: PropTypes.object.isRequired,
  closeGlobalDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  globalDialog: state.general.globalDialog,
})

export default connect(mapStateToProps, { closeGlobalDialog })(GlobalDialog)
