import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import debounce from 'awesome-debounce-promise';
import { setLoading, setSnackbar } from '../../store/general/actions';
import { searchDevice } from '../../services/DeviceService';
import DevicesTable from './DevicesTable';
import { localStorageEnum } from '../../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '10px 40px',
        '@media (max-width: 768px)': {
            padding: '10px 15px',
        }
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 0px 15px 0px',
        backgroundColor: '#FFFFFF',
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    searchWrapper: {
        marginBottom: theme.spacing(2),
    },
    popoverPaper: {
        padding: 20,
    },
}));

const debouncedSearchDevices = debounce(searchDevice, 1000);

const Devices = (props) => {
    const classes = useStyles();
    const { setSnackbar, setLoading, loading } = props;
    const [devices, setDevices] = useState([]);
    const isMounted = useRef(false);

    const [sort, setSort] = useState({ sortType: '', sortBy: '' });
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        let deviceFilters = localStorage.getItem(localStorageEnum.deviceFilters);

        if (deviceFilters) {
            deviceFilters = JSON.parse(deviceFilters);
            setSort(deviceFilters.sort);
        }

        isMounted.current = true;
        return () => (isMounted.current = false);
    }, []);

    useEffect(() => {
        searchDevice();
    }, [sort, keyword]);

    const searchDevice = () => {
        setLoading('searchDevice');
        debouncedSearchDevices(keyword, sort).then((res) => {
            if (isMounted.current) {
                setDevices(res.data.data ?? []);
                setLoading('searchDevice', false);
            }
        });
    };

    const handleSearch = (event) => {
        setKeyword(event.target.value);
    };

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} mb={2} className={classes.searchWrapper} id='device-search'>
                <TextField
                    id="device-page-search-input"
                    autoComplete='search-field'
                    onChange={handleSearch}
                    value={keyword}
                    fullWidth
                    variant='outlined'
                    className={classes.searchField}
                    placeholder='First Name, Last Name, Username, Device name, Device OS'
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position='start'>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <DevicesTable devices={devices} fetchDevices={searchDevice} setSort={setSort} sort={sort} />
            </Grid>
        </Grid>
    );
};

Devices.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.general.loading,
});

export default connect(mapStateToProps, { setSnackbar, setLoading })(Devices);
