import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, LabelList, Tooltip } from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
    dateAxisSetting,
    defaultAxisSetting,
    graphHeight,
    defaultLineSetting,
    percentAxisSetting,
    defaultLineChartSetting,
    getColor,
    toDateFull,
    renderCustomizedValue,
    defaultTooltipSetting,
    getMinMaxDate,
    generateDomainTicks,
    splitGraphDataByPhases,
    generateTargetName,
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const IntervalGraph = (props) => {
    const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
    const [disabledLines, setDisabledLines] = useState({});
    const countTotalData = props.graphData
        .map((target) => target.targetData.length > 0 && target.customData.codes.length)
        .reduce((a, b) => a + b);
    const height = graphHeight + Math.floor(countTotalData / 7) * 25;
    const valueToUse = props.usePercent ? 'value' : props.useCumulative ? 'cumulative' : 'value';

    const formattedPayload = useMemo(() => {
        const payload = [];
        props.graphData.forEach((target) => {
            target.targetData.length > 0 &&
                target.customData.codes.forEach((item, index) => {
                    if (disabledLines[item.id]) return;

                    target.targetData
                        .filter((e) => dateToDays(e.date) === hoveredDate)
                        .forEach((targetData) => {
                            if (props.usePercent) {
                                payload.push({
                                    date: hoveredDate,
                                    color: getColor(index, 'light'),
                                    name: item.code,
                                    value: targetData.value.percentage[item.id],
                                });
                            } else {
                                payload.push({
                                    date: hoveredDate,
                                    color: getColor(index, 'light'),
                                    name: item.code,
                                    value: targetData[valueToUse].count[item.id] || 0,
                                });
                            }
                        });
                });
        });
        return payload;
    }, [hoveredDate, props.usePercent, props.graphData]);

    const { graphDomain, graphTicks } = useMemo(() => {
        let { graphDomain, graphTicks } = props;
        if (!graphDomain.length || !graphTicks.length) {
            const { minDate, maxDate } = getMinMaxDate(props.graphData);
            const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
            graphDomain = domain;
            graphTicks = ticks;
        }
        return { graphDomain, graphTicks };
    }, [props.graphDomain, props.graphTicks, props.graphData]);

    const formattedGraphData = useMemo(() => {
        const formattedData = [];
        const dateValueMap = {};
        let maxValue = props.usePercent ? 100 : 9;

        props.graphData.forEach((target) => {
            const newTarget = clonedeep(target);
            newTarget.targetData.length > 0 &&
                newTarget.targetData.forEach((targetData) => {
                    const date = dateToDays(targetData.date);
                    if (props.usePercent) {
                        Object.entries(targetData.value.percentage).forEach(([acronym, value]) => {
                            const dateValue = `${date}-${value}`;
                            dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

                            targetData.value.percentage[acronym] = {
                                value,
                                sameDot: dateValueMap[dateValue],
                            };
                        });
                    } else {
                        Object.entries(targetData[valueToUse].count).forEach(([acronym, value]) => {
                            if (value > maxValue) {
                                maxValue = value;
                            }
                            const dateValue = `${date}-${value}`;
                            dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

                            targetData.value.count[acronym] = {
                                value,
                                sameDot: dateValueMap[dateValue],
                            };
                        });
                    }
                });
            formattedData.push(newTarget);
        });

        const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
        formattedData.forEach((target) => {
            target.targetData.length > 0 &&
                target.targetData.forEach((targetData) => {
                    if (props.usePercent) {
                        Object.entries(targetData.value.percentage).forEach(
                            ([acronym, valueObj]) => {
                                targetData.value.percentage[acronym] =
                                    valueObj.value + valueObj.sameDot * dotMultiplier;
                            }
                        );
                    } else {
                        Object.entries(targetData.value.count).forEach(([acronym, valueObj]) => {
                            targetData.value.count[acronym] =
                                valueObj.value + valueObj.sameDot * dotMultiplier;
                        });
                    }
                });
        });

        return formattedData;
    }, [props.graphData, props.usePercent, props.useCumulative]);

    const graphData = useMemo(() => {
        if (!props.showPhase) {
            return formattedGraphData;
        } else {
            return splitGraphDataByPhases(formattedGraphData);
        }
    }, [formattedGraphData, props.showPhase]);

    return props.usePercent ? (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis(
                    'date',
                    dateAxisSetting,
                    graphDomain,
                    graphTicks,
                    'Date',
                    !!props.graphElements.axisLabelsX
                )}
                {yAxis(
                    'percent',
                    percentAxisSetting,
                    'Percentage',
                    !!props.graphElements.axisLabelsY
                )}
                {enableTooltip && !props.isDownloading && (
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                valueFormatter={(value) => `${(value || 0).toFixed(2)}%`}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                )}
                {graphData.map(
                    (target, targetIndex) =>
                        target.targetData.length > 0 &&
                        target.customData.codes.map((item, index) => (
                            <Line
                                {...defaultLineSetting}
                                stroke={getColor(index, 'light')}
                                dataKey='percent'
                                name={generateTargetName(
                                    { ...target, name: '' },
                                    targetIndex,
                                    item.code
                                )}
                                dataId={generateTargetName(
                                    { ...target, name: '' },
                                    targetIndex,
                                    item.id
                                )}
                                key={generateTargetName(
                                    { ...target, name: '' },
                                    targetIndex,
                                    item.id
                                )}
                                isAnimationActive={enableAnimation}
                                data={
                                    !disabledLines[item.id]
                                        ? target.targetData.map((val) => ({
                                              date: dateToDays(val.date),
                                              percent: val.value.percentage.correct || 0,
                                          }))
                                        : []
                                }
                            >
                                {props.graphElements.lineTitles && (
                                    <LabelList
                                        dataKey='percent'
                                        content={(props) =>
                                            renderCustomizedValue(props, 0, -15, () => item.id)
                                        }
                                    />
                                )}
                                {props.graphElements.dataValues && (
                                    <LabelList
                                        dataKey='percent'
                                        content={(props) =>
                                            renderCustomizedValue(props, 3, 15, (e) =>
                                                e && typeof e === 'number' ? `${e.toFixed(2)}%` : ''
                                            )
                                        }
                                    />
                                )}
                            </Line>
                        ))
                )}
            </LineChart>
        </ResponsiveContainer>
    ) : (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis(
                    'date',
                    dateAxisSetting,
                    graphDomain,
                    graphTicks,
                    'Date',
                    !!props.graphElements.axisLabelsX
                )}
                {yAxis('count', defaultAxisSetting, 'Count', !!props.graphElements.axisLabelsY)}
                {enableTooltip && !props.isDownloading && (
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                )}
                {graphData.map(
                    (target) =>
                        target.targetData.length > 0 &&
                        target.customData.codes.map((item, index) => (
                            <Line
                                {...defaultLineSetting}
                                stroke={getColor(index, 'light')}
                                dataKey='count'
                                name={generateTargetName({ ...target, name: '' }, index, item.code)}
                                dataId={generateTargetName({ ...target, name: '' }, index, item.id)}
                                key={generateTargetName({ ...target, name: '' }, index, item.id)}
                                isAnimationActive={enableAnimation}
                                data={
                                    !disabledLines[item.id]
                                        ? target.targetData.map((val) => ({
                                              date: dateToDays(val.date),
                                              count: val.value.count[item.id] || 0,
                                          }))
                                        : []
                                }
                            >
                                {props.graphElements.lineTitles && (
                                    <LabelList
                                        dataKey='count'
                                        content={(props) =>
                                            renderCustomizedValue(props, 0, -15, () => item.id)
                                        }
                                    />
                                )}
                                {props.graphElements.dataValues && (
                                    <LabelList
                                        dataKey='count'
                                        content={(props) =>
                                            renderCustomizedValue(props, 0, 15, (value) =>
                                                value ? Math.round(value) : ''
                                            )
                                        }
                                    />
                                )}
                            </Line>
                        ))
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

IntervalGraph.propTypes = {
    graphData: PropTypes.array.isRequired,
    usePercent: PropTypes.bool,
    graphElements: PropTypes.object.isRequired,
    graphDomain: PropTypes.array.isRequired,
    graphTicks: PropTypes.array.isRequired,
    showPhase: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    useCumulative: PropTypes.bool,
};

export default IntervalGraph;
