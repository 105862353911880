import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    List, ListItem, ListItemText, Drawer, makeStyles,
    Divider, IconButton, Menu, MenuItem, LinearProgress, Tooltip
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import { Resizable } from 're-resizable';
import SimpleBar from 'simplebar-react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import DomainItem from './DomainItem';
import { CanUserFunc } from '../CanUser';
import { setGlobalDialog, setLoading, setSnackbar } from '../../store/general/actions';
import { setPrograms, setDomains } from '../../store/data/actions';
import { access, disabledSidebarRoutes } from '../../constants';
import { reorderDomain } from '../../services/DomainService';
import { reorderProgram, getDomainsProgramsByPatientId } from '../../services/ProgramService';
import * as LibraryService from '../../services/LibraryService';
import CustomOnboarding from '../../assets/onboarding/CustomOnboarding';
import { sidebarSteps } from '../../onboardingSteps';
import { useRouteMatch } from 'react-router-dom';
import ProgramSelectTarget from '../Library/ProgramSelectTarget';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        flexShrink: 0,
    },
    drawerPaper: {
        backgroundColor: '#0D3250',
        border: 'none',
        overflowX: 'hidden',
    },
    list: {
        backgroundColor: '#0D3250',
        color: '#fff',
        position: 'relative',
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 11,
    },
    divider: {
        marginLeft: 29,
        marginRight: 29,
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    },
    domain: {
        textTransform: 'uppercase',
        fontSize: 14,
        fontWeight: 500,
        paddingLeft: 16,
    },
    accelLogo: {
        alignSelf: 'flex-start',
        marginTop: 10,
        marginLeft: 35,
        marginBottom: 18,
    },
    allDomain: {
        marginTop: 6,
        paddingLeft: 24,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItem: {
        paddingLeft: 24,
        paddingRight: 32,
        '&:hover': { backgroundColor: '#00826d' },
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
    },
    collapseButton: {
        position: 'absolute',
        right: 16,
        top: 5,
        zIndex: 5,
        padding: 5,
        color: 'white',
        '&:hover': { backgroundColor: '#00826d' },
    },
    dot: {
        position: 'absolute',
        top: 7,
        right: 7,
        width: 10,
        height: 10,
        backgroundColor: '#0FB99E',
        borderRadius: 50,
    },
    version: {
        margin: 0,
        fontSize: 14,
        color: 'rgba(255, 255, 255, 0.2)',
        marginLeft: 35,
        marginRight: 30,
        position: 'relative',
    },
    progress: {
		width: '100%',
    },
    visibilityIcon: {
        cursor: 'pointer',
        position: 'absolute',
        right: 0,
        bottom: -4,
        '&:hover': {
            color: 'white',
        }
    },
    searchField: {
        width: 'calc(100% - 30px)',
        margin: '10px',
        '& .MuiInputBase-root': {
            color: 'white',
        },
        '& .MuiInputAdornment-root': {
            color: 'white',
        }
    },
    disableSidebar: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(244, 244, 244, 0.2)'
    }
}));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const Sidebar = (props) => {
    const classes = useStyles();
    const {
        filterByItem, selection, handleResize, handleResizeStop,
        width, editDomain, editProgram, deleteDomain, deleteProgram,
        open, setGlobalDialog, patient, loading, setLoading, archiveDomain, archiveProgram,
        setShowArchived, setSnackbar,
    } = props;
    const [collapsedDomains, setCollapsedDomains] = useState({});
    const [collapseAll, setCollapseAll] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorType, setAnchorType] = useState('domain');
    const [typeData, setTypeData] = useState(null);
    const [domains, setDomains] = useState(props.domains);
    const [domainList, setDomainList] = useState([]);
    const [ordering, setOrdering] = useState({ domainOrdering: [] });
    const disableSidebar = useRouteMatch(disabledSidebarRoutes);
    const [saveToLibraryOpen, setSaveToLibraryOpen] = useState(false);

    useEffect(() => {
        setDomains(props.domains);
        setDomainList(props.domains);
    }, [props.domains])

    useEffect(() => {
        const newOrdering = {};
        newOrdering.domainOrdering = [];
        domains.forEach((domain) => {
            newOrdering.domainOrdering.push(domain.id);
            
            newOrdering[domain.id] = []; 
            domain.programs.forEach((program) => {
                newOrdering[domain.id].push(program.id);
            });
        });
        setOrdering(newOrdering);
    }, [domains])

    const filter = item => {
        filterByItem(item);
    }

    const shouldCollapse = () => {
        const shouldCollapse = domains.every(e => !collapsedDomains[e.id]);
        return shouldCollapse;
    }

    const handleAllCollapse = () => {
        const collapse = shouldCollapse();
        setCollapseAll(collapse);

        const collapsedTemp = {};
        domains.forEach((domain) => {
            collapsedTemp[domain.id] = collapse;
        });
        setCollapsedDomains(collapsedTemp);
    }


    const handleOpenAction = (type, data) => event => {
        setAnchorType(type);
        setAnchorEl(event.currentTarget);
        setTypeData(data);
    }

    const handleEditProgram = () => {
        editProgram(typeData);
        setAnchorEl(null);
    }

    const handleEditDomain = () => {
        editDomain(typeData);
        setAnchorEl(null);
    }

    const handleDelete = () => {
        setAnchorEl(null);
        setGlobalDialog(
            `Delete ${anchorType}?`,
            `Deleted ${anchorType} cannot be restored, please proceed with caution.`,
            (answer) => handleAlertClose(answer),
        )
    }

    const handleArchive = () => {
        setAnchorEl(null);
        const isArchive = typeData?.status !== 'archived';
        const isDomain = anchorType === 'domain';
        if (isArchive) {
            setGlobalDialog(
                `Archive ${anchorType}?`,
                `Are you sure you want to archive all targets${isDomain ? ' and programs' : ''} in this ${anchorType}?`,
                (answer) => doArchive(answer),
            )
        } else {
            doArchive(true);
        }
    }

    const handleSaveToLibrary = () => {
        setAnchorEl(null);
        setSaveToLibraryOpen(true);
    }

    const onSaveToLibrary = (targetIds) => {
        setSaveToLibraryOpen(false);
        LibraryService.addProgramToLibrary(typeData.id, targetIds.map(e => +e)).then((res) => {
            setSnackbar('success', 'Program and selected targets are saved to library!');
        })
    }

    const doArchive = (answer) => {
        const unarchive = typeData?.status === 'archived';
        if (answer) {
            if (anchorType === 'program') {
                archiveProgram(typeData, unarchive);
            } else {
                archiveDomain(typeData, unarchive);
            }
        }
    }

    const handleAlertClose = answer => {
        if (answer) {
            if (anchorType === 'program') {
                deleteProgram(typeData);
            } else {
                deleteDomain(typeData);
            }
        }
    }

    const refreshDomainsPrograms = () => {
        setLoading('getDomains');
        getDomainsProgramsByPatientId(patient.id).then((res) => {
            props.setDomains(res.domains);
            setDomainList(res.domains);
            props.setPrograms(res.programs);
            setLoading('getDomains', false);
        });
    }
    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        if (result.type === 'domains') {
            const domainId = ordering.domainOrdering[result.source.index];
            const newPos = domains.find(e => e.id === ordering.domainOrdering[result.destination.index]).order;
            reorderDomain(domainId, newPos).then(() => {
                refreshDomainsPrograms();
            });

            const reorderedDomains = reorder(
                ordering.domainOrdering,
                result.source.index,
                result.destination.index
            );

    
            setOrdering({ ...ordering, domainOrdering: reorderedDomains });
        } else {
            const domainId = result.type.split('-')[1];
            const programId = ordering[domainId][result.source.index];
            const newPos = domains.find(e => e.id === parseInt(domainId, 10)).programs.find(e => e.id === ordering[domainId][result.destination.index]).order;
            reorderProgram(programId, newPos).then(() => {
                refreshDomainsPrograms();
            });

            const reorderedPrograms = reorder(
                ordering[domainId],
                result.source.index,
                result.destination.index,
            );
            
            setOrdering({ ...ordering, [domainId]: reorderedPrograms });
        }
    }

    const { showArchived } = selection;
    const isLoading = loading.getDomains && patient?.id;
    return (
        <Drawer className={classes.drawer} open={open} variant="persistent"
            classes={{ paper: classes.drawerPaper }} anchor="left">
            <Resizable
                minWidth={180}
                maxWidth={540}
                size={{ width: width }}
                enable={{ top: false, right: true, bottom: false, left: true, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }}
                onResize={handleResize}
                onResizeStop={handleResizeStop}
            >
                <SimpleBar autoHide={false} style={{ maxHeight: '100vh' }} id="sidebar">
                    <img alt="Accel Logo" src={require("../../resources/AccelLogo.svg")} className={classes.accelLogo} />
                    <p className={classes.version}>
                        <span id="sidebar-app-version">{`v${process.env.REACT_APP_VERSION}`}</span>
                        <span id="sidebar-archived-toggle" onClick={() => setShowArchived(!showArchived)} className={classes.visibilityIcon}>
                            <Tooltip title={(showArchived ? 'Hide' : 'Show') + ' archived domains and programs'}>
                                {showArchived ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </Tooltip>
                        </span>
                    </p>
                    <Divider classes={{ middle: classes.divider }} variant="middle" />
                    <div id="sidebar-rearrange">
                        <List component="nav" className={classes.list}>
                            {!!domains.length && !isLoading ?
                                <React.Fragment>
                                    <ListItem id="sidebar-all-domains" onClick={() => filter({ all: true })} className={`${classes.allDomain} ${selection.all ? classes.listItemSelected : ''}`} button >
                                        <ListItemText primaryTypographyProps={{ className: classes.domain }} primary="All Domains" />
                                    </ListItem>
                                    <IconButton onClick={handleAllCollapse} className={classes.collapseButton}>
                                        {shouldCollapse() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                        {selection.program && selection.program !== 'all' && collapsedDomains[selection.domain] && <div className={classes.dot} />}
                                    </IconButton>
                                </React.Fragment>
                                :
                                <ListItem
                                    className={`${classes.allDomain} ${classes.listItemSelected}`}
                                >
                                    <ListItemText primaryTypographyProps={{ className: classes.domain }} primary={isLoading ? 'Loading...' : 'No domain'} />
                                </ListItem>
                            }
				            {isLoading && <LinearProgress className={classes.progress} />}
                        </List>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppableDomain" type="domains">
                                {(provided, snapshot) => (
                                    <List
                                        component="nav"
                                        className={classes.list}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {!loading.getDomains && ordering.domainOrdering.map((domainId, index) => {
                                            const domain = domainList.find(e => e.id === domainId);
                                            if (!domain) return null;
                                            return (
                                                <Draggable key={domain.id} draggableId={`domain${domain.id}`} index={index}>
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            className={snapshot.isDragging ? classes.listItemSelected : ''}
                                                        >
                                                            <DomainItem
                                                                collapseAll={collapseAll}
                                                                domain={domain}
                                                                ordering={ordering[domainId]}
                                                                handleOpenAction={handleOpenAction}
                                                                filter={filter}
                                                                collapseDomain={(isCollapsed) => setCollapsedDomains({ ...collapsedDomains, [domain.id]: isCollapsed })}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </List>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </SimpleBar>
            </Resizable>
            {disableSidebar &&
                <div id="disabledSidebar" className={classes.disableSidebar}></div>
            }
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {anchorType === 'program' ?
                    CanUserFunc(access.programs.edit) ?
                        <MenuItem id="sidebar-edit-program" onClick={handleEditProgram}>Edit</MenuItem>
                        : null
                    :
                    CanUserFunc(access.domains.edit) ?
                        <MenuItem id="sidebar-edit-domain" onClick={handleEditDomain}>Edit</MenuItem>
                        : null
                }
                {anchorType === 'program' ?
                    CanUserFunc(access.programs.edit) ?
                        <MenuItem id="sidebar-toggle-program-archive" onClick={handleArchive}>{typeData?.status === 'archived' ? 'Unarchive' : 'Archive'}</MenuItem>
                        : null
                    :
                    CanUserFunc(access.domains.edit) ?
                        <MenuItem id="sidebar-toggle-domain-archive" onClick={handleArchive}>{typeData?.status === 'archived' ? 'Unarchive' : 'Archive'}</MenuItem>
                        : null
                }
                {anchorType === 'program' ?
                    CanUserFunc(access.programs.remove) ?
                        <MenuItem id="sidebar-toggle-program-delete" onClick={handleDelete}>Delete</MenuItem>
                        : null
                    :
                    CanUserFunc(access.domains.remove) ?
                        <MenuItem id="sidebar-toggle-domain-delete" onClick={handleDelete}>Delete</MenuItem>
                        : null
                }
                {anchorType === 'program' ?
                    CanUserFunc(access.programs.edit) ?
                        <MenuItem id="sidebar-save-program-to-library" onClick={handleSaveToLibrary}>Save to library</MenuItem>
                        : null
                    : null
                }
            </Menu>

            <ProgramSelectTarget
                open={saveToLibraryOpen}
                onClose={() => setSaveToLibraryOpen(false)}
                onSubmit={onSaveToLibrary}
                patient={patient}
                program={typeData}
            />

            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={sidebarSteps} updateTrigger={domainList} /> */}
        </Drawer>
    )
}

Sidebar.propTypes = {
    user: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    filterByItem: PropTypes.func.isRequired,
    selection: PropTypes.object.isRequired,
    handleResize: PropTypes.func.isRequired,
    handleResizeStop: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    editDomain: PropTypes.func.isRequired,
    editProgram: PropTypes.func.isRequired,
    deleteDomain: PropTypes.func.isRequired,
    deleteProgram: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setDomains: PropTypes.func.isRequired,
    setPrograms: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setShowArchived: PropTypes.func.isRequired,
    archiveProgram: PropTypes.func.isRequired,
    archiveDomain: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    selection: state.general.selection,
    domains: state.data.domains,
    user: state.auth.user,
    patient: state.patient.patient,
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setGlobalDialog, setDomains, setPrograms, setLoading, setSnackbar })(Sidebar);
