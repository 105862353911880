import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    Button, DialogTitle, DialogContent, Dialog, Select, MenuItem,
    Grid, IconButton, FormHelperText, CircularProgress, TextField
} from '@material-ui/core';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { phases, graphLineTypes, emptyAnnotationPhaseString } from '../../constants';
import CalendarIcon from '../../resources/CalendarIconSVG';
import { updateTarget } from '../../services/TargetService';
import { setLoading } from '../../store/general/actions';
import { masteryLineUnitNames } from '../../constants';
import { masteryLineUnits } from '../../constants';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    input: {
        width: '100%',
    },
    inputSmall: {
        width: '90%',
    },
    leftWrapper: {
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    instructionField: {
        height: 230,
        maxHeight: 230,
    },
    saveBtn: {
        padding: '6px 20px',
    },
    targetBtn: {
        padding: '6px 20px',
    },
    selectMenu: {
        textTransform: 'capitalize',
    },
    additionalDataWrapperOuter: {
        padding: '0px 40px',
    },
    dateInput: {
        color: '#53545E',
    },
    dateAdornment: {
        margin: 0,
        padding: 0,
    },
    clearIcon: {
        color: '#000000',
    },
    phasesToggle: {
        fontSize: 12,
        color: '#0FB99E',
        cursor: 'pointer',
        display: 'block',
        height: 14,
        padding: '2px 0px',
    },
    deleteBtn: {
        padding: '4px',
        color: 'rgba(255, 0, 0, 30%)',
        marginLeft: 5,
        marginTop: 5.5,
        '&:hover': { color: 'red' }
    },
    dialogPaper: {
        width: '80%',
        minHeight: 450,
        maxHeight: '80vh',
    },
    importLibraryWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '50px',

        '& .MuiInputLabel-root': {
            marginBottom: 0
        }
    },
    importBtn: {
        marginLeft: '10px',
        height: '30px',
    },
    formControlLabelText: {
        color: '#000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    },
    inputLabel: {
        margin: 0,
        marginBottom: '16px',
    },
    columnHeader: {
        paddingBottom: '10px',
        fontWeight: 'bold',
    },
    sectionHeader: {
        paddingBottom: '10px',
        paddingTop: '10px',
        fontSize: 18,
        fontWeight: 'bold',
    },
    actionButtons: {
        marginBottom: 20,
        marginTop: 10,
    },
}));

const defaultAnnotations = [{ target: null, phase: 0, showAt: new Date(), lineType: graphLineTypes[0], comment: '' }];
const phaseChoices = [ ...phases ];
phaseChoices.unshift(emptyAnnotationPhaseString);

const EditPhases = (props) => {
    const classes = useStyles();
    const { onClose, onCloseSave, open, targets, loading, setLoading } = props;
    const [annotations, setAnnotations] = useState([ ...defaultAnnotations ]);
    const [phaseLineError, setPhaseLineError] = useState(false);
    const [masteryLineError, setMasteryLineError] = useState(false);

    const canUpdate = CanUserFunc(access.targets.edit);

    useEffect(() => {
        if (open && targets.length) {
            const annotations = [];
            targets.forEach((baseTarget) => {
                if (!baseTarget.annotations) {
                    baseTarget.annotations = [];
                }
                const baseAnnotations = [ ...baseTarget.annotations ];
                baseAnnotations.forEach(annotation => {
                    if (annotation.phase === '') {
                        annotation.phase = emptyAnnotationPhaseString;
                    }
                    annotation.target = baseTarget;
                    annotation.initialTargetId = baseTarget.id;
                    return annotations.push({ ...annotation });
                });
                setPhaseLineError(false);
                setMasteryLineError(false);
            });
            if (!annotations.filter(e => e.type !== 'masteryLine').length) {
                annotations.push({ target: targets.length === 1 ? targets[0] : 0, initialTargetId: targets[0].id, phase: targets[0].phase, showAt: new Date(), lineType: graphLineTypes[0], comment: '' });
            }
            if (!annotations.filter(e => e.type === 'masteryLine').length) {
                annotations.push({ target: targets.length === 1 ? targets[0] : 0, initialTargetId: targets[0].id, phase: targets[0].phase, showAt: new Date(), lineType: graphLineTypes[0], comment: '', type: 'masteryLine' });
            }
            setAnnotations(annotations);
        }
    }, [open, targets])

    const handleCancel = () => {
        onClose();
    };

    const handleAddPhase = (isMasteryLine = false) => {
        annotations.push({ target: targets.length === 1 ? targets[0] : 0, phase: 0, showAt: new Date(), lineType: graphLineTypes[0], comment: '' , type: isMasteryLine ? 'masteryLine' : null});
        setAnnotations(annotations.slice());
    }

    const handleRemovePhase = index => event => {
        annotations[index].delete = true;
        setAnnotations(annotations.slice());
    }

    const handleChangePhaseDate = index => value => {
        annotations[index].showAt = value;
        setAnnotations(annotations.slice());
    }

    const handleChangePhase = index => event => {
        annotations[index].phase = event.target.value;
        setAnnotations(annotations.slice());
    }

    const handleChangeLineFormat = index => event => {
        annotations[index].lineType = event.target.value;
        setAnnotations(annotations.slice());
    }

    const handleChangeAnnotation = index => event => {
        annotations[index].comment = event.target.value;
        setAnnotations(annotations.slice());
    }

    const handleChangeTarget = index => event => {
        annotations[index].target = targets.find(e => e.id === event.target.value);
        setAnnotations(annotations.slice());
    }

    const handleChangeYValue = index => event => {
        if (!annotations[index].data) {
            annotations[index].data = {};
        }
        annotations[index].data.yValue = event.target.value;
        setAnnotations(annotations.slice());
    }

    const handleChangeUnit = index => event => {
        if (!annotations[index].data) {
            annotations[index].data = {};
        }
        annotations[index].data.unit = event.target.value;
        setAnnotations(annotations.slice());
    }

    const checkPhaseLineError = () => {
        const errors = [];
        if (annotations.filter(e => e.type !== 'masteryLine').some(e => !e.target && !e.delete)) {
            errors.push('Please make sure all targets are chosen');
        } 
        if (annotations.filter(e => e.type !== 'masteryLine').some(e => !e.phase && !e.delete)) {
            errors.push('Please make sure all phases are chosen');
        } 
        return errors;
    }

    const checkMasteryLineError = () => {
        const errors = [];
        if (annotations.filter(e => e.type === 'masteryLine').some(e => !e.target && !e.delete)) {
            errors.push('Please make sure all targets are chosen');
        } 
        if (annotations.filter(e => e.type === 'masteryLine').some(e => !e.data?.unit && !e.delete)) {
            errors.push('Please make sure all unites are chosen');
        }
        if (annotations.filter(e => e.type === 'masteryLine').some(e => !e.data?.yValue && !e.delete)) {
            errors.push('Please make sure all Y-Values are chosen');
        }
        const invalidPercentages = annotations.filter(e => e.type === 'masteryLine')
            .some(e => e.data?.yValue && e.data?.unit === masteryLineUnitNames.percentage &&
                !e.delete && (isNaN(e.data?.yValue) || e.data?.yValue < 0 || e.data?.yValue > 100));
        if (invalidPercentages) {
            errors.push('Please make sure Y-Values are in between 0 and 100 when the unit is "Percentage"');
        }
        const invalidSeconds = annotations.filter(e => e.type === 'masteryLine')
            .some(e => e.data?.yValue && e.data?.unit === masteryLineUnitNames.seconds &&
                !e.delete && (isNaN(e.data?.yValue) || e.data?.yValue < 0));
        if (invalidSeconds) {
            errors.push('Please make sure Y-Values are non negative numbers when the unit is "Seconds"');
        }
        const invalidMinutes = annotations.filter(e => e.type === 'masteryLine')
            .some(e => e.data?.yValue && e.data?.unit === masteryLineUnitNames.minutes &&
                !e.delete && (isNaN(e.data?.yValue) || e.data?.yValue < 0));
        if (invalidMinutes) {
            errors.push('Please make sure Y-Values are non negative numbers when the unit is "Minutes"');
        }
        const invalidNumberPerMinute = annotations.filter(e => e.type === 'masteryLine')
            .some(e => e.data?.yValue && e.data?.unit === masteryLineUnitNames.numberPerMinute &&
                !e.delete && (isNaN(e.data?.yValue) || e.data?.yValue < 0));
        if (invalidNumberPerMinute) {
            errors.push('Please make sure Y-Values are non negative numbers when the unit is "Number per minute"');
        }
        const invalidNumberPerHour = annotations.filter(e => e.type === 'masteryLine')
            .some(e => e.data?.yValue && e.data?.unit === masteryLineUnitNames.numberPerHour &&
                !e.delete && (isNaN(e.data?.yValue) || e.data?.yValue < 0));
        if (invalidNumberPerHour) {
            errors.push('Please make sure Y-Values are non negative numbers when the unit is "Number per hour"');
        }
        return errors;
    }

    const flagDeletedAndNewAnnotations = (filteredAnnotations) => {
        const results = [];
        filteredAnnotations.forEach((annotation) => {
            if (annotation.initialTargetId && annotation.target?.id !== annotation.initialTargetId) {
                results.push({ ...annotation, id: null });
                results.push({ ...annotation, delete: true });
            } else {
                results.push({ ...annotation });
            }
        });
        return results;
    }

    const handleUpdate = (isMasteryLine = false) => {
        if (!isMasteryLine && checkPhaseLineError().length) {
            setPhaseLineError(true);
            return;
        }
        if (isMasteryLine && checkMasteryLineError().length) {
            setMasteryLineError(true);
            return;
        }
        const annotationsToUpdate = flagDeletedAndNewAnnotations(
            isMasteryLine
            ? annotations.filter(e => e.type === 'masteryLine') 
            : annotations.filter(e => e.type !== 'masteryLine')
        );
        let targetIds = annotationsToUpdate.map(e => e.target?.id).filter((v, i, a) => a.indexOf(v) === i);
        const promises = [];
        targetIds.forEach((targetId) => {
            let data = {
                id: targetId,
                annotations: annotationsToUpdate.filter(e => e.target?.id === targetId).map(annotation => {
                    annotation = { ...annotation };
                    delete annotation.target;
                    delete annotation.initialTargetId;
                    if (annotation.phase === emptyAnnotationPhaseString) {
                        annotation.phase = '';
                    }
                    return annotation;
                }).filter(annotation => (annotation.id || !annotation.delete))
            };
            promises.push(updateTarget(targetId, data));
        });
        if (promises.length) {
            setLoading('phaseForm');
            Promise.all(promises).then(() => {
                setLoading('phaseForm', false);
                onCloseSave();
            });
        }
    };

    const renderLine = (phase, index) => {
        const error = (phase.type === "masteryLine") ? masteryLineError : phaseLineError;
        return (
            <Grid key={`phase-${index}`} item container xs={12} alignItems="center" style={{ marginBottom: 5 , display: phase.delete ? 'none':'flex' }}>
                <Grid item xs={2} style={{paddingRight: 20}}>
                    <Select
                        variant="outlined"
                        IconComponent={KeyboardArrowDownIcon}
                        className={`${classes.input} ${classes.selectMenu}`}
                        error={error && !phase.target}
                        onChange={handleChangeTarget(index)}
                        value={phase.target?.id || 0}
                        disabled={!canUpdate}
                    >
                        <MenuItem disabled value={0}>Choose</MenuItem>
                        {targets.map(target => (
                            <MenuItem key={target.id} classes={{ root: classes.selectMenu }} value={target.id}>{target.name}</MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item xs={2} style={{paddingRight: phase.type === "masteryLine" ? 20 : 0}}>
                    {phase.type === "masteryLine" ? <TextField
                    variant="outlined"
                    type="number"
                    value={phase.data?.yValue}
                    placeholder={'Y-Value'}
                    onChange={handleChangeYValue(index)}
                    className={classes.input}
                    InputProps={{ inputProps: { min: 0 } }}
                    disabled={!canUpdate}
                /> : <KeyboardDatePicker
                    autoOk
                    format="MM/dd/yyyy"
                    className={classes.inputSmall}
                    inputVariant="outlined"
                    value={phase.showAt}
                    inputProps={{ className: classes.dateInput }}
                    InputProps={{
                        classes: {
                            adornedEnd: classes.dateAdornment,
                            adornedStart: classes.dateAdornment,
                        },
                    }}
                    keyboardIcon={CalendarIcon}
                    InputAdornmentProps={{ position: "start", className: classes.dateAdornment }}
                    onChange={handleChangePhaseDate(index)}
                    disabled={!canUpdate}
                />}
                </Grid>
                <Grid item xs={2} style={{paddingRight: 20}}>
                {phase.type === "masteryLine" ? <Select
                        variant="outlined"
                        IconComponent={KeyboardArrowDownIcon}
                        className={`${classes.input} ${classes.selectMenu}`}
                        error={error && !phase.data?.unit}
                        onChange={handleChangeUnit(index)}
                        value={phase.data?.unit || 0}
                        disabled={!canUpdate}
                    >
                        <MenuItem disabled value={0}>Unit Unselected</MenuItem>
                        {masteryLineUnits[phase.target?.dataType]?.map(line => (
                            <MenuItem key={line} classes={{ root: classes.selectMenu }} value={line}>{line}</MenuItem>
                        ))}
                    </Select> : <Select
                        variant="outlined"
                        IconComponent={KeyboardArrowDownIcon}
                        className={`${classes.input} ${classes.selectMenu}`}
                        error={error && !phase.phase}
                        onChange={handleChangePhase(index)}
                        value={phase.phase || 0}
                        disabled={!canUpdate}
                    >
                        <MenuItem disabled value={0}>Choose</MenuItem>
                        {phaseChoices.map(phase => (
                            <MenuItem key={phase} classes={{ root: classes.selectMenu }} value={phase}>{phase}</MenuItem>
                        ))}
                    </Select>}
                </Grid>
                <Grid container xs={6}>
                    <Grid item xs={3} style={{paddingRight: 20}}>
                        <Select
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            className={`${classes.input} ${classes.selectMenu}`}
                            error={error && !phase.lineType}
                            onChange={handleChangeLineFormat(index)}
                            value={phase.lineType}
                            disabled={!canUpdate}
                        >
                            <MenuItem disabled value={0}>Choose</MenuItem>
                            {graphLineTypes.map(type => (
                                <MenuItem key={type} classes={{ root: classes.selectMenu }} value={type}>{type}</MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            variant="outlined"
                            value={phase.comment}
                            inputProps={{ maxLength: 60 }}
                            onChange={handleChangeAnnotation(index)}
                            className={classes.input}
                            disabled={!canUpdate}
                        />
                    </Grid>
                    {canUpdate && <Grid item xs={1}>
                        <IconButton onClick={handleRemovePhase(index)} className={classes.deleteBtn} tabIndex={-1}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>}
                </Grid>
            </Grid>
        );
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="lg" open={open} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        Edit Lines and Annotations
					</Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel} disabled={loading.phaseForm}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {loading.phaseForm ?
                    <Grid container justify="center" alignItems="center" style={{ width: 800, height: 250 }}>
                        <CircularProgress size={150} />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={12} className={classes.leftWrapper}>
                            {
                                /*
                                    When showing non deleted annotations on the modal, we can't filter 
                                    annotaitons by 'delete' attribute with annotations.filter(e => !e.delete) 
                                    because we are using the index to identify the annotations uniquily. 
                                    If we do so, the changes done to a specific annotation may get applied
                                    to another annotation, as deleting annotations changes the indexes.

                                    Using annotation id to uniquely identify annotations isn't also an option 
                                    as the newly added annotations don't have an id.

                                    As a solution we are using CSS to hide the deleted annotations.
                                */
                            }
                            <Grid container>
                                <Grid className={classes.sectionHeader} item container xs={12} alignItems="center">
                                    Phase / Condition Lines
                                </Grid>
                                <Grid item container xs={12} alignItems="center">
                                    <Grid className={classes.columnHeader} item xs={2}>Target</Grid>
                                    <Grid className={classes.columnHeader} item xs={2}>Date</Grid>
                                    <Grid className={classes.columnHeader} item xs={2}>Phase</Grid>
                                    <Grid container xs={6}>
                                        <Grid className={classes.columnHeader} item xs={3}>Line</Grid>
                                        <Grid className={classes.columnHeader} item xs={9}>Annotation</Grid>
                                    </Grid>
                                </Grid>
                                {annotations.map((phase, index) => (
                                    phase.type === 'masteryLine' ? null : renderLine(phase, index)
                                ))}
                                <FormHelperText className={classes.helperText} error={phaseLineError && checkPhaseLineError().length}>
                                    {phaseLineError && checkPhaseLineError().length ? checkPhaseLineError().map(error => <span>{error}<br/></span>) : ''}
                                </FormHelperText>
                                {canUpdate && <Grid className={classes.actionButtons} container>
                                    <Grid item xs={6}>
                                        <Button disabled={loading.phaseForm} onClick={() => handleAddPhase(false)}>Add More</Button>
                                    </Grid>
                                    <Grid item xs={6} container justify="flex-end">
                                        <Button className={classes.targetBtn} disabled={loading.phaseForm} onClick={() => handleUpdate(false)}>Update</Button>
                                    </Grid>
                                </Grid>}
                            </Grid>
                            <Grid container>
                                <Grid className={classes.sectionHeader} item container xs={12} alignItems="center">
                                    Base / Mastery Lines
                                </Grid>
                                <Grid item container xs={12} alignItems="center">
                                    <Grid className={classes.columnHeader} item xs={2}>Target</Grid>
                                    <Grid className={classes.columnHeader} item xs={2}>Y-Value</Grid>
                                    <Grid className={classes.columnHeader} item xs={2}>Unit</Grid>
                                    <Grid container xs={6}>
                                        <Grid className={classes.columnHeader} item xs={3}>Line</Grid>
                                        <Grid className={classes.columnHeader} item xs={9}>Annotation</Grid>
                                    </Grid>
                                </Grid>
                                {annotations.map((phase, index) => (
                                    phase.type !== 'masteryLine' ? null : renderLine(phase, index)
                                ))}
                                <FormHelperText className={classes.helperText} error={masteryLineError && checkMasteryLineError().length}>
                                    {masteryLineError && checkMasteryLineError().length ? checkMasteryLineError().map(error => <span>{error}<br/></span>) : ''}
                                </FormHelperText>
                                {canUpdate && <Grid className={classes.actionButtons} container>
                                    <Grid item xs={6}>
                                        <Button disabled={loading.phaseForm} onClick={() => handleAddPhase(true)}>Add More</Button>
                                    </Grid>
                                    <Grid item xs={6} container justify="flex-end">
                                        <Button className={classes.targetBtn} disabled={loading.phaseForm} onClick={() => handleUpdate(true)}>Update</Button>
                                    </Grid>
                                </Grid>}
                            </Grid>
                        </Grid>
                    </Grid>
                }
            </DialogContent>
        </Dialog>
    )
}

EditPhases.propTypes = {
    onClose: PropTypes.func.isRequired,
    onCloseSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    targets: PropTypes.array,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    programs: state.data.programs,
    selection: state.general.selection,
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading })(EditPhases)
