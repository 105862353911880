import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    DialogActions, Grid, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import LibraryTreeView from './LibraryTreeView';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: '500px',
        width: '500px'
    },
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    icon: {
        margin: "0 10px",
    },
    treeView: {
        margin: '5px 0'
    }
}));
  
const LibrarySelectTarget = (props) => {
    const classes = useStyles();
    const { open, onClose, onSubmit, programLibraries } = props;

    const [selectedProgramLibraries, setSelectedProgramLibraries] = useState([]);
    const [selectedTargetLibraries, setSelectedTargetLibraries] = useState({});

    const setSelected = (programLibs, targetLibs) => {
        setSelectedProgramLibraries(programLibs);
        setSelectedTargetLibraries(targetLibs);
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        onSubmit(selectedProgramLibraries, selectedTargetLibraries);
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
			classes={{
				paper: classes.dialogPaper
			}}
            open={open}
        >
            <DialogTitle>
                <Grid container>
                    <Grid id="library-page-share-modal-title" item xs={8}>
                        Select targets to share
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="library-page-share-modal-close" className={classes.closeIconBtn} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                {open && <LibraryTreeView programLibraries={programLibraries} setSelected={setSelected} />}
            </DialogContent>
            <DialogActions>
                <Button id="library-page-share-modal-close-button" onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button id="library-page-share-modal-share-button" disabled={!selectedProgramLibraries.length} autoFocus onClick={handleSubmit} color="primary">
                    Share
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LibrarySelectTarget.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    programLibraries: PropTypes.array.isRequired,
}

export default LibrarySelectTarget;
