import React, { useState } from 'react';
import { TextField, Box, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import Wrapper from './Wrapper';
import PhaseColoredButton from './PhaseColoredButton';
import { setSnackbar } from '../../../../store/general/actions';


const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '4px',
    marginLeft: '10px',
  },
}));

function Quantity(props) {
  const classes = useStyles();

  const [quantity, setQuantity] = useState('');
  const [textFieldError, setTextFieldError] = useState(false);

  const handleTextInput = (e) => {
    const text = e.target.value;
    setTextFieldError(!/^(0$|-?[1-9]\d*(\.\d*[1-9]$)?|-?0\.\d*[1-9])$/.test(text));
    setQuantity(text);
  };

  const handleQuantity = () => {
    if (!quantity) {
      props.setSnackbar('error', 'Quantity is required');
      return;
    }
    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      { quantity: quantity },
      quantity
    );
    setQuantity('');
    setTextFieldError(false);
  };

  return (
    <Wrapper {...props}>
      <Box display="flex">
        <TextField
          error={textFieldError}
          variant="outlined"
          placeholder="Quantity"
          fullWidth
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={handleTextInput}
          value={quantity}
        />
        <PhaseColoredButton
          className={classes.button}
          disabled={textFieldError}
          onClick={handleQuantity}
          phase={props.target?.phase}
        >
          +
        </PhaseColoredButton>
      </Box>
    </Wrapper>
  );
}

Text.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
};

export default connect(null, { setSnackbar })(Quantity)

