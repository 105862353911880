import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Draggable from 'react-draggable';
import { Button, Modal, InputLabel, TextField, Grid, IconButton, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AttachFile from '@material-ui/icons/AttachFile';
import { DropzoneArea } from 'material-ui-dropzone';

import { closeZendeskDialog, setLoading, setSnackbar } from '../../store/general/actions';
import emailIsValid from '../../utils/validateEmail';
import { createTicket } from '../../services/ZendeskService';

const useStyles = makeStyles(theme => ({
  closeIconBtn: {
    padding: '0px 4px',
    color: '#FFFFFF',
  },
  input: {
    width: '100%',
    marginBottom: 10,
  },
  multilineInputLarge: { minHeight: '20vh' },
  modal: {
    margin: 'auto 10px 10px auto',
    width: 350,
    maxWidth: 'calc(100vw - 20px)',
    height: '90vh',
    maxHeight: window.innerHeight,
    background: "white",
    boxShadow: '0 11px 15px -7px rgba(0, 0, 0, 0.4)',
    borderRadius: 8,
  },
  header: {
    backgroundColor: 'rgb(46, 148, 228)',
    padding: 10,
    lineHeight: '30px',
    borderRadius: '8px 8px 0 0',
  },
  content: {
    padding: 20,
    height: 'calc(90vh - 160px)',
    // minHeight: 340,
    overflowX: 'hidden',
    boxShadow: 'inset 0px -20px 20px -25px rgba(0,0,0,0.2)',
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: 'bold',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  button: {
    float: 'right',
    backgroundColor: 'rgb(46, 148, 228)',
    margin: '15px 20px 0 0',
    borderRadius: 5
  },
  labelSmall: { maxWidth: 100 },
  dropzone: { marginBottom: 20 },
  dropzoneText: { fontSize: 15, color: 'rgb(135, 146, 157)' },
  dropzoneContent: { padding: '0 70px', marginTop: -12 },
  dropzoneContainer: { minHeight: 50 },
  circularProgress: { height: 'calc(70vh - 70px)', minHeight: 430 }
}));

const ZendeskDialog = (props) => {
  const classes = useStyles();
  const { open } = props?.zendeskDialog;
  const [error, setError] = useState(false);
  const defaultForm = {
    message: '',
    email: '',
    name: '',
    clientId: '',
    files: []
  };
  const [zendeskForm, setZendeskForm] = useState(defaultForm);
  const { closeZendeskDialog, setLoading, loading, setSnackbar, user, patient } = props;

  const handleChange = field => event => {
    if (field === 'files') {
      return setZendeskForm({ ...zendeskForm, [field]: event });
    }

    let value = event.target?.value;
    setZendeskForm({ ...zendeskForm, [field]: value });
  }

  useEffect(() => {
    if (open) {
      setZendeskForm({
        ...defaultForm,
        email: user?.email || '',
        name: user?.username || '',
        clientId: patient?.clientId || '',
      });
    }
  }, [open]);

  const handleClose = () => {
    setError(false);
    setZendeskForm(defaultForm);
    closeZendeskDialog();
  }

  const handleSave = () => {
    const email = zendeskForm.email.trim();
    let message = zendeskForm.message.trim();
    const name = zendeskForm.name.trim();
    const clientId = zendeskForm.clientId.trim();
    if (!name || !message || !email || !emailIsValid(email)) {
      setError(true);
      return;
    }
    message += clientId ? `; Client Id: ${clientId}` : '';

    setLoading('sendingRequest');

    createTicket({ email, message, name, files: zendeskForm.files })
      .then(() => {
        setLoading('sendingRequest', false);
        setSnackbar('success', `Your message was successfully sent!`);
        setZendeskForm(defaultForm);
        closeZendeskDialog();
      })
      .catch(e => {
        setLoading('sendingRequest', false);
        setSnackbar('error', `There was an issue with sending your message.`);
      }).finally(() => {
        setError(false);
      })
  }

  const dropzoneContent = () => {
    return (
      <Grid container className={classes.dropzoneContent}>
        <Grid item xs={2}><AttachFile style={{ color: 'rgb(135, 146, 157)' }} /></Grid>
        <Grid item xs={10}><span classes={classes.dropzoneText}>Add up to 5 files</span></Grid>
      </Grid>
    );
  }

  return (
    <Draggable cancel="input, textarea">
      <Modal
        disableEnforceFocus={true}
        disableScrollLock={true}
        hideBackdrop={true}
        open={open}
        aria-labelledby="zendesk-dialog-title"
        aria-describedby="zendesk-dialog-description"
        className={classes.modal}
      >
        <div>
          <Grid container className={classes.header}>
            <Grid item xs={11} className={classes.title}>
              Contact Us
            </Grid>
            <Grid item xs={1} container justify="flex-end">
              <IconButton className={classes.closeIconBtn} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          {loading.sendingRequest ? (
            <Grid container justify="center" alignItems="center" className={classes.circularProgress}>
              <CircularProgress size={150} />
            </Grid>
          ) : (
            <>
              <div className={classes.content}>
                <Grid container>
                  <Grid item xs={12}>
                    <InputLabel required>Your name or username</InputLabel>
                    <TextField
                      variant="outlined"
                      value={zendeskForm.name}
                      onChange={handleChange('name')}
                      error={error && !zendeskForm.name.trim()}
                      helperText={error && !zendeskForm.name.trim() && "Name can't be empty"}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel required>Email address</InputLabel>
                    <TextField
                      variant="outlined"
                      value={zendeskForm.email}
                      onChange={handleChange('email')}
                      error={error && (!zendeskForm.email.trim() || !emailIsValid(zendeskForm.email))}
                      helperText={error ?
                        ((!zendeskForm.email.trim() && "Email can't be empty")
                          || (!emailIsValid(zendeskForm.email) ? 'Email is invalid' : ''))
                        : ''
                      }
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Client ID</InputLabel>
                    <TextField
                      variant="outlined"
                      value={zendeskForm.clientId}
                      onChange={handleChange('clientId')}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel required>How can we help you?</InputLabel>
                    <TextField
                      multiline
                      InputProps={{
                        classes: {
                          root: classes.multilineInputLarge,
                        },
                      }}
                      variant="outlined"
                      value={zendeskForm.message}
                      onChange={handleChange('message')}
                      error={error && !zendeskForm.message.trim()}
                      helperText={error && !zendeskForm.message.trim() && "Message can't be empty"}
                      className={classes.input}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.dropzone}>
                    <InputLabel>Attachments</InputLabel>
                    <DropzoneArea
                      Icon={dropzoneContent}
                      showPreviews={true}
                      dropzoneParagraphClass={classes.dropzoneArea}
                      showPreviewsInDropzone={false}
                      dropzoneText=''
                      dropzoneClass={classes.dropzoneContainer}
                      onChange={handleChange('files')}
                      filesLimit={5}
                      useChipsForPreview
                      previewChipProps={{
                        classes: { labelSmall: classes.labelSmall },
                        size: 'small'
                      }}
                      showAlerts={['error']}
                      alertSnackbarProps={{ anchorOrigin: { vertical: 'bottom', horizontal: 'right' } }}

                    />
                  </Grid>
                </Grid>
              </div>
              <div className={classes.buttonContainer}>
                <Button onClick={handleSave} className={classes.button}>
                  Send
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </Draggable>
  );
}

ZendeskDialog.propTypes = {
  zendeskDialog: PropTypes.object.isRequired,
  closeZendeskDialog: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  zendeskDialog: state.general.zendeskDialog,
  loading: state.general.loading,
  user: state.auth.user,
  patient: state.patient.patient,
})

export default connect(mapStateToProps, { closeZendeskDialog, setLoading, setSnackbar })(ZendeskDialog)
