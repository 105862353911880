import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NoteIcon from '@material-ui/icons/Notes';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	chart: {
		marginTop: 100,
		width: '30%',
		display: 'block',
		padding: 10,
		border: '5px solid #0FB99E',
		borderRadius: 10,
		fontSize: 250,
	},
	title: {
		fontWeight: 'bold',
	},
	subTitle: {
		margin: 0,
		color: '#5A5A5A',
	},
}));

const NoMemo = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.root}>
			<Grid item xs={12} container justify="center">
				<NoteIcon className={classes.chart} />
			</Grid>
			<Grid item xs={12} container justify="center">
				<p className={classes.title}>NO PATIENT SELECTED</p>
			</Grid>
			<Grid item xs={12} container justify="center">
				<p className={classes.subTitle}>Please select a patient first</p>
			</Grid>
		</Grid>
	)
}

export default NoMemo;
