import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Popover, ListItem, List, TextField } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
    menuItem: {
        paddingLeft: 32,
        paddingRight: 32,
    },
    dispalyValue: {
        fontSize: 14,
        margin: 0,
        backgroundColor: 'white',
        padding: '10px 40px 14px 15px',
        borderRadius: 4,
        border: '1px solid rgba(0, 0, 0, 0.26)',
        position: 'relative',
        cursor: 'pointer',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    invalidField: {
        borderColor: '#f44336'
    },
    validationMessage: {
        fontSize: 12,
        fontWeight: 400,
        lineHeight: 1.66,
        margin: 0,
        color: '#f44336'
    },
    arrowDownIcon: {
        right: 2,
        position: 'absolute',
        fontSize: 36,
        color: '#0FB99E',
        top: 'calc(50% - 18px)',
    },
    list: {
        maxHeight: 500,
    },
    searchBar: {
        width: 'calc(100% - 60px)',
        marginLeft: 30,
        marginTop: 10,
    },
    popoverPaper: {
        minWidth: 350,
        maxWidth: 350,
    },
}));

const SearchableSelect = (props) => {
    const classes = useStyles();
    const { entities, hasAll, handleChange, selectedEntity, displayEntity, emptyMessage, error } = props;
    const [selectedEntityId, setSelectedEntityId] = useState(undefined);
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (selectedEntity?.id) {
            setSelectedEntityId(selectedEntity.id);
        }
    }, [selectedEntity])

    const handleEntitySelection = (value) => event => {
        handleChange(value);
        setAnchorEl(null);
    }

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setSearchTerm('');
    }

    const entity = selectedEntity?.id || selectedEntity === 'all' ? selectedEntity : entities.find(e => e.id === selectedEntity);
    
    return (
        <React.Fragment>
            <p
                className={`${classes.dispalyValue} ${!!error ? classes.invalidField : ''}`}
                onClick={handleOpen}
                id="entity-picker"
            >
                {entities.length ? displayEntity(entity) : emptyMessage}
                <KeyboardArrowDownIcon className={classes.arrowDownIcon} />
            </p>
            {!!error && <p className={classes.validationMessage}>
                {error}
            </p>}
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{  vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                classes={{ paper: classes.popoverPaper }}
            >
                <TextField
                    placeholder="Search..."
                    className={classes.searchBar}
                    value={searchTerm}
                    label="Search"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    autoFocus
                />
                <List className={classes.list}>
                    {hasAll && <ListItem
                        className={classes.menuItem}
                        key={0}
                        value={"all"}
                        button
                        selected={selectedEntity === 'all'}
                        onClick={handleEntitySelection('all')}
                    >
                        All
                    </ListItem>}
                    {entities?.length && entities
                    .filter(entity => displayEntity(entity).toLowerCase().includes(searchTerm.toLowerCase()))
                    .map(entity => (
                        <ListItem
                            className={classes.menuItem}
                            key={entity.id}
                            value={entity.id}
                            button
                            selected={entity.id === selectedEntityId}
                            onClick={handleEntitySelection(entity.id)}
                        >
                            {displayEntity(entity)}
                        </ListItem>
                    ))}
                </List>
            </Popover>
        </React.Fragment>
    )
}

SearchableSelect.propTypes = {
    entities: PropTypes.array.isRequired,
    handleChange: PropTypes.func.isRequired,
    displayEntity: PropTypes.func.isRequired,
    selectedEntity: PropTypes.any.isRequired,
    hasAll: PropTypes.bool,
    emptyMessage: PropTypes.string,
    error: PropTypes.string
};

export default SearchableSelect;
