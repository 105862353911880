import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import format from 'date-fns/format';
import { connect } from 'react-redux';

import { updateTargetData } from '../../../services/TargetService';
import { setLoading, setGlobalDialog } from '../../../store/general/actions';
import LoadingCheck from '../../../assets/progress/LoadingCheck';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';

const useStyles = makeStyles((theme) => ({
    closeIconBtn: {
        padding: '4px',
        color: '#0FB99E',
    },
    dialogPaper: {
        width: 900,
        minWidth: 900,
        minHeight: 600,
    },
    dateCell: {
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        width: 150,
        maxWidth: 150,
        padding: '13px 8px',
    },
    noteCell: {
        padding: 8,
        whiteSpace: 'pre-line',
    },
    actionCell: {
        padding: '13px 8px',
        width: 100,
        maxWidth: 100,
    },
    iconButton: {
        padding: '4px',
        marginLeft: 4,
    },
    submitButton: {
        color: '#0FB99E',
    },
    cancelButton: {
        color: '#d32f2f',
    },
    input: {
        padding: '5px',
        lineHeight: '16px',
    },
    notes: {
        padding: '5px',
        whiteSpace: 'pre-line',
        display: 'inline-block',
        lineHeight: '16px',
    },
}));

const NotesDialog = (props) => {
    const classes = useStyles();
    const { open, handleClose, target, loading, setLoading, fetchData, setGlobalDialog } = props;
    const [notes, setNotes] = useState(null);
    const [isEditId, setIsEditId] = useState(0);
    const [updatedNote, setUpdatedNote] = useState('');

    useEffect(() => {
        setNotes(props.notes);
    }, [props.notes]);

    const handleChangeNote = (event) => {
        setUpdatedNote(event.target.value);
    };

    const submitUpdate = () => {
        let note = updatedNote.trim();
        if (!!note) {
            setLoading(`updateDataNote`);
            updateLocalNote(isEditId, note);
            updateTargetData(isEditId, null, note).then(() => {
                setLoading(`updateDataNote`, false);
                fetchData();
                handleCancelEdit();
            });
        } else {
            handleCancelEdit();
        }
    };

    const updateLocalNote = (id, updatedNote) => {
        const newNotes = notes;
        const updatedNoteObj = newNotes.find((e) => e.targetDataId === id);
        if (updatedNoteObj) {
            updatedNoteObj.note = updatedNote;
        }
        setNotes(newNotes.slice());
    };

    const deleteLocalNote = (id) => {
        const newNotes = notes;
        const noteIndex = newNotes.findIndex((e) => e.targetDataId === id);
        newNotes.splice(noteIndex, 1);
        setNotes(newNotes.slice());
    };

    const handleAskDelete = (id) => () => {
        setIsEditId(id);
        setLoading('updateDataNote');
        setGlobalDialog(
            'Delete note?',
            'Deleted note cannot be restored, please proceed with caution.',
            (answer) => handleDelete(answer, id)
        );
    };

    const handleDelete = (answer, id) => {
        if (answer) {
            deleteLocalNote(id);
            updateTargetData(id, null, '').then(() => {
                setLoading('updateDataNote', false);
                fetchData();
                handleCancelEdit();
            });
        } else {
            setLoading('updateDataNote', false);
            handleCancelEdit();
        }
    };

    const handleStartEdit = (targetDataId) => () => {
        setIsEditId(targetDataId);
        setUpdatedNote('');
    };

    const handleCancelEdit = () => {
        setIsEditId(0);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item xs={6}>
                        {target?.name}
                    </Grid>
                    <Grid item xs={6} container justify='flex-end'>
                        <IconButton className={classes.closeIconBtn} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableBody>
                        {notes &&
                            notes.map((note) => (
                                <TableRow key={note.targetDataId}>
                                    <TableCell align='left' className={classes.dateCell}>
                                        {format(new Date(note.date), 'dd MMM yyyy HH:mm')}
                                    </TableCell>
                                    <TableCell align='left' className={classes.noteCell}>
                                        {isEditId === note.targetDataId ? (
                                            <TextField
                                                variant='outlined'
                                                defaultValue={note.note}
                                                onChange={handleChangeNote}
                                                fullWidth
                                                multiline
                                                InputProps={{ classes: { root: classes.input } }}
                                            />
                                        ) : (
                                            <span className={classes.notes}>{note.note}</span>
                                        )}
                                    </TableCell>
                                    {isEditId === note.targetDataId ? (
                                        <TableCell align='right' className={classes.actionCell}>
                                            {loading.updateDataNote || loading.getTargetData ? (
                                                <LoadingCheck show size={24} />
                                            ) : (
                                                <React.Fragment>
                                                    <IconButton
                                                        className={`${classes.iconButton} ${classes.cancelButton}`}
                                                        onClick={handleCancelEdit}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        className={`${classes.iconButton} ${classes.submitButton}`}
                                                        onClick={submitUpdate}
                                                    >
                                                        <DoneIcon />
                                                    </IconButton>
                                                </React.Fragment>
                                            )}
                                        </TableCell>
                                    ) : (
                                        <TableCell align='right' className={classes.actionCell}>
                                            {CanUserFunc(access.data.edit) && (
                                                <>
                                                    <IconButton
                                                        className={classes.iconButton}
                                                        onClick={handleStartEdit(note.targetDataId)}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        className={classes.iconButton}
                                                        onClick={handleAskDelete(note.targetDataId)}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

NotesDialog.propTypes = {
    target: PropTypes.object,
    notes: PropTypes.array,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,

    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.general.loading,
});

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(NotesDialog);
