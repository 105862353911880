import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { dataTypeDict } from '../../../constants';
import DropdownButton from '../../../assets/button/DropdownButton';

const useStyles = makeStyles(theme => ({
  graphType: {
    fontWeight: 'bold',
    paddingLeft: 20,
    fontSize: 16,
  },
  graphTitle: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 18,
  },
  graphDetailsWrapper: {
    marginBottom: 10,
  },
  moreIconBtn: {
    padding: 0,
    color: '#0FB99E',
  },
  moreIcon: {
    fontSize: 32,
  },
  graphWrapper: {
    backgroundColor: '#fff',
    padding: 10,
    fontFamily: 'Roboto',
  },
  menuItem:{
    minWidth: 150
  },
  categoryDropdown: {
    marginRight: 10,
  },
  // this is needed so that dom-to-image can use fonts, alternatively use https://github.com/MichalBryxi/dom-to-image
  '@font-face': [
    {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontDisplay: 'swap',
      src: 'local(\'Roboto Medium\'), local(\'Roboto-Medium\'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format(\'woff2\')',
      unicodeRange: 'U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF',
    },
    {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontDisplay: 'swap',
      src: 'local(\'Roboto Medium\'), local(\'Roboto-Medium\'), url(https://fonts.gstatic.com/s/roboto/v20/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format(\'woff2\')',
      unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD',
    }
  ],
}));

const GraphWrapper = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (props.type === 'category') {
      const { selectedX, selectedY } = getSelectedXandYForCategory();
      if (selectedX === -1 && selectedY === -1 
          && typeof props.categorySetting[props.title]?.x === 'undefined' 
          && typeof props.categorySetting[props.title]?.x === 'undefined') {
        props.setCategorySetting({ ...props.categorySetting, [props.title]: { x: -1, y: -1 } });
      }
    }
  }, []);

  const handlePercentSwitch = () => {
    setAnchorEl(null);
    props.setUsePercent({ ...props.usePercent, [props.title]: !props.usePercent[props.title] });
  };

  const handleRateSetting = (rate) => () => {
    setAnchorEl(null);
    props.handleRateUnit({ ...props.rateUnit, [props.title]: rate });
  };

  const handleCumulativeSwitch = () => {
    setAnchorEl(null);
    props.setUseCumulative({ ...props.useCumulative, [props.title]: !props.useCumulative[props.title] });
  };
  const handleTotalSwitch = () => {
    setAnchorEl(null);
    props.setUseTotal({ ...props.useTotal, [props.title]: !props.useTotal[props.title] });
  };

  const handleGraphSwitch = () => {
    setAnchorEl(null);
    props.setSwitchGraphs({ ...props.switchGraphs, [props.title]: !props.switchGraphs[props.title] });
  };

  const handleCategorySetting = (axis) => (e) => {
    setAnchorEl(null);
    let targetCategorySetting = props.categorySetting[props.title] || { x: -1, y: -1 };
    const { value } = e.target;
    const oppositeAxis = axis === 'x' ? 'y' : 'x';
    if (value === -1) {
      targetCategorySetting[oppositeAxis] = -1;
    } else if ((oppositeAxis === 'x' && targetCategorySetting[oppositeAxis] === -1)
      || (targetCategorySetting[oppositeAxis] === value && targetCategorySetting[axis] === -1)) {
        targetCategorySetting[oppositeAxis] = (value === 0) ? 1 : 0;
    } else if (targetCategorySetting[oppositeAxis] === value) {
      targetCategorySetting[oppositeAxis] = targetCategorySetting[axis];
    }
    props.setCategorySetting({ ...props.categorySetting, [props.title]: { ...targetCategorySetting, [axis]: value } });
  };

  const handleDownload = () => {
    props.handleDownload(`${props.type} ${props.title}`);
    setAnchorEl(null);
  };

  const getCategoryXOptions = () => {
    if (props.customData.length === 1) {
      return [{ value: -1, label: 'Category' }];
    }
    return [{ value: -1, label: 'Category' }]
      .concat(props.customData.map(e => ({ value: e.id, label: e.category })))
  }

  const getCategoryYOptions = () => {
    if (props.customData.length === 1) {
      return [{ value: -1, label: 'Response' }];
    }
    return [{ value: -1, label: 'Response' }]
      .concat(props.customData.map(e => ({ value: e.id, label: e.category })))
  }

  const generatePercent = (props) => {
    switch (props.type) {
      case 'prompt':
      case 'interval':
      case 'correctIncorrect':
        return (
          <MenuItem onClick={() => { 
            props.useCumulative[props.title] && handleCumulativeSwitch(); 
            handlePercentSwitch(); 
            }
          }>
            {props.usePercent[props.title] ? 'Show count' : 'Show percent'}
          </MenuItem>
        );

      default:
        return (
          <MenuItem onClick={handlePercentSwitch}>
            {props.usePercent[props.title] ? 'Show numbers' : 'Show percent'}
          </MenuItem>
        );
    }
  };

  const generateCumulative = (props) => {
    switch (props.type) {
      case 'rate':
        if (!props.useCumulative[props.title]) {
          return (
            <MenuItem className={classes.menuItem} onClick={handleCumulativeSwitch}>
              Cumulative
            </MenuItem>
          );
        } else {
          return (
            <MenuItem className={classes.menuItem} onClick={handleCumulativeSwitch}>
              Rate per {props.rateUnit[props.title]}
            </MenuItem>
          );
        }

      case 'prompt':
      case 'interval':
      case 'correctIncorrect':
        return props.useCumulative[props.title] ? 
          <MenuItem onClick={handleCumulativeSwitch}>
           {props.usePercent[props.title] ? 'Show percent' : 'Show count'}
          </MenuItem>
          :
          <MenuItem onClick={() => { 
            handleCumulativeSwitch(); 
            props.usePercent[props.title] && handlePercentSwitch(); 
            }
          }>
            Cumulative
          </MenuItem>
        
      default:
        return (
          <MenuItem onClick={handleCumulativeSwitch}>
            {props.useCumulative[props.title] || props.useCumulative[props.type] ? 'Normal' : 'Cumulative'}
          </MenuItem>
        );
    }
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getSelectedXandYForCategory = () => {
    let selectedX, selectedY;

    if (props.customData?.length > 1) {
      selectedX = props.type === 'category' && (props.categorySetting[props.title]?.x ?? props.customData[0].id);
      selectedY = props.type === 'category' && (props.categorySetting[props.title]?.y ?? props.customData[1].id);
    } else {
      selectedX = props.type === 'category' && (props.categorySetting[props.title]?.x ?? -1);
      selectedY = props.type === 'category' && (props.categorySetting[props.title]?.y ?? -1);
    }
    return { selectedX, selectedY };
  }

  const { selectedX, selectedY } = getSelectedXandYForCategory();

  return (
    <Grid className={classes.graphWrapper} container id={`${props.type} ${props.title}`}>
      <Grid item xs={12} container className={classes.graphDetailsWrapper}>
        <Grid item xs={4} className={classes.graphType}>
          {props.graphElements.dataType &&
          `Data Type: ${dataTypeDict[props.type]}`
          }
        </Grid>
        <Grid item xs={4} className={classes.graphTitle}>
          {props.graphElements.graphTitles ? props.title : ''}
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <IconButton style={{ visibility: props.isDownloading ? 'hidden' : 'visible' }} onClick={openMenu}
                      className={classes.moreIconBtn}>
            <MoreVertIcon className={classes.moreIcon}/>
          </IconButton>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {props.children}
      </Grid>
      {props.type === 'category' &&
      <Grid item xs={12} container justify="center">
        <DropdownButton
          label="X"
          selectedValue={selectedX}
          onValueChange={handleCategorySetting('x')}
          options={getCategoryXOptions()}
          wrapperStyle={classes.categoryDropdown}
          emptySelection="Loading..."
        />
        <DropdownButton
          label="Y"
          selectedValue={selectedY}
          onValueChange={handleCategorySetting('y')}
          options={getCategoryYOptions()}
          emptySelection="Loading..."
        />
      </Grid>
      }

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleDownload}>Download</MenuItem>
        {props.usePercent && props.setUsePercent ? generatePercent(props) : null}
        {props.useCumulative ? generateCumulative(props) : null}
        {props.useTotal ?
          <MenuItem onClick={handleTotalSwitch}>
            {props.useTotal[props.title] ? 'Show average' : 'Show total'}
          </MenuItem>
          : null
        }
        {props.switchGraphs ?
          <MenuItem onClick={handleGraphSwitch}>
            {props.switchGraphs[props.title] ? '% correct on each step' : '% correct total'}
          </MenuItem>
          : null
        }
        {props.type === 'rate' ?
          <MenuItem className={classes.menuItem} onClick={handleRateSetting(props.rateUnit[props.title] === 'hour' ? 'minute' : 'hour')}>
            Rate per {props.rateUnit[props.title] === 'hour' ? 'minute' : 'hour'}
          </MenuItem>
          : null
        }
        {props.handleEditPhase &&
        <MenuItem onClick={props.handleEditPhase}>Lines/Annotations</MenuItem>
        }
      </Menu>
    </Grid>
  );
};

GraphWrapper.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  usePercent: PropTypes.object,
  setUsePercent: PropTypes.func,
  useTotal: PropTypes.object,
  setUseTotal: PropTypes.func,
  children: PropTypes.node,
  isDownloading: PropTypes.bool,
  handleDownload: PropTypes.func,
  graphElements: PropTypes.object.isRequired,
  handleRateUnit: PropTypes.func,
  rateUnit: PropTypes.object,
  showPhase: PropTypes.bool,
  handleEditPhase: PropTypes.func,
  useCumulative: PropTypes.object,
  setUseCumulative: PropTypes.func,
  customData: PropTypes.object,
  categorySetting: PropTypes.object,
  setCategorySetting: PropTypes.func,
};

export default GraphWrapper;
