import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
    ResponsiveContainer, LineChart, Line, LabelList, Tooltip,
} from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
    dateAxisSetting, graphHeight, defaultLineSetting, defaultAxisSetting,
    defaultLineChartSetting, getColor, toDateFull,
    renderCustomizedValue, defaultTooltipSetting, getMinMaxDate, generateDomainTicks,
    splitGraphDataByPhases, getTargetColor, generateTargetName
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const RatingScaleGraph = (props) => {
    const [hoveredDate, setHoveredDate] = useState(0);
    const [enableAnimation, enableTooltip] = useAnimationTimeout();
    const [disabledLines, setDisabledLines] = useState({});
    const countTotalData = props.graphData.length;
    const height = graphHeight + Math.floor(countTotalData / 7) * 25;

    const formattedPayload = useMemo(() => {
        const payload = [];
        props.graphData.forEach((target, index) => {
            target.targetData
                .filter(e => dateToDays(e.date) === hoveredDate)
                .forEach((targetData) => {
                        target.customData.map((data, id) => {
                                if (!disabledLines[`${target.name}: ${data.question}`]) {
                                        payload.push({
                                                date: hoveredDate,
                                                color: getColor(index, id % 2 ? 'light' : 'dark'),
                                                name: `${target.name}: ${data.question}`,
                                                value: targetData.value.find(v => v.questionId == data.id)?.answer || "",
                                        });
                                }
                                
                        })
                })
        })
        return payload;
    }, [hoveredDate, disabledLines, props.graphData])

    const { graphDomain, graphTicks } = useMemo(() => {
        let { graphDomain, graphTicks } = props;
        if (!graphDomain.length || !graphTicks.length) {
            const { minDate, maxDate } = getMinMaxDate(props.graphData);
            const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
            graphDomain = domain;
            graphTicks = ticks;
        }
        return { graphDomain, graphTicks };
    }, [props.graphDomain, props.graphTicks, props.graphData]);

    const formattedGraphData = useMemo(() => {
        const formattedData = [];
        const dateValueMap = {};
        let maxValue = 9;

        props.graphData.forEach(target => {
            const newTarget = clonedeep(target);
            newTarget.targetData.length > 0 && newTarget.targetData.forEach(targetData => {
                targetData.value = targetData.value.map(data => {
                    const date = dateToDays(targetData.date);
                    const value = data.answer;
                    if (value > maxValue) {
                        maxValue = value;
                    }
                    const dateValue = `${date}-${value}`;
                    dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;
                    return {
                        ...data,
                        answer: {
                            value: value,
                            sameDot: dateValueMap[dateValue],
                        }
                    }
                })
            })
            formattedData.push(newTarget);
        })

        const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
        formattedData.forEach(target => {
            target.targetData.length > 0 && target.targetData.forEach(targetData => {
                targetData.value = targetData.value.map(data => {
                    const valueObj = data.answer;
                    return {
                        ...data,
                        answer: valueObj.value + (valueObj.sameDot * dotMultiplier),
                    }
                })
            })
        })

        return formattedData;
    }, [props.graphData])
    
    const graphData = useMemo(() => {
        if (!props.showPhase) {
            return formattedGraphData;
        } else {
            return splitGraphDataByPhases(formattedGraphData);
        }
    }, [formattedGraphData, props.showPhase])

    return (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
                {yAxis('value', defaultAxisSetting, 'Rating', !!props.graphElements.axisLabelsY)}
                {(enableTooltip && !props.isDownloading) &&
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                }
                
                {graphData[0].customData.map((customDatum, index1) => (
                    graphData.map((target, index2) => (
                    <Line
                        {...defaultLineSetting}
                        stroke={getTargetColor(target, `${index1}_${index2}`, 'light')}
                        dataKey="value"
                        name={generateTargetName(target, `${index1}_${index2}`, `: ${customDatum.question}`)}
                        key={generateTargetName(target, `${index1}_${index2}`, `: ${customDatum.question}`)}
                        isAnimationActive={enableAnimation}
                        data={!disabledLines[`${target.name}: ${customDatum.question}`] ?
                            target.targetData.map(e => (
                                { date: dateToDays(e.date), value: e.value.find(v => v.questionId == customDatum.id)?.answer }
                            )) : []
                        }
                    >
                        {props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: correct`)} />}
                        {props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, (value) => value ? Math.round(value) : '')} />}
                    </Line>
                ))))}
            </LineChart>
        </ResponsiveContainer>
    )
}

RatingScaleGraph.propTypes = {
    graphData: PropTypes.array.isRequired,
    graphElements: PropTypes.object.isRequired,
    graphDomain: PropTypes.array.isRequired,
    graphTicks: PropTypes.array.isRequired,
    showPhase: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool.isRequired,
};

export default RatingScaleGraph;
