import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogTitle, DialogContent, Dialog, TextField, Grid, IconButton, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    saveBtn: {
        padding: '6px 20px',
        marginTop: 18,
        marginBottom: 18,
    },
}));
const DuplicateTarget = (props) => {
    const classes = useStyles();
    const { onClose, open, duplicateTarget } = props;
    const [duplicates, setDuplicates] = useState(1);
    const [error, setError] = useState(false);

    useEffect(() => {
        setDuplicates(1);
        setError(false);
    }, [open]);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        duplicateTarget(duplicates);
    };

    const handleChange = (e) => {
        const value = e.target.value;
        if (value < 1) {
            setDuplicates(1);
        } else {
            setDuplicates(e.target.value);
        }
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth='sm' open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        Create Duplicate
                    </Grid>
                    <Grid item xs={4} container justify='flex-end'>
                        <IconButton id="duplicate-target-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} container>
                        <InputLabel id="duplicate-target-number-label">Number of Duplicates</InputLabel>
                        <TextField
                            id="duplicate-target-number"
                            autoFocus
                            fullWidth
                            variant='outlined'
                            type='number'
                            value={duplicates}
                            onChange={handleChange}
                            error={error && !duplicates.trim()}
                            helperText={error && !duplicates.trim() ? "Number can't be empty" : ' '}
                        />
                    </Grid>
                    <Grid item xs={12} container justify='flex-end'>
                        <Button id="duplicate-target-save" className={classes.saveBtn} onClick={handleOk}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DuplicateTarget.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    duplicateTarget: PropTypes.func.isRequired,
};

export default DuplicateTarget;
