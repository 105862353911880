import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';
import format from 'date-fns/format';

import withDataTableHoc from './DataTableHoc';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';

const useStyles = makeStyles(theme => ({
    rowSeparator: {
        border: 'none !important',
        minHeight: 10,
        height: 10,
    },
    table: {
        borderCollapse: 'collapse',
    },
    tableCell: {
        border: '1px solid black',
        padding: '5px 10px',
        minWidth: 15,
        position: 'relative',
    },
    sessionCell: {
        paddingRight: 5,
    },
    borderlessCell: {
        padding: '0',
        border: 'none',
    },
    value: {
        display: 'inline-block',
    },
    textField: {
        width: '100%'
    },
    input: {
        padding: '0px !important',
        textAlign: 'center',
        '&::placeholder': { color: 'black', opacity: 1 }
    },
    deleteBtn: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#d32f2f' },
    },
    deleteActive: {
        opacity: 1,
        color: '#d32f2f',
    },
    addButton: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#0FB99E' },
    },
    menuItem: {
        justifyContent: 'center',
    },
    moreAction: {
        padding: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        position: 'absolute',
        bottom: 0,
        height: 10,
        width: 10,
        minWidth: 10,
    },
    bolded: {
        fontWeight: 'bold',
    },
    hidden: {
        display: 'none',
    }
}));

const createCustomData = (data) => {
    return data.value;
}

const CategoryTable = (props) => {
    const classes = useStyles();
    const { isEdit, dataToUse, setEditingData, handleDelete, handleAdd, handleMoreAction, customData } = props;

    const handleChange = (index, index2, categoryId) => event => {
        let { value } = event.target;

        const newData = dataToUse;
        const input = newData[index].value[index2].find(e => e.id === categoryId);
        if(input) {
            input.value = value;
        } else {
            newData[index].value[index2].push({ id: categoryId, value });
        }
        setEditingData(newData.slice());
    }

    const responsesMapSelect = useMemo(() => {
        if(!customData) return;

        const responses = {};
        customData.forEach(category => {
            responses[category.id] = category.responses;
        })

        return responses;
    }, [customData])


    const responsesMap = useMemo(() => {
        if(!customData) return;

        const responses = {};
        customData.forEach(category => {
            if(!category.responses) return; 
            
            responses[category.id] = {};
            category.responses.forEach((response) => {
                responses[category.id][response.value] = response.label;
            })
        })

        return responses;
    }, [customData])

    return (
        <Grid item xs={12} container>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <td align="center" className={`${classes.tableCell} ${classes.bolded}`}>Date/Time</td>
                        {customData.map(category => (
                            <td align="center" key={category.id} className={`${classes.tableCell} ${classes.bolded}`}>{category.category}</td>
                        ))}
                    </tr>
                </thead>
                {dataToUse && dataToUse.map((categoryData, index) => (
                    <tbody key={`category-${index}-tbody`}>
                        {categoryData?.value.map((input, index2) => (
                            <tr key={`category-${index}-${index2}-row`}>
                                <td className={classes.tableCell} align="center">
									{format(new Date(input?.[0]?.date ?? categoryData.date), "MM/d/yyyy HH:mm")}
                                </td>
                                {customData.map((category, index3, arr) => {
                                    const value = input.find(e => e.id === category.id)?.value;

                                    return (
                                        <td
                                            className={classes.tableCell}
                                            align="center"
                                            key={`category-${index}-${index2}-${index3}`}
                                        >
                                            {(isEdit && value !== null) ?
                                                <TextField
                                                    value={value}
                                                    className={classes.textField}
                                                    InputProps={{ classes: { input: classes.input } }}
                                                    onChange={handleChange(index, index2, category.id)}
                                                    placeholder={categoryData.isDelete ? '' : '1'}
                                                    select
                                                    SelectProps={{ IconComponent: () => null }}
                                                    disabled={categoryData.isDelete || category.isDeleted || categoryData.isOngoing}
                                                >
                                                    <MenuItem key="none" className={classes.menuItem} style={{ height: 36 }} value=""></MenuItem>
                                                    {responsesMapSelect[category.id].map((response) => (
                                                        <MenuItem 
                                                            key={response.value} 
                                                            className={`${classes.menuItem} ${response.isDeleted ? classes.hidden : ''}`}
                                                            value={response.value}
                                                        >{response.label}</MenuItem>
                                                    ))}
                                                </TextField>
                                                :
                                                <span className={classes.value}>{responsesMap[category.id][value] ?? ''}</span>
                                            }
                                            {(isEdit && !categoryData.isDelete && index3 === arr.length - 1) &&
                                                <Tooltip title="More">
                                                    <Button disabled={categoryData.isOngoing} className={classes.moreAction} onClick={handleMoreAction(index, index2)}></Button>
                                                </Tooltip>
                                            }
                                        </td>
                                    )
                                })}
                                <td valign="middle" className={classes.borderlessCell}>
                                    {(index2 === 0 && isEdit && CanUserFunc(access.data.remove)) &&
                                        <Grid container style={{ height: '100%' }} alignContent="center">
                                            <DeleteIcon
                                                color={categoryData.isOngoing ? 'disabled' : 'action'}
                                                className={`${classes.deleteBtn} ${categoryData.isDelete ? classes.deleteActive : ''}`}
                                                onClick={!categoryData.isOngoing && handleDelete(index)}
                                            />
                                        </Grid>
                                    }
                                </td>

                                <td valign="middle" className={classes.borderlessCell}>
                                    {(index2 === 0 && isEdit) &&
                                        <Grid container style={{ height: '100%', visibility: categoryData.isDelete ? 'hidden' : 'visible' }} alignContent="center">
                                            <AddIcon
                                                color={categoryData.isOngoing ? 'disabled' : 'action'}
                                                className={classes.addButton}
                                                onClick={!categoryData.isOngoing && handleAdd(index)}
                                            />
                                        </Grid>
                                    }
                                </td>
                            </tr>
                        ))}
                        <tr style={{ minHeight: 10 }}>
                            <td colSpan={customData.length} className={classes.rowSeparator}></td>
                        </tr>
                    </tbody>
                ))}
            </table>
        </Grid>
    )
}

CategoryTable.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
};

export default withDataTableHoc(CategoryTable, 'category', createCustomData, undefined, 'Add input');
