import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles, Chip, Box, Typography } from "@material-ui/core";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import TargetPopup from "./TargetPopup";
import Camera from "./Camera";
import Wrapper from "./Wrapper";
import PhaseColoredButton from "./PhaseColoredButton";
import { connect } from "react-redux";
import {
  setGlobalDialog,
  closeGlobalDialog,
} from "../../../../store/general/actions";
import { setSessionConfig } from "../../../../store/session/actions";
import { setSnackbar } from "../../../../store/general/actions";
import DialogActions from "@material-ui/core/DialogActions";
import { maxVideoTimeLimitInSessions } from "../../../../constants";
import { KEY_IMAGE } from "../../../../utils/sessionHistoryRecordParser";

const MAX_FILE_COUNT = 5;

const useStyles = makeStyles((theme) => ({
  controlButton: {
    borderRadius: 0,
    width: "40%",
  },
  editButton: {
    borderRadius: 0,
    width: "100%",
  },
  popupContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "baseline",
    width: 500,
    minHeight: 300,
  },
  mainControllers: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: 20,
  },
  attachedFiles: {
    maxHeight: 300,
    overflowY: "auto",
  },
  fileInputs: {
    display: "none",
  },
  dontShowMessage: {
    paddingRight: 0,
  },
  maxFileWarning: {
    color: "red",
    textAlign: "center",
    marginBottom: 10,
    fontSize: 14,
  },
}));

function Image(props) {
  const classes = useStyles();

  const [enterModal, setEnterModal] = useState(false);
  const [fileInputCount, setFileInputCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [webCamOn, setWebCamOn] = useState(false);

  const fileInputRef = useRef([]);

  useEffect(() => {
    if (enterModal && props.sessionData.length) {
      const tempData = props.sessionData[props.sessionData.length - 1];
      setFiles(tempData?.data);
    } else {
      setFiles([]);
    }
  }, [enterModal]);

  const fileUploadHandler = () => {
    fileInputRef.current[fileInputCount - 1].click();
  };

  const validateVideoFileLength = file => new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video');
      video.preload = 'metadata';
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration <= maxVideoTimeLimitInSessions);
      }
      video.src = URL.createObjectURL(file);
    } catch (e) {
      resolve(true)
    }
  });

  const validateVideoFiles = async (files) => {
    const promises = [];
    Array.from(files).filter(e => e.type?.startsWith('video/')).forEach((file) => {
      promises.push(validateVideoFileLength(file));
    });
    const valid = await Promise.all(promises);
    if (valid.filter(e => e === false).length) {
      props.setSnackbar("warning", `Videos up to ${maxVideoTimeLimitInSessions} seconds are allowed only.`);
      return false;
    }
    return true;
  };

  const fileChangeHandler = async (newInputFiles, fromCamera) => {
    setFileInputCount((count) => count + 1);
    if (newInputFiles.length + files.length > MAX_FILE_COUNT) {
      props.setSnackbar(
        "warning",
        "Maximum 5 images/videos are allowed per session"
      );
      return;
    }
    if (!fromCamera && !(await validateVideoFiles(newInputFiles))) {
      return;
    }
    Array.from(newInputFiles).forEach((newInputFile) => {
      setFiles((files) => {
        const newFiles = files.slice();
        newFiles.push(newInputFile);
        return newFiles;
      });
    });
  };

  const cancelHandler = () => {
    if (webCamOn) {
      setWebCamOn(false);
    } else {
      setEnterModal(false);
    }
  };

  const disableDataLossMessage = () => {
    props.setSessionConfig("dataLossMessage", false);
    props.closeGlobalDialog();
  };

  const saveHandler = () => {
    const imageCount = files.filter((e) => e.type.startsWith("image/")).length;
    const videoCount = files.filter((e) => e.type.startsWith("video/")).length;

    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      files,
      { key: KEY_IMAGE, data: { imageCount, videoCount }}
    );
    setEnterModal(false);
    props.sessionConfig?.dataLossMessage !== false &&
      props.setGlobalDialog(
        "Warning",
        "Collected image/video will be removed if you close or refresh the tab.",
        (answer) => {},
        <DialogActions className={classes.dontShowMessage}>
          <Button onClick={disableDataLossMessage} autoFocus>
            Don't show this message again
          </Button>
          <Button onClick={props.closeGlobalDialog} autoFocus>
            OK
          </Button>
        </DialogActions>,
        false
      );
  };

  const fileDeleteHandler = (index) => {
    setFiles((files) => {
      const newFiles = files.slice();
      newFiles.splice(index, 1);
      return newFiles;
    });
  };

  const photoVideoHandler = (file) => {
    fileChangeHandler([file], true);
    setWebCamOn(false);
  };

  const videoTimeLimitHandler = () => {
    props.setSnackbar("warning", `Videos up to ${maxVideoTimeLimitInSessions} seconds are allowed only.`);
  };

  return (
    <Wrapper {...props}>
      <PhaseColoredButton
        className={classes.editButton}
        onClick={() => setEnterModal(true)}
        phase={props.target?.phase}
      >
        <PhotoCameraIcon />
      </PhaseColoredButton>
      <div className={classes.fileInputs}>
        {[...Array(fileInputCount).keys()].map((index) => (
          <input
            key={index}
            ref={(el) => (fileInputRef.current[index] = el)}
            type="file"
            accept="video/*,image/*"
            onChange={(e) => fileChangeHandler(e.target.files, false)}
            multiple
          ></input>
        ))}
      </div>
      <TargetPopup
        open={enterModal}
        onSave={!webCamOn && saveHandler}
        onCancel={cancelHandler}
        title={webCamOn ? "Capture Photo / Video" : props.target.name}
        saveDisabled={!files?.length}
      >
        {!webCamOn && (
          <div className={classes.popupContent}>
            <div className={classes.maxFileWarning}>
              * Maximum 5 images/videos are allowed per session
            </div>
            <div className={classes.mainControllers}>
              <Button
                onClick={fileUploadHandler}
                className={classes.controlButton}
                disabled={files?.length >= MAX_FILE_COUNT}
              >
                Pick from Storage
              </Button>
              <Button
                className={classes.controlButton}
                style={{ backgroundColor: "blue" }}
                onClick={() => setWebCamOn(true)}
                disabled={files?.length >= MAX_FILE_COUNT}
              >
                Take from Camera
              </Button>
            </div>
            <Box className={classes.attachedFiles}>
              {files.map((file, index) => (
                <Chip
                  key={file.lastModified}
                  onDelete={() => {
                    fileDeleteHandler(index);
                  }}
                  label={
                    <Typography style={{ whiteSpace: "normal" }}>
                      {file.name}
                    </Typography>
                  }
                  style={{ height: "100%" }}
                />
              ))}
            </Box>
          </div>
        )}
        {webCamOn && (
          <div className={classes.popupContent}>
            <Camera
              onPhoto={photoVideoHandler}
              onVideo={photoVideoHandler}
              onVideoTimeLimitReached={videoTimeLimitHandler}
            />
          </div>
        )}
      </TargetPopup>
    </Wrapper>
  );
}

Image.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  setSessionConfig: PropTypes.func.isRequired,
  setGlobalDialog: PropTypes.func.isRequired,
  closeGlobalDialog: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  sessionConfig: state.sessions.sessionConfig,
});

export default connect(mapStateToProps, {
  setSessionConfig,
  setGlobalDialog,
  closeGlobalDialog,
  setSnackbar,
})(Image);
