import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog,
    Select, MenuItem, InputLabel, TextField, Grid, IconButton, FormHelperText, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import CanUser from '../CanUser';
import { access } from '../../constants';
import { setLoading } from '../../store/general/actions';
import RichEditor from '../../assets/rte/RichEditor';
import { getLibraryDomains } from '../../services/LibraryService'

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
    },
    rightWrapper: {
        paddingLeft: 19,
    },
    largeWrapper: {
        marginTop: 34,
        height: 340
    },
    input: {
        width: '100%',
    },
    saveBtn: {
        padding: '6px 20px',
    },
    targetBtn: {
        padding: '6px 20px',
    },
    dialogPaper: {
        width: 900,
        '@media (max-width: 768px)': {
            maxWidth: 600,
        }
    },
    dialogTitle: {
        '@media (max-width: 768px)': {
            padding: '25px 20px 20px 20px',
            whiteSpace: 'nowrap'
        }
    },
    dialogContent: {
        '@media (max-width: 768px)': {
            padding: '25px 20px 20px 20px'
        }
    },
    dialogActions: {
        '@media (max-width: 768px)': {
            padding: '15px 20px'
        }
    },
    instructionField: {
        height: 450,
        maxHeight: 450,
    },
    importLibraryWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: '50px',

        '& .MuiInputLabel-root': {
            marginBottom: 0
        }
    },
    importBtn: {
        marginLeft: '10px',
        height: '30px',
    },
}));

const defaultProgram = {
    domainId: 0,
    name: '',
    instructions: '',
};

const NewProgram = (props) => {
    const classes = useStyles();
    const { onClose, open, addProgram, selection, domains, patient, fromLibrary, program, updateProgram, loading, isFormDisabled, isMobile } = props;
    const [allDomains, setAllDomains] = useState([]);
    const [newProgram, setNewProgram] = useState(cloneDeep(defaultProgram));
    const [newInstructions, setNewInstructions] = useState('');
    const [error, setError] = useState(false);
    const isEdit = !!program;

    useEffect(() => {
        if (!!isEdit) {
            setNewProgram({
                name: program.name,
                domainId: program.domainId || program?.domain.id || 0,
            })
            setNewInstructions(program.instructions);
        } else {
            let domainId = 0;
            if (!selection.all) {
                domainId = selection.domain;
                // if domain isn't in the list, it's most likely an archived domain
                if (!allDomains.find(domain => domain.id === domainId)) {
                    domainId = 0;
                }
            }
            setNewProgram({
                ...cloneDeep(defaultProgram),
                domainId,
            });
            setNewInstructions(defaultProgram.instructions);
        }
        setError(false);
    }, [open, program])

    useEffect(() => {
        if (!fromLibrary) {
            setAllDomains(domains.filter(domain => domain.status === 'active'));
        }
    }, [domains, fromLibrary])

    useEffect(() => {
        if (fromLibrary) {
            getLibraryDomains('all').then((res) => {
                setAllDomains(res.data.data.map(libraryDomain => libraryDomain.domain));
            })
        }
    }, [fromLibrary]);

    const handleCancel = () => {
        onClose();
    };

    const handleChange = field => event => {
        setNewProgram({ ...newProgram, [field]: event.target.value });
    }

    const handleChangeRte = field => value => {
        setNewInstructions(value);
    }

    const handleAddTarget = (newTarget) => () => {
        if ((!fromLibrary && !newProgram.domainId) || !newProgram.name.trim()) {
            setError(true);
            return;
        }

        let data = {
            program: {
                ...newProgram,
                name: newProgram.name.trim(),
                branchId: patient.branch.id,
                instructions: newInstructions,
            },
            newTarget,
        }
        addProgram(data);
    }

    const handleUpdate = () => {
        if ((!fromLibrary && !newProgram.domainId) || !newProgram.name.trim()) {
            setError(true);
            return;
        }

        let data = {
            id: program.id,
            program: {
                ...newProgram,
                name: newProgram.name.trim(),
                instructions: newInstructions,
            },
        }
        updateProgram(data);
    };

    return (
        <Dialog disableBackdropClick disableEnforceFocus disableEscapeKeyDown maxWidth="md" open={open} classes={{ paper: classes.dialogPaper }}>
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid item xs={6}>
                        {`${isEdit ? 'Edit' : 'Create'} Program`}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton id="new-program-form-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {(loading.targetFormLib) ?
                    <Grid container justify="center" alignItems="center" style={{ width: 1200, height: 490 }}>
                        <CircularProgress size={250} />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <Grid item xs={12} className={classes.importLibraryWrapper}>
                                <InputLabel id="new-program-form-field-domain-label" required>Domain</InputLabel>
                            </Grid>
                            <Select
                                id="new-program-form-field-domain"
                                variant="outlined"
                                IconComponent={KeyboardArrowDownIcon}
                                className={classes.input}
                                value={newProgram.domainId}
                                error={error && !newProgram.domainId}
                                onChange={handleChange('domainId')}
                                disabled={isFormDisabled}
                            >
                                <MenuItem disabled value={0}>Choose</MenuItem>
                                {allDomains.map(dom => (
                                    <MenuItem id={`new-program-form-field-domain-list-item-${dom.name}`} key={dom.name} value={dom.id}>{dom.name}</MenuItem>
                                ))}
                            </Select>
                            <FormHelperText id="new-program-form-field-domain-help-text" error={error && !newProgram.domainId}>
                                {error && !newProgram.domainId ? 'Please choose a domain' : ' '}
                            </FormHelperText>
                        </Grid>
                        <Grid item xs={6} className={fromLibrary ? classes.leftWrapper : classes.rightWrapper}>
                            <Grid item xs={12} className={classes.importLibraryWrapper}>
                                <InputLabel id="new-program-form-field-program-label" required>Program Name</InputLabel>
                            </Grid>
                            <TextField
                                id="new-program-form-field-program"
                                variant="outlined"
                                value={newProgram.name}
                                onChange={handleChange('name')}
                                error={error && !newProgram.name.trim()}
                                helperText={error && !newProgram.name.trim() ? "Program name can't be empty" : ' '}
                                className={classes.input}
                                disabled={isFormDisabled}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.largeWrapper}>
                            <InputLabel id="new-program-form-field-instructions-label">Program Instructions</InputLabel>
                            <RichEditor
                                value={newInstructions}
                                onChange={handleChangeRte('instructions')}
                                className={classes.instructionField}
                                id="program-instructions"
                                disabled={isFormDisabled}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>

            <DialogActions className={classes.dialogActions}>
                <Grid container>
                    <Grid item xs={6}>
                        {!isEdit && <Button id="new-program-form-save-new" className={classes.saveBtn} onClick={handleAddTarget(false)}>Save</Button>}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        {isEdit ?
                            (!isFormDisabled && <Button id="new-program-form-save-existing" className={classes.saveBtn} onClick={handleUpdate}>Save</Button>)
                            :
                            <CanUser
                                perform={access.targets.add}
                                yes={() => (
                                    <Button id="new-program-form-proceed" className={classes.targetBtn} onClick={handleAddTarget(true)}>{!isMobile && 'Proceed to'} Add Target</Button>
                                )}
                            />
                        }
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

NewProgram.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    addProgram: PropTypes.func,
    selection: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    updateProgram: PropTypes.func,
    program: PropTypes.object,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    fromLibrary: PropTypes.bool,
    isFormDisabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    domains: state.data.domains,
    selection: state.general.selection,
    user: state.auth.user,
    patient: state.patient.patient,
    loading: state.general.loading,
    isMobile: state.general.isMobile,
})

export default connect(mapStateToProps, { setLoading })(NewProgram)
