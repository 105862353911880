import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    iconButton: {
        height: 34,
        width: 34,
        border: '1px solid #0FB99E',
        color: '#0FB99E',
        backgroundColor: '#ffffff',
        '&:disabled': {
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
        }
    }
}));

const TooltipedIconButton = (props) => {
    const classes = useStyles();
    const { tooltip, icon, onClick, disabled, className, id } = props;

    return (
        <Tooltip title={tooltip}>
            <span>
                <Fab
                    className={`${classes.iconButton} ${className}`}
                    onClick={onClick}
                    disabled={disabled}
                    id={id}
                >
                    {icon}
                </Fab>
            </span>
        </Tooltip>
    );
}

TooltipedIconButton.defaultProps = {
    disabled: false,
    onClick: () => {},
    tooltip: '',
}

TooltipedIconButton.propTypes = {
    icon: PropTypes.node.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    tooltip: PropTypes.string,
    className: PropTypes.string,
};

export default TooltipedIconButton;