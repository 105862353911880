import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	chart: {
		marginTop: 100,
		width: '30%',
		display: 'block',
		padding: 10,
		border: '5px solid #0FB99E',
		borderRadius: 10,
	},
	title: {
		fontWeight: 'bold',
	},
	subTitle: {
		margin: 0,
		color: '#5A5A5A',
	},
}));

const NoProgram = () => {
	const classes = useStyles();

	return (
		<Grid className={classes.root}>
			<Grid item xs={12} container justify="center">
				<img id="analysis_empty_data_icon" className={classes.chart} alt="Chart" src={require("../../resources/ChartIcon.svg")} />
			</Grid>
			<Grid item xs={12} container justify="center">
				<p id="analysis_no_item_selected_message" className={classes.title}>NO DOMAIN OR PROGRAM SELECTED</p>
			</Grid>
			<Grid item xs={12} container justify="center">
				<p id="analysis_please_select_message" className={classes.subTitle}>Please select a domain or program from the sidebar</p>
			</Grid>
		</Grid>
	)
}

export default NoProgram;
