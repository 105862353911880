import io from 'socket.io-client';
import { httpErrorHandling } from './agent';
import store from '../store/store';
import { setIsOnline } from '../store/auth/actions';
// import store from "../store";

export default {
  callbacks: [],
  connection: null,
  pingInterval: null,

  getSessionTargets(patientId, date) {
    console.log('getSessionTarget xoxo', date)
    return new Promise((resolve, reject) => {
      this.connection.emit('GET_SESSION_TARGETS', { 
        domain: 'all',
        program: 'all',
        patient: patientId,
        status: 'active',
        sessionDate: date,
        getOrder: true,
        appliesMaintenanceSetting: true,
        timeZoneOffset: new Date().getTimezoneOffset(),
      })

      this.addEventListeners('SESSION_TARGETS_READY', (data) => {
        console.log('getSessionTarget ready xoxo -----', data)
        if (!data.success) {
          httpErrorHandling(data.error)
          reject(data.error)
        }
        resolve(data)
      })
    })
  },


  connect(token) {
    console.log("SocketService::connect()");
    return new Promise((resolve, reject) => {
      // If we are already connected, we should close the current connection
      // We should be careful with the connection, we only need to connect when we are authenticated
      // and everytime we refresh the token
      if (this.connection) {
        this.connection.close();
        this.connection = null;
        this.callbacks = [];
      }

      this.connection = io(
        process.env.REACT_APP_CHAT_SOCKET_URL,
        {
          query: {
            token
          },
          pingTimeout: 2000,
          reconnection: true,
          reconnectionDelay: 500,
          reconnectionDelayMax : 2000,
          reconnectionAttempts: Infinity,
          path: '/api/socket',
          allowUpgrades: false,
        }
      )

      this.addEventListeners("connect", function socketConnect() {
        console.log("SocketService: connected successfully.");
        this.handleConnect();
        resolve();
      }.bind(this));

      this.addEventListeners("disconnect", function socketDisconnect(reason) {
        console.log("SocketService: disconnected.", reason);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("connect_error", function socketConnectError(error) {
        console.log(`SocketService: connection_error `, error);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("connect_timeout", function socketConnectTimeout(timeout) {
        console.log(`SocketService: connect_timeout `, timeout);
        this.handleDisconnect();
      }.bind(this));

      this.addEventListeners("reconnect", function socketReconnect(attemptNumber) {
        console.log(`SocketService: Trying to reconnect with attemptNumber=`, attemptNumber);
      });

      this.addEventListeners("reconnect_error", function socketReconnectError(error) {
        console.log(`SocketService: reconnect_error `, error);
      });
    });
  },

  disconnect() {
    console.log(`SocketService::disconnect()`);
    if (!this.connection) {
      return;
    }

    this.connection.close();
    this.connection = null;
    this.callbacks = [];
  },

  handleConnect() {
    console.log('SocketService:Socket connected!')
    store.dispatch(setIsOnline(true))
  },

  /**
   * This method should be used to dispatch any needed action when the socket is disconnected
   * At the moment is void since don't do any action when the socket is disconnected
   */
  handleDisconnect() {
    if(this.pingInterval) {
      clearInterval(this.pingInterval);
    }
    console.log(`SocketService::handleDisconnect()`);
    store.dispatch(setIsOnline(false))
  },

  send(name, data) {
    return this.connection.emit(name, data);
  },

  listenSessionTargets() {
    
  },

  addEventListeners(name, handler) {
    console.log("SocketService::addEventListeners()", { name });
    if (this.callbacks.includes(name)) {
      console.log(`SocketService::addEventListeners(). Handler for the ${name} event exits.`);
      return;
    }

    this.callbacks.push(name);
    console.log(`SocketService::addEventListeners(). Listening for ${name} events.`);
    this.connection.on(name, handler);
  },

  removeEventListener(name) {
    const indx = this.callbacks.findIndex(x => x === name);
    this.callbacks.splice(indx, 1);
    console.log(`SocketService::removeEventListeners(). Handler for the ${name} event removed.`);
    this.connection.off(name, () => {});
  }
}
