import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Button, InputLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import Edit from "@material-ui/icons/Edit";
import TargetPopup from "./TargetPopup";
import { getUnixTime } from "date-fns";
import PhaseColoredButton from "./PhaseColoredButton";
import { KEY_PROBE_SAVE } from "../../../../utils/sessionHistoryRecordParser";

const useStyles = makeStyles((theme) => ({
    probeButton: {
        borderRadius: 0,
        marginRight: 10,
        width: "100%",
    },
    selectedButton: {
        backgroundColor: "blue",
        "&:hover": {
            backgroundColor: "blue",
        },
    },
    container: {
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    setHolder: {
        display: "flex",
        flexDirection: "column",
    },
    set: {
        paddingBottom: 10,
        paddintTop: 10,
        display: "flex",
        flexDirection: "column",
    },
    codeHolder: {
        display: "flex",
        maxWidth: 600,
        overflowX: "auto",
    },
    setValue: {
        marginBottom: 5,
        fontWeight: "bold",
    },
}));

const Probe = (props) => {
    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState(false);
    const [selection, setSelection] = useState({});
    const [error, setError] = useState('');

    const fetchEmptySelection = () => {
        const sets = {};
        props.target.customData.sets.forEach((set) => {
            sets[set.value] = null;
        });
        return sets;
    };

    const isSaveDisabled = () => {
        let disabled = true;
        for (let key in selection) {
            if (selection[key]) {
                disabled = false;
            }
        }
        return disabled;
    };

    const isResponseAllowed = useMemo(() => {
        const maxTrialNo = props.target.maximumNumberTrials;
        if (!!maxTrialNo && !isNaN(maxTrialNo)) {
            return props.sessionData.length < maxTrialNo;
        }
        return true;
    }, [props.sessionData?.length]);

    useEffect(() => {
        if (props.target.customData) {
            setSelection(fetchEmptySelection());
        }
    }, [props.target]);

    useEffect(() => {
        if (error) {
            setError(getErrorMessage());
        }
    }, [selection]);

    useEffect(() => {
        if (dialogOpen && props.sessionData.length) {
            const tempData = props.sessionData[props.sessionData.length - 1];
            if (tempData?.data?.type === 'close') {
                setSelection(tempData?.data?.data ?? fetchEmptySelection());
            }
        }
    }, [dialogOpen]);

    const setSelectedCode = (set, code) => {
        if (selection[set.value]?.id !== code.id) {
            setSelection({
                ...selection,
                [set.value]: {
                    set: set.id,
                    id: code.id,
                    time: getUnixTime(new Date()),
                },
            });
        } else {
            setSelection({ ...selection, [set.value]: null });
        }
    };

    const getErrorMessage = () => {
        let error = '';
        props.target.customData.sets.forEach((set) => {
            if (!set.isDeleted && set.isRequired && !selection[set.value]) {
                error += set.value + ', ';
            }
        });
        return error ? 'Please input entry for ' + error.substring(0, error.length - 2) : '';
    }

    const emptyTempData = () => {
        const tempData = props.sessionData[props.sessionData.length - 1];
        if (tempData?.data?.type === 'close') {
            props.onDeleteSessionData(tempData.sessionId, tempData.id);
        }
    }

    const saveHandler = () => {
        const error = getErrorMessage();
        if (error) {
            setError(error);
            return false;
        }
        const data = [];
        for (let key in selection) {
            if (selection[key]) {
                data.push(selection[key]);
            }
        }
        emptyTempData();
        const sessionData = [data, getUnixTime(new Date())];
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {type: 'save', data: sessionData},
            { key: KEY_PROBE_SAVE, data: selection }
        );
        setDialogOpen(false);
        setSelection(fetchEmptySelection());
    };

    const cancelHandler = () => {
        emptyTempData();
        setDialogOpen(false)
        setSelection(fetchEmptySelection());
    }

    const closeHandler = () => {
        emptyTempData();
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            {type: 'close', data: selection},
            false
        );
        setDialogOpen(false);
        setSelection(fetchEmptySelection());
    }

    const responseCounter = (sessionData) => {
        return sessionData.filter((e) => e.data.type === "save").length;
    };

    return (
        <Wrapper {...props} responseCounter={responseCounter}>
            <div className={classes.container}>
                <PhaseColoredButton
                    onClick={() => setDialogOpen(true)}
                    className={classes.probeButton}
                    disabled={!isResponseAllowed}
                    phase={props.target?.phase}
                >
                    <Edit />
                </PhaseColoredButton>
            </div>
            <TargetPopup
                open={dialogOpen}
                onSave={saveHandler}
                saveDisabled={isSaveDisabled()}
                onCancel={cancelHandler}
                onClose={closeHandler}
                title={props.target.name}
            >
                <div className={classes.setHolder}>
                    {props.target.customData.sets.filter(e => !e.isDeleted).map((set) => (
                        <div className={classes.set}>
                            <InputLabel className={classes.setValue} required={set.isRequired}>{set.value}</InputLabel>
                            <div className={classes.codeHolder}>
                                {props.target.customData.codes.filter(e => !e.isDeleted).map((code) => (
                                    <Button
                                        onClick={() => setSelectedCode(set, code)}
                                        className={`${classes.probeButton} ${selection[set.value]?.id === code.id
                                                ? classes.selectedButton
                                                : ""
                                            }`}
                                    >
                                        {code.code}
                                    </Button>
                                ))}
                            </div>
                        </div>
                    ))}
                    <FormHelperText error={!!error}>
                        {error}
                    </FormHelperText>
                </div>
            </TargetPopup>
        </Wrapper>
    );
};

Probe.propTypes = {
    sessionData: PropTypes.array.isRequired,
    sessionNotes: PropTypes.array.isRequired,
    sessionId: PropTypes.number.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
    onDeleteSessionData: PropTypes.func.isRequired,
};

export default Probe;
