import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	ResponsiveContainer, LineChart, Line, LabelList, Tooltip
} from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
	dateAxisSetting, defaultAxisSetting, getColor, graphHeight, defaultLineSetting,
	defaultLineChartSetting, toDateFull, renderCustomizedValue, defaultTooltipSetting,
	getMinMaxDate, generateDomainTicks, splitGraphDataByPhases, getTargetColor, generateTargetName,
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const durationFormatter = (seconds) => {
	return `${Math.floor(seconds / 60)}m ${Math.floor(seconds % 60)}s`;
}

export const durationFormatter2 = (val) => {
	const seconds = Math.floor(val); // undo the effect of the dotMultiplier
	return seconds >= 60 ?
		`${Math.floor(seconds / 60)}m ${Math.floor(seconds % 60)}s`
		:
		`${seconds}s`
}

const DurationGraph = (props) => {
	const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
	const [disabledLines, setDisabledLines] = useState({});
	const countTotalData = props.graphData.length;
	const height = graphHeight + Math.floor(countTotalData / 7) * 25;
	const valueToUse = props.useCumulative ? 'cumulative' : 'value';

	const formattedPayload = useMemo(() => {
		const payload = [];
		props.graphData.forEach((target, index) => {
			target.targetData
				.filter(e => dateToDays(e.date) === hoveredDate)
				.forEach((targetData) => {
					if (!disabledLines[`${target.name}: total`]) {
						payload.push({
							date: hoveredDate,
							color: getColor(index, 'light'),
							name: `${target.name}: total`,
							value: targetData[valueToUse].total,
						});
					}
					if (!disabledLines[`${target.name}: average`] && !props.useCumulative) {
						payload.push({
							date: hoveredDate,
							color: getColor(index, 'dark'),
							name: `${target.name}: average`,
							value: targetData.value.average || 0,
						});
					}
				})
		})
		return payload;
	}, [hoveredDate, props.graphData])

	const { graphDomain, graphTicks } = useMemo(() => {
		let { graphDomain, graphTicks } = props;
		if (!graphDomain.length || !graphTicks.length) {
			const { minDate, maxDate } = getMinMaxDate(props.graphData);
			const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
			graphDomain = domain;
			graphTicks = ticks;
		}
		return { graphDomain, graphTicks };
	}, [props.graphDomain, props.graphTicks, props.graphData]);

	const formattedGraphData = useMemo(() => {
		const formattedData = [];
		const dateValueMap = {};
		let maxValue = 0;

		props.graphData.forEach(target => {
			const newTarget = clonedeep(target);
			newTarget.targetData.length > 0 && newTarget.targetData.forEach(targetData => {
				const date = dateToDays(targetData.date);
				const valueTotal = targetData[valueToUse].total;
				if (valueTotal > maxValue) {
					maxValue = valueTotal;
				}

				const dateValueTotal = `${date}-${valueTotal}`;
				dateValueMap[dateValueTotal] = (dateValueMap[dateValueTotal] ?? -1) + 1;

				targetData.value.total = {
					value: valueTotal,
					sameDot: dateValueMap[dateValueTotal]
				}

				const valueAvg = targetData.value.average;

				const dateValueAvg = `${date}-${valueAvg}`;
				dateValueMap[dateValueAvg] = (dateValueMap[dateValueAvg] ?? -1) + 1;

				targetData.value.average = {
					value: valueAvg,
					sameDot: dateValueMap[dateValueAvg]
				}
			})
			formattedData.push(newTarget);
		})

		const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
		formattedData.forEach(target => {
			target.targetData.length > 0 && target.targetData.forEach(targetData => {
				const valueObjTotal = targetData.value.total;
				targetData.value.total = valueObjTotal.value + (valueObjTotal.sameDot * dotMultiplier);

				const valueObjAvg = targetData.value.average;
				targetData.value.average = valueObjAvg.value + (valueObjAvg.sameDot * dotMultiplier);
			})
		})

		return formattedData;
	}, [props.graphData, props.useCumulative])

	const graphData = useMemo(() => {
		if (!props.showPhase) {
			return formattedGraphData;
		} else {
			return splitGraphDataByPhases(formattedGraphData);
		}
	}, [formattedGraphData, props.showPhase])

	return (
		<ResponsiveContainer height={height}>
			<LineChart {...defaultLineChartSetting}>
				{DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
				{xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
				{yAxis('value', { ...defaultAxisSetting, tickFormatter: durationFormatter }, 'Time', !!props.graphElements.axisLabelsY, -10)}
				{(enableTooltip && !props.isDownloading) &&
					<Tooltip
						{...defaultTooltipSetting}
						content={
							<CustomTooltip
								labelFormatter={toDateFull}
								valueFormatter={(value) => durationFormatter2(value)}
								formattedPayload={formattedPayload}
								setHoveredDate={setHoveredDate}
							/>
						}
					/>
				}
				{graphData.map((target, index) => (
					<Line
						{...defaultLineSetting}
						stroke={getTargetColor(target, index, 'light')}
						dataKey="value"
						name={generateTargetName(target, index, ': total')}
						key={generateTargetName(target, index, ': total')}
						isAnimationActive={enableAnimation}
						data={!disabledLines[`${target.name}: total`] ?
							target.targetData.map(e => (
								{ date: dateToDays(e.date), value: e.value.total }
							)) : []
						}
					>
						{props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: total`)} />}
						{props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, durationFormatter2)} />}
					</Line>
				))}
				{!props.useCumulative && graphData.map((target, index) => (
					<Line
						{...defaultLineSetting}
						stroke={getTargetColor(target, index, 'dark')}
						dataKey="value"
						name={generateTargetName(target, index, ': average')}
						key={generateTargetName(target, index, ': average')}
						isAnimationActive={enableAnimation}
						data={!disabledLines[`${target.name}: average`] ?
							target.targetData.map(e => (
								{ date: dateToDays(e.date), value: e.value.average }
							)) : []
						}
					>
						{props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: average`)} />}
						{props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, durationFormatter2)} />}
					</Line>
				))}
			</LineChart>
		</ResponsiveContainer>
	)
}

DurationGraph.propTypes = {
	graphData: PropTypes.array.isRequired,
	graphElements: PropTypes.object.isRequired,
	graphDomain: PropTypes.array.isRequired,
	graphTicks: PropTypes.array.isRequired,
	showPhase: PropTypes.bool.isRequired,
	isDownloading: PropTypes.bool.isRequired,
	useCumulative: PropTypes.bool,
};

export default DurationGraph;
