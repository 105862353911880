import agent from './agent';

export const getSites = (sortBy, sortAs, skip, limit) => {
    return agent.get('/branch', {
        params: {
            sortBy,
            sortAs,
            skip,
            limit,
        }
    });
}

export const postSite = (data) => {
    return agent.post('/branch/create', data);
}

export const updateSite = (data) => {
    return agent.put('/branch/update', data);
}

export const deleteSite = (branchId) => {
    return agent.delete('/branch/delete', {
        params: {
            id: branchId,
        },
    });
}

export const getSitesDropdown = () => {
    return agent.get('/branch/public', {
        params: {
            projection: 'id,name',
        }
    });
}