const modifiedFlagExists = (dataArray) => {
    return dataArray.find(e => e.preModifiedVersion);
}

const removePreModifiedVersions = (dataArray) => {
    return dataArray.map(e => {
        const { preModifiedVersion, ...rest } = e;
        return rest;
    });
}

const deleteModifiedAndAddNewCopy = (dataArray) => {
    const adjustedData = [];
    dataArray.forEach(item => {
        if (item.preModifiedVersion && !item.isDeleted) {
            adjustedData.push({ ...item.preModifiedVersion, isDeleted: true });
            const { id, preModifiedVersion, ...rest } = item;
            adjustedData.push(rest);
        } else if (item.preModifiedVersion && item.isDeleted) {
            const { preModifiedVersion, ...rest } = item;
            adjustedData.push(rest);
        } else {
            adjustedData.push(item);
        }
    });
    return adjustedData;
}

const assignIdsIfNotExists = (customData) => {
    if (Array.isArray(customData)) {
        const maxId = customData.reduce((maxId, val) => (!val.id || maxId > val.id) ? maxId : val.id, 0);
        let idToAssign = maxId + 1;
        customData.forEach((item) => {
            if (!item.id) {
                item.id = idToAssign;
                idToAssign++;
            }
        });
    }
    return customData;
}

const updateCustomData = (data, callback) => {
    if ((data.dataType === 'prompt' || data.dataType === 'interval') && Array.isArray(data.customData?.codes)) {
        data.customData.codes = callback(data.customData.codes);
    } else if (data.dataType === 'text') {
        data.customData = callback(data.customData);
    } else if (data.dataType === 'probe') {
        data.customData.codes = callback(data.customData.codes);
        data.customData.sets = callback(data.customData.sets);
    } else if (data.dataType === 'rating') {
        data.customData = callback(data.customData).map(scale => {
            if (scale.ratings) {
                return { ...scale, ratings: callback(scale.ratings) };
            }
            return scale;
        });
    } else if (data.dataType === 'category') {
        data.customData = callback(data.customData).map(category => {
            if (category.responses) {
                return { ...category, responses: callback(category.responses) };
            }
            return category;
        });
    }
    return data;
}

export const assignIdsAsCategoryResponseValues = (data) => {
    const customData = data.customData.map(category => {
        return { 
            ...category, responses: category.responses.map(response => {
                return { ...response, value: response.id };
            }) 
        };
    });
    return { ...data, customData };
}

export const assignIdsToCustomData = (data) => {
    let filteredData = updateCustomData(data, (items) => items?.filter(e => e.id || !e.isDeleted));
    filteredData = updateCustomData(filteredData, assignIdsIfNotExists);
    if (data.dataType === 'category') {
        filteredData = assignIdsAsCategoryResponseValues(filteredData);
    }
    return filteredData;
}

export const adjustDataForOnlyNewModification = (data) => {
    return updateCustomData(data, deleteModifiedAndAddNewCopy);
}

export const adjustDataForNewAndHistoricalModification = (data) => {
    return updateCustomData(data, removePreModifiedVersions);
}

export const hasModifiedCustomData = (data) => {
    if ((data.dataType === 'prompt' || data.dataType === 'interval') && Array.isArray(data.customData?.codes)) {
        return modifiedFlagExists(data.customData.codes);
    } else if (data.dataType === 'text') {
        return modifiedFlagExists(data.customData);
    } else if (data.dataType === 'probe') {
        return modifiedFlagExists(data.customData.codes) || modifiedFlagExists(data.customData.sets);
    } else if (data.dataType === 'rating') {
        if (modifiedFlagExists(data.customData)) {
            return true;
        }
        return data.customData.reduce((hasFlags, scale) => {
            return hasFlags || (scale.ratings ? modifiedFlagExists(scale.ratings) : false);
        }, false);
    } else if (data.dataType === 'category') {
        if (modifiedFlagExists(data.customData)) {
            return true;
        }
        return data.customData.reduce((hasFlags, category) => {
            return hasFlags || (category.responses ? modifiedFlagExists(category.responses) : false);
        }, false);
    }
    return false;
};