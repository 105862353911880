import agent from './agent';

export const searchDevice = (keyword, { sortBy, sortType }) => {
    return agent.post('/user-device/search', {
        keyword,
        sortBy,
        sortType: sortType.toUpperCase(),
    });
};

export const deleteDevice = (deviceUID, userId) => {
    return agent.post('/user-device/delete', {
        deviceUID,
        userId,
    });
};
