import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    InputLabel,
    TextField,
    Grid,
    IconButton,
    Tooltip,
    Switch,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CloseIcon from '@material-ui/icons/Close';
import cloneDeep from 'lodash.clonedeep';
import MaskedInput from 'react-text-mask';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

export const defaultInterval = {
    duration: '   minutes    seconds', // TODO:: change format to integer
    schedule: '   minutes    seconds', // TODO: change format to integer
    codes: [{ correctness: true, code: '', codeDesc: '' }], // TODO:: Format {"correctness": true,"code": "ABC","codeDesc": "Alpha Beta Correction"}
};

const defaultCode = { correctness: true, code: '', codeDesc: '' };

const TextMaskDuration = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                ' ',
                'h',
                'o',
                'u',
                'r',
                's',
                ' ',
                /[0-5]/,
                /\d/,
                ' ',
                'm',
                'i',
                'n',
                'u',
                't',
                'e',
                's',
            ]}
            placeholderChar={'_'}
            showMask
        />
    );
};

const TextMaskSchedule = (props) => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref) => {
            inputRef(ref ? ref.inputElement : null);
        }}
        mask={[
            /\d/,
            /\d/,
            ' ',
            'm',
            'i',
            'n',
            'u',
            't',
            'e',
            's',
            ' ',
            /[0-5]/,
            /\d/,
            ' ',
            's',
            'e',
            'c',
            'o',
            'n',
            'd',
            's',
        ]}
        placeholderChar={'_'}
        showMask
      />
    );
};

TextMaskDuration.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

TextMaskSchedule.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

export const convertScheduleMaskedToSeconds = (masked) => {
    const numbers = masked.split(' ');
    return parseInt(numbers[0], 10) * 60 + parseInt(numbers[2], 10);
};

export const convertDurationMaskedToSeconds = (masked) => {
    const numbers = masked.split(' ');
    return parseInt(numbers[0], 10) * 3600 + parseInt(numbers[2], 10) * 60;
};

const IntervalData = (props) => {
    const classes = useTargetDataStyles();
    const { interval, setInterval, itemError, error, setSnackbar, disabled, isMobile } = props;
    const [newCodeList, setNewCodeList] = useState([]);

    const handleChangeCodes = (field, id) => (event) => {
        const newInterval = { ...interval };
        if (field === 'code' && event.target.value.length > 4) {
            return;
        }
        if (field === 'code' && newInterval.codes[id].id && !newInterval.codes[id].preModifiedVersion) {
            newInterval.codes[id].preModifiedVersion = { ...newInterval.codes[id] };
        }
        newInterval.codes[id][field] =
            field === 'correctness' ? event.target.checked : event.target.value;
        setInterval(newInterval);
    };

    const addCode = () => {
        const newInterval = { ...interval };
        newInterval.codes.push({ ...defaultCode });
        if (newInterval.codes.length === 8) {
            setSnackbar('warning', 'Maximum 8 codes allowed!');
        }
        setInterval(newInterval);
        setNewCodeList([...newCodeList, newInterval.codes.length - 1]);
    };

    const deleteCodeById = (id) => () => {
        const newCodes = [...interval.codes];
        newCodes[id] = { ...newCodes[id], isDeleted: true };

        setInterval({
            ...interval,
            codes: newCodes,
        });
        setNewCodeList(newCodeList.filter(c => c !== id));
    };

    const trimmedCodes = useMemo(() => {
        return interval.codes.map((e) => e.code.trim());
    }, [interval.codes]);

    const checkCodesError = (value) => {
        if (!value.code.trim()) {
            return "Code can't be empty";
        }
        if (checkDuplicates(trimmedCodes, value.code.trim())) {
            return 'Code must be unique';
        }
        return ' ';
    };

    //   const handleChangeIntervalAcronyms = (id) => (event) => {
    //     const newInterval = cloneDeep(interval);
    //     if (event.target.value.length > 4) {
    //       return;
    //     }
    //     newInterval.codes[id] = event.target.value;
    //     setInterval(newInterval);
    //   };

    //   const addIntervalAcronyms = () => {
    //     const acronyms = interval.acronyms.slice();
    //     acronyms.push(defaultInterval.acronyms[0]);
    //     setInterval({ ...interval, acronyms });
    //     if (acronyms.length === 8) {
    //       setSnackbar('warning', 'Maximum 8 acronyms allowed!');
    //     }
    //   };

    //   const handleDeleteIntervalAcronym = (id) => () => {
    //     const acronyms = interval.acronyms.slice();
    //     acronyms.splice(id, 1);
    //     setInterval({ ...interval, acronyms });
    //   };

    const handleChangeInterval = (field) => (event) => {
        const newInterval = cloneDeep(interval);
        newInterval[field] = event.target.value;
        setInterval(newInterval);
    };

    const handleBlurInterval = (field) => (event) => {
        const newInterval = cloneDeep(interval);
        const numbers = event.target.value.split(' ');

        if (numbers[0][0] === '_' && numbers[0][1] !== '_') {
            numbers[0] = `0${numbers[0][1]}`;
        } else if (numbers[0][0] !== '_' && numbers[0][1] === '_') {
            numbers[0] = `0${numbers[0][0]}`;
        } else if (numbers[0] === '__') {
            numbers[0] = '00';
        }

        if (numbers[2][0] === '_' && numbers[2][1] !== '_') {
            numbers[2] = `0${numbers[2][1]}`;
        } else if (numbers[2][0] !== '_' && numbers[2][1] === '_') {
            numbers[2] = `0${numbers[2][0]}`;
        } else if (numbers[2] === '__') {
            numbers[2] = '00';
        }

        const value = numbers.join(' ');

        interval[field] = value;
        setInterval(newInterval);
    };

    //   const intervalAcronyms = useMemo(() => {
    //     return interval.acronyms.map((e) => e.trim());
    //   }, [interval.acronyms]);

    //   const checkAcronymError = (value) => {
    //     if (!value.trim()) {
    //       return "Entry code can't be empty";
    //     }
    //     if (checkDuplicates(intervalAcronyms, value.trim())) {
    //       return 'Entry code must be unique';
    //     }
    //     return ' ';
    //   };

    const duration = convertDurationMaskedToSeconds(interval.duration);
    const schedule = convertScheduleMaskedToSeconds(interval.schedule);
    const durationError = error && (duration === 0 || isNaN(duration));
    const scheduleError = error && (schedule === 0 || isNaN(schedule));
    const scheduleError2 = error && schedule > duration;

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <p id="target-form-interval-section-heading" className={classes.wrapperTitle}>Interval Data</p>
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} className={classes.leftDataWrapper}>
                <InputLabel id="target-form-interval-total-duration-field-label" required>Total Duration</InputLabel>
                <TextField
                    id="target-form-interval-total-duration-field"
                    variant='outlined'
                    className={classes.input}
                    error={durationError}
                    helperText={durationError ? "Total duration can't be empty" : ' '}
                    InputProps={{
                        onChange: handleChangeInterval('duration'),
                        onBlur: handleBlurInterval('duration'),
                        value: interval.duration,
                        inputComponent: TextMaskDuration,
                    }}
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs={isMobile ? 12 : 6} className={classes.rightDataWrapper}>
                <InputLabel id="target-form-interval-schedule-field-label" required>Take Data Every</InputLabel>
                <TextField
                    id="target-form-interval-schedule-field"
                    variant='outlined'
                    className={classes.input}
                    error={scheduleError || scheduleError2}
                    helperText={
                        scheduleError
                            ? "Can't be empty"
                            : scheduleError2
                            ? 'Must be below or equal to total duration'
                            : ' '
                    }
                    InputProps={{
                        onChange: handleChangeInterval('schedule'),
                        onBlur: handleBlurInterval('schedule'),
                        value: interval.schedule,
                        inputComponent: TextMaskSchedule,
                    }}
                    disabled={disabled}
                />
            </Grid>
            {interval.codes.map((code, index) => {
                const entryError = itemError[`interval ${index}`] && checkCodesError(code);
                return (
                    <Grid
                        item
                        xs={isMobile ? 12 : 6}
                        container
                        key={`acro${index}`}
                        className={classes.dataRowWrapper}
                    >
                        <Grid item xs={3} style={{ paddingLeft: 10, position: 'relative' }}>
                            <InputLabel id={`target-form-interval-plus-minus-field-label-${index}`} className={classes.plusMinusLabel}>
                                <span>+/-</span>
                                <Tooltip title='This column defines whether a response is considered a correct or incorrect response.'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </InputLabel>
                            <Switch
                                id={`target-form-interval-plus-minus-field-${index}`}
                                onChange={handleChangeCodes('correctness', index)}
                                checked={interval.codes[index]?.correctness}
                                classes={{
                                    track: interval.codes[index]?.correctness
                                        ? classes.plusTrack
                                        : classes.minusTrack,
                                    switchBase: interval.codes[index]?.correctness
                                        ? classes.plusThumb
                                        : classes.minusThumb,
                                }}
                                icon={<RemoveCircleIcon className={classes.switchIcon} />}
                                checkedIcon={<AddCircleIcon className={classes.switchIcon} />}
                                disabled={disabled && !newCodeList.includes(index)}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <InputLabel id={`target-form-interval-code-field-label-${index}`} required>Code {index + 1}</InputLabel>
                            <TextField
                                id={`target-form-interval-code-field-${index}`}
                                variant='outlined'
                                value={interval.codes[index].code}
                                onChange={handleChangeCodes('code', index)}
                                style={{ width: '80%' }}
                                error={!!entryError && entryError !== ' '}
                                helperText={entryError}
                                disabled={disabled && !newCodeList.includes(index)}
                            />
                        </Grid>
                        <Grid item xs={6} style={{ whiteSpace: 'nowrap'}}>
                            <InputLabel id={`target-form-interval-description-field-label-${index}`} required>Code Description {index + 1}</InputLabel>
                            <TextField
                                id={`target-form-interval-description-field-${index}`}
                                variant='outlined'
                                value={interval.codes[index].codeDesc}
                                onChange={handleChangeCodes('codeDesc', index)}
                                style={{ width: '75%' }}
                                error={
                                    itemError[`interval ${index}`] &&
                                    !interval.codes[index].codeDesc.trim()
                                }
                                helperText={
                                    itemError[`interval ${index}`] &&
                                    !interval.codes[index].codeDesc.trim()
                                        ? "Description can't be empty"
                                        : ' '
                                }
                                disabled={disabled && !newCodeList.includes(index)}
                            />
                            {interval.codes.length > 1 ? (
                                <IconButton
                                    id={`target-form-interval-section-delete-${index}`}
                                    disabled={disabled && !newCodeList.includes(index)}
                                    className={classes.deleteBtn}
                                    onClick={deleteCodeById(index)}
                                    tabIndex={-1}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </Grid>
                    </Grid>
                );
            })}
            {!isMobile && interval.codes.length % 2 === 0 && <Grid item xs={6} />}
            {interval.codes.length < 8 && (
                <Grid
                    item
                    xs={isMobile ? 12 : 6}
                    className={classes.addMoreWrapper}
                    container
                    alignItems='center'
                    justify='center'
                >
                    <Button id="target-form-interval-add-more" className={classes.saveBtn} onClick={addCode}>
                        Add More
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

IntervalData.propTypes = {
    interval: PropTypes.object.isRequired,
    setInterval: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    error: PropTypes.bool.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, { setSnackbar })(IntervalData);
