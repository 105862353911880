import React, { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import domToImage from 'dom-to-image';
import { saveAs } from 'file-saver';
import debounce from 'awesome-debounce-promise';
import SimpleBar from 'simplebar-react';
import RefreshIcon from '@material-ui/icons/Refresh';

import { setLoading } from '../../store/general/actions';
import NoProgram from './NoProgram';
import { Box, CircularProgress } from '@material-ui/core';
import { access, statuses, localStorageEnum, dateRanges, sessionDataSyncInterval } from '../../constants';
import { getUsersByTargetData, getDataPageListByResourceId } from '../../services/TargetService';
import * as SettingService from '../../services/SettingService';
import DropdownButton from '../../assets/button/DropdownButton';
import FilterDropdownButton from '../../assets/button/FilterDropdownButton';
import ProgramWrapper from './ProgramWrapper';
import capitalize from '../../utils/capitalize';
import DateRangePicker from '../../assets/input/DateRangePicker';
import NotesDialog from './TargetData/NotesDialog';
import TextDialog from './TargetData/TextDialog';
import ImageVideoDialog from './TargetData/ImageVideoDialog';
import CategoryDialog from './TargetData/CategoryDialog';
import NewData from './NewData';
import Session from './Session/Session';
import { listDataSheet } from '../../services/DataSheetService';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import CanUser from '../CanUser';
import Button from '@material-ui/core/Button';
import formatData from '../../utils/formatResData';
import dateFormatter from '../../utils/dateFormatter';
import { isTargetDataAvailable } from '../../utils/targetDataAnalyser';
import ProgramSelect from '../ProgramSelect';
import { Tune } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
    card: {
        marginTop: 15,
        marginBottom: 15,
        border: '1px solid #D5D5D5',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
    },
    filterWrapper: {
        padding: '0px 20px',
    },
    filterWrapperMobile: {
        position: 'absolute',
        top: 50,
        right: 0,
        minWidth: 260,
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 5,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: 99,
    },
    dateWrapper: {
        paddingTop: 5,
        paddingLeft: 15,
    },
    dateInput: {
        color: '#53545E',
        padding: 10.5,
    },
    dateAdornment: {
        margin: 0,
        padding: 0,
    },
    clearIcon: {
        color: '#000000',
    },
    progressWrapper: {
        marginTop: 100,
        height: 400,
    },
    popoverPaper: {
        padding: 20,
    },
    labels: {
        textTransform: 'capitalize'
    },
    filter: {
        marginRight: 15,
        marginBottom: 10,
    },
    currentlyShowing: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 16,
        margin: 0,
    },
    noData: {
        textAlign: 'center',
        margin: 5,
        fontSize: 14,
        color: 'red',
    },
    root: {
        padding: '5px 0px',
    },
    hideFilters: {
        height: 34,
        width: 34,
        color: '#0FB99E',
        backgroundColor: '#ffffff',
        '&hover:': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    table: {
        borderCollapse: 'separate',
        backgroundColor: 'white',
        width: 'initial',
        paddingBottom: 7,
    },
    tableCell: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: 5,
    },
    tableHead: {
        border: '1px solid rgba(224, 224, 224, 1)',
        padding: 5,
        verticalAlign: 'middle',
    },
    stickyHeader: {
        '& th': {
            position: 'sticky',
            top: 0,
            backgroundColor: '#fff',
            zIndex: 2,
            border: '1px solid rgba(224, 224, 224, 1)',
        },
    },
    dateCell: {
        textAlign: 'center',
        minWidth: 0,
    },
    targetName: {
        position: 'sticky',
        left: 0,
        width: 200,
        minWidth: 200,
        backgroundColor: '#fff',
        zIndex: 3,
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    targetNameMobile: {
        textAlign: 'center',
        width: 200,
        minWidth: 200,
        backgroundColor: '#fff',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    notesCell: {
        position: 'sticky',
        left: 212,
        width: 15,
        minWidth: 15,
        backgroundColor: '#fff',
        zIndex: 3,
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    notesCellMobile: {
        textAlign: 'center',
        width: 15,
        minWidth: 15,
        backgroundColor: '#fff',
        border: '1px solid rgba(224, 224, 224, 1)',
    },
    filterButton: {
        marginRight: 10,
    },
    domainStatusWrapper: {
        backgroundColor: '#F4F4F4',
    },
    domainWrapper: {
        padding: '12px 0px 12px 30px',
    },
    currentDomain: {
        color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: 16,
    },
    btnExpand: {
        color: '#0FB99E',
        padding: 0,
        marginLeft: 5,
    },
    expandIcon: {
        fontSize: 32,
    },
    btnWrapper: {
        padding: '5px 0px 10px 30px',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
}));

const statusList = statuses.map((status, index) => ({ label: capitalize(status), value: index }));
const debouncedGetUsers = debounce(getUsersByTargetData, 500);

const Data = (props) => {
    const classes = useStyles();
    const { selection, programs, domains, targetLoading, loading,
        patient, setLoading, sidebarWidth, user, isMobile } = props;

    const [targetData, setTargetData] = useState([]);
    const [startDate, setStartDate] = useState(subDays(new Date(), 14));
    const [endDate, setEndDate] = useState(new Date());
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [userFilterUntouched, setUserFilterUntouched] = useState(false);
    const [forceAllSelected, setForceAllSelected] = useState(false);
    const [status, setStatus] = useState();
    const [dateRange, setDateRange] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [titleWidth, setTitleWidth] = useState(10); // to set sticky for notes
    const [shouldShowAll, setShouldShowAll] = useState(0);
    const [collapseTarget, setCollapseTarget] = useState({});
    const [notesState, setNotesState] = useState({ target: null, notes: null, });
    const [textState, setTextState] = useState({ customData: null, textData: null, name: null, });
    const [mediaData, setImageData] = useState({ mediaData: null, name: null, })
    const [categoryState, setCategoryState] = useState({ customData: null, tableData: null, name: null, })
    const [filter, setFilter] = useState(0);
    const [filterSet, setFilterSet] = useState(false);
    const [patientId, setPatientId] = useState(0);
    const [addingNewData, setAddingNewData] = useState(false);
    const [dataSheets, setDataSheets] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [showFilters, setShowFilters] = useState(false);
    const history = useHistory()

    const [customDate, setCustomDate] = useState({
        startDate: undefined,
        endDate: undefined,
    })
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const firstLoad = useRef(false);

    const scrollbarClasses = makeStyles(theme => ({
        tableWrapper: {
            margin: '10px 10px 0px 10px',
            overflow: 'auto',
            '& .simplebar-track': {
                position: 'fixed',
                bottom: '3px',
                left: isMobile ? '3px' : sidebarWidth,
                zIndex: 10,
                margin: '0px 8px',
                'pointer-events': 'auto'
            }
        },
    }))();

    const programsGroup = useMemo(() => {
        const ids = [];
        const newDataArray = [];
        targetData.forEach((data) => {
            if (!ids.includes(data.programId)) {
                ids.push(data.programId);
                newDataArray.push({
                    targets: [{ ...data }],
                    programId: data.programId,
                    domainId: parseInt(data.domainId, 10),
                });
            } else {
                const newData = newDataArray.find(e => e.programId === data.programId);
                newData.targets.push({ ...data });
            }
        });

        return newDataArray;
    }, [targetData])

    /**
     * Get all dates with data
     */
    const dates = useMemo(() => {
        let availableDates = [];

        programsGroup.forEach((program) => {
            program.targets.forEach((target) => {
                const targetData = target.targetData;
                if (targetData && targetData.length) {
                    targetData.forEach((data) => {
                        const date = new Date(data.date).setHours(0, 0, 0, 0);
                        if (isTargetDataAvailable(targetData) && !availableDates.find(e => e === date)) {
                            availableDates.push(date);
                        }
                    });
                }
            })
        })

        availableDates = availableDates.sort((a, b) => a - b);
        return availableDates;

    }, [programsGroup]);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'filter')
            .then((res) => {
                if (res.status === 200 && res.data?.data?.data) {
                    return setFilter(res.data?.data?.data);
                }
                setFilter([]);
            });
        let dataFilters = localStorage.getItem(localStorageEnum.dataFilters);
        let dateFilters = localStorage.getItem(localStorageEnum.dateFilters);
        if (dataFilters && dateFilters) {
            dataFilters = JSON.parse(dataFilters);
            dateFilters = JSON.parse(dateFilters);
            setStatus(dataFilters.status || status);
            setUsers(dataFilters.users || users);
            setSelectedUsers(dataFilters.selectedUsers || selectedUsers);
            setPatientId(dataFilters.patientId || patientId);
            setCustomDate(dateFilters.customDate || customDate);
            setUserFilterUntouched(dataFilters.userFilterUntouched ?? true);

            /**
             * if custom
             */
            if (dateFilters.dateRange === 6) {
                setCustomDateRange(dateFilters.customDate);
            }
            setDateRange(dateFilters.dateRange);
        } else {
            setDateRange(undefined);
            setUserFilterUntouched(true);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            syncData();
        }, sessionDataSyncInterval);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (filter === 0 || dateRange === 0 || filterSet) return;

        const defaultStatus = status || filter.status || 0;
        const defaultCustomDate = (customDate.startDate && customDate.endDate) ? customDate : filter.customDate;
        const selectedRange = dateRanges.find(e => e.value === dateRange);
        const defaultRange = selectedRange ? dateRange : filter?.dateRange || 1;
        const defautSelectedUsers = (selectedUsers === null && filter?.users?.id) ? [filter.users.id] : selectedUsers;

        const dataFilters = {
            status: defaultStatus,
            users,
            selectedUsers: defautSelectedUsers,
            userFilterUntouched
        };
        const dateFilters = {
            dateRange: defaultRange,
            customDate: defaultCustomDate
        };

        setStatus(defaultStatus);
        setCustomDate(defaultCustomDate);
        setDateRange(defaultRange);
        setSelectedUsers(defautSelectedUsers);

        /**
         * if custom
         */
        if (defaultRange === 6) {
            setCustomDateRange(defaultCustomDate);
        }

        localStorage.setItem(localStorageEnum.dataFilters, JSON.stringify(dataFilters));
        localStorage.setItem(localStorageEnum.dateFilters, JSON.stringify(dateFilters));

        setFilterSet(true);
    }, [filter, dateRange]);


    useEffect(() => {
        const dataFilters = {
            status,
            users,
            selectedUsers,
            patientId,
            userFilterUntouched
        };
        localStorage.setItem(localStorageEnum.dataFilters, JSON.stringify(dataFilters));
    }, [status, users, selectedUsers]);

    useEffect(() => {
        // filters hasn't finished loading here
        if (dateRange === 0) return;

        const dateFilters = { dateRange, customDate };
        localStorage.setItem(localStorageEnum.dateFilters, JSON.stringify(dateFilters));
    }, [dateRange, customDate]);

    useEffect(() => {
        firstLoad.current = false;
    }, [selection, patient])

    useEffect(() => {
        // Filters haven't set yet
        if (filter === 0 || !filterSet) return;

        if (patient && patient.id !== patientId) {
            const dataFilters = {
                status,
                users,
                selectedUsers,
                patientId: patient.id,
                userFilterUntouched
            };
            localStorage.setItem(localStorageEnum.dataFilters, JSON.stringify(dataFilters));
            setPatientId(patient.id);
        }

        /**
         * Only when there is a domain/program selected
         */
        if (selection.domain !== 'all' && patient?.id) {
            let resourceType = 'program';
            let resourceId = selection.program;
            if (selection.program === 'all') { // domain is selected
                resourceType = 'domain';
                resourceId = selection.domain;
            }
            if (selection.target) {
                resourceType = 'target';
                resourceId = selection.target;
            }
            debouncedGetUsers(resourceType, resourceId).then((res) => {
                const allUsersUnselected = !res.data.data.users.find(e => {
                    return selectedUsers === null || selectedUsers === 'all' || (selectedUsers?.length && selectedUsers.includes(e.id));
                });
                const defaultUserNotListed = !!filter?.users?.id && !res.data.data.users.find(e => e.id === filter?.users?.id);
                const forceAll = userFilterUntouched && allUsersUnselected && defaultUserNotListed;
                setForceAllSelected(forceAll);
                setUsers(res.data.data.users.map(e => {
                    return {
                        ...e,
                        checked: forceAll || selectedUsers === null || selectedUsers === 'all' || (selectedUsers?.length && selectedUsers.includes(e.id))
                    }
                }));
                firstLoad.current = true;
            });
        }
    }, [selection, patient, filterSet])

    useEffect(() => {
        if (selection.domain !== 'all' && firstLoad.current && !isAfter(startDate, endDate)) {
            fetchData();
        }
    }, [selection, users, startDate, endDate, status]);

    useEffect(() => {
        if (refreshCount && selection.domain !== 'all' && firstLoad.current && !isAfter(startDate, endDate)) {
            fetchData(false);
        }
    }, [refreshCount]);

    useEffect(() => {
        // filters hasn't finished loading here
        if (dateRange === 0) return;

        const selectedRange = dateRanges.find(e => e.value === dateRange);
        if (!selectedRange) return;

        let newStartDate = new Date();
        let newEndDate = new Date();
        if (selectedRange.days) newStartDate = subDays(newStartDate, selectedRange.days);
        if (selectedRange.months) newStartDate = subMonths(newStartDate, selectedRange.months);
        if (selectedRange.max) newStartDate = null;

        if (selectedRange.custom) {
            return;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }, [dateRange])

    useEffect(() => {
        /**
         * If custom date
         */
        if (dateRange === 6) {
            setCustomDate({ startDate, endDate });
        }
    }, [startDate, endDate]);

    useEffect(() => {
        if (patient?.id) {
            listDataSheet(patient.id).then((res) => {
                setDataSheets(res.data.data);
            })
        }
    }, [patient]);

    // get title width
    useLayoutEffect(() => {
        const titleCell = document.getElementById('title');
        if (titleCell) {
            setTitleWidth(titleCell.clientWidth);
        }
    });

    const fetchData = async (shouldLoad = true) => {
        // don't send 'all' as filter
        const filterStatus = statuses[status];
        const userIds = users.filter(e => e.checked).map(e => e.id);
        const filter = {
            status: filterStatus === 'all' ? statuses.filter(e => !['archived', 'all'].includes(e)) : [filterStatus],
            sessionDateStart: startDate ? dateFormatter(new Date(startDate.setHours(0, 0, 0, 0)).toJSON(), 'add') : null,
            sessionDateEnd: endDate ? dateFormatter(new Date(endDate.setHours(23, 59, 59, 999)).toJSON(), 'add') : null,
            userIds: userIds?.length ? userIds : [-1],
        };

        if (shouldLoad) {
            setLoading('getTargetData');
        } else {
            setLoading('getTargetDataSilent');
        }
        const query = {
            type: '',
            selection: 0,
            filter
        }

        if (selection.target) { // coming from target table's graph button
            query.type = 'target';
            query.selection = selection.target;
        }
        if (selection.program !== 'all') { // program is selected
            query.type = 'program';
            query.selection = selection.program;
        }
        if (selection.program === 'all') { // domain is selected
            query.type = 'domain';
            query.selection = selection.domain;
        }
        return getDataPageListByResourceId(query.type, query.selection, query.filter).then((res) => {
            setLoading('getTargetData', false);
            setLoading('getTargetDataSilent', false);
            const newData = formatData(res.data.data);
            setTargetData(newData);
            return newData;
        });
    }

    const setCustomDateRange = (customDateRange) => {
        if (customDateRange?.startDate) {
            setStartDate(new Date(customDateRange.startDate));
        } else {
            setStartDate(null);
        }
        if (customDateRange?.endDate) {
            setEndDate(new Date(customDateRange.endDate));
        } else {
            setEndDate(null);
        }
    }

    const handleChangeUser = id => event => {
        let allChecked = true;
        const newUsers = users.map(e => {
            let user = { ...e };
            if (e.id === id) {
                user.checked = !user.checked;
            }
            if (!user.checked) {
                allChecked = false;
            }
            return user;
        });
        let newSelectedUsers;
        if (allChecked) {
            newSelectedUsers = 'all';
        } else {
            newSelectedUsers = newUsers.filter(e => e.id && e.checked).map(e => e.id);
        }
        setUsers(newUsers);
        setSelectedUsers(newSelectedUsers);
        setUserFilterUntouched(false);
        setForceAllSelected(false);
    };

    const handleCheckAllUsers = () => {
        const selectedAllUsers = (selectedUsers === 'all' || selectedUsers === null);
        setUsers(users.map(user => ({ ...user, checked: !selectedAllUsers })));
        setSelectedUsers(!selectedAllUsers ? 'all' : []);
        setUserFilterUntouched(false);
        setForceAllSelected(false);
    }

    const syncData = () => {
        setRefreshCount(count => {
            return (count + 1);
        });
    }

    const handleDownload = () => {
        programsGroup.forEach(program => {
            setIsDownloading(true);
            const title = programs.find(e => e.id === program.programId)?.name || 'Unknown Program';
            domToImage.toBlob(document.getElementById(title))
                .then((blob) => {
                    saveAs(blob, `${title}.png`);
                    setIsDownloading(false);
                });
        });
    }

    // everytime a target is collapsed increase by 1
    const toggleCollapse = (targetId) => {
        setCollapseTarget({ ...collapseTarget, [targetId]: !collapseTarget[targetId] });
        setShouldShowAll(shouldShowAll + (!collapseTarget[targetId] ? 1 : -1));
    }

    const toggleCollapseAll = () => {
        const collapse = {};

        programsGroup.forEach(program => {
            program.targets.forEach(target => {
                collapse[target.id] = !shouldShowAll;
            });
        })
        setCollapseTarget(collapse);
        if (shouldShowAll) {
            setShouldShowAll(0);
        } else {
            setShouldShowAll(Object.values(collapse).length);
        }
    }

    const getCustomDateLabel = () => {
        if (dateRange !== 6) return undefined;
        const startDateValue = startDate ? format(startDate, 'MM/dd/yyyy') : 'First Entry';
        const endDateValue = endDate ? format(endDate, 'MM/dd/yyyy') : 'Today';
        return `Date Range: ${startDateValue} - ${endDateValue}`;
    }

    const handleDateRangeChange = event => {
        const { value } = event.target;
        setDateRange(value);
        if (value === 6) {
            setOpenDatePicker(true);
        }
    }

    const handleNotesClose = () => {
        setNotesState({ target: null, notes: null });
    }

    const toggleFilters = (e, forcedState) => {
        if (e) e.preventDefault();
        if (forcedState !== undefined) {
            return setShowFilters(forcedState);
        }
        setShowFilters(!showFilters);
    }

    const handleTextClose = () => {
        setTextState({ customData: null, textData: null, name: null });
    }

    const handleImageVideoClose = () => {
        setImageData({ mediaData: null, name: null });
    }

    const handleCategoryClose = () => {
        setCategoryState({ customData: null, tableData: null, name: null });
    }

    const noData = programsGroup.length === 0;
    const showLoading = loading.getTargetData || targetLoading || !firstLoad.current;
    const isSilentLoading = loading.getTargetDataSilent;
    let selectedUsersValue = 'All';
    const allUserCount = users?.length;
    const checkedUserCount = users?.filter(e => e.checked)?.length;
    if (allUserCount > 0 && allUserCount !== checkedUserCount) {
        selectedUsersValue = (checkedUserCount > 0) ? 'Some' : 'None';
    } else if (allUserCount === 0 && selectedUsers !== null && selectedUsers !== 'all') {
        selectedUsersValue = 'None';
    }
    return (
        <div className={classes.root}>
            {selection.domain !== 'all' ?
                <Grid container className={classes.graphWrapper}>
                    <Grid item xs={isMobile ? 6 : 3} className={classes.btnWrapper} container alignItems="center">
                        <CanUser
                            perform={access.data.add}
                            yes={() => (
                                <Button id="data-add" onClick={() => history.push('/data/collect')} variant="contained">New Data <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} alt='' /></Button>
                            )}
                        />
                    </Grid>
                    <Grid item xs={isMobile ? 6 : 9} container justify="flex-end" className={classes.filterWrapper}>
                        {isMobile ?
                            <Box style={{ position: 'relative' }}>
                                <TooltipedIconButton
                                    icon={<Tune />}
                                    onClick={toggleFilters}
                                    tooltip="Filters"
                                    className={classes.filterButton}
                                    id="data-page-filters-session-data"
                                />
                                {showFilters && <Grid item xs={12} className={classes.filterWrapperMobile}>
                                    <DropdownButton
                                        id="data-page-date"
                                        label="Date Range"
                                        selectedValue={dateRange}
                                        onValueChange={handleDateRangeChange}
                                        options={dateRanges}
                                        wrapperStyle={classes.filter}
                                        customLabel={getCustomDateLabel()}
                                    />
                                    <DropdownButton
                                        id="data-page-status"
                                        label="Status"
                                        selectedValue={status}
                                        onValueChange={(event) => setStatus(event.target.value)}
                                        options={statusList}
                                        wrapperStyle={classes.filter}
                                    />
                                    <FilterDropdownButton
                                        id="data-page-user"
                                        label={`Users: ${selectedUsersValue}`}
                                        filters={users.map((user) => ({ ...user, label: `${user.firstName} ${user.lastName}`, id: user.id }))}
                                        handleChangeFilter={(user) => handleChangeUser(user.id)}
                                        isAllChecked={selectedUsers === 'all' || selectedUsers === null || forceAllSelected}
                                        handleCheckAll={handleCheckAllUsers}
                                        wrapperStyle={classes.filter}
                                    />
                                </Grid>}
                            </Box>
                            :
                            <>
                                <DropdownButton
                                    id="data-page-date"
                                    label="Date Range"
                                    selectedValue={dateRange}
                                    onValueChange={handleDateRangeChange}
                                    options={dateRanges}
                                    wrapperStyle={classes.filter}
                                    customLabel={getCustomDateLabel()}
                                />
                                <DropdownButton
                                    id="data-page-status"
                                    label="Status"
                                    selectedValue={status}
                                    onValueChange={(event) => setStatus(event.target.value)}
                                    options={statusList}
                                    wrapperStyle={classes.filter}
                                />
                                <FilterDropdownButton
                                    id="data-page-user"
                                    label={`Users: ${selectedUsersValue}`}
                                    filters={users.map((user) => ({ ...user, label: `${user.firstName} ${user.lastName}`, id: user.id }))}
                                    handleChangeFilter={(user) => handleChangeUser(user.id)}
                                    isAllChecked={selectedUsers === 'all' || selectedUsers === null || forceAllSelected}
                                    handleCheckAll={handleCheckAllUsers}
                                    wrapperStyle={classes.filter}
                                />
                            </>}
                        <TooltipedIconButton
                            icon={<RefreshIcon />}
                            onClick={syncData}
                            tooltip="Sync Session Data"
                            className={classes.downloadButton}
                            id="data-page-sync-session-data"
                        />

                        {/* <CanUser
                            perform={access.data.uploadDownload}
                            yes={() => (
                                <TooltipedIconButton
                                    icon={<GetAppIcon />}
                                    disabled={showLoading || noData}
                                    onClick={handleDownload}
                                    tooltip="Download"
                                    className={classes.filter}
                                />
                            )}
                        /> */}
                    </Grid>

                    <Grid container className={classes.domainStatusWrapper}>
                        {isMobile ? <Grid item xs={12}>
                            <ProgramSelect />
                        </Grid> : <Grid item xs={12} className={classes.domainWrapper}>
                            <span className={classes.currentDomain}>
                                {selection.all ? 'All Domain' : domains.find(e => e.id === selection.domain)?.name || 'Unknown'}
                                {selection.program !== 'all'
                                    ? ` / ${programs.find(e => e.id === selection.program)?.name || 'Unknown'}`
                                    : ''
                                }
                            </span>
                        </Grid>}
                    </Grid>

                    {showLoading ?
                        <Grid className={classes.progressWrapper} container justify="center">
                            <CircularProgress size={300} />
                        </Grid>
                        :
                        <Grid item xs={12} container style={{ position: 'relative' }}>
                            {noData &&
                                <Grid item xs={12}>
                                    <p className={classes.noData}>No data to show for current filters.</p>
                                </Grid>
                            }
                            <Grid item xs={12} className={scrollbarClasses.tableWrapper}>
                                <SimpleBar autoHide={false}>
                                    <Table stickyHeader={!isMobile} className={classes.table} id={props.title}>
                                        <TableHead>
                                            <TableRow className={classes.stickyHeader}>
                                                <TableCell
                                                    id="title"
                                                    className={`${isMobile ? classes.targetNameMobile : classes.targetName} ${classes.tableHead}`}
                                                    align="left"
                                                    style={isMobile ? {} : { zIndex: 4 }}
                                                >
                                                    <IconButton style={{ visibility: isDownloading ? 'hidden' : 'visible' }} className={classes.btnExpand} onClick={toggleCollapseAll}>
                                                        {shouldShowAll ?
                                                            <ArrowDownIcon className={classes.expandIcon} />
                                                            :
                                                            <ArrowUpIcon className={classes.expandIcon} />
                                                        }
                                                    </IconButton>
                                                    Target
                                                </TableCell>
                                                <TableCell
                                                    className={`${isMobile ? classes.notesCellMobile : classes.notesCell} ${classes.tableHead}`}
                                                    align="center"
                                                    style={isMobile ? {} : { zIndex: 4 }}
                                                >
                                                    Notes
                                                </TableCell>
                                                {dates.map(date => {
                                                    const formattedDate = format(date, 'dd MMM');
                                                    return (
                                                        <TableCell
                                                            id={formattedDate}
                                                            className={`${classes.dateCell} ${classes.tableHead}`}
                                                            key={formattedDate}
                                                            align="center"
                                                        >
                                                            {formattedDate}
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        </TableHead>
                                        {programsGroup.map(program => {
                                            const currentProgram = programs.find(e => e.id === program.programId);
                                            const programName = currentProgram?.name || 'Unknown Program';
                                            const currentDomain = domains.find(e => e.id === currentProgram?.domainId);
                                            const domainName = currentDomain?.name || 'Unknown Domain';

                                            return (
                                                <ProgramWrapper
                                                    key={program.programId}
                                                    targets={program.targets}
                                                    isDownloading={isDownloading}
                                                    dates={dates}
                                                    programName={programName}
                                                    domainName={domainName}
                                                    collapseTarget={collapseTarget}
                                                    toggleCollapse={toggleCollapse}
                                                    setNotesState={setNotesState}
                                                    setTextState={setTextState}
                                                    setImageData={setImageData}
                                                    setCategoryState={setCategoryState}
                                                    fetchData={fetchData}
                                                    isSilentLoading={isSilentLoading}
                                                    isMobile={isMobile}
                                                />
                                            )
                                        })}
                                    </Table>
                                </SimpleBar>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                : isMobile ?
                    <>
                        <Grid container className={classes.domainStatusWrapper}>
                            <Grid item xs={12}>
                                <ProgramSelect />
                            </Grid>
                        </Grid>
                        <NoProgram isMobile={isMobile} />
                    </>
                    :
                    <NoProgram />}
            <DateRangePicker
                startDate={startDate}
                setStartDate={(date) => setStartDate(date)}
                endDate={endDate}
                setEndDate={(date) => setEndDate(date)}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
            />
            <NotesDialog
                open={!!notesState.target}
                handleClose={handleNotesClose}
                target={notesState.target}
                notes={notesState.notes}
                fetchData={fetchData}
            />
            <TextDialog
                open={!!textState.textData}
                handleClose={handleTextClose}
                textData={textState.textData}
                customData={textState.customData}
                name={textState.name}
                fetchData={fetchData}
            />
            <ImageVideoDialog
                open={!!mediaData.mediaData}
                handleClose={handleImageVideoClose}
                mediaData={mediaData.mediaData}
                name={mediaData.name}
                targetId={mediaData.targetId}
                fetchData={fetchData}
                getTargetDataByDate={mediaData.getTargetDataByDate}
                setImageData={setImageData}
            />
            <CategoryDialog
                open={!!categoryState.tableData}
                setCategoryState={setCategoryState}
                handleClose={handleCategoryClose}
                tableData={categoryState.tableData}
                customData={categoryState.customData}
                targetId={categoryState.targetId}
                name={categoryState.name}
                fetchData={fetchData}
            />
            {/* <NewData
                open={addingNewData}
                onClose={() => {setAddingNewData(false)}}
                onSessionStart={() => {setAddingNewData(false)}}
                onSessionEnd={fetchData}
                dataSheets={dataSheets}
            /> */}
            <Session onSessionEnd={() => selection.domain !== 'all' && fetchData()} />
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={dataPageSteps} /> */}
        </div>
    )
}

Data.propTypes = {
    selection: PropTypes.object.isRequired,
    programs: PropTypes.array.isRequired,
    domains: PropTypes.array.isRequired,
    targetLoading: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    targets: PropTypes.array.isRequired,
    sidebarWidth: PropTypes.number,
    setLoading: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    selection: state.general.selection,
    targets: state.data.targets,
    programs: state.data.programs,
    domains: state.data.domains,
    targetLoading: state.general.targetLoading,
    loading: state.general.loading,
    patient: state.patient.patient,
    sidebarWidth: state.general.sidebarWidth,
    user: state.auth.user,
    isMobile: state.general.isMobile,
})

export default connect(mapStateToProps, { setLoading })(Data);
