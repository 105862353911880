import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button,
    InputLabel,
    TextField,
    Grid,
    IconButton,
    Tooltip,
    Switch,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import cloneDeep from 'lodash.clonedeep';

import useTargetDataStyles from '../Program/TargetDataForm/TargetDataFormStyles';
import { setSnackbar } from '../../store/general/actions';
import checkDuplicates from '../../utils/checkDuplicates';

export const defaultPrompt = [{ acronym: '', name: '', isNegative: false }];

const PromptData = (props) => {
    const classes = useTargetDataStyles();
    const { prompts, setPrompts, setSnackbar, itemError, disabled } = props;

    const handleChangePrompt = (id, field) => (event) => {
        const newPrompts = prompts.slice();
        if (field === 'acronym' && event.target.value.length > 4) {
            return;
        }
        newPrompts[id][field] = event.target.value;
        setPrompts(newPrompts);
    };

    const handleChangePromptCorrectness = (id, value) => () => {
        const newPrompts = prompts.slice();
        newPrompts[id].isNegative = value;
        setPrompts(newPrompts);
    };

    const addPrompt = () => {
        const newPrompts = prompts.slice();
        newPrompts.push(cloneDeep(defaultPrompt[0]));
        setPrompts(newPrompts);
        if (newPrompts.length === 8) {
            setSnackbar('warning', 'Maximum 8 prompts allowed!');
        }
    };

    const handleDeletePrompt = (id) => () => {
        const newPrompts = prompts.slice();
        newPrompts.splice(id, 1);
        setPrompts(newPrompts);
    };

    const promptsAcronyms = useMemo(() => {
        return prompts.map((e) => e.acronym.trim());
    }, [prompts]);

    const checkAcronymError = (value) => {
        if (!value.trim()) {
            return "Code can't be empty";
        }
        if (checkDuplicates(promptsAcronyms, value.trim())) {
            return 'Code must be unique';
        }
        return ' ';
    };

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12}>
                {disabled ? (
                    <p className={classes.wrapperDisabled}>Data exists, no edit is allowed.</p>
                ) : null}
            </Grid>
            {prompts.map((prompt, id) => {
                const acronymError = itemError[`prompt ${id}`] && checkAcronymError(prompt.acronym);
                return (
                    <Grid
                        item
                        xs={6}
                        container
                        key={`prompt${id}`}
                        className={classes.dataRowWrapper}
                    >
                        <Grid item xs={2} style={{ paddingLeft: 10, position: 'relative' }}>
                            <InputLabel id={`default-prompt-setting-plus-minus-field-label-${id}`} className={classes.plusMinusLabel}>
                                <span>+/-</span>
                                <Tooltip title='This column defines whether a response is considered a correct or incorrect response.'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </InputLabel>
                            <Switch
                                id={`default-prompt-setting-plus-minus-field-${id}`}
                                onChange={handleChangePromptCorrectness(id, !prompt.isNegative)}
                                checked={!prompt.isNegative}
                                classes={{
                                    track: !prompt.isNegative
                                        ? classes.plusTrack
                                        : classes.minusTrack,
                                    switchBase: !prompt.isNegative
                                        ? classes.plusThumb
                                        : classes.minusThumb,
                                }}
                                icon={<RemoveCircleIcon className={classes.switchIcon} />}
                                checkedIcon={<AddCircleIcon className={classes.switchIcon} />}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel id={`default-prompt-setting-code-field-label-${id}`} required>Code {id + 1}</InputLabel>
                            <TextField
                                id={`default-prompt-setting-code-field-${id}`}
                                variant='outlined'
                                value={prompt.acronym}
                                onChange={handleChangePrompt(id, 'acronym')}
                                style={{ width: '80%' }}
                                error={!!acronymError && acronymError !== ' '}
                                helperText={acronymError}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel id={`default-prompt-setting-description-field-label-${id}`} required>Code Description {id + 1}</InputLabel>
                            <TextField
                                id={`default-prompt-setting-description-field-${id}`}
                                variant='outlined'
                                value={prompt.name}
                                onChange={handleChangePrompt(id, 'name')}
                                style={{ width: '75%' }}
                                error={itemError[`prompt ${id}`] && !prompt.name.trim()}
                                helperText={
                                    itemError[`prompt ${id}`] && !prompt.name.trim()
                                        ? "Description can't be empty"
                                        : ' '
                                }
                                disabled={disabled}
                            />
                            {prompts.length > 0 ? (
                                <IconButton
                                    id={`default-prompt-setting-code-delete-${id}`}
                                    disabled={disabled}
                                    className={classes.deleteBtn}
                                    onClick={handleDeletePrompt(id)}
                                    tabIndex={-1}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ) : null}
                        </Grid>
                    </Grid>
                );
            })}
            {prompts.length % 2 === 0 && <Grid item xs={6} />}
            {prompts.length < 8 && (
                <Grid
                    item
                    xs={6}
                    className={classes.addMoreWrapper}
                    container
                    alignItems='center'
                    justify='center'
                >
                    <Button id="default-prompt-setting-add-code" disabled={disabled} className={classes.saveBtn} onClick={addPrompt}>
                        {prompts.length === 0 ? 'Add' : 'Add More'}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

PromptData.propTypes = {
    prompts: PropTypes.array.isRequired,
    setPrompts: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { setSnackbar })(PromptData);
