import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import Program from './components/Program/Program';
import Analysis from './components/Analysis/Analysis';
import BaseLayout from './components/BaseLayout';
import CanUser, { CanUserFunc } from './components/CanUser';
import User from './components/User/User';
import AuthLayout from './components/Auth/AuthLayout';
import Login from './components/Auth/Login';
import SetPassword from './components/Auth/SetPassword';
import ForgotPassword from './components/Auth/ForgotPassword';
import Roles from './components/Roles/Roles';
import Site from './components/Site/Site';
import Organization from './components/Organization/Organization';
import AccountInfo from './components/Organization/AccountInfo';
import { access } from './constants';
import Client from './components/Client/Client';
import ResetPassword from './components/Auth/ResetPassword';
import Data from './components/Data/Data';
import Reminder from './components/Reminder/Reminder';
import Print from './components/Print/Print';
import Notes from './components/Notes/Notes';
import UserSettings from './components/UserSetting/UserSettings';
import UserInfo from './components/User/UserInfo';
import DataSheet from './components/DataSheet/DataSheet';
import Devices from './components/Devices/Devices';
import Library from './components/Library/Library';
import NewDataPage from './components/Data/NewDataPage';

const Routing = (props) => {
    let redirect = '/';
    if (props.user && props.user.id) {
        if (CanUserFunc(access.targets.view)) {
            redirect = '/';
        } else if (CanUserFunc(access.analysis.view)) {
            redirect = '/analysis';
        } else if (CanUserFunc(access.data.view)) {
            redirect = '/data';
        } else if (CanUserFunc(access.notes.view)) {
            redirect = '/notes';
        } else if (CanUserFunc(access.users.view)) {
            redirect = '/admin/user';
        } else if (CanUserFunc(access.roles.view)) {
            redirect = '/admin/user/role';
        } else if (CanUserFunc(access.clients.view)) {
            redirect = '/admin/clients';
        } else if (CanUserFunc(access.sites.view)) {
            redirect = '/admin/subscription/sites';
        }
    }


    const PrivateRoute = ({ children, ...rest }) => {
        return (
            <Route
                {...rest}
                render={() =>
                    props.isLoggedIn ? (
                        children
                    ) : (
                            <Redirect
                                to={{
                                    pathname: "/auth/login",
                                }}
                            />
                        )
                }
            />
        );
    }

    return (
        <Router>
            <Switch>
                <Route path="/auth">
                    <AuthLayout>
                        <Switch>
                            <Route exact path="/auth/login"
                                component={() =>  
                                    <Login />
                                }
                            />
                            <Route exact path="/auth/set-password/:token"
                                component={() =>  
                                    <SetPassword />
                                }
                            />
                            <Route exact path="/auth/forgot-password"
                                component={() =>  
                                    <ForgotPassword />
                                }
                            />
                            <Route exact path="/auth/reset-password/:token"
                                component={() =>  
                                    <ResetPassword />
                                }
                            />
                            <Redirect to='/auth/login' />
                        </Switch>
                    </AuthLayout>
                </Route>
                <PrivateRoute path="/">
                    <BaseLayout>
                        <Switch>
                            <Route exact path="/"
                                component={() =>
                                    <CanUser
                                        perform={access.targets.view}
                                        yes={() => (
                                            <Program />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/data"
                                component={() =>
                                    <CanUser
                                        perform={access.data.view}
                                        yes={() => (
                                            <Data />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/data/collect"
                                component={() =>
                                    <CanUser
                                        perform={access.data.add}
                                        yes={() => (
                                            <NewDataPage />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/analysis"
                                component={() =>
                                    <CanUser
                                        perform={access.analysis.view}
                                        yes={() => (
                                            <Analysis />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/notes"
                                component={() =>
                                    <CanUser
                                        perform={access.notes.view}
                                        yes={() => (
                                            <Notes />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/user"
                                component={() =>
                                    <CanUser
                                        perform={access.users.view}
                                        yes={() => (
                                            <User />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/user/role"
                                component={() =>
                                    <CanUser
                                        perform={access.roles.view}
                                        yes={() => (
                                            <Roles />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/user/devices"
                                component={() =>
                                    <CanUser
                                        perform={access.devices.view}
                                        yes={() => (
                                            <Devices />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/client"
                                component={() =>
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <Client />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/subscription/site"
                                component={() =>
                                    <CanUser
                                        perform={access.sites.view}
                                        yes={() => (
                                            <Site />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/admin/subscription"
                                component={() => props.user.isSuperAdmin ? <Organization /> : <Redirect to={redirect} />}
                            />
                            <Route exact path="/admin/subscription/account-info"
                                component={() =>
                                    <CanUser
                                        perform={access.companies.view}
                                        yes={() => (
                                            <AccountInfo />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/user-setting"
                                component={() => 
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <UserSettings />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/user-setting/user-info"
                                component={() => <UserInfo />}
                            />
                            <Route exact path="/settings"
                                component={() => <Redirect to="/settings/reminder" />}
                            />
                            <Route exact path="/settings/reminder"
                                component={() =>
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <Reminder />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/settings/data-sheet"
                                component={() =>
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <DataSheet />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/settings/print"
                                component={() =>
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <Print />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Route exact path="/user-setting/my-library"
                                component={() =>
                                    <CanUser
                                        perform={access.clients.view}
                                        yes={() => (
                                            <Library />
                                        )}
                                        no={() => (
                                            <Redirect to={redirect} />
                                        )}
                                    />
                                }
                            />
                            <Redirect to='/' />
                        </Switch>
                    </BaseLayout>
                </PrivateRoute>
            </Switch>
        </Router>
    )
}


const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
})

export default connect(mapStateToProps)(Routing)

