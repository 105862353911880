import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import { connect } from 'react-redux';

import CanUser, { CanUserFunc } from '../CanUser';
import { access } from '../../constants';
import UserAvatar from '../UserAvatar';

const useStyles = makeStyles(theme => ({
	logoWrapper: {
		backgroundColor: '#0D3250',
		display: 'flex',
	},
	accelLogo: {
		alignSelf: 'flex-start',
		marginTop: 9,
		marginLeft: 35,
		marginBottom: 9,
		height: 43,
	},
	version: {
		fontSize: 14,
		color: 'rgba(255, 255, 255, 0.2)',
		marginLeft: 10,
		marginBottom: 5,
		display: 'inline-block',
		alignSelf: 'flex-end',
	},
	header: {
		backgroundColor: '#0D3250',
		width: '100%',
		paddingLeft: 20,
		position: 'sticky',
		top: 0,
		zIndex: 2,
	},
	routeTab: {
		display: 'inline-block',
		padding: 20,
		fontSize: 18,
	},
	tabActive: {
		borderBottom: '4px solid #0FB99E',
	},
	tabUnderline: {
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		textDecoration: 'none',
	},
	link: {
		color: '#ffffff',
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
	},
	sublink: {
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
		textAlign: 'center',
		color: '#0D3250',
	},
	popoverPaper: {
		backgroundColor: '#ffffff',
	},
	sublinkUnderline: {
		textDecoration: 'none',
	},
	sublinkActive: {
		borderBottom: '4px solid #0FB99E',
		'&:hover': { color: '#0FB99E' },
	},
	arrowIcon: {
		verticalAlign: 'middle',
	},
}));

const CustomLink = ({ route, openPopover }) => {
	const classes = useStyles();

	let hasSubroute = false;

	if (route.subroute) {
		route.subroute.forEach((sub) => {
			if (!sub.isParent) {
				hasSubroute = true;
			}
		})
	}

	const match = useRouteMatch({
		path: route.route,
		exact: !hasSubroute,
	});

	return (
		<div className={classes.routeTab} onMouseEnter={hasSubroute ? openPopover(route.subroute) : () => { }}>
			<Link to={{ pathname: route.route }} className={classes.link}>
				<u className={`${classes.tabUnderline} ${match ? classes.tabActive : ''}`}>
					{route.label}
					{route.special && <ArrowIcon className={classes.arrowIcon} />}
				</u></Link>
		</div>
	)
};

const CustomSubLink = ({ route }) => {
	const classes = useStyles();

	const match = useRouteMatch({
		path: route.route,
		exact: true,
	});


	return (
		<Link to={{ pathname: route.route }} className={classes.sublink}>
			<MenuItem>
				<u className={`${classes.sublinkUnderline} ${match ? classes.sublinkActive : ''}`} >{route.label}</u>
			</MenuItem>
		</Link>
	)
};

const Header = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [subroute, setSubroute] = useState([]);
	const [routeList, setRouteList] = useState([]);

	useEffect(() => {
		const newList = [];
		if (CanUserFunc(access.users.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/user',
				permission: access.users.view,
				special: true,
			})
		} else if (CanUserFunc(access.roles.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/user/role',
				permission: access.roles.view,
				special: true,
			})
		} else if (CanUserFunc(access.sites.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/subscription/site',
				permission: access.sites.view,
				special: true,
			})
		} else if (CanUserFunc(access.clients.view)) {
			newList.push({
				label: 'Admin',
				route: '/admin/client',
				permission: access.clients.view,
				special: true,
			})
		}
		
		if (CanUserFunc(access.targets.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/',
				permission: access.targets.view,
				special: true,
			})
		} else if (CanUserFunc(access.analysis.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/analysis',
				permission: access.analysis.view,
				special: true,
			})
		} else if (CanUserFunc(access.data.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/data',
				permission: access.data.view,
				special: true,
			})
		}

		setRouteList(newList);
	}, []);

	const openPopover = (subroute) => (event) => {
		setAnchorEl(event.currentTarget);
		setSubroute(subroute);
	}

	return (
		<Grid container className={classes.header}>
			<Grid item xs={7}>
				{routeList.map((e, i) => {
					if (!e.permission) {
						return (
							<CustomLink route={e} openPopover={openPopover} />
						)
					}
					return (
						<CanUser
							key={i}
							perform={e.permission}
							yes={() => (
								<CustomLink route={e} openPopover={openPopover} />
							)}
						/>
					)
				})}
			</Grid>
			<Grid item xs={5} container justify="flex-end" alignItems="center">
				<UserAvatar />
			</Grid>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={{
					onMouseLeave: () => setAnchorEl(null)
				}}
				classes={{
					paper: classes.popoverPaper,
				}}
				getContentAnchorEl={null}
			>
				{subroute.map((sub, i) => (
					<CanUser
						key={`sub-${i}`}
						perform={sub.permission}
						yes={() => (
							<CustomSubLink route={sub} />
						)}
					/>
				))}
			</Menu>
		</Grid>
	)
}

Header.propTypes = {
}

const mapStateToProps = state => ({
});

export default connect(mapStateToProps)(Header);
