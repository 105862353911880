import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import CloseIcon from '@material-ui/icons/Close';
import LinearProgress from '@material-ui/core/LinearProgress';

import { closeUploadDialog, setLoading } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
	dropZone: {
		marginTop: 20,
		width: '100%',
		height: 70,
		border: '2px dashed black',
	},
	dialogPaper: {
		width: 600,
		minWidth: 600,
	},
	deleteBtn: {
		color: 'red',
		padding: 10,
		marginLeft: 10,
	},
	closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
	},
	progress: {
		position: 'absolute',
		width: '100%',
		bottom: 0,
		left: 0,
	},
	dialogTitle: {
		position: 'relative',
	},
	errorMessage: {
		paddingLeft: 10,
	},
}));

const UploadDialog = (props) => {
	const classes = useStyles();
	const { title, message, open, handleUpload, templateLink, templateName, acceptedTypes, uploadCallback } = props.uploadDialog;
	const { closeUploadDialog, setLoading, loading } = props;
	const [uploadedFile, setUploadedFile] = useState(null);
	const [response, setResponse] = useState(null);
	const [fileUploaded, setFileUploaded] = useState(false)

	useEffect(() => {
		if (!open) {
			setUploadedFile(null);
			setResponse(null);
			setFileUploaded(false);
		}
	}, [open])

	const handleClose = (isOk) => () => {
		if (isOk) {
			const formData = new FormData();
			formData.append('uploadFile', uploadedFile);
			setLoading('uploadFile');
			handleUpload(formData).then((res) => {
				setResponse(res.data);
				setFileUploaded(true);
				setLoading('uploadFile', false);
				uploadCallback();
			});
		} else {
			if (!loading.uploadFile) {
				closeUploadDialog();
			}
		}
	}

	const handleDrop = (file) => {
		setUploadedFile(file[0]);
		setFileUploaded(false);
	}

	const handleDeleteFile = () => {
		setUploadedFile(null);
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose(false)}
			aria-labelledby="upload-dialog-title"
			aria-describedby="upload-dialog-description"
			maxWidth="md"
			classes={{
				paper: classes.dialogPaper
			}}
		>
			<DialogTitle className={classes.dialogTitle}>
				<Grid container>
					<Grid item xs={11}>
						{title}
					</Grid>
					<Grid item xs={1} container justify="flex-end">
						<IconButton disabled={loading.uploadFile} className={classes.closeIconBtn} onClick={() => closeUploadDialog()}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
				<LinearProgress className={classes.progress} style={{ visibility: loading.uploadFile ? 'visible' : 'hidden' }} />
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="upload-dialog-description">
					{message}
				</DialogContentText>
				{templateLink && <a href={templateLink} target="_blank" download={templateName}>Click here to download the template</a>}
				{!uploadedFile ?
					<Dropzone onDrop={handleDrop} multiple={false} accept={acceptedTypes}>
						{({ getRootProps, getInputProps }) => (
							<Grid {...getRootProps()} className={classes.dropZone} container justify="center" alignItems="center">
								<input {...getInputProps()} />
								<p>Drag and drop a file here, or click to select a file</p>
							</Grid>
						)}
					</Dropzone>
					:
					<Grid style={{ marginTop: 20 }} container alignItems="center">
						<p>File: {uploadedFile.name}</p>
						<IconButton
							disabled={loading.uploadFile}
							className={classes.deleteBtn}
							onClick={handleDeleteFile}
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				}
				{response &&
					<div>
						<p>Success: {response.successCount}</p>
						<p>Failed: {response.failCount}</p>
						{response.errors.map((error) => (
							// rowIndex + 2 since excel starts row at 1, and 1 is header
							<p className={classes.errorMessage}>{`• Row ${error.rowIndex + 2}: ${error.errorMessage}`}</p>
						))}
					</div>
				}
			</DialogContent>
			<DialogActions>
				<Button disabled={loading.uploadFile} onClick={handleClose(false)}>
					Close
          		</Button>
				<Button disabled={!uploadedFile || loading.uploadFile || fileUploaded} onClick={handleClose(true)} autoFocus>
					Upload
          		</Button>
			</DialogActions>
		</Dialog>
	);
}

UploadDialog.propTypes = {
	uploadDialog: PropTypes.object.isRequired,
	closeUploadDialog: PropTypes.func.isRequired,
	loading: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
	uploadDialog: state.general.uploadDialog,
})

export default connect(mapStateToProps, { closeUploadDialog, setLoading })(UploadDialog)
