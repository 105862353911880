import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import SiteTable from './SiteTable';
import NewSite from './NewSite';
import { setLoading, setSnackbar } from '../../store/general/actions'; 
import { postSite, updateSite, getSites } from '../../services/SiteService';
import CanUser from '../CanUser';
import { access, localStorageEnum } from '../../constants';
import { tokenLogin } from '../../store/auth/actions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 40px',
        '@media (max-width: 768px)': {
            padding: '10px 15px',
        }
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 40px 15px 0px',
        backgroundColor: '#FFFFFF'
    },
    siteTable: {
        maxWidth: 'calc(100vw - 30px)',
    }
}));


const Site = (props) => {
    const classes = useStyles();
    const { loading, setLoading, setSnackbar } = props;
    const [newOpen, setNewOpen] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [sites, setSites] = useState([]);
    const isMounted = useRef(false);

    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);

    useEffect(() => {
        let siteFilters = localStorage.getItem(localStorageEnum.siteFilters);
        if (siteFilters) {
            siteFilters = JSON.parse(siteFilters);
            setSort(siteFilters.sort);
        }
    }, []);

    useEffect(() => {
        const siteFilters = { sort };
        localStorage.setItem(localStorageEnum.siteFilters, JSON.stringify(siteFilters));
    }, [sort])

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        fetchSites();
    }, [sort, skip, limit]);

    const fetchSites = () => {
        setLoading('getSites');
        getSites(sort.sortBy, sort.sortAs, skip, limit).then((res) => {
            if (isMounted.current) {
                setSites(res.data.data);
                setLoading('getSites', false);
            }
        });
    }

    const onEdit = (site) => {
        setSelectedSite(site);
        setNewOpen(true);
    }

    const handleClose = () => {
        setNewOpen(false);
        setSelectedSite(null);
    }

    const handleSave = (data) => {
        if (!selectedSite) { // new data
            setLoading('newSite');
            postSite(data).then(async () => {
                props.tokenLogin(); // refreshing token to add the new site to user
                setLoading('newSite', false);
                setSnackbar('success', 'Site added!');
                fetchSites();
                handleClose();
            }).catch((err) => {
                setLoading('newSite', false);
            });
        } else {
            setLoading('editSite');
            updateSite(data).then(() => {
                setLoading('editSite', false);
                setSnackbar('success', 'Site updated!');
                fetchSites();
                handleClose();
            }).catch((err) => {
                setLoading('editSite', false);
            });
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.btnWrapper} container alignItems="center">
                <CanUser
                    perform={access.sites.add}
                    yes={() => (
                        <Button id="site-add" onClick={() => setNewOpen(true)} variant="contained">New Site <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} /></Button>
                    )}
                />
                <LoadingCheck show={loading.newSite} size={24} />
            </Grid>
            <Grid item xs={9} />
            <Grid item xs={12} className={classes.siteTable}>
                <SiteTable
                    handleEdit={onEdit}
                    sites={sites}
                    fetchSites={fetchSites}
                    setSort={setSort}
                    sort={sort}
                />
            </Grid>

            <NewSite
                open={newOpen}
                onSave={handleSave}
                onClose={handleClose}
                isEdit={!!selectedSite}
                site={selectedSite}
            />
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={siteSteps} /> */}
        </Grid>
    )
}

Site.propTypes = {
    loading: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    tokenLogin: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setSnackbar, setLoading, tokenLogin})(Site);