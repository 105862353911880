import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Chip,
  InputLabel,
  TextField,
  Grid,
  FormHelperText,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { access, roleNames } from '../../constants';
import emailIsValid from '../../utils/validateEmail';
import { getTitles } from '../../services/UserService';
import { getRolesDropdown } from '../../services/RoleService';
import { getSitesDropdown } from '../../services/SiteService';
import {
  setLoading,
  setGlobalDialog,
  setSnackbar,
} from '../../store/general/actions';
import { CanUserFunc } from '../../components/CanUser';
import passwordIsValid from '../../utils/validatePassword';
import { getCurrentUser, updateCurrentUser } from '../../services/AuthService';

const useStyles = makeStyles((theme) => ({
  closeIconBtn: {
    padding: '0px 4px',
    color: '#0FB99E',
  },
  leftWrapper: {
    paddingRight: 19,
    marginBottom: 28,
  },
  rightWrapper: {
    paddingLeft: 19,
    marginBottom: 28,
  },
  largeWrapper: {
    marginBottom: 28,
  },
  input: {
    width: '100%',
  },
  multilineInput: {
    minHeight: 90,
  },
  multilineInputLarge: {
    minHeight: 225,
  },
  saveBtn: {
    padding: '6px 40px',
  },
  closeBtn: {
    padding: '6px 40px',
    marginRight: '10px',
    backgroundColor: '#fff',
    border: '1px solid #0FB99E',
    color: '#0FB99E',

    '&:hover': {
      color: '#fff',
    },
  },
  targetBtn: {
    padding: '6px 50px',
  },
  addNewMenu: {
    backgroundColor: '#0FB99E',
    color: '#ffffff',
    '&:hover': { backgroundColor: '#00826d' },
    fontSize: 14,
  },
  closeAdornment: {
    padding: 8,
  },
  chipBox: {
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 5,
  },
  popoverPaper: {
    padding: 20,
    width: '40vw',
  },
  buttonChip: {
    border: '0 !important',
    color: '#ffffff',
    backgroundColor: '#0FB99E !important',
    '&:hover': { backgroundColor: '#00826d !important' },
  },
  iconBtn: {
    padding: 2,
  },
  listItemText: {
    fontSize: 14,
  },
  listItem: {
    padding: '0px 16px',
  },
  titleSelect: {
    padding: '8px 32px 8px 14px',
  },
  resetPassword: {
    fontSize: '0.75rem',
    cursor: 'pointer',
    color: 'blue',
    textDecoration: 'underline',
  },
  title: {
    fontSize: '35px',
    marginBottom: '20px',
  },
  infoIcon: {
    color: '#0FB99E',
    fontSize: 21,
    marginLeft: 5,
    marginBottom: -5,
  },
}));

const defaultUser = {
  firstName: '',
  lastName: '',
  username: '',
  title: 0,
  email: '',
  phone: '',
  roles: [],
  branches: [],
  patients: [],
  note: '',
};

const maxLength = {
  firstName: 100,
  lastName: 100,
  username: 100,
  email: 100,
  phone: 100,
  note: 1024,
};

const UserInfo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, setLoading, user, setSnackbar } = props;
  const [newUser, setNewUser] = useState(cloneDeep(defaultUser));
  const [createNewTitle, setCreateNewTitle] = useState(false);
  const [error, setError] = useState(false);

  const [sites, setSites] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setError(false);
    setCreateNewTitle(false);
    setLoading('loadingUser');
    Promise.all([
      getRolesDropdown(),
      getTitles(),
      CanUserFunc(access.sites.view)
        ? getSitesDropdown()
        : { data: { data: user.branchNames } },
    ]).then((values) => {
      setRoles(values[0].data.data.roles);
      setSites(values[2].data.data);

      getCurrentUser(user.id).then((res) => {
        let user = res.data.user;
        user = {
          ...user,
          branches: user.branches.map((e) => e.id),
          patients: user.patients,
          title: user.title ? user.title.name : undefined,
          phone: user.mobilePhone,
        };
        setNewUser(user);
        setLoading('loadingUser', false);
      });
    });
  }, []);

  const handleChange = (field) => (event) => {
    let value = event.target.value;
    if (maxLength[field]) {
      if (value.length > maxLength[field]) {
        value = value.substring(0, maxLength[field]);
      }
    }
    setNewUser({ ...newUser, [field]: value });
  };

  const handleChangePhone = (event) => {
    let filteredInput = event.target.value.replace(/([^0-9+]+)/g, '');
    const firstChar = filteredInput.substring(0, 1);
    const subbedInput = filteredInput.substring(1);
    filteredInput = firstChar + subbedInput.replace('+', '');
    setNewUser({ ...newUser, phone: filteredInput });
  };

  const handleSave = () => {
    const isCompanyAdmin = newUser.roles.some(e => e.name === roleNames.ORG_ADMIN);
    if (
      !newUser.firstName.trim() ||
      !newUser.lastName.trim() ||
      !newUser.username.trim() ||
      !newUser.email.trim() ||
      !emailIsValid(newUser.email) ||
      !newUser.roles.length ||
      (!newUser.branches.length && !isCompanyAdmin)
    ) {
      setError(true);
      return;
    }

    if (!!newUser.password && !passwordIsValid(newUser.password)) {
      setError(true);
      return;
    }

    if (isCompanyAdmin) {
      newUser.branches = sites.map((e) => e.id);
    }

    setLoading('editUser');
    updateCurrentUser({
      ...newUser,
      roles: newUser.roles.map(r => r.id),
      firstName: newUser.firstName.trim(),
      lastName: newUser.lastName.trim(),
      email: newUser.email.trim(),
      title: createNewTitle ? newUser.title.trim() : newUser.title,
      patients: newUser.patients.map((e) => e.id),
    })
      .then(() => {
        setLoading('editUser', false);
        setNewUser({
          ...newUser,
          password: '',
        });
        setSnackbar('success', 'User Information successfully updated!');
      })
      .catch((err) => {
        setLoading('editUser', false);
      });
  };

  const getPasswordText = () => {
    if (!error) {
      return ' ';
    }
    if (error && !newUser.password) {
      return ' ';
    }
    if (error && !passwordIsValid(newUser.password)) {
      return 'The password must be at least 8 characters long including one upper case letter and a number.';
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const passwordError =
    error && !!newUser.password && !passwordIsValid(newUser.password);
  return (
    <>
      {loading.loadingUser ? (
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ height: 'calc(100vh - 65px' }}
        >
          <CircularProgress size={250} />
        </Grid>
      ) : (
        <Grid container item xs={9} style={{ margin: '30px' }}>
          <Grid item container>
            <Grid item xs={12}>
              <InputLabel id="user-information-form-title" className={classes.title}>
                User Information
              </InputLabel>
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
              <InputLabel id="user-information-first-name-field-label" required>First Name</InputLabel>
              <TextField
                id="user-information-first-name-field"
                variant='outlined'
                value={newUser.firstName}
                onChange={handleChange('firstName')}
                error={error && !newUser.firstName.trim()}
                helperText={
                  error && !newUser.firstName.trim()
                    ? "First name can't be empty"
                    : ' '
                }
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.rightWrapper}>
              <InputLabel id="user-information-last-name-field-label" required>Last Name</InputLabel>
              <TextField
                id="user-information-last-name-field"
                variant='outlined'
                value={newUser.lastName}
                onChange={handleChange('lastName')}
                error={error && !newUser.lastName.trim()}
                helperText={
                  error && !newUser.lastName.trim()
                    ? "Last name can't be empty"
                    : ' '
                }
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
              <InputLabel id="user-information-username-field-label" required>Username</InputLabel>
              <TextField
                id="user-information-username-field"
                variant='outlined'
                value={newUser.username}
                onChange={handleChange('username')}
                error={error && !newUser.username.trim()}
                helperText={
                  error && !newUser.username.trim()
                    ? "Username can't be empty"
                    : ' '
                }
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.rightWrapper}>
              <InputLabel id="user-information-title-field-label">Title</InputLabel>
              <TextField
                id="user-information-title-field"
                variant='outlined'
                value={newUser.title || 'No Title'}
                onChange={handleChange('title')}
                className={classes.input}
                autoFocus
                inputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
              <InputLabel id="user-information-email-field-label" required>Email</InputLabel>
              <TextField
                id="user-information-email-field"
                variant='outlined'
                value={newUser.email}
                onChange={handleChange('email')}
                error={
                  error &&
                  (!newUser.email.trim() || !emailIsValid(newUser.email))
                }
                helperText={
                  error
                    ? (!newUser.email.trim() && "Email can't be empty") ||
                      (!emailIsValid(newUser.email) ? 'Email is invalid' : ' ')
                    : ' '
                }
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.rightWrapper}>
              <InputLabel id="user-information-phone-field-label">Phone</InputLabel>
              <TextField
                id="user-information-phone-field"
                variant='outlined'
                value={newUser.phone}
                onChange={handleChangePhone}
                className={classes.input}
              />
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
              <InputLabel id="user-information-new-password-field-label">
                New Password
                <Tooltip title='The password must be at least 8 characters long including one upper case letter and a number.'>
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </InputLabel>
              <TextField
                id="user-information-new-password-field"
                autoComplete='new-password'
                variant='outlined'
                value={newUser.password}
                onChange={handleChange('password')}
                className={classes.input}
                type='password'
                error={passwordError}
                helperText={getPasswordText()}
              />
            </Grid>
            <Grid item xs={6} className={classes.rightWrapper}>
              <InputLabel id="user-information-sites-field-label">Sites</InputLabel>
              <Grid
                container
                className={classes.chipBox}
                style={
                  error && !newUser.branches.length
                    ? { borderColor: '#f44336' }
                    : {}
                }
              >
                {newUser.branches.map((site) => {
                  const selectedSite = sites.find((e) => e.id === site);
                  if (selectedSite) {
                    return (
                      <Chip
                        id={`user-information-sites-field-chip-${site}`}
                        variant='outlined'
                        size='small'
                        label={selectedSite.name}
                        key={site}
                      />
                    );
                  } else return null;
                })}
              </Grid>
            </Grid>
            <Grid item xs={6} className={classes.leftWrapper}>
              <InputLabel id="user-information-roles-field-label">Roles</InputLabel>
              <Grid
                container
                className={classes.chipBox}
                style={
                  error && !newUser.roles.length
                    ? { borderColor: '#f44336' }
                    : {}
                }
              >
                {newUser.roles.map((role) => (
                  <Chip
                    id={`user-information-roles-field-chip-${role.id}`}
                    variant='outlined'
                    size='small'
                    label={roles.find((e) => e.id === role.id).name}
                    key={role.id}
                  />
                ))}
              </Grid>
              <FormHelperText id="user-information-roles-field-helper-text" error={error && !newUser.roles.length}>
                {error && !newUser.roles.length
                  ? 'Please choose at least one role'
                  : ' '}
              </FormHelperText>
            </Grid>

            <Grid item xs={12} className={classes.largeWrapper}>
              <InputLabel id="user-information-clients-field-label">Clients</InputLabel>
              <Grid container className={classes.chipBox}>
                {newUser.patients.map((patient) => {
                  const isLinked = patient.id === newUser.patientId;
                  return (
                    <Chip
                      id={`user-information-clients-field-chip-${patient.id}`}
                      variant='outlined'
                      size='small'
                      label={`${patient.firstName} ${patient.lastName}${
                        isLinked ? ' (Linked)' : ''
                      }`}
                      key={patient.id}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item xs={12} container justify='flex-end'>
                <Button
                  id="user-information-cancel-button"
                  className={classes.closeBtn}
                  variant='outlined'
                  onClick={handleCancel}
                  disabled={
                    loading.loadingUser || loading.newUser || loading.editUser
                  }
                >
                  Cancel
                </Button>
                <Button
                  id="user-information-save-button"
                  className={classes.saveBtn}
                  onClick={handleSave}
                  disabled={
                    loading.loadingUser || loading.newUser || loading.editUser
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

UserInfo.propTypes = {
  loading: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setGlobalDialog: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.general.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  setLoading,
  setGlobalDialog,
  setSnackbar,
})(UserInfo);
