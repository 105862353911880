import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { InputLabel, Grid, CircularProgress, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import { setLoading, setSnackbar } from '../../store/general/actions';
import { getUser, getUsers } from '../../services/UserService'; 
import { updateOrganization, getOrganizationById } from '../../services/OrganizationService';
import OrganizationForm from './OrganizationForm';
import { tokenLogin } from '../../store/auth/actions';
import { roleNames } from '../../constants';

const useStyles = makeStyles(theme => ({
  largeWrapper: {
      marginBottom: 28,
  },
  billingInfo: {
      fontSize: '0.75rem',
      cursor: 'pointer',
      color: 'blue',
      textDecoration: 'underline',
  },
  containerStyles: { 
      margin: '0px auto',
      paddingTop: '30px',
      paddingBottom: '30px',
  },
  title: {
    fontSize: '35px',
    marginBottom: '20px',
  },
  saveBtn: {
    padding: '6px 40px',
  },
  closeBtn: {
    padding: '6px 40px',
    marginRight: '10px',
    backgroundColor: '#fff',
    border: '1px solid #0FB99E',
    color: '#0FB99E',

    '&:hover': {
      color: '#fff',
    },
  },
}));

const debouncedGetUsers = debounce(getUsers, 500);

const AccountInfo = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user, loading, setSnackbar, setLoading, tokenLogin } = props;
  const [newOrganization, setNewOrganization] = useState({
      name: '',
      owner: {}
  });
  const [organization, setOrganization] = useState({
      name: '',
      owner: {}
  });
  const [error, setError] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState(user);
  const [changeAccountOwner, setChangeAccountOwner] = useState(false);

  const handleCancel = () => {
    setNewOrganization(organization);
    history.push('/admin/user');
  };

  useEffect(() => {
    getUser(user.id).then(res => {
      let owner = res.data.user;
      owner = {
          ...owner,
          roles: owner.roles.filter(r => r.name !== roleNames.ORG_ADMIN),
          branches: owner.branches.map(e => e.id),
          title: owner.title ? owner.title.id : 0,
          phone: owner.mobilePhone,
      };
      setNewUser(owner);

      getOrganizationById(user.companyId).then((res) => {
        setOrganization(res.data.data);
        setNewOrganization({...res.data.data, owner});
      })
    })
    return () => tokenLogin(false);
  }, []);
  
  useEffect(() => {
    if(!organization || !organization.branches) return;

    let branches = [];
    setLoading('organization');
    organization.branches.map(b => branches.push(b.id));
    debouncedGetUsers(null, null, null, null, true, branches, null, null, 'employee').then((res) => {
        setUsers(res.data.data.users);
        setLoading('organization', false);
    });
  }, [organization])


  const handleChange = field => event => {
    switch(field) {
      case 'roles':
          setNewUser({ ...newUser, roles: event });
          break;
      case 'ownerId':
          let owner = users.find(user => user.id === event);
          setNewOrganization({ ...newOrganization, owner, ownerId: event });
          break;
      case 'name':
          setNewOrganization({ ...newOrganization, name: event.target.value })
          break;
      default:
          setNewOrganization({ ...newOrganization, [field]: event.target.value });
    }    
  }

  const handleSave = () => {
    if (!newOrganization.name.trim()) {
      setError(true);
      return;
    }

    if (changeAccountOwner && newUser.roles.length < 1) {
      setError(true);
      return;
    }

    setLoading('editOrganization');
    updateOrganization({
        ...newOrganization,
        name: newOrganization.name.trim(),
        owner: changeAccountOwner ? newOrganization.owner : undefined,
        oldOwnerNewRoleIds: changeAccountOwner ? newUser.roles.map(r => r.id) : undefined,
    }).then(() => {
      setLoading('editOrganization', false);
      if(newOrganization.owner.id !== user.id) {
        history.push('/');
        tokenLogin();
      } else history.push('/admin/user');      
    }).then(() => {
        setTimeout(() => setSnackbar('success', 'Organization updated!'), 1000);
    }).catch((err) => {
        setLoading('editOrganization', false);
    });
  }

  const handleSubscriptionChange = () => {
      const link = document.querySelector('#chargebee-tag');
      link.click();
  }

  return (
    <>
      {(loading.organization || !organization.id) ? (
        <Grid
          container
          justify='center'
          alignItems='center'
          style={{ height: 'calc(100vh - 65px)' }}
        >
          <CircularProgress size={250} />
        </Grid>
      ) : (
        <Grid container item xs={9} className={classes.containerStyles}>
          <Grid item xs={12}>
            <InputLabel className={classes.title}>
              Account Information
            </InputLabel>
          </Grid>
          <OrganizationForm
            newOrganization={newOrganization} 
            users={users}
            updatedUser={newUser}
            handleChange={handleChange}
            error={error}
            handleSubscriptionChange={handleSubscriptionChange}
            changeOwner={changeAccountOwner}
            setChangeOwner={setChangeAccountOwner}
          >
            <Grid item xs={6} className={classes.largeWrapper}>
              <InputLabel>Billing Information</InputLabel>
              <span className={classes.billingInfo} onClick={handleSubscriptionChange}>
                  See Billing Information
              </span>
            </Grid>
            <Grid item container>
              <Grid item xs={12} container justify='flex-end'>
                <Button
                  className={classes.closeBtn}
                  variant='outlined'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  className={classes.saveBtn}
                  onClick={handleSave}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </OrganizationForm>
        </Grid>
      )}
    </>
  );
};

AccountInfo.propTypes = {
  loading: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setSnackbar: PropTypes.func.isRequired,
  tokenLogin: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  loading: state.general.loading,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  setLoading,
  setSnackbar,
  tokenLogin
})(AccountInfo);
