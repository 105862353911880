import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { getUnixTime } from 'date-fns';

import Wrapper from './Wrapper';
import PhaseColoredButton from './PhaseColoredButton';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 5,
    flexGrow: 1,
    backgroundColor: '#f4f4f4',
    color: '#000',
    '&:hover': {
      backgroundColor: '#b5b5b5',
    },
    '&:first-of-type': {
      marginRight: 5,
    },
  },
}));

const types = {
  '+': 'plus',
  '-': 'minus',
};
function CorrectIncorrect(props) {
  const classes = useStyles();

  const clickHandler = (type) => {
    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      { type: type, time: getUnixTime(new Date()) },
      type
    );
  };

  const isResponseAllowed = useMemo(() => {
    const maxTrialNo = props.target.maximumNumberTrials;
    if (
        props.sessionData?.length &&
        !!maxTrialNo &&
        !isNaN(maxTrialNo)
    ) {
        return props.sessionData.length < maxTrialNo;
    }
    return true;
  }, [props.sessionData?.length]);

  return (
    <Wrapper {...props} >
      <Box display="flex">
        {['+', '-'].map((item, index) => (
          <PhaseColoredButton
            className={classes.button}
            disabled={!isResponseAllowed}
            onClick={() => clickHandler(types[item])}
            phase={props.target?.phase}
          >
            {item}
          </PhaseColoredButton>
        ))}
      </Box>
    </Wrapper>
  );
}

CorrectIncorrect.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
};

export default CorrectIncorrect;
