import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import clonedeep from 'lodash.clonedeep';
import {
    Button, InputLabel, TextField, Grid, IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

export const defaultCategory = [{
    category: '',
    responses: [
        { value: 0, label: '' },
    ]
}, {
    category: '',
    responses: [
        { value: 0, label: '' },
    ]
}];

const CategoryData = (props) => {
    const classes = useTargetDataStyles();
    const { categories, setCategories, setSnackbar, itemError, disabled, isMobile } = props;

    const handleChangeCategory = id => event => {
        const newCategories = categories.slice();
        if ((newCategories[id].id || newCategories[id].id === 0) && !newCategories[id].preModifiedVersion) {
            newCategories[id].preModifiedVersion = { ...newCategories[id] };
        }
        newCategories[id].category = event.target.value;
        setCategories(newCategories);
    }

    const handleChangeResponse = (cateId, resId) => event => {
        const newCategories = categories.slice();
        if (newCategories[cateId].responses[resId].id && !newCategories[cateId].responses[resId].preModifiedVersion) {
            newCategories[cateId].responses[resId].preModifiedVersion = { ...newCategories[cateId].responses[resId] };
        }
        newCategories[cateId].responses[resId].label = event.target.value;
        setCategories(newCategories);
    }

    const handleAddCategory = () => {
        let newCategories = categories.slice();
        newCategories.push(clonedeep(defaultCategory[0]));
        setCategories(newCategories);
        if (newCategories.length === 5) {
            setSnackbar('warning', 'Maximum 5 categories allowed!');
        }
    }

    const handleAddResponse = (categoryId) => {
        const newCategories = categories.slice();
        let newCategory = newCategories[categoryId];
        const newResponse = clonedeep(defaultCategory[0].responses[0]);
        newCategory.responses.push(newResponse);
        if (newCategory.responses.length === 8) {
            setSnackbar('warning', 'Maximum 8 responses allowed!');
        }
        newCategories.splice(categoryId, 1, newCategory);
        setCategories(newCategories);
    }

    const handleDeleteCategory = (id) => () => {
        let newCategories = categories.slice();
        newCategories[id] = { ...newCategories[id], isDeleted: true };
        setCategories(newCategories);
    }

    const handleDeleteResponse = (cateId, resId) => {
        const newCategories = categories.slice();
        let newResponses = categories[cateId].responses;
        newResponses[resId] = { ...newResponses[resId], isDeleted: true };
        newCategories[cateId].responses = newResponses;
        setCategories(newCategories);
    }

    const trimmedCategories = useMemo(() => {
        return categories.map(e => e.category.trim());
    }, [categories])

    const checkCategoryError = (value) => {
        if (!value.category.trim()) {
            return "Category can't be empty";
        }
        if (checkDuplicates(trimmedCategories, value.category.trim())) {
            return "Category must be unique";
        }
        return " ";
    }

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <p id="target-form-category-section-heading" className={classes.wrapperTitle} style={{ textAlign: isMobile ? 'left' : 'center'}}>Category Data</p>
                {categories.length < 5 &&
                    <Grid item xs={12} className={classes.addCategory} container alignItems="center" justify="center">
                        <Button id="target-form-category-add" className={classes.saveBtn} onClick={handleAddCategory}>Add Category</Button>
                    </Grid>
                }
            </Grid>
            {categories.map((category, id) => {
                const categoryError = itemError[`category ${id}`] && checkCategoryError(category);
                return (
                    <>
                        <Grid item xs={6} className={classes.dataRowWrapper} key={`category${id}`}>
                            <InputLabel id={`target-form-category-name-field-label-${id}`} required>Category {id + 1}</InputLabel>
                            <TextField
                                id={`target-form-category-name-field-${id}`}
                                variant="outlined"
                                value={category.category}
                                onChange={handleChangeCategory(id)}
                                style={{ width: '85%' }}
                                error={!!categoryError && categoryError !== " "}
                                helperText={categoryError}
                                disabled={disabled}
                            />
                        </Grid>
                        {
                            category.responses.map((response, resId) => (
                                <Grid item xs={resId === 0 ? 6 : 12} className={classes.responseWrapper} key={`response${id}${resId}`} style={ resId === 0 ? { marginTop: '37px', placeSelf: 'flex-start' } : {}}>
                                    <Grid item xs={resId === 0 ? 12 : 6}>
                                        <InputLabel id={`target-form-category-response-field-label-${id}-${resId}`} required>Response {resId + 1}</InputLabel>
                                        <TextField
                                            id={`target-form-category-response-field-${id}-${resId}`}
                                            variant="outlined"
                                            value={response.label}
                                            onChange={handleChangeResponse(id, resId)}
                                            style={{ width: 'calc(100% - 55px)' }}
                                            error={itemError[`category ${id}`] && !response.label.trim()}
                                            helperText={itemError[`category ${id}`] && !response.label.trim() ? "Response cannot be empty": ' '}
                                            disabled={disabled}
                                        />
                                        {category.responses.length > 1 ?
                                            <IconButton
                                                id={`target-form-category-delete-response-${id}-${resId}`}
                                                disabled={disabled}
                                                className={classes.deleteBtn}
                                                onClick={() => handleDeleteResponse(id, resId)}
                                                tabIndex={-1}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            : null
                                        }
                                        {resId === category.responses.length -1 && category.responses.length < 8 ?
                                            <IconButton
                                                id={`target-form-category-add-response-${id}-${resId}`}
                                                disabled={disabled}
                                                className={classes.addBtn}
                                                onClick={() => handleAddResponse(id)}
                                                tabIndex={-1}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                            : null
                                        }
                                    </Grid>
                                </Grid>
                           ))
                        }
                        {(categories.length > 1) &&
                            <Grid item xs={12} className={classes.responseWrapper} container alignItems="center" justify="center">
                                <Button id={`target-form-category-delete-categor-${id}`} disabled={disabled} className={classes.saveBtn} onClick={handleDeleteCategory(id)}>Delete Category</Button>
                            </Grid>
                        }
                    </>
                )
            })}
        </Grid>
    )
}

CategoryData.propTypes = {
    categories: PropTypes.array.isRequired,
    setCategories: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    allowedToAdd: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isMobile: state.general.isMobile,
})

export default connect(mapStateToProps, { setSnackbar })(CategoryData)
