import React, { useEffect, useState, useMemo, createRef } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import MemoItem from './MemoItem';

const useStyles = makeStyles((theme) => ({}));

const MemoList = (props) => {
    const classes = useStyles();
    const { memos, scrollToEnd, fetchMemo } = props;
    const [search, setSearch] = useState('');

    const filteredMemo = useMemo(() => {
        if (!search) {
            return memos;
        }
        return memos.filter((memo) => memo.message.toLowerCase().includes(search.toLowerCase()));
    }, [search, memos]);

    useEffect(() => {
        scrollToEnd();
    }, [filteredMemo]);

    const handleSearch = (event) => {
        setSearch(event.target.value);
    };

    return (
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell colSpan={2}>
                        <TextField
                            autoComplete='search-field'
                            onChange={handleSearch}
                            value={search}
                            fullWidth
                            variant='outlined'
                            className={classes.searchField}
                            placeholder='Search'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </TableCell>
                </TableRow>

                {filteredMemo.map((memo) => (
                    <MemoItem fetchMemo={fetchMemo} memo={memo} key={memo.id} search={search} />
                ))}
            </TableBody>
        </Table>
    );
};

MemoList.propTypes = {
    memos: PropTypes.array.isRequired,
    scrollToEnd: PropTypes.func.isRequired,
    fetchMemo: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    memos: state.data.memos,
});

export default connect(mapStateToProps)(MemoList);
