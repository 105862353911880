import React, { useState, useRef, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Zoom from '@material-ui/core/Zoom';
import { connect } from 'react-redux';
import MemoList from './MemoList';
import { createMemo, listMemo, readMemo } from '../../../services/MemoService';
import { setLoading } from '../../../store/general/actions';
import { setMemos } from '../../../store/data/actions';
import NoMemo from './NoMemo';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import CustomOnboarding from '../../../assets/onboarding/CustomOnboarding';
import { memoSteps } from '../../../onboardingSteps';
import { setSnackbar } from '../../../store/general/actions';

const useStyles = makeStyles((theme) => ({
    searchField: {
        width: '100%',
    },
    searchWrapper: {
        padding: 20,
        backgroundColor: '#FFFFFF',
    },
    memoWrapper: {
        minHeight: 'calc(100vh - 70px)',
        maxHeight: 'calc(100vh - 70px)',
        overflowY: 'auto',
    },
    listWrapper: {
        overflowY: 'auto',
    },
    inputNew: {
        width: 'calc(100% - 30px)',
    },
    inputWrapper: {
        padding: 20,
        position: 'sticky',
        bottom: 0,
        backgroundColor: '#FFFFFF',
    },
    fabIcon: {
        position: 'fixed',
        bottom: 100,
        right: 40,
        color: '#FFFFFF',
    },
}));

const READ_DELAY = 500; // delay before updating memos to read

const Memo = (props) => {
    const classes = useStyles();
    const { patient, loading, setLoading, setMemos, unreadMemos, memos, setSnackbar } = props;
    const [newMemo, setNewMemo] = useState('');
    const memoRef = useRef();
    const [showFab, setShowFab] = useState(false);

    const scrollToEnd = () => {
        memoRef.current.scrollTop = memoRef.current.scrollHeight;
    };

    useEffect(() => {
        const showHideFab = () => {
            const shouldHide =
                Math.abs(
                    memoRef.current.scrollTop +
                        memoRef.current.clientHeight -
                        memoRef.current.scrollHeight
                ) < 20;
            setShowFab(!shouldHide);
        };
        memoRef.current.addEventListener('scroll', showHideFab);
        return () => {
            memoRef.current.removeEventListener('scroll', showHideFab);
        };
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (unreadMemos.length) {
                readMemo(unreadMemos.map((e) => e.readId)).then(() => {
                    fetchMemo();
                });
            }
        }, READ_DELAY);
    }, [unreadMemos]);

    const fetchMemo = () => {
        listMemo(patient.id).then((res) => {
            setMemos(res.data.data);
        });
    };

    const handleSubmit = () => {
        setLoading('createMemo');
        createMemo(newMemo, patient.id, patient.branch.id).then((res) => {
            setLoading('createMemo', false);
            setSnackbar('success', 'Memo saved!');
            setNewMemo('');
            fetchMemo();
        });
    };

    const handleChange = (event) => {
        setNewMemo(event.target.value);
    };

    if (!patient?.id) {
        return <NoMemo />;
    }

    return (
        <Grid container className={classes.memoWrapper} ref={memoRef}>
            <Grid item xs={12} className={classes.listWrapper}>
                <MemoList scrollToEnd={scrollToEnd} fetchMemo={fetchMemo} />
            </Grid>
            {CanUserFunc(access.notes.add) && (
                <Grid
                    item
                    xs={12}
                    className={classes.inputWrapper}
                    container
                    alignItems='flex-end'
                    id='memo-add'
                >
                    <Grid item xs={11}>
                        <TextField
                            id="notes-new-memo-field"
                            onChange={handleChange}
                            value={newMemo}
                            variant='outlined'
                            className={classes.inputNew}
                            placeholder='Add new memo'
                            multiline
                            rowsMax={4}
                            rows={1}
                            disabled={loading.createMemo}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        container
                        justify='center'
                        alignItems='flex-end'
                        style={{ paddingBottom: 8 }}
                    >
                        {loading.createMemo ? (
                            <CircularProgress />
                        ) : (
                            <Button id="notes-memo-add-button" onClick={handleSubmit} disabled={!newMemo.trim()}>
                                Add
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}
            <Zoom in={showFab}>
                <Fab
                    className={classes.fabIcon}
                    size='medium'
                    color='primary'
                    onClick={scrollToEnd}
                >
                    <KeyboardArrowDownIcon />
                </Fab>
            </Zoom>
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={memoSteps} /> */}
        </Grid>
    );
};

Memo.propTypes = {
    unreadMemos: PropTypes.array.isRequired,
    patient: PropTypes.object,
    loading: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    patient: state.patient.patient,
    loading: state.general.loading,
    unreadMemos: state.data.unreadMemos,
    memos: state.data.memos,
});

export default connect(mapStateToProps, { setLoading, setMemos, setSnackbar })(Memo);
