import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    Button, InputLabel, TextField, Grid, IconButton, Checkbox, Tooltip
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import useTargetDataStyles from './TargetDataFormStyles';
import { setSnackbar } from '../../../store/general/actions';
import checkDuplicates from '../../../utils/checkDuplicates';

export const defaultText = [{value: '', isRequired: false}];

const TextData = (props) => {
    const classes = useTargetDataStyles();
    const { texts, setTexts, setSnackbar, itemError, disabled, isMobile } = props;
    const [newTextList, setNewTextList] = useState([]);

    const specifyPreModifiedVersion = (newTexts, id) => {
        if (newTexts[id].id && !newTexts[id].preModifiedVersion) {
            newTexts[id].preModifiedVersion = { ...newTexts[id] };
        }
    }

    const handleChangeText = id => event => {
        const newTexts = texts.slice();
        specifyPreModifiedVersion(newTexts, id);
        newTexts[id].value = event.target.value;
        setTexts(newTexts);
    }

    const requiredFieldChangeHandler = id => event => {
        const newTexts = texts.slice();
        newTexts[id].isRequired = event.target.checked;
        setTexts(newTexts);
    }

    const addText = () => {
        const newTexts = texts.slice();
        const newData = defaultText[0];
        newTexts.push({...newData});
        setTexts(newTexts);
        if (newTexts.length === 5) {
            setSnackbar('warning', 'Maximum 5 columns allowed!');
        }
        setNewTextList([...newTextList, newTexts.length - 1]);
    }

    const handleDeleteTexts = (id) => () => {
        const newTexts = texts.slice();
        newTexts[id] = { ...newTexts[id], isDeleted: true };
        setTexts(newTexts);
        setNewTextList(newTextList.filter(t => t !== id));
    }

    const trimmedTexts = useMemo(() => {
        return texts.map(e => e.value.trim());
    }, [texts])

    const checkTextError = (value) => {
        if (!value.trim()) {
            return "Column name can't be empty";
        }
        if (checkDuplicates(trimmedTexts, value.trim())) {
            return "Column name must be unique";
        }
        return " ";
    }

    return (
        <Grid container className={classes.additionalDataWrapper}>
            <Grid item xs={12} style={{ position: 'relative' }}>
                <p id="target-form-text-section-heading" className={classes.wrapperTitle}>Text Data</p>
            </Grid>
            {texts.map((text, id) => {
                const textError = itemError[`text ${id}`] && checkTextError(text.value);
                return (
                    <Grid
                        container
                        item
                        xs={isMobile ? 12 : 6}
                        className={classes.dataRowWrapper}
                        key={`text${id}`}
                    >
                        <Grid item xs={4} style={{ paddingLeft: 5, position: 'relative' }}>
                            <InputLabel id={`target-form-text-required-field-label-${id}`} className={classes.plusMinusLabel}>
                                <span>Required</span>
                                <Tooltip title='The user will be required to enter data, if checked'>
                                    <InfoIcon className={classes.infoIcon} />
                                </Tooltip>
                            </InputLabel>
                            <div className={classes.requiredCheckboxWrapper} >
                                <Checkbox
                                    id={`target-form-text-required-field-${id}`}
                                    color="primary"
                                    checked={text.isRequired}
                                    onChange={requiredFieldChangeHandler(id)}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <InputLabel id={`target-form-text-column-field-label-${id}`} required>Column {id + 1}</InputLabel>
                            <TextField
                                id={`target-form-text-column-field-${id}`}
                                variant="outlined"
                                value={text.value}
                                onChange={handleChangeText(id)}
                                style={{ width: '80%' }}
                                error={!!textError && textError !== " "}
                                helperText={textError}
                                disabled={disabled && !newTextList.includes(id)}
                            />
                            {texts.length > 1 ?
                                <IconButton id={`target-form-text-section-delete-${id}`} disabled={disabled && !newTextList.includes(id)} className={classes.deleteBtn} onClick={handleDeleteTexts(id)} tabIndex={-1}>
                                    <CloseIcon />
                                </IconButton>
                                : null
                            }
                        </Grid>
                    </Grid>
                )
            })}
            {!isMobile && texts.length % 2 === 0 &&
                <Grid item xs={6} />
            }
            {texts.length < 5 &&
                <Grid item xs={isMobile ? 12 : 6} className={classes.addMoreWrapper} container alignItems="center" justify="center">
                    <Button id="target-form-text-add-more" className={classes.saveBtn} onClick={addText}>Add More</Button>
                </Grid>
            }
        </Grid>
    )
}

TextData.propTypes = {
    texts: PropTypes.array.isRequired,
    setTexts: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    itemError: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = state => ({
    isMobile: state.general.isMobile,
})

export default connect(mapStateToProps, { setSnackbar })(TextData)
