import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { InputLabel, Select, MenuItem, Grid, Divider, Button, Typography, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { subDays, format } from 'date-fns';
import debounce from 'awesome-debounce-promise';

import { setSnackbar } from '../../store/general/actions';
import * as SettingService from '../../services/SettingService';
import { access, dateRanges, elementsOptions, localStorageEnum, statuses } from '../../constants';
import { setUser } from '../../store/auth/actions';
import DateRangePicker from '../../assets/input/DateRangePicker';
import { getUsers } from '../../services/UserService';
import capitalize from '../../utils/capitalize';
import FilterDropdownButton from '../../assets/button/FilterDropdownButton';
import { CanUserFunc } from '../CanUser';
import SearchableSelect from '../SearchableSelect';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    additionalDataWrapperOuter: {
        padding: '0px 0px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    }
}));

const debouncedGetUsers = debounce(getUsers, 500);

const DefaultPrompt = (props) => {
    const classes = useStyles();
    const { user, setSnackbar } = props;
    const [users, setUsers] = useState([]);
    const [isUpdating, setIsUpating] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);
    const [startDate, setStartDate] = useState(subDays(new Date(), 14));
    const [endDate, setEndDate] = useState(new Date());
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [elements, setElements] = useState(elementsOptions);
    const showUsersFilter = CanUserFunc(access.users.view) && CanUserFunc(access.clients.view);
    const [filter, setFilter] = useState({
        status: 0,
        users: "all",
        dateRange: 1,
        customDate: {
            startDate: undefined,
            endDate: undefined
        },
        elements: elementsOptions,
    });
    
    useEffect(() => {
       showUsersFilter && debouncedGetUsers(null, null, null, null, true, user.branches, null, null, 'employee').then((res) => {
            setUsers(res.data.data.users);
        });

        SettingService.getSetting('user', user.id, 'filter')
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data?.data?.data;
                    if (data) {
                        setFilter(data);
                        data.elements && setElements(data.elements);

                        if(data.dateRange === 6) {
                            setEndDate(data.customDate?.endDate ? new Date(data.customDate.endDate) : null);
                            setStartDate(data.customDate?.endDate ? new Date(data.customDate.startDate) : null);
                        }
                    } else {
                        setFilter({
                            status: 0,
                            users: "all",
                            dateRange: 1,
                            customDate: {
                                startDate: undefined,
                                endDate: undefined
                            },
                            elements: elementsOptions,
                        });
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    }, []);
    
    const getCustomDateLabel = () => {
        if (filter.dateRange !== 6) return undefined;
        const startDateValue = startDate ? format(startDate, 'MM/dd/yyyy') : 'First Entry';
        const endDateValue = endDate ? format(endDate, 'MM/dd/yyyy') : 'Today';
        return `Date Range: ${startDateValue} - ${endDateValue}`;
    }
    
    const handleClose = (field) => (e) => {
        if(field === 'dateRange' && e.target.getAttribute("data-value") === '6') {
            setOpenDatePicker(true);
        }
    }

    const handleChange = (field) => (e) => {
        setIsUpating(true);
        setFilter({ ...filter, [field]: e.target?.value ?? e });
    };

    const handleSave = () => {
        setSettingSynced(false);
        const userObj = users.find(e => e?.id === filter.users || e?.id === filter.users?.id);
        const data = filter.dateRange === 6  ? 
        { ...filter, elements, users: userObj, customDate: { startDate, endDate } } : 
        { ...filter, elements, users: userObj, customDate : { startDate: undefined, endDate: undefined } };

        if (newSetting) {
            SettingService.createSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'filter',
                data,
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'filter',
                data,
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        }
        
        resetUserFilters();
    };

    const resetUserFilters = () => {
        let dataFilters = localStorage.getItem(localStorageEnum.dataFilters);
        if (dataFilters) {
            dataFilters = JSON.parse(dataFilters);
            dataFilters.users && dataFilters.users.forEach(user => user.checked = false);
    
            localStorage.setItem(localStorageEnum.dataFilters, JSON.stringify({ users: dataFilters.users }));
        }
        localStorage.setItem(localStorageEnum.analysisFilters, '');
        localStorage.setItem(localStorageEnum.dateFilters, '');
    }

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'filter')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setFilter(res.data?.data?.data);
                        const userSettings = user.settings;
                        userSettings.filter = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Filter Settings!');
                    } else {
                        setFilter({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };
    
    const handleChangeElement = (name) => (event) => {
        const newElements = elements;
        const changedElement = newElements.find((e) => e.name === name);
        changedElement.checked = !changedElement.checked;

        setIsUpating(true);
        setFilter({...filter, elements});
    };
    
    const handleCheckAll = () => {
        if (isAllChecked()) {
            elements.forEach((element) => {
                element.checked = false;
            });
        } else {
            elements.forEach((element) => {
                element.checked = true;
            });
        }
        setElements(elements.slice());
        setIsUpating(true);
    };

    const isAllChecked = () => {
        return !elements.some((element) => !element.checked);
    };

    const handleStartDate = (date) => {
        setStartDate(date);
        setIsUpating(true);
    }

    const handleEndDate = (date) => {
        setEndDate(date);
        setIsUpating(true);
    }

    return (
        <Grid item xs={7} container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Typography id="filter-settings-section-header" className={classes.title}>
                    Filter - Default Data Range, Status, User, Elements
                </Typography>
            </Grid>

            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item xs={showUsersFilter ? 3 : 4}>
                        <InputLabel id="filter-settings-date-range-field-label">Default Data Range</InputLabel>
                        <Select id="filter-settings-date-range-field" variant='outlined' IconComponent={KeyboardArrowDownIcon} className={classes.input} value={filter?.dateRange} onChange={handleChange('dateRange')} onClose={handleClose('dateRange')}>
                            {dateRanges.map((range) => (
                                <MenuItem key={range.value} value={range.value}>
                                    {(range.value === 6 && filter?.dateRange === 6) ? getCustomDateLabel() : range.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item xs={showUsersFilter ? 3 : 4}>
                        <InputLabel id="filter-settings-status-field-label">Default Status</InputLabel>
                        <Select id="filter-settings-status-field" variant='outlined' IconComponent={KeyboardArrowDownIcon} className={classes.input} value={filter?.status} onChange={handleChange('status')}>
                            {statuses.map((status, index) => (
                                <MenuItem key={status} value={index}>
                                    {capitalize(status)}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {showUsersFilter && <Grid item xs={3}>
                        <InputLabel id="filter-settings-user-field-label">Default User</InputLabel>
                        <SearchableSelect 
                            id="filter-settings-user-field"
                            entities={users} 
                            handleChange={handleChange('users')} 
                            selectedEntity={filter?.users?.id || filter?.users || 'all'}
                            hasAll
                            displayEntity={entity => entity === 'all' ? 'All' : `${entity?.firstName} ${entity?.lastName}`}
                            emptyMessage="Loading..."
                        />
                    </Grid>}
                    <Grid item xs={showUsersFilter ? 3 : 4}>
                        <InputLabel id="filter-settings-elements-field-label">Default Elements</InputLabel>
                        <FilterDropdownButton
                            id='filter-elements'
                            label='Elements'
                            filters={filter.elements.map((el) => ({ ...el, id: el.name }))}
                            handleChangeFilter={(el) => handleChangeElement(el.name)}
                            isAllChecked={isAllChecked()}
                            handleCheckAll={handleCheckAll}
                        />
                    </Grid>
                    <Grid item container xs={12} justify='space-between' direction='row-reverse'>
                        <Button id="filter-settings-save" onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </>
            )}
            <DateRangePicker
                startDate={startDate}
                setStartDate={handleStartDate}
                endDate={endDate}
                setEndDate={handleEndDate}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
            />
            <Divider className={classes.divider}/>
        </Grid>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps, { setUser, setSnackbar })(DefaultPrompt);