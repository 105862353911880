import React from 'react';
import DefaultPromptSetting from './DefaultPromptSetting';
import TrialNumberSetting from './TrialNumber';
import DisplayColumnsSetting from './DisplayColumns';
import LandingPageSetting from './LandingPage';
import FilterSetting from './Filter';
import MaintenanceSetting from './Maintenance';
import ZeroOutDataSetting from './ZeroOutData';
import MasteryLine from './MasteryLine';

const ZeraOutDataSettingsEnabled = false; //disabling under https://scopicsoftware.atlassian.net/browse/NA-1685

const UserSettings = (props) => {
    return (
        <>
            <DefaultPromptSetting />
            <TrialNumberSetting />
            <MasteryLine />
            <MaintenanceSetting />
            {ZeraOutDataSettingsEnabled && <ZeroOutDataSetting />}
            <LandingPageSetting />
            <FilterSetting />
            <DisplayColumnsSetting />
        </>
    )
}

export default UserSettings;