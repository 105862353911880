import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import FilterListIcon from '@material-ui/icons/FilterList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        padding: 20,
    },
    filter: {
        paddingRight: 5,
        color: '#000000',
        backgroundColor: '#ffffff',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    labels: {
        textTransform: 'capitalize',
    },
}));

const FilterDropdownButton = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();

    const showBadge = props.showChangeBadge && !props.isAllChecked;
    return (
        <div className={props.wrapperStyle} id={props.id}>
            <Badge color="error" variant="dot" invisible={!showBadge}>
                <Button className={classes.filter} variant="contained" onClick={(event) => setAnchorEl(event.currentTarget)}>
                    {props.label} <FilterListIcon style={{ color: '#0FB99E' }} />
                </Button>
                <Popover
                    open={!!anchorEl}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    classes={{
                        paper: classes.popoverPaper,
                    }}
                >
                    <FormGroup>
                        {props.handleCheckAll &&
                            <FormControlLabel
                                control={<Checkbox id={props.id ? `${props.id}_select_all` : undefined} color="primary" checked={props.isAllChecked} onChange={props.handleCheckAll} value="all" />}
                                label="All"
                            />
                        }
                        {props.filters.map(filter => (
                            <FormControlLabel
                                control={<Checkbox id={props.id ? `${props.id}_checkbox_${filter.id}` : undefined} color="primary" checked={filter.checked} onChange={props.handleChangeFilter(filter)} value="filter" />}
                                label={filter.label}
                                key={filter.id}
                                className={classes.labels}
                            />
                        ))}
                    </FormGroup>
                </Popover>
            </Badge>
        </div>
    );
}

FilterDropdownButton.defaultProps = {
    handleCheckAll: undefined,
    isAllChecked: false,
    wrapperStyle: null,
    showChangeBadge: false,
}

FilterDropdownButton.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    filters: PropTypes.array.isRequired,
    handleChangeFilter: PropTypes.func.isRequired,
    isAllChecked: PropTypes.bool,
    handleCheckAll: PropTypes.func,
    wrapperStyle: PropTypes.any,
    showChangeBadge: PropTypes.bool,
};

export default FilterDropdownButton;