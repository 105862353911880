import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: "0",
    },
    topDivider: {
        marginBottom: 10,
    },
    bottomDivider: {
        marginTop: 10,
    },
}));

const DividerWrapper = (props) => {
    const classes = useStyles();
    return (
        <>
            <Divider className={`${classes.divider} ${classes.topDivider}`} />
            {props.children}
            <Divider className={`${classes.divider} ${classes.bottomDivider}`} />
        </>
    );
};

export default DividerWrapper;
