import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, LabelList, Tooltip, Bar, BarChart } from 'recharts';
import clonedeep from 'lodash.clonedeep';
import {
    dateAxisSetting,
    defaultAxisSetting,
    getColor,
    graphHeight,
    defaultLineSetting,
    defaultLineChartSetting,
    toDateFull,
    renderCustomizedValue,
    defaultTooltipSetting,
    getMinMaxDate,
    generateDomainTicks,
    splitGraphDataByPhases,
    generateTargetName,
    percentAxisSetting,
    getTargetColor,
    defaultBarAxisSetting,
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand
const totalTrial = [{ codeDesc: 'Total Trial', id: 'Total Trial' }];

const ProbeGraph = (props) => {
    const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
    const [disabledLines, setDisabledLines] = useState({});
    const countTotalData = props.graphData
        .map((target) => target.targetData.length > 0 && target.customData.codes.length)
        .reduce((a, b) => a + b);
    const height = graphHeight + Math.floor(countTotalData / 7) * 25;

	const formattedData = useMemo(() => {
		const formattedData = [];
        props.graphData[0].customData.sets.map((e, index) => {
			let value = props.graphData[0].targetData[0].cumulative.sets[e.id]
			formattedData.push({
				step: e.value,
				correct: value?.percent,
                color: getTargetColor(props.graphData[0].targetData, index, 'light'),
			})
		});

		return formattedData;
	}, [props.graphData])

    const formattedPayload = useMemo(() => {
        const payload = [];
        props.graphData.forEach((target, targetIndex) => {
            if (props.switchGraphs) {
                if (disabledLines[target.name]) {
                    return;
                }
                target.targetData.length > 0 &&
                    target.targetData
                        .filter((e) => dateToDays(e.date) === hoveredDate)
                        .forEach((targetData) => {
                            payload.push({
                                date: hoveredDate,
                                color: getColor(targetIndex, 'light'),
                                name: target.name,
                                value:
                                    typeof targetData.value.percentage.correct === 'number'
                                        ? targetData.value.percentage.correct
                                        : null,
                            });
                        });
            } else {
                target.targetData.length > 0 &&
                    totalTrial.concat(target.customData.codes || []).forEach((item, index) => {
                        if (disabledLines[item.id]) return;

                        target.targetData
                            .filter((e) => dateToDays(e.date) === hoveredDate)
                            .forEach((targetData) => {
                                if (targetData.value?.count[item.id]) {
                                    payload.push({
                                        date: hoveredDate,
                                        color: getColor(index, 'light'),
                                        name: item.codeDesc,
                                        value: targetData.value.count[item.id],
                                    });
                                }
                            });
                    });
            }
        });
        return payload;
    }, [hoveredDate, props.graphData]);

    const { graphDomain, graphTicks } = useMemo(() => {
        let { graphDomain, graphTicks } = props;
        if (!graphDomain.length || !graphTicks.length) {
            const { minDate, maxDate } = getMinMaxDate(props.graphData);
            const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
            graphDomain = domain;
            graphTicks = ticks;
        }
        return { graphDomain, graphTicks };
    }, [props.graphDomain, props.graphTicks, props.graphData]);

    const formattedGraphData = useMemo(() => {
        const formattedData = [];
        const dateValueMap = {};
        let maxValue = 9;

        props.graphData.forEach((target) => {
            const newTarget = clonedeep(target);
            newTarget.targetData.length > 0 &&
                newTarget.targetData.forEach((targetData) => {
                    const date = dateToDays(targetData.date);
                    if (props.switchGraphs) {
                        const value = targetData.value.percentage;

                        const dateValue = `${date}-${value}`;
                        dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

                        targetData.value.percentage = {
                            value,
                            sameDot: dateValueMap[dateValue],
                        };
                    } else if (targetData.value?.count) {
                        Object.entries(targetData.value.count).forEach(
                            ([dataEntry, value]) => {
                                if (value > maxValue) {
                                    maxValue = value;
                                }

                                const dateValue = `${date}-${value}`;
                                dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

                                targetData.value.count[dataEntry] = {
                                    value,
                                    sameDot: dateValueMap[dateValue],
                                };
                            }
                        );
                    }
                });
            formattedData.push(newTarget);
        });

        const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
        formattedData.forEach((target) => {
            target.targetData.length > 0 &&
                target.targetData.forEach((targetData) => {
                    if (targetData.value?.count) {
                        Object.entries(targetData.value.count).forEach(([dataEntry, countObj]) => {
                            targetData.value.count[dataEntry] =
                                countObj.value + countObj.sameDot * dotMultiplier;
                        });
                    }
                });
        });

        return formattedData;
    }, [props.graphData]);

    const graphData = useMemo(() => {
        if (!props.showPhase) {
            return formattedGraphData;
        } else {
            return splitGraphDataByPhases(formattedGraphData);
        }
    }, [formattedGraphData, props.showPhase]);

    return props.switchGraphs ? (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis(
                    'date',
                    dateAxisSetting,
                    graphDomain,
                    graphTicks,
                    'Date',
                    !!props.graphElements.axisLabelsX
                )}
                {yAxis(
                    'percent',
                    percentAxisSetting,
                    'Percentage',
                    !!props.graphElements.axisLabelsY
                )}
                {enableTooltip && !props.isDownloading && (
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                valueFormatter={(value) => `${value}%`}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                )}
                {graphData.map((target, index) => {
                    return (
                        <Line
                            {...defaultLineSetting}
                            dot={true}
                            stroke={getTargetColor(target, index, 'light')}
                            dataKey='percent'
                            name={generateTargetName(target, index)}
                            key={generateTargetName(target, index)}
                            isAnimationActive={enableAnimation}
                            data={
                                !disabledLines[target.name]
                                    ? target.targetData.map((e) => ({
                                          date: dateToDays(e.date),
                                          percent:
                                              typeof e.value.percentage.value.correct === 'number'
                                                  ? e.value.percentage.value.correct
                                                  : null,
                                      }))
                                    : []
                            }
                        >
                            {props.graphElements.lineTitles && (
                                <LabelList
                                    dataKey='percent'
                                    content={(props) =>
                                        renderCustomizedValue(props, 0, -15, () => target.name)
                                    }
                                />
                            )}
                            {props.graphElements.dataValues && (
                                <LabelList
                                    dataKey='percent'
                                    content={(props) =>
                                        renderCustomizedValue(props, 3, 15, (e) =>
                                            typeof e === 'number' ? `${e}%` : null
                                        )
                                    }
                                />
                            )}
                        </Line>
                    );
                })}
            </LineChart>
        </ResponsiveContainer>
    ) : (
        <ResponsiveContainer height={height}>
			<BarChart {...defaultLineChartSetting} data={formattedData}>
                {DefaultGraphItems({ ...props, setDisabledLines, showPhase: false, legendPayload: []  }).map((e) => e)}
                {xAxis('step', defaultBarAxisSetting, undefined, undefined, '', false)}
                {yAxis(undefined, percentAxisSetting, 'Percentage', false)}
				{(enableTooltip && !props.isDownloading) &&
					<Tooltip
                    {...defaultTooltipSetting}
					/>
				}
                <Bar
                    fill={formattedData[0].color}
                    dataKey="correct"
                    isAnimationActive={enableAnimation}
                    unit="%"
                />
                {DefaultGraphItems({ ...props, setDisabledLines, showPhase: true, legendPayload: []  }).map((e) => e)[3]}
			</BarChart>
		</ResponsiveContainer>
    );
};

ProbeGraph.propTypes = {
    switchGraphs: PropTypes.bool,
    graphData: PropTypes.array.isRequired,
    graphElements: PropTypes.object.isRequired,
    graphDomain: PropTypes.array.isRequired,
    graphTicks: PropTypes.array.isRequired,
    showPhase: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    useCumulative: PropTypes.bool,
};

export default ProbeGraph;
