import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Popover, Grid, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'awesome-debounce-promise';

import { searchPatient } from '../../services/PatientService';
import { setLoading } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        padding: 20,
        width: '30vw',
		'@media (max-width: 768px)': {
            width: '70vw',
        },
    },
    input: {
        width: '100%',
    },
    list: {
        width: '100%',
        maxHeight: '20vh',
        overflowY: 'auto',
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
        color: '#ffffff',
        borderRadius: 10,
        '&:hover': { backgroundColor: '#00826d' },
    },
}));

const debouncedSearch = debounce(searchPatient, 500);

const UserClientConnection = (props) => {
    const classes = useStyles();
    const { onClick, onClose, anchorEl, sites, selectedClients, siteList, loading, setLoading } = props;
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (sites.length) {
            setSearchTerm('');
            handleSearch();
        } else {
            setClients([]);
        }
    }, [anchorEl])

    useEffect(() => {
        if (sites.length) {
            handleSearch();
        }
    }, [searchTerm, sites])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleSearch = () => {
        setLoading('searchUserClient');
        debouncedSearch(searchTerm, sites).then((res) => {
            setClients(res.data.data.patients);
            setLoading('searchUserClient', false);
        });
    };

    const handleClick = (client) => () => {
        onClick(client);
    }

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        placeholder="Search"
                        className={classes.input}
                        value={searchTerm}
                        onChange={handleChange}
                        helperText={`Sites: ${sites.length ?
                            sites.reduce((result, e) => {
                                const selectedSite = siteList.find(site => site.id === e);
                                if (selectedSite) {
                                    result.push(selectedSite.name);
                                }
                                return result;
                            }, []).join(', ') : 'Please select a site.'}`}
                    />
                </Grid>
                {loading.searchUserClient ?
                    <Grid item container xs={12} justify="center" alignItems="center">
                        <CircularProgress size={25} />
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <List className={classes.list}>
                            {clients.map(client => (
                                <React.Fragment key={client.id}>
                                    <ListItem
                                        alignItems="flex-start"
                                        className={`${classes.listItem} ${selectedClients.find(e => e.id === client.id) ? classes.listItemSelected : ''}`}
                                        button
                                        onClick={handleClick(client)}
                                    >
                                        <ListItemText
                                            primary={`${client.firstName} ${client.lastName} (${client.id})`}
                                            secondary={`${client.phone ? client.phone : ''}${client.email ? ` | ${client.email}` : ''}`}
                                        />
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                }
                {!loading.searchUserClient && !clients.length &&
                    <Grid item container xs={12} justify="center" alignItems="center">
                        <p>No client(s) found</p>
                    </Grid>
                }
            </Grid>
        </Popover>
    )
}

UserClientConnection.propTypes = {
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
    sites: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedClients: PropTypes.array.isRequired,
    siteList: PropTypes.array.isRequired,

    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading })(UserClientConnection)
