/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LoadingCheck from '../../../assets/progress/LoadingCheck';
import {
    addSessionData,
    deleteSessionData,
    endSession,
    cancelSession,
    pauseSession,
    resumeSession,
    setSessionTargets,
    syncSessionTargets,
    syncSessionData,
    setSessionLoading,
    setTargetChanges
} from "../../../store/session/actions";
import MinimizeIcon from "@material-ui/icons/Remove";
import RefreshIcon from "@material-ui/icons/Refresh";
import DataCollectionWidget from "./DataCollectionWidget";
import { reorderTargets } from "../../../services/sessionService";
import { format, parseISO } from "date-fns";
import { setGlobalDialog, closeGlobalDialog, setSnackbar } from "../../../store/general/actions";
import SessionHistory from "./SessionHistory";
import SessionTargetChanges from "./SessionTargetChanges";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { sessionDataSyncInterval } from "../../../constants";

import {
    Button,
    DialogTitle,
    DialogContent,
    Dialog,
    Grid,
    IconButton,
    createSvgIcon,
    DialogActions,
    Tooltip
} from "@material-ui/core";
import TargetActionMenu from "../../../assets/button/TargetActionMenu";

const ExpandIcon = createSvgIcon(
    <path d="M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z"></path>,
    "MaximizeIcon"
);

const useStyles = makeStyles((theme) => ({
    minimizeIconBtn: {
        padding: "0px 4px",
        color: "#0FB99E",
        height: 30,
    },
    refreshIconBtn: {
        padding: "0px 4px",
        color: "#0FB99E",
        height: 30,
        marginRight: 10
    },
    newDataBtn: {
        width: "30%",
        marginBottom: 20,
        marginTop: 20,
    },
    input: {
        width: "100%",
    },
    dialogPaper: {
        minWidth: "900px",
        height: '100vh',
        maxHeight: '100vh',
        [theme.breakpoints.down('sm')]: {
            minWidth: "90vw",
        },
    },
    dialogTitle: {
        '@media (max-width: 768px)': {
            padding: '15px 20px'
        }
    },
    titleBox: { maxWidth: '100%' },
    mobileDialogPaper: {
        minWidth: "100vw",
        minHeight: 'unset',
        maxHeight: 'unset',
        margin: 0,
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: 0,
    },
    dialogContainer: {
        maxWidth: '100vw',
        maxHeight: '100vh'
    },
    dialogHeader: {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'block',
        fontSize: '1.05rem',
        lineHeight: '19px'
    },
    contentContainer: {

    },
    mobileContentContainer: {
        padding: "18px 15px 18px 15px",
        display: 'flex',
        flexDirection: 'column',
    },
    allSessions: {
        position: "fixed",
        bottom: 0,
        left: 0,
        zIndex: 1300,
        display: "flex",
    },
    sessionTab: {
        marginRight: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    sessionTitleHolder: {
        display: "flex",
        flexDirection: "row",
    },
    minimizeTileIcon: {
        marginLeft: 10,
        fontSize: 16,
    },
    controlButtons: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    widgetContainer: {
        maxHeight: "85vh",
        minHeight: "25vh",
        overflowY: "auto",
        paddingRight: 20,
    },
    mobileWidgetContainer: {
        minHeight: "30vh",
        overflowY: "auto",
        flex: 1,
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    customDialogButton: {
        paddingRight: 0,
    },
    noActiveTargets: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        top: '15vh',
        transform: 'translateY(-50%)',
        position: 'relative',
    }
}));

const Session = (props) => {
    const isReady = useCallback(() => {
        return (
            props.sessions.activeSessionId &&
            props.sessions.sessions.find(
                (e) => e.id === props.sessions.activeSessionId
            )?.patientId === props.patient?.id
        );
    }, [props.patient, props.sessions.activeSessionId, props.sessions.sessions]);

    const classes = useStyles();
    const [open, setOpen] = useState(isReady());
    const [historyOpen, setHistoryOpen] = useState(false);
    const [targets, setTargets] = useState([]);
    const [currentSession, setCurrentSession] = useState(null);
    const [currentSessionId, setCurrentSessionId] = useState(null);
    const [sessionSyncTimeout, setSessionSyncTimeout] = useState(null);
    const [sessionTargetSyncCount, setSessionTargetSyncCount] = useState(0);
    const [sessionDataSyncCount, setSessionDataSyncCount] = useState(0);
    const [sessionSyncInitiated, setSessionSyncInitiated] = useState(false);
    const [sessionSyncInProgress, setSessionSyncInProgress] = useState(false);
    const [forceSessionSyncInProgress, setForceSessionSyncInProgress] = useState(false);
    const [sessionTargetChanges, setSessionTargetChanges] = useState(null);


    const getSessionTitle = (session) => {
        return `${props.patient?.firstName} ${props.patient?.lastName} ${session ? "- " + format(parseISO(session?.date), "MM/dd/yyyy") : ""
            }`;
    };

    const getSessionTitleAndDate = (session) => {
        return [
            `${props.patient?.firstName} ${props.patient?.lastName}`,
            session ? format(parseISO(session?.date), "MM/dd/yyyy") : undefined
        ];
    };

    useEffect(() => {
        if (isReady()) {
            const ongoingSession = props.sessions.sessions.find(
                (e) => e.id === props.sessions.activeSessionId
            );
            const sessionTargets = ongoingSession?.targets;
            if (Array.isArray(sessionTargets)) {
                setTargets(sessionTargets);
            }
            if (ongoingSession) {
                setCurrentSession(ongoingSession);
                setCurrentSessionId(ongoingSession.id);
                setSessionTargetSyncCount(ongoingSession.syncTargetCount);
                setSessionDataSyncCount(ongoingSession.syncDataCount);
                setSessionTargetChanges(ongoingSession.latestTargetsChanged);
            } else {
                setCurrentSessionId(null);
            }
            setOpen(true);
        } else {
            setCurrentSessionId(null);
            setOpen(false);
        }
    }, [props.sessions, props.patient, props.targets]);

    useEffect(() => {
        if (!currentSessionId) {
            sessionSyncTimeout && clearTimeout(sessionSyncTimeout);
        } else {
            syncSessionTargetsAfterTimeout(sessionDataSyncInterval);
        }
    }, [currentSessionId]);

    useEffect(() => {
        setSessionSyncInProgress(false);
        if (sessionSyncInitiated && sessionDataSyncCount && currentSessionId) {
            syncSessionTargetsAfterTimeout(sessionDataSyncInterval);
        }
    }, [sessionDataSyncCount]);

    useEffect(() => {
        const canSync = !forceSessionSyncInProgress && sessionSyncInitiated && sessionTargetSyncCount && currentSessionId
        if (canSync) {
            props.syncSessionData(currentSessionId);
        }

        if (forceSessionSyncInProgress) {
            if (!currentSession.latestTargetsChanged) {
                props.setSnackbar('success', 'No change detected');
            }
            setForceSessionSyncInProgress(false);
        }
    }, [sessionTargetSyncCount]);


    const syncSessionTargetsAfterTimeout = (time) => {
        setSessionSyncInitiated(true);
        const timeout = setTimeout(async () => {
            setSessionSyncInProgress(true);
            props.syncSessionTargets();
        }, time);
        setSessionSyncTimeout(timeout);
    };

    const forceSessionSync = () => {
        sessionSyncTimeout && clearTimeout(sessionSyncTimeout);
        setSessionSyncInProgress(true);
        setForceSessionSyncInProgress(true);
        props.syncSessionTargets();
    }

    const confirmDiscard = () => {
        if (targets?.length === 0) {
            props.endSession(props.sessions.activeSessionId);
            props.onSessionEnd();
        } else {
            props.setGlobalDialog(
                "Cancel Session?",
                "All the session data collected will be discarded, please proceed with caution.",
                (answer) => {
                    if (answer) {
                        props.cancelSession(props.sessions.activeSessionId);
                        props.onSessionEnd();
                    }
                }
            );
        }
    };

    const endSession = async () => {
        if (!currentSession?.loading) {
            try {
                props.setSessionLoading(currentSession.id, true);
                await props.endSession(props.sessions.activeSessionId);
                props.onSessionEnd();
            } catch (e) {
                if (e.request) {
                    props.setGlobalDialog(
                        "Unable to Save",
                        "Currently offline. Data cannot be saved.",
                        (answer) => { },
                        <DialogActions className={classes.customDialogButton}>
                            <Button onClick={props.closeGlobalDialog} autoFocus>
                                OK
                            </Button>
                        </DialogActions>,
                        false
                    );
                }
                props.setSessionLoading(currentSession.id, false);
            }
        }
    };

    const pauseSession = () => {
        props.pauseSession(props.sessions.activeSessionId);
    };

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const sortedTargets = targets.slice();
        sortedTargets.splice(result.destination.index, 0, sortedTargets.splice(result.source.index, 1)[0]);;
        setTargets(sortedTargets);
        props.setSessionTargets(currentSession.id, sortedTargets);
        reorderTargets(sortedTargets, currentSession.dataSheetId, currentSession.patientId);
    };

    const [sesName, sesDate] = getSessionTitleAndDate(currentSession);

    const menuButtons = useMemo(() =>
        [
            {
                name: 'Undo',
                onClick: () => { setHistoryOpen(true) },
                disabled: !currentSession?.data?.filter(e => e.responseSummary !== false).length || currentSession?.loading
            },
            {
                name: 'End without Saving',
                onClick: confirmDiscard,
                disabled: currentSession?.loading
            },
            {
                name: 'Save and End',
                onClick: endSession,
                disabled: currentSession?.loading || targets?.length === 0
            },
        ], [confirmDiscard, currentSession, endSession, targets])

    return (
        <>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                open={open}
                classes={{ paper: props.isMobile ? classes.mobileDialogPaper : classes.dialogPaper, container: classes.dialogContainer }}
            >
                <DialogTitle className={classes.dialogTitle}>
                    <Grid container>
                        <Grid item xs={8}>
                            <div className={classes.sessionTitleHolder}>
                                <div className={classes.titleBox}>
                                    <span id="session-modal-heading" className={classes.dialogHeader}>{sesName}</span>
                                    <span id="session-modal-heading" className={classes.dialogHeader}>{sesDate}</span>
                                </div>

                            </div>
                        </Grid>
                        <Grid item xs={4} container justify="flex-end" style={{ flexWrap: 'nowrap' }}>
                            <TargetActionMenu
                                buttonClassName={classes.refreshIconBtn}
                                buttons={menuButtons}
                            />
                            <IconButton
                                id="session-modal-refresh"
                                className={classes.refreshIconBtn}
                                onClick={forceSessionSync}
                                disabled={sessionSyncInProgress}
                            >
                                <Tooltip title="Fetch latest targets information"><RefreshIcon /></Tooltip>
                            </IconButton>
                            <IconButton
                                id="session-modal-minimize"
                                className={classes.minimizeIconBtn}
                                onClick={pauseSession}
                            >
                                <MinimizeIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={props.isMobile ? classes.mobileContentContainer : classes.contentContainer}>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={`${props.isMobile ? classes.mobileWidgetContainer : classes.widgetContainer} 
                                ${currentSession?.loading ? classes.loading : ''}`}
                                >
                                    {!currentSession?.loading && targets.map((target, index) => {
                                        return (
                                            <Draggable key={target.id} draggableId={target.id.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    ><DataCollectionWidget
                                                            session={currentSession}
                                                            onAddSessionData={props.addSessionData}
                                                            onDeleteSessionData={props.deleteSessionData}
                                                            target={target}
                                                        /></div>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {!currentSession?.loading && targets?.length === 0 && <div id="session-modal-no-active-target-message" className={classes.noActiveTargets}>
                                        No active targets for this patient.
                                    </div>}
                                    {provided.placeholder}
                                    {currentSession?.loading && <LoadingCheck
                                        show={true}
                                        size={48}
                                        className={classes.loader}
                                    />}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    {/* <Grid className={classes.controlButtons} item xs={12} container>
                        <Button
                            id="session-modal-cancel"
                            className={classes.newDataBtn}
                            onClick={confirmDiscard}
                            disabled={currentSession?.loading}
                        >
                            Cancel
                        </Button>
                        <Button
                            id="session-modal-undo"
                            className={classes.newDataBtn}
                            onClick={() => { setHistoryOpen(true) }}
                            disabled={!currentSession?.data?.filter(e => e.responseSummary !== false).length || currentSession?.loading}
                        >
                            Undo
                        </Button>
                        <Button
                            id="session-modal-save"
                            className={classes.newDataBtn}
                            onClick={endSession}
                            disabled={currentSession?.loading || targets?.length === 0}
                        >
                            Save
                        </Button>
                    </Grid> */}
                    {currentSession && <SessionHistory
                        open={historyOpen}
                        session={currentSession}
                        onClose={() => { setHistoryOpen(false) }}
                    />}
                    {sessionTargetChanges && <SessionTargetChanges
                        open={!!sessionTargetChanges}
                        changes={sessionTargetChanges}
                        onClose={() => { props.setTargetChanges(currentSessionId, null) }}
                    />}
                </DialogContent>
            </Dialog>
            <div className={classes.allSessions}>
                {props.sessions.sessions
                    .filter(
                        (e) =>
                            e.patientId === props.patient?.id &&
                            e.id !== props.sessions.activeSessionId
                    )
                    .map((session) => {
                        return (
                            <Button
                                className={classes.sessionTab}
                                onClick={() =>
                                    session.id === props.sessions.activeSessionId
                                        ? props.pauseSession(session.id)
                                        : props.resumeSession(session.id)
                                }
                                variant="contained"
                            >
                                {getSessionTitle(session)}
                                <ExpandIcon className={classes.minimizeTileIcon} />
                            </Button>
                        );
                    })}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    selection: state.general.selection,
    patient: state.patient.patient,
    sessions: state.sessions,
    user: state.auth.user,
    isMobile: state.general.isMobile,
});

Session.propTypes = {
    selection: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    sessions: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    endSession: PropTypes.func.isRequired,
    pauseSession: PropTypes.func.isRequired,
    resumeSession: PropTypes.func.isRequired,
    deleteSessionData: PropTypes.func.isRequired,
    setSessionTargets: PropTypes.func.isRequired,
    setSessionLoading: PropTypes.func.isRequired,
    onSessionEnd: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    closeGlobalDialog: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, {
    endSession,
    cancelSession,
    pauseSession,
    resumeSession,
    addSessionData,
    deleteSessionData,
    setSessionTargets,
    syncSessionTargets,
    syncSessionData,
    setSessionLoading,
    setGlobalDialog,
    closeGlobalDialog,
    setTargetChanges,
    setSnackbar
})(Session);
