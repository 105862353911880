import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog,
    InputLabel, Grid, IconButton, FormControlLabel, Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import { getUsers } from '../../services/UserService';
import OrganizationForm from './OrganizationForm';
import { roleNames } from '../../constants';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    largeWrapper: {
        marginBottom: 28,
    },
}));

const debouncedGetUsers = debounce(getUsers, 500);

const EditOrganization = (props) => {
    const classes = useStyles();
    const { onClose, open, organization, onSave, loading, user } = props;
    const [newOrganization, setNewOrganization] = useState({
        name: '',
        owner: {}
    });
    const [error, setError] = useState(false);
    const [users, setUsers] = useState([]);
    const [newUser, setNewUser] = useState();
    const [changeAccountOwner, setChangeAccountOwner] = useState(false);

    useEffect(() => {
        if (open) {
            let branches = [];
            organization.branches.map(b => branches.push(b.id));
            debouncedGetUsers(null, null, null, null, true, branches, null, null, 'employee').then((res) => {
                setUsers(res.data.data.users);
                let owner = res.data.data.users.find(u => u.id === organization.owner.id);    // the organization.owner user doesn't have the roles
                owner = { ...owner, companyId: organization.id, roles: owner.roles.filter(r => r.name !== roleNames.ORG_ADMIN)};
                setNewUser(owner);
                setNewOrganization({...organization, owner});
            });
        }
    }, [open])

    const handleCancel = () => {
        onClose();
    };

    const handleChange = field => event => {
        switch(field) {
            case 'roles':
                setNewUser({ ...newUser, roles: event });
                break;
            case 'ownerId':
                let owner = users.find(user => user.id === event);
                setNewOrganization({ ...newOrganization, owner, ownerId: event });
                break;
            case 'name':
                setNewOrganization({ ...newOrganization, name: event.target.value })
                break;
            case 'isActive':
                setNewOrganization({ ...newOrganization, isActive: event.target.value === 'active' });
                break;
            default:
                setNewOrganization({ ...newOrganization, [field]: event.target.value });
        }    
    }

    const handleSave = () => {
        if (!newOrganization.name.trim()) {
            setError(true);
            return;
        }

        if (changeAccountOwner && newUser.roles.length < 1) {
          setError(true);
          return;
        }

        onSave({
            ...newOrganization,
            owner: changeAccountOwner ? newOrganization.owner : undefined,
            oldOwnerNewRoleIds: changeAccountOwner ? newUser.roles.map(r => r.id) : undefined,
            name: newOrganization.name.trim(),
        });
    }
    
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="xl" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}> Edit Organization </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <OrganizationForm 
                    newOrganization={newOrganization} 
                    users={users}
                    updatedUser={newUser}
                    handleChange={handleChange}
                    error={error}
                    changeOwner={changeAccountOwner}
                    setChangeOwner={setChangeAccountOwner}
                >
                    <Grid item xs={6} className={classes.largeWrapper}>
                        <InputLabel>Status</InputLabel>
                        <Grid item container xs={12} >
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value='active'
                                    checked={!!newOrganization.isActive}
                                    onChange={handleChange('isActive')}
                                    control={
                                        <Radio
                                            name="radio-button-demo"
                                            color="primary"
                                        />
                                    }
                                    label="Active"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    value='inactive'
                                    checked={!newOrganization.isActive}
                                    onChange={handleChange('isActive')}
                                    control={
                                        <Radio
                                            name="radio-button-demo"
                                            color="primary"
                                        />
                                    }
                                    label="Inactive"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </OrganizationForm>
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button className={classes.saveBtn} onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Button className={classes.saveBtn} onClick={handleSave} disabled={loading.newOrganization || loading.editOrganization}>Save</Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

EditOrganization.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    organization: PropTypes.object,
    loading: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    user: state.auth.user,
    loading: state.general.loading,
})

export default connect(mapStateToProps)(EditOrganization)
