import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';

import { login } from '../../store/auth/actions';

const useStyles = makeStyles(theme => ({
    paper: {
        width: 350,
        padding: 30,
    },
    loginBtn: {
        padding: '6px 40px',
        width: '100%',
        marginBottom: 10,
    },
    input: {
        width: '100%',
        marginBottom: 30,
    },
    title: {
        textAlign: 'center',
        color: '#0FB99E',
        fontWeight: 'bold',
        fontSize: 24,
    },
    forgotPassword: {
        marginBottom: 20,
        fontSize: 12,
        color: '#a8a8a8'
    },
    error: {
        fontSize: 12,
        marginTop: 0,
        marginBottom: 10,
        color: 'red',
        textAlign: 'center',
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const { isLoggedIn, user, loading, loginError, login } = props;

    useEffect(() => {
        if (isLoggedIn) {
            const defaults = user.settings?.defaults;
            if (defaults) {
                if (defaults?.page) {
                    history.push(defaults.page);
                } else {
                    history.push('/');
                }
            } else {
                history.push('/');
            }
        }
    }, [isLoggedIn])

    const handleLogin = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (loading.userLogin) {
            return;
        }

        if (!username.trim() || !password) {
            setError(true);
            return;
        }

        login(username.trim(), password);
    }

    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleLogin}>
                <Grid container>
                    <Grid Item xs={12} container justify="center">
					    <img alt="Accel Logo" src={require("../../resources/AccelLogo.svg")} />
                    </Grid>
                    <Grid item xs={12}>
                        <p className={classes.title}>Welcome to Accel</p>
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="login-username-field-label">Username / Email</InputLabel>
                        <TextField
                            id="login-username-field"
                            variant="outlined"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className={classes.input}
                            error={error && !username.trim()}
                            helperText={error && !username.trim() ? "Username can't be empty" : ' '}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel id="login-password-field-label">Password</InputLabel>
                        <TextField
                            id="login-password-field"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.input}
                            InputProps={{
                                type: 'password',
                            }}
                            error={error && !password}
                            helperText={error && !password ? "Password can't be empty" : ' '}
                        />
                    </Grid>
                    <Grid item xs={12} container alignContent="center" justify="center">
                        <Button id="login-button" type="submit" className={classes.loginBtn} onClick={handleLogin} disabled={loading.userLogin}>
                            {loading.userLogin ?
                                'Loging in...'
                                :
                                'Login'
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {loginError ? <p id="login-form-error" className={classes.error}>{loginError}</p> : null}
                    </Grid>
                    <Grid item xs={12} container alignContent="center" justify="center">
			            <Link id="login-forgot-password-link" to={{ pathname: '/auth/forgot-password' }} className={classes.forgotPassword}>Forgot Password?</Link>
                    </Grid>
                    <Grid item xs={6} container alignContent="center" justify="center">
                        <a id="login-google-play-link" href='https://play.google.com/store/apps/details?id=com.northwest_aba_mobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' target="_blank">
                            <img alt='Get it on Google Play' src={require("../../resources/GooglePlayStoreBadge.svg")} height={40}/>
                        </a>
                    </Grid>
                    <Grid item xs={6} container alignContent="center" justify="center">
                        <a id="login-app-store-link" href='https://apps.apple.com/us/app/accel-practice/id1595495888' target="_blank">
                            <img alt="Download on the App Store" src={require("../../resources/AppStoreBadge.svg")} />
                        </a>
                    </Grid>
                </Grid>
            </form>

        </Paper>
    )
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
    loginError: PropTypes.string.isRequired,
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    user: state.auth.user,
    loading: state.general.loading,
    loginError: state.auth.loginError,
})

export default connect(mapStateToProps, { login })(Login)
