import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import NoMemo from './Memo/NoMemo';
import Memo from './Memo/Memo';

const useStyles = makeStyles(theme => ({
}));

const Notes = (props) => {
    const classes = useStyles();
    const { patient } = props;

    if (!patient?.id) {
        return (
            <NoMemo />
        )
    } else {
        return (
            <Memo />
        )
    }
}

Notes.propTypes = {
    patient: PropTypes.object,
}

const mapStateToProps = state => ({
    patient: state.patient.patient,
})

export default connect(mapStateToProps)(Notes)
