import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Wrapper from './Wrapper';
import TargetPopup from './TargetPopup';
import { useStyles } from '../style';
import PhaseColoredButton from './PhaseColoredButton';

const RatingScale = (props) => {
  const classes = useStyles();
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);

  const sendRating = () => {
    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      data,
      'Save ratings'
    );
    setData([]);
    setModal(false);
  };
  const handleData = (id, event) => {
    setData([
      ...data.filter((item) => item.questionId !== id),
      {
        questionId: id,
        answer: event.target.value,
      },
    ]);
  };
  const cancelModal = () => {
    setData([]);
    setModal(false);
  };
  const getRadioValue = (questionId) => {
    const questionObj = data?.find((item) => item.questionId === questionId);
    return questionObj ? parseInt(questionObj.answer) : 0;
  };
  return (
    <Wrapper {...props}>
      <PhaseColoredButton 
        className={classes.editButton} 
        onClick={() => setModal(true)}
        phase={props.target?.phase}
      >
        <Edit />
      </PhaseColoredButton>
      <TargetPopup
        open={modal}
        onSave={() => sendRating()}
        onClose={() => setModal(false)}
        onCancel={() => cancelModal()}
        title={props.target.name}
        saveDisabled={!data.length}
        isLarge={true}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          width="100%"
          padding={4}
        >
          {props.target?.customData?.filter(e => !e.isDeleted).map((item) => (
            <FormControl key={item.id}>
              <FormLabel>{item.question}</FormLabel>
              <RadioGroup
                value={getRadioValue(item.id)}
                onChange={(event) => handleData(item.id, event)}
              >
                {item.ratings?.filter(e => !e.isDeleted)?.map((el) => (
                  <FormControlLabel
                    key={el.value}
                    label={`${el.value}:${el.desc}`}
                    value={el.id}
                    control={<Radio color="primary" />}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          ))}
        </Box>
      </TargetPopup>
    </Wrapper>
  );
};

RatingScale.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
};

export default RatingScale;
