import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux';

import { logout } from '../store/auth/actions';
import { CanUserFunc } from './CanUser';
import { access } from '../constants';

const useStyles = makeStyles((theme) => ({
    avatar: {
        height: 40,
        width: 40,
        backgroundColor: 'transparent',
    },
    avatarBtn: {
        margin: 10,
        height: 40,
        width: 40,
        padding: 0,
        backgroundColor: '#0FB99E',
        '&:hover': {
            backgroundColor: '#00826d',
        },
    },
    topBorder: {
        borderTop: '2px solid #F4F4F4',
    },
    menuItem: {
        backgroundColor: '#FFFFFF',
    },
}));

const UserAvatar = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { user, logout } = props;
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
        logout();
        setAnchorEl(null);
    };

    const handleProgramTargetLibrary = () => {
        history.push('/user-setting/my-library');
        setAnchorEl(null);
    };

    const handleSettings = () => {
        history.push('/user-setting');
        setAnchorEl(null);
    };

    const generateInitial = () => {
        if (user.id) {
            const names = user.fullName.split(' ');
            return names[0].substring(0, 1) + names[names.length - 1].substring(0, 1);
        } else {
            return '';
        }
    };

    const handleUserInfo = () => {
        history.push('/user-setting/user-info');
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton id='user-avatar' onClick={handleClick} className={classes.avatarBtn}>
                <Avatar alt='Avatar' className={classes.avatar} src={user.profileUrl}>
                    {generateInitial()}
                </Avatar>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                <MenuItem
                    id='user-avatar-info'
                    onClick={handleUserInfo}
                    className={classes.menuItem}
                >
                    User Info
                </MenuItem>
                {CanUserFunc(access.clients.view) && (
                    <MenuItem
                        id='user-avatar-setting'
                        onClick={handleSettings}
                        className={classes.menuItem}
                    >
                        User Settings
                    </MenuItem>
                )}
                {CanUserFunc(access.clients.view) && (
                    <MenuItem
                        id='user-avatar-library'
                        onClick={handleProgramTargetLibrary}
                        className={classes.menuItem}
                    >
                        Program Library
                    </MenuItem>
                )}
                <MenuItem
                    id='user-avatar-logout'
                    className={classes.menuItem + ' ' + classes.topBorder}
                    onClick={handleLogout}
                >
                    Logout
                </MenuItem>

                {/* Onboarding Disabled */}
                {/* <CustomOnboarding steps={userAvatarMenuSteps} updateTrigger={anchorEl} run={!!anchorEl} /> */}
            </Menu>

            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={userAvatarSteps} /> */}
        </div>
    );
};

UserAvatar.propTypes = {
    user: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(UserAvatar);
