import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MaskedInput from "react-text-mask";

import withDataTableHoc from './DataTableHoc';
import sharedStyles from './styles';
import BasicDataLayout from './BasicDataLayout';
import { format, addMinutes } from 'date-fns'

const useStyles = makeStyles(theme => sharedStyles({
    cellWidth: 60
}));

const TextMaskDuration = (props) => {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[0-24]/,
                /\d/,
                ":",
                /[0-5]/,
                /\d/,
                ":",
                /[0-5]/,
                /\d/,
            ]}
            placeholderChar={"_"}
            showMask
        />
    );
};

const createCustomData = (data) => {
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    let start;
    let end = dateNow;
    for (let index = 0; index < data.value.length; index++) {
        start = end + 1;
        end = start + (parseInt(data.value[index] || 0, 10) || 0);
        customData.push([start, end]);
    }

    return customData;
}

const Duration = (props) => {
    const classes = useStyles();
    const { dataToUse, setEditingData } = props;

    const handleChange = (index, index2) => event => {
        let { value } = event.target;
        const inputParts = value?.split(':');
        if (inputParts?.length === 3 && !isNaN(inputParts[0]) && !isNaN(inputParts[1]) && !isNaN(inputParts[2])) {
            const newValue = 60 * 60 * parseInt(inputParts[0]) + 60 * parseInt(inputParts[1]) + parseInt(inputParts[2]);
            const newData = dataToUse;
            newData[index].value[index2] = newValue;
            setEditingData(newData.slice());
        }
    }

    const durationFormatter = (seconds) => {
        let duration = new Date(seconds * 1000);
        duration = addMinutes(duration, duration.getTimezoneOffset());
        return format(duration, 'HH:mm:ss')
    }

    const editComponent = (value, index, index2, DATA_PER_ROW, index3, isDisabled) => {
        value = durationFormatter(value);
        return (
            <TextField
                disabled={isDisabled}
                value={value}
                className={classes.textField}
                InputProps={{ 
                    classes: { input: classes.input },
                    inputComponent: TextMaskDuration,
                }}
                onChange={handleChange(index, (index2 * DATA_PER_ROW) + index3)}
                placeholder="1"
            />
        );
    }

    return (
        <BasicDataLayout editComponent={editComponent} classes={classes} {...props} valueFormatter={durationFormatter} />
    )
}

Duration.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default withDataTableHoc(Duration, 'duration', createCustomData);
