import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	ResponsiveContainer, LineChart, Line, LabelList, Tooltip,
} from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
	dateAxisSetting, graphHeight, defaultLineSetting, defaultAxisSetting,
	defaultLineChartSetting, getColor, toDateFull,
	renderCustomizedValue, defaultTooltipSetting, getMinMaxDate, generateDomainTicks,
	splitGraphDataByPhases, getTargetColor, generateTargetName
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const RateGraph = (props) => {
	const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
	const [disabledLines, setDisabledLines] = useState({});
	const countTotalData = props.graphData.length;
	const height = graphHeight + Math.floor(countTotalData / 7) * 25;

    useEffect(() => {
        const units = ['hour', 'minute'];
        const disabledLines = {};
        props.graphData.forEach(target => {
            units.forEach(unit => {
                if (unit !== props.rateUnit) {
                    disabledLines[`${target.name}: ${unit}`] = true;
                }
            })
        })
        setDisabledLines(disabledLines);

    }, [props.rateUnit])

	const formattedPayload = useMemo(() => {
		const payload = [];
		props.graphData.forEach((target, index) => {
			target.targetData
				.filter(e => dateToDays(e.date) === hoveredDate)
				.forEach((targetData) => {
                    if (props.useCumulative) {
                        payload.push({
                            date: hoveredDate,
                            color: getColor(index, 'light'),
                            name: `${target.name}: frequency`,
                            value: targetData.cumulative.frequency || '-',
                        });
                    } else {
                        if (!disabledLines[`${target.name}: minute`]) {
                            payload.push({
                                date: hoveredDate,
                                color: getColor(index, 'light'),
                                name: `${target.name}: minute`,
                                value: targetData.value.minute || '-',
                            });
                        }
                        if (!disabledLines[`${target.name}: hour`]) {
                            payload.push({
                                date: hoveredDate,
                                color: getColor(index, 'dark'),
                                name: `${target.name}: hour`,
                                value: targetData.value.hour || '-',
                            });
                        }
                    }
				})
		})
		return payload;
	}, [hoveredDate, disabledLines, props.graphData])

	const { graphDomain, graphTicks } = useMemo(() => {
		let { graphDomain, graphTicks } = props;
		if (!graphDomain.length || !graphTicks.length) {
			const { minDate, maxDate } = getMinMaxDate(props.graphData);
			const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
			graphDomain = domain;
			graphTicks = ticks;
		}
		return { graphDomain, graphTicks };
	}, [props.graphDomain, props.graphTicks, props.graphData]);

	const formattedGraphData = useMemo(() => {
		const formattedData = [];
		const dateValueMap = {};
		let maxValue = 9;

		props.graphData.forEach(target => {
			const newTarget = clonedeep(target);
			newTarget.targetData.length > 0 && newTarget.targetData.forEach(targetData => {
				const date = dateToDays(targetData.date);
                const valueMinute = targetData.value.minute;
                const valueHour = targetData.value.hour;
                const valueFrequency = targetData.cumulative.frequency;
                if (valueMinute > maxValue) {
                    maxValue = valueMinute;
                }
                if (valueHour > maxValue) {
                    maxValue = valueHour;
                }

                const dateValueHour = `${date}-${valueHour}`;
                dateValueMap[dateValueHour] = (dateValueMap[dateValueHour] ?? -1) + 1;
                targetData.value.hour = {
                    value: valueHour,
                    sameDot: dateValueMap[dateValueHour]
                }

                const dateValueMinute = `${date}-${valueMinute}`;
                dateValueMap[dateValueMinute] = (dateValueMap[dateValueMinute] ?? -1) + 1;					
                targetData.value.minute = {
                    value: valueMinute,
                    sameDot: dateValueMap[dateValueMinute]
                }

                const dateValueFrequency = `${date}-${valueFrequency}`;
                dateValueMap[dateValueFrequency] = (dateValueMap[dateValueFrequency] ?? -1) + 1;					
                targetData.cumulative.frequency = {
                    value: valueFrequency,
                    sameDot: dateValueMap[dateValueFrequency]
                }
			})
			formattedData.push(newTarget);
		})

		const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
		formattedData.forEach(target => {
			target.targetData.length > 0 && target.targetData.forEach(targetData => {
                const valueObjMinute = targetData.value.minute;
                const valueObjHour = targetData.value.hour;
                const valueObjFreq = targetData.cumulative.frequency;
                targetData.value.minute = valueObjMinute.value + (valueObjMinute.sameDot * dotMultiplier);
                targetData.value.hour = valueObjHour.value + (valueObjHour.sameDot * dotMultiplier);
                targetData.cumulative.frequency = valueObjFreq.value + (valueObjFreq.sameDot * dotMultiplier);
			})
		})

		return formattedData;
	}, [props.graphData])

	const graphData = useMemo(() => {
		if (!props.showPhase) {
			return formattedGraphData;
		} else {
			return splitGraphDataByPhases(formattedGraphData);
		}
	}, [formattedGraphData, props.showPhase])

	return (
        <ResponsiveContainer height={height}>
            <LineChart {...defaultLineChartSetting}>
                {DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
                {xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
                {yAxis('value', defaultAxisSetting, 'Count', !!props.graphElements.axisLabelsY)}
                {(enableTooltip && !props.isDownloading) &&
                    <Tooltip
                        {...defaultTooltipSetting}
                        content={
                            <CustomTooltip
                                labelFormatter={toDateFull}
                                formattedPayload={formattedPayload}
                                setHoveredDate={setHoveredDate}
                            />
                        }
                    />
                }
                {!props.useCumulative && graphData.map((target, index) => {
                    if (disabledLines[`${target.name}: minute`]) {
                        return null;
                    }
                    return (
                        <Line
                            {...defaultLineSetting}
                            stroke={getTargetColor(target, index, 'light')}
                            dataKey="value"
                            name={generateTargetName(target, index, ': minute')}
                            key={generateTargetName(target, index, ': minute')}
                            isAnimationActive={enableAnimation}
                            data={!disabledLines[`${target.name}: minute`] ?
                                target.targetData.map(e => (
                                    { date: dateToDays(e.date), value: e.value.minute || 0 }
                                )) : []
                            }
                        >
                            {props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: minute`)} />}
                            {props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15)} />}
                        </Line>
                    )}
                )}
                {!props.useCumulative && graphData.map((target, index) => {
                    if (disabledLines[`${target.name}: hour`]) {
                        return null;
                    }
                    return (
                        <Line
                            {...defaultLineSetting}
                            stroke={getTargetColor(target, index, 'dark')}
                            dataKey="value"
                            name={generateTargetName(target, index, ': hour')}
                            key={generateTargetName(target, index, ': hour')}
                            isAnimationActive={enableAnimation}
                            data={!disabledLines[`${target.name}: hour`] ?
                                target.targetData.map(e => (
                                    { date: dateToDays(e.date), value: e.value.hour || 0 }
                                )) : []
                            }
                        >
                            {props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: hour`)} />}
                            {props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15)} />}
                        </Line>
                    )}
                )}
                {props.useCumulative && graphData.map((target, index) => {
                    if (disabledLines[`${target.name}: frequency`]) {
                        return null;
                    }
                    return (
                        <Line
                            {...defaultLineSetting}
                            stroke={getTargetColor(target, index, 'light')}
                            dataKey="value"
                            name={generateTargetName(target, index, ': frequency')}
                            key={generateTargetName(target, index, ': frequency')}
                            isAnimationActive={enableAnimation}
                            data={!disabledLines[`${target.name}: frequency`] ?
                                target.targetData.map(e => (
                                    { date: dateToDays(e.date), value: e.cumulative.frequency || 0 }
                                )) : []
                            }
                        >
                            {props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: frequency`)} />}
                            {props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15)} />}
                        </Line>
                    )}
                )}
            </LineChart>
        </ResponsiveContainer>
	)
}

RateGraph.propTypes = {
	graphData: PropTypes.array.isRequired,
	graphElements: PropTypes.object.isRequired,
	graphDomain: PropTypes.array.isRequired,
	graphTicks: PropTypes.array.isRequired,
	showPhase: PropTypes.bool.isRequired,
    isDownloading: PropTypes.bool.isRequired,
    rateUnit: PropTypes.string.isRequired,
};

export default RateGraph;
