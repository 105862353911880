import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Popover from '@material-ui/core/Popover';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import ClientTable from './ClientTable';
import NewClient from './NewClient';
import { postNewPatient, updatePatient, downloadPatients, uploadPatients } from '../../services/PatientService';
import { setLoading, setSnackbar, setUploadDialog } from '../../store/general/actions';
import { getPatients as updatePatientDropdown } from '../../store/patient/actions';
import { getPatients } from '../../services/PatientService';
import { getSitesDropdown } from '../../services/SiteService';
import LoadingCheck from '../../assets/progress/LoadingCheck';
import urlDownload from '../../utils/urlDownload';
import CanUser from '../CanUser';
import { access, localStorageEnum } from '../../constants';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import MobileSearchInput from '../../assets/input/MobileSearchInput';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#FFFFFF',
        maxWidth: 'calc(100vw - 30px)',
        margin: 'auto',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 10px 15px 0px',
        flexWrap: 'nowrap',
    },
    searchField: {
        width: '100%',
    },
    popoverPaper: {
        padding: 20,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    upDownButton: {
        marginLeft: 10,
    },
    clientTable: {
        maxWidth: 'calc(100vw - 30px)',
        // paddingRight: 40
    }
}));

const statuses = ['all', 'active', 'inactive'];
const debouncedGetPatients = debounce(getPatients, 500);

const Client = (props) => {
    const classes = useStyles();
    const { loading, setLoading, setSnackbar, user, setUploadDialog, updatePatientDropdown, isMobile } = props;
    const [clients, setClients] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const isMounted = useRef(false);

    const [status, setStatus] = useState(statuses[1]);
    const [sites, setSites] = useState([]);
    const [search, setSearch] = useState('');
    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);

    const [anchorEl, setAnchorEl] = React.useState({
        site: null,
        status: null,
    });
    const [dialogOpen, setDialogOpen] = useState({
        newClient: false,
    });

    useEffect(() => {
        let clientFilters = localStorage.getItem(localStorageEnum.clientFilters);
        let parsedSites = [];

        if (clientFilters) {
            clientFilters = JSON.parse(clientFilters);
            setStatus(clientFilters.status);
            setSort(clientFilters.sort);
            parsedSites = clientFilters.sites;
        }

        getSitesDropdown().then((res) => {
            const newSites = [];
            const shouldCheckAll = !parsedSites.length || !parsedSites.some(e => e.checked) || !parsedSites.find(e => !e.checked);
            res.data.data.forEach(site => {
                newSites.push({ ...site, checked: shouldCheckAll || parsedSites.find(e => e.id === site.id)?.checked })
            });
            setSites(newSites);
        });

        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        const clientFilters = { status, sites, sort };
        localStorage.setItem(localStorageEnum.clientFilters, JSON.stringify(clientFilters));
    }, [status, sites, sort])

    useEffect(() => {
        fetchClient(false);
    }, [sort, status, search, sites])

    const fetchClient = (updateDropdown = true) => {
        setLoading('getClients');
        const sitesFilter = sites.some(e => e.checked)
            ? sites.filter(e => e.checked).map(site => site.id)
            : sites.map(site => site.id);
        debouncedGetPatients(
            search,
            sort.sortBy,
            sort.sortAs,
            skip,
            limit,
            status === 'active' ? true : status === 'inactive' ? false : undefined,
            sitesFilter,
        ).then((res) => {
            if (isMounted.current) {
                setClients(res.data.data.patients);
                setLoading('getClients', false);
            }
            if (updateDropdown) {
                updatePatientDropdown();
            }
        });
    }

    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = type => {
        setAnchorEl({ ...anchorEl, [type]: null });
    }

    const openDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: true });
    }

    const closeDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: false });
    }

    const handleChange = id => event => {
        const newSites = sites;
        const changedSite = newSites.find(e => e.id === id);
        changedSite.checked = !changedSite.checked;

        setSites(newSites.slice());
    };

    const handleChangeStatus = (e) => () => {
        setStatus(e);
        closeAnchorEl('status');
    }

    const handleSearch = event => {
        setSearch(event.target.value);
    }

    const handleCheckAll = () => {
        if (isAllChecked()) {
            sites.forEach(site => {
                site.checked = false;
            });
        } else {
            sites.forEach(site => {
                site.checked = true;
            });
        }
        setSites(sites.slice());
    }

    const isAllChecked = () => {
        return !sites.some(site => !site.checked);
    }

    const handleSave = (data) => {
        if (selectedClient) { // edit
            setLoading('editClient');
            updatePatient(data).then(() => {
                setLoading('editClient', false);
                setSnackbar('success', 'Client updated!');
                fetchClient();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('editClient', false);
            });
        } else {
            setLoading('newClient');
            postNewPatient(data).then(() => {
                setLoading('newClient', false);
                setSnackbar('success', 'Client added!');
                fetchClient();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('newClient', false);
            });
        }
    }

    const handleCloseDialog = () => {
        setSelectedClient(null);
        closeDialog('newClient')();
    }

    const handleEdit = (client) => {
        setSelectedClient(client);
        openDialog('newClient')();
    }

    const isFilterActive = (type) => {
        let isActive = false;
        switch (type) {
            case 'status':
                if (status !== 'all') isActive = true;
                break;

            case 'site':
                if (!isAllChecked() && sites.some(e => e.checked)) isActive = true;
                break;

            default:
                break;
        }

        return isActive;
    }

    const handleUpload = () => {
        setUploadDialog(
            'Upload clients',
            'Import client data using the provided template.',
            require("../../resources/templates/Client_Import_Template.xlsx"),
            'Client_Import_Template.xlsx',
            ['.xlsx'],
            uploadPatients,
            fetchClient,
        );
    }

    const handleDownload = () => {
        setLoading('downloadPatients');
        const sitesFilter = sites.some(e => e.checked)
            ? sites.filter(e => e.checked).map(site => site.id)
            : sites.map(site => site.id);
        downloadPatients(
            search,
            sort.sortBy,
            sort.sortAs,
            skip,
            limit,
            status === 'active' ? true : status === 'inactive' ? false : undefined,
            sitesFilter,
        ).then((res) => {
            urlDownload(res.data.data.path);
            setLoading('downloadPatients', false);
        });
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={isMobile ? 6: 3} className={classes.btnWrapper} container alignItems="center">
                <CanUser
                    perform={access.clients.add}
                    yes={() => (
                        <Button id="client-add" onClick={openDialog('newClient')} variant="contained">New Client <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} /></Button>
                    )}
                />
                <LoadingCheck show={loading.newClient} size={24} />
            </Grid>
            {!isMobile && <Grid item xs={6} className={classes.statusWrapper} id="client-search">
                <TextField
                    id="client-search-field"
                    autoComplete="search-field"
                    onChange={handleSearch}
                    value={search}
                    variant="outlined"
                    className={classes.searchField}
                    placeholder="First Name, Last Name, ID, Email, Site"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>}
            <Grid item xs={isMobile ? 6 : 3} className={classes.btnWrapper} container justify="flex-end">
                {isMobile && <MobileSearchInput onSearch={handleSearch} />}
                <CanUser
                    perform={access.clients.uploadDownload}
                    yes={() => (
                        <React.Fragment>
                            <TooltipedIconButton
                                id="client-download"
                                icon={<PublishIcon />}
                                onClick={handleUpload}
                                tooltip="Upload"
                                className={classes.upDownButton}
                            />
                            <TooltipedIconButton
                                id="client-upload"
                                icon={<GetAppIcon />}
                                onClick={handleDownload}
                                disabled={loading.downloadPatients || !clients.length}
                                tooltip="Download"
                                className={classes.upDownButton}
                            />
                        </React.Fragment>
                    )}
                />
            </Grid>

            <Grid item xs={12} className={classes.clientTable}>
                <ClientTable
                    setSkip={setSkip}
                    setSort={setSort}
                    setLimit={setLimit}
                    fetchClient={fetchClient}
                    clients={clients}
                    onEdit={handleEdit}
                    openFilter={openAnchorEl}
                    isFilterActive={isFilterActive}
                    sort={sort}
                />
            </Grid>

            <NewClient
                open={dialogOpen.newClient}
                onClose={handleCloseDialog}
                onSave={handleSave}
                selectedClient={selectedClient}
            />
            <Popover
                open={!!anchorEl.site}
                anchorEl={anchorEl.site}
                onClose={() => closeAnchorEl('site')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox color="primary" checked={isAllChecked()} onChange={handleCheckAll} value="all" />}
                        label="All"
                    />
                    {sites.map(site => (
                        <FormControlLabel
                            control={<Checkbox color="primary" checked={site.checked} onChange={handleChange(site.id)} value="site" />}
                            label={site.name}
                            key={site.id}
                        />
                    ))}
                </FormGroup>
            </Popover>

            <Menu
                id="status-menu"
                anchorEl={anchorEl.status}
                keepMounted
                open={Boolean(anchorEl.status)}
                onClose={() => closeAnchorEl('status')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {statuses.map(e => (
                    <MenuItem
                        key={e}
                        className={classes.menuItem}
                        selected={status === e}
                        onClick={handleChangeStatus(e)}
                    >
                        {e}
                    </MenuItem>
                ))}
            </Menu>
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={clientSteps} /> */}
        </Grid>
    )
}

Client.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setUploadDialog: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
    isMobile: state.general.isMobile
})

export default connect(mapStateToProps, { setSnackbar, setLoading, setUploadDialog, updatePatientDropdown })(Client);
