import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { setLoading, setSnackbar } from '../../store/general/actions'; 
import RolesTable from './RolesTable';
import NewRole from './NewRole';
import { postRole, updateRole, getRoles } from '../../services/RoleService';
import CanUser from '../CanUser';
import { access, localStorageEnum } from '../../constants';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 40px',
        '@media (max-width: 768px)': {
            padding: '10px 20px',
        }
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 0px 15px 0px',
        backgroundColor: '#FFFFFF'
    },
}));

const Roles = (props) => {
    const classes = useStyles();
    const { setSnackbar, setLoading, loading } = props;
    const [newOpen, setNewOpen] = useState(false);
    const [selectedRole, setSelectedRole] = useState(null);
    const [roles, setRoles] = useState([]);
    const isMounted = useRef(false);

    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);

    useEffect(() => {
        let roleFilters = localStorage.getItem(localStorageEnum.roleFilters);
        if (roleFilters) {
            roleFilters = JSON.parse(roleFilters);
            setSort(roleFilters.sort);
        }

        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        const roleFilters = { sort };
        localStorage.setItem(localStorageEnum.roleFilters, JSON.stringify(roleFilters));
    }, [sort])

    useEffect(() => {
        fetchRoles();
    }, [sort, skip, limit]);

    const fetchRoles = () => {
        setLoading('getRoles');
        if (isMounted.current) {
            getRoles(sort.sortBy, sort.sortAs, skip, limit).then((res) => {
                setRoles(res.data.data.roles);
                setLoading('getRoles', false);
            });
        }
    }

    const onEdit = (role) => {
        setSelectedRole(role);
        setNewOpen(true);
    }

    const handleClose = () => {
        setSelectedRole(null);
        setNewOpen(false);
    }

    const handleSave = (data) => {
        if (!selectedRole) { // new data
            setLoading('newRole');
            postRole(data).then(() => {
                setLoading('newRole', false);
                setSnackbar('success', 'Role added!');
                fetchRoles();
                handleClose();
            }).catch((err) => {
                setLoading('newRole', false);
            });
        } else {
            setLoading('editRole');
            updateRole(data).then(() => {
                setLoading('editRole', false);
                setSnackbar('success', 'Role updated!');
                fetchRoles();
                handleClose();
            }).catch((err) => {
                setLoading('newRole', false);
            });
        }
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={12} className={classes.btnWrapper} container alignItems="center">
                <CanUser
                    perform={access.roles.add}
                    yes={() => (
                        <Button id="role-add" onClick={() => setNewOpen(true)} variant="contained">New Role <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} /></Button>
                    )}
                />
                <LoadingCheck show={loading.newRole} size={24} />
            </Grid>

            <Grid item xs={12}>
                <RolesTable
                    onEdit={onEdit}
                    roles={roles}
                    setSort={setSort}
                    fetchRoles={fetchRoles}
                    sort={sort}
                />
            </Grid>

            <NewRole open={newOpen} onClose={handleClose} isEdit={!!selectedRole} roles={roles} role={selectedRole} onSubmit={handleSave} />
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={roleSteps} /> */}
        </Grid>
    )
}

Roles.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setSnackbar, setLoading, })(Roles);
