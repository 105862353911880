import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Popover, Grid, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import debounce from 'awesome-debounce-promise';

import { searchUser } from '../../services/UserService';
import { setLoading } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        padding: 20,
        width: '30vw',
    },
    input: {
        width: '100%',
    },
    list: {
        width: '100%',
        maxHeight: '20vh',
        overflowY: 'auto',
    },
    listItemSelected: {
        backgroundColor: '#0FB99E',
        color: '#ffffff',
        borderRadius: 10,
        '&:hover': { backgroundColor: '#00826d' },
    },
}));

const debouncedSearch = debounce(searchUser, 500);

const ClientUserConnection = (props) => {
    const classes = useStyles();
    const { onClick, onClose, anchorEl, site, selectedUsers, loading, setLoading } = props;
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        if (site.id) {
            setSearchTerm('');
        } else {
            setUsers([]);
        }
    }, [anchorEl])

    useEffect(() => {
        if (site.id) {
            handleSearch();
        }
    }, [searchTerm, site])

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleSearch = () => {
        setLoading('searchClientUser');
        debouncedSearch(searchTerm, [site.id]).then((res) => {
            setUsers(res.data.data.users);
            setLoading('searchClientUser', false);
        });
    };

    const handleClick = (user) => () => {
        onClick(user);
    }

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="client-form-user-search-field"
                        placeholder="Search"
                        className={classes.input}
                        value={searchTerm}
                        onChange={handleChange}
                        helperText={`Site: ${site.name ? site.name : 'Please select a site.'}`}
                    />
                </Grid>
                {loading.searchClientUser ?
                    <Grid item container xs={12} justify="center" alignItems="center">
                        <CircularProgress size={25} />
                    </Grid>
                    :
                    <Grid item xs={12}>
                        <List className={classes.list}>
                            {users.map(user => (
                                <React.Fragment key={user.id}>
                                    <ListItem
                                        id={`clients-new-form-user-${user.id}`}
                                        alignItems="flex-start"
                                        className={`${classes.listItem} ${selectedUsers.find(e => e.id === user.id) ? classes.listItemSelected : ''}`}
                                        button
                                        onClick={handleClick(user)}
                                    >
                                        <ListItemText
                                            primary={`${user.firstName} ${user.lastName} (${user.id})`}
                                            secondary={`${user.mobilePhone ? user.mobilePhone : ''}${user.email ? ` | ${user.email}` : ''}`}
                                        />
                                    </ListItem>
                                    <Divider variant="middle" component="li" />
                                </React.Fragment>
                            ))}
                        </List>
                    </Grid>
                }
                {!loading.searchClientUser && !users.length &&
                    <Grid item container xs={12} justify="center" alignItems="center">
                        <p>No user found.</p>
                    </Grid>
                }
            </Grid>
        </Popover>
    )
}

ClientUserConnection.propTypes = {
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
    site: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    selectedUsers: PropTypes.array.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading })(ClientUserConnection)
