import React, { useCallback, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

const Timer = (props) => {
    const { startTime, isPaused, consumedTimeFn, stopDuration, onStopDuration } =
        props;
    const timer = useRef(null);
    const [elapsedTime, setElapsedTime] = useState(0);

    const updateElapsedTime = useCallback(() => {
        let t = 0;
        if (startTime) {
            t = new Date().getTime() - startTime;
        } else if (consumedTimeFn) {
            t = consumedTimeFn();
        }
        if (stopDuration && stopDuration <= t) {
            t = stopDuration;
            clearInterval(timer.current);
            timer.current = null;
            onStopDuration && onStopDuration();
        }
        setElapsedTime(t);
    }, [consumedTimeFn, startTime]);

    const setupTimer = useCallback(() => {
        if (timer.current) {
            clearInterval(timer.current);
        }
        if (!startTime && !consumedTimeFn) {
            return;
        }
        timer.current = setInterval(() => {
            updateElapsedTime();
        }, 1000);
    }, [consumedTimeFn, startTime, updateElapsedTime]);

    useEffect(() => {
        if (startTime || consumedTimeFn) {
            updateElapsedTime();
            setupTimer();
        } else if (timer.current) {
            setElapsedTime(0);
            clearInterval(timer.current);
            timer.current = null;
        }
    }, [startTime, setupTimer, timer, consumedTimeFn, updateElapsedTime]);

    const prevPaused = useRef(false);

    useEffect(() => {
        if (isPaused) {
            if (timer.current) {
                clearInterval(timer.current);
            }
            prevPaused.current = true;
        } else if (prevPaused.current) {
            prevPaused.current = false;
            setupTimer();
        }
    }, [isPaused, setupTimer]);

    useEffect(() => {
        return () => {
            if (timer.current) {
                clearInterval(timer.current);
                timer.current = null;
                setElapsedTime(0);
            }
        };
    }, []);

    const secondsToTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${`${mins}`.padStart(2, "0")}:${`${secs}`.padStart(2, "0")}`;
    };

    return (
        <div>
            <span>{secondsToTime(Math.floor(elapsedTime / 1000))}</span>
        </div>
    );
};

Timer.propTypes = {
    startTime: PropTypes.string,
    isPaused: PropTypes.bool,
    consumedTimeFn: PropTypes.func,
    stopDuration: PropTypes.string,
    onStopDuration: PropTypes.func,
};

export default Timer;
