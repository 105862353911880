import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Grid,
    CircularProgress,
    makeStyles,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    FormControlLabel,
    Checkbox,
    Popover,
    FormGroup,
} from '@material-ui/core';
import { connect } from 'react-redux';
import Highlighter from 'react-highlight-words';
import CheckIcon from '@material-ui/icons/Check';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { dataTypeDict } from '../../constants';
import {
    Info,
    KeyboardArrowDown,
    KeyboardArrowRight,
    SubdirectoryArrowRight,
} from '@material-ui/icons';
import LoadingCheck from '../../assets/progress/LoadingCheck';
import { setGlobalDialog, setSnackbar } from '../../store/general/actions';
import NewTarget from '../Program/NewTarget';
import NewProgram from '../Program/NewProgram';
import useLazyLoadLibraryTargets from './useLazyLoadLibraryTargets';
import { updateTarget } from '../../services/TargetService';
import { updateProgram } from '../../services/ProgramService';
import { deleteLibrary, shareLibrary, unShareLibrary } from '../../services/LibraryService';
import LibrarySelectTarget from './LibrarySelectTarget';
import LibraryImport from './LibraryImport';

const dataType = {
    ...dataTypeDict,
    correctIncorrect: '+/-',
};

const useStyles = makeStyles((theme) => ({
    tableHeadRow: {
        fontWeight: 500,
        fontSize: 16,
        '& th': {
            verticalAlign: 'middle',
        },
        backgroundColor: '#FFFFFF',
    },
    table: {
        position: 'relative',
        tableLayout: 'auto',
    },
    tableCell: {
        fontSize: 14,
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    checkCell: {
        fontSize: 14,
        padding: '0px 12px',
        verticalAlign: 'middle',
    },
    iconCell: {
        width: 40,
        padding: 0,
    },
    progressWrapper: {
        paddingTop: 150,
        height: 550,
        backgroundColor: '#fff',
    },
    noTarget: {
        textAlign: 'center',
        marginTop: '30vh',
        fontSize: 36,
    },
    root: {
        position: 'relative',
    },
    iconButton: {
        padding: 0,
    },
    programRow: {
        backgroundColor: '#F4F4F4',
    },
    underline: {
        cursor: 'pointer',
        paddingBottom: 3,
        borderBottom: '1px solid black',
    },
    infoIcon: {
        color: '#0FB99E',
        marginLeft: 10,
    },
    filterButton: {
        marginLeft: 5,
        padding: 4,
        position: 'absolute',
        top: 13,
    },
    activeFilter: {
        color: '#0FB99E',
    },
    popoverPaper: {
        padding: 20,
    },
}));

const ProgramRow = ({
    library,
    classes,
    internalLoading,
    handleExpand,
    expandedPrograms,
    handleAction,
    highlight,
    openEdit
}) => (
    <TableRow onClick={openEdit(library)} className={classes.programRow}>
        <TableCell className={clsx(classes.tableCell, classes.iconCell)} align='center'>
            {!internalLoading[library.id] && (
                <IconButton onClick={handleExpand(library.id)} className={classes.iconButton}>
                    {expandedPrograms[library.id] ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </IconButton>
            )}
            {internalLoading[library.id] && <LoadingCheck size={14} show={true} />}
        </TableCell>
        <TableCell className={classes.tableCell} align='left'>
            <Highlighter
                searchWords={[highlight]}
                autoEscape={true}
                textToHighlight={library.program.name}
            />
        </TableCell>
        <TableCell className={classes.tableCell} align='center'>
            {library.targetLibraries && !library.targetLibraries.length && 'No Targets'}
        </TableCell>
        <TableCell className={classes.tableCell} align='center'></TableCell>
        <TableCell
            className={classes.tableCell}
            align='center'
        >{`${library.owner.firstName} ${library.owner.lastName}`}</TableCell>
        <TableCell className={classes.checkCell} align='center'>
            {library.isShared && <CheckIcon />}
        </TableCell>
        <TableCell className={classes.tableCell} align='center' valign='center'>
            {!internalLoading[`action-${library.id}`] && (
                <span onClick={handleAction(library)} className={classes.underline}>
                    Action
                </span>
            )}
            {internalLoading[`action-${library.id}`] && <LoadingCheck size={14} show={true} />}
        </TableCell>
    </TableRow>
);

const TargetRow = ({ library, programLib, classes, handleAction, internalLoading, highlight, openEdit }) => (
    <TableRow onClick={openEdit(library, true)}>
        <TableCell className={clsx(classes.tableCell, classes.iconCell)} align='right'>
            <SubdirectoryArrowRight />
        </TableCell>
        <TableCell className={classes.tableCell} align='left'>
            {programLib.program.name}
        </TableCell>
        <TableCell className={classes.tableCell} align='center'>
            <Highlighter
                searchWords={[highlight]}
                autoEscape={true}
                textToHighlight={library.target.name}
            />
        </TableCell>
        <TableCell className={classes.tableCell} align='center'>
            {dataType[library.target.dataType]}
        </TableCell>
        <TableCell
            className={classes.tableCell}
            align='center'
        >{`${library.owner.firstName} ${library.owner.lastName}`}</TableCell>
        <TableCell className={classes.checkCell} align='center'>
            {library.isShared && <CheckIcon />}
        </TableCell>
        <TableCell className={classes.tableCell} align='center' valign='center'>
            {!internalLoading[`action-${library.id}`] && (
                <span onClick={handleAction(library)} className={classes.underline}>
                    Action
                </span>
            )}
            {internalLoading[`action-${library.id}`] && <LoadingCheck size={14} show={true} />}
        </TableCell>
    </TableRow>
);

const LibraryTable = (props) => {
    const {
        libraries,
        setLibraries,
        forceExpands,
        search,
        loading,
        setSnackbar,
        user,
        setGlobalDialog,
    } = props;
    const classes = useStyles();

    const [internalLoading, setInternalLoading] = useState({});

    const [
        expandedPrograms,
        setExpandedPrograms,
        programsTargetLibraries,
        setProgramsTargetLibraries,
    ] = useLazyLoadLibraryTargets(libraries, internalLoading, setInternalLoading);

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLibrary, setSelectedLibrary] = useState(null);

    const [selectLibrariesOpen, setSelectLibrariesOpen] = useState(false);
    const [importLibrariesOpen, setImportLibrariesOpen] = useState(false);
    const [editTargetOpen, setEditTargetOpen] = useState(false);
    const [editProgramOpen, setEditProgramOpen] = useState(false);
    const [ownerFilter, setOwnerFilter] = useState({});
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [orderAsc, setOrderAsc] = useState(false);
    const [sharedAnchorEl, setSharedAnchorEl] = useState(null);
    const [sharedFilter, setSharedFilter] = useState('all');
    const [librariesSorted, setLibrariesSorted] = useState(false);
    
    const sortLibraries = (prop) => () => {
        let sortedArray = libraries.sort((a, b) => a.program[prop].toLowerCase() < b.program[prop].toLowerCase() ? 1 : -1 );
        if (!orderAsc) sortedArray.reverse();
        setLibraries(sortedArray);
        setLibrariesSorted(true);
        setOrderAsc(!orderAsc);
    }

    useEffect(() => {
        if (forceExpands) {
            const expanded = {};
            libraries.forEach((programLib) => {
                expanded[programLib.id] = true;
            });
            setExpandedPrograms(expanded);
        }
    }, [libraries]);

    const handleExpand = (libraryId) => (e) => {
        e.stopPropagation();
        const shouldExpand = !expandedPrograms[libraryId];
        setExpandedPrograms({ ...expandedPrograms, [libraryId]: shouldExpand });
    };

    const handleAction = (library, isTarget = false) => (e) => {
        e.stopPropagation();
        setSelectedLibrary({...library, isTarget});
        setAnchorEl(e.currentTarget);
    };

    const setActionLoading = (id, isLoading = true) => {
        if (Array.isArray(id)) {
            const loadings = {};
            id.forEach((id) => {
                loadings[`action-${id}`] = isLoading;
            });
            setInternalLoading({ ...internalLoading, ...loadings });
        } else {
            setInternalLoading({ ...internalLoading, [`action-${id}`]: isLoading });
        }
    };

    const handleEdit = () => {
        setAnchorEl(null);
        if (selectedLibrary.target) {
            setEditTargetOpen(true);
        } else {
            setEditProgramOpen(true);
        }
    };
    
    const openEdit = (library, isTarget = false) => (e) => {
        setSelectedLibrary({...library, isTarget});
        if (isTarget) {
            setEditTargetOpen(true);
        } else {
            setEditProgramOpen(true);
        }
    };

    const handleRemove = () => {
        setAnchorEl(null);
        setGlobalDialog(
            `Delete ${selectedLibrary.originalProgramId ? 'program' : 'target'}?`,
            `Are you sure you want to delete the ${selectedLibrary.originalProgramId ? 'program' : 'target'} from library?`,
            (answer) => {
                if (answer) {
                    setActionLoading(selectedLibrary.id, true);
                    deleteLibrary([selectedLibrary.id]).then((res) => {
                        const programId = selectedLibrary.programLibId;
                        if (programId) {
                            // this is a target library
                            programsTargetLibraries[programId] = programsTargetLibraries[
                                programId
                            ].filter((e) => e.id !== selectedLibrary.id);
                            setProgramsTargetLibraries({ ...programsTargetLibraries });
                            setSnackbar('success', 'Target library deleted!');
                        } else {
                            // this is a program library
                            setLibraries(libraries.filter((e) => e.id !== selectedLibrary.id));
                            setSnackbar('success', 'Program library deleted!');
                        }
                    });
                }
            }
        );
    };

    const handleShare = () => {
        setAnchorEl(false);
        if (!selectedLibrary.target) {
            // is program lib
            setSelectLibrariesOpen(true);
        } else {
            const programLib = libraries.find((lib) => lib.id === selectedLibrary.programLibId);
            if (!programLib.isShared) {
                setGlobalDialog(
                    "Share target's program?",
                    'In order to share this target, you should share the parent program as well. Continue?',
                    (answer) => {
                        if (answer) {
                            handleShareTarget([selectedLibrary.id, programLib.id]);
                        }
                    }
                );
            } else {
                handleShareTarget([selectedLibrary.id]);
            }
        }
    };

    const handleShareTarget = (libIds) => {
        setActionLoading(libIds, true);
        shareLibrary(libIds).then((res) => {
            const sharedLibraries = libraries.filter((e) => libIds.includes(e.id));
            sharedLibraries.forEach((lib) => {
                lib.isShared = true;
            });
            selectedLibrary.isShared = true;
            setActionLoading(libIds, false);
            setSnackbar('success', 'Library shared!');
        });
    };

    const handleImport = () => {
        setImportLibrariesOpen(true);
    };

    const getSelectedLibraryForImport = () => {
        if (selectedLibrary?.programLibId) {
            return [libraries.find((lib) => lib.id === selectedLibrary.programLibId)];
        }
        return selectedLibrary ? [selectedLibrary] : [];
    }

    const updateOneLibraryInLibraries = (updatedLibrary) => {
        const newLibraries = libraries.slice();
        newLibraries.forEach((library, index) => {
            if (library.id === updatedLibrary.id) {
                newLibraries[index] = updatedLibrary;
            }
        });
        setLibraries(newLibraries);
    }

    const onShare = (progLibs, targetLibs) => {
        const libId = selectedLibrary.id;
        setSelectLibrariesOpen(false);
        setActionLoading(libId, true);

        const ids = targetLibs[libId].map((e) => e.id);
        shareLibrary(ids.concat(libId)).then((res) => {
            selectedLibrary.isShared = true;

            if (!!programsTargetLibraries[libId]) {
                programsTargetLibraries[libId] = programsTargetLibraries[libId].map((e) => ({
                    ...e,
                    isShared: !!ids.includes(e.id),
                }));
                setProgramsTargetLibraries({ ...programsTargetLibraries });
            }
            updateOneLibraryInLibraries(selectedLibrary);

            setActionLoading(libId, false);
            setSnackbar('success', 'Library shared!');
        });
    };

    const handleUnShare = () => {
        const libId = selectedLibrary.id;
        setAnchorEl(false);
        setActionLoading(libId, true);

        unShareLibrary([libId]).then((res) => {
            selectedLibrary.isShared = false;

            if (!!programsTargetLibraries[libId]) {
                programsTargetLibraries[libId] = programsTargetLibraries[libId].map((e) => ({
                    ...e,
                    isShared: false,
                }));
                setProgramsTargetLibraries({ ...programsTargetLibraries });
            }
            updateOneLibraryInLibraries(selectedLibrary);

            setActionLoading(libId, false);
            setSnackbar('success', 'Library unshared!');
        });
    };

    const handleEditTarget = (target) => {
        setEditTargetOpen(false);
        setActionLoading(selectedLibrary.id, true);
        updateTarget(selectedLibrary.target.id, target).then((res) => {
            selectedLibrary.target = target;
            const newTargetIndex = programsTargetLibraries[
                selectedLibrary.programLibId
            ].findIndex((e) => e.id === selectedLibrary.id);
            programsTargetLibraries[selectedLibrary.programLibId][newTargetIndex].target = target;
            setProgramsTargetLibraries(programsTargetLibraries);

            setSnackbar('success', 'Target updated!');
            setActionLoading(selectedLibrary.id, false);
        });
        setSelectedLibrary(null);
    };

    const handleEditProgram = (program) => {
        setEditProgramOpen(false);
        setActionLoading(selectedLibrary.id, true);
        updateProgram(selectedLibrary.program.id, program.program).then(() => {
            selectedLibrary.program = { ...program.program, id: program.id };
            updateOneLibraryInLibraries(selectedLibrary);
            setSnackbar('success', 'Program updated!');
            setActionLoading(selectedLibrary.id, false);
        });
        setSelectedLibrary(null);
    };

    const filterLibraries = () => {
        const ownerIds = Object.keys(ownerFilter);
        const activeIds = ownerIds.filter((key) => !!ownerFilter[key]).map((e) => +e);
        const filteredLibraries = sharedFilter === 'all' ? libraries : libraries.filter(lib => lib.isShared === (sharedFilter === 'shared'));
        if (!activeIds.length) {
            return filteredLibraries;
        } else {
            return filteredLibraries.filter((e) => activeIds.includes(e.owner.id));
        }
    };

    const handleOwnerFilter = (id) => () => {
        setOwnerFilter({ ...ownerFilter, [id]: !ownerFilter[id] });
    };

    const handleCheckAllOwner = () => {
        const filter = {};
        owners.forEach((owner) => {
            filter[owner.id] = !isAllOwnerChecked;
        });
        setOwnerFilter({ ...filter });
    };

    const owners = useMemo(() => {
        const owners = [];
        libraries.forEach((lib) => {
            if (!owners.find((e) => e.id === lib.owner.id)) {
                owners.push(lib.owner);
            }
        });
        return owners;
    }, [libraries]);

    const ownerFilterActive = useMemo(() => {
        const ownerIds = Object.keys(ownerFilter);
        const activeIds = ownerIds.filter((key) => !!ownerFilter[key]);
        return activeIds.length && activeIds.length !== ownerIds.length;
    }, [ownerFilter]);

    const isAllOwnerChecked = useMemo(() => {
        const ownerIds = Object.keys(ownerFilter);
        const activeIds = ownerIds.filter((key) => !!ownerFilter[key]);
        return activeIds.length === owners.length;
    }, [ownerFilter, owners]);

    return (
        <div className={classes.root}>
            <Table className={classes.table}>
                <TableHead>
                    <TableRow className={classes.tableHeadRow}>
                        <TableCell align='center'></TableCell>
                        <TableCell align='left' style={{ width: '20%' }}>
                            <TableSortLabel 
                                id={'library-page-table-program-label'}
                                direction={(librariesSorted && !orderAsc) ? 'desc' : 'asc'} 
                                active={librariesSorted} 
                                onClick={sortLibraries('name')}
                            >Program</TableSortLabel>
                        </TableCell>
                        <TableCell align='center' style={{ width: '30%' }}>
                            Target
                        </TableCell>
                        <TableCell align='center' style={{ width: '15%' }}>
                            Data
                        </TableCell>
                        <TableCell
                            align='center'
                            style={{ width: '15%', paddingRight: 24, position: 'relative' }}
                        >
                            Owner
                            <IconButton
                                id={'library-page-table-owner-dropdown'}
                                className={classes.filterButton}
                                onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                            >
                                <KeyboardArrowDownIcon
                                    className={ownerFilterActive ? classes.activeFilter : ''}
                                />
                            </IconButton>
                        </TableCell>
                        <TableCell align='center' style={{ width: '5%' }}>
                            Shared
                            <IconButton
                                id={'library-page-table-shared-dropdown'}
                                className={classes.filterButton}
                                onClick={(e) => setSharedAnchorEl(e.currentTarget)}
                            >
                                <KeyboardArrowDownIcon className={sharedFilter !== 'all' ? classes.activeFilter : ''} />
                            </IconButton>
                        </TableCell>
                        <TableCell align='center'>Action</TableCell>
                    </TableRow>
                </TableHead>
                {!loading.getLibraries && (
                    <TableBody>
                        {libraries &&
                            filterLibraries().map((library) => (
                                <React.Fragment key={library.id}>
                                    <ProgramRow
                                        classes={classes}
                                        library={library}
                                        internalLoading={internalLoading}
                                        expandedPrograms={expandedPrograms}
                                        handleExpand={handleExpand}
                                        handleAction={handleAction}
                                        highlight={search}
                                        openEdit={openEdit}
                                    />
                                    {expandedPrograms[library.id] &&
                                        programsTargetLibraries[library.id]?.map((targetLib) => (
                                            <TargetRow
                                                key={targetLib.id}
                                                classes={classes}
                                                library={targetLib}
                                                programLib={library}
                                                handleAction={() => handleAction(targetLib, true)}
                                                internalLoading={internalLoading}
                                                highlight={search}
                                                openEdit={openEdit}
                                            />
                                        ))}
                                </React.Fragment>
                            ))}
                    </TableBody>
                )}
            </Table>
            {loading.getLibraries && (
                <Grid className={classes.progressWrapper} container justify='center'>
                    <CircularProgress size={300} />
                </Grid>
            )}
            {!loading.getLibraries && !libraries.length && (
                <p className={classes.noTarget}>No target to show</p>
            )}

            <NewProgram
                open={editProgramOpen}
                onClose={() => setEditProgramOpen(false)}
                updateProgram={handleEditProgram}
                program={selectedLibrary?.program}
                fromLibrary
                isFormDisabled={selectedLibrary?.owner?.id !== user.id && !user.isCompanyAdmin}
            />

            <NewTarget
                open={editTargetOpen}
                onClose={() => setEditTargetOpen(false)}
                addTarget={handleEditTarget}
                target={selectedLibrary?.target}
                fromLibrary
                libraries={libraries}
                isFormDisabled={selectedLibrary?.owner?.id !== user.id && !user.isCompanyAdmin}
                grayedOutFields={['status', 'masteredAt', 'openedAt']}
            />

            <LibrarySelectTarget
                open={selectLibrariesOpen}
                programLibraries={selectedLibrary ? [selectedLibrary] : []}
                onClose={() => setSelectLibrariesOpen(false)}
                onSubmit={onShare}
            />

            <LibraryImport
                open={importLibrariesOpen}
                programLibraries={getSelectedLibraryForImport()}
                onClose={() => setImportLibrariesOpen(false)}
            />

            <Popover
                anchorEl={filterAnchorEl}
                open={Boolean(filterAnchorEl)}
                onClose={() => setFilterAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="library-page-table-owner-dropdown-item-all"
                                color='primary'
                                checked={isAllOwnerChecked}
                                onChange={handleCheckAllOwner}
                                value='all'
                            />
                        }
                        label='All'
                    />
                    {owners.map((owner) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id={`library-page-table-owner-dropdown-item-${owner.id}`}
                                    color='primary'
                                    checked={ownerFilter[owner.id] || false}
                                    onChange={handleOwnerFilter(owner.id)}
                                />
                            }
                            label={`${owner.firstName} ${owner.lastName}`}
                            key={owner.id}
                        />
                    ))}
                </FormGroup>
            </Popover>
                          
            <Popover
                anchorEl={sharedAnchorEl}
                open={Boolean(sharedAnchorEl)}
                onClose={() => setSharedAnchorEl(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                classes={{ paper: classes.popoverPaper }}
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="library-page-table-shared-dropdown-item-all"
                                color='primary'
                                checked={sharedFilter === 'all'}
                                onChange={() => setSharedFilter('all')}
                                value='all'
                            />
                        }
                        label='All'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="library-page-table-shared-dropdown-item-shared"
                                color='primary'
                                checked={sharedFilter === 'shared'}
                                onChange={() => setSharedFilter("shared")}
                            />
                        }
                        label={'Shared'}
                        key={"shared"}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="library-page-table-shared-dropdown-item-unshared"
                                color='primary'
                                checked={sharedFilter === 'unshared'}
                                onChange={() => setSharedFilter("unshared")}
                            />
                        }
                        label={'Unshared'}
                        key={"unshared"}
                    />
                </FormGroup>
            </Popover>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {selectedLibrary?.owner?.id === user.id || user.isCompanyAdmin ? (
                    <MenuItem onClick={handleEdit}>Edit</MenuItem>
                ) : (
                    <Tooltip title='You cannot edit a shared library' placement='left'>
                        <span>
                            <MenuItem disabled>
                                Edit
                                <Info className={classes.infoIcon} />
                            </MenuItem>
                        </span>
                    </Tooltip>
                )}
                {selectedLibrary?.owner?.id === user.id || user.isCompanyAdmin ? (
                    <MenuItem onClick={handleRemove}>Delete</MenuItem>
                ) : (
                    <Tooltip title='You cannot delete a shared library' placement='left'>
                        <span>
                            <MenuItem disabled>
                                Delete
                                <Info className={classes.infoIcon} />
                            </MenuItem>
                        </span>
                    </Tooltip>
                )}
                {selectedLibrary?.owner?.id === user.id && !selectedLibrary?.isTarget &&
                    (selectedLibrary?.isShared ? (
                        <MenuItem onClick={handleUnShare}>Unshare</MenuItem>
                    ) : (
                        <MenuItem onClick={handleShare}>Share</MenuItem>
                    ))}
                {!selectedLibrary?.isTarget && <MenuItem onClick={handleImport}>Import</MenuItem>}
            </Menu>
        </div>
    );
};

LibraryTable.propTypes = {
    libraries: PropTypes.array.isRequired,
    setLibraries: PropTypes.func.isRequired,
    forceExpands: PropTypes.bool.isRequired,
    search: PropTypes.string.isRequired,
    loading: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.general.loading,
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar, setGlobalDialog })(LibraryTable);
