import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { InputLabel, Select, MenuItem, Grid, Divider, Button, Typography, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { setSnackbar } from '../../store/general/actions';
import * as SettingService from '../../services/SettingService';
import { localStorageEnum, pageNames } from '../../constants';
import { setUser } from '../../store/auth/actions';
import { CanUserFunc } from '../CanUser';
import SearchableSelect from '../SearchableSelect';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    input: {
        width: '100%',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '20px',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    }
}));

const LandingPage = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, patients } = props;
    const [isUpdating, setIsUpating] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);
    const [error, setError] = useState(null);
    const [defaults, setDefaults] = useState({ client: null, page: null });
    const showClients = useMemo(() => {
        const path = pageNames.find(e => e.path === defaults.page);
        return path ? path.showClients : true;
    }, [defaults]); 

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'defaults')
            .then((res) => {
                if (res.status === 200) {
                    const data = res.data?.data?.data;
                    if (data) {
                        setDefaults(data);
                    } else {
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    const handleChange = (field) => (e) => {
        setIsUpating(true);
        if (error && e.target?.value) {
            setError('');
        }
        setDefaults({ ...defaults, [field]: e.target?.value ?? e });
    };

    const validate = () => {
        const valid = !showClients || patients?.length > 0;
        if (!valid) {
            setError('Please select a client');
        }
        return valid;
    }

    const handleSave = () => {
        if (!validate()) {
            return;
        }
        setSettingSynced(false);
        if (newSetting) {
            SettingService.createSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'defaults',
                data: { 
                    page: defaults.page, 
                    client: showClients ? defaults.client : undefined 
                },
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'defaults',
                data: {
                    page: defaults.page, 
                    client: showClients ? defaults.client : undefined 
                },
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        }
        
        resetUserFilters();
    };

    const resetUserFilters = () => {
        let defaultsFilters = localStorage.getItem(localStorageEnum.defaultsFilters);
        if (defaultsFilters) {
            defaultsFilters = JSON.parse(defaultsFilters);
            localStorage.setItem(localStorageEnum.defaultsFilters, JSON.stringify(defaults));
        }
    }

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'defaults')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {

                        setDefaults(res.data?.data?.data);
                        const userSettings = user.settings;
                        userSettings.defaults = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Landing page Settings!');
                    } else {
                        setDefaults({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    return (
        <Grid item xs={7} container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Typography id="landing-page-setting-section-heading" className={classes.title}>
                    Landing Page and Client
                </Typography>
            </Grid>

            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item xs={6}>
                        <InputLabel id="landing-page-setting-page-field-label">Page</InputLabel>
                        <Select 
                            id="landing-page-setting-page-field"
                            variant='outlined' 
                            IconComponent={KeyboardArrowDown} 
                            className={classes.input} 
                            value={defaults?.page || 0} 
                            onChange={handleChange('page')}
                        >
                            <MenuItem key='placeholder' value={0} disabled={true}> Select Page </MenuItem>
                            {pageNames.map((page) => 
                                CanUserFunc(page.permission) && <MenuItem key={page.name} value={page.path}> {page.name} </MenuItem>
                             )}
                        </Select>
                    </Grid>
                    {showClients && 
                        <Grid item xs={6}>
                            <InputLabel id="landing-page-setting-client-field-label">Client</InputLabel>
                            <SearchableSelect 
                                id="landing-page-setting-page-field"
                                entities={patients} 
                                handleChange={handleChange('client')} 
                                selectedEntity={defaults?.client?.id || defaults?.client}
                                displayEntity={entity => entity ? `${entity?.firstName} ${entity?.lastName}` : 'Select Client'}
                                emptyMessage="No client found"
                                error={error}
                            />
                        </Grid>
                    }
                    <Grid item container xs={12} justify='space-between' direction='row-reverse'>
                        <Button id="landing-page-setting-save" onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </>
            )}
            <Divider className={classes.divider}/>
        </Grid>
    )
}

const mapStateToProps = state => ({
    user: state.auth.user,
    patients: state.patient.patients,
})

export default connect(mapStateToProps, { setUser, setSnackbar })(LandingPage);