import React from 'react'

function NotesIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1366_348)">
                <path d="M31.6666 20.0769C30.7449 20.0769 30 20.8236 30 21.7434V35.0769C30 35.9951 29.2532 36.7434 28.3334 36.7434H5C4.0799 36.7434 3.33344 35.9951 3.33344 35.0769V11.7434C3.33344 10.8252 4.0799 10.0769 5 10.0769H18.3334C19.2551 10.0769 20 9.33011 20 8.41031C20 7.4902 19.2551 6.74344 18.3334 6.74344H5C2.24335 6.74344 0 8.98679 0 11.7434V35.0769C0 37.8335 2.24335 40.0769 5 40.0769H28.3334C31.0901 40.0769 33.3334 37.8335 33.3334 35.0769V21.7434C33.3334 20.8218 32.5882 20.0769 31.6666 20.0769Z" fill="black" />
                <path d="M15.6265 18.5586C15.5099 18.6752 15.4315 18.8235 15.3982 18.9834L14.22 24.877C14.165 25.1501 14.2517 25.4318 14.4482 25.6302C14.6066 25.7885 14.8199 25.8734 15.0384 25.8734C15.0915 25.8734 15.1468 25.8685 15.2017 25.8569L21.0934 24.6786C21.2567 24.645 21.405 24.5669 21.5201 24.45L34.7067 11.2634L28.815 5.37198L15.6265 18.5586Z" fill="black" />
                <path d="M38.7799 1.29695C37.1552 -0.32811 34.5117 -0.32811 32.8882 1.29695L30.5817 3.60347L36.4734 9.49519L38.7799 7.18837C39.5667 6.40346 40 5.3567 40 4.24342C40 3.13014 39.5667 2.08339 38.7799 1.29695Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_1366_348">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default NotesIcon