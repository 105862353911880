import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  List,
  ListItem,
  ListItemText,
  Drawer,
  makeStyles,
  Divider,
  Box,
  Collapse,
} from "@material-ui/core";
import { Close, ExpandMore, ExpandLess } from "@material-ui/icons";

import { connect } from "react-redux";
import SimpleBar from "simplebar-react";

import { access, settingTypesEnum } from "../../constants";
import { logout } from "../../store/auth/actions";
import { setZendeskDialog } from "../../store/general/actions";
import CanUser from "../CanUser";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {},
  drawerPaper: {
    backgroundColor: "#0D3250",
    border: "none",
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 20,
  },
  iconButton: {
    color: "white",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 2,
    marginRight: 30,
  },
  list: {
    backgroundColor: "#0D3250",
    color: "#fff",
    position: "relative",
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 11,
    width: 300,
  },
  divider: {
    marginLeft: 29,
    marginRight: 29,
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  domain: {
    textTransform: "uppercase",
    fontSize: 14,
    fontWeight: 500,
    paddingLeft: 16,
  },
  accelLogo: {
    alignSelf: "flex-start",
    marginTop: 10,
    marginLeft: 35,
    marginBottom: 18,
  },
  allDomain: {
    marginTop: 6,
    paddingLeft: 24,
    "&:hover": { backgroundColor: "#00826d" },
  },
  listItem: {
    paddingLeft: 24,
    paddingRight: 32,
    "&:hover": { backgroundColor: "#00826d" },
  },
  listItemSelected: {
    backgroundColor: "#0FB99E",
  },
  collapseButton: {
    position: "absolute",
    right: 16,
    top: 5,
    zIndex: 5,
    padding: 5,
    color: "white",
    "&:hover": { backgroundColor: "#00826d" },
  },
  dot: {
    position: "absolute",
    top: 7,
    right: 7,
    width: 10,
    height: 10,
    backgroundColor: "#0FB99E",
    borderRadius: 50,
  },
  version: {
    margin: 0,
    fontSize: 14,
    color: "rgba(255, 255, 255, 0.2)",
    marginLeft: 35,
    marginRight: 30,
    position: "relative",
  },
  progress: {
    width: "100%",
  },
  visibilityIcon: {
    cursor: "pointer",
    position: "absolute",
    right: 0,
    bottom: -4,
    "&:hover": {
      color: "white",
    },
  },
  link: {
    color: "white",
    textDecoration: "none",
    "&:hover": { color: "#0FB99E" },
  },
  sublink: {
    textDecoration: "none",
    "&:hover": { color: "#0FB99E" },
    textAlign: "center",
    color: "#0D3250",
  },
  searchField: {
    width: "calc(100% - 30px)",
    margin: "10px",
    "& .MuiInputBase-root": {
      color: "white",
    },
    "& .MuiInputAdornment-root": {
      color: "white",
    },
  },
  disableSidebar: {
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(244, 244, 244, 0.2)",
  },
  menuItem: {
    paddingLeft: 24,
    paddingRight: 32,
    "&:hover": { backgroundColor: "#00826d" },
  },
  subMenuItem: {
    paddingLeft: 48,
    paddingRight: 32,
    "&:hover": { backgroundColor: "#00826d" },
  },
}));

const MobileSidebar = (props) => {
  const classes = useStyles();
  const { open, toggleSidebar } = props;

  const [isSubMenuOpen, setSubMenuOpen] = useState({});

  const handleSubMenuToggle = (index) => {
    setSubMenuOpen((prevState) => ({
      [index]: !prevState[index],
    }));
  };

  const [isSubSubMenuOpen, setSubSubMenuOpen] = useState({});

  const handleSubSubMenuToggle = (subIndex) => {
    setSubSubMenuOpen((prevState) => ({
      ...prevState,
      [subIndex]: !prevState[subIndex],
    }));
  };

  const routes = [
    {
      label: "Clinical",
      toplevelItems: [
        {
          label: "Program",
          route: "/",
          permission: access.targets.view,
        },
        {
          label: "Data",
          route: "/data",
          permission: access.data.view,
        },
        {
          label: "Analysis",
          route: "/analysis",
          permission: access.analysis.view,
        },
        {
          label: "Notes",
          route: "/notes",
          permission: access.notes.view,
        },
        {
          label: "Settings",
          route: "/settings",
          subroute: [
            {
              label: settingTypesEnum["reminder"],
              route: "/settings/reminder",
              isParent: true,
              permission: access.clients.view,
            },
            {
              label: settingTypesEnum["datasheet"],
              route: "/settings/data-sheet",
              permission: access.clients.view,
            },
          ],
          permission: access.clients.view,
        },
      ],
      permission:
        access.targets.view ||
        access.data.view ||
        access.analysis.view ||
        access.notes.view ||
        access.clients.view,
    },
    {
      label: "Admin",
      toplevelItems: [
        {
          label: "Users",
          route: "/admin/user",
          permission: access.users.view,
          special: true,
        },
        {
          label: "Roles",
          route: "/admin/user/role",
          permission: access.roles.view,
          special: true,
        },
        {
          label: "Devices",
          route: "/admin/user/devices",
          permission: access.devices.view,
          special: true,
        },
        {
          label: "Clients",
          route: "/admin/client",
          permission: access.clients.view,
          special: true,
        },
        {
          label: "Account Info",
          route: "/admin/subscription/account-info",
          permission: access.companies.view,
        },
        {
          label: "Sites",
          route: "/admin/subscription/site",
          permission: access.sites.view,
        },
      ],
      permission:
        access.users.view ||
        access.roles.view ||
        access.sites.view ||
        access.companies.view ||
        access.clients.view,
    },
    {
      label: "Support",
      toplevelItems: [
        {
          label: "Video Tutorials",
          route: "/",
          onClick: () => {
            window.open(
              "https://www.youtube.com/channel/UCRD832lL7Znw4ezL3Mr7Lrg",
              "_blank"
            );
          },
          permission: true,
          special: true,
        },
        {
          label: "Help Center",
          route: "/",
          onClick: () => {
            window.open("https://accelpractice.zendesk.com/hc/en-us", "_blank");
          },
          permission: true,
          special: true,
        },
        {
          label: "Contact Accel Support",
          route: "/",
          onClick: () => {
            props.setZendeskDialog();
          },
          permission: true,
          special: true,
        },
      ],
      permission: true, // Set the appropriate permission for support
    },
    {
      label: "User",
      toplevelItems: [
        {
          label: "User Info",
          route: "/user-setting/user-info",
          permission: true,
          special: true,
        },
        {
          label: "User Settings",
          route: "/user-setting",
          permission: access.clients.view,
          special: true,
        },
        {
          label: "Program Library",
          route: "/user-setting/my-library",
          permission: access.clients.view,
          special: true,
        },
        {
          label: "Logout",
          route: "/",
          onClick: () => {
            props.logout();
          },
          permission: access.clients.view,
          special: true,
        },
      ],
      permission: true, // Set the appropriate permission for user
    },
  ];

  return (
    <Drawer
      className={classes.drawer}
      open={open}
      variant="temporary"
      onClose={toggleSidebar}
      classes={{ paper: classes.drawerPaper }}
      anchor="left"
    >
      <List className={classes.list}>
        <SimpleBar style={{ maxHeight: "100vh" }} id="sidebar">
          <Box className={classes.header}>
            <img
              alt="Accel Logo"
              src={require("../../resources/AccelLogo.svg")}
              className={classes.accelLogo}
            />
            <Close onClick={toggleSidebar} className={classes.iconButton} />
          </Box>
        </SimpleBar>

        <Divider className={classes.divider} />
        {/* Menu */}
        {routes.map((route, index) => (
          <React.Fragment key={index}>
            <CanUser
              key={index}
              perform={route.permission}
              yes={() => (
                <ListItem
                  button
                  onClick={() => handleSubMenuToggle(index)}
                  className={classes.menuItem}
                >
                  <ListItemText primary={route.label} />
                  {isSubMenuOpen[index] ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
              )}
            />
            {/* Submenu */}
            {route.toplevelItems && route.toplevelItems.length > 0 && (
              <Collapse in={isSubMenuOpen[index]} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {route.toplevelItems.map(
                    (subroute, subIndex) => (
                      <CanUser
                        key={index}
                        perform={subroute.permission}
                        yes={() => (
                            <React.Fragment key={subIndex}>
                              {subroute?.subroute?.length > 0 ? (
                                <ListItem
                                  onClick={() => handleSubSubMenuToggle(subIndex)}
                                  button
                                  className={classes.subMenuItem}
                                >
                                  <ListItemText primary={subroute.label} />
                                  {isSubSubMenuOpen[subIndex] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItem>
                              ) : (
                                <ListItem
                                  onClick={() => handleSubSubMenuToggle(subIndex)}
                                  button
                                  className={classes.subMenuItem}
                                >
                                  <Link
                                    className={classes.link}
                                    style={{ width: "100%" }}
                                    id={`route-link-${subroute.route}`}
                                    to={subroute.route}
                                    onClick={(e) => {
                                      if (subroute.onClick) {
                                        subroute.onClick();
                                        e.preventDefault();
                                      }
                                      toggleSidebar();
                                    }}
                                  >
                                    <ListItemText
                                      style={{ width: "100%" }}
                                      primary={subroute.label}
                                    />
                                  </Link>
                                </ListItem>
                              )}
    
                              {/* Subsubmenu */}
                              {subroute.subroute &&
                                subroute.subroute.length > 0 && (
                                  <Collapse
                                    in={isSubSubMenuOpen[subIndex]}
                                    timeout="auto"
                                    unmountOnExit
                                  >
                                    <List component="div" disablePadding>
                                      {subroute.subroute.map(
                                        (subsubroute, subsubIndex) =>
                                          subsubroute.permission && (
                                            <ListItem
                                              button
                                              key={subsubIndex}
                                              className={classes.subMenuItem}
                                            >
                                              <Link
                                                className={classes.link}
                                                style={{ width: "100%" }}
                                                id={`route-link-${subsubroute.route}`}
                                                to={subsubroute.route}
                                                onClick={toggleSidebar}
                                              >
                                                <ListItemText
                                                  primary={subsubroute.label}
                                                />
                                              </Link>
                                            </ListItem>
                                          )
                                      )}
                                    </List>
                                  </Collapse>
                                )}
                            </React.Fragment>
                          
                        )}
                      />
                    )

                  )}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
};

MobileSidebar.propTypes = {
  user: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  setZendeskDialog: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout, setZendeskDialog })(
  MobileSidebar
);
