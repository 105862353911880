import { localStorageEnum } from '../../constants';
import { v4 as uuidv4 } from 'uuid';
import {
    START_SESSION,
    PAUSE_SESSION,
    RESUME_SESSION,
    END_SESSION,
    ADD_SESSION_DATA,
    DELETE_SESSION_DATA,
    DELETE_ALL_SESSION_DATA,
    SET_SESSION_LOADING,
    SET_SESSION_CONFIG,
    LOAD_SESSIONS,
    SET_SESSION_TARGETS,
    SYNC_SESSION_TARGETS,
    SYNC_SESSION_DATA,
    SET_TARGET_CHANGES
} from './action-types';
import { isSameDay } from 'date-fns';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/EncryptionService';
import { updateSessionBasedOnNewTargetData, getTargetChanges } from '../../services/sessionSyncService';

const initialState = {
    sessions: [],
    activeSessionId: null,
    sessionConfig: null,
    sessionLoading: false,
};

const getSessionFromLocalStorage = (state) => {
    //sessions in localstorage can be empty when user starts a session for the first time
    const sessionJson = getLocalStorageItem(localStorageEnum.sessions);
    const sessions = sessionJson ? JSON.parse(sessionJson) : [];

    //locastorage doesn't support File objects.
    //the file objects should be restored from the session state in memory.
    sessions.forEach((session) => {
        const newData = [];
        session.data.forEach((item) => {
            if (item?.dataType !== 'imageVideo') {
                newData.push(item);
            }
        });
        const sessionFromMemory = state?.sessions?.find(e => e.id === session.id);
        if (!!sessionFromMemory && sessionFromMemory.data) {
            sessionFromMemory.data.forEach((dataFromMemory) => {
                if (dataFromMemory?.dataType === 'imageVideo') {
                    newData.push(dataFromMemory);
                }
            })
        }
        session.data = newData;
    });
    return sessions;
}

export default function (state = initialState, action) {
    let sessions, existingSession, activeSessionId, existingSessionIndex, sessionConfig, savedSessions, savedActiveSessionId, savedSessionConfig;
    switch (action.type) {
        case START_SESSION:
            let activeSession = state.sessions.find(e =>
                action.payload.patientId === e.patientId
                && action.payload.dataSheetId === e.dataSheetId
                && isSameDay(new Date(action.payload.date), new Date(e.date))
            );
            if (activeSession) {
                activeSessionId = activeSession.id;
                setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(activeSessionId));
                return {
                    ...state,
                    activeSessionId,
                };
            } 
            sessions = getSessionFromLocalStorage(state);
            activeSession = action.payload;
            activeSessionId = uuidv4();
            activeSession.id = activeSessionId;
            activeSession.data = [];
            sessions.push(activeSession);
            setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(activeSessionId));
            setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
            return {
                ...state,
                sessions,
                activeSessionId,
            };

        case PAUSE_SESSION:
            if (state.activeSessionId === action.payload) {
                setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(null));
                return {
                    ...state,
                    activeSessionId: null,
                };
            }
            return state;

        case RESUME_SESSION:
            if (state.sessions.find(e => e.id === action.payload)) {
                setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(action.payload));
                return {
                    ...state,
                    activeSessionId: action.payload,
                };
            }
            return state;

        case END_SESSION:
            const sessionIndexToEnd = state.sessions.findIndex(e => e.id === action.payload);
            if (sessionIndexToEnd !== -1) {
                sessions = getSessionFromLocalStorage(state);
                sessions.splice(sessionIndexToEnd, 1);
                if (state.activeSessionId === action.payload) {
                    const activeSessionId = null;
                    setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(activeSessionId));
                    setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                    return { ...state, sessions, activeSessionId };
                }
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;

        case ADD_SESSION_DATA:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                session.data = session.data.slice();
                session.data.push({ ...action.payload, id: (new Date()).getTime() });
                sessions[existingSessionIndex] = session;
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;

        case DELETE_SESSION_DATA:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                const data = session.data.slice();
                const exisitingDatumIndex = data.findIndex(e => e.id === action.payload.dataId);
                if (!isNaN(exisitingDatumIndex)) {
                    data.splice(exisitingDatumIndex, 1);
                    session.data = data;
                    sessions[existingSessionIndex] = session;
                }
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;
        
        case DELETE_ALL_SESSION_DATA:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                session.data = [];
                sessions[existingSessionIndex] = session;
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;
        
        case SET_SESSION_LOADING:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                session.loading = action.payload.isLoading;
                sessions[existingSessionIndex] = session;
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;
        
        case SET_SESSION_CONFIG:
            sessionConfig = { ...state.sessionConfig };
            sessionConfig[action.payload.key] = action.payload.value;
            setLocalStorageItem(localStorageEnum.sessionConfig, JSON.stringify(sessionConfig));
            return { ...state, sessionConfig };
        
        case SET_SESSION_TARGETS:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                session.targets = action.payload.targets;
                sessions[existingSessionIndex] = session;
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;
        
        case SYNC_SESSION_TARGETS:
            sessions = getSessionFromLocalStorage(state);
            sessions.forEach((existingSession, index) => {
                sessions[index] = updateSessionBasedOnNewTargetData({ ...existingSession }, action.payload.targets);
                sessions[index].latestTargetsChanged = getTargetChanges(existingSession, action.payload.targets);
                sessions[index].syncTargetCount = !sessions[index].syncTargetCount ? 1 : (sessions[index].syncTargetCount + 1);
            });
            setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
            return { ...state, sessions };

        case SYNC_SESSION_DATA:
            sessions = getSessionFromLocalStorage(state);
            existingSession = sessions.find(e => e.id === action.payload.sessionId);
            existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
            if (existingSession) {
                const session = { ...existingSession };
                session.syncDataCount = !session.syncDataCount ? 1 : (session.syncDataCount + 1);
                session.targetIdDataIdMap = session.targetIdDataIdMap ?? {};
                if (action.payload.targetIdDataIdMap) {
                    session.targetIdDataIdMap = action.payload.targetIdDataIdMap;
                }
                sessions[existingSessionIndex] = session;
                setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                return { ...state, sessions };
            }
            return state;

        case LOAD_SESSIONS:
            sessions = [];
            activeSessionId = null;
            sessionConfig = null;
            savedSessions = getLocalStorageItem(localStorageEnum.sessions);
            savedActiveSessionId = getLocalStorageItem(localStorageEnum.activeSessionId);
            savedSessionConfig = getLocalStorageItem(localStorageEnum.sessionConfig);
            if (savedSessions) {
                sessions = JSON.parse(savedSessions);
                if (sessions) {
                    let dataModified = false;
                    const initialCount = sessions.length;
                    sessions = sessions.filter(e => !e?.loading); //discarding corrupted sessions
                    if (sessions.length !== initialCount) {
                        dataModified = true;
                    }
                    //removing video data
                    sessions.forEach((session) => {
                        const newData = [];
                        session.data.forEach((item) => {
                            if (item?.dataType !== 'imageVideo') {
                                newData.push(item);
                            } else {
                                dataModified = true;
                            }
                        });
                        session.data = newData;
                    });
                    if (dataModified) {
                        setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                    }
                }
            }
            if (savedActiveSessionId) {
                activeSessionId = JSON.parse(savedActiveSessionId);
                if (activeSessionId && !sessions.find(e => e.id === activeSessionId)) {
                    activeSessionId = null; //discarding corrupted ongoing session
                    setLocalStorageItem(localStorageEnum.activeSessionId, JSON.stringify(activeSessionId));
                }
            }
            sessionConfig = savedSessionConfig ? JSON.parse(savedSessionConfig) : {};
            return {
                ...state,
                sessions,
                activeSessionId,
                sessionConfig,
            };

        case SET_TARGET_CHANGES:
            if (action.payload.sessionId) {
                sessions = getSessionFromLocalStorage(state);
                existingSession = sessions.find(e => e.id === action.payload.sessionId);
                existingSessionIndex = sessions.findIndex(e => e.id === action.payload.sessionId);
                if (existingSession) {
                    const session = { ...existingSession };
                    session.latestTargetsChanged = action.payload.changes;
                    sessions[existingSessionIndex] = session;
                    setLocalStorageItem(localStorageEnum.sessions, JSON.stringify(sessions));
                    return { ...state, sessions };
                }
            }
            return state;

        default:
            return state
    }
}
