import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import { TableSortLabel, Tooltip } from '@material-ui/core';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { deleteDevice } from '../../services/DeviceService';
import { setLoading, setSnackbar, setGlobalDialog } from '../../store/general/actions';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';

const headCells = [
    { id: 'firstName', label: 'First Name', sort: true },
    { id: 'lastName', label: 'Last Name', sort: true },
    { id: 'username', label: 'User Name', sort: true },
    { id: 'deviceName', label: 'Device Name' },
    { id: 'deviceOS', label: 'Device OS' },
];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 200px)',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        verticalAlign: 'middle !important',
        padding: '12px 12px',
        textAlign: 'center',
    },
    tableCell: {
        padding: '5px 12px',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    tableCellInternal: {
        verticalAlign: 'middle',
        textAlign: 'center',
        padding: '5px 0px',
    },
    actionColumn: {
        width: 75,
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
    bottom: {
        color: 'rgba(0, 0, 0, 0.1)',
    },
    top: {
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

const DevicesTable = (props) => {
    const classes = useStyles();
    const {
        user,
        devices,
        fetchDevices,
        loading,
        setLoading,
        setSnackbar,
        setGlobalDialog,
        sort,
        setSort,
    } = props;

    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [updatingDevice, setUpdatingDevice] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);

    useEffect(() => {
        if (sort) {
            setOrder(sort.sortType || 'asc');
            setOrderBy(sort.sortBy || '');
        }
    }, [sort]);

    useEffect(() => {
        if (devices.length <= page * rowsPerPage) {
            // setPage(page - 1 >= 0 ? page - 1 : 0);
            setPage(0);
        }
    }, [devices]);

    const handleRequestSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort({ sortType: isAsc ? 'desc' : 'asc', sortBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openDeviceAction = (device) => (event) => {
        setSelectedDevice(device);
        setOpenDialog(true);
    };

    const handleDeviceDelete = () => {
        setLoading('deleteDevice');
        setUpdatingDevice(selectedDevice);
        deleteDevice(selectedDevice.deviceUID, selectedDevice.userId)
            .then(() => {
                setLoading('deleteDevice', false);
                setSnackbar('success', `Device deleted`);
                fetchDevices();
            })
            .finally(() => {
                setUpdatingDevice(null);
                setOpenDialog(false);
            });
    };

    const showActions = CanUserFunc(access.devices.remove);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow>
                                {headCells.map((headCell) => (
                                    <TableCell
                                        key={headCell.id}
                                        className={classes.headCell}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.sort ? (
                                            <TableSortLabel
                                                id={`device-page-table-sort-label-${headCell.id}`}
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={handleRequestSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc'
                                                            ? 'sorted decending'
                                                            : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                        ) : (
                                            headCell.label
                                        )}
                                    </TableCell>
                                ))}
                                {showActions && (
                                    <TableCell
                                        className={`${classes.headCell} ${classes.actionColumn}`}
                                    >
                                        Action
                                    </TableCell>
                                )}
                            </TableRow>
                        </TableHead>
                        {loading.getDevices || loading.searchDevice ? (
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={7}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ) : (
                            <TableBody>
                                {devices
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow tabIndex={-1} key={row.id}>
                                            <TableCell className={classes.tableCell}>
                                                {row.user.firstName}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {row.user.lastName}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {row.user.username}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {row.deviceInfo?.name}
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                {row.deviceInfo?.platform}{' '}
                                                {row.deviceInfo?.systemVersion}
                                            </TableCell>
                                            {showActions && (
                                                <TableCell
                                                    className={`${classes.tableCell} ${classes.actionColumn}`}
                                                >
                                                    {updatingDevice &&
                                                    updatingDevice.deviceUID === row.deviceUID ? (
                                                        <LoadingCheck
                                                            show={
                                                                loading.editDevice &&
                                                                selectedDevice.deviceUID ===
                                                                    row.deviceUID
                                                            }
                                                            size={40}
                                                            className={{ margin: 7 }}
                                                        />
                                                    ) : (
                                                        <Tooltip title='Delete'>
                                                            <IconButton
                                                                id={`device-page-table-row-delete-action-${row.id}`}
                                                                size='small'
                                                                onClick={openDeviceAction(row)}
                                                            >
                                                                <DeleteOutlineRoundedIcon
                                                                    style={{ strokeWidth: 1 }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                                {!devices.length && (
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell className={classes.noData} colSpan={7}>
                                            No devices found.
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        )}
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 50, 100]}
                    component='div'
                    count={devices.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby='delete-confirmation'
            >
                <DialogTitle id='delete-confirmation-title'>Confirm?</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to delete?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button id="device-delete-confirmation-close" onClick={() => setOpenDialog(false)} color='primary'>
                        Close
                    </Button>
                    <Button id="device-delete-confirmation-delete" onClick={handleDeviceDelete} color='primary' autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

DevicesTable.propTypes = {
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    devices: PropTypes.array.isRequired,
    fetchDevices: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.general.loading,
    user: state.auth.user,
});

export default connect(mapStateToProps, { setLoading, setSnackbar, setGlobalDialog })(DevicesTable);
