/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";

function isElementInViewport(el) {
    if (!el) return false;

    var rect = el.getBoundingClientRect();

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && 
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}


export const useInfiniteScroll = (scrollRef, cb) => {
    const elementRef = useRef();
    const callbackRef = useRef(cb);
    const blockerRef = useRef(false);

    useEffect(() => {
        if (blockerRef.current) {
            setTimeout(() => {
                blockerRef.current = false;
            }, 500)
        }
        callbackRef.current = cb;
    }, [cb, blockerRef.current])

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.addEventListener('scroll', () => {
                if (!blockerRef.current && isElementInViewport(elementRef.current)) {
                    callbackRef.current()
                    blockerRef.current = true;
                    scrollRef.current.scrollTo(0, scrollRef.current.scrollTop - 200)
                }
            })
        }
    }, [elementRef.current])

    return elementRef;
}
