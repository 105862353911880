import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, DialogContent, Dialog, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minWidth: 300,
  },
  dialogPaperLarge: {
    minWidth: 600,
    '@media (max-width: 768px)': {
      width: 600,
      minWidth: 'unset',
      maxWidth: '100vw'
    }
  },
  dialogContent: {
    padding: 20,
    '@media (max-width: 768px)': {
      padding: '20px 15px'
    }
  },
  grid: {
    justifyContent: 'space-around',
    alignItems: 'center',
    overflowX: 'auto',
  },
  content_: {
    '@media (max-width: 768px)': {
      maxHeight: 'calc(100vh - 200px)'
    }
  },
  title: {
    fontWeight: 'bold',
    marginBottom: 20,
  },
  buttonHolder: {
    marginTop: 10,
  },
  button: {
    width: '30%',
  },
}));

const TargetPopup = (props) => {
  const classes = useStyles();

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="md"
      open={props.open}
      classes={{ paper: props.isLarge ? classes.dialogPaperLarge : classes.dialogPaper }}
    >
      <DialogContent className={classes.dialogContent}>
        <Grid
          className={`${classes.grid} ${classes.title}`}
          item
          xs={12}
          container
          justify="flex-end"
        >
          {props.title}
        </Grid>
        <Grid
          className={`${classes.grid} ${classes.content_}`}
          item
          xs={12}
          container
          justify="flex-end"
        >
          {props.children}
        </Grid>
        <Grid
          className={`${classes.grid} ${classes.buttonHolder}`}
          item
          xs={12}
          container
          justify="flex-end"
        >
          {props.onCancel && <Button className={classes.button} onClick={props.onCancel}>
            Cancel
          </Button>}
          {props.onClose && (
            <Button className={classes.button} onClick={props.onClose}>
              Close
            </Button>
          )}
          {props.onSave && <Button
            disabled={props.saveDisabled ?? false}
            className={classes.button}
            onClick={props.onSave}
          >
            Save
          </Button>}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

TargetPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  saveDisabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
};

export default TargetPopup;
