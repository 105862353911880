import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Popover, Grid, Button, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { getPatient } from '../../services/PatientService';
import { setLoading, setGlobalDialog } from '../../store/general/actions';
import ClientViewer from '../Client/ClientViewer';

const useStyles = makeStyles(theme => ({
    popoverPaper: {
        padding: 20,
    },
    field: {
        fontWeight: 500,
        marginBottom: 10,
    },
    list: {
        margin: '5px 0px'
    },
}));

const ClientInfoPopover = (props) => {
    const classes = useStyles();
    const { onClose, anchorEl, clientId, loading, setLoading, setGlobalDialog } = props;
    const [client, setClient] = useState({});

    useEffect(() => {
        if (clientId) {
            setLoading('getClient');
            getPatient(clientId).then((res) => {
                setClient(res.data.data);
                setLoading('getClient', false);
            })
        }
    }, [clientId]);

    const openViewer = () => {
        setGlobalDialog('Client Viewer', undefined, undefined,
            <ClientViewer clientData={client} />,
            false
        )
        onClose();
    }

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            {loading.getClient ?
                <Grid item container justify="center" alignItems="center">
                    <CircularProgress size={40} />
                </Grid>
                :
                <Grid container>
                    <Grid item xs={3} className={classes.field}>Name</Grid>
                    <Grid item xs={9}>{`${client.firstName} ${client.lastName}`}</Grid>
                    {client.phone &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Phone</Grid>
                            <Grid item xs={9}>{client.phone}</Grid>
                        </React.Fragment>
                    }
                    {client.email &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Email</Grid>
                            <Grid item xs={9}>{client.email}</Grid>
                        </React.Fragment>
                    }
                    {client.sites &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Site</Grid>
                            <Grid item xs={9}>{client.site}</Grid>
                        </React.Fragment>
                    }
                    <Grid item xs={12} container justify="flex-end" onClick={openViewer}>
                        <Button>Show More</Button>
                    </Grid>
                </Grid>
            }
        </Popover>
    )
}

ClientInfoPopover.propTypes = {
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
    clientId: PropTypes.number,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(ClientInfoPopover)
