import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, DialogContent, Dialog, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import {
    deleteSessionData,
    deleteAllSessionData,
} from "../../../store/session/actions";
import { setGlobalDialog } from "../../../store/general/actions";
import { format } from "date-fns";
import DividerWrapper from "./DividerWraper";
import { parseSessionHistoryRecord } from "../../../utils/sessionHistoryRecordParser";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minWidth: 300,
    },
    dialogContent: {
        padding: 20,
    },
    grid: {
        justifyContent: "space-around",
        alignItems: "center",
    },
    title: {
        fontWeight: "bold",
        marginBottom: 20,
    },
    buttonHolder: {
        marginTop: 10,
    },
    button: {
        width: "30%",
    },
    historyContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "60vh",
        overflowY: "auto",
    },
    deleteButton: {
        borderRadius: 0,
        marginRight: 10,
        backgroundColor: "red",
        "&:hover": {
            backgroundColor: "darkred",
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: 10,
        },
    },
    deleteAllButton: {
        backgroundColor: "red",
        "&:hover": {
            backgroundColor: "darkred",
        },
    },
    historyItem: {
        display: "flex",
        justifyContent: "space-between",
        minWidth: 350,
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            minWidth: "100%",
        },
    },
    historyItemInfo: {
        marginBottom: 5,
    },
    historyItemInfoLabel: {
        fontWeight: "bold",
    },
}));

const SessionHistory = (props) => {
    const classes = useStyles();

    const deleteAllSessionData = () => {
        props.setGlobalDialog(
            "Delete All History",
            "Are you sure you want to delete all history items?",
            (answer) => {
                if (answer) {
                    props.deleteAllSessionData(props.session.id);
                    props.onClose();
                }
            }
        );
    };

    const deleteSessionData = (dataId) => {
        props.setGlobalDialog(
            "Delete History",
            "Are you sure you want to delete the history item?",
            (answer) => {
                if (answer) {
                    const closeModal = props.session.data?.length === 1;
                    props.deleteSessionData(props.session.id, dataId);
                    if (closeModal) {
                        props.onClose();
                    }
                }
            }
        );
    };

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={props.open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid
                    className={`${classes.grid} ${classes.title}`}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    Undo History
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    <div className={classes.historyContainer}>
                        {props.session.data
                            .slice()
                            .reverse()
                            .filter(e => e.responseSummary !== false)
                            .map((data) => (
                                <DividerWrapper>
                                    <div className={classes.historyItem}>
                                        <div>
                                            <div className={classes.historyItemInfo}>
                                                <span className={classes.historyItemInfoLabel}>
                                                    Target:{" "}
                                                </span>
                                                <span>
                                                    {
                                                        props.session.targets.find(
                                                            (e) => e.id === data.targetId
                                                        )?.name
                                                    }
                                                </span>
                                            </div>
                                            <div className={classes.historyItemInfo}>
                                                <span className={classes.historyItemInfoLabel}>
                                                    Date:{" "}
                                                </span>
                                                <span>{format(data.id, "yyyy-MM-dd HH:mm")}</span>
                                            </div>
                                            <div className={classes.historyItemInfo}>
                                                <span className={classes.historyItemInfoLabel}>
                                                    Response:{" "}
                                                </span>
                                                <span>{parseSessionHistoryRecord(data.responseSummary)}</span>
                                            </div>
                                        </div>
                                        <Button
                                            className={classes.deleteButton}
                                            onClick={() => {
                                                deleteSessionData(data.id);
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </DividerWrapper>
                            ))}
                    </div>
                </Grid>
                <Grid
                    className={`${classes.grid} ${classes.buttonHolder}`}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    <Button
                        className={`${classes.button} ${classes.deleteAllButton}`}
                        onClick={deleteAllSessionData}
                    >
                        Delete All
                    </Button>
                    <Button className={classes.button} onClick={props.onClose}>
                        Close
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

SessionHistory.propTypes = {
    open: PropTypes.bool.isRequired,
    session: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    deleteAllSessionData: PropTypes.string.isRequired,
    deleteSessionData: PropTypes.string.isRequired,
};

export default connect(null, {
    deleteAllSessionData,
    deleteSessionData,
    setGlobalDialog,
})(SessionHistory);
