// Downloads the file by the given url
// Use this instead of saveAs from file-saver in order to 
// prevent a new tab from opening on download and the CORS error in console
export default function download(url) { 
    let ifrm = document.createElement("IFRAME"); 
    ifrm.setAttribute("style", "display:none;");
    ifrm.setAttribute("src", url); 
    ifrm.style.width = 0; 
    ifrm.style.height = 0; 
    document.body.appendChild(ifrm); 
}  
