import { addHours, parseISO, subHours } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';

export default function dateFormatter(
  date,
  type = 'sub',
  fmt = "yyyy-MM-dd'T'HH:mm:ss.000'Z'",
) {
  const parsedTime = parseISO(date);
  const offset = new Date().getTimezoneOffset();
  utcToZonedTime(parsedTime, 'UTC');
  const zonedTime = utcToZonedTime(parsedTime, 'UTC');
  const offsetInHours = offset / 60;
  const newDate =
    type === 'add'
      ? addHours(zonedTime, -offsetInHours)
      : subHours(zonedTime, -offsetInHours);
  return format(newDate, fmt);
}
