import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import withDataTableHoc from './DataTableHoc';
import { Tooltip } from '@material-ui/core';

import BasicDataLayout from './BasicDataLayout';

const useStyles = makeStyles(theme => ({
    table: {
        borderCollapse: 'collapse',
    },
    tableCell: {
        border: '1px solid black',
        padding: 2,
    },
    value: {
        display: 'inline-block',
        width: 37,
        maxWidth: 37,
    },
    textField: {
        width: 37,
        maxWidth: 37,
    },
    input: {
        padding: 0,
        textAlign: 'center',
        '&::placeholder': { color: 'black', opacity: 1 }
    },
    deleteBtn: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#d32f2f' },
    },
    deleteActive: {
        opacity: 1,
        color: '#d32f2f',
    },
    cellContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    marginBottomCell: {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
    },
    rowSeparator: {
        border: 'none !important',
        minHeight: 10,
        height: 10,
    },
}));

const createCustomData = (data) => {
    const dateNow = Math.floor(Date.now() / 1000);
    const responseData = [];
    data.value.forEach((item) => {
        if (!isNaN(item?.duration) && item?.duration.toString().trim() !== "" && !isNaN(item?.frequency) && item?.frequency.toString().trim() !== "") {
            responseData.push({
                start: dateNow,
                end: dateNow + +item.duration,
                inputs: new Array(+item.frequency).fill(dateNow),
            });
        }
    });
    return responseData;
}

const Rate = (props) => {
    const classes = useStyles();
    const { dataToUse, isEdit, setEditingData } = props;
    const handleChange = (index, index2, field) => (event) => {
        let { value } = event.target;
        if (isNaN(value) || parseInt(value, 10) < 0) {
            const currentValue = dataToUse[index].value[index2]?.[field];
            if (!currentValue) {
                const newData = dataToUse;
                newData[index].value[index2] = { ...newData[index].value[index2], [field]: '' };
                setEditingData(newData.slice());
            }
            return;
        }
        if (field === 'frequency' && !isNaN(parseInt(value, 10))) {
            value = parseInt(value, 10);
        }
        const newData = dataToUse;
        newData[index].value[index2] = { ...newData[index].value[index2], [field]: value };
        setEditingData(newData.slice());
    }

    return (
        <BasicDataLayout
            editComponent={(value, index, index2, DATA_PER_ROW, index3, isDisabled) => (
                <div className={classes.cellContainer}>
                    <TextField
                        disabled={isDisabled}
                        value={value.duration}
                        className={classes.textField}
                        InputProps={{ classes: { input: classes.input } }}
                        onChange={handleChange(index, index2 * DATA_PER_ROW + index3, 'duration')}
                    />
                    <TextField
                        disabled={isDisabled}
                        value={value.frequency}
                        className={classes.textField}
                        InputProps={{ classes: { input: classes.input } }}
                        onChange={handleChange(index, index2 * DATA_PER_ROW + index3, 'frequency')}
                    />
                </div>
            )}
            viewComponent={(value) => (
                <div className={classes.cellContainer}>
                    <span className={`${classes.value} ${classes.marginBottomCell}`}>{value?.duration || 0}</span>
                    <span className={classes.value}>{value?.frequency || 0}</span>
                </div>
            )}
            legendComponent={(index, index2) => (
                isEdit ? <td>
                    <div className={classes.cellContainer}>
                        {index2 ? <div></div> : <Tooltip title="Duration in seconds">
                            <b>D:&nbsp;</b>
                        </Tooltip>}
                        {index2 ? <div></div> : <Tooltip title="Frequency">
                            <b>F:&nbsp;</b>
                        </Tooltip>}
                    </div>
                </td> : null
            )}
            classes={classes}
            {...props}
        />
    )
}

Rate.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
};

export default withDataTableHoc(Rate, 'rate', createCustomData);
