import {
    SET_USER, SET_TOKEN, SET_UEK, SET_LOGIN_ERROR, LOGOUT, SUBSCRIPTION_DIALOG,
    SET_IS_ONLINE
} from './action-types';

import { loginUser, loginToken } from '../../services/AuthService';
import { setUEKValue } from '../../services/EncryptionService';
import { setFinishedOnboardings, setLoading } from '../general/actions';
import checkSubscription from '../../utils/checkSubscription';
import { loadSessions } from '../session/actions';
import SocketService from '../../services/SocketService';

export const tokenLogin = (showError = true) => (dispatch) => {
    dispatch(setLoading('userLogin'));
    return loginToken().then((res) => {
        dispatch(setLoading('userLogin', false));
        // dispatch(setUser({ ...res.data.data, permissions: { ...res.data.data.permissions, roles: {} } }));
        dispatch(setUser(res.data.data.user)); // use above line to easily disable some permission
        dispatch(setToken(res.data.data.token));
        dispatch(setUEK(res.data.data.uek ?? ''));
        dispatch(loadSessions());
        checkSubscription(res.data.data.user, true);
        // Socket
        SocketService.connect(res.data.data.token)
    }).catch((err) => {
        dispatch(logout());
        dispatch(setLoading('userLogin', false));
        showError && dispatch(setLoginError("There was an error. Please login again"));
    });
}

export const login = (username, password) => (dispatch) => {
    dispatch(setLoading('userLogin'));
    loginUser(username, password).then((res) => {
        dispatch(setLoading('userLogin', false));
        dispatch(setUser(res.data.data.user));
        dispatch(setToken(res.data.data.token));
        dispatch(setUEK(res.data.data.uek ?? ''));
        dispatch(loadSessions());
        checkSubscription(res.data.data.user);
        // Socket
        SocketService.connect(res.data.data.token)
    }).catch((err) => {
        dispatch(setLoading('userLogin', false));
        dispatch(setLoginError(err.response?.data.message));
    });
}

export const logout = (isIdle = false, error = null) => (dispatch) => {
    localStorage.removeItem('token');
    // Object.keys(localStorageEnum).forEach((item) => {
    //     localStorage.removeItem(item);
    // })
    localStorage.clear();
    return dispatch({
        type: LOGOUT,
        payload: { isIdle, error }
    })
}

export const setLoginError = (data) => (dispatch) => {
    return dispatch({
        type: SET_LOGIN_ERROR,
        payload: data,
    })
}

export const showUpdateSubsctiptionDialog = (data) => (dispatch) => {
    return dispatch({
        type: SUBSCRIPTION_DIALOG,
        payload: data,
    })
}

export const setUser = (data) => (dispatch) => {
    const finishedOnboardings = data?.settings?.finishedOnboardings || {};
    dispatch(setFinishedOnboardings(finishedOnboardings));
    return dispatch({
        type: SET_USER,
        payload: data,
    })
}

export const setToken = (data) => (dispatch) => {
    localStorage.setItem('token', data);
    return dispatch({
        type: SET_TOKEN,
        payload: data,
    })
}

export const setIsOnline = (data) => (dispatch) => {
    return dispatch({
        type: SET_IS_ONLINE,
        payload: data,
    })
}

export const setUEK = (data) => (dispatch) => {
    setUEKValue(data);
    return dispatch({
        type: SET_UEK,
        payload: data,
    })
}
