import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

//Services
import * as ProgramService from '../services/ProgramService';
import * as TargetService from '../services/TargetService'
import { cancelPreviousRequest } from '../services/agent';

//Actions
import { setGlobalDialog, setLoading, setSnackbar, setTargetLoading, setSelection } from '../store/general/actions';
import { setPrograms, setDomains } from '../store/data/actions';
import { setTargets, setAll } from '../store/data/actions';

//Components
import { makeStyles, Popover, ListItem, List, Box, Tooltip } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles(theme => ({
    container: {
        marginRight: 10,
        marginLeft: 10,
        marginTop: 15,
        marginBottom: 15,
    },
    domainMenuItem: {
        paddingLeft: 32,
        paddingRight: 32,
        fontWeight: 'bold',
    },
    menuItem: {
        paddingLeft: 48,
        paddingRight: 32,
    },
    noItem: {
        marginRight: 20,
        color: 'red',
        fontWeight: 'bold',
    },
    itemName: {
        fontSize: 15,
        minWidth: 100,
        margin: 0,
        backgroundColor: 'white',
        padding: '10px 15px 10px 15px',
        borderRadius: 20,
        position: 'relative',
        cursor: 'pointer',
    },
    itemSpan: {
        minWidth: 100,
        display: 'inline-block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginRight: 15,
        maxWidth: '100%',
    },
    arrowDownIcon: {
        right: 2,
        position: 'absolute',
        fontSize: 36,
        color: '#0FB99E',
        top: 'calc(50% - 18px)',
    },
    list: {
        maxHeight: 500,
    },
    popoverPaper: {
        minWidth: 350,
        maxWidth: 350,
    },
}));

const ALL_DOMAINS = {
    id: 'all',
    name: 'All Domains',
    programs: [],
    status: 'active',
};

const ProgramSelect = (props) => {
    const classes = useStyles();
    const { selection } = props;
    const [anchorEl, setAnchorEl] = useState(null);    
    const [domains, setDomains] = useState(props.domains);
    const [programs, setPrograms] = useState(props.programs);

    useEffect(() => {
        setDomains([ALL_DOMAINS, ...props.domains]);
    }, [props.domains])

    useEffect(() => {
        setPrograms(props.programs);
    }, [props.programs])

    const fetchFullData = (patientId, all = true, customSelection = null) => {
		let selection;
		if (all) {
			selection = { ...props.selection, domain: 'all', program: 'all', all: true, patient: patientId };
		} else {
			selection = customSelection || props.selection;
		}

		selection = { ...selection, patient: patientId };

		props.setTargetLoading(true);
		props.setSelection(selection, selection.target);
		props.setLoading('getDomains');
		ProgramService.getFullDataByPatientId(
			selection, 
			patientId,
			[
				cancelPreviousRequest('getFullDataByPatientId_fetchFullData_0'),
				cancelPreviousRequest('getFullDataByPatientId_fetchFullData_1')
			]
		).then(res => {
			props.setAll(res);
			props.setTargetLoading(false);
			props.setLoading('getDomains', false);
		});
        TargetService.getFilteredTargets({ selection }, true, { limit: TargetService.PAGE_SIZE, skip: 0}).then(res => {
            props.setTargets(res.data.data);
            props.setTargetLoading(false);
        })
	}

	const filterByItem = item => {
		if (!props.selection.patient) {
			return;
		}

		props.setTargetLoading(true);
		if (item.all) {
			fetchFullData(props.patient.id);
		} else {
			props.setSelection({ ...props.selection, ...item });
			TargetService.getFilteredTargets({ ...props.selection, ...item }, true, { limit: TargetService.PAGE_SIZE, skip: 0}).then(res => {
				props.setTargets(res.data.data);
				props.setTargetLoading(false);
			})
		}

        setAnchorEl(null);
	}

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    }

    return (
        <Box className={classes.container}>
            {domains.length ?
                <Tooltip title={`${selection.all ? 'All Domain' : domains.find(e => e.id === selection.domain)?.name || 'Unknown'} ${selection.program !== 'all'
                    ? ` > ${programs.find(e => e.id === selection.program)?.name || 'Unknown'}`
                    : ''}`}
                >
                    <p
                        className={classes.itemName}
                        onClick={handleOpen}
                        id="client-picker"
                    >
                        <span className={classes.itemSpan}>
                            {selection.all ? 'All Domain' : domains.find(e => e.id === selection.domain)?.name || 'Unknown'}
                            {selection.program !== 'all'
                                ? ` > ${programs.find(e => e.id === selection.program)?.name || 'Unknown'}`
                                : ''
                            }
                        </span>
                        <KeyboardArrowDownIcon className={classes.arrowDownIcon} />
                    </p>
                </Tooltip>
                :
                <p className={classes.noItem}>No domain</p>
            }
            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <List className={classes.list}>
                    {domains?.length && domains
                    .map(domain => (
                        <>
                            <ListItem
                                className={classes.domainMenuItem}
                                key={domain.id}
                                value={domain.id}
                                button
                                selected={selection.program === 'all' && selection.domain && selection.domain === domain.id}
                                onClick={() => filterByItem({ domain: domain.id, all: domain.id === ALL_DOMAINS.id, program: 'all' })}
                            >
                                {`${domain.name}`}
                            </ListItem>
                            {domain.programs?.length > 0 && 
                                domain.programs.map(program => (
                                    <ListItem
                                        className={classes.menuItem}
                                        key={`${domain.id}-${program.id}`}
                                        value={program.id}
                                        button
                                        selected={selection.program && selection.program === program.id}
                                        onClick={() => filterByItem({ domain: domain.id, program: program.id, all: false })}
                                    >
                                        {`${program.name}`}
                                    </ListItem>
                                ))
                            }
                        </>
                    ))}
                </List>
            </Popover>
        </Box>
    )
}

ProgramSelect.propTypes = {
    user: PropTypes.object.isRequired,
    domains: PropTypes.array.isRequired,
    programs: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired,
    setSelection: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setDomains: PropTypes.func.isRequired,
    setPrograms: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setTargetLoading: PropTypes.func.isRequired,
    setTargets: PropTypes.func.isRequired,
    setAll: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    selection: state.general.selection,
    domains: state.data.domains,
    programs: state.data.programs,
    user: state.auth.user,
    patient: state.patient.patient,
    loading: state.general.loading,
});

export default connect(mapStateToProps, { 
    setGlobalDialog, setDomains, setPrograms, 
    setLoading, setSnackbar, setTargetLoading, 
    setTargets, setSelection, setAll 
})(ProgramSelect);
