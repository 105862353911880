import React from 'react';
import randomcolor from 'randomcolor';
import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import addMonths from 'date-fns/addMonths';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import isWithinInterval from 'date-fns/isWithinInterval';

import dateToDays from '../../../utils/dateToDays';

const tickStyle = { fill: '#5A5A5A', fontSize: 14 };

export const toDate = days => {
	// convert back the number of days from 1970 to actual date
	// add back timezoneoffset so it will be shown as user's current timezone

	const date = addMinutes(new Date(days * 86400000), new Date().getTimezoneOffset()).toDateString();
	return `${date.substr(8, 2)}-${date.substr(4, 3)}`
};

export const toDateFull = days => {
	// convert back the number of days from 1970 to actual date
	// add back timezoneoffset so it will be shown as user's current timezone

	if (days) {
		const date = addMinutes(new Date(days * 86400000), new Date().getTimezoneOffset());
		return format(date, 'dd MMMM, yyyy');
	}
	return null;
};

const toPercent = (decimal, fixed = 0) => `${(decimal).toFixed(fixed)}%`;

export const graphHeight = 400;

const colors = ['blue', 'red', 'orange', 'yellow', 'green', 'purple', 'pink'];

/**
* 
* @param index Index of item, determines the color picked
* @param luminosity Controls the luminosity of the generated color. You can specify a string containing bright, light or dark.
* Generate colors for the graph
*/
export const getColor = (index, luminosity) => {
	const hue = colors[index % colors.length];
	return randomcolor({ hue, luminosity, seed: index });
}

export const getTargetColor = (target, index, luminosity) => {
	return getColor(target.colorIndex !== undefined ? target.colorIndex : index, luminosity);
}

export const defaultAxisSetting = {
	tickLine: false,
	axisLine: false,
	tick: tickStyle,
	interval: 0,
	allowDecimals: false,
	padding: { top: 20, bottom: 20 },
	tickCount: 10,
};

export const defaultBarAxisSetting = {
	...defaultAxisSetting,
	padding: undefined,
};

export const dateAxisSetting = {
	...defaultAxisSetting,
	type: 'number',
	tickFormatter: toDate,
	dy: 20,
	height: 70,
	padding: { left: 20, right: 20 },
	allowDataOverflow: true,
	angle: -90,
	allowDecimals: false,
	minTickGap: 0,
};

export const percentAxisSetting = {
	...defaultAxisSetting,
	domain: [0, 100],
	tickFormatter: toPercent,
	ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
};

export const defaultLineSetting = {
	connectNulls: true,
	strokeWidth: 3,
	dot: { strokeWidth: 5 },
};

export const defaultGridSetting = {
	vertical: false,
};

export const defaultLineChartSetting = {
	margin: { top: 20, bottom: 20, left: 25, right: 5 },
};

export const defaultLegendSetting = {
	wrapperStyle: { fontSize: 14, bottom: 10 },
	iconSize: 24,
};

export const defaultTooltipSetting = {
	isAnimationActive: false,
	isUpdateAnimationActive: false,
	allowEscapeViewBox: { x: false, y: true },
}

export const renderCustomizedValue = (props, offsetX, offsetY, formatter) => {
	const { x, y, value } = props;

	return (
		<g>
			<text fontSize="12" x={x + offsetX} y={y + offsetY} fill="#000" textAnchor="middle" dominantBaseline="middle">
				{formatter ? formatter(value) : value}
			</text>
		</g>
	);
};

/**
* Generate custom domain and ticks for the grpah
* @param {date} startDate 
* @param {date} endDate 
*/
export const generateDomainTicks = (startDate, endDate) => {
	if (!startDate || !endDate) return { domain: [], ticks: [] };

	const startDays = dateToDays(new Date(startDate.setHours(0, 0, 0, 0)));
	const endDays = dateToDays(new Date(endDate.setHours(23, 59, 59, 999)));
	const daysDiff = endDays - startDays;
	const domain = [startDays, endDays];
	const ticks = [];

	let increment = 1;
	if (daysDiff <= 100) {

		if (daysDiff <= 20) increment = 1;
		if (daysDiff >= 21 && daysDiff <= 40) increment = 2;
		if (daysDiff >= 41 && daysDiff <= 60) increment = 3;
		if (daysDiff >= 61 && daysDiff <= 80) increment = 4;
		if (daysDiff >= 81 && daysDiff <= 100) increment = 5;

		for (let day = startDays; day <= endDays; day += increment) {
			ticks.push(day);
		}
	}

	// 182 ~= 6 months, weekly ticks
	if (daysDiff >= 101 && daysDiff <= 182) {
		increment = 7;
		const startingDate = startOfWeek(new Date(startDate.setHours(0, 0, 0, 0)));

		for (let day = dateToDays(startingDate); day <= endDays; day += increment) {
			ticks.push(day);
		}
	}

	// monthly
	if (daysDiff >= 183) {
		increment = 30; // more or less :)
		let firstDateOfMonth = startOfMonth(new Date(startDate.setHours(0, 0, 0, 0)));
		let day = dateToDays(firstDateOfMonth);

		while (day <= endDays) {
			ticks.push(day);
			firstDateOfMonth = addMonths(firstDateOfMonth, 1);
			day = dateToDays(firstDateOfMonth);
		}
	}

	// we don't want to skip endDays
	if (ticks[ticks.length - 1] !== endDays) {
		// if distance between lastTick and endDays is less than half increment
		// delete lastTick, add endDays
		if (endDays - ticks[ticks.length - 1] <= increment / 2) {
			ticks.splice(ticks.length - 1, 1, endDays);
		} else {
			ticks.push(endDays);
		}
	}

	return { domain, ticks };
}

export const getMinMaxDate = (graphData) => {
	const minDates = [];
	const maxDates = [];

	graphData.forEach(target => {
		if (target.targetData.length) { // targetData are already sorted by date
			minDates.push(target.targetData[0].date);
			maxDates.push(target.targetData[target.targetData.length - 1].date);
		}
		const annotations = target.annotations?.filter(e => e.type !== 'masteryLine');
		if(annotations?.length) {
			const phasesShowAt = annotations.map(phase => phase.showAt)
			minDates.push(...phasesShowAt)
			maxDates.push(...phasesShowAt)
		}
	});

	const minDate = !minDates.length ? null : minDates.reduce((a, b) => isBefore(new Date(a), new Date(b)) ? a : b);
	const maxDate = !maxDates.length ? null : maxDates.reduce((a, b) => isAfter(new Date(a), new Date(b)) ? a : b);
	return { minDate, maxDate };
}

export const ISCLONED = '--isCloned--';

/**
 * Split the graph data by phase changes
 * Target will be duplicated and populated
 * with different targetData
 * 
 * Splitting target into multiples entries,
 * it will split (disconnect) the target line
 * on graph as well.
 * 
 * @param graphData 
 */
export const splitGraphDataByPhases = (graphData) => {
	const newGraphData = [];
	graphData.forEach((target, targetIndex) => {
		const annotations = target.annotations?.filter(e => e.type !== 'masteryLine');
		if (!annotations || !target.targetData || !annotations.length) {
			newGraphData.push(target);
		} else {
			const phases = annotations.sort((a, b) => new Date(a.showAt) - new Date(b.showAt));
			let isCloned = false;
			for (let index = 0; index < phases.length; index++) {
				let targetData;
				// If index 0, get all data before the phase change
				if (index === 0) {
					targetData = target.targetData.filter(data =>
						isBefore(new Date(data.date), new Date(phases[index].showAt))
					);
				}

				// If index is bigger than 0
				// we get the data in between phase changes
				// after the last phase change, and before this phase change
				if (index > 0) {
					targetData = target.targetData.filter(data =>
						isWithinInterval(
							new Date(data.date), {
							start: new Date(phases[index - 1].showAt),
							end: new Date(phases[index].showAt)
						})
					);
				}

				if (!!targetData?.length) {
					newGraphData.push({
						...target,
						isCloned: isCloned, // only the first target isn't cloned
						colorIndex: target.colorIndex !== undefined ? target.colorIndex : targetIndex,
						targetData
					});
					isCloned = true;
				}

				// If index is last of array
				// We need to include the data after this phase changes as well
				if (index === phases.length - 1) {
					const afterPhaseChange = target.targetData.filter(data =>
						isAfter(new Date(data.date), new Date(phases[index].showAt))
					);

					if (!!afterPhaseChange?.length) {
						newGraphData.push({
							...target,
							isCloned: isCloned,
							colorIndex: target.colorIndex !== undefined ? target.colorIndex : targetIndex,
							targetData: afterPhaseChange,
						})
						isCloned = true;
					}
				}
			}
		}
	})
	return newGraphData;
}

export const generateTargetName = (target, index, endAdornment = '') => {
	return target.isCloned
		? `${target.name}${endAdornment}${ISCLONED}${index}`
		: target.name + endAdornment;
}
