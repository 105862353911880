import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';

import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';

const DATA_PER_ROW = 5;

const BasicDataLayout = (props) => {
    const { classes, editComponent, valueFormatter, viewComponent, legendComponent } = props;
    const { splitArrayPerRows, isEdit, dataToUse, handleNewDelete, handleAdd } = props;

    return (
        <Grid item xs={12} container>
            <table className={classes.table}>
                {dataToUse?.map((sessionData, index, sessionDataArr) => (
                    <tbody key={index}>
                        {splitArrayPerRows(sessionData.value, DATA_PER_ROW).map((data, index2, dataArr) => (
                            <React.Fragment key={index2}>
                                <tr>
                                    {legendComponent && legendComponent(index, index2)}
                                    {data.map((value, index3) => (
                                        <td
                                            className={clsx([
                                                classes.tableCell,
                                                index3 === 0 && classes.leftBorder,
                                                index2 === dataArr.length - 1 && classes.bottomBorder,
                                                isEdit && classes.bottomBorder,
                                            ])}
                                            align="center"
                                            key={index3}
                                        >
                                            {isEdit ?
                                                value !== null ?
                                                    editComponent(value, index, index2, DATA_PER_ROW, index3, sessionData.isOngoing)
                                                    :
                                                    <Grid container style={{ height: '100%' }} className={classes.textField} alignContent="center" justify="center">
                                                        <AddIcon
                                                            color={sessionData.isOngoing ? 'disabled' : 'action'}
                                                            className={classes.addButton}
                                                            onClick={!sessionData.isOngoing && handleAdd(index)}
                                                        />
                                                    </Grid>
                                                :
                                                (viewComponent ? viewComponent(value) : <span className={classes.value}>{valueFormatter(value)}</span>)
                                            }
                                        </td>
                                    ))}
                                    {/* fill remaining row space with empty cells */}
                                    {[...Array(DATA_PER_ROW - data.length)].map((e, key) => <td key={key} className={clsx((!isEdit && index2 !== 0) && classes.topBorder)}></td>)}
                                </tr>
                                {(isEdit && CanUserFunc(access.data.remove)) &&
                                    <tr>
                                        {legendComponent && <td></td>}
                                        {data.map((value, index3) => (
                                            <td className={classes.borderlessCell} align="center" key={index3} valign="middle">
                                                <Grid container style={{ height: '100%' }} alignContent="center" justify="center">
                                                    {value !== null &&
                                                        <DeleteIcon
                                                            color={sessionData.isOngoing ? 'disabled' : 'action'}
                                                            className={classes.deleteBtn}
                                                            onClick={!sessionData.isOngoing && handleNewDelete(index, (index2 * DATA_PER_ROW) + index3)}
                                                        />
                                                    }
                                                    {(value === null && index2 === 0 && index3 === 0) &&
                                                        <Tooltip title="This session data will be deleted">
                                                            <DeleteIcon
                                                                color={sessionData.isOngoing ? 'disabled' : 'action'}
                                                                className={clsx(classes.deleteBtn, classes.deleteActive)}
                                                            />
                                                        </Tooltip>
                                                    }
                                                </Grid>
                                            </td>
                                        ))}
                                    </tr>
                                }
                            </React.Fragment>
                        ))}
                        {(dataToUse?.length > 1 && index !== sessionDataArr.length - 1) &&
                            <tr style={{ minHeight: 10 }}>
                                <td colSpan={DATA_PER_ROW} className={classes.rowSeparator}></td>
                            </tr>
                        }
                    </tbody>
                ))}
            </table>
        </Grid>
    )
}

BasicDataLayout.defaultProps = {
    valueFormatter: (value) => value ?? '',
}

BasicDataLayout.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    editComponent: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    viewComponent: PropTypes.func,
    legendComponent: PropTypes.func,
    valueFormatter: PropTypes.func,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default BasicDataLayout;
