import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import withDataTableHoc from './DataTableHoc';
import BasicDataLayout from './BasicDataLayout';
import sharedStyles from './styles';

const useStyles = makeStyles(theme => sharedStyles({ cellWidth: 24 }));

const PLUS_MINUS_DICT = {
    plus: '+',
    minus: '-',
}

const createCustomData = (data) => {  
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    for (let index = 0; index < data.value.length; index++) {
        customData.push([data.value[index], dateNow + index]);
    }

    return customData;
}

const CorrectIncorrect = (props) => {
    const classes = useStyles();
    const { dataToUse, setEditingData } = props;

        
    const handleChange = (index, index2) => event => {
        const newData = dataToUse;
        newData[index].value[index2] = event.target.value;
        setEditingData(newData.slice());
    }

    const editComponent = (value, index, index2, DATA_PER_ROW, index3, isDisabled) => (
        <TextField
            disabled={isDisabled}
            value={value}
            className={classes.textField}
            InputProps={{ classes: { input: classes.input } }}
            onChange={handleChange(index, (index2 * DATA_PER_ROW) + index3)}
            select
            SelectProps={{ IconComponent: () => null }}
        >
            <MenuItem className={classes.menuItem} value="plus">+</MenuItem>
            <MenuItem className={classes.menuItem} value="minus">-</MenuItem>
        </TextField> 
    )
    return (
        <BasicDataLayout editComponent={editComponent} valueFormatter={(value) => PLUS_MINUS_DICT[value] || '-'} classes={classes} {...props} />
    )
}

CorrectIncorrect.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default withDataTableHoc(CorrectIncorrect, 'correctIncorrect', createCustomData);
