import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ImageGallery from 'react-image-gallery';
import format from 'date-fns/format';
import GetAppIcon from '@material-ui/icons/GetApp';
import { unsupportedVideoTypes } from '../../constants'
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';

const useStyles = makeStyles(theme => ({
	closeIconBtn: {
		padding: '0px 4px',
		color: '#0FB99E',
	},
	dialogPaper: {
		width: 1100,
		minWidth: 1100,
        minHeight: 750,
        '& .fullscreen': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        '& .image-gallery-image': {
            height: '400px',
            objectFit: 'contain',
        },
        '& .fullscreen .image-gallery-image': {
            height: 'calc(100vh - 200px)'
        },
        '& .fullscreen .video-player': {
            height: 'calc(100vh - 200px)'
        },
    },
    videoWrapper: {
        position: 'relative',
        height: 'fit-content',

        '& iframe': {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
        }
    },
    invalidVideoMessageWrapper: {
        height: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 24,
    },
    media: {
        position: 'relative',
        height: '400px',
    },
    playButton: {
        cursor: 'pointer',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        margin: 'auto',
        height: '60px',
        width: '100px',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        borderRadius: '5px',
        zIndex: 1,

        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
        },

        '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '16.5px',
            left: '40px',
            margin: '0 auto',
            borderStyle: 'solid',
            borderWidth: '12.5px 0 12.5px 20px',
            borderColor: 'transparent transparent transparent rgba(255, 255, 255, 1)',
        }
    },
    closeVideo: {
        '&::before': {
            content: '"✖"',
            cursor: 'pointer',
            position: 'absolute',
            right: 0,
            top: 0,
            fontSize: '30px',
            padding: '20px',
            zIndex: 1,
            lineHeight: .7,
            display: 'block',
            color: '#000',
        }
    },
    mediaThumb: {
        verticalAlign: 'middle',
        width: '100%',
        lineHeight: 0,
        margin: '-1px -1px -1px -1.5px',
    },
    videoThumbWrapper: {
        '& .image-gallery-description': {
            left: '0 !important',
            right: 'initial !important',
        },
    },
}));

const ImageVideoDialog = (props) => {
	const classes = useStyles();
    const { open, handleClose, name, mediaData, startElement } = props;
    const [items, setItems] = useState([]);
    const [showVideo, setShowVideo] = useState({});
    const galleryRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    
    const isVideoNotSupported = (fileLink) => {
        return !!unsupportedVideoTypes.filter(e => fileLink.endsWith(e)).length;
    };

    useEffect(() => {
        if (mediaData) {
            let items = [];
            mediaData.map((data) => {
                const username = `${data.user.firstName} ${data.user.lastName}`;
                const uploadedDate = format(new Date(data.date), "MM/d/yyyy");

                items = items.concat(data.value.map((item, _i) => {
                    let retData = {
                        index: _i,
                        original: item.url,
                        thumbnail: item.url,
                        originalTitle: item.name,
                        description: `${uploadedDate} by ${username}`,
                        isVideo: item.type.includes('video'),
                    }
                    if (item.type.includes('video')) {
                        retData.renderItem = _renderItem;
                        retData.renderThumbInner = _renderThumbInner;
                    }

                    return retData;
                }))
            })

            items.forEach((item, index) => item.index = index)

            setItems(items);
        }
    }, [mediaData, showVideo]);

    useEffect(() => {
        if (!galleryRef || !galleryRef.current) return;

        if(Object.keys(showVideo).length > 0) return galleryRef.current.slideToIndex(currentIndex);

        let index = items?.findIndex(el => el?.original === startElement?.url) || 0;
        galleryRef.current.slideToIndex(index);
        setCurrentIndex(index);
    }, [items, startElement])

    const _toggleShowVideo = (url, index) => {
        const updateState = { ...showVideo };
        updateState[url] = !Boolean(updateState[url]);
        setShowVideo(updateState);
        setCurrentIndex(index);
    };

    const _renderItem = (item) => {
        if (isVideoNotSupported(item.original)) {
            return (<div className={`${classes.videoWrapper} ${classes.invalidVideoMessageWrapper}`}><span>
                The file format is not supported. Please download the video for viewing.
            </span></div>);
        }
        return (
            <div>
                {
                showVideo[item.original] ?
                    <div className={classes.videoWrapper}>
                        <a
                            className={classes.closeVideo}
                            onClick={() => _toggleShowVideo(item.original, item.index)}
                        >
                            <video id="video1" className={`${classes.media} video-player`} autoPlay>
                                <source src={item.original} type="video/mp4" />
                                You browser
                            </video>
                        </a>
                    </div>
                :
                    <a className={classes.videoThumbWrapper}>
                        <div className={classes.playButton} onClick={() => _toggleShowVideo(item.original, item.index)}></div>

                        <video id="video1" className={`${classes.media} video-player`}>
                            <source src={item.original} type="video/mp4" />
                        </video>
                        {
                            item.description &&
                                <span
                                    className='image-gallery-description'
                                    style={{right: '0', left: 'initial'}}
                                >
                                    {item.description}
                                </span>
                        }
                    </a>
                }
            </div>
        )
    }

    const _renderThumbInner = (item) => (
        <div className="image-gallery-thumbnail-inner">
            <video id="video1" className={classes.mediaThumb}>
                <source src={item.original} type="video/mp4" />
            </video>
        </div>
    );

    function downloadURI(uri, name) {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const handleDownload = () => {
        let item = items[galleryRef.current.getCurrentIndex()];
        downloadURI(item.original, item.originalTitle);
    }

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			classes={{
				paper: classes.dialogPaper
			}}
		>
			<DialogTitle className={classes.dialogTitle}>
				<Grid container>
					<Grid item xs={6}>
						{name}
					</Grid>
					<Grid item xs={6} container justify="flex-end">
						<IconButton className={classes.closeIconBtn} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
                <ImageGallery
                    ref={galleryRef}
                    items={items}
                    showPlayButton={false}
                    renderCustomControls ={(stuff) => galleryRef.current?.state.isFullscreen ? <></> : (
                        <TooltipedIconButton
                            icon={<GetAppIcon />}
                            onClick={handleDownload}
                            disabled={!items?.length}
                            tooltip="Download"
                            id="image-video-download"
                        />
                    )}
                />
			</DialogContent>
		</Dialog>
	)
}

ImageVideoDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	mediaData: PropTypes.array,
	name: PropTypes.string,
};

export default ImageVideoDialog;
