import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import withDataTableHoc from './DataTableHoc';
import sharedStyles from './styles';
import BasicDataLayout from './BasicDataLayout';

const useStylesReadView = makeStyles(theme => sharedStyles({ cellWidth: 'fit-content' }));
const useStylesEditView = makeStyles(theme => sharedStyles({ cellWidth: 32 }));

const createCustomData = (data) => {
    return data.value.map(item => parseFloat(item || 0));
}

const Quantity = (props) => {
    const classesForReadView = useStylesReadView();
    const classesForEditView = useStylesEditView();
    const { dataToUse, setEditingData } = props;

    const handleChange = (index, index2) => event => {
        let { value } = event.target;
        if (value.trim() !== "" && !/^-?\d+\.\d*$|^-?[\d]*$/.test(value)) return;
        const newData = dataToUse;
        newData[index].value[index2] = value;
        setEditingData(newData.slice());
    }

    const editComponent = (value, index, index2, DATA_PER_ROW, index3, isDisabled) => (
        <TextField
            disabled={isDisabled}
            value={value}
            className={props.isEdit ? classesForEditView.textField : classesForReadView.textField}
            InputProps={{ classes: { input: props.isEdit ? classesForEditView.input : classesForReadView.input } }}
            onChange={handleChange(index, (index2 * DATA_PER_ROW) + index3)}
            placeholder="0"
        />
    )

    return (
        <BasicDataLayout editComponent={editComponent} classes={classesForReadView} {...props} />
    )
}

Quantity.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    handleChange: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default withDataTableHoc(Quantity, 'quantity', createCustomData);
