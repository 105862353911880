import React, { useState, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	ResponsiveContainer, Tooltip, Bar, BarChart
} from 'recharts';

import {
	defaultAxisSetting, defaultLineChartSetting, defaultTooltipSetting,
	getTargetColor, defaultBarAxisSetting
} from './GraphSettings';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const CategoryGraph = (props) => {
	const [catSetting, setCatSetting] = useState(props.categorySetting);

	useEffect(() => {
		if (props.categorySetting?.x || props.categorySetting?.y) {
			props.fetchCategoryData(props.graphData.id, props.categorySetting);
		}
	}, [])

	useEffect(() => {
		if (!!props.categorySetting) {
			setCatSetting(props.categorySetting);
			if (!catSetting || props.categorySetting.x !== catSetting.x || props.categorySetting.y !== catSetting.y) {
				props.fetchCategoryData(props.graphData.id, props.categorySetting);
			} 
		}
	}, [props.categorySetting])

	const [enableAnimation, setEnableAnimation] = useState(true);
	const [enableTooltip, setEnableTooltip] = useState(false);
	const [disabledLines, setDisabledLines] = useState({});
	const enableAnimationTimeout = useRef(null);

	useEffect(() => {
		setEnableAnimation(true);
		return () => {
			if (enableAnimationTimeout.current) {
				clearTimeout(enableAnimationTimeout.current);
			}
		}
	}, [props.graphData, props.useCumulative])

	const handleAnimationStart = () => {
		setEnableTooltip(false);
		if (enableAnimationTimeout.current) {
			clearTimeout(enableAnimationTimeout.current);
		}
		enableAnimationTimeout.current = setTimeout(() => {
			setEnableAnimation(false);
			setEnableTooltip(true);
		}, 2000);
	}

	const graphData = useMemo(() => {
		const formattedData = [];
		props.graphData.targetData.forEach((targetData) => {
			formattedData.push({
				x: targetData.x,
				...targetData.y
			})
		})

		return formattedData;
	}, [props.graphData])

	const yLabels = useMemo(() => {
		const selectedYAxis = catSetting?.y;

		let category = [];
		if (selectedYAxis === -1 || props.graphData.customData.length === 1) {
			let responses = [];
			props.graphData.customData.forEach((data) => {
				responses = responses.concat(data.responses.map((e, index) => ({
					value: e.label,
					color: getTargetColor(props.graphData, index, 'light'),
				})));
			});
			return responses
				.sort((response1, response2) => response1.value - response2.value)
				.filter((v,i,a)=>a.findIndex(t=>(t.value === v.value))===i);
		} 
		if (selectedYAxis !== undefined && selectedYAxis !== null) {
			category = props.graphData.customData.find(e => e.id === selectedYAxis);
		} else {
			category = props.graphData.customData[1];
		}
		return category.responses.map((e, index) => ({
			value: e.label,
			color: getTargetColor(props.graphData, index, 'light'),
		}));
	}, [props.graphData])

	return (
		<ResponsiveContainer height={400}>
			<BarChart {...defaultLineChartSetting} data={graphData}>
				{DefaultGraphItems({ ...props, setDisabledLines, showPhase: false, legendPayload: yLabels }).map((e) => e)}
				{xAxis('x', defaultAxisSetting, undefined, undefined, '', false)}
				{yAxis(undefined, defaultBarAxisSetting, '', false)}
				{(enableTooltip && !props.isDownloading) &&
					<Tooltip
						{...defaultTooltipSetting}
					/>
				}
				{yLabels.filter(e => !disabledLines[e.value]).map((yAxis, index) => (
					<Bar
						fill={yAxis.color}
						dataKey={yAxis.value}
						key={yAxis.value}
						onAnimationStart={handleAnimationStart}
						isAnimationActive={enableAnimation}
					>
					</Bar>
				))}
			</BarChart>
		</ResponsiveContainer>
	)
}

CategoryGraph.propTypes = {
	graphData: PropTypes.object.isRequired,
	graphElements: PropTypes.object.isRequired,
	graphDomain: PropTypes.array.isRequired,
	graphTicks: PropTypes.array.isRequired,
	showPhase: PropTypes.bool.isRequired,
	isDownloading: PropTypes.bool.isRequired,
	categorySetting: PropTypes.object,
	fetchCategoryData: PropTypes.func
};

export default CategoryGraph;
