import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const GroupedButton = (props) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    useEffect(() => {
        if (!!props.saveSelectedOption) {
            let index = localStorage.getItem(props.saveSelectedOption);
            if (index) {
                setSelectedIndex(parseInt(index, 10));
            }
        }
    }, [])

    useEffect(() => {
        if (!!props.saveSelectedOption) {
            localStorage.setItem(props.saveSelectedOption, selectedIndex);
        }
    }, [selectedIndex])

    useEffect(() => {
        if (props.overrideSelectedButton !== undefined) {
            setSelectedIndex(props.overrideSelectedButton);
        }
    }, [props.overrideSelectedButton])

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
        props.buttons[index].onClick();
    };

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    return (
        <div id={props.id}>
            {props.buttons.length > 0 ?
                <React.Fragment>
                    <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
                        <Button
                            id={props.id ? `${props.id}-main-button` : ''}
                            disabled={props.disabled || props.buttons[selectedIndex].disabled}
                            onClick={props.buttons[selectedIndex].onClick}
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {props.buttons[selectedIndex].name}
                        </Button>
                        {props.buttons.length > 1 &&
                            <Button
                                id={props.id ? `${props.id}-toggle-button` : ''}
                                size="small"
                                style={{ padding: '0px 5px' }}
                                aria-controls={open ? 'split-button-menu' : undefined}
                                aria-expanded={open ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={handleToggle}
                                disabled={props.disabled}
                            >
                                <ArrowDropDownIcon />
                            </Button>
                        }
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorRef.current}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        getContentAnchorEl={null}
                    >
                        {props.buttons.map((button, index) => (
                            <MenuItem
                                id={props.id ? `${props.id}-manu-item-${button.name}` : ''}
                                key={button.name}
                                selected={index === selectedIndex}
                                disabled={button.disabled}
                                onClick={event => handleMenuItemClick(event, index)}
                            >
                                {button.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </React.Fragment>
                :
                null
            }
        </div>
    );
}

GroupedButton.defaultProps = {
    overrideSelectedButton: undefined,
    disabled: false,
    saveSelectedOption: "",
}

GroupedButton.propTypes = {
    id: PropTypes.string,
    buttons: PropTypes.array.isRequired,
    overrideSelectedButton: PropTypes.number,
    disabled: PropTypes.bool,
    saveSelectedOption: PropTypes.string,
};

export default GroupedButton;