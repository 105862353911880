import { combineReducers } from 'redux';
import data from './data/reducer';
import patient from './patient/reducer';
import general from './general/reducer';
import auth from './auth/reducer';
import setting from './setting/reducer';
import sessions from './session/reducer';

const reducers = combineReducers({
    data,
    patient,
    general,
    auth,
    setting,
    sessions,
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'LOGOUT') {
        window.location.reload();
    }

    return reducers(state, action);
};

export default rootReducer;
