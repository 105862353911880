import {
    SET_SELECTION, SET_SNACKBAR, SET_LOADING, SET_TARGET_LOADING, SET_GLOBAL_DIALOG, CLOSE_GLOBAL_DIALOG, SET_SIDEBAR_WIDTH,
    SET_UPLOAD_DIALOG, CLOSE_UPLOAD_DIALOG, SET_PATIENT_SELECTION_MAP, CLEAR_LOADING, SET_LIBRARY_LOADING, SET_FINISHED_ONBOARDINGS,
    CLOSE_ZENDESK_DIALOG, SET_ZENDESK_DIALOG, SET_IS_MOBILE
} from './action-types';

/**
 * Set domain/program and status selection.
 * Only includes target when we are moving to graph from target table. 
 */
export const setSelection = (data, targetId = undefined) => (dispatch, getState) => {
    let selection = { ...data };

    if (targetId) {
        selection.target = targetId;
    } else {
        delete selection.target;
    }

    const storeSelection = getState().general.selection;
    const patientSelectionMap = getState().general.patientSelectionMap;

    /**
     * Get preserved selection from the map
     */
    if (selection.patient !== storeSelection.patient) {
        if (!!patientSelectionMap[selection.patient]) {
            selection = patientSelectionMap[selection.patient];
        }
    } 

    patientSelectionMap[selection.patient] = selection;
    dispatch(setPatientSelectionMap(patientSelectionMap));

    return dispatch({
        type: SET_SELECTION,
        payload: selection,
    })
}

export const setPatientSelectionMap = (data) => (dispatch) => {
    return dispatch({
        type: SET_PATIENT_SELECTION_MAP,
        payload: data,
    })
}

export const setSnackbar = (variant, message, open = true) => (dispatch) => {
    return dispatch({
        type: SET_SNACKBAR,
        payload: { open, variant, message},
    })
}

export const setLoading = (name, isLoading = true) => (dispatch) => {
    return dispatch({
        type: SET_LOADING,
        payload: { name, isLoading },
    })
}

export const clearLoading = () => (dispatch) => {
    return dispatch({
        type: CLEAR_LOADING,
    })
}

export const setTargetLoading = (data) => (dispatch) => {
    return dispatch({
        type: SET_TARGET_LOADING,
        payload: data,
    })
}

export const setLibraryLoading = (data) => (dispatch) => {
    return dispatch({
        type: SET_LIBRARY_LOADING,
        payload: data,
    })
}

export const setGlobalDialog = (title, message = '', handleAnswer = undefined, content = undefined, showActions = true) => (dispatch) => {
    return dispatch({
        type: SET_GLOBAL_DIALOG,
        payload: { open: true, title, message, handleAnswer, content, showActions },
    })
}

export const closeGlobalDialog = () => (dispatch) => {
    return dispatch({
        type: CLOSE_GLOBAL_DIALOG,
    })
}

export const setZendeskDialog = () => (dispatch) => {
    return dispatch({
        type: SET_ZENDESK_DIALOG,
        payload: { open: true },
    })
}

export const closeZendeskDialog = () => (dispatch) => {
    return dispatch({
        type: CLOSE_ZENDESK_DIALOG,
    })
}

export const setUploadDialog = (title, message, templateLink = undefined, templateName = true, acceptedTypes = [], handleUpload = undefined, uploadCallback = undefined) => (dispatch) => {
    return dispatch({
        type: SET_UPLOAD_DIALOG,
        payload: { open: true, title, message, templateLink, templateName, acceptedTypes, handleUpload, uploadCallback },
    })
}

export const closeUploadDialog = () => (dispatch) => {
    return dispatch({
        type: CLOSE_UPLOAD_DIALOG,
    })
}

export const setFinishedOnboardings = (data) => (dispatch) => {
    return dispatch({
        type: SET_FINISHED_ONBOARDINGS,
        payload: data
    })
}

export const setSidebarWidth = (data) => (dispatch) => {
    return dispatch({
        type: SET_SIDEBAR_WIDTH,
        payload: data
    })
}

export const setIsMobile = (data) => (dispatch) => {
    return dispatch({
        type: SET_IS_MOBILE,
        payload: data
    })
}