import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import {
    Grid,
    FormHelperText,
    Button,
    Typography,
    Divider,
    CircularProgress,
    Box,
} from '@material-ui/core';

import { setSnackbar } from '../../store/general/actions';
import * as SettingService from '../../services/SettingService';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';
import { setUser } from '../../store/auth/actions';
import FilterDropdownButton from '../../assets/button/FilterDropdownButton';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    additionalDataWrapperOuter: {
        padding: '0px 0px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    label: {
        fontSize: 14,
        fontWeight: 500,
        marginBottom: 15,
    },
    divider: {
        width: '100%',
        marginTop: '150px',
    },
    filter: {
        marginRight: 15,
        marginBottom: 10,
    },
}));

export const defaultColumnSettings = {
    clinicals: [
        { label: 'Star', checked: true, key: 'star' },
        { label: 'Status', checked: true, key: 'status' },
        { label: 'Phase', checked: true, key: 'phase' },
        { label: 'Data Type', checked: true, key: 'dataType' },
        { label: 'Date Opened', checked: true, key: 'opened' },
        { label: 'Date Mastered', checked: true, key: 'mastered' },
    ],
    users: [
        { label: 'First Name', checked: true, key: 'firstName' },
        { label: 'Last Name', checked: true, key: 'lastName' },
        { label: 'Title', checked: true, key: 'title' },
        { label: 'Email', checked: true, key: 'email' },
        { label: 'Phone', checked: true, key: 'mobilePhone' },
        { label: 'Roles', checked: true, key: 'roles' },
        { label: 'Sites', checked: true, key: 'sites' },
        { label: 'Status', checked: true, key: 'status' },
        { label: 'Account Type', checked: true, key: 'accountType' },
    ],
    clients: [
        { label: 'First Name', checked: true, key: 'firstName' },
        { label: 'Last Name', checked: true, key: 'lastName' },
        { label: 'Email', checked: true, key: 'email' },
        { label: 'Phone', checked: true, key: 'phone' },
        { label: 'Site', checked: true, key: 'site' },
        { label: 'Status', checked: true, key: 'status' },
    ],
};

const DefaultPrompt = (props) => {
    const classes = useStyles();
    const { user, setSnackbar } = props;

    const [columnsSetting, setColumnsSetting] = useState(defaultColumnSettings);

    const [isUpdating, setIsUpating] = useState(false);
    const [error, setError] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [setttingSynced, setSetttingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'columnsSetting')
            .then((res) => {
                const settings = res.data?.data?.data;
                if (settings && settings.clinicals && settings.users && settings.clients) {
                    setColumnsSetting(parseSettings(settings));
                    // setColumnsSetting(defaultColumnSettings);
                } else {
                    setColumnsSetting(defaultColumnSettings);
                    setNewSetting(true);
                }
            })
            .finally(() => {
                setSetttingSynced(true);
            });
    }, []);

    const parseSettings = (settings) => {
        const parsedSettings = { ...defaultColumnSettings };
        parsedSettings.clinicals.forEach((item) => (item.checked = settings.clinicals[item.key]));
        parsedSettings.users.forEach((item) => (item.checked = settings.users[item.key]));
        parsedSettings.clients.forEach((item) => (item.checked = settings.clients[item.key]));
        return parsedSettings;
    };

    const getformattedSettings = () => {
        const formatted = { clinicals: {}, users: {}, clients: {} };
        columnsSetting.clinicals.forEach((item) => {
            formatted.clinicals[item.key] = item.checked;
        });
        columnsSetting.users.forEach((item) => {
            formatted.users[item.key] = item.checked;
        });
        columnsSetting.clients.forEach((item) => {
            formatted.clients[item.key] = item.checked;
        });
        return formatted;
    };

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'columnsSetting')
            .then((res) => {
                if (res.status === 200) {
                    const settings = res.data?.data?.data;
                    if (settings && settings.clinicals && settings.users && settings.clients) {
                        const parsedSettings = parseSettings(settings);
                        setColumnsSetting(parsedSettings);

                        const userSettings = user.settings;
                        userSettings.columnsSetting = settings;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Columns Setting!');
                    } else {
                        setColumnsSetting(defaultColumnSettings);
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSetttingSynced(true);
            });
    };

    const handleSave = () => {
        let error = false;

        error =
            columnsSetting.clinicals.some((item) => typeof item.checked !== 'boolean') ||
            columnsSetting.users.some((item) => typeof item.checked !== 'boolean') ||
            columnsSetting.clients.some((item) => typeof item.checked !== 'boolean');

        if (error) {
            setError(true);
            return;
        }

        setSetttingSynced(false);

        if (newSetting) {
            SettingService.createSetting({
                type: 'columnsSetting',
                data: getformattedSettings(),
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                type: 'columnsSetting',
                data: getformattedSettings(),
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpating(false);
                    getUserSettings();
                }
            });
        }
    };

    const handleChangeSetting = (group, key) => (event) => {
        setIsUpating(true);

        const newSetting = { ...columnsSetting };
        const changedSetting = newSetting[group].find((e) => e.key === key);
        changedSetting.checked = !changedSetting.checked;

        setColumnsSetting(newSetting);
    };

    const isAllChecked = (group) => {
        return !columnsSetting[group].some((filter) => !filter.checked);
    };

    const handleCheckAll = (group) => {
        setIsUpating(true);

        const newSetting = { ...columnsSetting };
        if (isAllChecked(group)) {
            newSetting[group].forEach((filter) => {
                filter.checked = false;
            });
        } else {
            newSetting[group].forEach((filter) => {
                filter.checked = true;
            });
        }
        setColumnsSetting(newSetting);
    };

    const showUsers = CanUserFunc(access.users.view);
    const showClients = CanUserFunc(access.clients.view);

    return (
        <Grid item xs={7} container className={classes.root}>
            <Grid item xs={12} style={{ marginBottom: 16 }}>
                <Typography id="display-columns-setting-section-label" className={classes.title}>Hide/Show Columns</Typography>
            </Grid>
            {!setttingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={80} />
                    </Box>
                </Grid>
            ) : (
                <>
                    <Grid item xs={6} sm={4}>
                        <Typography id="display-columns-setting-clinicals-field-label" className={classes.label}>Clinicals</Typography>
                        <FilterDropdownButton
                            id='clinical-columns'
                            label='Clinicals'
                            filters={columnsSetting?.clinicals.map((el) => ({ ...el, id: el.key }))}
                            handleChangeFilter={(el) => handleChangeSetting('clinicals', el.key)}
                            isAllChecked={isAllChecked('clinicals')}
                            handleCheckAll={() => handleCheckAll('clinicals')}
                            wrapperStyle={classes.filter}
                        />
                    </Grid>
                    {showUsers && (
                        <Grid item xs={6} sm={4}>
                            <Typography id="display-columns-setting-users-field-label" className={classes.label}>Users</Typography>
                            <FilterDropdownButton
                                id='users-columns'
                                label='Users'
                                filters={columnsSetting?.users.map((el) => ({ ...el, id: el.key }))}
                                handleChangeFilter={(el) => handleChangeSetting('users', el.key)}
                                isAllChecked={isAllChecked('users')}
                                handleCheckAll={() => handleCheckAll('users')}
                                wrapperStyle={classes.filter}
                            />
                        </Grid>
                    )}
                    {showClients && (
                        <Grid item xs={6} sm={4}>
                            <Typography id="display-columns-setting-clients-field-label" className={classes.label}>Clients</Typography>
                            <FilterDropdownButton
                                id='clients-columns'
                                label='Clients'
                                filters={columnsSetting?.clients.map((el) => ({
                                    ...el,
                                    id: el.key,
                                }))}
                                handleChangeFilter={(el) => handleChangeSetting('clients', el.key)}
                                isAllChecked={isAllChecked('clients')}
                                handleCheckAll={() => handleCheckAll('clients')}
                                wrapperStyle={classes.filter}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        <FormHelperText error={error}>
                            {error ? 'Error occurred while setting the columns' : ' '}
                        </FormHelperText>
                    </Grid>

                    <Grid item container xs={12} justify='space-between' direction='row-reverse'>
                        <Button id="display-columns-setting-save" onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </>
            )}
            <Divider className={classes.divider} />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar })(DefaultPrompt);
