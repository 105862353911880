import dateFormatter from './dateFormatter';

export default function formatData(data) {
  const newData = data.map((item) => {
    const notes = item.notes.map((note) => ({
      ...note,
      date: dateFormatter(note.date),
    }));
    const targetDatas = item.targetData.map((targetData) => (targetData.date ? {
      ...targetData,
      date: dateFormatter(targetData.date),
    } : targetData));
    return { ...item, notes: notes, targetData: targetDatas };
  });
  return newData;
}
