import agent from './agent';

export const getOrganizations = (searchTerm, sortBy, sortAs, skip, limit, isActive) => {
    return agent.get('/company', {
        params: {
            searchTerm,
            sortBy,
            sortAs,
            skip,
            limit,
            isActive,
            projection: 'createdAt,updatedAt,id,name,subscription,organizationId,branches,isActive,owner,totalUsers'
        },
    });
}

export const getOrganizationById = (id) => {
    return agent.get('/company/get', {
        params: {
            id,
            projection: 'createdAt,updatedAt,id,name,subscription,organizationId,branches,isActive,owner,totalUsers'
        },
    });
}

export const postOrganization = (data) => {
    return agent.post('/company/create', data);
}

export const updateOrganization = (data) => {
    return agent.put('/company/update', data);
}

export const deleteOrganization = (companyId) => {
    return agent.delete('/company/delete', {
        params: {
            id: companyId,
        },
    });
}
