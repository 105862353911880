import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';
import { setLoading, setSnackbar, setGlobalDialog } from '../../store/general/actions';
import { deleteSite } from '../../services/SiteService';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        fontSize: '16px !important',
        verticalAlign: 'middle !important',
        padding: '12px 12px',
    },
    tableCell: {
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    actionColumn: {
        width: 75,
        padding: '2px 12px',
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
}));

const headCells = [
    { id: 'name', label: 'Name', sort: true },
    { id: 'address', label: 'Address', sort: true },
];

const SiteTable = (props) => {
    const classes = useStyles();
    const { handleEdit, sites, loading, setLoading, setSnackbar, fetchSites, setGlobalDialog, setSort, sort } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedSite, setSelectedSite] = React.useState(null);

    useEffect(() => {
        if (sort) {
            setOrder(sort.sortAs || 'asc');
            setOrderBy(sort.sortBy || '');
        }
    }, [sort])

    useEffect(() => {
        if (sites.length <= page * rowsPerPage) {
            setPage(page - 1 >= 0 ? page - 1 : 0);
        }
    }, [sites])

    const handleRequestSort = (property) => event => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort({ sortAs: isAsc ? 'desc' : 'asc', sortBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openSiteAction = (site) => (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedSite(site);
    }

    const handleEditSite = () => {
        handleEdit(selectedSite);
        setAnchorEl(null);
    }

    /** When the entire row is clicked, set the selected client to clicked client and open edit form */
    const handleRowClick = (site) => (event) => {
        setSelectedSite(site);
        handleEdit(site);
    }

    const handleDeleteSite = () => {
        setGlobalDialog(
            `Delete site?`,
            `Deleted site cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    setLoading('deleteSite');
                    deleteSite(selectedSite.id).then(() => {
                        setLoading('deleteSite', false);
                        setSnackbar('success', 'Site has been deleted!');
                        setSelectedSite(null);
                        fetchSites();
                    }).catch((err) => {
                        setLoading('deleteSite', false);
                    })
                }
            },
        )
        setAnchorEl(null);
    }

    const showActions = CanUserFunc(access.sites.edit) || CanUserFunc(access.sites.remove);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tblHeadRow}>
                                {headCells.map(headCell => (
                                    <TableCell
                                        key={headCell.id}
                                        className={classes.headCell}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.sort ?
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={handleRequestSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                            :
                                            headCell.label
                                        }
                                    </TableCell>
                                ))}
                                {showActions &&
                                    <TableCell className={`${classes.headCell} ${classes.actionColumn}`}>Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        {loading.getSites ?
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={showActions ? 3 : 2}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {sites.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                onClick={CanUserFunc(access.sites.edit) ? handleRowClick(row) : null}
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell id={`sites-row-name-${row.id}`} className={classes.tableCell} component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell id={`sites-row-address-${row.id}`} className={classes.tableCell}>{row.address}</TableCell>
                                                {showActions &&
                                                    <TableCell className={`${classes.tableCell} ${classes.actionColumn}`}>
                                                        <IconButton id={`sites-row-actions-${row.id}`} onClick={openSiteAction(row)}>
                                                            <img alt="Edit" src={require("../../resources/EditIcon.svg")} />
                                                        </IconButton>
                                                        {selectedSite &&
                                                            <LoadingCheck show={(loading.deleteSite || loading.editSite) && selectedSite && selectedSite.id === row.id} size={24} />
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        );
                                    })}
                                {!sites.length &&
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell className={classes.noData} colSpan={showActions ? 3 : 2}>No sites found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 50, 100]}
                    component="div"
                    count={sites.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.sites.edit) ?
                    <MenuItem id={`sites-row-edit`} onClick={handleEditSite}>Edit</MenuItem>
                    : null
                }
                {CanUserFunc(access.sites.remove) ?
                    <MenuItem id={`sites-row-delete`} onClick={handleDeleteSite}>Delete</MenuItem>
                    : null
                }
            </Menu>
        </div>
    );
}

SiteTable.propTypes = {
    handleEdit: PropTypes.func.isRequired,
    sites: PropTypes.array.isRequired,
    loading: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    fetchSites: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setSnackbar, setLoading, setGlobalDialog })(SiteTable);