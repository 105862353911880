import { fetchNewTargetData, sendData } from "../../services/sessionSyncService";
import { submitSessionData, deleteSessionData as discardSessionData } from "../../services/sessionService";
import {
    START_SESSION,
    PAUSE_SESSION,
    RESUME_SESSION,
    END_SESSION,
    ADD_SESSION_DATA,
    DELETE_SESSION_DATA,
    DELETE_ALL_SESSION_DATA,
    SET_SESSION_LOADING,
    SET_SESSION_CONFIG,
    LOAD_SESSIONS,
    SET_SESSION_TARGETS,
    SYNC_SESSION_TARGETS,
    SYNC_SESSION_DATA,
    SET_TARGET_CHANGES
} from "./action-types";

export const startSession = (patientId, dataSheetId, date, targets) => (dispatch) => {
    return dispatch({
        type: START_SESSION,
        payload: { patientId, dataSheetId, date, targets },
    });
};

export const pauseSession = (id) => (dispatch) => {
    return dispatch({
        type: PAUSE_SESSION,
        payload: id,
    });
};

export const resumeSession = (id) => (dispatch) => {
    return dispatch({
        type: RESUME_SESSION,
        payload: id,
    });
};

export const cancelSession = (id) => async (dispatch, getState) => {
    const activeSessionId = getState().sessions.activeSessionId;
    const session = getState().sessions.sessions.find(e => e.id === activeSessionId);
    await discardSessionData(session);
    return dispatch({
        type: END_SESSION,
        payload: id,
    });
}

export const endSession = (id) => async (dispatch, getState) => {
    const activeSessionId = getState().sessions.activeSessionId;
    const session = getState().sessions.sessions.find(e => e.id === activeSessionId);
    await submitSessionData(session, true);
    return dispatch({
        type: END_SESSION,
        payload: id,
    });
};

export const addSessionData = (sessionId, targetId, dataType, data, responseSummary = '') => (dispatch) => {
    return dispatch({
        type: ADD_SESSION_DATA,
        payload: { sessionId, targetId, dataType, data, responseSummary },
    });
};

export const deleteSessionData = (sessionId, dataId) => (dispatch) => {
    return dispatch({
        type: DELETE_SESSION_DATA,
        payload: { sessionId, dataId },
    });
};

export const deleteAllSessionData = (sessionId) => (dispatch) => {
    return dispatch({
        type: DELETE_ALL_SESSION_DATA,
        payload: { sessionId },
    });
};

export const setSessionLoading = (sessionId, isLoading) => (dispatch) => {
    return dispatch({
        type: SET_SESSION_LOADING,
        payload: { sessionId, isLoading },
    });
};

export const setSessionConfig = (key, value) => (dispatch) => {
    return dispatch({
        type: SET_SESSION_CONFIG,
        payload: { key, value },
    });
};

export const loadSessions = () => (dispatch, getState) => {
    return dispatch({
        type: LOAD_SESSIONS,
        payload: {},
    });
};

export const setSessionTargets = (sessionId, targets) => (dispatch) => {
    return dispatch({
        type: SET_SESSION_TARGETS,
        payload: { sessionId, targets },
    });
};

export const syncSessionTargets = () => async (dispatch, getState) => {
    const activeSessionId = getState().sessions.activeSessionId;
    const session = getState().sessions.sessions.find(e => e.id === activeSessionId);
    const targets = await fetchNewTargetData(session);
    return dispatch({
        type: SYNC_SESSION_TARGETS,
        payload: { targets },
    });
};

export const syncSessionData = (sessionId, retry = true) => async (dispatch, getState) => {
    const session = getState().sessions.sessions.find(e => e.id === sessionId);
    const targetIdDataIdMap = await sendData(session, null, retry);
    return dispatch({
        type: SYNC_SESSION_DATA,
        payload: { sessionId, targetIdDataIdMap },
    });
};

export const setTargetChanges = (sessionId, changes) => async (dispatch, getState) => {
    return dispatch({
        type: SET_TARGET_CHANGES,
        payload: { sessionId, changes },
    });
};