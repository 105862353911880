import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
	ResponsiveContainer, LineChart, Line, LabelList, Tooltip,
} from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
	dateAxisSetting, percentAxisSetting, graphHeight, defaultLineSetting, defaultAxisSetting,
	defaultLineChartSetting, getColor, toDateFull,
	renderCustomizedValue, defaultTooltipSetting, getMinMaxDate, generateDomainTicks,
	splitGraphDataByPhases, getTargetColor, generateTargetName
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const CorrectIncorrectGraph = (props) => {
	const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
	const [disabledLines, setDisabledLines] = useState({});
	const countTotalData = props.graphData.length;
	const height = graphHeight + Math.floor(countTotalData / 7) * 25;
	const valueToUse = !props.usePercent && props.useCumulative ? 'cumulative' : 'value';

	const formattedPayload = useMemo(() => {
		const payload = [];
		
		props.graphData.forEach((target, index) => {
			if (props.usePercent) {
				if (disabledLines[target.name]) {
					return;
				}
			}
			target.targetData
				.filter(e => dateToDays(e.date) === hoveredDate)
				.forEach((targetData) => {
					if (props.usePercent) {
						payload.push({
							date: hoveredDate,
							color: getColor(index, 'light'),
							name: target.name,
							value: typeof targetData.value.percentage === 'number' ? targetData.value.percentage : null,
						});
					} else {
						if (!disabledLines[`${target.name}: correct`]) {
							payload.push({
								date: hoveredDate,
								color: getColor(index, 'light'),
								name: `${target.name}: correct`,
								value: targetData[valueToUse].count.plus,
							});
						}
						if (!disabledLines[`${target.name}: incorrect`]) {
							payload.push({
								date: hoveredDate,
								color: getColor(index, 'dark'),
								name: `${target.name}: incorrect`,
								value: targetData[valueToUse].count.minus,
							});
						}
					}
				})
		})
		return payload;
	}, [hoveredDate, disabledLines, props.usePercent, props.graphData, props.useCumulative])

	const { graphDomain, graphTicks } = useMemo(() => {
		let { graphDomain, graphTicks } = props;
		if (!graphDomain.length || !graphTicks.length) {
			const { minDate, maxDate } = getMinMaxDate(props.graphData);
			const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
			graphDomain = domain;
			graphTicks = ticks;
		}
		return { graphDomain, graphTicks };
	}, [props.graphDomain, props.graphTicks, props.graphData]);

	const formattedGraphData = useMemo(() => {
		const formattedData = [];
		const dateValueMap = {};
		let maxValue = props.usePercent ? 100 : 9;
		props.graphData.forEach(target => {
			const newTarget = clonedeep(target);
			newTarget.targetData.length > 0 && newTarget.targetData.forEach(targetData => {
				const date = dateToDays(targetData.date);
				if (props.usePercent) {
					const value = targetData.value.percentage;

					const dateValue = `${date}-${value}`;
					dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;
					
					targetData.value.percentage = {
						value,
						sameDot: dateValueMap[dateValue]
					}
				} else {
					const valueMinus = targetData[valueToUse].count.minus;
					const valuePlus = targetData[valueToUse].count.plus;
					if (valueMinus > maxValue) {
						maxValue = valueMinus;
					}
					if (valuePlus > maxValue) {
						maxValue = valuePlus;
					}

					const dateValuePlus = `${date}-${valuePlus}`;
					dateValueMap[dateValuePlus] = (dateValueMap[dateValuePlus] ?? -1) + 1;
					targetData.value.count.plus = {
						value: valuePlus,
						sameDot: dateValueMap[dateValuePlus]
					}

					const dateValueMinus = `${date}-${valueMinus}`;
					dateValueMap[dateValueMinus] = (dateValueMap[dateValueMinus] ?? -1) + 1;					
					targetData.value.count.minus = {
						value: valueMinus,
						sameDot: dateValueMap[dateValueMinus]
					}
				}
			})
			
			formattedData.push(newTarget);
		})

		const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
		formattedData.forEach(target => {
			target.targetData.length > 0 && target.targetData.forEach(targetData => {
				if (props.usePercent) {
					const valueObj = targetData.value.percentage;
					targetData.value.percentage = valueObj.value + (valueObj.sameDot * dotMultiplier);
				} else {
					const valueObjMinus = targetData.value.count.minus;
					const valueObjPlus = targetData.value.count.plus;
					targetData.value.count.minus = valueObjMinus.value + (valueObjMinus.sameDot * dotMultiplier);
					targetData.value.count.plus = valueObjPlus.value + (valueObjPlus.sameDot * dotMultiplier);
				}
			})
		})
		return formattedData;
	}, [props.graphData, props.usePercent, props.useCumulative])

	const graphData = useMemo(() => {
		if (!props.showPhase) {
			return formattedGraphData;
		} else {
			return splitGraphDataByPhases(formattedGraphData);
		}
	}, [formattedGraphData, props.showPhase])

	return (
		props.usePercent ?
			<ResponsiveContainer height={height}>
				<LineChart {...defaultLineChartSetting}>
					{DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
					{xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
					{yAxis('percent', percentAxisSetting, 'Percentage', !!props.graphElements.axisLabelsY)}
					{(enableTooltip && !props.isDownloading) &&
						<Tooltip
							{...defaultTooltipSetting}
							content={
								<CustomTooltip
									labelFormatter={toDateFull}
									valueFormatter={(value) => `${value}%`}
									formattedPayload={formattedPayload}
									setHoveredDate={setHoveredDate}
								/>
							}
						/>
					}
					{graphData.map((target, index) => (
						<Line
							{...defaultLineSetting}
							stroke={getTargetColor(target, index, 'light')}
							dataKey="percent"
							name={generateTargetName(target, index)}
							key={generateTargetName(target, index)}
							isAnimationActive={enableAnimation}
							data={!disabledLines[target.name] ?
								target.targetData.map(e => ({
									date: dateToDays(e.date),
									percent: typeof e.value.percentage === 'number' ? e.value.percentage : null,
								})) : []
							}
						>
							{props.graphElements.lineTitles && <LabelList dataKey="percent" content={(props) => renderCustomizedValue(props, 0, -15, () => target.name)} />}
							{props.graphElements.dataValues && <LabelList dataKey="percent" content={(props) => renderCustomizedValue(props, 3, 15, (e) => typeof e === 'number' ? `${e}%` : null)} />}
						</Line>
					))}
				</LineChart>
			</ResponsiveContainer>
			:
			<ResponsiveContainer height={height}>
				<LineChart {...defaultLineChartSetting}>
					{DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
					{xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
					{yAxis('value', defaultAxisSetting, 'Count', !!props.graphElements.axisLabelsY)}
					{(enableTooltip && !props.isDownloading) &&
						<Tooltip
							{...defaultTooltipSetting}
							content={
								<CustomTooltip
									labelFormatter={toDateFull}
									formattedPayload={formattedPayload}
									setHoveredDate={setHoveredDate}
								/>
							}
						/>
					}
					{graphData.map((target, index) => (
						<Line
							{...defaultLineSetting}
							stroke={getTargetColor(target, index, 'light')}
							dataKey="value"
							name={generateTargetName(target, index, ': correct')}
							key={generateTargetName(target, index, ': correct')}
							isAnimationActive={enableAnimation}
							data={!disabledLines[`${target.name}: correct`] ?
								target.targetData.map(e => (
									{ date: dateToDays(e.date), value: e.value.count.plus }
								)) : []
							}
						>
							{props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: correct`)} />}
							{props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, (value) => value ? Math.round(value) : '')} />}
						</Line>
					))}
					{graphData.map((target, index) => (
						<Line
							{...defaultLineSetting}
							stroke={getTargetColor(target, index, 'dark')}
							dataKey="value"
							name={generateTargetName(target, index, ': incorrect')}
							key={generateTargetName(target, index, ': incorrect')}
							isAnimationActive={enableAnimation}
							data={!disabledLines[`${target.name}: incorrect`] ?
								target.targetData.map(e => (
									{ date: dateToDays(e.date), value: e.value.count.minus }
								)) : []
							}
						>
							{props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => `${target.name}: incorrect`)} />}
							{props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, (value) => value ? Math.round(value) : '')} />}
						</Line>
					))}
				</LineChart>
			</ResponsiveContainer>
	)
}

CorrectIncorrectGraph.propTypes = {
	graphData: PropTypes.array.isRequired,
	usePercent: PropTypes.bool,
	graphElements: PropTypes.object.isRequired,
	graphDomain: PropTypes.array.isRequired,
	graphTicks: PropTypes.array.isRequired,
	showPhase: PropTypes.bool.isRequired,
	isDownloading: PropTypes.bool.isRequired,
};

export default CorrectIncorrectGraph;
