import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    wrapper: {
        backgroundColor: '#fff',
        zIndex: 10,
        padding: 10,
    },
    label: {
        margin: 0,
    },
    item: {
        margin: '5px 0px',
        paddingLeft: 10,
    },
}));

const CustomTooltip = (props) => {
    const classes = useStyles();
    const { payload, label, labelFormatter, valueFormatter, formattedPayload, setHoveredDate } = props;

    /**
     * Recharts is buggy on determining what to shows
     * on payload, so I had to create my own function
     * given the date, we generate the applicable payload
     * date, color, name and value is required.
     */
    useEffect(() => {
        if (!payload?.length || !props.active) return;
        setHoveredDate(payload[0].payload.date);
    })    

    if (!props.active) return null;

    return (
        <Paper className={classes.wrapper}>
            <p className={classes.label}>{labelFormatter ? labelFormatter(label) : label}</p>
            <Grid container>
                <Grid item>
                    {formattedPayload.map((entry, index) => (
                        !!entry &&
                        <p className={classes.item} key={`item-${index}`} style={{ color: entry.color }}>
                            •&nbsp;{entry.name}
                        </p>
                    ))}
                </Grid>
                <Grid item>
                    {formattedPayload.map((entry, index) => (
                        !!entry &&
                        <p className={classes.item} key={`item2-${index}`} style={{ color: entry.color }}>
                            :&nbsp;{valueFormatter ? valueFormatter(entry.value) : entry.value}
                        </p>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomTooltip;