import agent from './agent';

export const getUsers = (sortBy, sortAs, skip, limit, isActive, branches, roles, term, accountType) => {
    return agent.post('/user?', {
        term,
        sortBy,
        sortAs,
        skip,
        limit,
        isActive,
        branches,
        roles,
        accountType,
    }, { params: {
        projection: 'id,name,firstName,lastName,email,username,mobilePhone,branches,branches.company,roles,isActive,title,accountType'
    }});
}

export const searchUser = (term, branches) => {
    return agent.post('/user?', {
        term,
        isActive: true,
        branches,
    });
}

export const getUser = (id) => {
    return agent.get('/user/get', {
        params: {
            id,
            projection: 'id,name,firstName,lastName,email,username,mobilePhone,branches,roles,isActive,title,patients,patients.branch,note,patientId'
        },
    });
}

export const postUser = (data) => {
    return agent.post('/user/create', data);
}

export const updateUser = (data) => {
    return agent.put('/user/update', data);
}

export const deleteUser = (userId) => {
    return agent.delete('/user/delete', {
        params: {
            id: userId,
        },
    });
}

export const activateUser = (userId) => {
    return agent.post('/user/activate', { userId });
}

export const deactivateUser = (userId) => {
    return agent.post('/user/deactivate', { userId });
}

export const resetUserPassword = (id) => {
    return agent.put('/user/update', { id, changePassword: true });
}

export const getTitles = () => {
    return agent.get('/title');
}

export const getUsersByPatient = (patientId) => {
    return agent.get('/user/patient', {
        params: {
            id: patientId,
            projection: 'id,firstName,lastName'
        }
    });
}

export const updateTitle = (title) => {
    return agent.put('/title/update', title);
}

export const deleteTitle = (id) => {
    return agent.delete('/title/delete', {
        params: {
            id,
        }
    });
}

export const downloadUsers = (sortBy, sortAs, skip, limit, isActive, branches, roles, term, accountType) => {
    return agent.post('/user/download', {
        term,
        sortBy,
        sortAs,
        skip,
        limit,
        isActive,
        branches,
        roles,
        accountType,
    }, { params: {
        projection: 'id,name,firstName,lastName,email,username,mobilePhone,branches,roles,isActive,title'
    }});
}

export const uploadUsers = (file) => {
    return agent.post('/user/upload', file);
}
