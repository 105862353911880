import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
    Grid,
    InputLabel,
    TextField,
    Checkbox,
    Radio,
    FormControlLabel,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton,
    FormHelperText,
    Select,
    MenuItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import { format, addDays, setMinutes, setHours, addHours } from 'date-fns';

import { setLoading, setSnackbar } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '0 40px 10px',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: '100%',
    },
    timeSelect: {
        width: '40%',
    },
    divider: {
        fontWeight: 'bold',
        margin: '0 10px',
    },
    inputTitle: {
        width: '100%',
        marginTop: '21px',
    },
    multilineInputLarge: {
        maxHeight: 163,
        height: '100% !important',
        overflowY: 'auto !important',
    },
    multilineInputLargeWrapper: {
        height: 200,
        maxHeight: 200,
    },
    label: {
        marginBottom: 0,
        color: '#000000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    },
    endLabel: {
        marginBottom: 0,
        color: '#000000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
        marginLeft: '5px',
    },
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    saveBtn: {
        padding: '6px 40px',
    },
    labelWithError: {
        marginBottom: 0,
        color: '#000000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 60,
      },
    },
  };

const maxLength = {
    title: 128,
    message: 1024
}

const NewReminder = (props) => {
    const classes = useStyles();
    const { onClose, onSave, open, selectedReminder, loading, setLoading, setSnackbar, fetchUser } = props;

    const [reminderSettings, setReminderSettings] = useState({
        title: '',
        message: '',
        isRepeat: false,
        period: 1,
        endRepeat: null,
        startSession: false,
        beforeEndingSession: false,
        isMinuteAfterSessionStartEnabled: false,
        isSpecificTimeEnabled: false,
        specificTime: null,
        minuteAfterSessionStart: 1,
    });
    const [error, setError] = useState(false);

    useEffect(() => {
        if (selectedReminder) {
            setReminderSettings({
                ...selectedReminder,
                ...selectedReminder.repeat,
                ...selectedReminder.schedule,
                isSpecificTimeEnabled: Boolean(selectedReminder.schedule?.specificTime),
                isMinuteAfterSessionStartEnabled: Number(selectedReminder.schedule?.minuteAfterSessionStart) > 0,
                specificTime: selectedReminder.schedule?.specificTime ? {
                    hour: new Date(selectedReminder.schedule?.specificTime).getHours(),
                    minute: new Date(selectedReminder.schedule?.specificTime).getMinutes(),
                } : {
                    hour: 0,
                    minute: 0,
                } 
            });
            setError(false);
        }
    }, [selectedReminder])

    useEffect(() => {
        if (!open) {
            setReminderSettings({
                title: '',
                message: '',
                isRepeat: false,
                period: 1,
                endRepeat: null,
                startSession: false,
                beforeEndingSession: false,
                isMinuteAfterSessionStartEnabled: false,
                isSpecificTimeEnabled: false,
                specificTime: null,
                minuteAfterSessionStart: 1,
            });
            setError(false);
        }
    }, [open]);

    const handleChange = (field) => (event) => {
        let { value, type } = event.target;
        if (field === 'isRepeat') {
            setReminderSettings({
                ...reminderSettings,
                isRepeat: value === '1',
                endRepeat:
                    value === '1' 
                    ? format(setMinutes(setHours(addDays(new Date(), 7), 18), 30), "yyyy-MM-dd'T'HH:mm")
                    : reminderSettings.endRepeat
            });
            return;
        }

        if (type === 'number') {
            setReminderSettings({ ...reminderSettings, [field]: parseInt(value ? value : '1') });
            return;
        }

        if (maxLength[field]) {
            if (value.length > maxLength[field]) {
                value = value.substring(0, maxLength[field]);
            }
        }

        setReminderSettings({ ...reminderSettings, [field]: value });
    }


    const handleDateChange = (field) => (data) => {
        setReminderSettings({ ...reminderSettings, [field]: format(new Date(data), "yyyy-MM-dd'T'HH:mm") });
    }

    const handleChangeSpecificTime = (field) => (data) => {
        setReminderSettings({ ...reminderSettings, specificTime: { ...reminderSettings.specificTime, [field]: parseInt(data.target.value) }})
    }

    const handleScheduleSettings = (selectedField) => () => {
        const fields = ['startSession', 'beforeEndingSession', 'isMinuteAfterSessionStartEnabled', 'isSpecificTimeEnabled'];
        
        let updatedReminderSettings = { ...reminderSettings };
        updatedReminderSettings[selectedField] = !updatedReminderSettings[selectedField];
        fields.filter(field => field !== selectedField).forEach(field => {
            updatedReminderSettings[field] = false;
        })

        if (!updatedReminderSettings.isMinuteAfterSessionStartEnabled) {
            updatedReminderSettings.minuteAfterSessionStart = 1;
        } else {
            updatedReminderSettings.minuteAfterSessionStart = updatedReminderSettings.minuteAfterSessionStart || 1;
        }

        if (updatedReminderSettings.isSpecificTimeEnabled) {
            updatedReminderSettings.specificTime = {
                hour: new Date().getHours() + 1,
                minute: new Date().getMinutes(),
            }
        } else {
            updatedReminderSettings.specificTime = null
        }

        if(selectedField === 'beforeEndingSession') {
            updatedReminderSettings.isRepeat = false;
        }

        setReminderSettings(updatedReminderSettings);
    }

    const handleCancel = () => {
        onClose();
    }

    const handleSave = () => {
        const {
            title,
            message,
            isRepeat,
            period,
            endRepeat,
            startSession,
            isSpecificTimeEnabled,
            isMinuteAfterSessionStartEnabled,
            beforeEndingSession,
            minuteAfterSessionStart,
            specificTime,
        } = reminderSettings;

        let error = false;
        if (
            !reminderSettings.isMinuteAfterSessionStartEnabled
            && !reminderSettings.isSpecificTimeEnabled
            && !reminderSettings.startSession
            && !reminderSettings.beforeEndingSession
        ) {
            error = true;
        }
        
        if (!title.trim() || !message.trim()) {
            error = true;
        }

        if (isRepeat && new Date(endRepeat).getTime() <= new Date().getTime()) {
            error = true;
        }

        if (isRepeat && parseInt(period) <= 0) {
            error = true;
        }

        if (isMinuteAfterSessionStartEnabled && parseInt(minuteAfterSessionStart) <= 0) {
            error = true;
        }

        if (error) {
            setError(true);
            return;
        }

        onSave({
            title,
            message,
            repeat: {
                isRepeat,
                period: Number(period),
                endRepeat,
            },
            schedule: {
                startSession,
                beforeEndingSession,
                minuteAfterSessionStart: isMinuteAfterSessionStartEnabled ? Number(minuteAfterSessionStart) : null,
                specificTime: isSpecificTimeEnabled ?  setMinutes(setHours(new Date(), specificTime.hour), specificTime.minute): null,
            },
        });
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid id={`reminder_title_${selectedReminder ? 'edit' : 'new'}_reminder`} item xs={6}>
                        {selectedReminder ? 'Edit Reminder' : 'New Reminder'}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton id="reminder_modal_close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid item container xs={12} className={classes.root}>
                    <Grid item xs={12} className={classes.btnWrapper} container>
                        <Grid item container xs={12} alignItems="center">
                            <Grid item xs={2}>
                                <InputLabel id="reminder_form_field_title_label" className={classes.label} required>Title:</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="reminder_form_field_title"
                                    variant="outlined"
                                    value={reminderSettings.title}
                                    onChange={handleChange('title')}
                                    className={classes.inputTitle}
                                />
                                <FormHelperText id="reminder_form_field_title_helper_text" error={error && !reminderSettings.title.trim()}>
                                    {error && !reminderSettings.title.trim() ? 'Title can\'t be empty' : ' '}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} style={{ marginTop: "28px" }}>
                            <Grid item xs={2}>
                                <InputLabel id="reminder_form_field_message_label" className={classes.label} required>Message:</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    id="reminder_form_field_message"
                                    variant="outlined"
                                    multiline
                                    value={reminderSettings.message}
                                    onChange={handleChange('message')}
                                    className={classes.input}
                                    InputProps={{
                                        classes: {
                                            root: classes.multilineInputLargeWrapper,
                                            inputMultiline: classes.multilineInputLarge,
                                        },
                                    }}
                                />
                                <FormHelperText id="reminder_form_field_message_helper_text" error={error && !reminderSettings.message.trim()}>
                                    {error && !reminderSettings.message.trim() ? 'Message can\'t be empty' : ' '}
                                </FormHelperText>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} style={{ marginTop: "28px" }} alignItems="center">
                            <Grid item xs={12}>
                                <InputLabel id="reminder_form_field_schedule_label" className={classes.label} required>Schedule:</InputLabel>
                            </Grid>
                            <FormHelperText
                                id="reminder_form_field_schedule_helper_text"
                                error={
                                    error
                                    && !reminderSettings.isMinuteAfterSessionStartEnabled
                                    && !reminderSettings.isSpecificTimeEnabled
                                    && !reminderSettings.startSession
                                    && !reminderSettings.beforeEndingSession
                                }
                            >
                                {
                                    error
                                    && !reminderSettings.isMinuteAfterSessionStartEnabled
                                    && !reminderSettings.isSpecificTimeEnabled
                                    && !reminderSettings.startSession
                                    && !reminderSettings.beforeEndingSession 
                                    ? 'Please choose a schedule' : ' '
                                }
                            </FormHelperText>
                        </Grid>
                        <Grid item container xs={12} style={{ marginTop: "10px", marginLeft: "20px" }} alignItems="center">
                            <Grid item xs={1}>
                                <Checkbox
                                    id="reminder_form_field_start_session"
                                    checked={reminderSettings.startSession}
                                    color="primary"
                                    onChange={handleScheduleSettings("startSession")}
                                    name="startSession"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel
                                    id="reminder_form_field_start_session_label"
                                    className={classes.label}
                                    disabled={!reminderSettings.startSession}
                                >
                                    Start Session:
                                </InputLabel>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} style={{ marginTop: "10px", marginLeft: "20px" }} alignItems="center">
                            <Grid item xs={1}>
                                <Checkbox
                                    id="reminder_form_field_before_ending_session"
                                    checked={reminderSettings.beforeEndingSession}
                                    color="primary"
                                    onChange={handleScheduleSettings("beforeEndingSession")}
                                    name="beforeEndingSession"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel
                                    id="reminder_form_field_before_ending_session_label"
                                    className={classes.label}
                                    disabled={!reminderSettings.beforeEndingSession}
                                >
                                    Before Ending Session:
                                </InputLabel>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item container xs={12} alignItems="center" style={{ marginTop: '10px', marginLeft: "20px" }}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        id="reminder_form_field_session_start_enabled"
                                        checked={reminderSettings.isMinuteAfterSessionStartEnabled}
                                        color="primary"
                                        onChange={handleScheduleSettings("isMinuteAfterSessionStartEnabled")}
                                        name="isMinuteAfterSessionStartEnabled"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel
                                        id="reminder_form_field_session_start_enabled_label"
                                        className={classes.label}
                                        disabled={!reminderSettings.isMinuteAfterSessionStartEnabled}
                                    >
                                        Minutes After Session Start:
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="reminder_form_field_minutes"
                                        variant="outlined"
                                        type="number"
                                        value={(reminderSettings.minuteAfterSessionStart || 1).toString()}
                                        onChange={handleChange('minuteAfterSessionStart')}
                                        disabled={!reminderSettings.isMinuteAfterSessionStartEnabled}
                                        className={classes.input}
                                        InputProps={{ inputProps: { min: 1 } }}
                                    />
                                    {error && reminderSettings.isMinuteAfterSessionStartEnabled && parseInt(reminderSettings.minuteAfterSessionStart) <= 0 && (
                                        <FormHelperText id="reminder_form_field_minutes_helper_text" error>
                                            0 or negative value is not allowed
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={1}>
                                    <InputLabel
                                        id="reminder_form_field_minutes_label_1"
                                        className={classes.endLabel}
                                        disabled={!reminderSettings.isMinuteAfterSessionStartEnabled}
                                    >
                                        minute(s)
                                    </InputLabel>
                                </Grid>
                            </Grid>
                            <Grid item container xs={12} alignItems="center" style={{ marginTop: "10px", marginLeft: "20px" }}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        id="reminder_form_field_specific_time"
                                        checked={reminderSettings.isSpecificTimeEnabled}
                                        color="primary"
                                        onChange={handleScheduleSettings("isSpecificTimeEnabled")}
                                        name="isSpecificTimeEnabled"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <InputLabel
                                        id="reminder_form_field_specific_time_label"
                                        className={classes.label}
                                        disabled={!reminderSettings.isSpecificTimeEnabled}
                                    >
                                        Specific Time:
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        id="reminder_form_field_hh"
                                        labelId="HH"
                                        defaultValue=""
                                        disabled={!reminderSettings.isSpecificTimeEnabled}
                                        value={reminderSettings.specificTime?.hour || 0}
                                        onChange={handleChangeSpecificTime('hour')}
                                        className={classes.timeSelect}
                                        MenuProps={MenuProps}
                                    >
                                        {Array(24).fill().map((_, i) => (
                                            <MenuItem value={i} key={i}>{("0" + i).slice(-2)}</MenuItem>
                                        ))}
                                    </Select>
                                    <span className={classes.divider}>:</span>
                                    <Select
                                        id="reminder_form_field_mm"
                                        labelId="MM"
                                        defaultValue=""
                                        disabled={!reminderSettings.isSpecificTimeEnabled}
                                        value={reminderSettings.specificTime?.minute || 0}
                                        onChange={handleChangeSpecificTime('minute')}
                                        className={classes.timeSelect}
                                        MenuProps={MenuProps}
                                    >
                                        {Array(60).fill().map((_, i) => (
                                            <MenuItem value={i} key={i}>{("0" + i).slice(-2)}</MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item container xs={12} style={{ marginTop: "28px" }} alignItems="center">
                            <Grid item xs={2}>
                                <InputLabel id="reminder_form_field_repeat_label" className={classes.label}>Repeat:</InputLabel>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    id="reminder_form_field_repeat_no_label"
                                    value="0"
                                    disabled={reminderSettings.beforeEndingSession}
                                    checked={!reminderSettings.isRepeat}
                                    onChange={handleChange('isRepeat')}
                                    control={
                                        <Radio
                                            id="reminder_form_field_repeat_no"
                                            name="radio-button-demo"
                                            color="primary"
                                        />
                                    }
                                    label="No"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    id="reminder_form_field_repeat_yes_label"
                                    value="1"
                                    disabled={reminderSettings.beforeEndingSession}
                                    checked={reminderSettings.isRepeat}
                                    onChange={handleChange('isRepeat')}
                                    control={
                                        <Radio
                                            id="reminder_form_field_repeat_yes"
                                            name="radio-button-demo"
                                            color="primary"
                                        />
                                    }
                                    label="Yes"
                                />
                            </Grid>
                        </Grid>
                        { reminderSettings.isRepeat && (
                            <Grid item container xs={12} style={{ marginLeft: "30px", marginTop: '10px' }} alignItems="center">
                                <Grid item xs={2}>
                                    <InputLabel id="reminder_form_field_repeat_every_label" className={classes.labelWithError}>Repeat every </InputLabel>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        id="reminder_form_field_zero_allowed_label"
                                        variant="outlined"
                                        type="number"
                                        value={(reminderSettings.period || 1).toString()}
                                        onChange={handleChange('period')}
                                        className={classes.input}
                                        InputProps={{ inputProps: { min: 1 } }}
                                    />
                                    {
                                        error && parseInt(reminderSettings.period) <= 0 && (
                                            <FormHelperText id="reminder_form_field_zero_allowed_helper_text" error>
                                                0 or negative value is not allowed
                                            </FormHelperText>
                                        )
                                    }
                                </Grid>
                                <Grid item xs={1}>
                                    <InputLabel id="reminder_form_field_minutes_label_2" className={classes.endLabel}>
                                        minute(s)
                                    </InputLabel>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button id="reminder_form_cancel_button" className={classes.saveBtn} onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Button
                            id="reminder_form_save_button"
                            className={classes.saveBtn}
                            onClick={handleSave}
                            disabled={loading.loadingReminder || loading.newReminder || loading.editReminder}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

NewReminder.propTypes = {
    loading: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedReminder: PropTypes.object,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setSnackbar, setLoading })(NewReminder);