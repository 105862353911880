import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box, makeStyles } from '@material-ui/core';
import { getUnixTime } from 'date-fns';

import Wrapper from './Wrapper';
import PhaseColoredButton from './PhaseColoredButton';

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 0,
    flexGrow: 0.3,
    '&:first-of-type': {
      flexGrow: 1,
      marginRight:5,
    },
  },
}));

function Frequency(props) {
  const classes = useStyles();
  const clickHandler = (type) => {
    props.onAddSessionData(
      props.sessionId,
      props.target.id,
      props.target.dataType,
      { type: type, time: getUnixTime(new Date()) },
      type === 'zero' ? '0' : '+',
    );
  };

  const isResponseAllowed = useMemo(() => {
    const maxTrialNo = props.target.maximumNumberTrials;
    if (!!maxTrialNo && !isNaN(maxTrialNo)) {
        return props.sessionData.length < maxTrialNo;
    }
    return true;
  }, [props.sessionData?.length]);

  return (
    <Wrapper {...props}>
      <Box display="flex">
        <PhaseColoredButton
          className={classes.button}
          disabled={!isResponseAllowed}
          onClick={() => clickHandler('plus')}
          phase={props.target?.phase}
        >
          +
        </PhaseColoredButton>
        <PhaseColoredButton
          className={classes.button}
          onClick={() => clickHandler('zero')}
          disabled={!!props.sessionData.length || !isResponseAllowed}
          phase={props.target?.phase}
        >
          0
        </PhaseColoredButton>
      </Box>
    </Wrapper>
  );
}

Frequency.propTypes = {
  sessionData: PropTypes.array.isRequired,
  sessionNotes: PropTypes.array.isRequired,
  sessionId: PropTypes.number.isRequired,
  target: PropTypes.object.isRequired,
  onAddSessionData: PropTypes.func.isRequired,
};

export default Frequency;
