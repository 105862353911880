import debounce from 'awesome-debounce-promise';
import  { v4 as uuid } from 'uuid';

import {
    SET_DOMAINS, SET_PROGRAMS, SET_TARGETS, SET_TARGET_DATA, SET_ALL, SET_MEMOS,
} from './action-types';
import { getTargetDataListByResourceId, submitTargetData, updateTarget } from '../../services/TargetService';
import { setLoading, setSnackbar, setSelection } from '../general/actions';
import formatData from '../../utils/formatResData';

const debouncedSubmitTargetData = debounce(submitTargetData, 500);
const debouncedGetTargetDataList = debounce(getTargetDataListByResourceId, 500);

export const setDomains = (data) => (dispatch, getState) => {
    const selection = getState().general.selection;
    if (!data.find(e => e.id === selection.domain)) {
        dispatch(setSelection({ ...selection, all: true, domain: 'all', program: 'all' }));
    }

    return dispatch({
        type: SET_DOMAINS,
        payload: data
    })
}

export const setPrograms = (data) => (dispatch, getState) => {
    const selection = getState().general.selection;
    if (!data.find(e => e.id === selection.program)) {
        dispatch(setSelection({ ...selection, program: 'all' }));
    }

    return dispatch({
        type: SET_PROGRAMS,
        payload: data,
    })
}

export const setTargets = (data) => (dispatch) => {
    return dispatch({
        type: SET_TARGETS,
        payload: data,
    })
}

export const setTargetData = (data) => (dispatch) => {
    return dispatch({
        type: SET_TARGET_DATA,
        payload: data,
    })
}

export const setAll = (data) => (dispatch) => {
    return dispatch({
        type: SET_ALL,
        payload: data,
    })
}

export const addTargetData = (files, targetId, sessionDate = new Date()) => async dispatch => {
    dispatch(setLoading('submitTargetData'));
    const uid = uuid();
    const formData = new FormData();
    formData.append('uid', uid);
    formData.append('targetId', targetId);
    formData.append('sessionDate', sessionDate);
    Array.from(files).forEach(file=>{
        formData.append("customData", file);
      });
    
    return debouncedSubmitTargetData(formData)
        .then(res => {
            dispatch(setLoading('submitTargetData', false));
        })
        .catch(error => {
            dispatch(setLoading('submitTargetData', false));
        });
}

export const getTargetDataList = (type, resourceId, filters) => async dispatch => {
    dispatch(setLoading('getTargetData'));
    // this is a hack to trigger the graph to show dots
    // otherwise the graph won't show dots unless we put isAnimationActive = false
    await dispatch(setTargetData([]));
    return debouncedGetTargetDataList(type, resourceId, filters)
        .then(res => {
            dispatch(setLoading('getTargetData', false));
            const data = formatData(res.data.data);
            dispatch(setTargetData(data));
        })
        .catch(error => {
            dispatch(setLoading('getTargetData', false));
        });
}

export const editTarget = (target) => dispatch => {
    dispatch(setLoading('editTarget'));
    return updateTarget(target)
        .then(res => {
            setLoading('editTarget', false);
            setSnackbar('success', 'Target updated!');
        })
        .catch(error => {
            setLoading('editTarget', false);
        });
}

export const setMemos = (data) => (dispatch) => {
    const unreadMemos = data.filter(e => !e.isRead);
    return dispatch({
        type: SET_MEMOS,
        payload: { memos: data, unreadMemos },
    })
}
