import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, Link } from 'react-router-dom';
import { Tooltip, Grid, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { resetPassword } from '../../services/AuthService';
import { setLoading, setSnackbar } from '../../store/general/actions';
import passwordIsValid from '../../utils/validatePassword';

const useStyles = makeStyles(theme => ({
    paper: {
        width: 350,
        padding: 30,
    },
    loginBtn: {
        padding: '6px 40px',
        width: '100%',
        marginBottom: 10,
    },
    input: {
        width: '100%',
        marginBottom: 30,
    },
    title: {
        textAlign: 'center',
    },
    backToLogin: {
        fontSize: 12,
        color: '#a8a8a8'
    },
    error: {
        fontSize: 12,
        marginTop: 0,
        marginBottom: 10,
        color: 'red',
        textAlign: 'center',
    },
    infoIcon: {
      color: '#0FB99E',
      fontSize: 21,
      marginLeft: 5,
      marginBottom: -5,
    },
}));

const ResetPassword = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { loading, isLoggedIn, setLoading, setSnackbar } = props;
    const [password, setPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const { token } = useParams();

    useEffect(() => {
        if (isLoggedIn) {
            history.push('/');
        }
    }, [isLoggedIn])

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (loading.userLogin) {
            return;
        }

        if (!password || !passwordIsValid(password)) {
            setError(true);
            return;
        }

        setLoading('resetPassword');
        setErrorMessage('');
        resetPassword(token, password).then(() => {
            setLoading('resetPassword', false);
            setSnackbar('success', 'Password has been updated!');
            history.push('/auth/login');
        }).catch((err) => {
            setLoading('resetPassword', false);
            setErrorMessage(err.response.data.message);
        });
    }

    const getErrorText = () => {
        if (!error) {
            return ' ';
        }
        if (error && !password) {
            return "Password can't be empty";
        }
        if (error && !passwordIsValid(password)) {
            return "The password must be at least 8 characters long including one upper case letter and a number."
        }
    }

    const isError = error && (!password || !passwordIsValid(password));
    return (
        <Paper className={classes.paper}>
            <form onSubmit={handleSubmit}>
                <Grid container>
                    <Grid Item xs={12} container justify="center">
					    <img id="reset-password-accel-logo" alt="Accel Logo" src={require("../../resources/AccelLogo.svg")} />
                    </Grid>
                    <Grid item xs={12}>
                        <p id="reset-password-form-title" className={classes.title}>Enter your new password. 
                            <Tooltip title='The password must be at least 8 characters long including one upper case letter and a number.'>
                                <InfoIcon className={classes.infoIcon} />
                            </Tooltip>
                        </p>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="reset-password-password-field"
                            variant="outlined"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className={classes.input}
                            InputProps={{
                                type: 'password',
                            }}
                            error={isError}
                            helperText={getErrorText()}
                        />
                    </Grid>
                    <Grid item xs={12} container alignContent="center" justify="center">
                        <Button id="reset-password-submit-button" type="submit" className={classes.loginBtn} onClick={handleSubmit} disabled={loading.resetPassword}>
                            {loading.userLogin ?
                                'Submitting...'
                                :
                                'Submit'
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage ? <p id="reset-password-form-error" className={classes.error}>{errorMessage}</p> : null}
                    </Grid>
                    <Grid item xs={12} container alignContent="center" justify="center">
			            <Link id="reset-password-login-link" to={{ pathname: '/auth/login' }} className={classes.backToLogin}>Login</Link>
                    </Grid>
                </Grid>
            </form>

        </Paper>
    )
}

ResetPassword.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    isLoggedIn: state.auth.isLoggedIn,
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setSnackbar })(ResetPassword)
