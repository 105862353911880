import agent from './agent';

export const getPatientListByUser = () => {
    return agent.get('/patient/user', {
        params: {
            projection: 'createdAt,updatedAt,id,firstName,lastName,clientId,email,phone,note,isActive,branch',
            env: 'web',
        }
    });
};

export const postNewPatient = (data) => {
    return agent.post('/patient/create', data);
}

export const searchPatient = (searchTerm, branches) => {
    return agent.post('/patient/search', {
        searchTerm,
        branches,
        isActive: true
    })
}

export const getPatients = (searchTerm, sortBy, sortAs, skip, limit, isActive, branches) => {
    return agent.post('/patient/search?', {
        searchTerm,
        sortBy,
        sortAs,
        skip,
        limit,
        isActive,
        branches: branches?.length ? branches : [0],
    });
}

export const getPatient = (id) => {
    return agent.get('/patient/get', {
        params: {
            id,
        },
    });
}

export const updatePatient = (data) => {
    return agent.put('/patient/update', data);
}

export const activatePatient = (id) => {
    return agent.put('patient/activate', { id })
}

export const deactivatePatient = (id) => {
    return agent.put('patient/deactivate', { id })
}

export const deletePatient = (id) => {
    return agent.delete('/patient/delete', {
        params: {
            id,
        },
    });
}

export const downloadPatients = (searchTerm, sortBy, sortAs, skip, limit, isActive, branches) => {
    return agent.post('/patient/download?', {
        searchTerm,
        sortBy,
        sortAs,
        skip,
        limit,
        isActive,
        branches: branches.length ? branches : [0],
    });
}

export const uploadPatients = (file) => {
    return agent.post('/patient/upload', file);
}
