import agent from './agent';

export const getRoles = (sortBy, sortAs, skip, limit) => {
    return agent.get('/role', {
        params: {
            sortBy,
            sortAs,
            skip,
            limit,
        }
    });
}

export const postRole = (data) => {
    return agent.post('/role/create', data);
}

export const updateRole = (data) => {
    return agent.put('/role/update', data);
}

export const deleteRole = (roleId) => {
    return agent.delete('/role/delete', {
        params: {
            id: roleId,
        },
    });
}

export const getRolesDropdown = (companyId) => {
    return agent.get('/role', { params: {
        projection: 'id,name,company',
        companyId
    }})
}
