import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Popover, Grid, Button, CircularProgress
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import { getUser } from '../../services/UserService';
import { setLoading, setGlobalDialog } from '../../store/general/actions';
import UserViewer from '../User/UserViewer';


const useStyles = makeStyles(theme => ({
    popoverPaper: {
        padding: 20,
    },
    field: {
        fontWeight: 500,
        marginBottom: 10,
    },
    list: {
        margin: '5px 0px'
    },
}));

const UserInfoPopover = (props) => {
    const classes = useStyles();
    const { onClose, anchorEl, userId, loading, setLoading, setGlobalDialog } = props;
    const [user, setUser] = useState({});

    useEffect(() => {
        if (userId) {
            setLoading('getUser');
            getUser(userId).then((res) => {
                setUser(res.data.user);
                setLoading('getUser', false);
            })
        }
    }, [userId]);

    const openViewer = () => {
        setGlobalDialog('User Viewer', undefined, undefined,
            <UserViewer selectedUser={user} />,
            false
        )
        onClose();
    }

    return (
        <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            classes={{
                paper: classes.popoverPaper,
            }}
        >
            {loading.getUser ?
                <Grid item container justify="center" alignItems="center">
                    <CircularProgress size={40} />
                </Grid>
                :
                <Grid container>
                    <Grid item xs={3} className={classes.field}>Name</Grid>
                    <Grid item xs={9}>{`${user.firstName} ${user.lastName}`}</Grid>
                    {user.phone &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Phone</Grid>
                            <Grid item xs={9}>{user.phone}</Grid>
                        </React.Fragment>
                    }
                    {user.email &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Email</Grid>
                            <Grid item xs={9}>{user.email}</Grid>
                        </React.Fragment>
                    }
                    {user.sites &&
                        <React.Fragment>
                            <Grid item xs={3} className={classes.field}>Sites</Grid>
                            <Grid item xs={9}>
                                {user.sites.map(site => (
                                    <p key={`${user.id}-site-${site}`} className={classes.list}>{site}</p>
                                ))}
                            </Grid>
                        </React.Fragment>
                    }
                    <Grid item xs={12} container justify="flex-end" onClick={openViewer}>
                        <Button>Show More</Button>
                    </Grid>
                </Grid>
            }
        </Popover>
    )
}

UserInfoPopover.propTypes = {
    onClose: PropTypes.func.isRequired,
    anchorEl: PropTypes.any,
    userId: PropTypes.object.isRequired,
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(UserInfoPopover)
