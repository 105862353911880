import { InputAdornment, TextField, makeStyles } from '@material-ui/core';
import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles(theme => ({
    mobileSearchBtn: {
        backgroundColor: 'transparent',
        border: '2px solid #12b99e',
        color: '#12b99e',
        width: 40,
        height: 40,
        minWidth: 40,
        borderRadius: '10px',
    },
    mobileSearchField: {
        position: 'absolute',
        background: '#fff',
        left: 5,
        right: 5,
        maxWidth: 'calc(100vw - 10px)',
        zIndex: 1,

        '& fieldset': {
            borderColor: '#0FB99E',
            borderWidth: 2
        }
    },
    userTable: {
        maxWidth: 'calc(100vw - 50px)',
        margin: 'auto',
        // paddingRight: 40
    }
}));

const MobileSearchInput = ({ onSearch }) => {
    const classes = useStyles();
    const [mobileMenu, setMobileMenu] = useState(false);
    const [text, setText] = useState('');


    const handleBlur = (force = false) => {
        if (text) return;
        setMobileMenu(false)
    }

    const handleClose = () => {
        setText('')
        onSearch({ target: { value : '' } })
        setMobileMenu(false)
    }

    const openMobileMenu = () => {
        setMobileMenu(true)
    }

    const handleChange = (e) => {
        setText(e.target.value)
        onSearch(e)
    }

    return (
        <>
            <Button onClick={openMobileMenu} className={classes.mobileSearchBtn}>
                <SearchIcon />
            </Button>
            {mobileMenu && <TextField
                value={text}
                id="program-page-search-field"
                variant="outlined"
                className={classes.mobileSearchField}
                placeholder="Program Name, Target Name..."
                onChange={handleChange}
                autoFocus
                autoComplete='off'
                onBlur={handleBlur}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end" onClick={handleClose}>
                            <CloseIcon />
                        </InputAdornment>
                    ),
                }}
            />}
        </>
    )
}

export default MobileSearchInput