import Axios from 'axios';
import { ZENDESK_TOKEN, ZENDESK_URL } from '../config/config';

export const createTicket = async (data) => {
    const { name, email, message, files } = data;
    const token = files?.length > 0 && await uploadAttachments(files); 
    const comment = token ? { body: message, uploads: [ token ] } : { body: message };
    const requester = name || 'Anonymous';

    return Axios.post(`${ZENDESK_URL}/requests.json`, {
        request: {
            requester: { name: requester, email }, 
            subject: `Message from ${requester}`,
            comment
        }
    });
}

const uploadAttachments = (files) => {
    return new Promise((resolve, reject) => {
        const file = files.shift();

        sendUploadRequest(file)
        .then(res => res.json())
        .then(res => res?.upload?.token) // get the token from the first upload and use it to upload the remaining attachments
        .then(token => {
            const uploadPromises = [];
            files.map(file => uploadPromises.push(sendUploadRequest(file, token)));
            Promise.all(uploadPromises).then(() => resolve(token));
        })
        .catch((err) => reject(err));
    })
}

const sendUploadRequest = (file, token = null) => {
    const url = token ? `${ZENDESK_URL}/uploads?filename=${file.name}&token=${token}` : `${ZENDESK_URL}/uploads?filename=${file.name}`;
    return fetch(url, { // files uploaded with Axios are corrupted
        method: 'POST',
        body: file,
        headers: { 
            Authorization: `Bearer ${ZENDESK_TOKEN}`,
            'content-type': 'application/binary',
        }
    })
}