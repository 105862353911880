import {
    SET_DOMAINS, SET_PROGRAMS, SET_TARGETS, SET_TARGET_DATA, SET_ALL, SET_MEMOS,
} from './action-types';

const initialState = {
    domains: [],
    filteredDomains: [],
    programs: [],
    targets: [],
    targetData: [],
    memos: [],
    unreadMemos: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_ALL:
            return {
                ...state,
                domains: action.payload.domains,
                programs: action.payload.programs,
            }

        case SET_DOMAINS:
            return {
                ...state,
                domains: action.payload,
            }

        case SET_PROGRAMS:
            return {
                ...state,
                programs: action.payload,
            }

        case SET_TARGETS:
            return {
                ...state,
                targets: action.payload,
            }

        case SET_TARGET_DATA:
            return {
                ...state,
                targetData: action.payload,
            }


        case SET_MEMOS:
            return {
                ...state,
                memos: action.payload.memos,
                unreadMemos: action.payload.unreadMemos,
            }

        default:
            return state
    }
}