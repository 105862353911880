import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    DialogActions, Grid, IconButton, InputLabel, Select, FormHelperText, MenuItem
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import LibraryTreeView from './LibraryTreeView';
import { getDomainsProgramsByPatientId, postProgram } from '../../services/ProgramService';
import { setLoading, setSnackbar } from '../../store/general/actions';
import { postTarget } from '../../services/TargetService';
import { setPatient } from '../../store/patient/actions';
import { getLibrary } from '../../services/LibraryService';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: '800px',
        width: '800px',
        '@media (max-width: 768px)': {
            minWidth: 'calc(100vw - 30px)',
        }
    },
    dialogContent: {
        '@media (max-width: 768px)': {
            padding: '20px'
        }
    },
    dialogTitle: {
        '@media (max-width: 768px)': {
            padding: '15px 20px'
        }
    },
    dialogActions: {
        '@media (max-width: 768px)': {
            padding: '10px 20px'
        }
    },
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    icon: {
        margin: "0 10px",
    },
    treeView: {
        margin: '5px 0'
    }
}));

const LibraryImport = (props) => {
    const classes = useStyles();
    const { open, onClose, programLibraries, fromClinical,
        patients, loading, setLoading, setSnackbar, setPatient, clinicalPatient, selection } = props;

    const [progLibraries, setProgLibraries] = useState([]);
    const [selectedProgramLibraries, setSelectedProgramLibraries] = useState([]);
    const [selectedTargetLibraries, setSelectedTargetLibraries] = useState({});
    const [domains, setDomains] = useState([]);
    const [domainId, setDomainId] = useState(0);
    const [patientId, setPatientId] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (open) {
            if (fromClinical) {
                setLoading('importLibraryGetLibraries');
                getLibrary('all').then((res) => {
                    setProgLibraries(res.data.data);
                    setLoading('importLibraryGetLibraries', false);
                })
                setPatientId(clinicalPatient.id);
            } else {
                setProgLibraries(programLibraries);
            }
        } else {
            setPatientId(0);
            setProgLibraries([]);
        }
    }, [fromClinical, programLibraries, open])

    useEffect(() => {
        if (patientId) {
            setDomainId(0);
            setLoading('importLibraryGetDomain');
            getDomainsProgramsByPatientId(patientId).then(res => {
                setDomains(res.domains);
                setLoading('importLibraryGetDomain', false);

                if (fromClinical && !selection.all) {
                    setDomainId(selection.domain)
                }
            })
        }
    }, [patientId])

    const setSelected = (programLibs, targetLibs) => {
        setSelectedProgramLibraries(programLibs);
        setSelectedTargetLibraries(targetLibs);
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = async () => {
        if (!patientId || !domainId) {
            return setError(true);
        }

        const patient = patients.find(e => e.id === patientId)
        const branchId = patient.branch.id;

        setLoading('importLibrary')
        Promise.all(
            selectedProgramLibraries.map(e => postProgram({
                name: e.program.name,
                instructions: e.program.instructions,
                branchId,
                domainId,
                isLibrary: true,
            }))
        ).then((values) => {
            const promises = [];
            values.forEach((res, index) => {
                const programId = res.data.data.programId;
                const programLibId = selectedProgramLibraries[index].id;

                selectedTargetLibraries[programLibId].forEach(targetLib => {
                    promises.push(postTarget({
                        ...targetLib.target,
                        branchId,
                        programId,
                    }))
                })
            })

            Promise.all(promises).then(values => {
                setLoading('importLibrary', false);
                setSnackbar('success', 'Imported programs and targets to client!');
                
                // hack webapp to reload sidebars, etc
                if (patient.id === clinicalPatient.id) {
                    setPatient({ ...patient })
                }

                onClose();
            })
        })
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            classes={{
                paper: classes.dialogPaper
            }}
            open={open}
        >
            <DialogTitle className={classes.dialogTitle}>
                <Grid container>
                    <Grid id="library-page-import-modal-title" item xs={8}>
                        Import from Library
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="library-page-import-modal-close" className={classes.closeIconBtn} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Grid container>
                    <Grid item xs={6} style={{ paddingRight: 10 }}>
                        <InputLabel id="library-page-import-modal-client-field-label" required>Client</InputLabel>
                        <Select
                            id="library-page-import-modal-client-field"
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            fullWidth
                            value={patientId}
                            error={error && !patientId}
                            onChange={(e) => setPatientId(e.target.value)}
                        >
                            <MenuItem id="library-page-import-modal-client-menu-item-choose" disabled value={0}>Choose</MenuItem>
                            {patients.map(patient => (
                                <MenuItem id={`library-page-import-modal-client-menu-item-${patient.id}`} key={patient.id} value={patient.id}>
                                    {`${patient.firstName} ${patient.lastName}`}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText id="library-page-import-modal-client-field-helper-text" error={error && !patientId}>
                            {error && !patientId ? 'Please choose a client' : ' '}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={6} style={{ paddingLeft: 10 }}>
                    <InputLabel id="library-page-import-modal-domain-field-label" required>Domain</InputLabel>
                        <Select
                            id="library-page-import-modal-domain-field"
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            fullWidth
                            disabled={!patientId || loading['importLibraryGetDomain']}
                            value={domainId}
                            error={error && !domainId}
                            onChange={(e) => setDomainId(e.target.value)}
                        >
                            <MenuItem id="library-page-import-modal-domain-menu-item-choose" disabled value={0}>
                                {loading['importLibraryGetDomain'] ? 'Loading...' : 'Choose' }
                            </MenuItem>
                            {domains.map(dom => (
                                <MenuItem id={`library-page-import-modal-domain-menu-item-${dom.id}`} key={dom.id} value={dom.id}>{dom.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText id="library-page-import-modal-client-field-helper-text" error={error && !domainId}>
                            {error && !domainId ? 'Please choose a domain' : ' '}
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop: 20 }}>
                        {(open && !loading['importLibraryGetLibraries']) &&
                            <LibraryTreeView programLibraries={progLibraries} setSelected={setSelected} />}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button id="library-page-import-modal-close-button" onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button id="library-page-import-modal-import-button" disabled={!selectedProgramLibraries.length || loading['importLibrary']} autoFocus onClick={handleSubmit} color="primary">
                    Import
                </Button>
            </DialogActions>
        </Dialog>
    )
}

LibraryImport.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    programLibraries: PropTypes.array,
    fromClinical: PropTypes.bool,
}

const mapStateToProps = state => ({
    patients: state.patient.patients,
    loading: state.general.loading,
    clinicalPatient: state.patient.patient,
    selection: state.general.selection,
});

export default connect(mapStateToProps, { setLoading, setSnackbar, setPatient })(LibraryImport);
