import { useEffect, useState } from 'react'

import { getLibrary } from '../../services/LibraryService';

/**
 * Lazy load libraries targets by selected (expanded) programs
 * usage example:
 * 
 * const [expandedPrograms, setExpandedPrograms, programsTargetLibraries, setProgramsTargetLibraries] =
 *  useLazyLoadLibraryTargets(libraries, internalLoading, setInternalLoading);
 */

const useLazyLoadLibraryTargets = (libraries, loading, setLoading) => {
    const [expandedPrograms, setExpandedPrograms] = useState({});
    const [programsTargetLibraries, setProgramsTargetLibraries] = useState({});

    useEffect(() => {
        const fetchTargets = [];
        Object.entries(expandedPrograms).forEach(([libraryId, isExpanded]) => {
            if (isExpanded) {
                const programLib = libraries.find(e => e.id === +libraryId);
                if (programLib && !programsTargetLibraries[programLib.id]) {
                    fetchTargets.push(programLib);
                }
            }
        })

        if (!!fetchTargets.length) {
            fetchTargets.forEach(programLib => loading[programLib.id] = true);
            setLoading({ ...loading });

            Promise.all(
                fetchTargets.map(programLib => getLibrary('program', programLib.program.id))
            ).then(results => {
                fetchTargets.forEach((programLib, index) => {
                    programsTargetLibraries[programLib.id] = results[index].data.data.map(e => ({ ...e, programLibId: programLib.id, program: programLib.program }));
                })
                
                fetchTargets.forEach(programLib => loading[programLib.id] = false);
                setLoading({ ...loading });
                setProgramsTargetLibraries({ ...programsTargetLibraries });
            })
        }
    }, [expandedPrograms, libraries]) // eslint-disable-line

    return [expandedPrograms, setExpandedPrograms, programsTargetLibraries, setProgramsTargetLibraries];
}


export default useLazyLoadLibraryTargets;