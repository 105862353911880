import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import { listReminder, updateReminder, createReminder } from '../../services/ReminderService';
import { setLoading, setSnackbar } from '../../store/general/actions';
import ReminderTable from './ReminderTable';
import NewReminder from './NewReminder';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 20px',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {
        padding: '5px 0px 15px 0px',
        backgroundColor: '#FFFFFF'
    },
    popoverPaper: {
        padding: 20,
    },
    menuItem: {
        textTransform: 'capitalize',
    },
    upDownButton: {
        marginLeft: 10,
    },
}));

const debouncedGetReminders = debounce(listReminder, 500);

const Reminder = (props) => {
    const classes = useStyles();
    const { setLoading, setSnackbar, loading, patient } = props;
    const [reminders, setReminders] = useState([]);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);

    const [selectedReminder, setSelectedReminder] = useState(null);
    const [dialogOpen, setDialogOpen] = useState({
        newReminder: false,
    });

    useEffect(() => {
        if (patient?.id) {
            fetchReminder();
        }
    }, [patient])

    const fetchReminder = () => {
        setLoading('listReminder');
        debouncedGetReminders(
            skip,
            limit,
            patient.id,
        ).then((res) => {
            setReminders(res.data.data);
            setLoading('listReminder', false);
        })
    }

    const openDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: true });
    }

    const closeDialog = type => () => {
        setDialogOpen({ ...dialogOpen, [type]: false });
    }

    const handleSave = (data) => {
        if (selectedReminder) { // edit
            setLoading('editReminder');
            updateReminder({ ...data, id: selectedReminder.id }).then(() => {
                setLoading('editReminder', false);
                setSnackbar('success', 'Reminder updated!');
                fetchReminder();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('editReminder', false);
            });
        } else {
            setLoading('newReminder');
            createReminder({ ...data, patientId: patient.id, branchId: patient.branch.id }).then(() => {
                setLoading('newReminder', false);
                setSnackbar('success', 'Reminder added!');
                fetchReminder();
                handleCloseDialog();
            }).catch((err) => {
                setLoading('newReminder', false);
            });
        }
    }

    const handleCloseDialog = () => {
        setSelectedReminder(null);
        closeDialog('newReminder')();
    }

    const handleEdit = (reminder) => {
        setSelectedReminder(reminder);
        openDialog('newReminder')();
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs={6} className={classes.btnWrapper}>
                <Button
                    id="reminder-add"
                    onClick={openDialog('newReminder')}
                    variant="contained"
                >
                    New Reminder <img className={classes.plus} src={require('../../resources/PlusIcon.svg')} alt='' />
                </Button>
            </Grid>

            <Grid item xs={12}>
                <ReminderTable
                    setSkip={setSkip}
                    setLimit={setLimit}
                    fetchReminder={fetchReminder}
                    reminders={reminders}
                    onEdit={handleEdit}
                />
            </Grid>

            <NewReminder
                open={dialogOpen.newReminder}
                onClose={handleCloseDialog}
                onSave={handleSave}
                selectedReminder={selectedReminder} 
                fetchReminder={fetchReminder}
            />
            {/* Onboarding Disabled */}
            {/* <CustomOnboarding steps={reminderSteps} /> */}
        </Grid>
    )
}

Reminder.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    loading: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
    patient: state.patient.patient,
})

export default connect(mapStateToProps, { setSnackbar, setLoading })(Reminder);