import { localStorageEnum } from '../../constants';
import {
    SET_LIB_FILTER,
    SET_LIB_TABLE_FILTER,
    SET_REMINDERS,
} from './action-types';

const libFilters = localStorage.getItem(localStorageEnum.libFilters);
const libTableFilters = localStorage.getItem(localStorageEnum.libTableFilters);
let libFilter = { groupBy: 'all', resourceId: 0, resourceName: '' };
let libTableFilter = { ownedBy: 'all', domains: [] };
if (libFilters) {
    libFilter = { ...libFilter, ...JSON.parse(libFilters)};
}
if (libTableFilters) {
    libTableFilter = { ...libTableFilter, ...JSON.parse(libTableFilters)};
}

const initialState = {
    reminders: [],
    libFilter,
    libTableFilter
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_REMINDERS:
            return {
                ...state,
                reminders: action.payload,
            }
        
        case SET_LIB_FILTER:
            localStorage.setItem(localStorageEnum.libFilters, JSON.stringify(action.payload));
            return {
                ...state,
                libFilter: action.payload,
            }
             
        case SET_LIB_TABLE_FILTER:
            localStorage.setItem(localStorageEnum.libTableFilters, JSON.stringify(action.payload));
            return {
                ...state,
                libTableFilter: action.payload,
            }

        default:
            return state
    }
}