import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import Snackbar from '../../assets/snackbar/Snackbar';

const useStyles = makeStyles(theme => ({
    root: {
        height: '100vh',
        backgroundColor: '#5bd4c0',
    },
}));

const AuthLayout = (props) => {
    const classes = useStyles();

    return (
        <Grid className={classes.root} container justify="center" alignItems="center">
            {props.children}
			<Snackbar />
        </Grid>
    )
}

AuthLayout.propTypes = {
    children: PropTypes.node,
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps)(AuthLayout)
