import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    TextField, Grid, IconButton, MenuItem, InputLabel,
    Select, FormHelperText, Checkbox, FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { maintenanceSettingTypes, maintenanceSettingTypeEnum } from '../../constants';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    input: {
        width: '100%',
    },
    saveBtn: {
        padding: '6px 20px',
        marginTop: 18,
        marginBottom: 18,
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    inputLabel: {
        margin: 0,
        marginBottom: '16px',
    }
}));

const defaultMaintenanceSetting = {
    type: maintenanceSettingTypes[0],
    interval: 1,
    reappearIfUncollected: true,   
}

const MaintenanceModal = (props) => {
    const classes = useStyles();
    const { onClose, open, onSubmit, user } = props;
    const [error, setError] = useState(false);
    const [maintenanceSetting, setMaintenanceSetting] = useState({ ...defaultMaintenanceSetting })

    useEffect(() => {
        if (!open) {
            setError(false);
            setMaintenanceSetting({ ...(user.settings?.maintenanceSetting || defaultMaintenanceSetting) })
        }
    }, [open, user]);

    const handleCancel = () => {
        onClose();
    };

    
    const handleChange = (field) => (event) => {
        if (field === 'reappearIfUncollected') {
            setMaintenanceSetting({ ...maintenanceSetting, [field]: event.target.checked });
            return ;
        }
        const value = event.target.type === 'number' ? Math.floor(Number(event.target.value)) : event.target.value;
        setMaintenanceSetting({ ...maintenanceSetting, [field]: value });
    }

    const handleSubmit = () => {
        let error = false;

        if (maintenanceSetting.interval <= 0) {
            error = true;
        }

        if (error) {
            setError(true);
            return;
        }

        onSubmit(maintenanceSetting)
    }

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="lg" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        Maintenance Settings
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="maintenance-settings-modal-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <InputLabel id="maintenance-settings-modal-interval-field-label" className={classes.inputLabel}>Maintenance targets show up on the mobile every</InputLabel>
                    </Grid>
                    <Grid item container xs={8}>
                        <Grid item container xs={12}>
                            <Grid item xs={2}>
                                <TextField
                                    id="maintenance-settings-modal-interval-field"
                                    variant="outlined"
                                    type="number"
                                    value={maintenanceSetting?.interval?.toString() || "1"}
                                    onChange={handleChange('interval')}
                                    className={classes.input}
                                    InputProps={{ inputProps: { min: 1, style: { textAlign: 'right' } } }}
                                />
                            </Grid>
                            <Grid item xs={4} style={{ marginLeft: '10px' }}>
                                <Select
                                    id="maintenance-settings-modal-type-field"
                                    variant="outlined"
                                    IconComponent={KeyboardArrowDownIcon}
                                    className={classes.input}
                                    error={error && !maintenanceSetting?.type}
                                    value={maintenanceSetting?.type}
                                    onChange={handleChange('type')}
                                >
                                    {maintenanceSettingTypes.map(type => (
                                        <MenuItem key={type} value={type}>{maintenanceSettingTypeEnum[type]}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormHelperText id="maintenance-settings-modal-form-helper-text" error={error && maintenanceSetting && maintenanceSetting?.interval <= 0}>
                                {error && maintenanceSetting && maintenanceSetting?.interval <= 0 ? 'Negative value or 0 is not allowed' : ' '}
                            </FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{marginTop: '10px'}}>
                        <FormControlLabel
                            id="maintenance-settings-modal-reappear-field-label"
                            classes={{
                                label: classes.formControlLabelText
                            }}
                            control={
                                <Checkbox
                                    id="maintenance-settings-modal-reappear-field"
                                    checked={maintenanceSetting?.reappearIfUncollected}
                                    onChange={handleChange('reappearIfUncollected')}
                                    color="primary"
                                />
                            }
                            label="Make the target appear at the next session/day again if no data is collected"
                        />
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        <Button id="maintenance-settings-modal-submit" className={classes.saveBtn} onClick={handleSubmit}>Submit</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

MaintenanceModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    user: state.auth.user,
})

export default connect(mapStateToProps)(MaintenanceModal)
