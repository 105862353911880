const isCustomDataNewlyAdded = (oldDataArray, newDataArray) => {
    const oldDataIds = oldDataArray.map(e => e.id);
    return !!newDataArray.find(e => !oldDataIds.includes(e.id));
}

const isCustomDataDeleted = (oldDataArray, newDataArray) => {
    const newDataIds = newDataArray.filter(e => !e.isDeleted).map(e => e.id);
    return !!oldDataArray.find(e => !e.isDeleted && !newDataIds.includes(e.id));
}

const isCustomDataModified = (oldDataArray, newDataArray, callback = null) => {
    const newIds = newDataArray.filter(e => !e.isDeleted).map(e => e.id);
    const commonIds = oldDataArray.filter(e => !e.isDeleted && newIds.includes(e.id)).map(e => e.id);
    const newCommonData = newDataArray.filter(e => commonIds.includes(e.id));
    const oldCommonData = oldDataArray.filter(e => commonIds.includes(e.id));
    if (callback) {
        return JSON.stringify(newCommonData.map(callback)) !== JSON.stringify(oldCommonData.map(callback));
    }
    return JSON.stringify(newCommonData) !== JSON.stringify(oldCommonData);
}

const isNestedCustomDataChanged = (oldDataArray, newDataArray, callbackToCompare, callbackToMap) => {
    const newIds = newDataArray.filter(e => !e.isDeleted).map(e => e.id);
    const commonIds = oldDataArray.filter(e => !e.isDeleted && newIds.includes(e.id)).map(e => e.id);
    return !!commonIds.find(e => {
        const oldNestedData = oldDataArray.filter(k => k.id === e).map(callbackToMap)[0];
        const newNestedData = newDataArray.filter(k => k.id === e).map(callbackToMap)[0];
        return callbackToCompare(oldNestedData, newNestedData);
    });
}

export const detectTargetChanges = (oldTarget, newTarget) => {
    const changes = [];
    try {
        //common changes
        oldTarget.program.id !== newTarget.program.id && changes.push("Changed program");
        oldTarget.numberTrials !== newTarget.numberTrials && changes.push("Changed target number of trials");
        oldTarget.maximumNumberTrials !== newTarget.maximumNumberTrials && changes.push("Changed maximum number of trials");
        oldTarget.name !== newTarget.name && changes.push("Changed target name");
        oldTarget.description !== newTarget.description && changes.push("Change target description");
        oldTarget.dataType !== newTarget.dataType && changes.push("Changed data type");
        oldTarget.status !== newTarget.status && newTarget.status === 'active' && changes.push("Added target");
        oldTarget.status !== newTarget.status && newTarget.status === 'inactive' && changes.push("Removed target");
        oldTarget.phase !== newTarget.phase && changes.push("Changed phase");
        oldTarget.instructions !== newTarget.instructions && changes.push("Changed target instruction");
        
        //changes for codes
        oldTarget.dataType === newTarget.dataType && ['prompt', 'interval', 'probe'].includes(newTarget.dataType) && isCustomDataNewlyAdded(oldTarget.customData.codes, newTarget.customData.codes) && changes.push("Added new prompt code");
        oldTarget.dataType === newTarget.dataType && ['prompt', 'interval', 'probe'].includes(newTarget.dataType) && isCustomDataModified(oldTarget.customData.codes, newTarget.customData.codes, (code) => code.code) && changes.push("Changed prompt code label");
        oldTarget.dataType === newTarget.dataType && ['prompt', 'interval', 'probe'].includes(newTarget.dataType) && isCustomDataModified(oldTarget.customData.codes, newTarget.customData.codes, (code) => code.codeDesc) && changes.push("Changed prompt code label");
        oldTarget.dataType === newTarget.dataType && ['prompt', 'interval', 'probe'].includes(newTarget.dataType) && isCustomDataDeleted(oldTarget.customData.codes, newTarget.customData.codes) && changes.push("Removed prompt code");
        
        //interval specific changes
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'interval' && oldTarget.customData.duration !== newTarget.customData.duration && changes.push("Changed total duration");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'interval' && oldTarget.customData.schedule !== newTarget.customData.schedule && changes.push("Changed interval");
        
        //text specific changes
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'text' && isCustomDataNewlyAdded(oldTarget.customData, newTarget.customData) && changes.push("Added new column");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'text' && isCustomDataModified(oldTarget.customData, newTarget.customData, (data) => data.value) && changes.push("Changed column");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'text' && isCustomDataDeleted(oldTarget.customData, newTarget.customData) && changes.push("Removed column");
        
        //probe specific changes
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'probe' && isCustomDataNewlyAdded(oldTarget.customData.sets, newTarget.customData.sets) && changes.push("Added new set");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'probe' && isCustomDataModified(oldTarget.customData.sets, newTarget.customData.sets, (data) => data.value) && changes.push("Changed set label");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'probe' && isCustomDataDeleted(oldTarget.customData.sets, newTarget.customData.sets) && changes.push("Removed set");
        
        //rating specific changes
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isCustomDataNewlyAdded(oldTarget.customData, newTarget.customData) && changes.push("Added new question");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isCustomDataModified(oldTarget.customData, newTarget.customData, (data) => data.question) && changes.push("Changed rating question");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isCustomDataDeleted(oldTarget.customData, newTarget.customData) && changes.push("Removed question");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, isCustomDataNewlyAdded, (data) => data.ratings) && changes.push("Added new rating");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, (a, b) => isCustomDataModified(a, b, (rating) => rating.desc), (data) => data.ratings) && changes.push("Changed rating description");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, (a, b) => isCustomDataModified(a, b, (rating) => rating.value), (data) => data.ratings) && changes.push("Changed rating value");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'rating' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, isCustomDataDeleted, (data) => data.ratings) && changes.push("Removed rating");
        
        //category specific changes
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isCustomDataNewlyAdded(oldTarget.customData, newTarget.customData) && changes.push("Added new category");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isCustomDataModified(oldTarget.customData, newTarget.customData, (data) => data.category) && changes.push("Changed category label");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isCustomDataDeleted(oldTarget.customData, newTarget.customData) && changes.push("Removed category");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, isCustomDataNewlyAdded, (data) => data.responses) && changes.push("Added new response");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, (a, b) => isCustomDataModified(a, b, (response) => response.label), (data) => data.responses) && changes.push("Changed response label");
        oldTarget.dataType === newTarget.dataType && newTarget.dataType === 'category' && isNestedCustomDataChanged(oldTarget.customData, newTarget.customData, isCustomDataDeleted, (data) => data.responses) && changes.push("Removed response");
    } catch (e) {
        //prevent app crashing from error
    } finally {
        return changes;
    }
    
}