import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Clear';
import cloneDeep from 'lodash.clonedeep';

import LoadingCheck from '../../../assets/progress/LoadingCheck';
import { deleteTargetData, updateTargetData } from '../../../services/TargetService';
import { setGlobalDialog, setSnackbar } from '../../../store/general/actions';
import store from '../../../store/store';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        height: '100%',
        padding: 0,
    },
    component: {
        padding: 2,
    },
    editButton: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        borderRadius: 0,
        opacity: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: 0,
        transition: 'opacity 0.5s',
        minWidth: 0,
        '&:hover': {
            opacity: 1,
            backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
    },
    editButtonLabel: {
        backgroundColor: '#0FB99E',
        padding: '2px 6px',
    },
    loading: {
        position: 'absolute',
        top: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        transition: 'opacity 0.5s',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loadingVisible: {
        opacity: 1,
    },
    actionButton: {
        padding: 6,
        minWidth: 30,
        width: 30,
        borderRadius: 0,
        marginLeft: 6,
    },
    redButton: {
        backgroundColor: '#d32f2f',
        '&:hover': { backgroundColor: '#b02e2e' },
    },
    editingItems: {
        padding: 2,
    },
}));

const withDataTableHoc = (
    WrappedComponent,
    tableType,
    createCustomData,
    addValidation = undefined,
    addLabel = 'Add to right'
) => {
    const TableCellWrapper = (props) => {
        const classes = useStyles();
        const [isEdit, setIsEdit] = useState(false);
        const [tableData, setTableData] = useState(null);
        const [editingData, setEditingData] = useState(null);
        const [anchorEl, setAnchorEl] = useState(null);
        const [selectedItem, setSelectedItem] = useState({ index: 0, index2: 0 });
        const [loading, setLoading] = useState(false);
        const [errors, setErrors] = useState(null);

        useEffect(() => {
            if(!loading) return;

            if (!props.isSilentLoading) {
                setLoading(false);
            }
        }, [props]);

        useEffect(() => {
            setTableData(props.tableData);
        }, [props.tableData]);

        useEffect(() => {
            if (isEdit) {
                setEditingData(tableData.map((e) => cloneDeep(e)));
            }
        }, [isEdit]);

        const splitArrayPerRows = (array, DATA_PER_ROW) => {
            const arrayCopy = Array.from(array);
            const newArray = [];
            for (let index = 0; index < arrayCopy.length; index += DATA_PER_ROW) {
                const newRow = arrayCopy.slice(index, index + DATA_PER_ROW);
                newArray.push(newRow);
            }

            if (isEdit) {
                const lastRow = newArray[newArray.length - 1];
                if (!lastRow || lastRow.length === DATA_PER_ROW) {
                    newArray.push([null]);
                } else {
                    lastRow.push(null);
                }
            }

            return newArray;
        };

        const handleSubmit = () => {
            if (errors) {
                if (typeof errors === 'string') {
                    props.setSnackbar('error', errors);
                }
                return;
            }
            const deletedItems = editingData.filter((data) => data.isDelete).length;
            if (deletedItems > 0) {
                store.dispatch(
                    setGlobalDialog(
                        `Delete data?`,
                        `Deleted data cannot be restored, please proceed with caution.`,
                        (answer) => {
                            if (answer) {
                                submitData();
                            }
                        }
                    )
                );
                return;
            } else {
                return submitData();
            }
        };

        const submitData = () => {
            setLoading(true);
            Promise.all(
                editingData.map(async (data) => {
                    if (data.value.length === 0 || data.isDelete) {
                        return await deleteTargetData(data.id);
                    }

                    const customData = createCustomData(data);
                    return await updateTargetData(data.id, customData);
                })
            ).then(() => {
                props.fetchData(false);
            });
            setIsEdit(false);
        };

        const handleDelete = (index) => () => {
            const newData = editingData;
            newData[index].isDelete = !newData[index].isDelete;
            setEditingData(newData.slice());
        };

        const handleAdd = (index) => () => {
            const newData = editingData;
            if (!!addValidation) {
                const isValid = addValidation(props.customData, newData[index].value.length);
                if (!isValid) return;
            }
            newData[index].value.push(getDefaultValue());
            setEditingData(newData.slice());
        };

        const handleMoreAction = (index, index2) => (event) => {
            setAnchorEl(event.currentTarget);
            setSelectedItem({ index, index2 });
        };

        const handleNewDelete = (index, index2) => () => {
            const newData = editingData;
            newData[index].value.splice(index2, 1);
            // if (newData[selectedItem.index].value.length === 0) {
            //     newData[selectedItem.index].isDelete = true;
            //     newData[selectedItem.index].value = [""];
            // }
            setEditingData(newData.slice());
            setAnchorEl(null);
        };

        const handleAddToRight = () => {
            const newData = editingData;
            if (!!addValidation) {
                const isValid = addValidation(
                    props.customData,
                    newData[selectedItem.index].value.length
                );
                if (!isValid) return;
            }
            newData[selectedItem.index].value.splice(selectedItem.index2 + 1, 0, getDefaultValue());
            setEditingData(newData.slice());
            setAnchorEl(null);
        };

        const handleDeleteInput = () => {
            const newData = editingData;
            newData[selectedItem.index].value.splice(selectedItem.index2, 1);
            if (newData[selectedItem.index].value.length === 0) {
                newData[selectedItem.index].isDelete = true;
                newData[selectedItem.index].value = [''];
            }
            setEditingData(newData.slice());
            setAnchorEl(null);
        };

        const getDefaultValue = () => {
            let value = '';
            switch (tableType) {
                case 'correctIncorrect':
                    value = 'plus';
                    break;

                case 'prompt':
                    value = props.customData?.codes[0]?.codeDesc;
                    break;

                case 'interval':
                    value = props.customData?.codes[0]?.code;
                    break;

                case 'rating':
                    value = [];
                    // props.customData.forEach((question) => {
                    //     value.push({ answer: question.ratings[0].value, questionId: question.id });
                    // });
                    break;

                case 'category':
                    value = [];
                    break;

                case 'probe':
                    value = {};
                    props.customData.sets.forEach((set) => {
                        value[set] = '';
                    });
                    break;

                case 'duration':
                    value = 1;
                    break;

                case 'quantity':
                default:
                    value = '';
                    break;
            }

            return value;
        };

        const dataToUse = isEdit ? editingData : tableData;
        if (!tableData?.length) {
            return (
                <Grid container className={classes.root} alignContent='flex-start'>
                    <Grid item xs={12} className={classes.component}>
                        <WrappedComponent
                            customData={props.customData}
                            isEdit={isEdit}
                            dataToUse={dataToUse}
                            setEditingData={setEditingData}
                            setErrors={setErrors}
                            errors={errors}
                            handleMoreAction={handleMoreAction}
                            handleDelete={handleDelete}
                            handleAdd={handleAdd}
                            splitArrayPerRows={splitArrayPerRows}
                        />
                    </Grid>
                </Grid>
            );
        }

        return (
            <Grid className={classes.root}>
                <Grid item xs={12} className={classes.component}>
                    <WrappedComponent
                        customData={props.customData}
                        isEdit={isEdit}
                        dataToUse={dataToUse}
                        setEditingData={setEditingData}
                        setErrors={setErrors}
                        errors={errors}
                        handleMoreAction={handleMoreAction}
                        handleDelete={handleDelete}
                        handleAdd={handleAdd}
                        splitArrayPerRows={splitArrayPerRows}
                        handleNewDelete={handleNewDelete}
                    />
                </Grid>
                {isEdit && (
                    <Grid
                        item
                        xs={12}
                        container
                        justify='flex-end'
                        className={classes.editingItems}
                    >
                        <Button
                            className={`${classes.actionButton} ${classes.redButton}`}
                            onClick={() => {
                                setIsEdit(false);
                                setErrors(false);
                            }}
                        >
                            <CloseIcon />
                        </Button>
                        <Button className={classes.actionButton} onClick={handleSubmit}>
                            <DoneIcon />
                        </Button>
                    </Grid>
                )}
                {!isEdit && !loading && CanUserFunc(access.data.edit) && (
                    <Button className={classes.editButton} onClick={() => setIsEdit(true)}>
                        <span className={classes.editButtonLabel}>Edit</span>
                    </Button>
                )}
                {loading && (
                    <div className={`${classes.loading} ${loading ? classes.loadingVisible : ''}`}>
                        <LoadingCheck show={loading} size={24} />
                    </div>
                )}
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    getContentAnchorEl={null}
                >
                    <MenuItem onClick={handleAddToRight}>{addLabel}</MenuItem>
                    <MenuItem onClick={handleDeleteInput}>Delete input</MenuItem>
                </Menu>
            </Grid>
        );
    };

    TableCellWrapper.propTypes = {
        tableData: PropTypes.array,
        setSnackbar: PropTypes.func.isRequired
    };
    return connect(null, { setSnackbar })(TableCellWrapper);
};

export default withDataTableHoc;
