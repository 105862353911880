import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';

import WebIcon from '@material-ui/icons/Web';
import MobileIcon from '@material-ui/icons/PhoneIphone';
import GroupIcon from '@material-ui/icons/Group';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { deleteRole } from '../../services/RoleService';
import { permissionGroups, access } from '../../constants';
import { CanUserFunc } from '../CanUser';
import { setLoading, setSnackbar, setGlobalDialog } from '../../store/general/actions';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
    },
    container: {
        maxHeight: 'calc(100vh - 200px)',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        verticalAlign: 'middle !important',
        padding: '12px 12px',
        textAlign: 'center',
    },
    tableCell: {
        padding: '5px 12px',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
    actionColumn: {
        width: 75,
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
    circularWrapper: {
        position: 'relative',
    },
    bottom: {
        color: 'rgba(0, 0, 0, 0.1)',
    },
    top: {
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    circle: {
        strokeLinecap: 'round',
    },
    circleLabel: {
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'inline-block',
        height: 40,
        lineHeight: '40px',
        color: '#0FB99E',
        fontWeight: 'bold',
    },
    blockIcon: {
        width: 40,
        height: 40,
        color: 'rgba(0, 0, 0, 0.1)',
    },
    fullIcon: {
        width: 40,
        height: 40,
        color: '#0FB99E',
    },
    halfIcon: {
        width: 40,
        height: 40,
        color: '#0FB99E',
    },
}));

const CustomTooltipIcon = ({ title, visible, iconComponent, unmountOnHide = false }) => {
    if (!visible && unmountOnHide) {
        return null;
    }
    return (
        <Tooltip
            title={title}
            style={visible ? {} : { color: '#bdbdbd' }}
        >
            {iconComponent}
        </Tooltip>
    )
}

const RolesTable = (props) => {
    const classes = useStyles();
    const { onEdit, roles, loading, fetchRoles, setLoading, setSnackbar, setGlobalDialog, setSort, sort } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedRole, setSelectedRole] = React.useState(null);

    useEffect(() => {
        if (sort) {
            setOrder(sort.sortAs || 'asc');
            setOrderBy(sort.sortBy || '');
        }
    }, [sort])

    useEffect(() => {
        if (roles.length <= page * rowsPerPage) {
            setPage(page - 1 >= 0 ? page - 1 : 0);
        }
    }, [roles])

    const handleRequestSort = (property) => event => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort({ sortAs: isAsc ? 'desc' : 'asc', sortBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openRoleAction = (role) => (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedRole(role);
    }

    const handleEditRole = () => {
        onEdit(selectedRole);
        setAnchorEl(null);
    }

    const handleDeleteRole = () => {
        setGlobalDialog(
            `Delete role?`,
            `Deleted role cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    setLoading('deleteRole');
                    deleteRole(selectedRole.id).then(() => {
                        setLoading('deletedRole', false);
                        setSnackbar('success', 'Role has been deleted!');
                        setSelectedRole(null);
                        fetchRoles();
                    })
                }
            },
        )
        setAnchorEl(null);
    }

    const calculateAllowedActions = (actions, permissions) => {
        if (!permissions) {
            return 0;
        }
        let allowed = 0;
        actions.forEach(action => {
            if (permissions[action]) {
                allowed++;
            }
        })
        return (allowed / actions.length) * 100;
    }

    const showActions = CanUserFunc(access.roles.edit) || CanUserFunc(access.roles.remove);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tblHeadRow}>
                                <TableCell
                                    className={classes.headCell}
                                    style={{ textAlign: 'left' }}
                                    rowSpan={2}
                                    sortDirection={orderBy === 'name' ? order : false}
                                >
                                    <TableSortLabel
                                        id="role-sort-name"
                                        active={orderBy === 'name'}
                                        direction={orderBy === 'name' ? order : 'asc'}
                                        onClick={handleRequestSort('name')}
                                    >
                                        Roles Name
                                        {orderBy === 'name' ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell className={classes.headCell} rowSpan={2}>Access</TableCell>
                                <TableCell className={classes.headCell} colSpan={permissionGroups.length}>Permission</TableCell>
                                {showActions &&
                                    <TableCell className={`${classes.headCell} ${classes.actionColumn}`} rowSpan={2}>Action</TableCell>
                                }
                            </TableRow>
                            <TableRow className={classes.tblHeadRow}>
                                {permissionGroups.map(permission => (
                                    <TableCell key={permission.name} className={classes.headCell}>{permission.label}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {loading.getRoles ?
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={showActions ? 11 : 10}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {roles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={row.id}
                                        >
                                            <TableCell className={classes.tableCell} component="th" scope="row" style={{ textAlign: 'left' }}>{row.name}</TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <CustomTooltipIcon
                                                    title="Web"
                                                    visible={row.hasWebAccess}
                                                    iconComponent={<WebIcon />}
                                                />
                                                <CustomTooltipIcon
                                                    title="Mobile"
                                                    visible={row.hasMobileAccess}
                                                    iconComponent={<MobileIcon />}
                                                />
                                                <CustomTooltipIcon
                                                    title="View Assigned Patients Only"
                                                    visible={row.viewOnlyAssignedPatients}
                                                    iconComponent={<GroupIcon />}
                                                    unmountOnHide
                                                />
                                                <CustomTooltipIcon
                                                    title="View All Patients"
                                                    visible={!row.viewOnlyAssignedPatients}
                                                    iconComponent={<GroupAddIcon />}
                                                    unmountOnHide
                                                />
                                            </TableCell>
                                            {permissionGroups.map(perm => {
                                                const allowedActionsPercent = calculateAllowedActions(perm.actions, row.permissions[perm.name]);
                                                return (
                                                    <TableCell key={perm.name} className={classes.tableCell}>
                                                        {allowedActionsPercent === 0 ?
                                                            <BlockIcon className={classes.blockIcon} />
                                                            :
                                                            allowedActionsPercent === 100 ?
                                                                <CheckCircleRoundedIcon className={classes.fullIcon} />
                                                                :
                                                                <CheckCircleOutlineRoundedIcon className={classes.halfIcon} />
                                                        }
                                                    </TableCell>
                                                )
                                            })}
                                            {showActions &&
                                                <TableCell className={`${classes.tableCell} ${classes.actionColumn}`}>
                                                    <IconButton id={`role-list-edit-${row.id}`} onClick={openRoleAction(row)}>
                                                        <img alt="Edit" src={require("../../resources/EditIcon.svg")} />
                                                    </IconButton>
                                                    <LoadingCheck show={loading.editRole && selectedRole.id === row.id} size={24} />
                                                </TableCell>
                                            }
                                        </TableRow>
                                    )
                                    )}
                                {!roles.length &&
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell className={classes.noData} colSpan={showActions ? 11 : 10}>No roles found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 50, 100]}
                    component="div"
                    count={roles.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.roles.edit) ?
                    <MenuItem id={`role-list-action-${selectedRole && selectedRole.editable ? 'edit' : 'view'}`} onClick={handleEditRole}>
                        {selectedRole && selectedRole.editable ? 'Edit' : 'View'}
                    </MenuItem>
                    : null
                }
                {selectedRole && selectedRole.editable && CanUserFunc(access.roles.remove) ?
                    <MenuItem id="role-list-action-delete" onClick={handleDeleteRole}>Delete</MenuItem>
                    : null
                }
            </Menu>
        </div>
    );
}

RolesTable.propTypes = {
    onEdit: PropTypes.func.isRequired,
    roles: PropTypes.array.isRequired,
    loading: PropTypes.object.isRequired,
    fetchRoles: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setSnackbar, setGlobalDialog })(RolesTable);
