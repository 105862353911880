import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';

//Plugins
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//Components
import Routing from './Routing';
import store from './store/store';
import Logger from './services/error-handling/Logger'
import theme from './theme';

function App() {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Logger>
          <ThemeProvider theme={theme}>
            <Routing />
          </ThemeProvider>
        </Logger>
      </MuiPickersUtilsProvider>
    </Provider>
  );
}

export default App;
