import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, TextField, Menu, MenuItem } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import OrganizationTable from './OrganizationTable';
import EditOrganization from './EditOrganization';
import { setLoading, setSnackbar } from '../../store/general/actions'; 
import { updateOrganization, getOrganizations } from '../../services/OrganizationService';
import { localStorageEnum } from '../../constants';

const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 0 10px 40px',
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    searchWrapper: {
        padding: '5px 40px 15px 0px',
        backgroundColor: '#FFFFFF'
    },
    organizationTable: {
        maxWidth: 'none',
        paddingRight: 40
    },
    searchField: {
        width: '100%',
    },
}));

const statuses = ['All', 'Active', 'Inactive'];
const debouncedGetOrganizations = debounce(getOrganizations, 500);

const Organization = (props) => {
    const classes = useStyles();
    const { loading, setLoading, setSnackbar } = props;
    const [newOpen, setNewOpen] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const isMounted = useRef(false);

    const [sort, setSort] = useState({ sortAs: '', sortBy: '' });
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(0);
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState({ status: null });
    const [status, setStatus] = useState(statuses[1]);

    const handleSearch = event => {
        setSearch(event.target.value);
    }
    
    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = type => {
        setAnchorEl({ ...anchorEl, [type]: null });
    }
    
    const handleChangeStatus = (e) => () => {
        setStatus(e);
        closeAnchorEl('status');
    }
    
    const isFilterActive = (type) => {
        let isActive = false;
        switch (type) {
            case 'status':
                if (status !== 'All') isActive = true;
                break;
        
            default:
                break;
        }

        return isActive;
    }

    useEffect(() => {
        let organizationFilters = localStorage.getItem(localStorageEnum.organizationFilters);
        if (organizationFilters) {
            organizationFilters = JSON.parse(organizationFilters);
            setSort(organizationFilters.sort);
        }
    }, []);

    useEffect(() => {
        const organizationFilters = { sort };
        localStorage.setItem(localStorageEnum.organizationFilters, JSON.stringify(organizationFilters));
    }, [sort])

    useEffect(() => {
        isMounted.current = true;
        return () => isMounted.current = false;
    }, []);

    useEffect(() => {
        fetchOrganizations();
    }, [search, sort, skip, limit, status]);

    const fetchOrganizations = () => {
        setLoading('getOrganizations');
        debouncedGetOrganizations(
            search, 
            sort.sortBy, 
            sort.sortAs, 
            skip, 
            limit, 
            status === 'Active' ? true : status === 'Inactive' ? false : undefined
        ).then((res) => {
            if (isMounted.current) {
                setOrganizations(res.data.data);
                setLoading('getOrganizations', false);
            }
        });
    }

    const onEdit = (organization) => {
        setSelectedOrganization(organization);
        setNewOpen(true);
    }

    const handleClose = () => {
        setNewOpen(false);
        setSelectedOrganization(null);
    }

    const handleSave = (data) => {
        setLoading('editOrganization');
        updateOrganization(data).then(() => {
            setLoading('editOrganization', false);
            setSnackbar('success', 'Organization updated!');
            fetchOrganizations();
            handleClose();
        }).catch((err) => {
            setLoading('editOrganization', false);
        });
    }

    return (
        <Grid container className={classes.root}>
             <Grid item xs={3} container alignItems="center"/>
            <Grid item xs={8} className={classes.searchWrapper} id="organization-search">
                <TextField
                    autoComplete="search-field"
                    onChange={handleSearch}
                    value={search}
                    variant="outlined"
                    className={classes.searchField}
                    placeholder="Organization Name, Account Owner Name"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Grid>
            <Grid item xs={3} container alignItems="center"/>

            <Grid item xs={12} className={classes.organizationTable}>
                <OrganizationTable
                    handleEdit={onEdit}
                    organizations={organizations}
                    fetchOrganizations={fetchOrganizations}
                    setSort={setSort}
                    sort={sort}
                    openFilter={openAnchorEl}
                    isFilterActive={isFilterActive}
                />
            </Grid>

            <EditOrganization
                open={newOpen}
                onSave={handleSave}
                onClose={handleClose}
                organization={selectedOrganization}
            />

            <Menu
                id="status-menu"
                anchorEl={anchorEl.status}
                keepMounted
                open={Boolean(anchorEl.status)}
                onClose={() => closeAnchorEl('status')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {statuses.map(e => (
                    <MenuItem
                        key={e}
                        className={classes.menuItem}
                        selected={status === e}
                        onClick={handleChangeStatus(e)}
                    >
                        {e}
                    </MenuItem>
                ))}
            </Menu>
        </Grid>
    )
}

Organization.propTypes = {
    loading: PropTypes.object.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setSnackbar, setLoading, })(Organization);