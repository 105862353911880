import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  editButton: {
    borderRadius: 0,
    width: '100%',
  },
  formWrapper: {
    marginTop: '14px',
  },
}));
