import agent from './agent';

export const getLibrary = (groupBy, resourceId) => {
    return agent.get('/library/', {
        params: {
            groupBy,
            resourceId
        }
    });
}

export const getLibraryDomains = () => {
    return agent.get('/library/domains', {
        params: {
            includesEmptyTargetDomain: true
        }
    });
}

export const addTargetToLibrary = (targetId) => {
    return agent.post('/library/add-target', {
        targetId,
    })
}

export const addProgramToLibrary = (programId, targetIds) => {
    return agent.post('/library/add-program', {
        programId,
        targetIds,
    })
}

export const shareLibrary = (libraryIds) => {
    return agent.post('/library/share', {
        libraryIds,
    })
}

export const unShareLibrary = (libraryIds) => {
    return agent.post('/library/unshare', {
        libraryIds,
    })
}

export const deleteLibrary = (libraryIds) => {
    return agent.post('/library/delete', {
        libraryIds,
    })
}

export const findTargetLibrary = (groupBy, resourceId, search) => {
    return agent.get('/library/find-targets', {
        params: {
            groupBy,
            resourceId,
            search
        }
    });
}

export const reorderLibrary = (id, newPos) => {
    return agent.post('/library/reorder', { id, newPos });
}
