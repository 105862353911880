import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import { setSnackbar } from '../../../store/general/actions';
import store from '../../../store/store';
import withDataTableHoc from './DataTableHoc';
import sharedStyles from './styles';
import BasicDataLayout from './BasicDataLayout';

const useStyles = makeStyles((theme) => sharedStyles({ cellWidth: 40 }));

const createCustomData = (data) => {
    const customData = [];
    const dateNow = Math.floor(Date.now() / 1000);
    for (let index = 0; index < data.value.length; index++) {
        customData.push([[data.value[index], dateNow + index]]);
    }

    return [customData];
};

const addValidation = (customData, itemsCount) => {
    const { duration, schedule } = customData;
    let maxInterval = 0;
    if (duration && schedule) {
        maxInterval = Math.floor(duration / schedule);
    }

    if (itemsCount === maxInterval) {
        store.dispatch(setSnackbar('error', 'Max intervals reached'));
        return false;
    }
    return true;
};

const Interval = (props) => {
    const classes = useStyles({ cellWidth: 40 });
    const { dataToUse, setEditingData, customData } = props;

    const handleChange = (index, index2) => (event) => {
        const newData = dataToUse;
        newData[index].value[index2] = event.target.value;
        setEditingData(newData.slice());
    };

    const valueFormatter = (value) => {
        return customData.codes.find(e => e.id === value)?.code;
    }

    const editComponent = (value, index, index2, DATA_PER_ROW, index3, isDisabled) => (
        <TextField
            disabled={isDisabled}
            value={value}
            className={classes.textField}
            InputProps={{ classes: { input: classes.input } }}
            onChange={handleChange(index, index2 * DATA_PER_ROW + index3)}
            select
            SelectProps={{ IconComponent: () => null }}
        >
            {customData?.codes?.map((item) => (
                <MenuItem key={item.id} className={`${classes.menuItem} ${item.isDeleted ? classes.hidden : ''}`} value={item.id}>
                    {item.code}
                </MenuItem>
            ))}
        </TextField>
    );
    return <BasicDataLayout editComponent={editComponent} valueFormatter={valueFormatter} classes={classes} {...props} />;
};

Interval.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleMoreAction: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleAdd: PropTypes.func.isRequired,
    splitArrayPerRows: PropTypes.func.isRequired,
};

export default withDataTableHoc(Interval, 'interval', createCustomData, addValidation);
