import React, { useState } from 'react';
import {
    Surface, Symbols,
} from 'recharts';
import { ISCLONED } from './GraphSettings';

const CustomLegend = ({ payload, setDisabledLines }) => {
    const [disabled, setDisabled] = useState({});

    const handleClick = (value) => event => {
        const newDisabled = { ...disabled };
        newDisabled[value] = !newDisabled[value];
        setDisabled(newDisabled);
        setDisabledLines(newDisabled);
    }

    return (
        <div style={{ width: '100%', textAlign: 'center' }}>
            {payload.map((entry, index) => {
                const { value, color, payload } = entry;
                const style = {
                    marginRight: 10,
                    color: '#000',
                };
                const isCloned = value.includes(ISCLONED);
                if (isCloned) {
                    return null;
                }

                return (
                    <span
                        key={`${value}${index}`}
                        onClick={handleClick(payload?.dataId ?? value)}
                        style={style}
                    >
                        <Surface width={10} height={10} viewBox={{ x: 0, y: 0, width: 10, height: 10 }}>
                            <Symbols cx={5} cy={5} type="circle" size={50} fill={color} />
                            {disabled[payload?.dataId ?? value] && (
                                <Symbols
                                    cx={5}
                                    cy={5}
                                    type="circle"
                                    size={25}
                                    fill={"#FFF"}
                                />
                            )}
                        </Surface>
                        <span>{value}</span>
                    </span>
                );
            })}
        </div>
    );
};

export default CustomLegend;