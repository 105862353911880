import subMinutes from 'date-fns/subMinutes';
/**
 * 
 * @param date: Date to be converted to days
 * Convert Date to number of days from 1970, timezone included
*/
const dateToDays = (date) => {
    // firstly set date to 00:00 as we only care about the date itself
    // add timezone to date, so when date is converted to UTC, it will be on 00:00
    // e.g. 02/01 00:00 +7 is 01/01 17:00 in UTC
    // with this, when converted to UTC, it will be 02/01 00:00
    // when we show this to user, we will convert it back to user's UTC offset
    // this is just for our internal calculation, the dates itself won't change

    const calculatedDate = subMinutes(new Date(date).setHours(0, 0, 0, 0), new Date().getTimezoneOffset());

    // parse divided with how many miliseconds a day is
    // this should always return natural number (without decimal)
    return Date.parse(calculatedDate) / 86400000;
}

export default dateToDays;