import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
	ResponsiveContainer, LineChart, Line,LabelList, Tooltip
} from 'recharts';
import clonedeep from 'lodash.clonedeep';

import {
	dateAxisSetting, defaultAxisSetting, getColor, graphHeight, defaultLineSetting,
	defaultLineChartSetting, toDateFull, renderCustomizedValue, defaultTooltipSetting,
	getMinMaxDate, generateDomainTicks, splitGraphDataByPhases, getTargetColor, generateTargetName
} from './GraphSettings';
import dateToDays from '../../../utils/dateToDays';
import CustomTooltip from './CustomTooltip';
import DefaultGraphItems, { xAxis, yAxis } from './DefaultGraphItems';
import useAnimationTimeout from './useAnimationTimeout';

// IMPORTANT
// Please read the comment on Analysis.js beforehand

const FrequencyGraph = (props) => {
	const [hoveredDate, setHoveredDate] = useState(0);
	const [enableAnimation, enableTooltip] = useAnimationTimeout();
	const [disabledLines, setDisabledLines] = useState({});
	const countTotalData = props.graphData.length;
	const height = graphHeight + Math.floor(countTotalData / 7) * 25;
	const valueToUse = props.useCumulative ? 'cumulative' : 'value';

	const formattedPayload = useMemo(() => {
		const payload = [];
		props.graphData.forEach((target, index) => {
			if (disabledLines[target.name]) return;

			target.targetData
				.filter(e => dateToDays(e.date) === hoveredDate)
				.forEach((targetData) => {
					payload.push({
						date: hoveredDate,
						color: getColor(index, 'light'),
						name: target.name,
						value: targetData[valueToUse],
					});
				})
		})
		return payload;
	}, [hoveredDate, props.graphData])

	const { graphDomain, graphTicks } = useMemo(() => {
		let { graphDomain, graphTicks } = props;
		if (!graphDomain.length || !graphTicks.length) {
			const { minDate, maxDate } = getMinMaxDate(props.graphData);
			const { domain, ticks } = generateDomainTicks(new Date(minDate), new Date(maxDate));
			graphDomain = domain;
			graphTicks = ticks;
		}
		return { graphDomain, graphTicks };
	}, [props.graphDomain, props.graphTicks, props.graphData]);

	const formattedGraphData = useMemo(() => {
		const formattedData = [];
		const dateValueMap = {};
		let maxValue = 9;

		props.graphData.forEach(target => {
			const newTarget = clonedeep(target);
			newTarget.targetData.length > 0 && newTarget.targetData.forEach(targetData => {
				const date = dateToDays(targetData.date);
				const value = targetData[valueToUse];
				if (value > maxValue) {
					maxValue = value;
				}

				const dateValue = `${date}-${value}`;
				dateValueMap[dateValue] = (dateValueMap[dateValue] ?? -1) + 1;

				targetData.value = {
					value,
					sameDot: dateValueMap[dateValue]
				}
			})
			formattedData.push(newTarget);
		})

		const dotMultiplier = (maxValue / 10) * 0.05; // max value / y ticks / 1/20
		formattedData.forEach(target => {
			target.targetData.length > 0 && target.targetData.forEach(targetData => {
				const valueObj = targetData.value;
				targetData.value = valueObj.value + (valueObj.sameDot * dotMultiplier);
			})
		})

		return formattedData;
	}, [props.graphData, props.useCumulative])

	const graphData = useMemo(() => {
		if (!props.showPhase) {
			return formattedGraphData;
		} else {
			return splitGraphDataByPhases(formattedGraphData);
		}
	}, [formattedGraphData, props.showPhase])

	return (
		<ResponsiveContainer height={height}>
			<LineChart {...defaultLineChartSetting}>
				{DefaultGraphItems({ ...props, setDisabledLines, graphTicks }).map((e) => e)}
				{xAxis('date', dateAxisSetting, graphDomain, graphTicks, 'Date', !!props.graphElements.axisLabelsX)}
				{yAxis('value', defaultAxisSetting, 'Count', !!props.graphElements.axisLabelsY)}
				{(enableTooltip && !props.isDownloading) &&
					<Tooltip
						{...defaultTooltipSetting}
						content={
							<CustomTooltip
								labelFormatter={toDateFull}
								formattedPayload={formattedPayload}
								setHoveredDate={setHoveredDate}
							/>
						}
					/>
				}
				{graphData.map((target, index) => (
					<Line
						{...defaultLineSetting}
						stroke={getTargetColor(target, index, 'light')}
						dataKey="value"
						name={generateTargetName(target, index)}
						key={generateTargetName(target, index)}
						isAnimationActive={enableAnimation}
						data={!disabledLines[target.name] ?
							target.targetData.map(e => (
								{ date: dateToDays(e.date), value: e.value }
							)) : []
						}
					>
						{props.graphElements.lineTitles && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, -15, () => target.name)} />}
						{props.graphElements.dataValues && <LabelList dataKey="value" content={(props) => renderCustomizedValue(props, 0, 15, (value) => value ? Math.round(value) : '')} />}
					</Line>
				))}
			</LineChart>
		</ResponsiveContainer>
	)
}

FrequencyGraph.propTypes = {
	graphData: PropTypes.array.isRequired,
	graphElements: PropTypes.object.isRequired,
	graphDomain: PropTypes.array.isRequired,
	graphTicks: PropTypes.array.isRequired,
	showPhase: PropTypes.bool.isRequired,
	isDownloading: PropTypes.bool.isRequired,
	useCumulative: PropTypes.bool,
};

export default FrequencyGraph;
