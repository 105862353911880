import agent from './agent';

export const createDataSheet = (data) => {
    return agent.post('/data-sheet/create', data);
}

export const updateDataSheet = (data) => {
    return agent.post('/data-sheet/update', data);
}

export const getDataSheet = (dataSheetId) => {
    return agent.post('/data-sheet/get', { dataSheetId });
}

export const deleteDataSheet = (dataSheetId) => {
    return agent.post('/data-sheet/delete', { dataSheetId });
}

export const listDataSheet = (patientId) => {
    return agent.post('/data-sheet/list', { patientId });
}

export const addTargetToDataSheet = (dataSheetId, targetIds) => {
    return agent.post('/data-sheet/bulk-add-target', { dataSheetId, targetIds });
}

export const removeTargetFromDataSheet = (dataSheetId, targetIds) => {
    return agent.post('/data-sheet/bulk-remove-target', { dataSheetId, targetIds });
}

export const printDataSheet = (id) => {
    return agent.get('/data-sheet/print', { params: { id } });
}