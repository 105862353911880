import agent from './agent';

export const listReminder = (skip, limit, patientId) => {
    return agent.get('/reminder/list', {
        params: {
            skip,
            limit,
            patientId,
            projection: 'id,title,message,repeat,patientId,branchId,schedule',
        }
    });
};

export const getReminder = () => {
    return agent.get('/reminder', {
        params: {
            id: 1,
            projection: 'id,title,message,repeat,repeat_period,patientId,branchId'
        }
    });
};

export const createReminder = (data) => {
    return agent.post('/reminder/create', data);
};

export const updateReminder = (data) => {
    return agent.post('/reminder/update', data);
};

export const deleteReminder = (id) => {
    return agent.delete('/reminder/delete', {
        params: {id}
    });
};