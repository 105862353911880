import {
    SET_SELECTION, SET_SNACKBAR, SET_LOADING, SET_TARGET_LOADING, SET_GLOBAL_DIALOG, SET_SIDEBAR_WIDTH,
    CLOSE_GLOBAL_DIALOG, CLOSE_UPLOAD_DIALOG, SET_UPLOAD_DIALOG, SET_PATIENT_SELECTION_MAP, CLEAR_LOADING,
    SET_LIBRARY_LOADING, SET_FINISHED_ONBOARDINGS, CLOSE_ZENDESK_DIALOG, SET_ZENDESK_DIALOG, SET_IS_MOBILE,
} from './action-types';
import { localStorageEnum } from '../../constants';


const selectionFilters = localStorage.getItem(localStorageEnum.selectionFilters);
let selection = { all: true, status: 'all', domain: 'all', program: 'all', patient: null, showArchived: false, star: 'all' };
if (selectionFilters) {
    selection = { ...selection, ...JSON.parse(selectionFilters)};
}

const patientSelectionMapStorage = localStorage.getItem(localStorageEnum.patientSelectionMap);
let patientSelectionMap = {};
if (patientSelectionMapStorage) {
    patientSelectionMap = JSON.parse(patientSelectionMapStorage);
}

const initialState = {
    snackbar: { open: false, variant: 'info', message: '' }, // must have variant, otherwise error
    loading: { getDomains: true },
    targetLoading: false,
    libraryLoading: false,
    selection,
    globalDialog: { open: false, title: '', message: '', handleAnswer: undefined, content: undefined, showActions: true },
    uploadDialog: { open: false, title: '', message: '', handleUpload: undefined, templateLink: undefined, templateName: true, acceptedTypes: [], uploadCallback: undefined },
    zendeskDialog: { open: false, type: '' },
    patientSelectionMap,
    finishedOnboardings: { firstLoad: true }, // disable onboarding on firstLoad
    sidebarWidth: 0,
    isMobile: false,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_LOADING:
            return {
                ...state,
                loading: { ...state.loading, [action.payload.name]: action.payload.isLoading },
            }
        
        case CLEAR_LOADING:
            return {
                ...state,
                loading: {}
            }

        case SET_SELECTION:
            localStorage.setItem(localStorageEnum.selectionFilters, JSON.stringify(action.payload));
            return {
                ...state,
                selection: action.payload,
            }

        case SET_PATIENT_SELECTION_MAP:
            localStorage.setItem(localStorageEnum.patientSelectionMap, JSON.stringify(action.payload));
            return {
                ...state,
                patientSelectionMap: action.payload,
            }

        case SET_SNACKBAR:
            return {
                ...state,
                snackbar: action.payload,
            }

        case SET_TARGET_LOADING:
            return {
                ...state,
                targetLoading: action.payload,
            }

        case SET_LIBRARY_LOADING:
            return {
                ...state,
                libraryLoading: action.payload,
            }

        case SET_GLOBAL_DIALOG:
            return {
                ...state,
                globalDialog: action.payload,
            }
        
        case CLOSE_GLOBAL_DIALOG:
            return {
                ...state,
                globalDialog: { ...state.globalDialog, open: false },
            }

        case SET_ZENDESK_DIALOG:
            return {
                ...state,
                zendeskDialog: action.payload,
            }
        
        case CLOSE_ZENDESK_DIALOG:
            return {
                ...state,
                zendeskDialog: { ...state.zendeskDialog, open: false },
            }

        case SET_UPLOAD_DIALOG:
            return {
                ...state,
                uploadDialog: action.payload,
            }
        
        case CLOSE_UPLOAD_DIALOG:
            return {
                ...state,
                uploadDialog: { ...state.uploadDialog, open: false, templateLink: undefined, templateName: true, acceptedTypes: [] },
            }

        case SET_FINISHED_ONBOARDINGS:
            return {
                ...state,
                finishedOnboardings: action.payload
            }

        case SET_SIDEBAR_WIDTH:
            return {
                ...state,
                sidebarWidth: action.payload
            }
        case SET_IS_MOBILE:
            return {
                ...state,
                isMobile: action.payload
            }

        default:
            return state
    }
}
