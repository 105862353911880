export const START_SESSION = 'START_SESSION';
export const PAUSE_SESSION = 'PAUSE_SESSION';
export const RESUME_SESSION = 'RESUME_SESSION';
export const END_SESSION = 'END_SESSION';
export const ADD_SESSION_DATA = 'ADD_SESSION_DATA';
export const DELETE_SESSION_DATA = 'DELETE_SESSION_DATA';
export const DELETE_ALL_SESSION_DATA = 'DELETE_ALL_SESSION_DATA';
export const SET_SESSION_LOADING = 'SET_SESSION_LOADING';
export const SET_SESSION_CONFIG = 'SET_SESSION_CONFIG';
export const LOAD_SESSIONS = 'LOAD_SESSIONS';
export const SET_SESSION_TARGETS = 'SET_SESSION_TARGETS';
export const SYNC_SESSION_TARGETS = 'SYNC_SESSION_TARGETS';
export const SYNC_SESSION_DATA = 'SYNC_SESSION_DATA';
export const SET_TARGET_CHANGES = 'SET_TARGET_CHANGES';
