import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Chip, CircularProgress, InputLabel, TextField, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { access } from '../../constants';
import UserInfoPopover from './UserInfoPopover';
import { setLoading } from '../../store/general/actions';
import { getSitesDropdown } from '../../services/SiteService';
import { CanUserFunc } from '../CanUser';

const useStyles = makeStyles(theme => ({
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    multilineInputLarge: {
        minHeight: 225,
    },
    chipBox: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
    },
}));

const defaultClient = {
    firstName: '',
    lastName: '',
    clientId: '',
    email: '',
    phone: '',
    branchId: '',
    users: [],
    note: '',
};

const ClientViewer = (props) => {
    const classes = useStyles();
    const { clientData, setLoading, loading, user } = props;
    const [client, setClient] = useState(cloneDeep(defaultClient));
    const [sites, setSites] = useState([]);
    const [createdAsUser, setCreatedAsUser] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);

    useEffect(() => {
        setLoading('loadingClientViewer');
        if (clientData) {
            Promise.all([
                CanUserFunc(access.sites.view) ? getSitesDropdown() : { data: { data: user.branchNames } },
            ]).then((values) => {
                setSites(values[0].data.data);
                if (!!clientData) {
                    let client = clientData;
                    client = {
                        ...client,
                        firstName: client.firstName || '',
                        lastName: client.lastName || '',
                        clientId: client.clientId || '',
                        email: client.email || '',
                        phone: client.phone || '',
                        branchId: client.branch ? client.branch.id : '',
                        users: client.users || [],
                        note: client.note || '',
                    }
                    setClient(client);

                    // userId indicates that client has an user account
                    setCreatedAsUser(!!client.userId);

                    setLoading('loadingClientViewer', false);
                }
            })
        }

    }, [clientData])

    const openUserInfo = id => event => {
        setSelectedUserId(id)
        setAnchorEl(event.currentTarget);
    }

    return (
        <div>
            {loading.loadingClientViewer ?
                <Grid container justify="center" alignItems="center" style={{ width: 400, height: 400 }}>
                    <CircularProgress size={250} />
                </Grid>
                :
                <Grid container>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>First Name</InputLabel>
                        <TextField
                            variant="outlined"
                            value={client.firstName}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.rightWrapper}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField
                            variant="outlined"
                            value={client.lastName}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.leftWrapper}>
                        <InputLabel>Site</InputLabel>
                        <TextField
                            variant="outlined"
                            value={sites.find(site => site.id === client.branchId)?.name || 'Unknown'}
                            className={classes.input}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>

                    {createdAsUser &&
                        <React.Fragment>
                            <Grid item xs={6} className={classes.rightWrapper}>
                                <InputLabel required>Client ID</InputLabel>
                                <TextField
                                    variant="outlined"
                                    value={client.clientId}
                                    className={classes.input}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.leftWrapper}>
                                <InputLabel required>Email</InputLabel>
                                <TextField
                                    variant="outlined"
                                    value={client.email}
                                    className={classes.input}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} className={classes.rightWrapper}>
                                <InputLabel>Phone</InputLabel>
                                <TextField
                                    variant="outlined"
                                    value={client.phone}
                                    className={classes.input}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                            </Grid>
                        </React.Fragment>
                    }

                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel required>Users</InputLabel>
                        <Grid container className={classes.chipBox}>
                            {client.users.map(user => {
                                const isLinked = user.id === client.userId;
                                return (
                                    <Chip
                                        variant="outlined"
                                        size="small"
                                        label={`${user.firstName} ${user.lastName}${isLinked ? ' (Linked)' : ''}`}
                                        key={user.id}
                                        onClick={openUserInfo(user.id)}
                                    />
                                )
                            }
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.largeWrapper}>
                        <InputLabel>Note</InputLabel>
                        <TextField
                            variant="outlined"
                            multiline
                            value={client.note}
                            className={classes.input}
                            InputProps={{
                                classes: {
                                    root: classes.multilineInputLarge,
                                },
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid>
            }
            <UserInfoPopover
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                userId={selectedUserId}
            />
        </div>
    )
}

ClientViewer.propTypes = {
    clientData: PropTypes.object,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading })(ClientViewer)
