import React from 'react';
export default (
	<svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M22.3622 4.38122C22.3622 3.5648 21.7003 2.90292 20.8839 2.90292H18.384V1.60308C18.384 1.27009 18.1139 1 17.7809 1H16.4784C16.1468 1 15.8746 1.27009 15.8746 1.60308V2.9036H7.57371V1.60308C7.57371 1.27009 7.30362 1 6.97063 1H5.7549C5.4219 1 5.15182 1.27009 5.15182 1.60308V2.9036H2.4783C1.66188 2.9036 1 3.56548 1 4.3819V9.64962H1.77881V21.102C1.77881 21.9423 2.45915 22.622 3.29882 22.622H19.9786C20.8189 22.622 21.4986 21.9423 21.4986 21.102V9.64894H22.3649L22.3622 4.38122ZM19.076 18.5933C19.076 19.4336 18.3963 20.1133 17.556 20.1133H5.80686C4.9672 20.1133 4.28686 19.4336 4.28686 18.5933V9.64894H19.0753V18.5933H19.076Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M8.784 11.2914H6.36279V13.8001H8.784V11.2914Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M12.9354 11.2914H10.5142V13.8001H12.9354V11.2914Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M17.086 11.2914H14.6641V13.8001H17.086V11.2914Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M8.784 15.7023H6.36279V18.2103H8.784V15.7023Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M12.9354 15.7023H10.5142V18.2103H12.9354V15.7023Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
		<path d="M17.086 15.7023H14.6641V18.2103H17.086V15.7023Z" fill="#0D3250" stroke="white" strokeWidth="0.29" />
	</svg>
);
