import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Grid, Button, Typography, Divider, Box, Grow } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setSnackbar } from '../../store/general/actions';
import PromptData, { defaultPrompt } from './PromptData';
import * as SettingService from '../../services/SettingService';
import { setUser } from '../../store/auth/actions';
import checkDuplicates from '../../utils/checkDuplicates';
import cloneDeep from 'lodash.clonedeep';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    additionalDataWrapperOuter: {
        padding: '0px 0px',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
}));

const DefaultPrompt = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, setUser } = props;

    const [defaultData, setDefaultData] = useState({});
    const [itemError, setItemError] = useState({});
    const [isUpdating, setIsUpating] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'defaultPrompt')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setDefaultData(res.data?.data?.data);
                    } else {
                        setDefaultData({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    }, []);

    const handleSave = () => {
        const newItemError = {};
        let error = false;

        if (defaultData.prompts.length > 0) {
            const acronymArray = defaultData.prompts.map((e) => e.acronym.trim());
            defaultData.prompts.forEach((prompt, index) => {
                prompt.acronym = prompt.acronym.trim();
                prompt.name = prompt.name.trim();
                if (
                    !prompt.name ||
                    !prompt.acronym ||
                    checkDuplicates(acronymArray, prompt.acronym.trim())
                ) {
                    error = true;
                    newItemError[`prompt ${index}`] = true;
                }
            });
        } else {
            defaultData.prompts = null;
        }

        if (error) {
            setItemError(newItemError);
            return;
        }

        setSettingSynced(false);

        if (newSetting) {
            SettingService.createSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'defaultPrompt',
                data: { ...defaultData },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setNewSetting(false);
                        setIsUpating(false);
                        getUserSettings();
                    }
                })
                .catch(() => {
                    setSettingSynced(true);
                });
        } else {
            SettingService.updateSetting({
                resourceType: 'user',
                resourceId: user.id,
                type: 'defaultPrompt',
                data: { ...defaultData },
            })
                .then((res) => {
                    if (res.status === 200) {
                        setIsUpating(false);
                        getUserSettings();
                    }
                })
                .catch(() => {
                    setSettingSynced(true);
                });
        }
    };

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'defaultPrompt')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setDefaultData(res.data?.data?.data);

                        const userSettings = user.settings;
                        userSettings.defaultPrompt = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Default Prompt!');
                    } else {
                        setDefaultData({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    const handleAdd = () => {
        setIsUpating(true);
        setDefaultData({ prompts: cloneDeep(defaultPrompt) });
    };

    return (
        <Grid item xs={7} container className={classes.root}>
            <Grid item xs={12}>
                <Typography id="default-prompt-setting-section-heading"  className={classes.title}>Prompt Codes</Typography>
            </Grid>
            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={200} />
                    </Box>
                </Grid>
            ) : defaultData.prompts ? (
                <>
                    <Grid item xs={12}>
                        <Grow in={true} className={classes.additionalDataWrapperOuter}>
                            <PromptData
                                prompts={defaultData.prompts}
                                setPrompts={(prompts) => {
                                    setIsUpating(true);
                                    setDefaultData({ ...defaultData, prompts });
                                }}
                                itemError={itemError}
                            />
                        </Grow>
                    </Grid>
                    <Grid item container xs={12} justify='space-between' direction='row-reverse'>
                        <Button id="default-prompt-setting-save" onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </>
            ) : (
                <Grid item container xs={12} justify='space-between' direction='row'>
                    <Button id="default-prompt-setting-add" onClick={handleAdd} variant='contained'>
                        Add
                    </Button>
                </Grid>
            )}
            <Divider className={classes.divider} />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar, setUser })(DefaultPrompt);
