import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    TextField, Grid, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    input: {
        width: '100%',
    },
    saveBtn: {
        padding: '6px 20px',
        marginTop: 18,
        marginBottom: 18,
    },
}));
const NewDomain = (props) => {
    const classes = useStyles();
    const { onClose, open, addDomain, patient, user } = props;
    const [domain, setDomain] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        setDomain('');
        setError(false);
    }, [open]);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        const domainName = domain.trim();
        if (!domainName) {
            setError(true);
            return;
        }
        let data = {
            name: domainName,
            patient: patient.id,
            branchId: patient.branch.id,
        }
        addDomain(data);
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        Create Domain
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="new-domain-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} container justify="center">
                        <TextField
                            id="new-domain-form-field-name"
                            autoFocus
                            variant="outlined"
                            label="Name"
                            className={classes.input}
                            value={domain}
                            onChange={(e) => setDomain(e.target.value)}
                            error={error && !domain.trim()}
                            helperText={error && !domain.trim() ? "Name can't be empty" : ' '}
                        />
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        <Button id="new-domain-save" className={classes.saveBtn} onClick={handleOk}>Save</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

NewDomain.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    addDomain: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    patient: state.patient.patient,
    user: state.auth.user,
})

export default connect(mapStateToProps)(NewDomain)
