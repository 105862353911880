import agent from './agent';
import { getUnixTime } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import dateFormatter from '../utils/dateFormatter';

const getNote = (targetData) => {
  if (targetData?.length) {
    const latestNote = targetData
      .slice()
      .reverse()
      .find((e) => e.data?.type === 'note');
    return latestNote ? latestNote.data?.note : '';
  }
  return '';
};

const getDataObject = (targetData, sessionData) => {
  const data = {};
  data.note = getNote(targetData);
  data.sessionDate = dateFormatter(sessionData.date, 'add');
  data.uid = sessionData.id;
  data.targetId = targetData[0].targetId;
  data.customData = { data: [] };
  return data;
};

const formatPromptData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  data.customData = { data: [] };
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((targetDatum) => {
      data.customData.data.push([
        targetDatum.data.code.id,
        targetDatum.data.id,
      ]);
    });
  if (!data.customData.data.length) {
    data.customData = {};
  }
  return data;
};

const formatRateData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => {
      if (item.data.type === 'start') {
        formattedData.push({ start: item.data.time, inputs: [] });
      } else if (item.data.type === 'add') {
        formattedData[formattedData.length - 1].inputs.push(item.data.time);
      } else if (item.data.type === 'stop') {
        formattedData[formattedData.length - 1].end = item.data.time;
      }
    });
  if (!formattedData.length) {
    data.customData = {};
  } else {
    if (!formattedData[formattedData.length - 1].end) {
      formattedData[formattedData.length - 1].end = getUnixTime(new Date());
    }
    data.customData.data = formattedData;
  }
  return data;
};

const formatIntervalData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => {
      if (item.data?.type === 'add') {
        formattedData.push([[item.data?.code?.id, item.data?.time]]);
      }
    });
  if (!formattedData.length) {
    data.customData = {};
  } else {
    data.customData.data = [formattedData];
  }
  return data;
};

const formatDurationData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => {
      if (item.data?.type === 'empty' && targetData.length === 1) {
        formattedData.push([item.data?.time, item.data?.time]);
      } else if (item.data?.type === 'start') {
        formattedData.push([item.data?.time]);
      } else if (item.data?.type === 'stop' && targetData.length > 0) {
        formattedData[formattedData.length - 1].push(item.data?.time);
      } else if (item.data?.type === 'custom') {
        formattedData.push([item.data?.start_time, item.data?.end_time]);
      }
    });
  if (!formattedData.length) {
    data.customData = {};
  } else {
    data.customData.data = formattedData;
  }
  return data;
};

const formatCorrectIncorrectData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => {
      formattedData.push([item.data.type, item.data.time]);
    });
  if (!formattedData.length) {
    data.customData = {};
  } else {
    data.customData.data = formattedData;
  }
  return data;
};

const formatFrequency = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const zeroData = targetData.filter((e) => e?.data?.type === 'zero');
  const plusData = targetData.filter((e) => e?.data?.type === 'plus');
  if (plusData.length) {
    plusData.forEach((item) => {
      data.customData.data.push([item.data.time]);
    });
  } else if (zeroData.length) {
    data.customData.data = [];
  } else {
    data.customData = {};
  }
  return data;
};

const formatProbeData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type === 'save')
    .forEach((item) => {
      formattedData.push(item.data?.data);
    });
  data.customData.data = formattedData;
  return data;
};

const formatQuantityData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = targetData
    .filter((e) => e?.data?.type !== 'note')
    .map((item) => item?.data?.quantity);
  const isAutoZero = sessionData.targets.find(e => e.id === data.targetId)?.isAutoZero;
  data.customData = (isAutoZero || formattedData?.length) ? { data: formattedData } : {};
  return data;
};

const formatTextData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) =>
      formattedData.push([
        {
          ...item.data.data,
        },
        item.data.time,
      ])
    );
  data.customData.data = formattedData;
  return data;
};

const formatRatingScaleData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => formattedData.push(item.data));
  data.customData.data = formattedData;
  return data;
};

const formatCategory = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  const formattedData = [];
  targetData
    .filter((e) => e?.data?.type !== 'note')
    .forEach((item) => formattedData.push(item.data));
  data.customData.data = formattedData;
  return data;
};

const formatImageVideoData = (targetData, sessionData) => {
  const data = getDataObject(targetData, sessionData);
  data.customData = targetData;
  return data;
};

const getFormatedSessionDataArray = (session) => {
  const dataArray = [];
  const filesArray = [];
  const targetIds = [...new Set(session.data.map((e) => e.targetId))];
  targetIds.forEach((targetId) => {
    const targetData = session.data.filter((e) => e.targetId === targetId);
    if (targetData.length) {
      const dataType = targetData[0].dataType;
      switch (dataType) {
        case 'prompt':
          dataArray.push(formatPromptData(targetData, session));
          break;
        case 'rate':
          dataArray.push(formatRateData(targetData, session));
          break;
        case 'interval':
          dataArray.push(formatIntervalData(targetData, session));
          break;
        case 'duration':
          dataArray.push(formatDurationData(targetData, session));
          break;
        case 'correctIncorrect':
          dataArray.push(formatCorrectIncorrectData(targetData, session));
          break;
        case 'frequency':
          dataArray.push(formatFrequency(targetData, session));
          break;
        case 'probe':
          dataArray.push(formatProbeData(targetData, session));
          break;
        case 'quantity':
          dataArray.push(formatQuantityData(targetData, session));
          break;
        case 'text':
          dataArray.push(formatTextData(targetData, session));
          break;
        case 'rating':
          dataArray.push(formatRatingScaleData(targetData, session));
          break;
        case 'category':
          dataArray.push(formatCategory(targetData, session));
          break;
        case 'imageVideo':
          filesArray.push(formatImageVideoData(targetData, session));
          break;
        default:
          break;
      }
    }
  });
  return [dataArray, filesArray];
};

const getFormatedZeroOutSessionDataArray = (session) => {
  const dataCollectedTargetIds = session.data.map((e) => e.targetId);
  const zeroOutDataTypes = ['frequency', 'duration', 'quantity'];
  const zeroOutTargetIds = session.targets
    .filter(
      (e) =>
        e.isAutoZero &&
        !dataCollectedTargetIds.includes(e.id) &&
        zeroOutDataTypes.includes(e.dataType)
    )
    .map((e) => e.id);
  const dataArray = [];
  zeroOutTargetIds.forEach((id) => {
    dataArray.push(getDataObject([{ targetId: id }], session));
  });
  return dataArray;
};

const submitImageVideoData = async (data, promisses) => {
  if (data.customData?.length) {
    const videoDataArray = data.customData.filter(
      (e) => e.data?.type !== 'note'
    );
    if (videoDataArray.length) {
      const videoData = videoDataArray[videoDataArray.length - 1];
      videoData.data.forEach((file, index) => {
        const formData = new FormData();
        for (let key in data) {
          if (key !== 'customData' && key !== 'uid' && key !== 'note') {
            formData.append(key, data[key]);
          }
        }
        formData.append('uid', `${data.uid}-part${index + 1}`);
        formData.append('customData', file);
        promisses.push(agent.post('targetData/submit', formData));
      });
    }
    if (data?.note) {
      const noteData = { ...data, uid: uuidv4(), customData: { data: [] } };
      promisses.push(agent.post('targetData/submit', noteData));
    }
  }
};

export const submitSessionData = async (session, endSession = false) => {
  const [sessionDataArray, filesArray] = getFormatedSessionDataArray(session);
  const zeroOutDataArray = getFormatedZeroOutSessionDataArray(session);
  const promisses = [];
  const targetIdDataIdMap = session.targetIdDataIdMap ? { ...session.targetIdDataIdMap } : {};
  const targetDataIds = [...new Set(session.data.map((e) => e.targetId.toString()))];
  const targetIdsToDelete = Object.keys(targetIdDataIdMap).filter(e => !targetDataIds.includes(e));

  sessionDataArray.forEach((data) => {
    if (!targetIdDataIdMap[data.targetId]) {
      promisses.push(agent.post('targetData/submit', { ...data, isOngoing: !endSession }).then(
        (result) => {
          if (result.data?.data?.res?.id) targetIdDataIdMap[data.targetId] = result.data?.data?.res?.id
        }
      ));
    } else {
      promisses.push(agent.put('targetData/update', 
        { ...data, id: session.targetIdDataIdMap[data.targetId], isOngoing: !endSession }
      ));
    }
  });

  zeroOutDataArray.forEach((data) => {
    if (!targetIdDataIdMap[data.targetId]) {
      promisses.push(agent.post('targetData/submit', data).then(
        (result) => {
          if (result.data?.data?.res?.id) targetIdDataIdMap[data.targetId] = result.data?.data?.res?.id
        }
      ));
    } else {
      promisses.push(agent.put('targetData/update', 
        { ...data, id: session.targetIdDataIdMap[data.targetId] }
      ));
    }
  });
  filesArray.forEach((data) => {
    submitImageVideoData(data, promisses);
  });
  targetIdsToDelete.forEach((id) => {
    promisses.push(agent.delete(`targetData/delete?id=${targetIdDataIdMap[id]}`));
    delete targetIdDataIdMap[id];
  });
  await Promise.all(promisses);
  return targetIdDataIdMap;
};

export const deleteSessionData = async (session) => {
  if (!session.targetIdDataIdMap) {
    return;
  }
  const promisses = [];
  for (const [key, value] of Object.entries(session.targetIdDataIdMap)) {
    promisses.push(agent.delete(`targetData/delete?id=${value}`).catch(e => {
      if (e?.response?.status !== 404) {
        throw e;
      }
    }));
  }
  await Promise.all(promisses);
}

export const reorderTargets = async (targets, dataSheetId, patientId) => {
  const targetOrderData = targets.map((target, index) => ({
    id: parseInt(target.id, 10),
    order: index,
    pinnedAt: null,
  }));
  targetOrderData.sort((e1, e2) => (e1.id > e2.id ? 1 : -1));
  const data = {
    targets: targetOrderData,
  };
  if (dataSheetId && dataSheetId !== -1) {
    data.dataSheetId = parseInt(dataSheetId, 10);
  } else {
    data.patientId = parseInt(patientId, 10);
  }
  agent.post('target/reorder-multiple', data);
};