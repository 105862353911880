import React, { useLayoutEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Joyride from 'react-joyride';
import { Button, Grid, makeStyles, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import { updateSetting } from '../../services/SettingService';
import { setFinishedOnboardings } from '../../store/general/actions';
import { allSteps } from '../../onboardingSteps';

const useStyles = makeStyles(theme => ({
    root: {
        padding: 10,
        maxWidth: 500,
    },
    button: {
        marginLeft: 5,
        marginRight: 5,
    },
    content: {
        whiteSpace: 'pre-line'
    },
}));


const Tooltip = (props) => {
    const { continuous, index, step, backProps, closeProps, primaryProps, tooltipProps, isLastStep, skipProps } = props;
    const classes = useStyles();

    return (
        <Paper {...tooltipProps} className={classes.root}>
            <Grid container>
                {step.title &&
                    <Grid item xs={12}>
                        <p>{step.title}</p>
                    </Grid>
                }
                <Grid item xs={12}>
                    <p className={classes.content}>{step.content}</p>
                </Grid>
                <Grid item xs={4} container>
                    <Button {...skipProps} className={classes.button}>
                        {'Skip'}
                    </Button>
                </Grid>
                <Grid item xs={8} container justify="flex-end">
                    {index > 0 &&
                        <Button {...backProps} className={classes.button}>
                            {'Back'}
                        </Button>
                    }
                    {continuous &&
                        <Button {...primaryProps} className={classes.button}>
                            {isLastStep ? 'Finish' : 'Next'}
                        </Button>
                    }
                    {!continuous &&
                        <Button {...closeProps} className={classes.button}>
                            {'Close'}
                        </Button>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

const debouncedUpdateSetting = debounce(updateSetting, 1000);
const CustomOnboarding = (props) => {
    const { user, finishedOnboardings, setFinishedOnboardings, steps, updateTrigger } = props;
	const [triggerUpdate, setTriggerUpdate] = useState(0);
    
    useLayoutEffect(() => {
        setTriggerUpdate(triggerUpdate + 1);
    }, [updateTrigger])

    const handleCallback = (data) => {
        const { action, lifecycle, step } = data;
        if (action === 'next' && lifecycle === 'complete' && step) {
            const { target } = step;
            finishedOnboardings[target] = true;
            setFinishedOnboardings({ ...finishedOnboardings });
            debouncedUpdateSetting({
                type: 'finishedOnboardings',
                resourceType: 'user',
                resourceId: user.id,
                data: finishedOnboardings
            });
        }
        if (action === 'skip' && lifecycle === 'complete') {
            const finishAllOnboardings = {};
            allSteps.forEach((step) => {
                finishAllOnboardings[`#${step.target}`] = true;
            })
            setFinishedOnboardings(finishAllOnboardings);
            debouncedUpdateSetting({
                type: 'finishedOnboardings',
                resourceType: 'user',
                resourceId: user.id,
                data: finishAllOnboardings
            });
        }
    }

    const generatedSteps = useMemo(() => {
        if (finishedOnboardings.firstLoad) {
            return [];
        }
        if (!steps) {
            return [];
        }

        const generatedSteps = [];
        steps.forEach(step => {
            const target = '#' + step.target;
            if (!finishedOnboardings[target]) {
                const component = document.getElementById(step.target);
                if (component) {
                    generatedSteps.push({
                        ...step,
                        target
                    })
                }
            }
        })

        return generatedSteps;
    }, [steps, triggerUpdate, finishedOnboardings])

    return (
        <Joyride
            {...props}
            continuous
            steps={generatedSteps}
            tooltipComponent={Tooltip}
            callback={handleCallback}
            styles={{
                options: {
                    primaryColor: '#0FB99E',
                    zIndex: 99999,
                }
            }}
        />
    )
}

CustomOnboarding.propTypes = {
    updateTrigger: PropTypes.any, // to trigger steps formatting on component updates
    steps: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    finishedOnboardings: state.general.finishedOnboardings,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setFinishedOnboardings })(CustomOnboarding)