import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    DialogActions, Grid, IconButton, FormControlLabel, Checkbox
} from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { getFilteredTargets } from '../../services/TargetService';

const useStyles = makeStyles(theme => ({
    dialogPaper: {
        minWidth: '500px',
        width: '500px'
    },
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    icon: {
        margin: "0 10px",
    },
    treeView: {
        margin: '5px 0'
    }
}));
  
const ProgramSelectTarget = (props) => {
    const classes = useStyles();
    const { open, onClose, onSubmit, program, patient } = props;

    const [targets, setTargets] = useState([]);
    const [selectedIds, setSelectedIds] = useState({})
    const [isLoading, setIsLoading] = useState(false);

    // default target to checked
    useEffect(() => {
        if (open && program && patient) {
            const filters = {
                all: false,
                domain: program.domainId,
                program: program.id,
                patient: patient.id,
                star: 'all',
                status: 'all',
            }
            setIsLoading(true);
            getFilteredTargets(filters, true).then(res => {
                setIsLoading(false);
                setTargets(res.data.data);
                const selectedIds = {};
                res.data.data.forEach(target => {
                    selectedIds[target.id] = true;
                })
                setSelectedIds({ ...selectedIds })
            })
        }
    }, [program, patient, open])

    useEffect(() => {
        if (!open) {
            setTargets([]);
            setSelectedIds({});
        }
    }, [open])

    const handleSelect = (libId) => () => {
        selectedIds[libId] = !selectedIds[libId]
        setSelectedIds({ ...selectedIds });
    }

    const handleClose = () => {
        onClose();
    }

    const handleSubmit = () => {
        onSubmit(Object.keys(selectedIds).filter(e => !!selectedIds[e]))
    }

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
			classes={{
				paper: classes.dialogPaper
			}}
            open={open}
        >
            <DialogTitle>
                <Grid container>
                    <Grid id="program-select-target-form-title" item xs={8}>
                        Select targets to add to Library
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="program-select-target-close" className={classes.closeIconBtn} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <TreeView expanded={['program']}>
                    <TreeItem
                        id="program-select-target-program-name"
                        nodeId="program"
                        label={program?.name}
                    >
                        {targets.map(target => (
                            <TreeItem
                                nodeId={`${target.id}`}
                                key={target.id}
                                label={
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id={`program-select-target-selection-${target.id}`}
                                                color="primary"
                                                checked={!!selectedIds[target.id]}
                                                onChange={handleSelect(target.id)}
                                            />
                                        }
                                        label={target.name}
                                    />
                                }
                            />
                        ))}
                        {isLoading &&
                            <TreeItem
                                nodeId="loading"
                                label="Loading..."
                            />
                        }
                        {(!isLoading && !targets.length) &&
                            <TreeItem
                                id="program-select-target-no-target-message"
                                nodeId="no-targets"
                                label="No Targets"
                            />
                        }
                    </TreeItem>
                </TreeView>
            </DialogContent>
            <DialogActions>
                <Button id="program-select-target-form-close" onClick={handleClose} color="primary">
                    Close
                </Button>
                <Button id="program-select-target-form-add" autoFocus onClick={handleSubmit} color="primary">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    )
}

ProgramSelectTarget.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    program: PropTypes.object,
}

export default ProgramSelectTarget;
