import React from 'react';
import PropTypes from 'prop-types';
import Prompt from './Widgets/Prompt';
import Rate from './Widgets/Rate';
import CorrectIncorrect from './Widgets/CorrectIncorrect';
import Interval from './Widgets/Interval';
import Duration from './Widgets/Duration';
import Probe from './Widgets/Probe';
import Frequency from './Widgets/Frequency';
import Quantity from './Widgets/Quantity';
import Text from './Widgets/Text';
import DividerWrapper from './DividerWraper';
import RatingScale from './Widgets/RatingScale';
import Category from './Widgets/Category';
import Image from './Widgets/Image';

const DataCollectionWidget = (props) => {
  const sessionData = props.session.data.filter(
    (e) => e.targetId === props.target.id && e.data?.type !== 'note'
  );
  const sessionNotes = props.session.data.filter(
    (e) => e.targetId === props.target.id && e.data?.type === 'note'
  );
  return (
    <>
      {props.target.dataType === 'correctIncorrect' && (
        <DividerWrapper>
          <CorrectIncorrect
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'frequency' && (
        <DividerWrapper>
          <Frequency
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'prompt' && (
        <DividerWrapper>
          <Prompt
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'duration' && (
        <DividerWrapper>
          <Duration
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'interval' && (
        <DividerWrapper>
          <Interval
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'text' && (
        <DividerWrapper>
          <Text
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'probe' && (
        <DividerWrapper>
          <Probe
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            onDeleteSessionData={props.onDeleteSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'rating' && (
        <DividerWrapper>
          <RatingScale
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'rate' && (
        <DividerWrapper>
          <Rate
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'imageVideo' && (
        <DividerWrapper>
          <Image
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'quantity' && (
        <DividerWrapper>
          <Quantity
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
      {props.target.dataType === 'category' && (
        <DividerWrapper>
          <Category
            sessionData={sessionData}
            sessionNotes={sessionNotes}
            onAddSessionData={props.onAddSessionData}
            sessionId={props.session.id}
            target={props.target}
          />
        </DividerWrapper>
      )}
    </>
  );
};

DataCollectionWidget.propTypes = {
  onAddSessionData: PropTypes.func.isRequired,
  onDeleteSessionData: PropTypes.func.isRequired,
  session: PropTypes.object.isRequired,
  target: PropTypes.object.isRequired,
};

export default DataCollectionWidget;
