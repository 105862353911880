import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Menu, MenuItem, IconButton } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { connect } from 'react-redux';

import { setZendeskDialog } from '../store/general/actions'

const useStyles = makeStyles(theme => ({
	popoverPaper: {
		backgroundColor: '#ffffff',
	},
	helpOutline: { margin: '0 5px'},
}));

const ZendeskPopover = (props) => {
	const classes = useStyles();
	const [zendeskOptions, setZendeskOptions] = useState(null);
	
	const openZendeskOptions = () => {
		props.setZendeskDialog();
		setZendeskOptions(null);
	};

	const openVideoTutorials = () => {
		window.open('https://www.youtube.com/channel/UCRD832lL7Znw4ezL3Mr7Lrg', '_blank');
		setZendeskOptions(null);
	};

	const openHelpCenter = () => {
		window.open('https://accelpractice.zendesk.com/hc/en-us', '_blank');
		setZendeskOptions(null);
	};

	return (
		<>
            <IconButton id="user-help" onClick={(event) => setZendeskOptions(event.currentTarget)} className={classes.helpOutline}>
                <HelpOutline style={{ fontSize: 30, color: props.light ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }} />
            </IconButton>
			<Menu
				open={Boolean(zendeskOptions)}
				anchorEl={zendeskOptions}
				onClose={() => setZendeskOptions(null)}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
				transformOrigin={{ vertical: 'top', horizontal: 'center' }}
				classes={{ paper: classes.popoverPaper }}
				getContentAnchorEl={null}
			>
				<MenuItem id="user-help-video-tutorials" onClick={openVideoTutorials}>Video Tutorials</MenuItem>
				<MenuItem id="user-help-open-center" onClick={openHelpCenter}>Help Center</MenuItem>
				<MenuItem id="user-help-zendesk-options" onClick={openZendeskOptions}>Contact Accel Support</MenuItem>
			</Menu>
		</>
	)
}

ZendeskPopover.propTypes = {
	setZendeskDialog: PropTypes.func.isRequired,
}

export default connect(null, { setZendeskDialog })(ZendeskPopover);
