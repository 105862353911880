import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles(theme => ({
    table: {
        marginTop: 28,
        borderCollapse: 'collapse',
        border: '1px solid black !important',
        '& td': {
            border: '1px solid black !important',
            padding: 2,
        },
    },
    popoverPaper: {
        padding: 10,
    },
    dateCell: {
        whiteSpace: 'nowrap',
        fontWeight: 'bold',
        width: 80,
        maxWidth: 80,
        padding: 8,
    },
    textCell: {
        padding: 8,
    },
    seeMore: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));

const ImageVideo = (props) => {
    const classes = useStyles();

    const handleOpen = () => {
        props.setImageData({ targetId: props.targetId, mediaData: props.mediaData, name: props.name, getTargetDataByDate: props.getTargetDataByDate });
    }

    return (
        <Grid container>
            <Grid item xs={12} container>
                <span onClick={handleOpen} className={classes.seeMore}>View</span>
            </Grid>
        </Grid>
    )
}

ImageVideo.propTypes = {
    mediaData: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    setTextState: PropTypes.func.isRequired,
    getTargetDataByDate: PropTypes.func.isRequired,
};

export default ImageVideo;
