import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, Dialog,
    TextField, Grid, IconButton
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
        width: 32,
        height: 32,
    },
    input: {
        width: '100%',
    },
    saveBtn: {
        padding: '6px 40px',
        marginTop: 18,
        marginBottom: 18,
    },
}));

const EditDomain = (props) => {
    const classes = useStyles();
    const { onClose, open, updateDomain, domain } = props;
    const [name, setName] = useState('');
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!!domain) {
            setName(domain.name);
        }
        setError(false);
    }, [open, domain]);

    const handleCancel = () => {
        onClose();
    };

    const handleOk = () => {
        const domainName = name.trim();
        if (!domainName) {
            setError(true);
            return;
        }
        let data = {
            name: domainName,
            id: domain.id,
        }
        updateDomain(data);
    };

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="sm" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={8}>
                        Edit Domain Name
					</Grid>
                    <Grid item xs={4} container justify="flex-end">
                        <IconButton id="edit-domain-close" className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                <Grid container>
                    <Grid item xs={12} container justify="center">
                        <TextField
                            id="edit-domain-name" 
                            variant="outlined"
                            multiline
                            label="Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            error={error && !name.trim()}
                            helperText={error && !name.trim() ? "Name can't be empty" : ' '}
                            className={classes.input}
                        />
                    </Grid>
                    <Grid item xs={12} container justify="flex-end">
                        <Button id="edit-domain-update" className={classes.saveBtn} onClick={handleOk}>Update</Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

EditDomain.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    updateDomain: PropTypes.func.isRequired,
    domain: PropTypes.object,
}

export default EditDomain;
