import React, { useState, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { makeStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import ArrowIcon from '@material-ui/icons/ArrowRight';
import { connect } from 'react-redux';

import CanUser, { CanUserFunc } from '../components/CanUser';
import { access } from '../constants';
import UserAvatar from './UserAvatar';
import ZendeskPopover from './ZendeskPopover';

const useStyles = makeStyles(theme => ({
	logoWrapper: {
		backgroundColor: '#0D3250',
		display: 'flex',
	},
	accelLogo: {
		alignSelf: 'flex-start',
		marginTop: 9,
		marginLeft: 35,
		marginBottom: 9,
		height: 43,
	},
	version: {
		fontSize: 14,
		color: 'rgba(255, 255, 255, 0.2)',
		marginLeft: 10,
		marginBottom: 5,
		display: 'inline-block',
		alignSelf: 'flex-end',
	},
	header: {
		backgroundColor: '#0D3250',
		width: '100%',
		paddingLeft: 20,
		position: 'fixed',
		top: 0,
		zIndex: 2,
	},
	routeTab: {
		display: 'inline-block',
		padding: 20,
		fontSize: 18,
	},
	tabActive: {
		borderBottom: '4px solid #0FB99E',
	},
	tabUnderline: {
		paddingBottom: 5,
		paddingLeft: 5,
		paddingRight: 5,
		textDecoration: 'none',
		fontSize: 17
	},
	link: {
		color: '#ffffff',
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
	},
	sublink: {
		textDecoration: 'none',
		'&:hover': { color: '#0FB99E' },
		textAlign: 'center',
		color: '#0D3250',
	},
	popoverPaper: {
		backgroundColor: '#ffffff',
	},
	sublinkUnderline: {
		textDecoration: 'none',
	},
	sublinkActive: {
		borderBottom: '4px solid #0FB99E',
		'&:hover': { color: '#0FB99E' },
	},
	arrowIcon: {
		verticalAlign: 'middle',
	},
}));

const routes = [
	{
		label: 'Clients',
		route: '/admin/client',
		permission: access.clients.view,
	},
]

const CustomLink = ({ route, openPopover }) => {
	const classes = useStyles();

	let hasSubroute = false;

	if (route.subroute) {
		route.subroute.forEach((sub) => {
			if (!sub.isParent) {
				if (CanUserFunc(sub.permission)) {
					hasSubroute = true;
				}
			}
		})
	}

	const match = useRouteMatch({
		path: route.route,
		exact: !hasSubroute,
	});

	return (
		<div id={'route-' + route.label} className={classes.routeTab} onClick={hasSubroute ? openPopover(route.subroute) : () => { }}>
			<Link id={`route-link-${route.route}`} to={{ pathname: route.route }} className={classes.link} onClick={(e) => hasSubroute && e.preventDefault()}>
				<u className={`${classes.tabUnderline} ${match ? classes.tabActive : ''}`}>
					{route.label}
					{route.special && <ArrowIcon className={classes.arrowIcon} />}
				</u>
			</Link>
		</div>
	)
};

const CustomSubLink = ({ route }) => {
	const classes = useStyles();

	const match = useRouteMatch({
		path: route.route,
		exact: true,
	});

	return (
		<Link id={`route-link-${route.route}`} to={{ pathname: route.route }} className={classes.sublink}>
			<MenuItem>
				<u className={`${classes.sublinkUnderline} ${match ? classes.sublinkActive : ''}`} >{route.label}</u>
			</MenuItem>
		</Link>
	)
};

const Header = (props) => {
	const classes = useStyles();
	const [anchorEl, setAnchorEl] = useState(null);
	const [subroute, setSubroute] = useState([]);
	const [routeList, setRouteList] = useState([]);

	useEffect(() => {
		const newList = routes.slice();
		if (props.user.isSuperAdmin) {
			newList.push({
				label: 'Subscriptions',
				route: '/admin/subscription',
				permission: access.sites.view,
			})
		} else if (props.user.isCompanyAdmin) {
			newList.push({
				label: 'Organization',
				route: '/admin/subscription',
				subroute: [
					{ label: 'Account Info', route: '/admin/subscription/account-info', permission: access.companies.view },
					{ label: 'Sites', route: '/admin/subscription/site', permission: access.sites.view },
				],
				permission: access.companies.view,
			})
		}
		else if (CanUserFunc(access.sites.view)) {
			newList.push({ label: 'Sites', route: '/admin/subscription/site', permission: access.sites.view })
		}

		if (CanUserFunc(access.targets.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/',
				permission: access.targets.view,
				special: true,
			})
		} else if (CanUserFunc(access.analysis.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/analysis',
				permission: access.analysis.view,
				special: true,
			})
		} else if (CanUserFunc(access.data.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/data',
				permission: access.data.view,
				special: true,
			})
		} else if (CanUserFunc(access.notes.view)) {
			newList.push({
				label: 'Clinicals',
				route: '/notes',
				permission: access.notes.view,
				special: true,
			})
		}

		if (props.user.isSuperAdmin) {
			newList.unshift({
				label: 'Users',
				route: '/admin/user',
				permission: access.users.view,
			})
		}
		else if (CanUserFunc(access.users.view)) {
			newList.unshift({
				label: 'Users',
				route: '/admin/user',
				subroute: [
					{ label: 'Users', route: '/admin/user', isParent: true, permission: access.users.view },
					{ label: 'Roles', route: '/admin/user/role', permission: access.roles.view },
					{ label: 'Devices', route: '/admin/user/devices', permission: access.devices.view }
				],
				permission: access.users.view,
			})
		}
		else if (CanUserFunc(access.devices.view) && CanUserFunc(access.roles.view)) {
			newList.unshift({
				label: 'Roles',
				route: '/admin/user/role',
				subroute: [
					{ label: 'Roles', route: '/admin/user/role', isParent: true, permission: access.roles.view },
					{ label: 'Devices', route: '/admin/user/devices', permission: access.devices.view }
				],
				permission: access.roles.view,
			})
		} else {
			if (CanUserFunc(access.devices.view)) {
				newList.unshift({ label: 'Devices', route: '/admin/user/devices', permission: access.devices.view });
			}
			if (CanUserFunc(access.roles.view)) {
				newList.unshift({ label: 'Roles', route: '/admin/user/role', permission: access.roles.view });
			}
		}

		setRouteList(newList);
	}, []);

	const openPopover = (subroute) => (event) => {
		setAnchorEl(event.currentTarget);
		setSubroute(subroute);
	}

	return (
		<Grid container className={classes.header}>
			<Grid item xs={2}>
				<div className={classes.logoWrapper}>
					<img alt="Accel Logo" src={require("../resources/AccelLogo.svg")} className={classes.accelLogo} />
					<span className={classes.version}>{`v${process.env.REACT_APP_VERSION}`}</span>
				</div>
			</Grid>
			<Grid item xs={8} id="admin-header">
				{routeList.map((e, i) => (
					<CanUser
						key={i}
						perform={e.permission}
						yes={() => (
							<CustomLink route={e} openPopover={openPopover} />
						)}
					/>
				))}
			</Grid>
			<Grid item xs={2} container justify="flex-end" alignItems="center">
				<ZendeskPopover light />
				<UserAvatar />
			</Grid>
			<Menu
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				onClose={() => setAnchorEl(null)}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				// PaperProps={{
				// 	onMouseLeave: () => setAnchorEl(null)
				// }}
				classes={{
					paper: classes.popoverPaper,
				}}
				getContentAnchorEl={null}
				disableScrollLock={true}
			>
				{subroute.map((sub, i) => (
					<CanUser
						key={`sub-${i}`}
						perform={sub.permission}
						yes={() => (
							<CustomSubLink route={sub} />
						)}
					/>
				))}
			</Menu>

			{/* Onboarding Disabled */}
			{/* <CustomOnboarding steps={adminHeaderSteps} updateTrigger={routeList} /> */}
		</Grid>
	)
}

Header.propTypes = {
}

const mapStateToProps = state => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(Header);
