import {
    SET_PATIENT, SET_PATIENTS,
} from './action-types';

const initialState = {
    patient: {},
    patients: [],
}

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_PATIENT:
            return {
                ...state,
                patient: action.payload,
            }

        case SET_PATIENTS:
            return {
                ...state,
                patients: action.payload,
            }


        default:
            return state
    }
}