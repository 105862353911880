import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Chip, FormControlLabel, Checkbox, InputLabel, Grid, FormHelperText, Popover, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { roleNames } from '../../constants';
import { getRolesDropdown } from '../../services/RoleService';

const useStyles = makeStyles(theme => ({
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    input: { width: '100%' },
    chipBox: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
    },
    popoverPaper: {
        padding: 20,
        width: '40vw',
		'@media (max-width: 768px)': {
            width: '70vw',
        },
    },
    buttonChip: {
        border: '0 !important',
        color: '#ffffff',
        backgroundColor: '#0FB99E !important',
        '&:hover': { backgroundColor: '#00826d !important' },
    },
    infoIcon: {
        color: '#0FB99E',
        fontSize: 20,
        margin: '-10px 0 -5px 5px',
    },
}));

const RolePicker = (props) => {
    const classes = useStyles();
    const { error, onChange, user, title, style, tooltipTitle, isMobile } = props;
    const [roles, setRoles] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getRolesDropdown(user.companyId).then((res) => {
            setRoles(res.data.data.roles)
        });
    }, []);

    const handleDeleteChip = (item) => () => {
        const array = user.roles;
        array.splice(array.indexOf(item), 1);
        onChange(array);
    }
    
    const handleRoleCheck = (item) => event => {
        let array = user.roles;
        if (event.target.checked) {
            array.push(item);
        } else {
            array = array.filter(e => e.name !== item.name);
        }
        onChange(array);
    }

    return (
        <>
            <Grid item xs={6} className={classes.leftWrapper}>
                <InputLabel required>{title}{ tooltipTitle ? 
                    <Tooltip title={tooltipTitle}>
                        <InfoIcon className={classes.infoIcon} />
                    </Tooltip> : <></>
                    }
                </InputLabel>
                <Grid container className={classes.chipBox} style={error && !user.roles.length ? { borderColor: '#f44336', ...style } : style}>
                    {user.roles?.map(role =>(
                        <Chip
                            variant="outlined"
                            size="small"
                            label={role.name}
                            key={role?.id}
                            onDelete={handleDeleteChip(role)}
                            disabled={role.name === roleNames.ORG_ADMIN}
                        />
                    ))}
                    <Chip
                        variant="outlined"
                        size="small"
                        label="Add Roles"
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        className={classes.buttonChip}
                    />
                </Grid>
                <FormHelperText error={error && !user.roles.length}>
                    {error && !user.roles.length ? 'Please choose at least one role' : ' '}
                </FormHelperText>
            </Grid>

            <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <Grid container>
                    {roles.filter(role => role.name !== roleNames.ORG_ADMIN).map(role => (
                        <Grid item xs={6} sm={3} key={role.id}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={user.roles.find(r => r.name === role.name)} onChange={handleRoleCheck(role)} />}
                                label={role.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Popover>
        </>
    )
}

RolePicker.propTypes = {
    user: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default RolePicker;