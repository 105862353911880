export const headerSteps = [
    {
        target: 'clinical-header',
        content: 'Here you can navigate within our application'
    },
    {
        target: 'route-Program',
        content: 'This is the main page of Clinical.\nYou can see targets and add new domain, program, and target here.'
    },
    {
        target: 'route-Data',
        content: 'Here you can find submitted data from past sessions.\nYou can also edit past data here.'
    },
    {
        target: 'route-Analysis',
        content: 'Here you can find graphs that represents submitted data from past sessions.'
    },
    {
        target: 'route-Notes',
        content: 'Here you can find notes regarding current client'
    },
    {
        target: 'route-Settings',
        content: 'Here you can find settings regarding current client'
    },
    {
        target: 'route-Admin',
        content: 'Click here to navigate to Admin parts of the app'
    }
]

export const clientPickerSteps = [
    {
        target: 'client-picker',
        content: 'Select client from here'
    }
]

export const userAvatarSteps = [
    {
        target: 'user-avatar',
        content: 'Click here to access account menu'
    }
]

export const userAvatarMenuSteps = [
    {
        target: 'user-avatar-info',
        content: 'Click here to change your account details'
    },
    {
        target: 'user-avatar-library',
        content: 'Click here to open library page'
    },
    {
        target: 'user-avatar-setting',
        content: 'Click here to change your personal settings'
    },
    {
        target: 'user-avatar-tutorial',
        content: 'Click here to check tutorials'
    },
    {
        target: 'user-avatar-logout',
        content: 'Click here to logout'
    },
]

export const sidebarSteps = [
    {
        target: 'sidebar',
        content: 'Here is the sidebar. You can navigate between domains and programs from here'
    },
    {
        target: 'sidebar-app-version',
        content: 'Application version can always be found here'
    },
    {
        target: 'sidebar-archived-toggle',
        content: 'Click here to toggle show archived on/off'
    },
    {
        target: 'sidebar-all-domains',
        content: 'Click here to show targets from all domains'
    },
    {
        target: 'sidebar-rearrange',
        content: 'You can always rearrange domains and programs by dragging it around'
    }
]

export const programPageSteps = [
    {
        target: 'program-page-add',
        content: 'Click here to add domain, program or target'
    },
    {
        target: 'program-page-search',
        content: 'You can search targets using program name and target name'
    },
    {
        target: 'program-page-upload',
        content: 'Upload domain, program, and target from here'
    },
    {
        target: 'program-page-download',
        content: 'Download domain, program, and target from here'
    }
]

export const targetGridSteps = [
    {
        target: 'target-grid-star',
        content: 'Toggle starred targets from here'
    },
    {
        target: 'target-grid-graph',
        content: 'Click on icons below to open analysis with only data from the selected target'
    },
    {
        target: 'target-grid-action',
        content: 'Click on actions below to see target actions'
    }
]

export const dataPageSteps = [
    {
        target: 'data-page-date',
        content: 'Set date filter'
    },
    {
        target: 'data-page-status',
        content: 'Set status filter'
    },
    {
        target: 'data-page-user',
        content: 'Set user filter'
    }
]

export const analysisPageSteps = [
    {
        target: 'analysis-page-date',
        content: 'Set date filter'
    },
    {
        target: 'analysis-page-status',
        content: 'Set status filter'
    },
    {
        target: 'analysis-page-user',
        content: 'Set user filter'
    },
    {
        target: 'analysis-page-combine',
        content: 'Combine target or separate target'
    },
    {
        target: 'analysis-page-elements',
        content: 'Toggle elements of the graph on/off'
    },
    {
        target: 'analysis-page-download',
        content: 'Download graph, notes, or both'
    },
]

export const memoSteps = [
    {
        target: 'memo-search',
        content: 'Search phrases or words'
    },
    {
        target: 'memo-add',
        content: 'Add a new memo',
        placement: 'top'
    },
]

export const reminderSteps = [
    {
        target: 'reminder-add',
        content: 'Add a new reminder'
    }
]

export const librarySteps = [
    {
        target: 'library-type',
        content: 'View target library or program library'
    }
]

export const userSteps = [
    {
        target: 'user-add',
        content: 'Add a new user'
    },
    {
        target: 'user-search',
        content: 'Search for user'
    },
    {
        target: 'user-download',
        content: 'Download the displayed user list'
    },
    {
        target: 'user-upload',
        content: 'Upload users'
    },
]

export const roleSteps = [
    {
        target: 'role-add',
        content: 'Add a new role'
    },
]

export const clientSteps = [
    {
        target: 'client-add',
        content: 'Add a new client'
    },
    {
        target: 'client-search',
        content: 'Search for client'
    },
    {
        target: 'client-download',
        content: 'Download the displayed client list'
    },
    {
        target: 'client-upload',
        content: 'Upload clients'
    },
]

export const siteSteps = [
    {
        target: 'site-add',
        content: 'Add a new site'
    },
]

export const adminHeaderSteps = [
    {
        target: 'admin-header',
        content: 'Here you can navigate within admin pages'
    },
    {
        target: 'route-Users',
        content: 'Manage users within your workspace'
    },
    {
        target: 'route-Clients',
        content: 'Manage clients within your workspace'
    },
    {
        target: 'route-Sites',
        content: 'Manage sites within your workspace'
    },
    {
        target: 'route-Clinicals',
        content: 'Open clinical pages'
    },
]

export const allSteps = [
    ...headerSteps,
    ...clientPickerSteps,
    ...userAvatarSteps,
    ...userAvatarMenuSteps,
    ...sidebarSteps,
    ...programPageSteps,
    ...targetGridSteps,
    ...dataPageSteps,
    ...analysisPageSteps,
    ...memoSteps,
    ...reminderSteps,
    ...librarySteps,
    ...userSteps,
    ...roleSteps,
    ...clientSteps,
    ...siteSteps,
    ...adminHeaderSteps
]