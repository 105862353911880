export const removeDeletedEmptyTextData = (target) => {
    const getValue = (rawValue) =>
        typeof rawValue === 'string' 
            ? rawValue.replace(/\s/g,' ') 
            : rawValue?.join(', ').replace(/\s/g,' ');
    const customData = target.customData;
    const targetData = target.targetData;
    const filteredFields = {};
    (targetData ?? []).forEach(e => {
        if (typeof e?.value === 'object') {
            for (let key in e.value) {
                if (getValue(e.value[key])) {
                    filteredFields[key] = true;
                }
            }
        }
    });
    const newCustomData = customData?.filter(e => !e.isDeleted || filteredFields[e.id]) ?? [];
    return { ...target, customData: newCustomData };
}

export const removeDeletedEmptyCategoryData = (target) => {
    const customData = target.customData;
    const targetData = target.targetData;
    const filteredFields = {};
    (targetData ?? []).forEach(e => {
        if (Array.isArray(e?.value)) {
            e.value.forEach((k) => {
                if (Array.isArray(k)) {
                    k.forEach((l) => {
                        if (l.value) {
                            filteredFields[l.id] = true;
                        }
                    });
                }
            });
        }
    });
    const newCustomData = customData?.filter(e => !e.isDeleted || filteredFields[e.id]) ?? [];
    return { ...target, customData: newCustomData };
}

export const removeDeletedEmptyProbeData = (target) => {
    const sets = target.customData.sets;
    const targetData = target.targetData;
    const filteredFields = {};
    (targetData ?? []).forEach(e => {
        if (Array.isArray(e?.value)) {
            e.value.forEach((k) => {
                for (const key in k) {
                    if ((Array.isArray(k[key]) && !!k[key].find(e => !!e))
                        || (!Array.isArray(k[key]) && !!k[key])
                    ) {
                        filteredFields[key] = true; 
                    }
                }
            });
        }
    });
    const newSets = sets?.filter(e => !e.isDeleted || filteredFields[e.id]) ?? [];
    return { ...target, customData: { ...target.customData, sets: newSets }};
}

export const removeDeletedEmptyRatingScaleData = (target) => {
    const customData = target.customData;
    const targetData = target.targetData;
    const filteredFields = {};
    (targetData ?? []).forEach(e => {
        if (Array.isArray(e?.value)) {
            e.value.forEach((k) => {
                if (Array.isArray(k)) {
                    k.forEach((l) => {
                        filteredFields[l.questionId] = true;
                    });
                }
            });
        }
    });
    const newCustomData = customData?.filter(e => !e.isDeleted || filteredFields[e.id]) ?? [];
    return { ...target, customData: newCustomData };
}