import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { connect } from 'react-redux';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { setSnackbar, setLoading, setGlobalDialog } from '../../store/general/actions';
import { CanUserFunc } from '../CanUser';
import { access } from '../../constants';
import { updatePatient, deletePatient, activatePatient, deactivatePatient } from '../../services/PatientService';
import { defaultColumnSettings } from '../UserSetting/DisplayColumns';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        verticalAlign: 'middle !important',
        padding: '12px 12px',
    },
    tableCell: {
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    actionColumn: {
        width: 75,
        padding: '2px 12px',
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
    iconButton: {
        marginLeft: 5,
        padding: 4,
    },
    activeFilter: {
        color: '#0FB99E',
    },
    tableHead: {
        position: 'relative',
        zIndex: 0
    }
}));

const headCells = [
    { id: 'firstName', label: 'First Name', sort: true },
    { id: 'lastName', label: 'Last Name', sort: true },
    { id: 'clientId', label: 'Client ID', sort: true },
    { id: 'email', label: 'Email', sort: true },
    { id: 'phone', label: 'Phone', sort: true },
    { id: 'site', label: 'Site', filter: true },
    { id: 'status', label: 'Status', filter: true },
];

const ClientTable = (props) => {
    const classes = useStyles();
    const {
        clients, loading, setSort, onEdit, fetchClient,
        setSnackbar, setLoading, setGlobalDialog, openFilter,
        isFilterActive, sort, user
    } = props;
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [selectedClient, setSelectedClient] = React.useState(null);
    const [columnsSetting, setColumnsSetting] = React.useState({});

    useEffect(() => {
        if (sort) {
            setOrder(sort.sortAs || 'asc');
            setOrderBy(sort.sortBy || '');
        }
    }, [sort])

    useEffect(() => {
        if (clients.length <= page * rowsPerPage) {
            setPage(page - 1 >= 0 ? page - 1 : 0);
        }
    }, [clients])

    useEffect(() => {
        const clientsColumnsSetting = user.settings?.columnsSetting?.clients;
        const formattedSetting = {};
        if (clientsColumnsSetting) {
            Object.entries(clientsColumnsSetting).forEach(([key, value]) => {
                formattedSetting[key] = value;
            });
        } else {
            defaultColumnSettings.clients.forEach((item) => {
                formattedSetting[item.key] = item.checked;
            });
        }
        setColumnsSetting(formattedSetting);
    }, [user])

    const handleRequestSort = (property) => event => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        setSort({ sortAs: isAsc ? 'desc' : 'asc', sortBy: property });
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const openClientAction = (client) => (event) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setSelectedClient(client);
    }


    const handleEditClient = () => {
        setAnchorEl(null);
        onEdit(selectedClient);
    }

    /** When the entire row is clicked, set the selected client to clicked client and open edit form */
    const handleRowClick = (client) => (event) => {
        setSelectedClient(client);
        onEdit(client);
    }

    const handleActivateClient = () => {
        setAnchorEl(null);
        setLoading('editClient');
        activatePatient(selectedClient.id).then(() => {
            setLoading('editClient', false);
            setSnackbar('success', 'Client activated!');
            fetchClient();
        })
    }

    const handleDeactivateClient = () => {
        setAnchorEl(null);
        setLoading('editClient');
        deactivatePatient(selectedClient.id).then(() => {
            setLoading('editClient', false);
            setSnackbar('success', 'Client deactivated!');
            fetchClient();
        })
    }

    const handleDeleteClient = () => {
        setAnchorEl(null);
        setGlobalDialog(
            `Delete client?`,
            `Deleted client cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    setLoading('deleteClient');
                    deletePatient(selectedClient.id).then(() => {
                        setLoading('deleteClient', false);
                        setSnackbar('success', 'Client deleted!');
                        fetchClient();
                    });
                }
            },
        )
    }

    const showActions = CanUserFunc(access.clients.edit) || CanUserFunc(access.clients.remove);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table stickyHeader className={classes.table}>
                        <TableHead className={classes.tableHead}>
                            <TableRow className={classes.tblHeadRow}>
                                {headCells.map(headCell => 
                                    (columnsSetting[headCell.id] || headCell.id === 'clientId') && <TableCell
                                        key={headCell.id}
                                        className={classes.headCell}
                                        sortDirection={orderBy === headCell.id ? order : false}
                                    >
                                        {headCell.sort ?
                                            <TableSortLabel
                                                active={orderBy === headCell.id}
                                                direction={orderBy === headCell.id ? order : 'asc'}
                                                onClick={handleRequestSort(headCell.id)}
                                            >
                                                {headCell.label}
                                                {orderBy === headCell.id ? (
                                                    <span className={classes.visuallyHidden}>
                                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                                    </span>
                                                ) : null}
                                            </TableSortLabel>
                                            :
                                            headCell.label
                                        }
                                        {headCell.filter &&
                                            <IconButton className={classes.iconButton} onClick={(e) => openFilter(headCell.id, e.currentTarget)}>
                                                <KeyboardArrowDownIcon className={isFilterActive(headCell.id) ? classes.activeFilter : ''} />
                                            </IconButton>
                                        }
                                    </TableCell>
                                )}
                                {showActions &&
                                    <TableCell className={`${classes.headCell} ${classes.actionColumn}`}>Action</TableCell>
                                }
                            </TableRow>
                        </TableHead>
                        {loading.getClients ?
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={showActions ? 7 : 6}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {clients.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                onClick={CanUserFunc(access.clients.edit) ? handleRowClick(row) : null}
                                                key={row.id}
                                            >
                                                {columnsSetting.firstName &&<TableCell id={`clients-row-first-name-${row.id}`} className={classes.tableCell} component="th" scope="row">
                                                    {row.firstName}
                                                </TableCell>}
                                                {columnsSetting.lastName && <TableCell id={`clients-row-last-name-${row.id}`} className={classes.tableCell}>{row.lastName}</TableCell>}
                                                <TableCell id={`clients-row-client-id-${row.id}`} className={classes.tableCell}>{row.clientId}</TableCell>
                                                {columnsSetting.email && <TableCell id={`clients-row-email-${row.id}`} className={classes.tableCell}>{row.email}</TableCell>}
                                                {columnsSetting.phone && <TableCell id={`clients-row-phone-${row.id}`} className={classes.tableCell}>{row.phone}</TableCell>}
                                                {columnsSetting.site && <TableCell id={`clients-row-branch-name-${row.id}`} className={classes.tableCell}>{row.branch.name}</TableCell>}
                                                {columnsSetting.status && <TableCell id={`clients-row-is-active-${row.id}`} className={classes.tableCell}>{row.isActive ? 'Active' : 'Inactive'}</TableCell>}
                                                {showActions &&
                                                    <TableCell className={`${classes.tableCell} ${classes.actionColumn}`}>
                                                        <IconButton id={`client-row-edit-${row.id}`} onClick={openClientAction(row)}>
                                                            <img alt="Edit" src={require("../../resources/EditIcon.svg")} />
                                                        </IconButton>
                                                        {selectedClient &&
                                                            <LoadingCheck show={(loading.deleteClient || loading.editClient) && selectedClient.id === row.id} size={24} />
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        );
                                    })}
                                {!clients.length &&
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell id="client-row-no-user-found" className={classes.noData} colSpan={showActions ? 7 : 6}>No clients found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 15, 50, 100]}
                    component="div"
                    count={clients.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                getContentAnchorEl={null}
            >
                {CanUserFunc(access.clients.edit) ?
                    <MenuItem id="client-row-edit-option" onClick={handleEditClient}>Edit</MenuItem>
                    : null
                }
                {CanUserFunc(access.clients.edit) ?
                    selectedClient && selectedClient.isActive ?
                        <MenuItem id="client-row-inactivate-option" onClick={handleDeactivateClient}>Inactivate</MenuItem>
                        :
                        <MenuItem id="client-row-activate-option" onClick={handleActivateClient}>Activate</MenuItem>
                    : null
                }
                {CanUserFunc(access.clients.remove) ?
                    <MenuItem id="client-row-delete-option" onClick={handleDeleteClient}>Delete</MenuItem>
                    : null
                }
            </Menu>
        </div>
    );
}

ClientTable.propTypes = {
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setSkip: PropTypes.func.isRequired,
    setSort: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    fetchClient: PropTypes.func.isRequired,
    clients: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
    openFilter: PropTypes.func.isRequired,
    isFilterActive: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading, setSnackbar, setGlobalDialog })(ClientTable);