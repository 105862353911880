import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Button, DialogContent, Dialog, Grid } from "@material-ui/core";
import DividerWrapper from "./DividerWraper";

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minWidth: 300,
    },
    dialogContent: {
        padding: 20,
    },
    grid: {
        justifyContent: "start",
    },
    title: {
        fontWeight: "bold",
        marginBottom: 20,
    },
    buttonHolder: {
        marginTop: 10,
        justifyContent: "right",
    },
    button: {
        width: "30%",
    },
    historyContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxHeight: "60vh",
        overflowY: "auto",
    },
}));

const SessionTargetChanges = (props) => {
    const classes = useStyles();

    return (
        <Dialog
            disableBackdropClick
            disableEscapeKeyDown
            maxWidth="md"
            open={props.open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogContent className={classes.dialogContent}>
                <Grid
                    className={`${classes.grid} ${classes.title}`}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    Update Summary
                </Grid>
                <Grid
                    className={classes.grid}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    <div className={classes.historyContainer}>
                        {props.changes?.map((e, index) => (
                            <DividerWrapper key={index}>
                                <span>{`Updated Target "${e.name}"`}</span>
                                {e.changes?.map((k, index1) => (
                                  <li key={index1}>{k}</li> 
                                ))}
                            </DividerWrapper>
                        ))}
                    </div>
                </Grid>
                <Grid
                    className={`${classes.buttonHolder}`}
                    item
                    xs={12}
                    container
                    justify="flex-end"
                >
                    <Button className={classes.button} onClick={props.onClose}>
                        Close
                    </Button>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

SessionTargetChanges.propTypes = {
    open: PropTypes.bool.isRequired,
    changes: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SessionTargetChanges;
