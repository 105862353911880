import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Collapse,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CanUser from '../CanUser';
import { access } from '../../constants';
import { format, } from 'date-fns-tz';

const useStyles = makeStyles((theme) => ({
  notesTblHeadRow: {
    backgroundColor: '#F4F4F4',
  },
  headCell: {
    fontWeight: 'bold !important',
    fontSize: '16px !important',
    verticalAlign: 'middle !important',
    padding: '12px 16px',
  },
  btnExpand: {
    color: '#0FB99E',
    padding: 0,
    marginLeft: 15,
  },
  expandIcon: {
    fontSize: 32,
  },
  dateCell: {
    width: '15%',
    verticalAlign: 'top',
    color: 'black',
    fontSize: 14,
  },
  targetCell: {
    width: '20%',
    verticalAlign: 'top',
    fontSize: 14,
    color: 'black',
  },
  noteCell: {
    width: '65%',
    fontWeight: 500,
    color: 'black',
    fontSize: 14,
  },
  noNotes: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 500,
    textAlign: 'center',
    fontSize: 18,
  },
  type: {
    fontWeight: 'bold',
    paddingLeft: 20,
    fontSize: 16,
  },
  detailsWrapper: {
    marginBottom: 10,
  },
  wrapper: {
    backgroundColor: '#fff',
    padding: 10,
    fontFamily: 'Roboto',
  },
  moreIconBtn: {
    padding: 0,
    color: '#0FB99E',
  },
  moreIcon: {
    fontSize: 32,
  },
}));

const Notes = (props) => {
  const classes = useStyles();
  const [showNotes, setShowNotes] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChangeShowNotes = () => {
    setShowNotes(!showNotes);
  };

  const handleDownload = () => {
    props.handleDownload();
    setAnchorEl(null);
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  let noNotes = true;
  let notes = [];
  props.notesData.forEach((target) => {
    if (target.notes?.filter((e) => e.note)?.length > 0) {
      noNotes = false;
      target.notes
        .filter((e) => e.note)
        .forEach((data) => {
          notes.push({
            name: target.name,
            date: data.date,
            note: data.note,
          });
        });
    }
  });

  notes.sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <Grid className={classes.wrapper} container>
      <Grid item xs={12} container className={classes.detailsWrapper}>
        <Grid item xs={4} className={classes.type}></Grid>
        <Grid item xs={4} className={classes.title}>
          Comments
        </Grid>
        <Grid item xs={4} container justify="flex-end">
          <IconButton
            className={classes.btnExpand}
            onClick={handleChangeShowNotes}
          >
            {showNotes ? (
              <KeyboardArrowUpIcon className={classes.expandIcon} />
            ) : (
              <KeyboardArrowDownIcon className={classes.expandIcon} />
            )}
          </IconButton>
          <CanUser
            perform={access.analysis.uploadDownload}
            yes={() => (
              <IconButton onClick={openMenu} className={classes.moreIconBtn}>
                <MoreVertIcon className={classes.moreIcon} />
              </IconButton>
            )}
            no={() => null}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Collapse in={showNotes} style={{ width: '100%' }}>
          <Table>
            <TableHead>
              <TableRow className={classes.notesTblHeadRow}>
                <TableCell
                  className={`${classes.dateCell} ${classes.headCell}`}
                >
                  Date
                </TableCell>
                <TableCell
                  className={`${classes.targetCell} ${classes.headCell}`}
                >
                  Target
                </TableCell>
                <TableCell
                  className={`${classes.headCell} ${classes.noteCell}`}
                >
                  Notes
                </TableCell>
              </TableRow>
            </TableHead>
            {!noNotes && (
              <TableBody>
                {notes.map((data, index) => {
                  return (
                    <TableRow key={`notes-${index}`}>
                      <TableCell className={classes.dateCell}>
                        {format(new Date(data.date), 'MM/d/yyyy HH:mm')}
                      </TableCell>
                      <TableCell className={classes.targetCell}>
                        {data.name}
                      </TableCell>
                      <TableCell className={classes.noteCell}>
                        {data.note}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          {noNotes ? (
            <p className={classes.noNotes}>No Comments available</p>
          ) : null}
        </Collapse>
      </Grid>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <MenuItem onClick={handleDownload}>Download</MenuItem>
      </Menu>
    </Grid>
  );
};

Notes.propTypes = {
  notesData: PropTypes.array.isRequired,
};

export default Notes;
