export const SET_SELECTION = 'SET_SELECTION';
export const SET_SNACKBAR = 'SET_SNACKBAR';
export const SET_LOADING = 'SET_LOADING';
export const SET_TARGET_LOADING = 'SET_TARGET_LOADING';
export const SET_LIBRARY_LOADING = 'SET_LIBRARY_LOADING';
export const SET_GLOBAL_DIALOG = 'SET_GLOBAL_DIALOG';
export const CLOSE_GLOBAL_DIALOG = 'CLOSE_GLOBAL_DIALOG';
export const SET_UPLOAD_DIALOG = 'SET_UPLOAD_DIALOG';
export const CLOSE_UPLOAD_DIALOG = 'CLOSE_UPLOAD_DIALOG';
export const SET_PATIENT_SELECTION_MAP = 'SET_PATIENT_SELECTION_MAP';
export const CLEAR_LOADING = 'CLEAR_LOADING';
export const SET_FINISHED_ONBOARDINGS = 'SET_FINISHED_ONBOARDINGS';
export const SET_SIDEBAR_WIDTH = 'SET_SIDEBAR_WIDTH';
export const SET_ZENDESK_DIALOG = 'SET_ZENDESK_DIALOG';
export const CLOSE_ZENDESK_DIALOG = 'CLOSE_ZENDESK_DIALOG';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
