
/**
 * 
 * @param date: Date to ISO String with offset added
 * Convert Date to number of days from 1970, timezone included
*/
const dateToIsoString = (date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = function (num) {
      return (num < 10 ? "0" : "") + num;
    };

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." + 
    pad(date.getMilliseconds()) +
    "Z"
  );
};

export default dateToIsoString;
