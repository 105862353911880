import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import { Grid, InputAdornment, TextField } from '@material-ui/core';
import DropdownButton from '../../assets/button/DropdownButton';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux';
import debounce from 'awesome-debounce-promise';

import LibraryTable from './LibraryTable';
import { findTargetLibrary, getLibrary } from '../../services/LibraryService';
import { setLibTableFilter } from '../../store/setting/actions';
import { setLoading } from '../../store/general/actions';
import { localStorageEnum } from '../../constants';

const useStyles = makeStyles(theme => ({
    searchWrapper: {
        padding: '5px 0px 5px 30px',
        backgroundColor: '#FFFFFF',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
    },
    searchField: {
        width: '100%'
    },
	titleWrapper: {
		height: 50,
		backgroundColor: '#F4F4F4',
		color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
		lineHeight: '50px',
		paddingLeft: 54,
	},
    filter: {
		marginTop: 5,
        paddingRight: 5,
        color: '#000000',
        backgroundColor: '#ffffff',
        '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
    },
    ownerFilter: {
        marginRight: 15,
        marginBottom: 10,
    },
    popoverPaper: { padding: 20 },
}));

const debouncedSearch = debounce(findTargetLibrary, 750);

const ownerFilterOptions = [
    {
        label: 'All Libraries',
        value: 1
    },
    {
        label: 'My Library',
        value: 2
    },
	{
        label: 'Other Libraries',
        value: 3
    },
];

const ownerFilterValueMap = [null, 'all', 'me', 'other']

const Library = (props) => {
    const classes = useStyles();
    const { libFilter, setLoading, user, setLibTableFilter  } = props;

	const [libTableFilters] = useState(() => JSON.parse(localStorage.getItem(localStorageEnum.libTableFilters)));
    const [ownedBy, setOwnedBy] = useState(libTableFilters?.ownedBy || 'all');
	const [libraries, setLibraries] = useState([]);
	const [allLibraries, setAllLibraries] = useState([]);
    const [search, setSearch] = useState('');
	const resourceName = libFilter.resourceName && libFilter.resourceName !== '' ? ' > ' + libFilter.resourceName + '' : '';
	const pageTitle = ownedBy === 'all' ? 'All Libraries' + resourceName :
		ownedBy === 'me' ? 'My Library' + resourceName :
		'Other Libraries' + resourceName;
	
	const filteredLibraries = ownedBy === 'all' ? libraries :
			ownedBy === 'me' ? libraries.filter(lib => lib.owner.id === user.id) :
			libraries.filter(lib => lib.owner.id !== user.id);
	
	const filteredAllLibraries = ownedBy === 'all' ? allLibraries :
			ownedBy === 'me' ? allLibraries.filter(lib => lib.owner.id === user.id) :
			allLibraries.filter(lib => lib.owner.id !== user.id);

    useEffect(() => {
		const { groupBy, resourceId } = libFilter;
		if (search) {
			setLoading('getLibraries');
			debouncedSearch(groupBy, resourceId, search).then((res) => {
				setLibraries(res.data.data);
				setLoading('getLibraries', false);
			})
		} else {
			fetchData();
		}
    }, [search, libFilter])

	useEffect(() => {
		const domains = filteredAllLibraries.map(lib => lib.program.domain.id);
		setLibTableFilter({ownedBy, domains})
    }, [ownedBy, filteredAllLibraries]);

	useEffect(() => {
		getLibrary('all', 0).then((res) => {
			setAllLibraries(res.data.data);
		})
    }, []);

    const fetchData = (load = true) => {
		const { groupBy, resourceId } = libFilter;
		load && setLoading('getLibraries')
		getLibrary(groupBy, resourceId).then((res) => {
			setLibraries(res.data.data);
			setLoading('getLibraries', false)
		})
    }

    const handleSearch = (event) => {
        setSearch(event.target.value);
    }

    return (
        <div style={{ maxWidth: "100%", overflow: 'hidden' }}>
            <Grid container justify="center">
				<Grid item md={3} lg={2} className={classes.searchWrapper}>
					<DropdownButton
                        id='library-page-options'
                        selectedValue={ownerFilterValueMap.findIndex(e => e === ownedBy) || 0}
                        onValueChange={(event) => setOwnedBy(ownerFilterValueMap[event.target.value])}
                        options={ownerFilterOptions}
                        wrapperStyle={classes.ownerFilter}
                    />
				</Grid>
				<Grid item md={4} lg={5} className={classes.searchWrapper}>
					<TextField
						id="library-page-search-input"
						variant="outlined"
						className={classes.searchField}
						placeholder="Program, target..."
						onChange={handleSearch}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</Grid>
				<Grid item xs={2}></Grid>
            </Grid>
			<div id="library-page-title" className={classes.titleWrapper}>{pageTitle}</div>
            <LibraryTable
				libraries={filteredLibraries}
				setLibraries={setLibraries}
				forceExpands={!!search}
				search={search}
			/>
        </div>
    );
}

Library.propTypes = {
    loading: PropTypes.object.isRequired,
	libFilter: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
	libFilter: state.setting.libFilter,
	user: state.auth.user,
    setLibTableFilter: PropTypes.func.isRequired,
})

export default connect(mapStateToProps, { setLoading, setLibTableFilter })(Library)
