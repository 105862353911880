import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, IconButton, Collapse, Typography, Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Menu, MenuItem } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import format from 'date-fns/format';

import { dataTypeDict } from '../../constants';
import ImageVideoDialog from './ImageVideoDialog';
import urlDownload from '../../utils/urlDownload';

const useStyles = makeStyles(theme => ({
	btnExpand: {
		color: '#0FB99E',
		padding: 0,
	},
	tblExpandWrapper: {
		width: '10%',
		textAlign: 'right',
		padding: '12px 16px',
	},
	expandIcon: {
		fontSize: 32,
	},
	title: {
		fontWeight: 500,
		textAlign: 'center',
		fontSize: 18,
	},
	type: {
		fontWeight: 'bold',
		paddingLeft: 20,
		fontSize: 16,
	},
	detailsWrapper: {
		padding: 10,
	},
	wrapper: {
		backgroundColor: '#fff',
		fontFamily: 'Roboto',
    },
    targetDataWrapper: {
        margin: '16px 24px !important',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    column: {
      flexBasis: '33.33%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: theme.spacing(1, 2),
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    mediaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '200px',
        margin: '10px 0',
        padding: '0 10px',
        cursor: 'pointer',
        '&:hover': {
            border: '4px solid #337ab7'
        },
    },
    media: {
        width: '150px',
        height: '100px',
        objectFit: 'cover',
        margin: '0 10px',
    },
    mediaName: {
        fontSize: '14px',
        fontWeight: 'bold',
        overflowWrap: 'anywhere',
        textAlign: 'center',
    },
    mediaInfo: {
        fontSize: '14px',
        textAlign: 'center',
    },
    downloadButton: {
        position: 'absolute',
        right: -50,
        top: 15,
        bottom: 10,
    },
    moreIconBtn: {
      padding: 0,
      color: '#0FB99E',
    },
    moreIcon: {
      fontSize: 32,
    },
    accordionSummary: { marginRight: 30}
}));

const ImageVideoTable = (props) => {
	const classes = useStyles();
	const { loopKey, data, title } = props;
	const [showNotes, setShowNotes] = useState(true);
	const [target, setTarget] = useState(null);
	const [mediaData, setMediaData] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);

	const handleChangeShowNotes = () => {
		setShowNotes(!showNotes);
    };
    
    const handleMediaClick = (media, targetData) => {
        setTarget(media);
        setMediaData(targetData);
    }
    
    const handleClose = () => {
        setTarget(null);
        setMediaData({});
    }

    const handleDownload = targetData => {
        targetData.map(data => data?.value.map(item => urlDownload(item.url)));
        setAnchorEl(null);
    }
    
    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
	return (
		<Grid key={loopKey} className={classes.wrapper} container id={`text ${title}`}>
			<Grid item xs={12} container className={classes.detailsWrapper}>
				<Grid item xs={4} className={classes.type}>
					Data Type: {dataTypeDict.imageVideo}
				</Grid>
				<Grid item xs={4} className={classes.title}>
					{props.graphElements.graphTitles ? title : ''}
				</Grid>
				<Grid item xs={4} container justify="flex-end">
					<IconButton className={classes.btnExpand} onClick={handleChangeShowNotes}>
						{showNotes ?
							<KeyboardArrowUpIcon className={classes.expandIcon} />
							:
							<KeyboardArrowDownIcon className={classes.expandIcon} />
						}
					</IconButton>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={showNotes} style={{ width: '100%' }}>
                    {
                        data.map((targetData, _i) => (
                            <Accordion defaultExpanded className={classes.targetDataWrapper} id={`image/video ${targetData.name}`}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1c-content"
                                    id="panel1c-header"
                                    className={classes.accordionSummary}
                                >
                                    <Typography className={classes.heading}>{targetData.name}</Typography>
                                    <AccordionDetails className={classes.downloadButton}>
                                        <FormControlLabel
                                            aria-label="Download"
                                            onClick={(event) => event.stopPropagation()}
                                            onFocus={(event) => event.stopPropagation()}
                                            control={
                                                <IconButton onClick={openMenu} className={classes.moreIconBtn}>
                                                    <MoreVertIcon className={classes.moreIcon}/>
                                                </IconButton>
                                            }
                                        />
                                    </AccordionDetails>
                                </AccordionSummary>
                                <AccordionDetails className={classes.details}>
                                    {
                                        targetData.targetData.map((target, _i) => {
                                            const userName = `${target.user.firstName} ${target.user.lastName}`;
                                            const uploadedDate = format(new Date(target.date), "MM/d/yyyy");
                                            return target.value.map((media, _i) => (
                                            <a onClick={() => handleMediaClick(media, targetData)}>
                                                {media.type.includes('image') ? (
                                                    <div className={classes.mediaWrapper}>
                                                        <img src={media.url} className={classes.media}/>
                                                        <Typography className={classes.mediaName}>{media.name}</Typography>
                                                        <Typography className={classes.mediaInfo}>{`${uploadedDate} by ${userName}`}</Typography>
                                                    </div>
                                                ) : (
                                                    <div className={classes.mediaWrapper}>
                                                        <video id="video1" className={classes.media}>
                                                            <source src={media.url} type="video/mp4" />
                                                        </video>
                                                        <Typography className={classes.mediaName}>{media.name}</Typography>
                                                        <Typography className={classes.mediaInfo}>{`${uploadedDate} by ${userName}`}</Typography>
                                                    </div>
                                                )}
                                            </a>
                                            ))
                                        })
                                    }
                                </AccordionDetails>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                getContentAnchorEl={null}
                                >
                                <MenuItem onClick={() => handleDownload(targetData.targetData)}>Download</MenuItem>
                            </Menu>
                            </Accordion>
                        ))
                    }
				</Collapse>
                <ImageVideoDialog 
                    open={!!target} 
                    handleClose={handleClose} 
                    name={mediaData?.name} 
                    mediaData={mediaData?.targetData}
                    startElement={target}
                />
			</Grid>
		</Grid>
	)
}

ImageVideoTable.propTypes = {
	notesData: PropTypes.array,
	loopKey: PropTypes.string.isRequired,
	graphElements: PropTypes.object.isRequired,
};

export default ImageVideoTable;
