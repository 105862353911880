import agent from './agent';

export const getSetting = (resourceType, resourceId, type) => {
    return agent.get('/setting', {
        params: {
            resourceType,
            resourceId,
            type,
        }
    });
}

export const createSetting = (data) => {
    return agent.post('/setting/create', data);
}

export const updateSetting = (data) => {
    return agent.put('/setting/update', data);
}
