import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Button, DialogTitle, DialogContent, DialogActions, Dialog, Chip, FormControlLabel, Checkbox,
    Select, ListItem, InputLabel, TextField, Grid, IconButton, FormHelperText, InputAdornment,
    Popover, CircularProgress, Tooltip, ListItemText, ListItemSecondaryAction, Collapse
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { connect } from 'react-redux';
import cloneDeep from 'lodash.clonedeep';

import { access, roleNames } from '../../constants';
import emailIsValid from '../../utils/validateEmail';
import UserClientConnection from './UserClientConnection';
import ClientInfoPopover from './ClientInfoPopover';
import { getUser, getTitles, updateTitle, deleteTitle, resetUserPassword } from '../../services/UserService';
import { getSitesDropdown } from '../../services/SiteService';
import { setLoading, setGlobalDialog, setSnackbar } from '../../store/general/actions';
import { CanUserFunc } from '../../components/CanUser';
import EditTitle from './EditTitle';
import LoadingCheck from '../../assets/progress/LoadingCheck';
import passwordIsValid from '../../utils/validatePassword';
import RolePicker from '../../assets/input/RolePicker';

const useStyles = makeStyles(theme => ({
    closeIconBtn: {
        padding: '0px 4px',
        color: '#0FB99E',
    },
    leftWrapper: {
        paddingRight: 19,
        marginBottom: 28,
    },
    rightWrapper: {
        paddingLeft: 19,
        marginBottom: 28,
    },
    largeWrapper: {
        marginBottom: 28,
    },
    input: {
        width: '100%',
    },
    multilineInput: {
        minHeight: 90,
    },
    multilineInputLarge: {
        minHeight: 225,
    },
    saveBtn: {
        padding: '6px 40px',
    },
    targetBtn: {
        padding: '6px 50px',
    },
    addNewMenu: {
        backgroundColor: '#0FB99E',
        color: '#ffffff',
        '&:hover': { backgroundColor: '#00826d' },
        fontSize: 14,
    },
    closeAdornment: {
        padding: 8,
    },
    chipBox: {
        padding: 10,
        border: '1px solid rgba(0, 0, 0, 0.23)',
        borderRadius: 5,
    },
    popoverPaper: {
        padding: 20,
        width: '40vw',
		'@media (max-width: 768px)': {
            width: '70vw',
        },
    },
    buttonChip: {
        border: '0 !important',
        color: '#ffffff',
        backgroundColor: '#0FB99E !important',
        '&:hover': { backgroundColor: '#00826d !important' },
    },
    iconBtn: {
        padding: 2,
    },
    listItemText: {
        fontSize: 14,
    },
    listItem: {
        padding: '0px 16px',
    },
    titleSelect: {
        padding: '8px 32px 8px 14px',
    },
    resetPassword: {
        fontSize: '0.75rem',
        cursor: 'pointer',
        color: 'blue',
        textDecoration: 'underline',
    },
    infoIcon: {
      color: '#0FB99E',
      fontSize: 21,
      marginLeft: 5,
      marginBottom: -5,
    },
}));

const defaultUser = {
    firstName: '',
    lastName: '',
    username: '',
    title: 0,
    email: '',
    phone: '',
    roles: [],
    branches: [],
    patients: [],
    note: '',
};

const maxLength = {
    firstName: 100,
    lastName: 100,
    username: 100,
    email: 100,
    phone: 100,
    note: 1024,
}

const NewUser = (props) => {
    const classes = useStyles();
    const { onClose, onSave, open, selectedUser, loading, setLoading, user, setGlobalDialog, setSnackbar, fetchUser } = props;
    const [newUser, setNewUser] = useState(cloneDeep(defaultUser));
    const [titles, setTitles] = useState([]);
    const [createNewTitle, setCreateNewTitle] = useState(false);
    const [error, setError] = useState(false);
    const [hoverTitleId, setHoverTitleId] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);
    const [updateTitleOpen, setUpdateTitleOpen] = useState(false);

    const [sites, setSites] = useState([]);

    const [anchorEl, setAnchorEl] = useState({
        sites: null,
    });
    const [selectedClientId, setSelectedClientId] = useState(null);

    useEffect(() => {
        setError(false);
        setCreateNewTitle(false);
        if (open) {
            setLoading('loadingUser');
            Promise.all([
                getTitles(),
                CanUserFunc(access.sites.view) ? getSitesDropdown() : { data: { data: user.branchNames } },
            ]).then((values) => {
                setTitles(values[0].data.data.titles);
                setSites(values[1].data.data);

                if (!!selectedUser) {
                    getUser(selectedUser.id).then((res) => {
                        let user = res.data.user;
                        user = {
                            ...user,
                            branches: user.branches.map(e => e.id),
                            companyId: selectedUser.branches[0]?.company?.id,
                            patients: user.patients,
                            title: user.title ? user.title.id : 0,
                            phone: user.mobilePhone,
                        }
                        setNewUser(user)
                        setLoading('loadingUser', false);
                    });
                } else {
                    setNewUser(cloneDeep(defaultUser))
                    setLoading('loadingUser', false);
                }
            })
        }

    }, [open])

    const branchChanged = () => {
        const userPatients = newUser.patients.filter(patient => newUser.branches.some(branch => branch === patient.branch.id));
        setNewUser({ ...newUser, patients: userPatients });
    }

    const openAnchorEl = (type, el) => {
        setAnchorEl({ ...anchorEl, [type]: el });
    }

    const closeAnchorEl = type => () => {
        setAnchorEl({ ...anchorEl, [type]: null });
    }

    const handleCancel = () => {
        onClose();
    };

    const handleCheck = (field, item) => event => {
        const array = newUser[field];
        if (event.target.checked) {
            array.push(item);
        } else {
            array.splice(array.indexOf(item), 1);
        }
        setNewUser({ ...newUser, [field]: array });
        if (field === 'branches') {
            branchChanged();
        }
    }

    const handleDeleteClient = (patient) => () => {
        const array = newUser.patients;
        array.splice(array.indexOf(patient), 1);
        setNewUser({ ...newUser, patients: array });
    }

    const handleClickClient = patient => {
        const array = newUser.patients;
        const findClient = array.find(e => e.id === patient.id);
        if (findClient) {
            array.splice(array.indexOf(findClient), 1);
        } else {
            array.push(patient);
        }
        setNewUser({ ...newUser, patients: array });
    }

    const handleChange = field => event => {
        let value = event.target.value
        if (maxLength[field]) {
            if (value.length > maxLength[field]) {
                value = value.substring(0, maxLength[field]);
            }
        }
        setNewUser({ ...newUser, [field]: value });
    }

    const handleChangePhone = event => {
        let filteredInput = event.target.value.replace(/([^0-9+]+)/g, '');
        const firstChar = filteredInput.substring(0, 1);
        const subbedInput = filteredInput.substring(1);
        filteredInput = firstChar + subbedInput.replace('+', '');
        setNewUser({ ...newUser, phone: filteredInput });
    }

    const handleChangeTitle = event => {
        const { value } = event.target;
        if (value === 'new-title') {
            setCreateNewTitle(true);
            setNewUser({ ...newUser, title: '' });
        } else {
            setNewUser({ ...newUser, title: value });
        }
        setHoverTitleId(null);
    }

    const handleCancelNewTitle = () => {
        setCreateNewTitle(false);
        setNewUser({ ...newUser, title: 0 });
    }

    const handleSave = () => {
        const isCompanyAdmin = newUser.roles.some(e => e.name === roleNames.ORG_ADMIN);
        if (!newUser.firstName.trim()
            || !newUser.lastName.trim()
            || !newUser.username.trim()
            || !newUser.email.trim()
            || !emailIsValid(newUser.email)
            || !newUser.roles.length
            || (!newUser.branches.length && !isCompanyAdmin)
        ) {
            setError(true);
            return;
        }

        if (!!selectedUser) {
            if (!!newUser.password && !passwordIsValid(newUser.password)) {
                setError(true);
                return;
            }
        }

        if (isCompanyAdmin) {
            newUser.branches = sites.map(e => e.id);
        }

        onSave({
            ...newUser,
            roles: newUser.roles.map(r => r.id),
            firstName: newUser.firstName.trim(),
            lastName: newUser.lastName.trim(),
            email: newUser.email.trim(),
            title: createNewTitle ? newUser.title.trim() : newUser.title,
            patients: newUser.patients.map(e => e.id),
        });
    }

    const openClientInfo = id => event => {
        setSelectedClientId(id);
        openAnchorEl('patientInfo', event.currentTarget);
    }

    const openEditTitle = title => () => {
        setSelectedTitle(title);
        setUpdateTitleOpen(true);
    }

    const handleUpdateTitle = title => {
        setLoading('updateTitle');
        setUpdateTitleOpen(false);
        updateTitle(title).then(() => {
            getTitles().then((res) => {
                setTitles(res.data.data.titles);
                setLoading('updateTitle', false)
                setSnackbar('success', 'Title updated!');
                setSelectedTitle(null);
            })
        })
    }

    const openDeleteTitle = title => () => {
        setGlobalDialog(
            `Delete title "${title.name}"?`,
            `Deleted title cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    handleDeleteTitle(title);
                }
            },
        )
    }

    const handleDeleteSite = (item) => () => {
        const array = newUser.branches.splice(newUser.branches.indexOf(item), 1);
        setNewUser({ ...newUser, branches: array });
        branchChanged();
    }

    const handleDeleteTitle = title => {
        setSelectedTitle(title);
        setLoading('updateTitle');
        deleteTitle(title.id).then(() => {
            getTitles().then((res) => {
                setTitles(res.data.data.titles);
                setSelectedTitle(null);
                setLoading('updateTitle', false);
                setSnackbar('success', 'Title deleted!');
                fetchUser();
            })
        }).catch((err) => {
            setSelectedTitle(null);
            setLoading('updateTitle', false);
        })
    }

    const handleResetPassword = () => {
        setGlobalDialog(
            `Reset user password?`,
            `User will receive an email to reset their password.`,
            (answer) => {
                if (answer) {
                    setLoading('editUser');
                    resetUserPassword(selectedUser.id).then(() => {
                        setLoading('editUser', false);
                        setSnackbar('success', 'Reset password email sent!');
                    });
                }
            },
        )
    }

    const getPasswordText = () => {
        if (!selectedUser) return;

        if (!error) {
            return ' ';
        }
        if (error && !newUser.password) {
            return ' ';
        }
        if (error && !passwordIsValid(newUser.password)) {
            return "The password must be at least 8 characters long including one upper case letter and a number."
        }
    }

    const passwordError = error && (!!newUser.password && !passwordIsValid(newUser.password));
    const isCompanyAdmin = newUser.roles.some(e => e.name === roleNames.ORG_ADMIN);
    const companyAdminSites = useMemo(() => (sites.map(e => e.id)), [sites]);
    return (
        <Dialog disableBackdropClick disableEscapeKeyDown maxWidth="md" open={open}>
            <DialogTitle>
                <Grid container>
                    <Grid item xs={6}>
                        {selectedUser ? 'Edit User' : 'New User'}
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <IconButton className={classes.closeIconBtn} onClick={handleCancel}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>

            <DialogContent>
                {loading.loadingUser ?
                    <Grid container justify="center" alignItems="center" style={{ width: 400, height: 400 }}>
                        <CircularProgress size={250} />
                    </Grid>
                    :
                    <Grid container>
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <InputLabel required>First Name</InputLabel>
                            <TextField
                                variant="outlined"
                                value={newUser.firstName}
                                onChange={handleChange('firstName')}
                                error={error && !newUser.firstName.trim()}
                                helperText={error && !newUser.firstName.trim() ? "First name can't be empty" : ' '}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel required>Last Name</InputLabel>
                            <TextField
                                variant="outlined"
                                value={newUser.lastName}
                                onChange={handleChange('lastName')}
                                error={error && !newUser.lastName.trim()}
                                helperText={error && !newUser.lastName.trim() ? "Last name can't be empty" : ' '}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <InputLabel required>Username</InputLabel>
                            <TextField
                                variant="outlined"
                                value={newUser.username}
                                onChange={handleChange('username')}
                                error={error && !newUser.username.trim()}
                                helperText={error && !newUser.username.trim() ? "Username can't be empty" : ' '}
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel>Title</InputLabel>
                            {createNewTitle ?
                                <TextField
                                    variant="outlined"
                                    value={newUser.title}
                                    onChange={handleChange('title')}
                                    className={classes.input}
                                    autoFocus
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton tabIndex={-1} className={classes.closeAdornment} onClick={handleCancelNewTitle}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                :
                                <Select
                                    variant="outlined"
                                    IconComponent={KeyboardArrowDownIcon}
                                    className={classes.input}
                                    value={newUser.title}
                                    onChange={handleChangeTitle}
                                    classes={{
                                        outlined: classes.titleSelect
                                    }}
                                >
                                    <ListItem
                                        button
                                        value={0}
                                        className={classes.listItem}
                                    >
                                        <ListItemText classes={{ primary: classes.listItemText }}>No Title</ListItemText>
                                    </ListItem>
                                    {titles.map(title => (
                                        <ListItem
                                            button
                                            key={title.id}
                                            value={title.id}
                                            onMouseEnter={() => setHoverTitleId(title.id)}
                                            onMouseLeave={() => setHoverTitleId(null)}
                                            className={classes.listItem}
                                        >
                                            <ListItemText classes={{ primary: classes.listItemText }}>
                                                {title.name}
                                                <LoadingCheck show={loading.updateTitle && selectedTitle === title} size={16} />
                                            </ListItemText>
                                            {hoverTitleId === title.id &&
                                                <ListItemSecondaryAction
                                                    onMouseEnter={() => setHoverTitleId(title.id)}
                                                    onMouseLeave={() => setHoverTitleId(null)}
                                                >
                                                    <IconButton className={classes.iconBtn} onClick={openEditTitle(title)} tabIndex={-1}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton className={classes.iconBtn} onClick={openDeleteTitle(title)} tabIndex={-1}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </ListItemSecondaryAction>
                                            }
                                        </ListItem>
                                    ))}
                                    <ListItem button className={classes.addNewMenu} value="new-title">Add New Title</ListItem>
                                </Select>
                            }
                        </Grid>
                        <Grid item xs={6} className={classes.leftWrapper}>
                            <InputLabel required>Email</InputLabel>
                            <TextField
                                variant="outlined"
                                value={newUser.email}
                                onChange={handleChange('email')}
                                error={error && (!newUser.email.trim() || !emailIsValid(newUser.email))}
                                helperText={error ?
                                    ((!newUser.email.trim() && "Email can't be empty")
                                    || (!emailIsValid(newUser.email) ? 'Email is invalid' : ' '))
                                    : ' '
                                }
                                className={classes.input}
                            />
                        </Grid>
                        <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel>Phone</InputLabel>
                            <TextField
                                variant="outlined"
                                value={newUser.phone}
                                onChange={handleChangePhone}
                                className={classes.input}
                            />
                        </Grid>
                        {selectedUser &&
                            <React.Fragment>
                                <Grid item xs={6} className={classes.leftWrapper}>
                                    <InputLabel>
                                        New Password
                                        <Tooltip title='The password must be at least 8 characters long including one upper case letter and a number.'>
                                            <InfoIcon className={classes.infoIcon} />
                                        </Tooltip>
                                    </InputLabel>
                                    <TextField
                                        autoComplete="new-password"
                                        variant="outlined"
                                        value={newUser.password}
                                        onChange={handleChange('password')}
                                        className={classes.input}
                                        type="password"
                                        error={passwordError}
                                        helperText={getPasswordText()}
                                    />
                                    <span id="user-email-reset-password-link" className={classes.resetPassword} onClick={() => handleResetPassword()}>
                                        or email the user a link to reset password instead.
                                    </span>
                                </Grid>
                                <Grid item xs={6}></Grid>
                            </React.Fragment>
                        }
                        <RolePicker 
                            user={newUser} 
                            onChange={(array) => setNewUser({ ...newUser, roles: array })} 
                            title="Roles"   
                            error={error} 
                        />
                        {!isCompanyAdmin && <Grid item xs={6} className={classes.rightWrapper}>
                            <InputLabel required>Sites</InputLabel>
                            <Grid container className={classes.chipBox} style={error && !newUser.branches.length ? { borderColor: '#f44336' } : {}}>
                                {newUser.branches.map(site => {
                                    const selectedSite = sites.find(e => e.id === site);
                                    if (selectedSite) {
                                        return (
                                            <Chip
                                                variant="outlined"
                                                size="small"
                                                label={selectedSite.name}
                                                key={site}
                                                onDelete={handleDeleteSite(site)}
                                            />
                                        )
                                    } else return null;
                                })}
                                {!CanUserFunc(access.sites.view) && newUser.branches.length > sites.length &&
                                    <Tooltip arrow title="User belongs to other sites that you don't have access to.">
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label="Other Sites"
                                        />
                                    </Tooltip>
                                }
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label="Add Sites"
                                    onClick={(e) => openAnchorEl('sites', e.currentTarget)}
                                    className={classes.buttonChip}
                                />
                            </Grid>
                            <FormHelperText error={error && !newUser.branches.length}>
                                {error && !newUser.branches.length ? 'Please choose at least one site' : ' '}
                            </FormHelperText>
                        </Grid>
                        }
                        <Grid item xs={12} className={classes.largeWrapper}>
                            <InputLabel>Clients</InputLabel>
                            <Grid container className={classes.chipBox}>
                                {newUser.patients.map(patient => {
                                    const isLinked = patient.id === newUser.patientId;
                                    return (
                                        <Chip
                                            variant="outlined"
                                            size="small"
                                            label={`${patient.firstName} ${patient.lastName}${isLinked ? ' (Linked)' : ''}`}
                                            key={patient.id}
                                            onClick={openClientInfo(patient.id)}
                                            onDelete={isLinked ? null : handleDeleteClient(patient)}
                                        />
                                    )
                                }
                                )}
                                <Chip
                                    variant="outlined"
                                    size="small"
                                    label="Add Clients"
                                    onClick={(e) => openAnchorEl('patients', e.currentTarget)}
                                    className={classes.buttonChip}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={classes.largeWrapper}>
                            <InputLabel>Note</InputLabel>
                            <TextField
                                variant="outlined"
                                multiline
                                value={newUser.note}
                                onChange={handleChange('note')}
                                className={classes.input}
                                InputProps={{
                                    classes: {
                                        root: classes.multilineInputLarge,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                }
            </DialogContent>

            <DialogActions>
                <Grid container>
                    <Grid item xs={6}>
                        <Button className={classes.saveBtn} onClick={handleCancel}>Cancel</Button>
                    </Grid>
                    <Grid item xs={6} container justify="flex-end">
                        <Button
                            className={classes.saveBtn}
                            onClick={handleSave}
                            disabled={loading.loadingUser || loading.newUser || loading.editUser}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>

            <Popover
                open={!!anchorEl.sites}
                anchorEl={anchorEl.sites}
                onClose={closeAnchorEl('sites')}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.popoverPaper,
                }}
            >
                <Grid container>
                    {sites.map(site => (
                        <Grid item xs={6} sm={3} key={site.id}>
                            <FormControlLabel
                                control={<Checkbox color="primary" checked={newUser.branches.includes(site.id)} onChange={handleCheck('branches', site.id)} />}
                                label={site.name}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Popover>

            <UserClientConnection
                anchorEl={anchorEl.patients}
                onClose={closeAnchorEl('patients')}
                sites={isCompanyAdmin ? companyAdminSites : newUser.branches}
                onClick={handleClickClient}
                selectedClients={newUser.patients}
                siteList={sites}
            />

            <ClientInfoPopover
                anchorEl={anchorEl.patientInfo}
                onClose={closeAnchorEl('patientInfo')}
                clientId={selectedClientId}
            />

            <EditTitle
                open={updateTitleOpen}
                title={selectedTitle}
                onClose={() => setUpdateTitleOpen(false)}
                updateTitle={handleUpdateTitle}
            />
        </Dialog>
    )
}

NewUser.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedUser: PropTypes.object,
    loading: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    fetchUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
    user: state.auth.user,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog, setSnackbar })(NewUser)
