import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, InputLabel, TextField, FormHelperText, Select, MenuItem, FormControlLabel, Checkbox, Typography, Divider, Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { maintenanceSettingTypes, maintenanceSettingTypeEnum } from '../../constants';
import { setUser } from '../../store/auth/actions';
import { setSnackbar } from '../../store/general/actions';

//Services
import * as SettingService from '../../services/SettingService';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 100px',
        minWidth: 850,
    },
    plus: {
        width: 14,
        marginLeft: 5,
    },
    btnWrapper: {},
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    input: {
        width: '100%',
    },
    formControlLabelText: {
        color: '#000',
        display: 'block',
        fontSize: '14px',
        fontWeight: 500,
    },
    buttonContainer: {
        marginTop: '20px',
    },
    inputLabel: {
        margin: 0,
        marginBottom: '16px',
    },
    title: {
        fontSize: '18px',
        fontWeight: 'bold',
    },
    divider: {
        width: '100%',
        marginTop: '15px',
    },
}));

const Maintenance = (props) => {
    const classes = useStyles();
    const { user, setSnackbar, setUser } = props;
    const [maintenanceSetting, setMaintenanceSetting] = useState({
        type: maintenanceSettingTypes[0],
        interval: 1,
        reappearIfUncollected: true,
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [error, setError] = useState(false);
    const [newSetting, setNewSetting] = useState(false);
    const [settingSynced, setSettingSynced] = useState(null);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'maintenanceSetting')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setMaintenanceSetting(res.data?.data?.data);
                    } else {
                        setMaintenanceSetting({
                            type: maintenanceSettingTypes[0],
                            interval: 1,
                            reappearIfUncollected: true,
                        });
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    }, []);

    const handleChange = (field) => (event) => {
        setIsUpdating(true);
        if (field === 'reappearIfUncollected') {
            setMaintenanceSetting({ ...maintenanceSetting, [field]: event.target.checked });
            return;
        }
        const value = event.target.type === 'number' ? Math.floor(Number(event.target.value)) : event.target.value;
        setMaintenanceSetting({ ...maintenanceSetting, [field]: value });
    };

    const handleSave = () => {
        let error = false;

        if (maintenanceSetting.interval <= 0) {
            error = true;
        }

        if (error) {
            setError(true);
            return;
        }

        setSettingSynced(false);

        if (newSetting) {
            SettingService.createSetting({
                type: 'maintenanceSetting',
                data: { ...maintenanceSetting },
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setNewSetting(false);
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        } else {
            SettingService.updateSetting({
                type: 'maintenanceSetting',
                data: { ...maintenanceSetting },
                resourceType: 'user',
                resourceId: user.id,
            }).then((res) => {
                if (res.status === 200) {
                    setIsUpdating(false);
                    getUserSettings();
                }
            });
        }
    };

    const getUserSettings = () => {
        SettingService.getSetting('user', user.id, 'maintenanceSetting')
            .then((res) => {
                if (res.status === 200) {
                    if (res.data?.data?.data) {
                        setMaintenanceSetting(res.data?.data?.data);

                        const userSettings = user.settings;
                        userSettings.maintenanceSetting = res.data?.data?.data;
                        const updatedUser = { ...user, settings: userSettings };
                        setUser(updatedUser);

                        setSnackbar('success', 'Updated Maintenance Setting!');
                    } else {
                        setMaintenanceSetting({});
                        setNewSetting(true);
                    }
                }
            })
            .finally(() => {
                setSettingSynced(true);
            });
    };

    return (
        <Grid item container xs={7} className={classes.root}>
            <Grid item xs={12}>
                <Typography id="maintenance-setting-section-header" className={classes.title}>Maintenance Setting</Typography>
            </Grid>
            {!settingSynced ? (
                <Grid item container xs={12} direction='row' justify='center'>
                    <Box my={4}>
                        <CircularProgress size={100} />
                    </Box>
                </Grid>
            ) : (
                <Grid item xs={12} className={classes.btnWrapper} container>
                    <Grid item xs={4} style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                        <InputLabel id="maintenance-setting-interval-field-label" className={classes.inputLabel}>Maintenance targets show up on the mobile every</InputLabel>
                    </Grid>
                    <Grid item container xs={8}>
                        <Grid item container xs={12}>
                            <Grid item xs={2}>
                                <TextField id="maintenance-setting-interval-number-field" variant='outlined' type='number' value={maintenanceSetting?.interval?.toString() || '1'} onChange={handleChange('interval')} className={classes.input} InputProps={{ inputProps: { min: 1, style: { textAlign: 'right' } } }} />
                            </Grid>
                            <Grid item xs={4} style={{ marginLeft: '10px' }}>
                                <Select id="maintenance-setting-interval-date-field" variant='outlined' IconComponent={KeyboardArrowDownIcon} className={classes.input} error={error && !maintenanceSetting?.type} value={maintenanceSetting?.type} onChange={handleChange('type')}>
                                    {maintenanceSettingTypes.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {maintenanceSettingTypeEnum[type]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <FormHelperText id="maintenance-setting-interval-field-helper-text" error={error && maintenanceSetting && maintenanceSetting?.interval <= 0}>{error && maintenanceSetting && maintenanceSetting?.interval <= 0 ? 'Negative value or 0 is not allowed' : ' '}</FormHelperText>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{}}>
                        <FormControlLabel
                            id="maintenance-setting-reappear-field-label"
                            classes={{
                                label: classes.formControlLabelText,
                            }}
                            control={<Checkbox id="maintenance-setting-reappear-field" checked={maintenanceSetting?.reappearIfUncollected} onChange={handleChange('reappearIfUncollected')} color='primary' />}
                            label='Make the target appear at the next session/day again if no data is collected'
                        />
                    </Grid>
                    <Grid item container xs={12} justify='space-between' direction='row-reverse' className={classes.buttonContainer}>
                        <Button id="maintenance-setting-save" onClick={handleSave} variant='contained' disabled={!isUpdating}>
                            Save
                        </Button>
                    </Grid>
                </Grid>
            )}

            <Divider className={classes.divider} />
        </Grid>
    );
};

Maintenance.propTypes = {
    setSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { setSnackbar, setUser })(Maintenance);
