import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, CircularProgress, makeStyles, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';

import { dataTypeDict } from '../../constants';

const dataType = {
    ...dataTypeDict,
    correctIncorrect: '+/-',
};

const useStyles = makeStyles(theme => ({
    tableHeadRow: {
        fontWeight: 500,
        fontSize: 16,
        '& th': {
            verticalAlign: 'middle',
        },
        backgroundColor: '#FFFFFF'
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    table: {
        position: 'relative',
        tableLayout: 'auto',
    },
    tableCell: {
        fontSize: 14,
        border: 'none',
        padding: '15px 12px',
        verticalAlign: 'middle',
    },
    progressWrapper: {
        paddingTop: 150,
        height: 550,
        backgroundColor: '#fff',
    },
    noTarget: {
        textAlign: 'center',
        marginTop: '30vh',
        fontSize: 36,
    },
    defaultRowBackground: {
        backgroundColor: '#FFFFFF',
    },
    intervention: {
        backgroundColor: 'rgba(19, 137, 222, 0.15)',
    },
    baseline: {
        backgroundColor: '#F4F4F4',
    },
    generalization: {
        backgroundColor: 'rgba(222, 202, 19, 0.15)',
    },
    maintenance: {
        backgroundColor: 'rgba(15, 185, 158, 0.15)',
    },
    checkbox: {
        padding: 0,
    },
    hidden: {
        visibility: 'hidden',
    },
    root: {
        position: 'relative',
        overflow: 'auto'
    },
}));

const TargetGrid = (props) => {
    const { targetLoading, onSelect, selectedTargets } = props;
    const classes = useStyles();
    const [targets, setTargets] = useState([]);

    useEffect(() => {
        setTargets(props.targets);
    }, [props.targets])

    return (
        <div className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow className={classes.tableHeadRow}>
                            <TableCell align="center" style={{ width: '10%' }}>
                                <Checkbox
                                    id="datasheet_table_select_all"
                                    color="primary"
                                    className={clsx(classes.checkbox, !onSelect && classes.hidden)}
                                    checked={targets.every(target => selectedTargets[target.id])}
                                    onChange={onSelect && onSelect('all')}
                                />
                            </TableCell>
                            <TableCell align="center" style={{ width: '30%' }}>Program</TableCell>
                            <TableCell align="center" style={{ width: '30%' }}>Target</TableCell>
                            <TableCell align="center" style={{ width: '15%' }}>Phase</TableCell>
                            <TableCell align="center" style={{ width: '15%' }}>Data</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody
                        >
                            {targets && targets.map(target => (
                                <TableRow className={clsx(
                                        target.status === 'inactive' || target.status === 'mastered' ? 
                                        classes.defaultRowBackground : classes[target.phase?.toLowerCase()],
                                    )}
                                >
                                    <TableCell className={classes.tableCell} align="center">
                                        <Checkbox
                                            id={`datasheet_table_select_${target.id}`}
                                            color="primary"
                                            className={clsx(classes.checkbox, !onSelect && classes.hidden)}
                                            checked={selectedTargets[target.id] || false}
                                            onChange={onSelect && onSelect(target.id)}
                                        />
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">{target.program?.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="center">{target.name}</TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        <span className={classes.capitalize}>
                                            {target.status === 'inactive' || target.status === 'mastered' ? '- - -' : target.phase || '- - -'}
                                        </span>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">{dataType[target.dataType]}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                </Table>
            {targetLoading &&
                <Grid className={classes.progressWrapper} container justify="center">
                    <CircularProgress size={300} />
                </Grid>
            }
            {!targetLoading && !targets.length && <p id="datasheet_list_no_target_message" className={classes.noTarget}>No target to show</p>}
        </div>
    )
}

TargetGrid.propTypes = {
    targets: PropTypes.array.isRequired,
    targetLoading: PropTypes.bool.isRequired,
    onSelect: PropTypes.func,
    selectedTargets: PropTypes.object
}

const mapStateToProps = state => ({
    targetLoading: state.general.targetLoading,
})

export default connect(mapStateToProps, {})(TargetGrid)