import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import subDays from 'date-fns/subDays';
import subMonths from 'date-fns/subMonths';
import isAfter from 'date-fns/isAfter';
import format from 'date-fns/format';
import { connect } from 'react-redux';
import domToImage from 'dom-to-image';
import { saveAs } from 'file-saver';
import debounce from 'awesome-debounce-promise';

import CanUser from '../CanUser';
import { getTargetDataList } from '../../store/data/actions';
import GraphWrapper from './Graphs/GraphWrapper';
import FrequencyGraph from './Graphs/FrequencyGraph';
import CorrectIncorrectGraph from './Graphs/CorrectIncorrectGraph';
import QuantityGraph from './Graphs/QuantityGraph';
import Notes from './Notes';
import { Box, CircularProgress } from '@material-ui/core';
import DurationGraph from './Graphs/DurationGraph';
import IntervalGraph from './Graphs/IntervalGraph';
import TextTable from './TextTable';
import { statuses, access, localStorageEnum, dateRanges, elementsOptions, sheetNames } from '../../constants';
import {
    downloadTargetDataByResourceId,
    getMasteredTargetListByResourceId,
    getUsersByTargetData,
} from '../../services/TargetService';
import PromptGraph from './Graphs/PromptGraph';
import RatingScaleGraph from './Graphs/RatingScaleGraph';
import RateGraph from './Graphs/RateGraph';
import DropdownButton from '../../assets/button/DropdownButton';
import FilterDropdownButton from '../../assets/button/FilterDropdownButton';
import capitalize from '../../utils/capitalize';
import urlDownload from '../../utils/urlDownload';
import ImageVideoTable from './ImageVideoTable';
import { generateDomainTicks } from './Graphs/GraphSettings';
import DateRangePicker from '../../assets/input/DateRangePicker';
import ProbeGraph from './Graphs/ProbeGraph';
import EditPhases from './EditPhases';
import CategoryGraph from './Graphs/CategoryGraph';
import NoProgram from './NoProgram';
import * as SettingService from '../../services/SettingService';
import TooltipedIconButton from '../../assets/button/TooltipedIconButton';
import { getCategoryDataListByResourceId } from '../../services/TargetService';
import dateFormatter from '../../utils/dateFormatter';
import { sessionDataSyncInterval } from "../../constants";
import { isTargetDataAvailable } from '../../utils/targetDataAnalyser';
import ProgramSelect from '../ProgramSelect';
import { Tune } from '@material-ui/icons';

// IMPORTANT
// To understand how date works with recharts and how this workaround worked
// recharts only supports 2 type of data, number and category.
// since we want to display date, category won't work since it won't fill in if we have any missing dates
// such we are currently using number data type.
// Since we only care about showing dates (e.g. 21-Nov) we first convert the date to miliseconds (from 1970)
// we then divide the miliseconds by 86400000, which is miliseconds in a day, and we have days (from 1970)
// as we are using days, the graph can render and scale linearly (by 1 day or more)
// and if we need to display the data to end user, we need to reconvert the days back to miliseconds, back to date.
// this workaround is used because the limitations of recharts on handling dates.

// IMPORTANT #2


const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: 15,
        marginBottom: 15,
        border: '1px solid #D5D5D5',
        boxSizing: 'border-box',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: 5,
    },
    graphWrapper: {
        width: '100%',
        paddingTop: 5,
    },
    wrapper: {
        padding: '0px 20px',
    },
    filterWrapper: {
        padding: '0px 20px',
    },
    filterWrapperMobile: {
        position: 'absolute',
        top: 50,
        right: 0,
        minWidth: 260,
        backgroundColor: '#ffffff',
        padding: 10,
        borderRadius: 5,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        zIndex: 99,
    },
    filterButton: {
        marginRight: 10,
    },
    statusTab: {
        display: 'inline-block',
        textTransform: 'capitalize',
        paddingLeft: 10,
        paddingRight: 10,
        fontWeight: 'bold',
        cursor: 'pointer',
        '&:hover': { color: '#0FB99E' },
    },
    tabDivider: {
        borderLeft: '1px solid #D5D5D5',
    },
    tabActive: {
        borderBottom: '1px solid #0FB99E',
    },
    tabUnderline: {
        textDecoration: 'none',
    },
    datePicker: {
        marginLeft: 15,
        width: '45%',
    },
    dateInput: {
        color: '#53545E',
        padding: 10.5,
    },
    dateAdornment: {
        margin: 0,
        padding: 0,
    },
    clearIcon: {
        color: '#000000',
    },
    progressWrapper: {
        marginTop: 100,
        height: 400,
    },
    root: {
        paddingBottom: 50,
    },
    popoverPaper: {
        padding: 20,
    },
    labels: {
        textTransform: 'capitalize',
    },
    filter: {
        marginRight: 15,
        marginBottom: 10,
    },
    currentlyShowing: {
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 0,
    },
    noGraph: {
        textAlign: 'center',
        margin: 5,
        fontSize: 16,
        color: 'red',
    },
    domainStatusWrapper: {
        backgroundColor: '#F4F4F4',
    },
    domainWrapper: {
        padding: '12px 0px 12px 30px',
    },
    currentDomain: {
        color: '#30313E',
        fontSize: 18,
        fontWeight: 'bold',
        display: 'inline-block',
        marginRight: 16,
    },
    sessionSyncBtn: {
        marginRight: 10,
    },
    mobileProgramSelect: {
        marginRight: 'auto',
        maxHeight: 37,
        display: 'flex',
        alignItems: 'center',
        '& div': {
            margin: 0,
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
            borderRadius: 18,
            '& p': {
                maxWidth: 175,
                '& span': {
                    maxWidth: 155,
                }
            }
        }
    }
}));

const displayFilterOptions = [
    {
        label: 'Combine Targets',
        value: 1,
        tooltip:
            'Displays data from multiple targets on the same graph. This option is available for +/-, frequency, and duration.',
    },
    {
        label: 'Separate Targets',
        value: 2,
        tooltip: 'Shows phase change lines on the graph.',
    },
];

const statusList = statuses.map((status) => ({ label: capitalize(status), value: status }));
const debouncedGetUsers = debounce(getUsersByTargetData, 500);
const debouncedGetMasteredTargets = debounce(getMasteredTargetListByResourceId, 500);

const Analysis = (props) => {
    const classes = useStyles();
    const {
        targetData,
        selection,
        programs,
        domains,
        targetLoading,
        loading,
        patient,
        getTargetDataList,
        targets,
        user,
        isMobile,
    } = props;

    const [startDate, setStartDate] = useState(subDays(new Date(), 14));
    const [endDate, setEndDate] = useState(new Date());
    const [usePercent, setUsePercent] = useState({});
    const [useTotal, setUseTotal] = useState({});
    const [switchGraphs, setSwitchGraphs] = useState({});
    const [useCumulative, setUseCumulative] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [elements, setElements] = useState([]);
    const [dateRange, setDateRange] = useState(0);
    const [userFilter, setUserFilter] = useState(0);
    const [displayFilter, setDisplayFilter] = useState(1);
    const [graphDomain, setGraphDomain] = useState([]);
    const [graphTicks, setGraphTicks] = useState([]);
    const [rateUnit, setRateUnit] = useState({});
    const [categoryTargetData, setCategoryTargetData] = useState(targetData);
    const [categorySetting, setCategorySetting] = useState({});
    const [customDate, setCustomDate] = useState({
        startDate: undefined,
        endDate: undefined,
    });
    const [showDownloadOptions, setShowDownloadOptions] = useState(false);
    const [shouldLoad, setShouldLoad] = useState(false);
    const [openDatePicker, setOpenDatePicker] = useState(false);
    const [editPhaseTargets, setEditPhaseTargets] = useState([]);
    const [masteredTargets, setMasteredTargets] = useState([]);
    const [filter, setFilter] = useState(0);
    const [filterSet, setFilterSet] = useState(false);
    const [patientId, setPatientId] = useState(0);
    const [refreshCount, setRefreshCount] = useState(0);
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        setCategoryTargetData(targetData?.slice());
    }, [targetData]);

    useEffect(() => {
        SettingService.getSetting('user', user.id, 'filter')
            .then((res) => {
                if (res.status === 200 && res.data?.data?.data) {
                    return setFilter(res.data?.data?.data);
                }
                setFilter([]);
            });

        let analysisFilters = localStorage.getItem(localStorageEnum.analysisFilters);
        let dateFilters = localStorage.getItem(localStorageEnum.dateFilters);
        if (analysisFilters && dateFilters) {
            analysisFilters = JSON.parse(analysisFilters);
            dateFilters = JSON.parse(dateFilters);
            setStatus(analysisFilters.status);
            setUserFilter(analysisFilters.userFilter ?? 0);
            setDisplayFilter(analysisFilters.displayFilter ?? 1);
            setPatientId(analysisFilters.patientId);
            setUsePercent({ ...usePercent, ...analysisFilters.usePercent });
            setSwitchGraphs({ ...switchGraphs, ...analysisFilters.switchGraphs });
            setUseCumulative({ ...useCumulative, ...analysisFilters.useCumulative });
            setRateUnit({ ...rateUnit, ...analysisFilters.rateUnit });
            setCategorySetting({ ...categorySetting, ...analysisFilters.categorySetting });

            if (elementsOptions.length === analysisFilters.elements?.length) {
                setElements(analysisFilters.elements);
            }

            /**
             * if custom
             */
            if (dateFilters.dateRange === 6) {
                setCustomDateRange(dateFilters.customDate);
            }
            setDateRange(dateFilters.dateRange);
        } else {
            setDateRange(undefined);
        }
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            syncData();
        }, sessionDataSyncInterval);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if (filter === 0 || dateRange === 0 || filterSet) return;

        let localFilters = localStorage.getItem(localStorageEnum.analysisFilters);
        localFilters = localFilters && JSON.parse(localFilters);
        const defaultElements = elementsOptions.length === localFilters.elements?.length ? localFilters.elements : filter.elements || elementsOptions;
        const defaultStatus = status || statuses[filter.status] || 'all';
        const defaultUserFilter = userFilter || filter.users?.id || 0;
        const defaultCustomDate = (customDate.startDate && customDate.endDate) ? customDate : filter.customDate;
        const selectedRange = dateRanges.find(e => e.value === dateRange);
        const defaultRange = selectedRange ? dateRange : filter?.dateRange || 1;
        const analysisFilters = {
            ...localFilters,
            status: defaultStatus,
            userFilter: defaultUserFilter,
            elements: defaultElements,
        };
        const dateFilters = {
            dateRange: defaultRange,
            customDate: defaultCustomDate
        };

        setStatus(defaultStatus);
        setUserFilter(defaultUserFilter);
        setCustomDate(defaultCustomDate);
        setDateRange(defaultRange);
        setElements(defaultElements);

        /**
         * if custom
         */
        if (defaultRange === 6) {
            setCustomDateRange(defaultCustomDate);
        }

        localStorage.setItem(localStorageEnum.analysisFilters, JSON.stringify(analysisFilters));
        localStorage.setItem(localStorageEnum.dateFilters, JSON.stringify(dateFilters));

        setFilterSet(true);
    }, [filter, dateRange]);

    useEffect(() => {
        const analysisFilters = {
            elements,
            status,
            userFilter,
            displayFilter,
            usePercent,
            switchGraphs,
            useCumulative,
            rateUnit,
            categorySetting,
            patientId
        };
        localStorage.setItem(localStorageEnum.analysisFilters, JSON.stringify(analysisFilters));
    }, [
        elements,
        status,
        userFilter,
        displayFilter,
        usePercent,
        switchGraphs,
        useCumulative,
        rateUnit,
        categorySetting,
    ]);

    useEffect(() => {
        // filters hasn't finished loading here
        if (dateRange === 0) return;

        const dateFilters = { dateRange, customDate };
        localStorage.setItem(localStorageEnum.dateFilters, JSON.stringify(dateFilters));
    }, [dateRange, customDate]);

    useEffect(() => {
        setShouldLoad(false);
        setCategoryTargetData([]);
    }, [patient]);

    useEffect(() => {
        // Filters haven't set yet
        if (filter === 0 || !filterSet) return;

        if (patient && patient.id !== patientId) {
            const analysisFilters = {
                elements,
                status,
                userFilter: filter.users?.id || 0,
                displayFilter,
                usePercent,
                switchGraphs,
                useCumulative,
                rateUnit,
                categorySetting,
                patientId: patient.id,
            };
            localStorage.setItem(localStorageEnum.analysisFilters, JSON.stringify(analysisFilters));
            setPatientId(patient.id);
            setUserFilter(filter.users?.id || 0);
        }

        /**
         * Only when there is a domain/program selected
         */
        if (selection.domain !== 'all' && patient?.id) {
            let resourceType = 'program';
            let resourceId = selection.program;
            if (selection.program === 'all') {
                // domain is selected
                resourceType = 'domain';
                resourceId = selection.domain;
            }
            if (selection.target) {
                resourceType = 'target';
                resourceId = selection.target;
            }
            debouncedGetUsers(resourceType, resourceId).then((res) => {
                setUsers(
                    res.data.data.users.map((e) => ({
                        label: `${e.firstName} ${e.lastName}`,
                        value: e.id,
                    }))
                );
                const selectedUser = res.data.data.users.find(e => e.id === userFilter);
                if (!selectedUser || !res.data?.data?.users?.length) {
                    setUserFilter(0);
                }
                setShouldLoad(true);
            });
        }
    }, [selection, patient, filterSet]);

    useEffect(() => {
        if (
            selection.domain !== 'all' &&
            shouldLoad &&
            !isAfter(startDate, endDate) &&
            !targetLoading
        ) {
            fetchData();
        } else if (
            selection.domain === 'all' &&
            patient?.id &&
            !isAfter(startDate, endDate) &&
            !targetLoading
        ) {
            const filter = generateFilterFormat();
            fetchMasteredTargets('all', 0, filter);
        }
    }, [selection, userFilter, startDate, endDate, status, targetLoading, shouldLoad, refreshCount]);

    useEffect(() => {
        // filters hasn't finished loading here
        if (dateRange === 0) return;

        const selectedRange = dateRanges.find((e) => e.value === dateRange);
        if (!selectedRange) return;

        let newStartDate = new Date();
        let newEndDate = new Date();
        if (selectedRange.days) newStartDate = subDays(newStartDate, selectedRange.days);
        if (selectedRange.months) newStartDate = subMonths(newStartDate, selectedRange.months);
        if (selectedRange.max) newStartDate = null;

        if (selectedRange.custom) {
            return;
        }

        setStartDate(newStartDate);
        setEndDate(newEndDate);
    }, [dateRange]);

    useEffect(() => {
        const { domain, ticks } = generateDomainTicks(startDate, endDate);
        setGraphDomain(domain);
        setGraphTicks(ticks);

        /**
         * If custom date
         */
        if (dateRange === 6) {
            setCustomDate({ startDate, endDate });
        }
    }, [startDate, endDate]);

    const setCustomDateRange = (customDateRange) => {
        if (customDateRange?.startDate) {
            setStartDate(new Date(customDateRange.startDate));
        } else {
            setStartDate(null);
        }
        if (customDateRange?.endDate) {
            setEndDate(new Date(customDateRange.endDate));
        } else {
            setEndDate(null);
        }
    }

    const fetchData = () => {
        const filter = generateFilterFormat();

        if (selection.target) {
            // coming from target table's graph button
            getTargetDataList('target', selection.target, filter);
            return;
        }

        if (selection.program !== 'all') {
            // program is selected
            getTargetDataList('program', selection.program, filter);
            fetchMasteredTargets('program', selection.program, filter);
        }
        if (selection.program === 'all') {
            // domain is selected
            getTargetDataList('domain', selection.domain, filter);
            fetchMasteredTargets('domain', selection.domain, filter);
        }
    };
    const fetchMasteredTargets = (type, resourceId, filter) => {
        if (patient?.id) {
            debouncedGetMasteredTargets(type, resourceId, {
                startDate: filter.sessionDateStart,
                endDate: filter.sessionDateEnd,
                patientId: patient.id,
            }).then((res) => {
                // make it similar to frequency data for easier maintenance
                const masteredTargets = { targetData: [], name: 'Mastered targets' };
                masteredTargets.targetData = res.data.data.map((day) => ({
                    cumulative: day.cumulative,
                    date: day.day,
                    value: day.count,
                }));
                setMasteredTargets([masteredTargets]);
            });
        }
    };

    const fetchCategoryData = (targetId, catSetting) => {
        const filter = generateFilterFormat();
        return getCategoryDataListByResourceId(targetId, filter, catSetting)
            .then(res => {
                const list = res.data.data;
                if (list.length >= 1) {
                    setCategoryTargetData(categoryTargetData => {
                        let categoryTargetList = categoryTargetData.slice();
                        const newItem = list[0];
                        const oldIndex = categoryTargetList.findIndex(e => e.id === targetId);
                        if (oldIndex !== -1) {
                            categoryTargetList.splice(oldIndex, 1, newItem);
                        } else {
                            categoryTargetList.push(newItem);
                        }
                        return categoryTargetList;
                    });
                }
            })
    };

    const handleDownload = (id) => {
        setTimeout(() => {
            setIsDownloading(true);
            domToImage.toBlob(document.getElementById(id)).then((blob) => {
                saveAs(blob, `${id}.png`);
                setIsDownloading(false);
            });
        }, 1000);
    };

    const groupDataByProgramId = (dataArray) => {
        const ids = [];
        const newDataArray = [];
        dataArray.forEach((data) => {
            if (!ids.includes(data.programId)) {
                ids.push(data.programId);
                newDataArray.push({
                    targets: [{ ...data }],
                    programId: data.programId,
                    name: data.name,
                });
            } else {
                const newData = newDataArray.find((e) => e.programId === data.programId);
                newData.targets.push({ ...data });
                newData.name += `, ${data.name}`;
            }
        });

        return newDataArray;
    };

    const handleChangeElement = (name) => (event) => {
        const newElements = elements;
        const changedElement = newElements.find((e) => e.name === name);
        changedElement.checked = !changedElement.checked;

        setElements(newElements.slice());
    };

    const handleCheckAll = (filters, setFilters) => {
        if (isAllChecked(filters)) {
            filters.forEach((filter) => {
                filter.checked = false;
            });
        } else {
            filters.forEach((filter) => {
                filter.checked = true;
            });
        }
        setFilters(filters.slice());
    };

    const isAllChecked = (filters) => {
        return !filters.some((filter) => !filter.checked);
    };

    let frequencyData = [];
    let correctIncorrectData = [];
    let quantityData = [];
    let promptData = [];
    let ratingData = [];
    let rateData = [];
    let durationData = [];
    let intervalData = [];
    let categoryData = [];
    let textData = [];
    let probeData = [];
    let imageVideoData = [];
    if (selection.domain !== 'all' && targetData.length > 0) {
        const targetIds = targetData.map(e => e.id);

        frequencyData = targetData.filter((e) => e.dataType === 'frequency' && e.targetData?.length > 0);
        correctIncorrectData = targetData.filter((e) => e.dataType === 'correctIncorrect' && e.targetData?.length > 0);
        durationData = targetData.filter((e) => e.dataType === 'duration' && e.targetData?.length > 0);
        promptData = targetData.filter((e) => e.dataType === 'prompt' && e.targetData?.length > 0);
        quantityData = targetData.filter((e) => e.dataType === 'quantity' && e.targetData?.length > 0);
        ratingData = targetData.filter((e) => e.dataType === 'rating' && e.targetData?.length > 0);
        rateData = targetData.filter((e) => e.dataType === 'rate' && e.targetData?.length > 0);
        intervalData = targetData.filter((e) => e.dataType === 'interval' && e.targetData?.length > 0);
        categoryData = categoryTargetData.filter((e) => e.dataType === 'category' && targetIds.includes(e.id) && e.targetData?.length > 0);
        textData = targetData.filter((e) => e.dataType === 'text' && e.targetData?.length > 0);
        probeData = targetData.filter((e) => e.dataType === 'probe' && e.targetData?.length > 0);
        imageVideoData = targetData.filter((e) => e.dataType === 'imageVideo' && e.targetData?.length > 0 && isTargetDataAvailable(e.targetData));

        if (displayFilter === 1) {
            frequencyData = groupDataByProgramId(frequencyData);
            correctIncorrectData = groupDataByProgramId(correctIncorrectData);
            durationData = groupDataByProgramId(durationData);
        }
    }

    const downloadGraphs = () => {
        const ids = [];
        if (masteredTargets[0]?.targetData?.length > 0) {
            ids.push(`masteredTargets ${pageTitle}`);
        }
        if (correctIncorrectData.length > 0) {
            correctIncorrectData.forEach((data) => {
                ids.push(`correctIncorrect ${data.name}`);
            });
        }
        if (frequencyData.length > 0) {
            frequencyData.forEach((data) => {
                ids.push(`frequency ${data.name}`);
            });
        }
        if (promptData.length > 0) {
            promptData.forEach((data) => {
                ids.push(`prompt ${data.name}`);
            });
        }
        if (quantityData.length > 0) {
            quantityData.forEach((data) => {
                ids.push(`quantity ${data.name}`);
            });
        }
        if (ratingData.length > 0) {
            ratingData.forEach((data) => {
                ids.push(`rating ${data.name}`);
            });
        }
        if (rateData.length > 0) {
            rateData.forEach((data) => {
                ids.push(`rate ${data.name}`);
            });
        }
        if (durationData.length > 0) {
            durationData.forEach((data) => {
                ids.push(`duration ${data.name}`);
            });
        }
        if (intervalData.length > 0) {
            intervalData.forEach((data) => {
                ids.push(`interval ${data.name}`);
            });
        }
        if (categoryData.length > 0) {
            categoryData.forEach((data) => {
                ids.push(`category ${data.name}`);
            });
        }
        if (probeData.length > 0) {
            probeData.forEach((data) => {
                ids.push(`probe ${data.name}`);
            });
        }
        if (imageVideoData.length > 0) {
            imageVideoData.forEach(data => downloadImages(data));
        }

        handleDownloadArr(ids);
    };

    const downloadImages = data => data.targetData?.map(data => data?.value.map(item => urlDownload(item.url)));

    const handleDownloadArr = async (ids) => {
        setIsDownloading(true);
        for (const id of ids) {
            const blob = await domToImage.toBlob(document.getElementById(id));
            saveAs(blob, `${id}.png`);
        }
        setIsDownloading(false);
    };

    const generateFilterFormat = () => {
        const currentStatus = getSelectedStatus();
        let statusFilter =
            currentStatus === 'all'
                ? statuses.filter((e) => !['archived', 'all'].includes(e))
                : [currentStatus];

        return {
            status: statusFilter,
            sessionDateStart: startDate ? dateFormatter(new Date(startDate.setHours(0, 0, 0, 0)).toJSON(), 'add') : null,
            sessionDateEnd: endDate ? dateFormatter(new Date(endDate.setHours(23, 59, 59, 999)).toJSON(), 'add') : null,
            userIds: userFilter === 0 ? users.map((e) => e.value) : [userFilter],
        };
    };

    const downloadNotes = (type, id, sheetNames) => {
        const filter = generateFilterFormat();
        let resourceType = 'program';
        let resourceId = 0;

        if (selection.domain !== 'all' && selection.program !== 'all') {
            // program is selected
            resourceType = 'program';
            resourceId = selection.program;
        }
        if (selection.domain !== 'all' && selection.program === 'all') {
            // domain is selected
            resourceType = 'domain';
            resourceId = selection.domain;
        }
        if (selection.target) {
            // target is selected
            resourceType = 'target';
            resourceId = selection.target;
        }

        resourceType = type || resourceType;
        resourceId = id || resourceId;

        downloadTargetDataByResourceId(resourceType, resourceId, filter, sheetNames)
            .then((res) => {
                let link = document.createElement("a");
                link.download = res.data.data.fileName;
                link.href = res.data.data.path;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
    };

    const downloadAll = () => {
        downloadGraphs();
        downloadNotes(null, null, [sheetNames.notes, sheetNames.text, sheetNames.probe]);
    };

    const syncData = () => {
        setRefreshCount(count => {
            return (count + 1);
        });
    };

    const getCustomDateLabel = () => {
        if (dateRange !== 6) return undefined;
        const startDateValue = startDate ? format(startDate, 'MM/dd/yyyy') : 'First Entry';
        const endDateValue = endDate ? format(endDate, 'MM/dd/yyyy') : 'Today';
        return `Date Range: ${startDateValue} - ${endDateValue}`;
    };

    const handleDateRangeChange = (event) => {
        const { value } = event.target;
        setDateRange(value);
        if (value === 6) {
            setOpenDatePicker(true);
        }
    };

    const handleEditPhase = (targets) => {
        setEditPhaseTargets(targets);
    };

    const toggleFilters = (e, forcedState) => {
        if (e) e.preventDefault();
        if (forcedState !== undefined) {
            return setShowFilters(forcedState);
        }
        setShowFilters(!showFilters);
    }

    const closeEditPhase = (shouldRefresh) => () => {
        setEditPhaseTargets([]);
        if (shouldRefresh) {
            fetchData();
        }
    };

    const pageTitle = useMemo(() => {
        if (selection.all) {
            return 'All Domain';
        }
        const domainName = domains.find((e) => e.id === selection.domain)?.name || 'Unknown';
        if (selection.program !== 'all') {
            const programName = programs.find((e) => e.id === selection.program)?.name || 'Unknown';
            const domainProgramTitle = `${domainName} / ${programName}`;

            if (selection.target) {
                const selectedTarget = targets.find((e) => e.id === selection.target);
                const targetName = selectedTarget?.name || 'Unknown';
                const targetStatus = selectedTarget?.status;

                if (!selectedTarget) {
                    return `${domainProgramTitle} / ${targetName}${targetStatus ? ` (${capitalize(targetStatus)})` : ''
                        }`;
                } else {
                    return `${selectedTarget.program.domain.name} / ${selectedTarget.program.name
                        } / ${targetName}${targetStatus ? ` (${capitalize(targetStatus)})` : ''}`;
                }
            }

            return domainProgramTitle;
        } else {
            return domainName;
        }
    }, [selection, domains, programs, targets]);

    const getSelectedStatus = () => {
        let currentStatus = status;
        if (selection.target) {
            const selectedTarget = targets.find((e) => e.id === selection.target);
            if (selectedTarget) {
                currentStatus = selectedTarget.status;
            }
        }
        if (!statuses.find((e) => e === currentStatus)) {
            currentStatus = 'all';
        }

        return currentStatus;
    };

    const noGraph =
        correctIncorrectData.length === 0 &&
        frequencyData.length === 0 &&
        promptData.length === 0 &&
        quantityData.length === 0 &&
        ratingData.length === 0 &&
        rateData.length === 0 &&
        durationData.length === 0 &&
        intervalData.length === 0 &&
        categoryData.length === 0 &&
        textData.length === 0 &&
        probeData.length === 0 &&
        imageVideoData.length === 0 &&
        (masteredTargets[0]?.targetData?.length || 0) === 0;

    const graphElements = {};
    elements.forEach((e) => {
        graphElements[e.name] = e.checked;
    });
    const combineTargets = displayFilter === 1;
    const showPhase = displayFilter === 1 || displayFilter === 2;

    const defaultGraphProps = {
        graphElements,
        graphDomain,
        graphTicks,
        showPhase,
        isDownloading,
    };

    return (
        <div className={classes.root}>
            {/* {selection.domain !== 'all' ? ( */}
            <Grid container className={classes.graphWrapper}>
                {/* Status filter */}
                <Grid item xs={12} className={classes.filterWrapper} style={isMobile ? { paddingBottom: 16 } : {}} container>
                    {isMobile ? <>
                        <Grid item xs={6} className={classes.mobileProgramSelect}>
                            <ProgramSelect wrapperStyle={classes.filter}/>
                        </Grid>
                        <Box style={{ position: 'relative' }}>

                            <TooltipedIconButton
                                icon={<Tune />}
                                onClick={toggleFilters}
                                tooltip="Filters"
                                className={classes.filterButton}
                                id="analysis-page-filters-session-data"
                            />
                            {showFilters && <Grid item xs={12} className={classes.filterWrapperMobile}>
                                <DropdownButton
                                    id='analysis-page-date'
                                    label='Date Range'
                                    selectedValue={dateRange}
                                    onValueChange={handleDateRangeChange}
                                    options={dateRanges}
                                    wrapperStyle={classes.filter}
                                    customLabel={getCustomDateLabel()}
                                />
                                <DropdownButton
                                    id='analysis-page-status'
                                    label='Status'
                                    selectedValue={getSelectedStatus()}
                                    onValueChange={(event) => setStatus(event.target.getAttribute('value'))}
                                    options={statusList}
                                    wrapperStyle={classes.filter}
                                    disabled={!!selection.target}
                                />
                                <DropdownButton
                                    id='analysis-page-user'
                                    label='Users'
                                    selectedValue={users.length > 0 ? userFilter : 0}
                                    onValueChange={(event) => setUserFilter(event.target.value)}
                                    options={[{ value: 0, label: 'All' }].concat(users)}
                                    wrapperStyle={classes.filter}
                                />
                                <DropdownButton
                                    id='analysis-page-combine'
                                    selectedValue={displayFilter}
                                    onValueChange={(event) => setDisplayFilter(event.target.value)}
                                    options={displayFilterOptions}
                                    wrapperStyle={classes.filter}
                                />
                                <FilterDropdownButton
                                    id='analysis-page-elements'
                                    label='Elements'
                                    filters={elements.map((el) => ({ ...el, id: el.name }))}
                                    handleChangeFilter={(el) => handleChangeElement(el.name)}
                                    isAllChecked={isAllChecked(elements)}
                                    handleCheckAll={() => handleCheckAll(elements, setElements)}
                                    wrapperStyle={classes.filter}
                                />
                            </Grid>}

                        </Box>
                    </>
                        :
                        <>
                            <DropdownButton
                                id='analysis-page-date'
                                label='Date Range'
                                selectedValue={dateRange}
                                onValueChange={handleDateRangeChange}
                                options={dateRanges}
                                wrapperStyle={classes.filter}
                                customLabel={getCustomDateLabel()}
                            />
                            <DropdownButton
                                id='analysis-page-status'
                                label='Status'
                                selectedValue={getSelectedStatus()}
                                onValueChange={(event) => setStatus(event.target.getAttribute('value'))}
                                options={statusList}
                                wrapperStyle={classes.filter}
                                disabled={!!selection.target}
                            />
                            <DropdownButton
                                id='analysis-page-user'
                                label='Users'
                                selectedValue={users.length > 0 ? userFilter : 0}
                                onValueChange={(event) => setUserFilter(event.target.value)}
                                options={[{ value: 0, label: 'All' }].concat(users)}
                                wrapperStyle={classes.filter}
                            />
                            <DropdownButton
                                id='analysis-page-combine'
                                selectedValue={displayFilter}
                                onValueChange={(event) => setDisplayFilter(event.target.value)}
                                options={displayFilterOptions}
                                wrapperStyle={classes.filter}
                            />
                            <FilterDropdownButton
                                id='analysis-page-elements'
                                label='Elements'
                                filters={elements.map((el) => ({ ...el, id: el.name }))}
                                handleChangeFilter={(el) => handleChangeElement(el.name)}
                                isAllChecked={isAllChecked(elements)}
                                handleCheckAll={() => handleCheckAll(elements, setElements)}
                                wrapperStyle={classes.filter}
                            />
                        </>}
                    <TooltipedIconButton
                        icon={<RefreshIcon />}
                        onClick={syncData}
                        tooltip="Sync Session Data"
                        className={`${classes.downloadButton} ${classes.sessionSyncBtn}`}
                        id="analysis-page-sync-session-data"
                    />
                    <CanUser
                        perform={access.analysis.uploadDownload}
                        yes={() =>
                            <TooltipedIconButton
                                icon={<GetAppIcon />}
                                onClick={downloadAll}
                                tooltip="Download All Data"
                                className={classes.downloadButton}
                                id="analysis-page-download"
                            />
                        }
                    />
                </Grid>

                <Grid container className={classes.domainStatusWrapper}>
                    {!isMobile && <Grid item xs={12} className={classes.domainWrapper}>
                        <span className={classes.currentDomain}>{pageTitle}</span>
                    </Grid>}
                </Grid>

                {loading.getTargetData || targetLoading ? (
                    <Grid className={classes.progressWrapper} container justify='center'>
                        <CircularProgress size={300} />
                    </Grid>
                ) : (
                    <Grid item xs={12} container className={classes.wrapper}>
                        <Grid item xs={12}>
                            <p className={classes.noGraph}>
                                {noGraph && 'No graph to show for current filters.'}
                            </p>
                        </Grid>

                        {/* Mastered graph */}
                        <Grid item xs={12}>
                            {selection.program === 'all' && masteredTargets[0]?.targetData?.length > 0 && (
                                <Card className={classes.card} key={`masteredTargets`}>
                                    <GraphWrapper
                                        type='masteredTargets'
                                        title={pageTitle}
                                        isDownloading={isDownloading}
                                        handleDownload={handleDownload}
                                        graphElements={graphElements}
                                        useCumulative={useCumulative}
                                        setUseCumulative={() =>
                                            setUseCumulative({
                                                ...useCumulative,
                                                masteredTargets: !useCumulative.masteredTargets,
                                            })
                                        }
                                    >
                                        <FrequencyGraph
                                            {...defaultGraphProps}
                                            useCumulative={useCumulative.masteredTargets}
                                            graphData={masteredTargets}
                                        />
                                    </GraphWrapper>
                                </Card>
                            )}
                        </Grid>

                        {selection.domain !== 'all' ? (
                            <>
                                {/* correctIncorrect graph */}
                                <Grid item xs={12}>
                                    {correctIncorrectData.length > 0 &&
                                        correctIncorrectData.map((data, index) => (
                                            <Card className={classes.card} key={`correct${index}`}>
                                                <GraphWrapper
                                                    type='correctIncorrect'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    usePercent={usePercent}
                                                    setUsePercent={setUsePercent}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase(combineTargets ? data.targets : [data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <CorrectIncorrectGraph
                                                        {...defaultGraphProps}
                                                        usePercent={usePercent[data.name]}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={
                                                            combineTargets ? data.targets : [data]
                                                        }
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* quantity graph */}
                                <Grid item xs={12}>
                                    {quantityData.length > 0 &&
                                        quantityData.map((data, index) => (
                                            <Card className={classes.card} key={`correct${index}`}>
                                                <GraphWrapper
                                                    type='quantity'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    setUseTotal={setUseTotal}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <QuantityGraph
                                                        {...defaultGraphProps}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={[data]}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* frequency graph */}
                                <Grid item xs={12}>
                                    {frequencyData.length > 0 &&
                                        frequencyData.map((data, index) => (
                                            <Card className={classes.card} key={`freq${index}`}>
                                                <GraphWrapper
                                                    type='frequency'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase(combineTargets ? data.targets : [data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <FrequencyGraph
                                                        {...defaultGraphProps}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={
                                                            combineTargets ? data.targets : [data]
                                                        }
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* rating scale graph */}
                                <Grid item xs={12}>
                                    {ratingData.length > 0 &&
                                        ratingData.map((data, index) => (
                                            <Card className={classes.card} key={`rating${index}`}>
                                                <GraphWrapper
                                                    type='rating'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                >
                                                    <RatingScaleGraph
                                                        {...defaultGraphProps}
                                                        usePercent={usePercent[data.name]}
                                                        graphData={[data]}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* prompt graph */}
                                <Grid item xs={12}>
                                    {promptData.length > 0 &&
                                        promptData.map((data, index) => (
                                            <Card className={classes.card} key={`prompt${index}`}>
                                                <GraphWrapper
                                                    type='prompt'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    usePercent={usePercent}
                                                    setUsePercent={setUsePercent}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <PromptGraph
                                                        {...defaultGraphProps}
                                                        usePercent={usePercent[data.name]}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={[data]}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* rate graph */}
                                <Grid item xs={12}>
                                    {rateData.length > 0 &&
                                        rateData.map((data, index) => (
                                            <Card className={classes.card} key={`rate${index}`}>
                                                <GraphWrapper
                                                    type='rate'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    graphElements={graphElements}
                                                    handleRateUnit={setRateUnit}
                                                    rateUnit={rateUnit}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <RateGraph
                                                        {...defaultGraphProps}
                                                        usePercent={usePercent[data.name]}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={[data]}
                                                        rateUnit={rateUnit[data.name] || 'minute'}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* duration graph */}
                                <Grid item xs={12}>
                                    {durationData.length > 0 &&
                                        durationData.map((data, index) => (
                                            <Card className={classes.card} key={`duration${index}`}>
                                                <GraphWrapper
                                                    type='duration'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase(combineTargets ? data.targets : [data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <DurationGraph
                                                        {...defaultGraphProps}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={
                                                            combineTargets ? data.targets : [data]
                                                        }
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* interval graph */}
                                <Grid item xs={12}>
                                    {intervalData.length > 0 &&
                                        intervalData.map((data, index) => (
                                            <Card className={classes.card} key={`interv${index}`}>
                                                <GraphWrapper
                                                    type='interval'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    usePercent={usePercent}
                                                    setUsePercent={setUsePercent}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                    useCumulative={useCumulative}
                                                    setUseCumulative={setUseCumulative}
                                                >
                                                    <IntervalGraph
                                                        {...defaultGraphProps}
                                                        usePercent={usePercent[data.name]}
                                                        useCumulative={useCumulative[data.name]}
                                                        graphData={[data]}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* probe graph */}
                                <Grid item xs={12}>
                                    {probeData.length > 0 &&
                                        probeData.map((data, index) => (
                                            <Card className={classes.card} key={`probe${index}`}>
                                                <GraphWrapper
                                                    type='probe'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    switchGraphs={switchGraphs}
                                                    setSwitchGraphs={setSwitchGraphs}
                                                    graphElements={graphElements}
                                                    showPhase={showPhase}
                                                    handleEditPhase={() => handleEditPhase([data])}
                                                >
                                                    <ProbeGraph
                                                        {...defaultGraphProps}
                                                        switchGraphs={switchGraphs[data.name]}
                                                        graphData={[data]}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* category graph */}
                                <Grid item xs={12}>
                                    {categoryData.length > 0 &&
                                        categoryData.map((data, index) => (
                                            <Card className={classes.card} key={`category${index}`}>
                                                <GraphWrapper
                                                    type='category'
                                                    title={data.name}
                                                    isDownloading={isDownloading}
                                                    handleDownload={handleDownload}
                                                    graphElements={graphElements}
                                                    customData={data.customData}
                                                    categorySetting={categorySetting}
                                                    setCategorySetting={setCategorySetting}
                                                >
                                                    <CategoryGraph
                                                        {...defaultGraphProps}
                                                        categorySetting={categorySetting[data.name]}
                                                        fetchCategoryData={fetchCategoryData}
                                                        graphData={data}
                                                    />
                                                </GraphWrapper>
                                            </Card>
                                        ))}
                                </Grid>

                                {/* text data */}
                                <Grid item xs={12}>
                                    {textData.length > 0 &&
                                        textData.map((text, index) => (
                                            <Card
                                                className={classes.card}
                                                key={`text${index}-card`}
                                            >
                                                <TextTable
                                                    title={text.name}
                                                    textData={text}
                                                    key={`text${index}-root`}
                                                    loopKey={`text${index}`}
                                                    graphElements={graphElements}
                                                    handleDownload={() => downloadNotes('target', text.id, [sheetNames.text])}
                                                />
                                            </Card>
                                        ))}
                                </Grid>

                                {/* image/video data */}
                                <Grid item xs={12}>
                                    {imageVideoData.length > 0 && (
                                        <Card className={classes.card}>
                                            <ImageVideoTable
                                                data={imageVideoData}
                                                graphElements={graphElements}
                                            />
                                        </Card>
                                    )}
                                </Grid>

                                {/* depreceated probe data table */}
                                {/* <Grid item xs={12}>
                                    {probeData.length > 0 &&
                                        probeData.map((probe, index) => (
                                            <Card className={classes.card} key={`probe${index}-card`}>
                                                <ProbeTable
                                                    title={probe.name}
                                                    probeData={probe}
                                                    key={`probe${index}-root`}
                                                    loopKey={`probe${index}`}
                                                    graphElements={graphElements}
                                                />
                                            </Card>
                                        ))}
                                </Grid> */}

                                {/* notes */}
                                <Grid item xs={12}>
                                    <Card className={classes.card}>
                                        <Notes notesData={targetData} handleDownload={() => downloadNotes(null, null, [sheetNames.notes])} />
                                    </Card>
                                </Grid>
                            </>
                        ) : (
                            <NoProgram />
                        )}
                    </Grid>
                )}
                {/* Onboarding Disabled */}
                {/* <CustomOnboarding steps={analysisPageSteps} /> */}
            </Grid>

            <DateRangePicker
                startDate={startDate}
                setStartDate={(date) => setStartDate(date)}
                endDate={endDate}
                setEndDate={(date) => setEndDate(date)}
                open={openDatePicker}
                onClose={() => setOpenDatePicker(false)}
            />
            <EditPhases
                open={editPhaseTargets?.length}
                onClose={closeEditPhase(false)}
                onCloseSave={closeEditPhase(true)}
                targets={editPhaseTargets}
            />
        </div>
    );
};

Analysis.propTypes = {
    targetData: PropTypes.array.isRequired,
    selection: PropTypes.object.isRequired,
    programs: PropTypes.array.isRequired,
    domains: PropTypes.array.isRequired,
    targetLoading: PropTypes.bool.isRequired,
    loading: PropTypes.object.isRequired,
    patient: PropTypes.object.isRequired,
    targets: PropTypes.array.isRequired,
    getTargetDataList: PropTypes.func.isRequired,
    isMobile: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
    selection: state.general.selection,
    targets: state.data.targets,
    programs: state.data.programs,
    domains: state.data.domains,
    targetData: state.data.targetData,
    targetLoading: state.general.targetLoading,
    loading: state.general.loading,
    patient: state.patient.patient,
    user: state.auth.user,
    isMobile: state.general.isMobile,
});

export default connect(mapStateToProps, { getTargetDataList })(Analysis);
