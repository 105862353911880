import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import CircularProgress from '@material-ui/core/CircularProgress';
import format from 'date-fns/format';

import LoadingCheck from '../../assets/progress/LoadingCheck';
import { setSnackbar, setLoading, setGlobalDialog } from '../../store/general/actions';
import { deleteReminder } from '../../services/ReminderService';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tblHeadRow: {
        backgroundColor: '#F4F4F4',
    },
    headCell: {
        fontWeight: 'bold !important',
        padding: '12px 12px',
    },
    tableCell: {
        padding: '12px 12px',
    },
    noData: {
        textAlign: 'center',
        fontWeight: 'bold',
        verticalAlign: 'middle',
        fontSize: 32,
    },
    iconButton: {
        padding: 4,
    },
}));

const headCells = [
    { id: 'title', label: 'Title' },
    { id: 'message', label: 'Message' },
    { id: 'schedule', label: 'Schedule' },
    { id: 'repeat', label: 'Repeat' },
];

const ReminderTable = (props) => {
    const classes = useStyles();
    const {
        reminders, loading, onEdit, fetchReminder,
        setSnackbar, setLoading, setGlobalDialog
    } = props;
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedReminder, setSelectedReminder] = React.useState(null);

    useEffect(() => {
        if (reminders.length <= page * rowsPerPage) {
            setPage(page - 1 >= 0 ? page - 1 : 0);
        }
    }, [reminders])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleEditReminder = (reminder) => () => {
        setSelectedReminder(reminder);
        onEdit(reminder);
    }

    const handleDeleteReminder = (reminder) => () => {
        setSelectedReminder(reminder);
        setGlobalDialog(
            `Delete reminder?`,
            `Deleted reminder cannot be restored, please proceed with caution.`,
            (answer) => {
                if (answer) {
                    setLoading('deleteReminder');
                    deleteReminder(reminder.id).then(() => {
                        setLoading('deleteReminder', false);
                        setSnackbar('success', 'Reminder deleted!');
                        fetchReminder();
                    });
                }
            },
        )
    }

    // Max 24 letters * 3 lines
    const formatMessage = (message) => {
        if (message.length < 75) {
            return message;
        }
        
        return message.substr(0, 75) + '...';
    }

    const formatSchedule = (schedule) => {
        let scheduleText = '';
        if (schedule.startSession) {
            scheduleText = 'When starting session';
        }
        if (schedule.beforeEndingSession) {
            scheduleText = 'Before ending session';
        }
        if (schedule.minuteAfterSessionStart) {
            scheduleText = schedule.minuteAfterSessionStart + ' minute after session start';
        }
        if (schedule.specificTime) {
            scheduleText = "Specific Time: " + format(new Date(schedule.specificTime), 'HH:mm');
        }
        return scheduleText;
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.tblHeadRow}>
                                {headCells.map(headCell => (
                                    <TableCell
                                        key={headCell.id}
                                        className={classes.headCell}
                                    >
                                        {headCell.label}
                                    </TableCell>
                                ))}
                                <TableCell className={classes.headCell} align="center">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        {loading.listReminder ?
                            <TableBody>
                                <TableRow style={{ height: 400 }}>
                                    <TableCell className={classes.noData} colSpan={9}>
                                        <CircularProgress size={250} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {reminders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow
                                                hover
                                                tabIndex={-1}
                                                key={row.id}
                                            >
                                                <TableCell className={classes.tableCell} component="th" scope="row">
                                                    {row.title}
                                                </TableCell>
                                                <TableCell className={classes.tableCell} style={{ width: 200 }}>{formatMessage(row.message)}</TableCell>
                                                <TableCell className={classes.tableCell}>{formatSchedule(row.schedule)}</TableCell>
                                                <TableCell className={classes.tableCell}>{row.repeat.isRepeat ? `Yes (${row.repeat.period + 'min'})` : 'No'}</TableCell>
                                                <TableCell className={classes.tableCell} align="center">
                                                    <IconButton id={`reminder-table-edit-${row.id}`} onClick={handleEditReminder(row)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton id={`reminder-table-delete-${row.id}`} onClick={handleDeleteReminder(row)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                    {selectedReminder &&
                                                        <LoadingCheck show={(loading.deleteReminder || loading.editReminder) && selectedReminder.id === row.id} size={24} />
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {!reminders.length &&
                                    <TableRow style={{ height: 400 }}>
                                        <TableCell id="reminders_no_reminders_message" className={classes.noData} colSpan={9}>No reminders found.</TableCell>
                                    </TableRow>
                                }
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 50, 100]}
                    component="div"
                    count={reminders.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

ReminderTable.propTypes = {
    loading: PropTypes.object.isRequired,
    setLoading: PropTypes.func.isRequired,
    setSnackbar: PropTypes.func.isRequired,
    setGlobalDialog: PropTypes.func.isRequired,
    setSkip: PropTypes.func.isRequired,
    setLimit: PropTypes.func.isRequired,
    fetchReminder: PropTypes.func.isRequired,
    reminders: PropTypes.array.isRequired,
    onEdit: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setSnackbar, setGlobalDialog })(ReminderTable);