import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import DeleteIcon from '@material-ui/icons/Delete';

import withDataTableHoc from './DataTableHoc';
import AddIcon from '@material-ui/icons/AddCircle';
import { CanUserFunc } from '../../CanUser';
import { access } from '../../../constants';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    table: {
        borderCollapse: 'collapse',
    },
    cell: {
        border: '1px solid black !important',
        padding: 2,
        minWidth: 15,
        height: 20,
        minHeight: 20,
    },
    topSeparator: {
        padding: 2,
        height: 25,
        minHeight: 25,
        minWidth: 15,
    },
    text: {
        margin: 0,
    },
    separatorCell: {
        width: 10,
        minWidth: 10,
    },
    value: {
        display: 'inline-block',
        width: 24,
        maxWidth: 24,
    },
    textField: {
        width: 24,
        maxWidth: 24,
    },
    input: {
        padding: '0px !important',
        textAlign: 'center',
    },
    deleteBtn: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#d32f2f' },
    },
    deleteActive: {
        opacity: 1,
        color: '#d32f2f',
    },
    menuItem: {
        justifyContent: 'center',
    },
    addButton: {
        width: 20,
        height: 20,
        cursor: 'pointer',
        opacity: 0.4,
        '&:hover': { color: '#0FB99E' },
    },
    hidden: {
        display: 'none',
    }
}));

const createCustomData = (data) => {
    return data.value.filter(arr => arr.length > 0);
};

const Rating = (props) => {
    const classes = useStyles();
    const { isEdit, dataToUse, setEditingData, handleNewDelete, customData, handleAdd, setErrors } = props;

    const formattedData = useMemo(() => {
        const newRatingData = [];

        dataToUse &&
            dataToUse.forEach((data) => {
                const newRating = {
                    value: {},
                    date: data.date,
                    isDelete: data.isDelete,
                };

                customData.forEach((question) => {
                    newRating.value[question.id] = [];
                });

                data.value.forEach((values) => {
                    values.length && values.forEach((value) => {
                        newRating.value[value.questionId].push(value.answer || null);
                    });
                    if (values.length < customData.length) {
                        customData.forEach((question) => {
                            const existingData = values.find(
                                (item) => item.questionId === question.id
                            );
                            if (!existingData) {
                                newRating.value[question.id].push('');
                            }
                        });
                    }
                });

                newRatingData.push(newRating);
            });
        return newRatingData;
    }, [dataToUse]);

    const handleChange = (index, index2, key) => (event) => {
        const newData = dataToUse;
        const valueToChange = newData[index].value[index2].find((e) => e.questionId === key);
        if (valueToChange) {
            valueToChange.answer = event.target.value;
        } else {
            newData[index].value[index2].push({ questionId: key, answer: event.target.value });
        }
        const hasErrors = !!newData[index].value.find(e => !e.find(k => k.answer !== ''));
        setErrors(hasErrors ? 'At least one rating should be selected for a question' : false);
        setEditingData(newData.slice());
    };

    return (
        <Grid item xs={12} container>
            <table className={classes.table}>
                <tbody>
                    <tr>
                        {isEdit && <td></td>}
                        {formattedData.map((data, index) => (
                            <React.Fragment key={index}>
                                <td
                                    align='center'
                                    className={classes.topSeparator}
                                    colSpan={data.value[customData[0].id].length}
                                ></td>
                                {index !== formattedData.length - 1 && (
                                    <td className={classes.separatorCell}></td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    {customData.map((question, questionIndex) => {
                        const key = question.id;
                        const ratings = question.ratings;
                        return (
                            <tr key={key}>
                                {isEdit && (
                                    <td style={{ paddingRight: 5 }}>Q{questionIndex + 1}. </td>
                                )}
                                {formattedData.map((data, index) => (
                                    <React.Fragment key={index}>
                                        {data.value[key].map((value, index2) => (
                                            <td
                                                key={index2}
                                                align='center'
                                                className={classes.cell}
                                            >
                                                {isEdit ? (
                                                    <TextField
                                                        value={value}
                                                        className={classes.textField}
                                                        InputProps={{
                                                            classes: { input: classes.input },
                                                        }}
                                                        onChange={handleChange(index, index2, key)}
                                                        select
                                                        SelectProps={{ IconComponent: () => null }}
                                                        disabled={data.isDelete || question?.isDeleted ||dataToUse[index]?.isOngoing}
                                                    >
                                                        <MenuItem
                                                            key="empty-rating"
                                                            className={classes.menuItem}
                                                            value=""
                                                        >-</MenuItem>
                                                        {ratings.map((rating) => (
                                                            <MenuItem
                                                                key={rating.value}
                                                                className={`${classes.menuItem} ${rating.isDeleted ? classes.hidden : ''}`}
                                                                value={rating.id}
                                                            >
                                                                {rating.value} - {rating.desc}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                ) : (
                                                    <span className={classes.value}>{ratings.find(e => e.id === parseInt(value))?.value ?? ''}</span>
                                                )}
                                            </td>
                                        ))}
                                        {isEdit && questionIndex === 0 && (
                                            <td
                                                className={classes.cell}
                                                rowSpan={customData.length}
                                            >
                                                <Grid
                                                    container
                                                    style={{ height: '100%' }}
                                                    className={classes.textField}
                                                    alignContent='center'
                                                    justify='center'
                                                >
                                                    <AddIcon
                                                        color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                        className={classes.addButton}
                                                        onClick={!dataToUse[index]?.isOngoing && handleAdd(index)}
                                                    />
                                                </Grid>
                                            </td>
                                        )}
                                        {index !== formattedData.length - 1 && (
                                            <td className={classes.separatorCell}></td>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tr>
                        );
                    })}
                    {isEdit && CanUserFunc(access.data.remove) && (
                        <tr>
                            <td></td>
                            {formattedData.map((data, index) => (
                                <React.Fragment key={index}>
                                    {[...Array(data.value[customData[0].id].length)].map(
                                        (e, index2) => (
                                            <td align='center' key={index} valign='middle'>
                                                <Grid
                                                    container
                                                    style={{ height: '100%' }}
                                                    alignContent='center'
                                                    justify='center'
                                                >
                                                    <DeleteIcon
                                                        color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                        className={classes.deleteBtn}
                                                        onClick={!dataToUse[index]?.isOngoing && handleNewDelete(index, index2)}
                                                    />
                                                </Grid>
                                            </td>
                                        )
                                    )}
                                    <td align='center' valign='middle'>
                                        {data.value[customData[0].id].length === 0 && (
                                            <Grid
                                                container
                                                style={{ height: '100%' }}
                                                alignContent='center'
                                                justify='center'
                                            >
                                                <Tooltip title='This session data will be deleted'>
                                                    <DeleteIcon
                                                        color={dataToUse[index]?.isOngoing ? 'disabled' : 'action'}
                                                        className={clsx(
                                                            classes.deleteBtn,
                                                            classes.deleteActive
                                                        )}
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        )}
                                    </td>
                                    {index !== formattedData.length - 1 && (
                                        <td className={classes.separatorCell}></td>
                                    )}
                                </React.Fragment>
                            ))}
                        </tr>
                    )}
                </tbody>
            </table>
        </Grid>
    );
};

Rating.propTypes = {
    isEdit: PropTypes.bool.isRequired,
    dataToUse: PropTypes.array,
    setEditingData: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    customData: PropTypes.any,
    setErrors: PropTypes.func
};

export default withDataTableHoc(Rating, 'rating', createCustomData);
