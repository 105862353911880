import { connect } from 'react-redux';
import store from '../store/store';

const check = (permissions, perform) => {
  if (!permissions) {
    // no permissions on user
    return false;
  }

  if (typeof perform === 'boolean') return perform;

  if (permissions && permissions[perform?.group] && permissions[perform.group][perform.action]) {
    return true;
  }

  return false;
};

const CanUser = props =>
  check(props.user.permissions, props.perform)
    ? props.yes()
    : props.no();

CanUser.defaultProps = {
  yes: () => null,
  no: () => null
};

const mapStateToProps = state => ({
	user: state.auth.user,
});

export default connect(mapStateToProps)(CanUser);

export const CanUserFunc = (perform) => {
  const user = store.getState().auth.user;
  return check(user.permissions, perform);
}