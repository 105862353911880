import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Wrapper from "./Wrapper";
import { getUnixTime } from "date-fns";
import Timer from "./Timer";
import PhaseColoredButton from "./PhaseColoredButton";

const useStyles = makeStyles((theme) => ({
    rateButton: {
        borderRadius: 0,
        marginRight: 10,
        width: "100%",
    },
    rateButtons: {
        marginTop: 10,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
}));

const Rate = (props) => {
    const classes = useStyles();
    const startTime = useMemo(() => {
        if (!props.sessionData?.length) {
            return 0;
        }
        const lastResponse = props.sessionData.reduce(
            (prev, current) => {
                return current.data.type === "start" || current.data.type === "stop"
                    ? current.data
                    : prev;
            },
            { type: "stop" }
        );
        return lastResponse.type === "start" ? lastResponse.time : 0;
    }, [props.sessionData]);

    const handleEntryPress = useCallback(() => {
        props.onAddSessionData(
            props.sessionId,
            props.target.id,
            props.target.dataType,
            { type: "add", time: getUnixTime(new Date()) },
            'Add rate input',
        );
    }, [props.onAddSessionData, props.target.id]);

    const handleStartStopPress = useCallback(() => {
        if (startTime) {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                { type: "stop", time: getUnixTime(new Date()) },
                'Stop rate',
            );
        } else {
            props.onAddSessionData(
                props.sessionId,
                props.target.id,
                props.target.dataType,
                { type: "start", time: getUnixTime(new Date()) },
                'Start rate',
            );
        }
    }, [startTime, props.onAddSessionData, props.target.id]);

    const responseCounter = (sessionData) => {
        return sessionData.filter((e) => e.data.type === "add").length;
    };

    const isResponseAllowed = useMemo(() => {
        const maxTrialNo = props.target.maximumNumberTrials;
        if (!!maxTrialNo && !isNaN(maxTrialNo)) {
            return responseCounter(props.sessionData) < maxTrialNo;
        }
        return true;
    }, [props.sessionData?.length]);

    return (
        <Wrapper {...props} responseCounter={responseCounter}>
            <div className={classes.rateButtons}>
                {!!startTime && (
                    <PhaseColoredButton
                        onClick={handleEntryPress}
                        className={classes.rateButton}
                        disabled={!isResponseAllowed}
                        phase={props.target?.phase}
                    >
                        +
                    </PhaseColoredButton>
                )}
                <PhaseColoredButton 
                    onClick={handleStartStopPress} 
                    className={classes.rateButton}
                    phase={props.target?.phase}
                >
                    {startTime ? "Stop" : "Start"}
                </PhaseColoredButton>
                {!!startTime && <Timer startTime={startTime * 1000} />}
            </div>
        </Wrapper>
    );
};

Rate.propTypes = {
    sessionData: PropTypes.array.isRequired,
    sessionNotes: PropTypes.array.isRequired,
    sessionId: PropTypes.number.isRequired,
    target: PropTypes.object.isRequired,
    onAddSessionData: PropTypes.func.isRequired,
};

export default Rate;
