import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle, DialogContent, Dialog, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';

import { setLoading, setGlobalDialog } from '../../../store/general/actions';
import CategoryTable from './CategoryTable';

const useStyles = makeStyles(theme => ({
	closeIconBtn: {
		padding: '0px 4px',
		color: '#0FB99E',
	},
	dialogPaper: {
		minWidth: 900,
		minHeight: 300,
	},
	dialogContent: {
		marginBottom: 20
	}
}));

const CategoryDialog = (props) => {
	const classes = useStyles();
	const { open, handleClose, tableData, customData, name, fetchData, targetId, setCategoryState } = props;

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			classes={{
				paper: classes.dialogPaper
			}}
			maxWidth='md'
		>
			<DialogTitle>
				<Grid container>
					<Grid item xs={6}>
						{name}
					</Grid>
					<Grid item xs={6} container justify="flex-end">
						<IconButton className={classes.closeIconBtn} onClick={handleClose}>
							<CloseIcon />
						</IconButton>
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				{!!customData && !!tableData && 
					<CategoryTable
						customData={customData}
						tableData={tableData}
						fetchData={() => {
							fetchData().then((res) => {
								const updatedData = res.find(d => d.id === targetId)
								const newData = [updatedData?.targetData.find(d => d.id === tableData[0].id)];
								setCategoryState({ customData, tableData: newData, name, targetId })
							})
						}}
					/>
				}
			</DialogContent>
		</Dialog>
	)
}

CategoryDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	tableData: PropTypes.array,
	name: PropTypes.string,
	fetchData: PropTypes.func.isRequired,

	loading: PropTypes.object.isRequired,
	setLoading: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	loading: state.general.loading,
})

export default connect(mapStateToProps, { setLoading, setGlobalDialog })(CategoryDialog);
