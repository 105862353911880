import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import Table from '@material-ui/core/Table';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Grid from '@material-ui/core/Grid';
import format from 'date-fns/format';
import { dataTypeDict } from '../../constants';
import CanUser from '../CanUser';
import { access } from '../../constants';

const useStyles = makeStyles(theme => ({
	notesTblHeadRow: {
		backgroundColor: '#F4F4F4',
	},
	headCell: {
		fontWeight: 'bold !important',
		fontSize: '16px !important',
		verticalAlign: 'middle !important',
		padding: '12px 16px',
	},
	btnExpand: {
		color: '#0FB99E',
		padding: 0,
	},
	tblExpandWrapper: {
		width: '10%',
		textAlign: 'right',
		padding: '12px 16px',
	},
	expandIcon: {
		fontSize: 32,
	},
	dateCell: {
		width: '15%',
		verticalAlign: 'top',
		color: 'black',
		fontSize: 14,
	},
	targetCell: {
		width: '20%',
		verticalAlign: 'top',
		fontSize: 14,
		color: 'black',
	},
	noteCell: {
		fontWeight: 500,
		color: 'black',
		fontSize: 14,
	},
	noNotes: {
		textAlign: 'center',
	},
	text: {
		margin: 0,
	},
	title: {
		fontWeight: 500,
		textAlign: 'center',
		fontSize: 18,
	},
	type: {
		fontWeight: 'bold',
		paddingLeft: 20,
		fontSize: 16,
	},
	detailsWrapper: {
		padding: 10,
	},
	wrapper: {
		backgroundColor: '#fff',
		fontFamily: 'Roboto',
	},
	date: {
		margin: '0px 0px 2px 0px',
	},
    moreIconBtn: {
      padding: 0,
      color: '#0FB99E',
    },
    moreIcon: {
      fontSize: 32,
    },
}));

const TextTable = (props) => {
	const classes = useStyles();
	const { loopKey, textData, title } = props;
	const [showNotes, setShowNotes] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);

	const handleChangeShowNotes = () => {
		setShowNotes(!showNotes);
	};

    const openMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

	const handleDownload = () => {
		setAnchorEl(null);
		props.handleDownload(`text ${title}.png`);
	}

	const textFieldNames = textData.customData.map((item) => {
		return item.value;
	}) || [];

	const textFieldIds = textData.customData.map((item) => {
		return item.id;
	}) || [];

	return (
		<Grid key={loopKey} className={classes.wrapper} container id={`text ${title}`}>
			<Grid item xs={12} container className={classes.detailsWrapper}>
				<Grid item xs={4} className={classes.type}>
					Data Type: {dataTypeDict.text}
				</Grid>
				<Grid item xs={4} className={classes.title}>
					{props.graphElements.graphTitles ? title : ''}
				</Grid>
				<Grid item xs={4} container justify="flex-end">
					<IconButton className={classes.btnExpand} onClick={handleChangeShowNotes}>
						{showNotes ?
							<KeyboardArrowUpIcon className={classes.expandIcon} />
							:
							<KeyboardArrowDownIcon className={classes.expandIcon} />
						}
					</IconButton>
					<CanUser
						perform={access.analysis.uploadDownload}
						yes={() => (
							<IconButton onClick={openMenu} className={classes.moreIconBtn}>
								<MoreVertIcon className={classes.moreIcon}/>
							</IconButton>
						)}
						no={() => (
							null
						)}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Collapse in={showNotes} style={{ width: '100%', overflowX: 'auto' }}>
					<Table>
						<TableHead>
							<TableRow className={classes.notesTblHeadRow}>
								<TableCell className={`${classes.dateCell} ${classes.headCell}`}>Date/Time</TableCell>
								{textData.targetData.length > 0 && textFieldNames.map((e, index) => (
									<TableCell key={`${loopKey}-head-${index}`} style={{ width: `${55 / textFieldNames.length}%` }} className={`${classes.noteCell}${classes.headCell}`}>{e}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{textData.targetData.map((data, index) => (
								<TableRow key={`${loopKey}-row-${index}`}>
									<TableCell className={classes.dateCell}>
										<p className={classes.date}>{format(new Date(data.date), "MM/d/yyyy")}</p>
										<p className={classes.date}>{format(new Date(data.date), "HH:mm")}</p>
									</TableCell>
									{textFieldNames.map((e, index2) => (
										<TableCell key={`${loopKey}-note-${index}-${index2}`} style={{ width: `${55 / textFieldNames.length}%` }} className={classes.noteCell}>
											{(data.value[textFieldIds[index2]] || []).map((text, index3) => (
												<p key={`${loopKey}-note-${index}-${index2}-${index3}`} className={classes.text}>{typeof text === 'string' ? text.replace(/\s/g,' ') : text?.join(', ').replace(/\s/g,' ')}</p>
											))}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableBody>
					</Table>
				</Collapse>
				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={() => setAnchorEl(null)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'left',
					}}
					getContentAnchorEl={null}
					>
						<MenuItem onClick={handleDownload}>Download</MenuItem>
				</Menu>
			</Grid>
		</Grid>
	)
}

TextTable.propTypes = {
	notesData: PropTypes.array,
	loopKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	graphElements: PropTypes.object.isRequired,
};

export default TextTable;
