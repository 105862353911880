import agent from './agent';
import { localStorageEnum } from '../constants';

export const PAGE_SIZE = 30

export const getFilteredTargets = (request, sortWithPersistedFilters = false, query = {}) => {
    let params = {};
    if (sortWithPersistedFilters) {
        let programFilters = localStorage.getItem(localStorageEnum.programFilters);
        if (programFilters) {
            programFilters = JSON.parse(programFilters);
            if (programFilters.sort?.sortBy && programFilters.sort?.sortAs) {
                params = programFilters.sort;
            }
        }
    }
    return agent.post('/target/filter', request, { params: { ...params, ...query } });
}

export const getSessionTargets = (patientId, date) => {
    const request = {
        domain: 'all',
        program: 'all',
        patient: patientId,
        status: 'active',
        sessionDate: date,
        getOrder: true,
        appliesMaintenanceSetting: true,
        timeZoneOffset: new Date().getTimezoneOffset(),
    };
    return agent.post('/target/filter', request);
}

export const getTarget = id => {
    return agent.get('/target/get', {
        params: { id },
    })
}

export const postTarget = newTarget => {
    return agent.post('/target/create', newTarget);
}

export const updateTarget = (id, target) => {
    return agent.put('/target/update', { id, ...target });
}

export const deleteTarget = id => {
    return agent.delete('target/delete', {
        params: {
            id,
        },
    });
}

export const duplicateTarget = (id, duplicates) => {
    return agent.post('/target/duplicate', { id, num: duplicates });
}

export const getTargetDataListByResourceId = (type, resourceId, filters) => {
    return agent.post('/targetData/list', { groupBy: type, resourceId, filters, cumulative: true });
}

export const getCategoryDataListByResourceId = (resourceId, filters, category) => {
    return agent.post('/targetData/list', { groupBy: 'target', resourceId, filters, category });
}

export const getDataPageListByResourceId = (type, resourceId, filters) => {
    return agent.post('/targetData/data-list', { groupBy: type, resourceId, filters });
}

export const downloadTargetDataByResourceId = (type, resourceId, filters, sheetNames) => {
    return agent.post('/targetData/download', { groupBy: type, resourceId, filters, sheetNames });
}

export const submitTargetData = (data) => {
    return agent.post('/targetData/submit', data);
}

export const getMasteredTargetListByResourceId = (type, resourceId, filters) => {
    return agent.post('/target/list-mastered-targets', { groupBy: type, resourceId, filters });
}

export const getUsersByTargetData = (groupBy, resourceId) => {
    return agent.post('/user/who-submitted-target-data', { groupBy, resourceId }, {
        params: {
            projection: 'id,firstName,lastName'
        }
    })
}

export const downloadClinicalData = (term, request) => {
    return agent.post('/target/download', request, { params: { term } });
}

export const unarchiveTarget = (targetId) => {
    return agent.put('/target/un-archive', { id: targetId });
}

export const updateTargetData = (id, customData, note = undefined) => {
    return agent.put('/targetData/update', { id, customData, note });
}

export const deleteTargetData = (id) => {
    return agent.delete('/targetData/delete', { params: { id } });
}

export const uploadTarget = (file) => {
    return agent.post('/target/upload', file);
}
